import React, { useCallback, useEffect, useRef } from 'react'
import { log } from '../../../services/LogService'
import { Box, DegreesToRadians, FindPointOnCircle, Point, ProcessColor, RadiansToDegrees } from './RadialMeterHelper'
import RadialMeterOptions, { DefaultRadialMeterPropertiesDTO, defaultTextTransform, RadialMeterProperties } from './types'

export const RadialMeter = (properties: RadialMeterProperties): React.ReactElement => {
    //     data = {value: 5, min: 0, max: 20};

    const refCanvas = useRef() as React.MutableRefObject<HTMLCanvasElement>
    log.info('RadialMeter', 'MSG001', 'properties', properties)

    const draw = useCallback(() => {
        log.info('RadialMeter', 'MSG001', 'useCallback properties', properties)
        const canvas: HTMLCanvasElement | undefined = refCanvas.current
        const renderOptions = new RadialMeterOptions(properties.data.properties || DefaultRadialMeterPropertiesDTO)
        const percentValue = (properties.data.value - properties.data.min) / (properties.data.max - properties.data.min)

        if (typeof canvas === 'undefined' || canvas === null) {
            log.error('RadialMeter', 'MSG001', 'Reference to canvas could not be found')
            return
        }
        const ctx = canvas.getContext('2d')
        const dims = canvas.getBoundingClientRect()
        const w = dims.width,
            h = dims.height
        canvas.width = w
        canvas.height = h
        ctx?.clearRect(0, 0, w, h)
        if (ctx) {
            ctx.strokeStyle = 'transparent'
        }

        const center = new Point(w / 2, h / 2)

        const fncGetFontSize = function (size: number) {
            return `bold ${size}px ${renderOptions.Label.fontFamily}`
        }

        const drawOuterRing = () => {
            if (ctx) {
                log.info('RadialMeter', 'MSG001', 'drawOuterRing')
                ctx.fillStyle = ProcessColor(renderOptions.OuterRing.voidColor) || '#205BE1'
                /** Outer radius of the drawing path */
                const r1 = (renderOptions.OuterRing._radius || 0.3357) * h
                /** Inner radius of the drawing path */
                const r2 = r1 - (renderOptions.OuterRing._thickness || 0.0748) * h

                const outerBox = new Box(
                        center.FindOnCircleFromHere(r1, renderOptions.Start),
                        center.FindOnCircleFromHere(r1, renderOptions.End)
                    ),
                    innerBox = new Box(
                        center.FindOnCircleFromHere(r2, renderOptions.Start),
                        center.FindOnCircleFromHere(r2, renderOptions.End)
                    )

                // Start drawing Void path
                outerBox.Start.MoveTo(ctx)
                ctx.beginPath()
                ctx.arc(center.X, center.Y, r1, renderOptions.Start, renderOptions.End)
                innerBox.End.LineTo(ctx)
                ctx.arc(center.X, center.Y, r2, renderOptions.End, renderOptions.Start, true)
                ctx.closePath()
                ctx.fill()

                // Start drawing Value path
                ctx.fillStyle = ProcessColor(renderOptions.OuterRing.color || '#205BE1', ctx, outerBox) || ''
                const endRadian =
                    renderOptions.Start +
                    DegreesToRadians(360 - (RadiansToDegrees(renderOptions.Start) - RadiansToDegrees(renderOptions.End))) * percentValue
                innerBox.End = FindPointOnCircle(center, r2, endRadian)

                outerBox.Start.MoveTo(ctx)
                ctx.beginPath()
                ctx.arc(center.X, center.Y, r1, renderOptions.Start, endRadian)
                innerBox.End.LineTo(ctx)
                ctx.arc(center.X, center.Y, r2, endRadian, renderOptions.Start, true)
                ctx.closePath()
                ctx.fill()

                const thicknessSize = (renderOptions.OuterRing._thickness || 0.0748) * h
                const drawLabel = (text: string, angle: number, endPoint: Point, offset: Point) => {
                    if (ctx) {
                        log.info('RadialMeter', 'MSG001', 'drawLabel')
                        ctx.save()
                        ctx.translate(endPoint.X, endPoint.Y)
                        ctx.rotate(DegreesToRadians(angle))
                        ctx.translate(offset.X, offset.Y)
                        ctx.textAlign = 'center'
                        ctx.textBaseline = 'middle'
                        ctx.fillStyle = ProcessColor(renderOptions.TextColor) || '#205BE1'
                        let fontSize = thicknessSize / 2
                        ctx.font = fncGetFontSize(fontSize)
                        const testFont = ctx.measureText(text)
                        fontSize = fontSize + (fontSize - testFont.width / 2)
                        ctx.font = fncGetFontSize(fontSize)
                        ctx.fillText(text, 0, 0)
                        ctx.restore()
                    }
                }
                drawLabel('' + properties.data.min, -45, outerBox.Start, new Point(thicknessSize / 2, thicknessSize)) // Minimum threshold label "0"
                drawLabel('' + properties.data.max, 45, outerBox.End, new Point(thicknessSize / -2, thicknessSize)) // Maximum threshold label "10"
            }
        }

        const drawTicks = () => {
            const tickRadianSize = (2 * Math.PI - (renderOptions.Start - renderOptions.End)) / ((renderOptions.TickRing.count || 100) * 2)
            const r1 = (renderOptions.TickRing._radius || 0.3357) * h
            const r2 = r1 - (renderOptions.TickRing._thickness || 0.0748) * h
            const colorBounds = new Box(
                center.FindOnCircleFromHere(r1, renderOptions.Start),
                center.FindOnCircleFromHere(r1, renderOptions.End)
            )
            for (let i = 0; i < (renderOptions.TickRing.count || 100); i++) {
                const startRadian = renderOptions.Start + tickRadianSize * 2 * i
                const endRadian = startRadian + tickRadianSize
                const outerBox: Box = new Box(center.FindOnCircleFromHere(r1, startRadian), center.FindOnCircleFromHere(r1, endRadian)),
                    innerBox: Box = new Box(center.FindOnCircleFromHere(r2, startRadian), center.FindOnCircleFromHere(r2, endRadian))
                if (ctx) {
                    // log.info('RadialMeter', 'MSG001', 'drawTicks');

                    // Start drawing path
                    outerBox.Start.MoveTo(ctx)
                    ctx.beginPath()
                    ctx.arc(center.X, center.Y, r1, startRadian, endRadian)
                    innerBox.End.LineTo(ctx)
                    ctx.arc(center.X, center.Y, r2, endRadian, startRadian, true)
                    ctx.closePath()

                    if (percentValue > i / (renderOptions.TickRing.count || 100)) {
                        ctx.fillStyle = ProcessColor(renderOptions.TickRing.color, ctx, colorBounds) || '#205BE1'
                    } else {
                        ctx.fillStyle = ProcessColor(renderOptions.TickRing.voidColor, ctx, colorBounds) || '#205BE1'
                    }
                    ctx.fill()
                }
            }
        }

        const drawLabel = () => {
            log.info('RadialMeter', 'MSG001', 'data.properties?.textTransform', properties.data.properties?.textTransform)
            log.info('RadialMeter', 'MSG001', 'data.value', properties.data.value)
            const text = properties.data.properties?.textTransform
                ? properties.data.properties.textTransform(properties.data.value)
                : defaultTextTransform(properties.data.value)
            // const text = 'hello world';
            if (ctx) {
                log.info('RadialMeter', 'MSG001', 'drawLabel')
                ctx.textAlign = 'center'
                ctx.textBaseline = 'middle'
                ctx.fillStyle = ProcessColor(renderOptions.Label.color) || '#205BE1'
                let fontSize = (renderOptions.Label._radius || 0.3357) * h
                ctx.font = fncGetFontSize(fontSize)
                const testFont = ctx.measureText(text)
                fontSize = fontSize + (fontSize - testFont.width / 2)
                ctx.font = fncGetFontSize(fontSize)
                ctx.fillText(text, w / 2, h / 2)
                log.info('RadialMeter', 'MSG001', 'text', text, w, h, fontSize)
            }
        }

        drawOuterRing()
        drawTicks()
        drawLabel()
        // if (ctx) {
        //     // debug create a block
        //     ctx.fillStyle = "#FF0000";
        //     ctx.fillRect(0, 0, 150, 75);
        // }
    }, [properties])

    useEffect(() => {
        document.fonts.ready.then(() => draw())
        window.addEventListener('resize', draw)
    })

    return (
        <canvas ref={refCanvas} className="radial-meter">
            HTML Canvas is not supported by this browser!
        </canvas>
    )
}
