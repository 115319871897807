import React from 'react'
import { Navigate } from 'react-router-dom'
import { NotFoundLinkStyled, NotFoundStyled, NotFoundWrapperStyled } from '../../../pages/common/styled'
import { OopsIcon } from '../../styled-icons/OopsIcon'
import { DetailsWrapperStyled } from './styled'

export class ErrorBoundary extends React.Component {
    // @ts-ignore
    constructor(props) {
        super(props)
        this.state = { error: null, errorInfo: null, goHome: false }
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: any) {
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, errorInfo);
        this.setState({
            error: error,
            errorInfo: errorInfo,
            goHome: false,
        })
    }

    // @ts-ignore
    componentDidUpdate(previousProps, previousState) {
        // @ts-ignore
        if (previousProps.children !== this.props.children) this.setState({ hasError: false })
    }

    goHome() {
        setTimeout(() => {
            window.location.reload()
        }, 200)
    }

    render() {
        // @ts-ignore
        // @ts-ignore
        if (!this.state.goHome && this.state.errorInfo) {
            // Error path
            // @ts-ignore
            return (
                <NotFoundWrapperStyled>
                    <OopsIcon />
                    <NotFoundStyled>Something went wrong!</NotFoundStyled>
                    <NotFoundLinkStyled onClick={() => this.goHome()}>Return home</NotFoundLinkStyled>
                    <DetailsWrapperStyled>
                        <details style={{ whiteSpace: 'pre-wrap' }}>
                            {
                                // @ts-ignore
                                this.state.error && this.state.error.toString()
                            }
                            <br />
                            {
                                // @ts-ignore
                                this.state.errorInfo.componentStack
                            }
                        </details>
                    </DetailsWrapperStyled>
                </NotFoundWrapperStyled>
            )
        }
        // Normally, just render children
        // @ts-ignore
        return this.props.children
    }
}
