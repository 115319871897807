import React, { useEffect, useState } from 'react'
import { EnvironmentContext } from '../../providers/environment/EnvironmentContext'
import { environmentService } from '../../providers/environment/EnvironmentService'
import { AuthType, IEnvironment } from '../../providers/environment/types'
import { log } from '../../services/LogService'
import './Login.css'
import { notBlank } from '../../utils/TypeCheckers'
import LoginGoogleSSO from './LoginGoogleSSO'
import LoginMsalSSO from './LoginMsalSSO'
import LoginUsernamePassword from './LoginUsernamePassword'
import LoginUsernamePasswordTOTP from './LoginUsernamePasswordTOTP'
import { LoginCardStyled, LoginTextStyled, LoginTitleBlockStyled } from './styled'

export interface IError {
    message: string
    details: string
}

export const Login = (): React.ReactElement => {
    const environmentContext = React.useContext(EnvironmentContext)
    const [haveEnvironment, setHaveEnvironment] = useState<boolean>(false)
    const [environment, setEnvironment] = useState<IEnvironment | null>(null)

    useEffect(() => {
        environmentContext.watchEnvironment().subscribe((environment: IEnvironment) => {
            log.debug('Login', 'MSG001', ' environment', environment)

            if (environment.loaded) {
                setEnvironment(environment)
                setHaveEnvironment(true)
            }
        })
    }, [environmentContext])

    const renderLoginByType = (): React.ReactElement => {
        log.debug('Login', 'MSG002', ' AuthType', environment?.authType)
        switch (environment?.authType) {
            case AuthType.TIME_BASED_ONE_TIME_PASSWORD:
                return <LoginUsernamePasswordTOTP environment={environment} />
            case AuthType.USERNAME_PASSWORD:
                return <LoginUsernamePassword environment={environment} />
            case AuthType.MSAL:
                return <LoginMsalSSO environment={environment} />
            case AuthType.GOOGLE_SSO:
            default:
                return <LoginGoogleSSO environment={environment} />
        }
    }

    const renderLoginText = (): React.ReactElement => {
        return (
            <>
                {notBlank(environment?.loginUiProperties?.loginTitle) && (
                    <LoginTitleBlockStyled>{environment?.loginUiProperties?.loginTitle}</LoginTitleBlockStyled>
                )}
                {notBlank(environment?.loginUiProperties?.loginText) && (
                    <LoginTextStyled noModal={environmentService.getEnvironment()?.loginUiProperties?.noModal}>
                        {environment?.loginUiProperties?.loginText}
                    </LoginTextStyled>
                )}
            </>
        )
    }

    const renderLogin = (): React.ReactElement => {
        return (
            <LoginCardStyled>
                {renderLoginText()}
                {renderLoginByType()}
            </LoginCardStyled>
        )
    }

    if (haveEnvironment) {
        return renderLogin()
    } else {
        return <></>
    }
}
