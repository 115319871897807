import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface RowActionOpenState {
    code: string | undefined
    rowId: string | undefined
}

const initialState: RowActionOpenState = {
    code: undefined,
    rowId: undefined,
}

export interface RowActionOpenPayloadAction {
    code: string | undefined
    rowId: string | undefined
}

export interface RowActionCleanUpPayload {
    code: string | undefined
    rowId: string | undefined
}

export const rowActionOpenSlice = createSlice({
    name: 'rowActionOpenSlice',
    initialState,
    reducers: {
        toggleActionOpen(state, action: PayloadAction<RowActionOpenPayloadAction>) {
            let entry: RowActionOpenState = state
            entry.code = action.payload.code
            entry.rowId = action.payload.rowId
        },
        cleanUpActionOpen(state, action: PayloadAction<RowActionCleanUpPayload>) {
            // delete state[action.payload.rowId]
        },
    },
})

export const { toggleActionOpen, cleanUpActionOpen } = rowActionOpenSlice.actions

export default rowActionOpenSlice.reducer
