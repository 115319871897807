import { Http, HttpResponse } from '@capacitor-community/http'
import { Capacitor } from '@capacitor/core'
import { UserAgentApplication } from 'msal'
import React, { useEffect, useState } from 'react'
import { BehaviorSubject, Observable } from 'rxjs'
import { MediaType, WidgetPanelProperties } from '../../components/types'
import { Environments } from '../../environments/environment'
import { log } from '../../services/LogService'
import { isDefined } from '../../utils/TypeCheckers'
import { RunOnceEffect } from '../../utils/Utils'
import { guslStorage } from '../session/GuslStorage'
import { GuslThemeContext, ReactChildProperties } from '../theme/GuslThemeProvider'

import { EnvironmentContext } from './EnvironmentContext'
import { environmentService } from './EnvironmentService'
import { AuthType, IEnvironment, MsalProperties, UiPropertiesRequestDTO } from './types'

export const EnvironmentProvider: React.FC<ReactChildProperties> = ({ children }) => {
    const [className] = React.useState<string>(() => 'EnvironmentProvider-' + new Date().getTime())

    const guslThemeContext = React.useContext(GuslThemeContext)

    const [environmentSubject] = React.useState<BehaviorSubject<IEnvironment>>(
        new BehaviorSubject<IEnvironment>({ loaded: false } as IEnvironment)
    )

    // note the double negative - so noService = true when the server is down
    const [noServiceSubject] = React.useState<BehaviorSubject<boolean>>(new BehaviorSubject<boolean>(false))

    const [msalUserAgentApplication, setMsalUserAgentApplication] = React.useState<UserAgentApplication | undefined>(undefined)
    const [_isMobileDevice, setIsMobileDevice] = useState<boolean>(false)
    const [mediaTypeSubject] = React.useState<BehaviorSubject<MediaType>>(new BehaviorSubject<MediaType>(MediaType.Desktop))
    const [resizeSubject] = React.useState<BehaviorSubject<number>>(new BehaviorSubject<number>(window.innerWidth))
    const [orientationSubject] = React.useState<BehaviorSubject<string>>(new BehaviorSubject<string>('portrait'))
    //
    //     const [orientationSubject] = React.useState<BehaviorSubject<string>>(new BehaviorSubject<string>(() => {
    //     if (window?.screen?.orientation?.type) {
    //         return '' + window.screen.orientation.type
    //     } else {
    //         let mql = window.matchMedia("(orientation: portrait)");
    //         if(mql.matches) {
    //             return 'portrait'
    //         } else {
    //             return 'landscape'
    //         }
    //     }
    // }));

    const [isIphone, setIsIphone] = useState<boolean>(false)
    const [isAndroid, setIsAndroid] = useState<boolean>(false)
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [isDesktop, setIsDesktop] = useState<boolean>(false)

    const calculateTheMediaType = (width: number, mobileDevice: boolean): MediaType => {
        /*
320px — 480px: Mobile devices.
481px — 768px: iPads, Tablets.
769px — 1024px: Small screens, laptops.
1025px — 1200px: Desktops, large screens.
1201px and more — Extra large screens, TV.
 */
        let mediaType: MediaType = MediaType.Desktop
        if (mobileDevice) {
            mediaType = MediaType.Mobile
        } else {
            if (!width) {
                mediaType = MediaType.Desktop
            } else if (width <= 480) {
                mediaType = MediaType.Mobile
            } else if (width <= 768) {
                mediaType = MediaType.Tablet
            } else if (width <= 1024) {
                mediaType = MediaType.Laptop
            } else if (width <= 1200) {
                mediaType = MediaType.Desktop
            } else {
                mediaType = MediaType.ExtraLarge
            }
        }
        // console.log('calculateTheMediaType.width ==>',width, width <= 480, mediaType)
        return mediaType
    }

    const calculateMediaType = (width: number, mobileDevice: boolean): MediaType => {
        const mediaType: MediaType = calculateTheMediaType(width, mobileDevice)
        mediaTypeSubject.next(mediaType)
        // log.info('Device', 'MSG002', 'Media Type: ' + mediaType);
        return mediaType
    }

    const onResizeFinished = () => {
        let mobileDevice = false
        const ua = navigator.userAgent
        if (/(tablet|ipad|playbook|silk) | (android(?!.*mobi))/i.test(ua)) {
            mobileDevice = true
        } else if (/Mobile|Android}iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            mobileDevice = true
            // } else {
            //     mobileDevice = window.innerWidth <= 768
        }

        if (/(android(?!.*mobi))/i.test(ua)) {
            setIsAndroid(true)
        } else if (/(ipad|iPhone)/i.test(ua) && ua.indexOf('Mac OS X') < 0) {
            setIsIphone(true)
        } else {
            setIsDesktop(true)
        }

        setIsMobileDevice(mobileDevice)
        resizeSubject.next(window.innerWidth)
        const mediaType: MediaType = calculateMediaType(window.innerWidth, mobileDevice)

        document.documentElement.style.setProperty(`--${'internal-height'}`, window.innerHeight + 'px')
        document.documentElement.style.setProperty(`--${'internal-width'}`, window.innerWidth + 'px')

        log.info(
            'Device',
            'MSG001',
            `ua: (mobile: ${mobileDevice ? 'true' : 'false'} ua: ${navigator.userAgent} height: ${window.innerHeight} width: ${
                window.innerWidth
            } mediaType: ${mediaType}`
        )
    }
    const getDeviceClassname = (): string => {
        if (isAndroid) {
            return 'android'
        } else if (isIphone) {
            return 'ios'
        } else {
            return 'desktop'
        }
    }

    useEffect(() => {
        let timeout: NodeJS.Timeout
        const onResize = () => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                onResizeFinished()
            }, 400)
        }
        window.addEventListener('resize', onResize)
        onResize()

        try {
            window.screen.orientation.addEventListener('change', (event) => {
                orientationSubject.next(window.screen.orientation.type)
                // console.log(`ScreenOrientation change: ${window.screen.orientation.type}`);
            })
        } catch (err) {
            // worried about mobile
        }
    })

    const configureMsal = (environment: IEnvironment, data: any) => {
        const msalProperties: MsalProperties = data.msalProperties
        environment.useMsalRedirect = true // could become part of msal properties
        environment.msalConfig = {
            auth: {
                clientId: msalProperties.clientId,
                authority: msalProperties.authority,
                redirectUri: msalProperties.redirectUri,
            },
            cache: {
                cacheLocation: msalProperties.cacheLocation,
                storeAuthStateInCookie: msalProperties.storeAuthStateInCookie,
            },
        }

        environment.msalGraphConfig = {
            graphMeEndpoint: msalProperties.graphMeEndpoint || '',
        }

        environment.msalScopes = ['profile', 'openid', ...msalProperties.scopes] // name ["clientId/.default"] //

        try {
            let msalApplication: UserAgentApplication | undefined
            if (environment.msalConfig) {
                msalApplication = new UserAgentApplication(environment.msalConfig)
                setMsalUserAgentApplication(msalApplication)
            }
        } catch (error) {
            console.log('--- MSAL ---error: ', error)
        }
    }

    /**
     * No theme exists, get theme definition from server
     * @param themeId The theme Id
     */
    const getThemeDefinitionFromServer = (endpoint: string, storagePrefix: string | undefined, themeId: string): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const options = {
                url: `${endpoint}/profile/${themeId}/theme`,
                headers: { 'Content-Type': 'application/json' },
                readTimeout: 20000,
                connectTimeout: 20000,
            }
            Http.request({ ...options, method: 'GET' })
                .then(async (response: HttpResponse) => {
                    if (response.status === 200) {
                        guslStorage.saveThemeId(themeId)
                        guslStorage.saveTheme(response.data)
                        guslStorage.removeThemeLastUpdated() // will for ce user update
                        guslThemeContext.setTheme(themeId, response.data, _isMobileDevice)
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                })
                .catch((error) => {
                    console.error('Error getting theme', error)
                    resolve(false)
                })
        })
    }

    /**
     * Check and update theme.
     * Note: This is at the splash screen login stage, we do not know who the user is.getMsalInstance
     * Will update theme from ui-props
     * (a) if there is no theme at all
     * (b) the server's theme updatedDate is more recent than the UI
     * No update if UI theme is different to default, will get updated when user logins in (see SessionProvider)
     * @param endpoint
     * @param environ
     * @return Promise<boolean> success if theme updated
     */
    const checkAndUpdateTheme = (endpoint: string, environ: IEnvironment): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            if (environ.theme) {
                const themeId: string | undefined = guslStorage.getThemeId()

                // console.log('checkAndUpdateTheme serverTheme: ' + environ.theme + ' ui theme: ' + themeId + ' haveTheme: ' + guslThemeContext.hasTheme(environ.storagePrefix))
                if (!guslThemeContext.hasTheme(environ.storagePrefix)) {
                    /**
                     * We have no theme, so go to server and get latest
                     */
                    getThemeDefinitionFromServer(endpoint, environ.storagePrefix, environ.theme)
                        .then((success: boolean) => {
                            resolve(success)
                        })
                        .catch((err) => {
                            log.error(className, 'ERR002', 'GET theme. Error', err)
                            resolve(false)
                        })
                } else if (themeId !== environ.theme) {
                    // need to override default
                    guslThemeContext.setTheme(environ.storagePrefix, guslStorage.getTheme(), _isMobileDevice)
                    resolve(true)
                } else if (environ.themeLastUpdated) {
                    /**
                     * UI theme is same as default, check if server update date is different o our date, and update if different
                     */
                    const uiThemeLastUpdated: Date | undefined = guslStorage.getThemeLastUpdated()
                    const serverDate = new Date(environ.themeLastUpdated)
                    // console.log('serverDate vs uiThemeLastUpdated', serverDate, uiThemeLastUpdated, (uiThemeLastUpdated ? (serverDate > uiThemeLastUpdated) : 'no date'))
                    if (!uiThemeLastUpdated || serverDate > uiThemeLastUpdated) {
                        getThemeDefinitionFromServer(endpoint, environ.storagePrefix, environ.theme)
                            .then((success: boolean) => {
                                resolve(success)
                            })
                            .catch((err) => {
                                log.error(className, 'ERR002', 'GET theme. Error', err)
                                resolve(false)
                            })
                    } else {
                        guslThemeContext.setTheme(environ.storagePrefix, guslStorage.getTheme(), _isMobileDevice)
                        resolve(true)
                    }
                } else {
                    resolve(true)
                }
            }
        })
    }

    const retryUiProps = (
        endpoint: string,
        baseEnvironment: IEnvironment | undefined,
        isApp: boolean,
        host: any,
        scheme: any,
        cohortId: string | undefined
    ) => {
        noServiceSubject.next(true)
        setTimeout(() => {
            getUiProps(endpoint, baseEnvironment, isApp, host, scheme, cohortId)
        }, 10000)
    }

    const getUiProps = (
        endpoint: string,
        baseEnvironment: IEnvironment | undefined,
        isApp: boolean,
        host: any,
        scheme: any,
        cohortId: string | undefined
    ) => {
        const request: UiPropertiesRequestDTO = {
            hostname: isApp ? host : window.location.hostname,
            port: isApp ? '' : window.location.port,
            scheme: isApp ? scheme : window.location.protocol,
            cohortId: cohortId || guslStorage.getCohortId(),
            timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
        }
        // log.info(className, 'MSG006', 'POST ui-props request ', endpoint, request);
        //        console.log('ui-props request', request)

        const options = {
            url: `${endpoint}/ui-props`,
            data: request,
            headers: { 'Content-Type': 'application/json' },
            readTimeout: 20000,
            connectTimeout: 20000,
        }
        Http.request({ ...options, method: 'POST' })
            .then(async (response: HttpResponse) => {
                if (response.status === 200) {
                    const data = await response.data
                    // log.info(className, 'MSG004', 'GET ui-props. Response', data);
                    response.data.loaded = true
                    const environ: IEnvironment = {
                        ...baseEnvironment,
                        ...data,
                    } as IEnvironment
                    // console.log('environ', environ)
                    log.info('Device', 'MSG003', 'Platform: ' + Capacitor.getPlatform() + ' Native:' + Capacitor.isNativePlatform())

                    document.title = environ.titlePrefix || ''
                    guslStorage.setStoragePrefix(environ.storagePrefix)
                    checkAndUpdateTheme(endpoint, environ)
                        .then((success: boolean) => {
                            if (success) {
                                if (environ.authType === AuthType.MSAL) {
                                    configureMsal(environ, response.data)
                                }
                                environmentSubject.next(environ)
                                environmentService.setEnvironment(environ)
                                noServiceSubject.next(false)
                            } else {
                                retryUiProps(endpoint, environ, isApp, host, scheme, cohortId)
                            }
                        })
                        .catch((error) => {
                            log.error(className, 'ERR002', 'GET Theme. Error', error)
                            retryUiProps(endpoint, environ, isApp, host, scheme, cohortId)
                        })
                }
            })
            .catch((error) => {
                log.error(className, 'ERR003', 'GET ui-props. Error', error)
                retryUiProps(endpoint, baseEnvironment, isApp, host, scheme, cohortId)
            })
    }

    RunOnceEffect(() => {
        //log.info(className, 'MSG001', '-- EnvironmentProvider Init --')
        let found: boolean = false
        let endpoint: string = ''
        let baseEnvironment: IEnvironment | undefined
        // @ts-ignore
        console.log('__RUNTIME_CONFIG__', window?.__RUNTIME_CONFIG__)
        // @ts-ignore
        let appEnv = window?.__RUNTIME_CONFIG__?.APP_ENV || 'default'
        // @ts-ignore
        let host = window?.__RUNTIME_CONFIG__?.HOST
        // @ts-ignore
        let scheme = window?.__RUNTIME_CONFIG__?.SCHEME

        // @ts-ignore
        let cohortId = window?.__RUNTIME_CONFIG__?.COHORT_ID

        const isApp = isDefined(host)

        // @ts-ignore
        //  log.info(className, 'MSG002', 'window.__RUNTIME_CONFIG__', window?.__RUNTIME_CONFIG__)
        // log.info(className, 'MSG003', 'appEnv', appEnv)
        // let appEnv = process.env.REACT_APP_ENV || 'default';
        Environments.filter((env) => env.name === appEnv).forEach((env) => {
            // @ts-ignore
            env.apiBase = window?.__RUNTIME_CONFIG__?.API_BASE || env.apiBase
            env.cohortId = cohortId || env.cohortId
            // log.info(className, 'MSG004', 'found env', env);
            endpoint = env.apiBase
            baseEnvironment = env
            found = true
        })

        if (!found) {
            log.error(className, 'ERR001', 'Failed to find environment for ', appEnv)
        } else {
            // log.info(className, 'MSG005', 'POST ui-props', endpoint);
            getUiProps(endpoint, baseEnvironment, isApp, host, scheme, cohortId)
        }
        // set initial size
        onResizeFinished()
    })

    const watchEnvironment = (): Observable<IEnvironment> => {
        return environmentSubject.asObservable()
    }

    const watchNoService = (): Observable<boolean> => {
        return noServiceSubject.asObservable()
    }

    const getMsalInstance = (): UserAgentApplication | undefined => {
        return msalUserAgentApplication
    }
    const isMsalRedirect = (): boolean => {
        return true
    }

    const getStoragePrefix = (): string | undefined => {
        return environmentService.getEnvironment()?.storagePrefix
    }

    /**
     * Returns true if 'app' is running on a mobile device
     */
    const isNativeDevice = (): boolean => {
        return Capacitor.isNativePlatform()
        // console.log('=======> capacitor platform',Capacitor.getPlatform())
        // console.log('=======> capacitor native',Capacitor.isNativePlatform())
    }
    const isNativeIOS = (): boolean => {
        try {
            // also done in GuslThemeProvider
            return 'ios' === Capacitor.getPlatform() && Capacitor.isNativePlatform()
        } catch (err) {
            return false
        }
    }

    /**
     * based on size - trying to identify an ipad is a bit difficult
     */
    const isMobileDevice = (widgetPanelProperties?: WidgetPanelProperties): boolean => {
        if (widgetPanelProperties && widgetPanelProperties.width) {
            const mediaType: MediaType = calculateTheMediaType(widgetPanelProperties.width, false)
            if (mediaType === MediaType.Mobile || mediaType === MediaType.Tablet) {
                return true
            }
        }
        return _isMobileDevice
    }

    const watchMediaType = (): Observable<MediaType> => {
        return mediaTypeSubject.asObservable()
    }

    const getCurrentMediaType = (widgetPanelProperties?: WidgetPanelProperties): MediaType => {
        if (widgetPanelProperties && widgetPanelProperties.width) {
            return calculateTheMediaType(widgetPanelProperties.width, false)
        }
        return mediaTypeSubject.getValue()
    }

    const watchResize = (): Observable<number> => {
        return resizeSubject.asObservable()
    }

    const watchOrientation = (): Observable<string> => {
        return orientationSubject.asObservable()
    }

    const getCurrentOrientation = (): string => {
        return orientationSubject.getValue()
    }

    return (
        <EnvironmentContext.Provider
            value={{
                watchEnvironment,
                getMsalInstance,
                isMsalRedirect,
                watchNoService,
                getStoragePrefix,
                isMobileDevice,
                isNativeDevice,
                isNativeIOS,
                watchMediaType,
                watchResize,
                getCurrentMediaType,
                getDeviceClassname,
                watchOrientation,
                getCurrentOrientation,
            }}
        >
            {children}
        </EnvironmentContext.Provider>
    )
}
