import styled from 'styled-components'
import { PANEL_HEADER_HEIGHT } from '../../../../../providers/theme/GuslThemeProvider'

interface SlidingPanelStyledProperties {
    windowHeight: number
    isMobile: boolean
    windowWidth: number
    opening: boolean
}

export const SlidingPanelStyled = styled.div.attrs((props: SlidingPanelStyledProperties) => ({}))<SlidingPanelStyledProperties>`
    z-index: 4000;
    width: ${(props) => props.windowWidth}px;
`

interface SlidingPanelHeaderProperties {
    width: number
}

export const SlidingPanelHeaderStyled = styled.div.attrs((props: SlidingPanelHeaderProperties) => ({}))<SlidingPanelHeaderProperties>`
    display: inline-block;
    color: ${(props) => props.theme.table.titleColor};
    font-size: 16px; // ${(props) => props.theme.table.titleFontSize};
    //padding: 7px 10px 10px 5px;
    //padding-top: 3px;
    background-color: ${(props) => props.theme.table.titleBgColor};
    width: ${(props) => props.width}px;
    height: ${(props) => PANEL_HEADER_HEIGHT}px;
    border: none;
`

interface SlidingPanelBodyProperties {
    width: number
    isMobile: boolean
    startPos: number
    noFooter?: boolean
    footerHeight: number
}

export const SlidingPanelBodyStyled = styled.div.attrs((props: SlidingPanelBodyProperties) => ({}))<SlidingPanelBodyProperties>`
    overflow: auto;
    height: ${(props) =>
        'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.startPos + props.footerHeight) + 'px)'} !important;
    width: ${(props) => props.width}px;
`

export const BackIconStyled = styled.div.attrs((props) => ({}))`
    margin: 10px 15px 5px 10px;
    display: inline-block;
`

interface HeaderProperties {
    width: number
}

export const HeaderStyled = styled.div.attrs((props: HeaderProperties) => ({}))<HeaderProperties>`
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: ${(props) => props.width - 70}px;
    position: relative;
    top: 2px;
`
export const SubHeaderStyled = styled.div.attrs((props: HeaderProperties) => ({}))<HeaderProperties>`
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: ${(props) => props.width - 70}px;
    padding-left: 38px;
`
