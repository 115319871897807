import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useAppSelector } from '../../../app/hooks'
import { hasValidation, shouldDisable, shouldHide } from '../../../services/ValidationService'
import { FieldProperties } from '../../types'
import { ElementWithTooltip } from '../element-with/element-with-tooltip/ElementWithTooltip'
import { GuslFormState } from '../maintain-form/guslFormSlice'

export const TableLinkField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [formMode, setFormMode] = useState(properties.formMode)
    // const [formValue, setFormValue] = useState<string>(properties?.data || 'test');
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) || properties.data || 'test')

    // const to = properties?.link;

    /*OPTIONAL...*/
    // const target = properties?.target || '';
    // const className = properties?.className || '';

    const to = '/admin/Summary' // temp
    const target = '_blank' // opens a new tab
    const className = 'text-info' /// OR StyledComponent based on theme....

    const renderTableView = (): React.ReactElement => {
        return (
            <ElementWithTooltip
                element={
                    <NavLink to={to} target={target} className={className}>
                        {formValue}
                    </NavLink>
                }
                tooltip={
                    <span>
                        {`Go to page.`}
                        {/*{`Go to ${formValue} page.`}*/}
                    </span>
                }
            />
        )
    }

    const renderFormView = (): React.ReactElement => {
        const hideField = shouldHide(formMode, properties.fieldConfig)
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const disableField: boolean = shouldDisable(formMode, properties.fieldConfig)
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const required = hasValidation(properties.fieldConfig?.validators)

        return <>{!hideField && <p>Not implemented</p>}</>
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
