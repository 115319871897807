import React, { useState } from 'react'
import ElementWithModal from '../../element-with/element-with-modal/ElementWithModal'
import { BoxStyled, ButtonGroupStyled, CurrencyTickerStyled } from '../styled_v2'
import Icon from '../../maintain-table/bootstrap/Icon'
import HeaderItem from './HeaderItem'
import { BuySellState } from '../buySellSlice'
import { HeaderItemWrapperStyled } from './styled'
import { NewTickerDO } from './types'
import { useParams } from 'react-router-dom'
import { EquitiesTrie } from './search-table/equities/EquitiesTrie'
import SearchTableContent from './search-table/SearchTableContent'

export interface BSHeaderDO {
    lookUpClosed: boolean
    setLookUpClosed: Function
    setTickers: Function
    _buySellSlice: BuySellState
    tickers: NewTickerDO[]
    ticker: NewTickerDO
    originalTickers: NewTickerDO[]
    performPreOrderQuery: Function
    setWLQueryRefresher: Function
    currentlySelectedFavorites: NewTickerDO[]
}

export default function BSHeader({
    lookUpClosed,
    setLookUpClosed,
    setTickers,
    _buySellSlice,
    tickers,
    ticker,
    originalTickers,
    performPreOrderQuery,
    setWLQueryRefresher,
    currentlySelectedFavorites,
}: BSHeaderDO) {
    const [className] = useState('BSHeader-' + new Date().getTime())
    const params = useParams()
    const tickerParam = params?.tickerId ? decodeURIComponent(params?.tickerId) : undefined
    const [showTickerSearch] = useState<boolean>(typeof tickerParam === 'undefined')

    const trie = new EquitiesTrie()
    originalTickers.forEach((ticker) => trie.insert(ticker))

    console.log({ currentlySelectedFavorites })

    function searchHandler(e: any) {
        const userInput: string = e.target.value.toLowerCase()
        const filteredTickers: NewTickerDO[] = trie.search(userInput)
        setTickers(filteredTickers)
        if (userInput === '') {
            setTickers(originalTickers)
        }
    }

    return (
        <div className="row g-0">
            <div className="col-xxl-2 col-xl-2 col-md-2 d-flex align-items-center">
                {!showTickerSearch && (
                    <BoxStyled>
                        <CurrencyTickerStyled>{ticker?.displayTicker}</CurrencyTickerStyled>
                    </BoxStyled>
                )}
                {showTickerSearch && (
                    <ElementWithModal
                        close={lookUpClosed}
                        withCancel={true}
                        hideHeader={true}
                        centered={true}
                        element={
                            <div
                                className=""
                                onClick={() => {
                                    setTickers(originalTickers)
                                    setLookUpClosed(false)
                                }}
                            >
                                <ButtonGroupStyled className={'d-flex align-items-center justify-content-between px-3 py-2'}>
                                    <span>
                                        {' '}
                                        {_buySellSlice ? _buySellSlice.ticker?.displayTicker || _buySellSlice.ticker?.symbol : 'Select one'}
                                    </span>
                                    <Icon icon={'fa-solid fa-caret-down'} extraClass={'ms-1'} />
                                </ButtonGroupStyled>
                            </div>
                        }
                        modalContent={
                            <SearchTableContent
                                tickers={tickers}
                                setTickers={setTickers}
                                performPreOrderQuery={performPreOrderQuery}
                                searchHandler={searchHandler}
                                setWLQueryRefresher={setWLQueryRefresher}
                                currentlySelectedFavorites={currentlySelectedFavorites}
                            />
                        }
                    />
                )}
            </div>
            <HeaderItemWrapperStyled>
                <HeaderItem
                    ticker={ticker}
                    label={'Last price'}
                    value={ticker?.lastPrice as number}
                    valueType={'lastPrice'}
                    buySellSlice={_buySellSlice}
                />
            </HeaderItemWrapperStyled>
            <HeaderItemWrapperStyled>
                <HeaderItem
                    ticker={ticker}
                    label={'24h change'}
                    value={ticker?.change as number}
                    valueType={'change'}
                    buySellSlice={_buySellSlice}
                />
            </HeaderItemWrapperStyled>
            <HeaderItemWrapperStyled>
                <HeaderItem
                    ticker={ticker}
                    label={'24h high'}
                    value={ticker?.dailyHigh as number}
                    valueType={'dailyHigh'}
                    buySellSlice={_buySellSlice}
                />
            </HeaderItemWrapperStyled>
            <HeaderItemWrapperStyled>
                <HeaderItem
                    ticker={ticker}
                    label={'24h low'}
                    value={ticker?.dailyLow as number}
                    valueType={'dailyLow'}
                    buySellSlice={_buySellSlice}
                />
            </HeaderItemWrapperStyled>
            <HeaderItemWrapperStyled>
                <HeaderItem
                    ticker={ticker}
                    label={'Volume'}
                    value={ticker?.volume as number}
                    valueType={'volume'}
                    buySellSlice={_buySellSlice}
                />
            </HeaderItemWrapperStyled>
        </div>
    )
}
