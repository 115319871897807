import i18next from 'i18next'
import { isDefined, isNotObject } from '../utils/TypeCheckers'
import { log } from './LogService'

class TranslateService {
    public getLabel = (menuCode: string | undefined, fieldCode: string | undefined, defaultValue: string | undefined) => {
        return this.translate(menuCode, fieldCode, undefined, defaultValue)
    }

    public getTitle = (menuCode: string | undefined, defaultValue: string | undefined) => {
        return this.translate(menuCode, undefined, 'title', defaultValue)
    }

    public getValue = (menuCode: string | undefined, defaultValue: string | undefined) => {
        return this.translate(menuCode, undefined, undefined, defaultValue)
    }

    public getKey = (key: string, params?: object) => {
        return i18next.t(key, params)
    }

    public getHeader = (menuCode: string | undefined, fieldCode: string | undefined, defaultValue: string | undefined) => {
        return this.translate(menuCode, fieldCode, 'header', defaultValue)
    }

    public getFormLabel = (menuCode: string | undefined, fieldCode: string | undefined, defaultValue: string | undefined) => {
        if (isDefined(fieldCode) && isDefined(defaultValue) && fieldCode !== defaultValue) {
            return defaultValue
        }
        return this.translate(menuCode, fieldCode, 'label', defaultValue)
    }

    public getErrorMessage = (
        menuCode: string | undefined,
        fieldCode: string | undefined,
        type: string,
        defaultValue: string | undefined
    ) => {
        const key =
            (menuCode ? menuCode.toLowerCase() : '') +
            (fieldCode ? '.' + fieldCode?.toLowerCase() || '' : '') +
            '.validation' +
            '.' +
            type.toLowerCase()
        let value = i18next.t(key)
        if (isNotObject(value) && value !== key) {
            return value
        }

        if ('not_null' === type.toLowerCase()) {
            // this is horrible!
            return 'Field is required'
        }
        log.error('TranslateService', 'ERR001', 'key not found', key)
        return defaultValue
    }

    public translate = (
        menuCode: string | undefined,
        fieldCode: string | undefined,
        element: string | undefined,
        defaultValue: string | undefined
    ): string => {
        let key = ''
        if (menuCode) {
            key = menuCode.toLowerCase() + (fieldCode ? '.' + fieldCode?.toLowerCase() || '' : '') + (element ? '.' + element : '')
        } else {
            if (fieldCode) {
                key = fieldCode
            } else {
                return defaultValue || ''
            }
        }
        let value = i18next.t(key)
        if (isNotObject(value) && value !== key) {
            return value
        }

        key = fieldCode?.toLowerCase() || '' + (element ? '.' + element : '')
        value = i18next.t(key)
        if (isNotObject(value) && value !== key) {
            return value
        }

        key = fieldCode?.toLowerCase() || ''
        value = i18next.t(key)
        if (isNotObject(value) && value !== key) {
            return value
        }
        return defaultValue || ''
    }
}

const translateService = new TranslateService()

export { translateService }

export default TranslateService
