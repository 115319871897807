import React, { useState } from 'react'
import { ElementWithTooltip } from '../../../element-with/element-with-tooltip/ElementWithTooltip'
import { PastUnitsPaginationBtnStyled, PastUnitsPaginationIconStyled } from '../../styled/date-range/styled'
import { PaginationBtnDO } from '../types'

export default function PaginationBtn({ isActive, direction, onClick, tooltip }: PaginationBtnDO) {
    const [className] = useState('PaginationBtn-' + new Date().getTime())

    return (
        <ElementWithTooltip
            element={
                <PastUnitsPaginationBtnStyled
                    className={'p-1 px-2 col-1 d-flex justify-content-center align-items-center'}
                    active={isActive}
                    onClick={onClick}
                >
                    <PastUnitsPaginationIconStyled
                        icon={'fa-solid fa-angle-' + (direction === 'past' ? 'left' : 'right')}
                        active={isActive}
                    />
                </PastUnitsPaginationBtnStyled>
            }
            tooltip={isActive ? tooltip : <span>N/A</span>}
        />
    )
}
