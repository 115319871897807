import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Define a type for the slice state
interface FlagState {
    resized: number
}

// Define the initial state using that type
const initialState: FlagState = {
    resized: 1,
}

export const flagSlice = createSlice({
    name: 'flagSlice',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setResized(state, action: PayloadAction<boolean>) {
            state.resized = state.resized + 1
        },
    },
})

export const { setResized } = flagSlice.actions

export default flagSlice.reducer
