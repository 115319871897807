import { Subject } from 'rxjs'
import { LogService } from './log-service'

export class Attachment {
    _isList: boolean | undefined
    _key: string
    _data: any
    _listData: any[] | undefined
    _attachmentId: string
    _loadStream: Subject<any>
    _addStream: Subject<any>
    _changeStream: Subject<any>
    _removeStream: Subject<any>
    _tickStream: Subject<any>
    _logService: LogService

    constructor(attachmentId: string, key: string, logService: LogService) {
        this._key = key
        this._attachmentId = attachmentId
        this._loadStream = new Subject()
        this._addStream = new Subject()
        this._changeStream = new Subject()
        this._removeStream = new Subject()
        this._tickStream = new Subject()
        this._logService = logService
    }

    isList(): boolean {
        return this._isList || false
    }

    getAttachmentId(): string {
        return this._attachmentId
    }

    getKey(): string {
        return this._key
    }

    getData(): any {
        return this._data
    }

    getListData(): any[] {
        return this._listData || []
    }

    setData(data: any) {
        if (data === undefined) {
            this._data = {}
        } else {
            this._data = data
        }
        this._isList = false
    }

    setListData(data: any[]) {
        if (data === undefined) {
            this._listData = []
        } else {
            this._listData = data
        }
        this._isList = true
    }

    load(data: any) {
        this._logService.log('attachment ' + this._key + ' loaded', data)
        if (data !== undefined) {
            this._loadStream.next(data)
        } else {
            // we need to send back empty data if collection had nothing
            if (this._isList) {
                this._loadStream.next(undefined)
            } else {
                this._loadStream.next(undefined)
            }
        }
        this._tickStream.next(data)
    }

    added(data: any) {
        this._logService.log('attachment ' + this._key + ' added', data)
        this._addStream.next(data)
        this._tickStream.next(data)
    }

    changed(data: any) {
        this._logService.log('attachment ' + this._key + ' changed', data)
        this._changeStream.next(data)
        this._tickStream.next(data)
    }

    removed(data: any) {
        this._logService.log('attachment ' + this._key + ' removed', data)
        this._removeStream.next(data)
        this._tickStream.next(data)
    }

    loadStream(): Subject<any> {
        return this._loadStream
    }

    addedStream(): Subject<any> {
        return this._addStream
    }

    changedStream(): Subject<any> {
        return this._changeStream
    }

    removedStream(): Subject<any> {
        return this._removeStream
    }
    onTick(): Subject<any> {
        return this._tickStream
    }
}
