import styled, { ThemeProps } from 'styled-components'
import { EDIT_BUTTON_HEIGHT } from '../../../providers/theme/GuslThemeProvider'
import { deviceMax } from '../../../styled'

export const AllPanelsContainerStyled = styled.div.attrs((props) => ({
    id: 'panels_container',
}))``

export const TablePanelStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.modal.innerBackground};
`

export const PanelContainerStyled = styled.div.attrs((props) => ({
    id: 'panel_container',
}))`
    color: ${(props) => props.theme.modal.innerBackground};
`

export const PaneListStyled = styled.div.attrs((props) => ({
    className: 'row g-0',
}))``

interface PanelProperties extends ThemeProps<any> {
    fxFlex?: number
    isDialog: boolean
    width?: string
    height?: string

    isEdit?: boolean
}

export const PanelWrapperStyled = styled.div.attrs((props: PanelProperties) => ({
    // className:'col-md-6 p-lg-3 p-xl-4',
}))<PanelProperties>`
    width: ${(props) => {
        if (props.width) {
            return props.width
        }
        if (props.fxFlex && props.fxFlex > 1) {
            if (props.fxFlex === 98) {
                return '100%'
            }
            return props.fxFlex + '%'
        } else if (props.isDialog) {
            return '100%'
        } else {
            return '50%'
        }
    }};
    ${(props) => props.height && 'height: ' + props.height + ';'};
    border: 1px solid ${(props) => props.theme.colors.dark[1]};

    @media only screen and (${deviceMax.tablet}) {
        width: 100%;
    }

    :not(:last-child) {
        // 28/08 nested tables
        //margin-right: 30px;
    }

    :not(:first-child) {
        margin-left: 20px;
    }

    // 28/08 nested tables
    //margin-left: 30px;

    :only-child {
        margin-left: 0;
        border: none;
    }
`

interface MaintainPanelProperties extends ThemeProps<any> {}

export const MaintainPanelWrapperStyled = styled.div.attrs((props: MaintainPanelProperties) => ({
    id: 'maintain_panel_wrapper',
}))<MaintainPanelProperties>`
    background-color: ${(props) => props.theme.colors.background[1]};
`

export const PanelStyled = styled.div.attrs((props: PanelProperties) => ({}))<PanelProperties>`
    background-color: ${(props) => props.theme.modal.innerBackground};
`

interface PanelTitleProperties extends ThemeProps<any> {}

interface PanelHeaderProperties extends ThemeProps<any> {
    hasTitle: boolean
    hasBanner: boolean
}

export const PanelHeaderWrapperStyled = styled.div.attrs((props: PanelHeaderProperties) => ({
    role: 'toolbar',
    className: 'row g-0 d-flex align-items-center justify-content-between ',
}))<PanelHeaderProperties>`
    background-color: ${(props) => (props.hasBanner ? props.theme.panel.subPanel.titleBgColor : 'inherit')};
    height: 50px;
    padding: ${(props) => props.theme.table.titlePadding};
`

export const PanelTitleStyled = styled.div.attrs((props: PanelTitleProperties) => ({}))<PanelTitleProperties>`
}

color: ${(props) => props.theme.panel.subPanel.titleColor}

;
font-size:${(props) => props.theme.panel.subPanel.titleFontSize}

;

`

export const PanelHeaderStyled = styled.div.attrs((props) => ({
    // className: 'col-md-4'
    className: 'row g-0 ',
}))`
    background-color: ${(props) => props.theme.panel.subPanel.titleBgColor};
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    // padding: 10px 0px 10px 10px;
`

export const MaintainPanelContainerStyled = styled.div.attrs((props: PanelProperties) => ({}))<PanelProperties>`
    ${(props) => props.height && 'height: calc(' + props.height + ' + ' + (props.isEdit ? '100' : '50') + ' + px);'};
`
export const MaintainPanelActionBarContainerStyled = styled.div.attrs((props) => ({}))``
export const MaintainPanelActionBarStyled = styled.div.attrs((props) => ({}))``
export const PanelMenuGroupStyled = styled.div.attrs((props) => ({
    className: 'col-md-12 d-flex justify-content-end align-items-center',
}))`
    // min-height:40px;
    // padding-right:20px;
`

export const DialogPanelStyled = styled.div.attrs((props) => ({
    id: 'dialog_panel',
}))`
    overflow: auto;

    .form-control {
        border: none;
        // border-bottom:  ${(props) => props.theme.form.fieldBorder} !important;
        box-shadow: none !important;
    }

    .autoNumeric-positive {
        color: var(--positive) !important;
    }

    .autoNumeric-negative {
        color: var(--negative) !important;
    }
`

interface PanelFormProperties {
    isEdit: boolean
    isMobile: boolean
}

/*
    // height: ${props => props.isEdit ? 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  277px)' : 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  237px)'};
  height: ${props => {
    // if (!props.isMobile) {
    //     return '3000px';
    // }
    return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.height + (props.isEdit ? 120 : 60)) + 'px)'
  }};
  overflow: scroll;

 */

/*
vvv forgottenDetails - new form
  ${props => props.isMobile && props.height && 'height: calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.height + (props.isEdit ? 120 : 60)) + 'px)'};

 */
export const PanelFormStyled = styled.form.attrs((props: PanelFormProperties) => ({
    id: 'panel_entry_form',
}))<PanelFormProperties>`
    height: 100%;
    ${(props) => props.isMobile && 'overflow: auto'};
`

interface PanelFormWrapperProperties {
    isMobile?: boolean
    isTradePanel?: boolean
    height?: string
    isEdit?: boolean
    startFormPos: number
    footerHeight: number
}

export const PanelFormWrapper = styled.div.attrs((props: PanelFormWrapperProperties) => ({
    className: '',
}))<PanelFormWrapperProperties>`
    // GBW 26/08 0 -> 30px customer edit bet
    padding: ${(props) => (props.isMobile ? '0px 3px 0px 3px' : '0 0 0 20px')};
    overflow: auto;
    max-height: ${(props) => {
        if (props.isTradePanel && props.isMobile) {
            return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.startFormPos + props.footerHeight) + 'px)'
        } else if (!props.isTradePanel && props.isMobile) {
            return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.startFormPos + props.footerHeight) + 'px)'
        } else if (props.height && props.isEdit) {
            return 'calc(' + props.height + ' - 120px);'
        } else if (props.height) {
            return 'calc(' + props.height + ' - 55px);'
        } else if (props.isMobile) {
            return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.startFormPos + props.footerHeight) + 'px)'
        } else if (props.isEdit) {
            // 28/08 42 -> 72 because of bottom padding -> 30
            return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.startFormPos + 10 + EDIT_BUTTON_HEIGHT) + 'px)'
        } else {
            // was -45 change to + 45 nova cust edit
            return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.startFormPos + 10) + 'px)'
        }
    }};

    ::-webkit-scrollbar {
        width: var(--scrollbar-width);
        height: var(--scrollbar-height);
    }
    ::-webkit-scrollbar-track {
        background: ${(props) => props.theme.table.panelBgColor};
    }
    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.dark[0]};
        border: 1px solid ${(props) => props.theme.colors.dark[1]};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${(props) => props.theme.table.titleBgColor};
    }
    .form-control:not(.lookup):not(.cron) {
        border: none;
        box-shadow: none !important;
    }

    .shadow {
        box-shadow: none !important;
    }
`

interface ActionButtonContainerProperties extends ThemeProps<any> {
    controlOnTop: boolean
}

export const ActionButtonContainerStyles = styled.div.attrs((props: ActionButtonContainerProperties) => ({
    className: 'col-md-12 d-flex ' + (props.controlOnTop ? 'justify-content-end' : ' justify-content-end '),
    id: 'action_button_container',
}))<ActionButtonContainerProperties>`
    display: inline-flex;
    margin-right: 20px;
    margin-top: ${(props) => (props.controlOnTop ? '10px' : '0px')};
    padding-bottom: ${(props) => (props.controlOnTop ? 'inherit' : '20px')};
    padding-top: ${(props) => (props.controlOnTop ? 'inherit' : '20px')};
    padding-right: 10px;
    // vvv GBW action dialog buttons at bottom
    ${(props) => !props.controlOnTop && 'position: relative;bottom: 0;'}
`

export const ErrorMessageStyled = styled.div.attrs((props) => ({
    className: 'invalid',
}))`
    margin: 10px 10px 10px 30px;
    font-size: 12px;
`

export const EditRowFormStyled = styled.form.attrs((props) => ({
    className: 'row g-0',
}))``
export const PopupDescriptionStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.panel.subPanel.titleColor};
    font-size: ${(props) => props.theme.panel.subPanel.titleFontSize};
`
export const NewEditModeStyled = styled.div.attrs((props) => ({
    id: 'new_edit',
}))``

interface MaintainNewContainerProperties {
    height: string | undefined
    width: string | undefined
}

export const MaintainNewContainerStyled = styled.div.attrs((props: MaintainNewContainerProperties) => ({
    className: 'col-md-12',
}))<MaintainNewContainerProperties>`
    ${(props) => props.height && 'height: ' + props.height};
    width: ${(props) => (props.width ? props.width : '400px')};
    overflow: auto;
    background-color: ${(props) => props.theme.colors.background[1]};
`
export const ErrorMessageWrapperStyled = styled.div.attrs((props) => ({}))`
    padding: 15px;
`

export const ReportWrapperStyled = styled.div.attrs((props) => ({}))`
    padding: 10px;
`
export const TableWrapperStyled = styled.div.attrs((props) => ({}))`
    padding: 10px;
`
