import React from 'react'
import { useAppDispatch } from '../../../../app/hooks'
import { ActionConfigDTO, TableRowDTO } from '../../../types'
import { TableRowStyled } from '../../gusl-table/styled/table/styled'
import MaintainForm from '../../maintain-form/MaintainForm'
import { maintainTableService } from '../MaintainTableService'
import { ColumnStyled } from '../styled'
import { closeAction, ShowActionType, toggleActionItem } from '../table-row/tableRowSlice'
import { ContainerStyled, InnerContainerStyled } from './styled'

interface InlineActionProps {
    code: string
    rowId: string
    row: TableRowDTO
    openCode: string
    openRowId: string | undefined
    colspan: number
    action: ActionConfigDTO | undefined
    performTableRefresh: () => void
    showActionModal: ShowActionType
    closeDialog: () => void
}

export const InlineActionPanel = ({
    code,
    rowId,
    colspan,
    action,
    row,
    performTableRefresh,
    showActionModal,
    openCode,
    openRowId,
    closeDialog,
}: InlineActionProps): React.ReactElement<InlineActionProps> => {
    const dispatch = useAppDispatch()

    if (!action || showActionModal !== ShowActionType.IN_LINE) {
        return <></>
    }

    const resetOpenInlineActionHandler = () => {
        dispatch(closeAction({ rowId })) // clean the action
        // dispatch(setOpenInlineActionId(""));
        // dispatch(setOpenInlineRowId(""));
    }

    if (openCode === code && openRowId !== rowId) {
        setTimeout(() => {
            dispatch(closeAction({ rowId })) // clean the action
        }, 100)
        return <></>
    }

    return (
        <TableRowStyled key={'id_' + rowId + '_' + action.id} id={'id_' + rowId + '_' + action.id}>
            <ColumnStyled colSpan={colspan} cellWidth={'auto'}>
                <ContainerStyled>
                    <InnerContainerStyled>
                        <MaintainForm
                            isDialog={false}
                            controlOnTop={true}
                            canEdit={maintainTableService.getCanEditForAction(action)}
                            formMode={maintainTableService.getFormModeForAction(action)}
                            data={{}}
                            rowData={{ ...row }}
                            menuItem={maintainTableService.convertToMenu(row, action)}
                            minModalWidth={action.minModalWidth}
                            minModalHeight={action.minModalHeight}
                            refreshTable={() => {
                                if (performTableRefresh) {
                                    performTableRefresh()
                                }
                            }}
                            closeDialog={() => {
                                if (performTableRefresh) {
                                    performTableRefresh()
                                }
                                dispatch(
                                    toggleActionItem({
                                        rowId,
                                        actionItem: undefined,
                                        showType: ShowActionType.NO_ACTION,
                                    })
                                )
                                resetOpenInlineActionHandler()
                            }}
                        />
                    </InnerContainerStyled>
                </ContainerStyled>
            </ColumnStyled>
        </TableRowStyled>
    )
}
