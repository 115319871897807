import React, { MouseEventHandler } from 'react'

export type IconProps = {
    icon?: string
    label?: string
    value?: string | number
    extraClass?: string
    isOnTop?: boolean
    title?: string
    onClick?: MouseEventHandler<HTMLElement>
}
export default function IconWrapper({ icon, label, value, extraClass, isOnTop, title, onClick }: IconProps) {
    return (
        <span
            className={
                'blinker_item_wrapper ' +
                (label ? ' d-flex justify-content-between align-items-center ' : ' ') +
                (isOnTop ? ' top_bar_icon ' : ' ') +
                extraClass
            }
            onClick={onClick}
            title={title}
        >
            <span>
                {/*ICON...;-)*/}
                <i className={icon} />
                {/*NUMBER*/}
                {value ? (
                    <sup>
                        <span className={'blinker_badge'}>{value}</span>
                    </sup>
                ) : (
                    <></>
                )}
            </span>
            {/*TEXT NEXT TO THE ICON*/}
            {label ? <span className={'ms-2'}>{label}</span> : <></>}
        </span>
    )
}
