import styled, { ThemeProps } from 'styled-components'

export const OrderByActionWrapperStyled = styled.div.attrs((props) => ({
    id: 'order_by_actions_wrapper',
}))`
    display: inline-block;
`

export interface OrderByActionProperties extends ThemeProps<any> {
    active?: boolean | undefined
    isMobile: boolean
}

export const OrderByActionStyled = styled.i.attrs((props: OrderByActionProperties) => ({}))<OrderByActionProperties>`
    color: ${(props) => (props.active ? props.theme.money.positiveColor : props.theme.colors.dark[1])};
    margin-right: ${(props) => (props.isMobile ? '15px' : '5px')};
`

export const TableControlsStyled = styled.div.attrs((props) => ({}))`
    margin-bottom: 5px;
    padding: 0 10px;
`
