import React, { useContext, useState } from 'react'
import { BlastContext } from '../../../providers/blast/BlastContext'
import { SessionContext } from '../../../providers/session/SessionContext'
import { getStyle } from '../../../utils/CssUtils'
import { assignReferences, RunOnceEffect } from '../../../utils/Utils'
import { FieldProperties, FormMode } from '../../types'
import { HtmlFieldStyled } from './styled'

export const HtmlField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const sessionContext = React.useContext(SessionContext)
    const blastContext = useContext(BlastContext)
    const [formMode, setFormMode] = useState(properties.formMode)
    const [formValue, setFormValue] = useState<string>(properties.data || '')

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
    }

    const onRefresh = () => {}

    const doValidation = (fieldValue: any): boolean => {
        return true
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, onRefresh, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.fieldConfig.tableCss, '')

        return (
            <HtmlFieldStyled id={'html_' + properties.fieldConfig.name}>
                <div style={style} dangerouslySetInnerHTML={{ __html: formValue }}></div>
            </HtmlFieldStyled>
        )
    }

    const renderFormView = (): React.ReactElement => {
        // not implemented
        return <></>
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
