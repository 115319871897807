import { CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js'
import React, { useEffect, useRef, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { log } from '../../../services/LogService'
import { safeStream } from '../../../utils/Utils'
import ErrorMsg from '../../ui/ErrorMsg'
import { ChartProps } from '../types'
// @ts-ignore
import PrepareChartData from '../utils/PrepareChartData'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Title)

export default function LineChart({ endpoint, serverData, chartFor }: ChartProps) {
    const [className] = useState('LineChart-' + new Date().getTime())
    const chartRef = useRef<ChartJS>(null)
    const [chartData, setChartData] = useState({ datasets: [], labels: [] })
    const _options = serverData?.chartOptions?.line
    const [options, setOptions] = useState(_options)
    const _height = serverData?.chartOptions?.line?.chart?.canvas.height
    const [height, setHeight] = useState<string | number | undefined>(_height)

    try {
        useEffect(() => {
            const chart = chartRef.current
            if (!chart) {
                return
            }

            const { datasets, options, labels, canvasHeight } = PrepareChartData({
                chartType: 'line',
                endpoint,
                serverData,
                chart,
                chartFor,
            })
            // used by position charts
            safeStream(datasets).forEach((dataset) => (dataset.fill = false))

            // @ts-ignore
            setChartData({ labels, datasets })
            setOptions(options)
            setHeight(canvasHeight)
        }, [chartFor, JSON.stringify(serverData)])
        //log.info(className, 'MSG001', {height})

        // @ts-ignore
        return <Line ref={chartRef} data={chartData} options={options} type={'line'} height={height} /> /*height={height}*/
    } catch (e) {
        log.error(className, 'MSG001', { endpoint })
        return <ErrorMsg />
    }
}
