import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { clone } from '../../../utils/Utils'
import { ActionConfigDTO, TableRowDTO } from '../../types'

export interface ActionState {
    showActionModal: boolean
    activeAction: ActionConfigDTO | undefined
    openModalActionId: string | undefined
    openModalRowId: string | undefined
    openInlineActionId: string | undefined
    openInlineRowId: string | undefined
    openExpandedRowId: string | undefined
    selectedRows: { [id: string]: TableRowDTO } | undefined
}

const initialState: ActionState = {
    showActionModal: false,
    activeAction: undefined,
    openModalActionId: undefined,
    openModalRowId: undefined,
    openInlineActionId: undefined,
    openInlineRowId: undefined,
    openExpandedRowId: undefined,
    selectedRows: undefined,
}

export interface ActiveActionPayload {
    action: ActionConfigDTO
    selectedRows?: { [id: string]: TableRowDTO }
    refreshTable?: () => void
}

export const actionSlice = createSlice({
    name: 'actionSlice',
    initialState,
    reducers: {
        hideAction(state, action: PayloadAction<boolean>) {
            state.showActionModal = false // action.payload;
            state.activeAction = undefined
        },

        setActiveAction(state, action: PayloadAction<ActiveActionPayload>) {
            const clonedAction = clone(action.payload.action)
            clonedAction.refreshTable = action.payload.refreshTable
            state.activeAction = clonedAction
            state.selectedRows = clone(action.payload.selectedRows)
            state.showActionModal = true
        },
        cleanUpActiveAction(state, action: PayloadAction<void>) {
            // state.activeAction = undefined;
            // state.showActionModal = false;
        },
        setOpenModalActionId(state, action: PayloadAction<string | undefined>) {
            state.openModalActionId = action.payload
        },
        setOpenModalRowId(state, action: PayloadAction<string | undefined>) {
            state.openModalRowId = action.payload
        },
        setOpenInlineActionId(state, action: PayloadAction<string | undefined>) {
            state.openInlineActionId = action.payload
        },
        setOpenInlineRowId(state, action: PayloadAction<string | undefined>) {
            state.openInlineRowId = action.payload
        },
        setOpenExpandedRowId(state, action: PayloadAction<string | undefined>) {
            state.openExpandedRowId = action.payload
        },
    },
})

export const {
    hideAction,
    setActiveAction,
    cleanUpActiveAction,
    setOpenModalActionId,
    setOpenModalRowId,
    setOpenInlineActionId,
    setOpenInlineRowId,
    setOpenExpandedRowId,
} = actionSlice.actions

export default actionSlice.reducer
