import React, { useState } from 'react'
import { log } from '../../../../../../services/LogService'
import {
    InputGroupIconStyled,
    InputGroupIconWrapperStyled,
    InputGroupInputStyled,
    InputGroupResultsWrapperStyled,
} from '../../../styled_v2'
import { noop } from '../../../../../../utils/Utils'

export interface IndicesTable {}

export default function IndicesTable({}: IndicesTable) {
    const [className] = useState('IndicesTable-' + new Date().getTime())

    return (
        <div>
            <div className={'w-100'}>
                <div className="input-group mb-3">
                    <InputGroupIconWrapperStyled>
                        <InputGroupIconStyled className={'fa-solid fa-search'} />
                    </InputGroupIconWrapperStyled>
                    <InputGroupInputStyled
                        type="text"
                        id={'tickerSearch'}
                        autoFocus={true}
                        onChange={(e) => {
                            noop()
                        }}
                        placeholder="Search..."
                        aria-label="Search"
                        aria-describedby="tickerSearch"
                    />
                    <InputGroupResultsWrapperStyled>
                        <small className={'xs-small'}>0 results</small>
                    </InputGroupResultsWrapperStyled>
                </div>
            </div>
            <div className="table-responsive mt-3" style={{ height: 400 }}>
                <table className={'table table-sm table-striped'}></table>
            </div>
            <p className="text-center">Indices List Coming Soon...</p>
        </div>
    )
}
