import React, { useState } from 'react'
import { FieldConfigDTO, FormMode, TableRowDTO } from '../../types'
import Report from '../report/Report'
import { ReportDO, ReportProperties } from '../report/types'

type GuslReportProperties = {
    code: string
    selectUrl?: string
    data?: ReportDO
    label?: string

    rowData?: TableRowDTO

    reLoad?: () => void
}

export const GuslReport = ({ code, selectUrl, data, label, rowData, reLoad }: GuslReportProperties): React.ReactElement => {
    const reportProperties = {
        code: code,
        formMode: FormMode.VIEW,
        fieldConfig: {
            name: code,
            label: label,
            type: 'report',
        } as FieldConfigDTO,
        menuItem: undefined,
        isTableView: false,
        // data?: any;
        reportData: data,
        rowData: rowData,
        reLoad: reLoad,
    } as ReportProperties

    return (
        <>
            <Report key={code} {...reportProperties}></Report>
        </>
    )
}
