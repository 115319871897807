import { Capacitor } from '@capacitor/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { setResized } from '../../../../features/flagSlice'
import { RELEASE_DETAILS } from '../../../../features/release-details'
import { AppSeal } from '../../../../layouts/mercury-layout/app-seal/AppSeal'
import { Legals } from '../../../../layouts/mercury-layout/legals/Legals'
import { StyledFooter } from '../../../../layouts/mobile-layout/styled'
import { EnvironmentContext } from '../../../../providers/environment/EnvironmentContext'
import { environmentService } from '../../../../providers/environment/EnvironmentService'
import { AppSealDTO, LegalsDTO } from '../../../../providers/environment/types'
import { SessionContext } from '../../../../providers/session/SessionContext'
import { GuslUser, UserCohortsDTO } from '../../../../providers/session/types'
import { SystemContext } from '../../../../providers/system/SystemContext'
import {
    GuslThemeContext,
    HEADER_HEIGHT,
    CONTENT_START_POSITION,
    MOBILE_HEADER_OFFSET,
} from '../../../../providers/theme/GuslThemeProvider'
import { notBlank } from '../../../../utils/TypeCheckers'
import {
    addImageSuffix,
    constructUrl,
    haveChildrenWithMediaTypeWithRequiredAndSensitivity,
    matchMediaTypeWithRequiredAndSensitivity,
    RunOnceEffect,
    unSubscribe,
} from '../../../../utils/Utils'
import { CohortSelector } from '../../../cohort-selector/CohortSelector'
import { ElementWithOffCanvas } from '../../../common/element-with/element-with-offcanvas/ElementWithOffCanvas'
import ElementWithPopover from '../../../common/element-with/element-with-popover/ElementWithPopover'
import { GuslReport } from '../../../common/gusl-report/GuslReport'
import { HeaderItemStyled } from '../../../common/tabbed-page/styled'
import { PowerOfAttorneySelector } from '../../../power-of-attorney/PowerOfAttorneySelector'
import { HeaderDTO, IMenuDTO, MediaType } from '../../../types'
import UserInfo from '../../UserInfo'
import MenuGroup from '../left-hand-menu/MenuGroup'
import MainToolbarDropdown from './MainToolbarDropdown'
import {
    BlankHamburgerStyled,
    GradientLineStyled,
    HamburgerStyled,
    HamburgerWrapperStyled,
    HeaderInfoStyled,
    LeftHandMenuContainerStyled,
    LeftHandMenuContentsStyled,
    LeftHandMenuTitleStyled,
    LeftHandMenuWrapperStyled,
    LogoWrapperStyledStyled,
    MobileHeaderInfoStyled,
    MobileHeaderItemStyled,
    MobileLogoImageStyled,
    MobileLogoImageStyledLarge,
    MobileLogoStyled,
    MobileMainHeaderEmbeddedStyled,
    MobileMainHeaderStyled,
    MobileNavbarStyled,
    MobileToolbarMenuStyled,
    ThreeDotsStyled,
    TopBarStyled,
    UserIconStyled,
    UserNameStyled,
} from './styled'
import { MainToolbarProperties } from './types'
import sortByDisplayOrder from './utils/sortByDisplayOrder'

const MainToolbarMobile = (props: MainToolbarProperties): React.ReactElement => {
    const [className] = useState('MobileNav-' + new Date().getTime())

    const dispatch = useAppDispatch()

    const systemContext = useContext(SystemContext)
    const sessionContext = useContext(SessionContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const guslThemeContext = React.useContext(GuslThemeContext)

    const isMobile = environmentContext.isMobileDevice()
    const [isIOS] = useState<boolean>(environmentContext.isNativeIOS())

    const [mediaType] = useState<MediaType>(environmentContext.getCurrentMediaType())
    const [mediaTypeSensitive, setMediaTypeSensitive] = useState<boolean>(false)

    const [refreshCounter, setRefreshCounter] = useState<number>(0)

    const [hideHamburger, setHideHamburger] = useState<boolean>(environmentService.getEnvironment()?.hideHamburger || false)
    const [noMobileLogo, setNoMobileLogo] = useState<boolean>(environmentService.getEnvironment()?.noMobileLogo || false)
    const headerInfoElement = useRef(null)
    const resized = useAppSelector((state) => state.flagSlice.resized)

    const [userCohorts, setUserCohorts] = useState<UserCohortsDTO | undefined>(undefined)
    const [showPowerOfAttorney] = useState<boolean>(() =>
        matchMediaTypeWithRequiredAndSensitivity(
            environmentService.getEnvironment()?.showPowerOfAttorney,
            environmentContext.getCurrentMediaType(),
            true
        )
    )
    const [showCohortSelector] = useState<boolean>(() =>
        matchMediaTypeWithRequiredAndSensitivity(
            environmentService.getEnvironment()?.showCohortSelector,
            environmentContext.getCurrentMediaType(),
            true
        )
    )
    const [hasPowerOfAttorney] = useState<boolean>(sessionContext.hasPowerOfAttorney())

    const [haveToolbarMenuGroups, setHaveToolbarMenuGroups] = useState<boolean>(false)
    const [toolbarMenus] = useState<IMenuDTO[]>(() => {
        let haveSticky = false
        ;(systemContext.getSystemConfig()?.toolbarMenus || [])
            .filter((group: IMenuDTO) => group.mobileTopSticky)
            .forEach(() => (haveSticky = true))
        setHaveToolbarMenuGroups(haveSticky)
        return sortByDisplayOrder(systemContext.getSystemConfig()?.toolbarMenus || [])
    })

    const [headerInfoWidth, setHeaderInfoWidth] = useState<number>(() => {
        let haveSticky = false
        ;(systemContext.getSystemConfig()?.toolbarMenus || [])
            .filter((group: IMenuDTO) => group.mobileTopSticky)
            .forEach(() => (haveSticky = true))
        // MK 02/10/2023
        // return 320 - (haveSticky ? 80 : 0)
        return window.innerWidth - (haveSticky ? 80 : MOBILE_HEADER_OFFSET)
    })

    const [ultraNarrowDevice, setUltraNarrowDevice] = useState<boolean>(false)

    const [menuGroups] = useState<IMenuDTO[]>(() => {
        const items: IMenuDTO[] = sortByDisplayOrder([...(systemContext.getSystemConfig()?.menuGroups || [])])
        // setMediaTypeSensitive(areMenusMediaTypeSensitive(items))
        setMediaTypeSensitive(true) // alar lhm including group with no children
        return items
    })

    const [smallLogoUrl] = useState<string>(() => {
        if (environmentService.getEnvironment()?.mobileLogoUrl) {
            return (
                addImageSuffix(
                    environmentService.getEnvironment()?.mobileLogoUrl,
                    guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).imageSuffix
                ) || ''
            )
        }
        console.warn(className, 'MSG001', "WE DON'T HAVE SMALL LOGO URL", {
            'BO environmentService.getEnvironment()?.mobileLogoUrl': environmentService.getEnvironment()?.mobileLogoUrl,
        })
        return (
            addImageSuffix(
                environmentService.getEnvironment()?.logoUrl,
                guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).imageSuffix
            ) || ''
        )
    })
    const [largeLogoUrl] = useState<string>(() => {
        return (
            addImageSuffix(
                environmentService.getEnvironment()?.logoUrl,
                guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).imageSuffix
            ) || ''
        )
    })
    const [homePage] = useState<string>(() => {
        return environmentService.getEnvironment()?.homePage || ''
    })

    const [headerUrl] = useState<string>(() => {
        return environmentService.getEnvironment()?.headerURL || ''
    })

    const [headerData, setHeaderData] = useState<HeaderDTO | undefined>(undefined)
    const code = 'Position'
    const [defaultRefreshRate, setDefaultRefreshRate] = useState<number>(-1)

    const [isSideNavOpen, setSideNavOpen] = useState<boolean>(false)
    const [nickname, setNickname] = useState<string>('')
    const [contentStartPos, setContentStartPos] = useState<number>(CONTENT_START_POSITION + HEADER_HEIGHT)
    const contentElement = useRef(null)
    const userInfoElement = useRef(null)
    const [userInfoHeight, setUserInfoHeight] = useState<number>(HEADER_HEIGHT)

    const [footerHeight, setFooterHeight] = useState<number>(0)

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                if (contentElement) {
                    // @ts-ignore
                    const rect = contentElement?.current?.getBoundingClientRect()
                    if (rect) {
                        setContentStartPos(rect.y)
                    }
                }
            }, 100)
        })
    }, [isSideNavOpen])

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                if (userInfoElement) {
                    // @ts-ignore
                    const rect = userInfoElement?.current?.getBoundingClientRect()
                    if (rect) {
                        setUserInfoHeight(rect.height)
                    }
                }
            }, 100)
        })
    }, [isSideNavOpen])

    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription)
        }
    })

    RunOnceEffect(() => {
        let sideMenuSubscription: Subscription = environmentService.watchSideMenu().subscribe((menuOpen: boolean) => {
            setSideNavOpen(menuOpen)
        })
        const loginSubscription = sessionContext.watchLogin().subscribe((user: GuslUser | undefined | null) => {
            if (user) {
                if (notBlank(user.nickname)) {
                    setNickname(user.nickname || '')
                } else {
                    setNickname(user.username || '')
                }
                if (user?.userCohorts) {
                    setUserCohorts(user.userCohorts)
                }
            }
        })
        return () => {
            unSubscribe(sideMenuSubscription)
            unSubscribe(loginSubscription)
        }
    })

    RunOnceEffect(() => {
        const subscriptionResize = environmentContext.watchResize().subscribe(() => {
            dispatch(setResized(true))
        })
        return () => {
            unSubscribe(subscriptionResize)
        }
    })

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                // @ts-ignore
                if (headerInfoElement?.current?.offsetHeight) {
                    // @ts-ignore
                    //const rect = headerInfoElement?.current?.getBoundingClientRect();

                    // MK 02/10/2023 keep it simple...
                    setHeaderInfoWidth(window.innerWidth - MOBILE_HEADER_OFFSET)
                    // MK 02/10/2023 keep it simple...
                    // if (rect) {
                    //
                    //     let width = rect.width - 50;
                    //     if (width < 320) {
                    //         width = 310 // 270;
                    //         setUltraNarrowDevice(true)
                    //     }
                    //     if (window.innerWidth < 320) {
                    //         width = window.innerWidth // - 50
                    //         setUltraNarrowDevice(true)
                    //     }
                    //     // - 50
                    //     setHeaderInfoWidth(haveToolbarMenuGroups ? (width - 10) : width)
                    // } else {
                    //     setHeaderInfoWidth(haveToolbarMenuGroups ? (320 - 50) : 320)
                    // }
                }
            }, 50)
        })
    }, [resized])

    const getHeaderData = () => {
        sessionContext
            .get<HeaderDTO>(constructUrl(headerUrl, { id: code }))
            .then((response) => {
                setHeaderData(response.data)
                setRefreshCounter(new Date().getTime())
                if (response.data.refreshRate) {
                    if (defaultRefreshRate === -1 && response.data.refreshRate !== -1) {
                        setDefaultRefreshRate(response.data.refreshRate * 1000)
                    }
                }
            })
            .catch((error) => {
                console.error('Error in getting header', error)
            })
    }

    useEffect(() => {
        setHideHamburger(environmentService.getEnvironment()?.hideHamburger || false)
        setNoMobileLogo(environmentService.getEnvironment()?.noMobileLogo || false)

        if (headerUrl) {
            getHeaderData()
        }
    }, [headerUrl, sessionContext])

    useEffect(() => {
        let subscription: Subscription | undefined = undefined
        if (defaultRefreshRate > 0) {
            subscription = sessionContext.watchRefreshRate().subscribe((nextRefreshRate: number) => {
                if (nextRefreshRate !== -1 && defaultRefreshRate === nextRefreshRate) {
                    getHeaderData()
                }
            })
            sessionContext.registerRefreshRate(defaultRefreshRate)
        }
        return () => {
            unSubscribe(subscription)
            sessionContext.unregisterRefreshRate(defaultRefreshRate)
        }
    }, [defaultRefreshRate])

    const isOnlyUserMenu = () => {
        return toolbarMenus.length === 1 && toolbarMenus[0].label === 'User'
    }

    const renderLogo = (menuOpen: boolean): React.ReactElement => {
        return (
            <LogoWrapperStyledStyled isIOS={isIOS}>
                {!menuOpen && !environmentService?.getEnvironment()?.noMobileLogo && (
                    <NavLink to={'/' + homePage}>
                        <MobileLogoImageStyled src={smallLogoUrl} alt="Logo" />
                    </NavLink>
                )}
                {menuOpen && (
                    <NavLink to={'/' + homePage}>
                        <MobileLogoImageStyledLarge src={largeLogoUrl} alt="Logo" />
                    </NavLink>
                )}
            </LogoWrapperStyledStyled>
        )
    }

    const renderHeaderAsEmbedded = (): React.ReactElement => {
        if (!headerData || !headerData.info) {
            return <></>
        }
        return (
            <MobileMainHeaderEmbeddedStyled
                key={code + '_' + refreshCounter + '_hdr'}
                id={'header-info'}
                ultraNarrowDevice={ultraNarrowDevice}
            >
                {headerData && headerData.info && headerUrl && (
                    <MobileHeaderItemStyled key={code}>
                        <GuslReport code={code} key={code + '_' + refreshCounter + '_rep'} data={{ ...headerData.info }} />
                    </MobileHeaderItemStyled>
                )}
            </MobileMainHeaderEmbeddedStyled>
        )
    }

    const renderUserName = (): React.ReactElement => {
        if (!environmentService.getEnvironment()?.withNameInMobileToolbar) {
            return <></>
        }
        return <UserNameStyled ultraNarrowDevice={ultraNarrowDevice}>{nickname}</UserNameStyled>
    }

    const renderToolbarMenus = (): React.ReactElement => {
        if (!haveToolbarMenuGroups) {
            return <></>
        }
        return (
            <MobileToolbarMenuStyled>
                {toolbarMenus
                    .filter((toolbarMenu) => !toolbarMenu.footer)
                    .filter((menuGroup) => matchMediaTypeWithRequiredAndSensitivity(menuGroup.mediaType, mediaType, true))
                    .filter((menuGroup) => menuGroup.mobileTopSticky)
                    .map((toolbarMenu, idx: any) => (
                        <MainToolbarDropdown key={'toolbar_' + idx} menuGroup={[toolbarMenu]} />
                    ))}
            </MobileToolbarMenuStyled>
        )
    }

    const renderHeaderAsNewRow = (): React.ReactElement => {
        return (
            <div className={'row'}>
                <div className={'d-flex align-items-center'}>
                    <MobileHeaderInfoStyled>
                        <MobileMainHeaderStyled>
                            {headerData && headerData.info && headerUrl && (
                                <HeaderItemStyled>
                                    <GuslReport code={code} data={headerData.info} />
                                </HeaderItemStyled>
                            )}
                        </MobileMainHeaderStyled>
                    </MobileHeaderInfoStyled>
                </div>
            </div>
        )
    }

    if (!environmentContext.isMobileDevice()) {
        return <></>
    }

    const renderHamburger = (): React.ReactElement => {
        if (hideHamburger && isMobile) {
            return (
                <BlankHamburgerStyled hideHamburger={hideHamburger} noMobileLogo={noMobileLogo}>
                    &nbsp;
                </BlankHamburgerStyled>
            )
        }
        return (
            <HamburgerWrapperStyled id={'mob-burger'}>
                <HamburgerStyled
                    $isSideNavOpen={isSideNavOpen}
                    onClick={() => {
                        environmentService.toggleSideMenu()
                    }}
                />
            </HamburgerWrapperStyled>
        )
    }

    const onOffCanvassClose = () => {
        environmentService.closeSideMenu()
    }

    const onOffCanvassOpen = () => {
        environmentService.openSideMenu()
    }

    const renderUserMenu = (): React.ReactElement => {
        return (
            <div className="ms-auto" style={{ maxWidth: 30 }}>
                {isOnlyUserMenu() ? (
                    <ElementWithPopover
                        placement={'auto'}
                        trigger={'click'}
                        element={<UserIconStyled />}
                        popover={
                            <>
                                <UserInfo />
                                {toolbarMenus.map((toolbarMenu: IMenuDTO, idx: any) => (
                                    <MainToolbarDropdown key={idx} menuGroup={[toolbarMenu]} isMobile={true} />
                                ))}
                            </>
                        }
                    />
                ) : (
                    <ElementWithOffCanvas
                        onClose={onOffCanvassClose}
                        key={2}
                        title={
                            <div className="d-flex align-items-center justify-content-between">
                                <UserInfo />
                                <ThreeDotsStyled $isSideNavOpen={true} angleDirection={'right'} isRight={true} />
                            </div>
                        }
                        element={
                            <div className={'d-flex align-items-center justify-content-end me-1'}>
                                {isOnlyUserMenu() ? <UserIconStyled /> : <ThreeDotsStyled $isSideNavOpen={false} isRight={true} />}
                            </div>
                        }
                        content={
                            <>
                                {toolbarMenus
                                    .filter((toolbarMenu: IMenuDTO) => !toolbarMenu.footer)
                                    .map((toolbarMenu: IMenuDTO, idx: any) => (
                                        <MainToolbarDropdown key={idx} menuGroup={[toolbarMenu]} isMobile={true} />
                                    ))}
                            </>
                        }
                        placement={'end'}
                    />
                )}
            </div>
        )
    }

    const renderAppSeal = (): React.ReactElement => {
        const appSeal: AppSealDTO | undefined = environmentService.getEnvironment()?.appSeal
        if (appSeal) {
            return <AppSeal {...appSeal} />
        }
        return <></>
    }

    const renderLegals = (): React.ReactElement => {
        const legals: LegalsDTO | undefined = environmentService.getEnvironment()?.legals
        if (legals) {
            legals.horizontalLayout = false
            legals.label = ''
            return <Legals {...legals} />
        }
        return <></>
    }
    const renderUserItems = (): React.ReactElement => {
        return (
            <>
                {toolbarMenus
                    .filter((toolbarMenu: IMenuDTO) => !toolbarMenu.footer)
                    .filter((toolbarMenu: IMenuDTO) => !toolbarMenu.mobileTopSticky)
                    .map((toolbarMenu: IMenuDTO, idx: any) => (
                        <MainToolbarDropdown key={idx} menuGroup={[toolbarMenu]} isMobile={true} />
                    ))}
            </>
        )
    }

    const renderPowerOfAttorney = (): React.ReactElement => {
        // console.log(`renderPowerOfAttorney showPowerOfAttorney ${showPowerOfAttorney} hasPowerOfAttorney ${hasPowerOfAttorney}`)
        if (props.tandCUpgradeRequired || !hasPowerOfAttorney || !showPowerOfAttorney) {
            return <></>
        }
        return <PowerOfAttorneySelector />
    }
    const renderCohortSelector = (): React.ReactElement => {
        // console.log(`renderPowerOfAttorney showCohortSelector ${showCohortSelector}`, userCohorts)
        if (props.tandCUpgradeRequired || !userCohorts || !showCohortSelector) {
            return <></>
        }
        return <CohortSelector userCohorts={userCohorts} />
    }

    const renderMenuFooter = (): React.ReactElement => {
        const platform = Capacitor?.getPlatform() || 'uk'
        const native = Capacitor?.isNativePlatform() || 'false'
        return (
            <>
                <StyledFooter isMobile={true}>
                    <div>
                        Release: {RELEASE_DETAILS.releaseNumber} ({platform}/{native})
                    </div>
                </StyledFooter>
            </>
        )
    }

    return (
        <>
            <MobileNavbarStyled key={'mobile_navbar_' + resized} id={'mobile_navbar'} ref={headerInfoElement}>
                <TopBarStyled id={'mobile_topbar'}>
                    <LeftHandMenuContainerStyled id={'mobile_lhmcs'}>
                        <ElementWithOffCanvas
                            onClose={onOffCanvassClose}
                            onOpen={onOffCanvassOpen}
                            toggleSubject={environmentService.watchSideMenu()}
                            key={'1'}
                            title={
                                <LeftHandMenuTitleStyled hideHamburger={hideHamburger} noMobileLogo={noMobileLogo} id={'main-mobile-nav'}>
                                    {renderHamburger()} {renderLogo(true)}
                                </LeftHandMenuTitleStyled>
                            }
                            element={
                                hideHamburger ? (
                                    <BlankHamburgerStyled hideHamburger={hideHamburger} noMobileLogo={noMobileLogo}>
                                        &nbsp;
                                    </BlankHamburgerStyled>
                                ) : (
                                    <HamburgerWrapperStyled id={'hm'}>
                                        <HamburgerStyled $isSideNavOpen={false} />
                                    </HamburgerWrapperStyled>
                                )
                            }
                            content={
                                <LeftHandMenuWrapperStyled isMobile={true}>
                                    <div ref={userInfoElement} id={'uiw'}>
                                        <UserInfo />
                                    </div>
                                    <LeftHandMenuContentsStyled
                                        id={'lhmcs'}
                                        ref={contentElement}
                                        isMobile={true}
                                        startPos={contentStartPos}
                                        userInfoHeight={userInfoHeight}
                                        footerHeight={footerHeight}
                                    >
                                        {renderCohortSelector()}
                                        {renderPowerOfAttorney()}
                                        {menuGroups
                                            // .filter(menuGroup => {
                                            //     console.log(`menu group ${menuGroup.label}  SB:${menuGroup.showInSideBar} MT: ${menuGroup.mediaType} Act-MT: ${mediaType} MSense: ${mediaTypeSensitive} passes: ${matchMediaTypeWithRequiredAndSensitivity(menuGroup.mediaType, mediaType, mediaTypeSensitive)} notFooter: ${!menuGroup.footer}`)
                                            //     return true;
                                            // })
                                            .filter((menuGroup) => menuGroup.showInSideBar)
                                            .filter((menuGroup) =>
                                                matchMediaTypeWithRequiredAndSensitivity(menuGroup.mediaType, mediaType, mediaTypeSensitive)
                                            )
                                            .filter((menuGroup) =>
                                                haveChildrenWithMediaTypeWithRequiredAndSensitivity(
                                                    menuGroup,
                                                    mediaType,
                                                    mediaTypeSensitive
                                                )
                                            )
                                            .filter((menuGroup) => !menuGroup.footer)
                                            .map((menuGroup, idx: number) => (
                                                <MenuGroup
                                                    isMobile={true}
                                                    menuGroup={menuGroup}
                                                    idx={idx}
                                                    key={'mmg_' + idx}
                                                    mediaTypeSensitive={mediaTypeSensitive}
                                                />
                                            ))}
                                        {renderUserItems()}
                                        {renderLegals()}
                                        {renderAppSeal()}
                                    </LeftHandMenuContentsStyled>
                                    {renderMenuFooter()}
                                </LeftHandMenuWrapperStyled>
                            }
                            placement={'start'}
                        />
                    </LeftHandMenuContainerStyled>
                    <MobileLogoStyled hideHamburger={hideHamburger} id={'mobile_closed_log'} noMobileLogo={noMobileLogo}>
                        {renderLogo(false)}
                    </MobileLogoStyled>
                    <HeaderInfoStyled id={'mobile_header'} className={environmentContext.getDeviceClassname()} width={headerInfoWidth}>
                        {renderHeaderAsEmbedded()}
                    </HeaderInfoStyled>
                </TopBarStyled>

                {!props.tandCUpgradeRequired && renderToolbarMenus()}
                {renderUserName()}
            </MobileNavbarStyled>
            <GradientLineStyled>
                <span />
            </GradientLineStyled>
        </>
    )
}
export default MainToolbarMobile
