import styled, { ThemeProps } from 'styled-components'

export interface TabsProperties extends ThemeProps<any> {
    isMobile: boolean
}

export interface TableHeaderProperties extends ThemeProps<any> {
    align?: string
    col?: string
}

interface TabsContainer {
    isMobile: boolean
}

export const TabsContainerStyled = styled.div.attrs((props: TabsContainer) => ({}))<TabsContainer>`
    width: 100%;
    margin: 0px auto;
    text-align: ${(props) => (props.isMobile ? 'center' : 'left')};
`

export const NoTabsStyledWrapperStyled = styled.div.attrs((props: TabsProperties) => ({}))<TabsProperties>`
    border-bottom: ${(props) => (props.isMobile ? 'none' : '1px solid ' + props.theme.colors.dark[0])};
`

/*todo wrapper around tabs use this*/
export const TabsStyledWrapperStyled = styled.div.attrs((props: TabsProperties) => ({
    className: 'd-flex justify-content-start align-items-center',
}))<TabsProperties>`
    border-bottom: ${(props) => (props.isMobile ? 'none' : '1px solid ' + props.theme.colors.dark[0])};
    //margin-top: 20px;
    //margin-left: 20px;
    //margin-right: 20px;
    text-align: center;
    padding: 10px;
    overflow-x: auto;

    ::-webkit-scrollbar {
        width: var(--scrollbar-width);
        height: var(--scrollbar-height);
    }
    ::-webkit-scrollbar-track {
        background: ${(props) => props.theme.table.panelBgColor};
    }
    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.dark[0]};
        border: 1px solid ${(props) => props.theme.colors.dark[1]};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${(props) => props.theme.table.titleBgColor};
    }
`
export const TabStyled = styled.div.attrs((props: TabProperties) => ({}))<TabProperties>`
    font-size: 14px;

    border-bottom-left-radius: ${(props) => {
        if (!props.isMobile) {
            return 'none'
        }
        if (props.isFirst) {
            return '10px'
        }
        if (props.isLast) {
            return 'none'
        }
    }};

    border-top-left-radius: ${(props) => {
        if (!props.isMobile) {
            return 'none'
        }
        if (props.isFirst) {
            return '10px'
        }
        if (props.isLast) {
            return 'none'
        }
    }};

    border-bottom-right-radius: ${(props) => {
        if (!props.isMobile) {
            return 'none'
        }
        if (props.isFirst) {
            return 'none'
        }
        if (props.isLast) {
            return '10px'
        }
    }};

    border-top-right-radius: ${(props) => {
        if (!props.isMobile) {
            return 'none'
        }
        if (props.isFirst) {
            return 'none'
        }
        if (props.isLast) {
            return '10px'
        }
    }};

    border-top: ${(props) => {
        if (!props.isMobile) {
            return 'none'
        }
        return '2px solid ' + props.theme.colors.light
    }};

    border-bottom: ${(props) => {
        if (!props.isMobile) {
            return 'none'
        }
        return '2px solid ' + props.theme.colors.light
    }};

    border-right: ${(props) => {
        if (!props.isMobile || !props.isLast) {
            return 'none'
        }
        return '2px solid ' + props.theme.colors.light
    }};

    border-left: ${(props) => {
        if (!props.isMobile) {
            return 'none'
        }
        return '2px solid ' + props.theme.colors.light
    }};

    :hover {
        cursor: pointer;
        border-bottom: ${(props) => 'solid 2px ' + props.theme.colors.light};
    }

    color: ${(props) => (props.active ? props.theme.colors.light : props.theme.colors.dark[1])};

    margin-right: ${(props) => (props.isMobile ? '0px' : '10px')};
    padding: ${(props) => (props.isMobile ? '0px 10px 0px 10px' : '0px')};

    line-height: ${(props) => (props.isMobile ? '35px' : 'inherit')};

    padding-bottom: ${(props) => (props.isMobile ? 'inherit' : '5px')};
    border-bottom: ${(props) => {
        if (props.isMobile) {
            return '2px solid ' + props.theme.colors.light
        }
        return 'solid 2px ' + (props.active ? props.theme.colors.light : 'transparent')
    }};

    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};

    display: inline-block;
`

export const TabsStyled = styled.div.attrs((props: TabsProperties) => ({}))<TabsProperties>``

export interface TabProperties extends ThemeProps<any> {
    active: boolean
    isMobile: boolean
    isFirst: boolean
    isLast: boolean
}

export const TabContentStyled = styled.div.attrs((props: TabProperties) => ({}))<TabProperties>`
    padding: 5px;
    background-color: ${(props) => props.theme.table.panelBgColor};
`
export const TabHeaderStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.table.titleColor};
    font-size: ${(props) => props.theme.table.titleFontSize};
    // padding-top:5px;
    // padding-bottom: 20px;
`
/*
  // padding: 10px; // trade screen
  // background-color: ${props => props.theme.table.panelBgColor};
  min-height: 100%;

 */
export const TabContainerStyled = styled.div.attrs((props) => ({}))``

interface TabScrollContainerProperties {
    isMobile: boolean
    hasTabs: boolean
    startHeight: number
    fixedHeight?: number
}

export const TabScrollContainerStyled = styled.div.attrs((props: TabScrollContainerProperties) => ({
    id: 'tab_scroll_cont',
}))<TabScrollContainerProperties>`
    // vvv drill down tables nova event -> market
    ${(props) =>
        !props.fixedHeight &&
        !props.isMobile &&
        'height: calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + props.startHeight + 'px); overflow:auto;'};
    ${(props) => props.fixedHeight && 'height:  ' + props.fixedHeight + 'px; overflow:auto;'};
`

/*
  overflow: ${props => props.isMobile ? 'auto' : 'inherit'};
height: ${props => {
if (props.isMobile) {
  return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (TABS_HEIGHT + (props.isMobile ? FOOTER_HEIGHT : 0)) + 'px)'
}
return 'inherit'
}};

*/

interface TabbedPageHeaderBlockProperties extends ThemeProps<any> {}

export const TabbedPageHeaderBlockStyled = styled.div.attrs(
    (props: TabbedPageHeaderBlockProperties) => ({})
)<TabbedPageHeaderBlockProperties>`
    background-color: ${(props) => props.theme.panel.titleBgColor};
    border-top-left-radius: ${(props) => '10px'};
    border-top-right-radius: ${(props) => '10px'};
`

interface TabbedPageTitleBlockProperties extends ThemeProps<any> {}

export const TabbedPageTitleBlockStyled = styled.div.attrs((props: TabbedPageTitleBlockProperties) => ({
    role: 'toolbar',
    className: 'row g-0',
}))<TabbedPageTitleBlockProperties>`
    // padding: ${(props) => {
        return '20px 20px 15px 20px'
    }};
`

interface TabbedPageTitleProperties extends ThemeProps<any> {}

export const TabbedPageTitleStyled = styled.div.attrs((props: TabbedPageTitleProperties) => ({}))<TabbedPageTitleProperties>`
    color: ${(props) => props.theme.table.titleColor};
    font-size: ${(props) => props.theme.table.titleFontSize};
    // padding-top:5px;
    // padding-bottom: 20px;
`

interface TabbedPageMenuBarProperties extends ThemeProps<any> {}

export const TabbedPageMenuBarStyled = styled.div.attrs((props: TabbedPageMenuBarProperties) => ({
    className: 'col-md-8 d-flex justify-content-end table-menubar',
    id: 'tabbed_menubar',
}))<TabbedPageMenuBarProperties>`
    > .navbar-collapse {
        > .dropdown {
            > button {
                color: ${(props) => props.theme.table.menuButtonColor};
                background-color: ${(props) => props.theme.table.menuButtonBgColor};
                border: none;

                :hover {
                    box-shadow: none;
                    color: ${(props) => props.theme.table.menuButtonHoverColor};
                    background-color: ${(props) => props.theme.table.menuButtonHoverBgColor};
                }

                :active {
                    box-shadow: none;
                }

                :focus {
                    box-shadow: none;
                }
            }
        }
    }
`

export const TabbedPageHeaderContentWrapperStyled = styled.div.attrs((props) => ({
    className: 'd-flex align-items-center ',
}))`
    //display:flex;
`

export const TabbedPageBackButtonStyled = styled.div.attrs((props) => ({
    className: 'col-1',
}))`
    width: 45px !important;
`
/*
col-${props.col ? props.col : '9'}
 */
export const TabbedPageHeaderContentStyled = styled.div.attrs((props: TableHeaderProperties) => ({
    className: ` ms-auto d-flex justify-content-${props.align ? props.align : 'start'}`,
}))<TableHeaderProperties>`
    overflow-x: auto;

    ::-webkit-scrollbar {
        width: var(--scrollbar-width);
        height: var(--scrollbar-height);
    }
    ::-webkit-scrollbar-track {
        background: ${(props) => props.theme.table.titleBgColor};
        margin-block: 25px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.dark[0]};
        border: 1px solid ${(props) => props.theme.colors.dark[1]};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${(props) => props.theme.table.titleBgColor};
    }
`

export const TabbedPageMenuBarWrapperStyled = styled.div.attrs((props) => ({
    className: 'ms-auto  table-menubar col',
}))`
    // width:200px;
    // width:100%;
    padding-top: 4px;
    display: flex;
`

export const TabbedPageRefreshIcon = styled.div.attrs((props) => ({
    className: '',
}))`
    justify-content: flex-start !important;
    padding-left: 10px;
    display: flex;
    margin-top: 8px;
`

export const HeaderIconStyled = styled.i.attrs((props) => ({}))`
    vertical-align: top;
    margin-right: 5px;
`
export const HeaderImageStyled = styled.img.attrs((props) => ({}))`
    vertical-align: top;
    margin-right: 5px;
`

export const HeaderTextStyled = styled.div.attrs((props) => ({}))`
    // vertical-align: top;
    margin-right: 5px;
    // display: inline-flex;
    color: ${(props) => props.theme.panel.titleColor};
`

export const HeaderEntityStyled = styled.div.attrs((props) => ({
    // className: "row g-0"
}))``

export const HeaderItemsWrapperStyled = styled.div.attrs((props) => ({
    // className: "col-md-12"
}))`
    // vertical-align:middle;
    // height:100%;
`

export const HeaderItemStyled = styled.div.attrs((props) => ({}))`
    // display: inline-flex;
    // vertical-align: middle;
    color: ${(props) => props.theme.panel.titleColor};
    overflow: hidden !important;
    text-align: center;
    margin: 0 auto;
`

interface TabbedPageTableTitleProperties extends ThemeProps<any> {
    startPos: number
    isMobile?: boolean
    footerHeight: number
    fixedHeight?: number
}

export const TabbedPageTableContainerStyled = styled.div.attrs((props) => ({
    id: 'tabbed-page-table',
}))``
export const TabbedPageTableContentStyled = styled.div.attrs(
    (props: TabbedPageTableTitleProperties) => ({})
)<TabbedPageTableTitleProperties>`
    ${(props) =>
        !props.fixedHeight &&
        props.isMobile &&
        'height: calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.startPos + props.footerHeight) + 'px)'};
    ${(props) =>
        !props.fixedHeight &&
        !props.isMobile &&
        'height: calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + props.startPos + 'px)'};
    ${(props) => props.fixedHeight && 'height: ' + props.fixedHeight + 'px;'};
    ${(props) => props.isMobile && 'overflow: overlay;'};
`
