import React, { useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { hasValidation, shouldDisable, shouldHide } from '../../../services/ValidationService'
import { FieldProperties } from '../../types'
import { GuslFormState } from '../maintain-form/guslFormSlice'

export const OptionListField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'OptionListField-' + new Date().getTime())
    const [formMode, setFormMode] = useState(properties.formMode)

    // log.debug(className,'MSG001',properties)

    const [formValue, setFormValue] = useState<string>(properties?.data || '')
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [submitted, setSubmitted] = useState(false)

    const renderTableView = (): React.ReactElement => {
        return <></>
    }

    const renderFormView = (): React.ReactElement => {
        const hideField = shouldHide(formMode, properties.fieldConfig)
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const disableField: boolean = shouldDisable(formMode, properties.fieldConfig)
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const required = hasValidation(properties.fieldConfig?.validators)

        return <>{!hideField && <p>not implemented</p>}</>
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
