import { ChartColor, DatasetDO, UIDatasetDO } from '../types'

type CustomColorsProps = {
    idx: number
    dataset: UIDatasetDO | DatasetDO
    chartType: string
    colors?: { [index: string]: { [index: string]: string | Array<string> } }
    themeColors: ChartColor[]
}
export default function applyCustomColors({ idx, dataset, chartType, colors, themeColors }: CustomColorsProps) {
    const className = 'applyCustomColors-' + new Date().getTime()
    if (colors) {
        //log.info(className, 'MSG001', {name: dataset['name'], label: dataset['label'], chartType})
        dataset['backgroundColor'] = _backgroundColor({
            idx,
            dataset,
            chartType,
            colors,
            themeColors,
        })
        dataset['borderColor'] = dataset['name'] && colors[dataset['name']] ? colors[dataset['name']]['borderColor'] : undefined
    } else if (themeColors) {
        let index = idx < themeColors.length ? idx : 0
        dataset['backgroundColor'] = themeColors[index].backgroundColor
        dataset['borderColor'] = themeColors[index].borderColor
    }
    //log.info(className, 'MSG001', {name: dataset['name'], dataset, colors})
    return dataset
}
export const _charts = ['bar', 'doughnut', 'pie', 'polarArea']

function _backgroundColor({ idx, dataset, chartType, colors, themeColors }: CustomColorsProps) {
    if (colors) {
        if (_charts.indexOf(chartType) !== -1) {
            if (dataset['name'] && colors[dataset['name']]) {
                if (
                    colors[dataset['name']]['backgroundColor'] === 'transparent' ||
                    typeof colors[dataset['name']]['backgroundColor'] === 'undefined'
                ) {
                    return colors[dataset['name']]['borderColor']
                } else {
                    return colors[dataset['name']]['backgroundColor']
                }
            } else if (themeColors) {
                let index = idx < themeColors.length ? idx : 0
                return themeColors[index].backgroundColor
            }
            // return (dataset['name'] && colors[dataset['name']]) ?
            // colors[dataset['name']]['backgroundColor'] : undefined;
        } else {
            return dataset['name'] && colors[dataset['name']]
                ? colors[dataset['name']]['backgroundColor']
                : themeColors
                ? themeColors[0].backgroundColor
                : undefined
        }
    } else if (themeColors) {
        return themeColors[0].backgroundColor
    }
}
