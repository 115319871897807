import React, { useState } from 'react'
import { EnvironmentContext } from '../../../../providers/environment/EnvironmentContext'
import MainToolbar from './MainToolbar'
import MainToolbarMobile from './MainToolbarMobile'
import { MainToolbarWrapperStyled, MobileMainStyled } from './styled'
import { TopNavigationProperties } from './types'

const TopNavigation = (props: TopNavigationProperties): React.ReactElement => {
    const environmentContext = React.useContext(EnvironmentContext)
    const [isIOS] = useState<boolean>(environmentContext.isNativeIOS())

    if (environmentContext.isMobileDevice()) {
        return (
            <MobileMainStyled isIOS={isIOS}>
                <MainToolbarMobile sidebarNavigation={props.sidebarNavigation} tandCUpgradeRequired={props.tandCUpgradeRequired} />
            </MobileMainStyled>
        )
    }

    return (
        <MainToolbarWrapperStyled isIOS={isIOS}>
            <MainToolbar sidebarNavigation={props.sidebarNavigation} tandCUpgradeRequired={props.tandCUpgradeRequired} />
        </MainToolbarWrapperStyled>
    )
}
export default TopNavigation
