import { DecimalDO, FieldConfigDTO } from '../components/types'
import { log } from '../services/LogService'
import { isDefined, notBlank, notDefined } from './TypeCheckers'

export const formatDecimalNumber = (fieldConfig: FieldConfigDTO, data: DecimalDO | undefined) => {
    let requiredDecimalPlaces: number | undefined = undefined
    let trimZero: boolean = false
    let withSign: boolean = false
    let suffix: string | undefined
    let zeroAsSpace: boolean = false
    let zeroAsDash: boolean = false

    if (notBlank(fieldConfig.properties)) {
        try {
            const properties = JSON.parse(fieldConfig.properties)
            if (isDefined(properties.decimalPlaces)) {
                requiredDecimalPlaces = properties.decimalPlaces
            }
            if (isDefined(properties.trimZero)) {
                trimZero = properties.trimZero
            }

            if (isDefined(properties.withSign)) {
                withSign = properties.withSign
            }
            if (isDefined(properties.suffix)) {
                suffix = properties.suffix
            }

            if (isDefined(properties.zeroAsSpace)) {
                zeroAsSpace = properties.zeroAsSpace
            }

            if (isDefined(properties.zeroAsDash)) {
                zeroAsDash = properties.zeroAsDash
            }
        } catch (err) {
            log.error('DecimalField', 'ERR001', 'error converting to json', err, fieldConfig.properties)
        }
    }
    if (notDefined(requiredDecimalPlaces)) {
        requiredDecimalPlaces = isDefined(data?.p) ? data?.p : 2
    }
    // @ts-ignore
    let formattedValue = formatNumber(data, requiredDecimalPlaces, trimZero, withSign)

    if (isDefined(suffix)) {
        formattedValue += suffix
    }

    if (zeroAsSpace && data?.v === 0) {
        return ''
    }
    if (zeroAsDash && data?.v === 0) {
        return '-'
    }

    return formattedValue
}

export const formatNumber = (
    data: DecimalDO | undefined,
    requiredDecimalPlaces: number,
    trimZero?: boolean,
    withSign?: boolean
): string => {
    if (isDefined(data)) {
        let formattedNumber
        const value = data?.v || 0
        const decimalPlaces = data?.p === 0 ? 0 : data?.p || 2
        formattedNumber = (
            Math.round((value / Math.pow(10, decimalPlaces)) * Math.pow(10, requiredDecimalPlaces)) / Math.pow(10, requiredDecimalPlaces)
        ).toFixed(requiredDecimalPlaces)
        if (trimZero) {
            if (formattedNumber.endsWith('.0')) {
                formattedNumber = formattedNumber.replace('.0', '')
            } else if (formattedNumber.endsWith('.00')) {
                formattedNumber = formattedNumber.replace('.00', '')
            } else if (formattedNumber.endsWith('.000')) {
                formattedNumber = formattedNumber.replace('.000', '')
            } else if (formattedNumber.endsWith('.0000')) {
                formattedNumber = formattedNumber.replace('0000', '')
            }
        }
        if (withSign) {
            if (value >= 0) {
                formattedNumber = '+' + formattedNumber
            }
        }

        return formattedNumber
    } else {
        return ''
    }
}
