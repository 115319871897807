import styled from 'styled-components'

export const ItemStyled = styled.div.attrs((props) => ({}))`
    border-radius: 6px;
    padding: 6px 8px 6px 8px;
    background: #a699f13f;

    width: max-content;
    color: rgba(26, 26, 26, 1);
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    &:hover {
        text-decoration: underline;
        text-underline-offset: 2px;
        cursor: pointer;
    }
`
export const TickerWrapperStyled = styled.div.attrs((props) => ({
    className: '',
}))`
    box-shadow: 0 5px 8px -4px rgba(0, 0, 0, 0.4);
    //border: 1px solid rgba(226, 226, 226, 1);
`
