import { isArray } from '../../../utils/TypeCheckers'
import { UIDatasetDO } from '../types'
import { CHART_COLORS, COLORS_ARRAY_FOR_SOLID_GRADIENT, COLORS_ARRAY_FOR_TRANSPARENT_GRADIENT } from './colorsAndGradients'
import createGradient from './createChartGradient'

type Props = {
    chart: any
    datasets?: UIDatasetDO[]
    chartType: string
    chartFor?: string
}

export const AREA_CHARTS: string[] = ['line', 'radar', 'bubble']
export const ARC_CHARTS = ['pie', 'doughnut', 'polarArea']

export default function checkDatasetsColors({ chart, datasets, chartType, chartFor }: Props) {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const className = 'checkDatasetsColors-' + new Date().getTime()

    const COLORS_ARRAY = AREA_CHARTS.indexOf(chartType) !== -1 ? COLORS_ARRAY_FOR_TRANSPARENT_GRADIENT : COLORS_ARRAY_FOR_SOLID_GRADIENT

    datasets?.forEach((dataset, idx) => {
        const backgroundColorType = typeof dataset.backgroundColor
        const borderColorType = typeof dataset.borderColor
        // const randomNumber = idx < NUM_OF_COLORS ? idx : getRandomIntInclusive({min: 0, max: NUM_OF_COLORS - 1});
        // const randomNumber = getRandomIntInclusive({min: 0, max: NUM_OF_COLORS - 1});
        const randomNumber = 1
        if (backgroundColorType === 'undefined') {
            /*default colors*/
            try {
                if (ARC_CHARTS.indexOf(chartType) !== -1) {
                    dataset.backgroundColor = Array.from({
                        length: dataset.data.length,
                    }).map((_, idx) => CHART_COLORS[idx])
                } else {
                    // dataset.backgroundColor = createGradient(chart.ctx, chart.chartArea,
                    //     COLORS_ARRAY[randomNumber])
                    // dataset['backgroundColor'] = CHART_COLORS[randomNumber];
                }
            } catch (e) {
                dataset['backgroundColor'] = CHART_COLORS[randomNumber]
            }
        } else if (backgroundColorType === 'object') {
            /*array of colors to create gradient*/
            try {
                if (ARC_CHARTS.indexOf(chartType) !== -1) {
                    if (isArray(dataset.backgroundColor)) {
                        // @ts-ignore
                        dataset.backgroundColor = dataset?.backgroundColor?.map((color: string[], idx2: number) =>
                            isArray(color)
                                ? createGradient(chart.ctx, chart.chartArea, color)
                                : typeof color !== 'undefined'
                                ? color
                                : CHART_COLORS[idx2]
                        )
                    } else {
                        dataset.backgroundColor = Array.from({
                            length: dataset.data.length,
                        }).map((_, idx) => CHART_COLORS[idx])
                    }
                } else {
                    dataset.backgroundColor = createGradient(
                        chart.ctx,
                        chart.chartArea,
                        Array.isArray(dataset.backgroundColor) ? dataset.backgroundColor : []
                    )
                }
            } catch (e) {
                dataset.backgroundColor = CHART_COLORS[randomNumber]
            }
        }

        if (borderColorType === 'undefined') {
            if (ARC_CHARTS.indexOf(chartType) !== -1) {
                dataset.borderColor = Array.from({ length: dataset.data.length }).map((_, idx) => CHART_COLORS[idx])
            } else {
                dataset.borderColor = createGradient(chart.ctx, chart.chartArea, COLORS_ARRAY[randomNumber])
                dataset['borderColor'] = CHART_COLORS[randomNumber]
            }
        } else if (borderColorType === 'object') {
            try {
                if (ARC_CHARTS.indexOf(chartType) !== -1) {
                    // dataset.borderColor = Array.from({length: dataset.data.length}).map((_, idx) =>
                    //     CHART_COLORS[idx])

                    if (isArray(dataset.borderColor)) {
                        // @ts-ignore
                        dataset.borderColor = dataset?.borderColor?.map((color: string[], idx2: number) =>
                            isArray(color)
                                ? createGradient(chart.ctx, chart.chartArea, color)
                                : typeof color !== 'undefined'
                                ? color
                                : CHART_COLORS[idx2]
                        )
                    } else {
                        dataset.borderColor = Array.from({
                            length: dataset.data.length,
                        }).map((_, idx) => CHART_COLORS[idx])
                    }
                } else {
                    dataset.borderColor = createGradient(
                        chart.ctx,
                        chart.chartArea,
                        Array.isArray(dataset.borderColor) ? dataset.borderColor : []
                    )
                }
            } catch (e) {
                dataset.borderColor = CHART_COLORS[randomNumber]
            }
        }
    })

    return datasets
}
