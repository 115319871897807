import React, { useContext, useEffect, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { BlastContext } from '../../../providers/blast/BlastContext'
import { EnvironmentContext } from '../../../providers/environment/EnvironmentContext'
import { environmentService } from '../../../providers/environment/EnvironmentService'
import { SessionContext } from '../../../providers/session/SessionContext'
import { GuslThemeContext } from '../../../providers/theme/GuslThemeProvider'
import { getSettings } from '../../../services/ValidationService'
import { isBlank } from '../../../utils/TypeCheckers'
import { assignReferences, extractExtraFieldProperties, noop, RunOnceEffect, unSubscribe } from '../../../utils/Utils'
import { FieldProperties, FormMode } from '../../types'
import { watchBlastDeltas } from '../../ui/FieldUtils'
import FieldLabel from '../field-label/FieldLabel'
import { IframeStyled } from '../iframe/styled'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import Icon from '../maintain-table/bootstrap/Icon'
import { ExtraFieldProperties } from '../money/types'
import { FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled } from '../text/styled'
import { ExternalLinkStyled, ExternalLinkWrapperStyled } from './styled'

export const ExternalLinkField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const sessionContext = React.useContext(SessionContext)
    const blastContext = useContext(BlastContext)

    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'ExternalLinkField-' + new Date().getTime())

    const environmentContext = React.useContext(EnvironmentContext)
    const guslThemeContext = React.useContext(GuslThemeContext)

    const [trueColor] = useState<string>(
        guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).money.positiveColor
    )
    const [falseColor] = useState<string>(
        guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).money.negativeColor
    )

    const [formMode, setFormMode] = useState(properties.formMode)
    // const [formValue, setFormValue] = useState<string>(properties?.data || '');
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) || properties.data || '')

    const [label, setLabel] = useState<string>('more ...')
    const [refreshCounter, setRefreshCounter] = useState<number>(1)
    const [extraFieldProperties] = useState<ExtraFieldProperties>(extractExtraFieldProperties(properties))

    useEffect(() => {
        const [loaderSubscription, blastDeltaSubscription, valueObservable] = watchBlastDeltas(
            sessionContext,
            blastContext,
            properties,
            formMode
        )
        const valueSubscription = valueObservable.subscribe((newValue: any) => {
            if (newValue) {
                setFormValue(newValue)
                setRefreshCounter(refreshCounter + 1)
            }
        })
        return () => {
            unSubscribe(loaderSubscription)
            unSubscribe(blastDeltaSubscription)
            unSubscribe(valueSubscription)
        }
    }, [properties])

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setFormValue(properties?.data || '')
    }

    useEffect(() => {
        setFormValue(properties?.data || '')
    }, [properties])

    const doValidation = (fieldValue: any): boolean => {
        return true
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        if (isBlank(formValue)) {
            return <></>
        }
        const onLinkClick = (e: React.MouseEvent<HTMLDivElement>) => {
            window.open(formValue)
        }

        return (
            <ExternalLinkWrapperStyled>
                <ExternalLinkStyled onClick={(e: React.MouseEvent<HTMLDivElement>) => onLinkClick(e)}>
                    {label}&nbsp;
                    <Icon icon={'fa-solid fa-external-link'} />
                </ExternalLinkStyled>
            </ExternalLinkWrapperStyled>
        )
        // fa fa-external-link
    }
    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        return (
            <>
                {!hideField && (
                    <FloatingFormStyled>
                        <FieldContentWrapperStyled>
                            <FieldContentStyled>{renderTableView()}</FieldContentStyled>
                        </FieldContentWrapperStyled>
                        <FieldLabel properties={properties} />
                    </FloatingFormStyled>
                )}
            </>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
