import styled, { ThemeProps } from 'styled-components'

interface ComponentTitleWrapperProps extends ThemeProps<any> {
    isSummaryHeader?: boolean | undefined
    isSummaryFooter?: boolean | undefined
    isSidePanel?: boolean | undefined
}

interface MainHeaderProps extends ThemeProps<any> {
    align: string
}

interface TradeCentralLinkProps extends ThemeProps<any> {
    isActive: boolean
    onClick: Function
}

/*gbw row */
export const ComponentTitleWrapperStyled = styled.div.attrs((props: ComponentTitleWrapperProps) => ({
    id: 'component_title_w',
    role: 'toolbar',
    className: 'g-0 d-flex align-items-center justify-content-between grid-item__title',
}))<ComponentTitleWrapperProps>`
    background-color: ${(props) => (props.isSummaryHeader || props.isSummaryFooter ? 'inherit' : props.theme.table.titleBgColor)};
    // height:50px;
    padding: ${(props) => {
        if (props.isSummaryHeader || props.isSummaryFooter) {
            return '0px'
        } else if (props.isSidePanel) {
            return '10px 0px'
        } else {
            return props.theme.table.titlePadding
        }
    }};
    // padding-top:3px; // (a) affects main panel title bar
`
export const MainHeaderStyled = styled.div.attrs((props: MainHeaderProps) => ({
    className: 'd-flex',
    //className: 'col-md-6 d-flex justify-content-'+(props.align ? props.align : 'start')
}))<MainHeaderProps>`
    //text-align: center;
    //margin: 0px auto;
`
export const TradeCentralLinkStyled = styled.div.attrs((props: TradeCentralLinkProps) => ({
    role: 'button',
    className: 'mx-2 ',
}))<TradeCentralLinkProps>`
    background-color: ${(props) => (!props.isActive ? 'inherit' : props.theme.colors.dark[1])};
    padding: 5px;
    font-size: 12px;
`
