import { SetStateAction, useContext, useEffect, useState } from 'react'
import { ModulesRequestsDTO } from '../../components/types'
import { log } from '../../services/LogService'
import { SessionContext } from './SessionContext'

export type MGQueryDO = {
    method: string
    endpoint: string
    queryParams: any
    controller?: AbortController
    dontRunQuery?: boolean
}
export type MGQueryReturnTypeDO = {
    isLoading: boolean
    isSuccess: boolean
    isError: boolean
    error: any
    data: { [index: string]: any }
    chartsData?: { [index: string]: any }
}
export default function MGQuery({ method, endpoint, queryParams, controller, dontRunQuery }: MGQueryDO) {
    const [className] = useState('MGQuery-' + new Date().getTime())
    log.debug(className, 'MSG001', { method, endpoint, queryParams, controller })
    const sessionContext = useContext(SessionContext)
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [isError, setIsError] = useState(false)
    const [error, setError] = useState({})
    const [data, setData] = useState<Array<any>>([])

    /*queryParamsString to refresh call when paginating, filtering tables...*/
    const queryParamsString = JSON.stringify(queryParams)

    useEffect(() => {
        if (dontRunQuery) return

        const abortController = new AbortController()
        const getData = new Promise<any[]>((resolve, reject) => {
            setIsLoading(true)
            // @ts-ignore
            sessionContext[method]<ModulesRequestsDTO, ModulesRequestsDTO>(endpoint, queryParams, controller).then(
                (response: { status: number; data: { content?: SetStateAction<[]> } }) => {
                    // @ts-ignore
                    resolve(response.data || [])
                },
                (reason: any) => {
                    reject(reason)
                }
            )
        })
        getData
            .then((response: any[]) => {
                setData(response)
                setIsSuccess(true)
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
                setIsSuccess(false)
                setIsError(true)
                setError(error)
            })

        return () => {
            // stop the query by aborting on the AbortController on unmount
            abortController.abort()
        }
    }, [queryParamsString])

    return { isLoading, isSuccess, isError, error, data }
}
