const defaultTheme = {
    imageSuffix: 'white',
    headerPanel: {
        fgColor: '#9aa9b2',
        bgColor: '#00205a',
        border: 'solid 1px #7d8187',
        borderRadius: '5px',
    },
    colors: {
        bespoke: ['#00B050', '#FF0000'],
        primary: ['#045de2', '#518eeb'],
        secondary: ['#04e282', '#56e7a8'],
        background: ['#1c2027', '#202931'],
        dark: ['#303840', '#7d8187'],
        light: '#ffffff',
        error: '#c83331',
        warning: '#ead553',
        notification: '#338a4b',
        accentColor: '#045de2',
        accentColorLight: '#c2e8fb',
        accentColorMuted: 'rgba(4,93,226,0.4)',
    },
    body: {
        background: '#303840',
    },
    splash: {
        background: '#1c2027',
        color: '#045de2',
        size: 60,
        left: '40px',
        top: '40px',
    },
    spinner: {
        color: '#045de2',
    },
    modal: {
        outerBackground: '#30384050',
        innerBackground: '#303840',
    },
    scrollbar: {
        trackBgColor: '#045de2',
        thumbBgColor: '#045de2',
        thumbBorderColor: '#045de2',
        thumbBorderRadius: '#045de2',
        thumbHoverBgColor: '#045de2',
    },
    login: {
        fontColor: '#ffffff',
        linkColor: '#045de2',
        buttonBgColor: '#045de2',
        buttonFgColor: '#ffffff',
        outerBackground: 'rgba(2, 133, 182, 0.5)',
        innerBackground: '#00205a',
        titlePanel: {
            bgColor: '#045de2',
            fgColor: '#ffffff',
            titleFontSize: '32px',
            fontWeight: '800',
            height: 'inherit',
            padding: '10px 10px 10px 10px',
        },
        loginText: {
            bgColor: '#045de2',
            fgColor: '#ffffff',
            fontSize: '32px',
            fontWeight: '800',
            padding: '10px 10px 10px 10px',
        },
        input: {
            fieldBorder: 'solid 1px #454545',
            bgColor: '#454545',
            fgColor: '#454545',
            borderRadius: '10px',
            margin: '2px',
            padding: '5px',
            labelColor: 'rgba(26, 26, 26, 1)',
            fontSize: '18px',
            iconColor: '#045de2',
            iconColorDisabled: '#ffffff',
        },
    },
    filterControls: {
        bgColor: 'rgb(48, 56, 64)',
        gridBorder: '',
    },
    table: {
        panelBgColor: '#00205a',
        titleColor: '#ffffff',
        titleBgColor: '#040421',
        titlePadding: '10px 10px 15px 20px',
        titleFontSize: '20px',
        headerColor: '#ffffff',
        headerBgColor: '#1f1f1f',
        columnColor: '#9aa9b2',
        highlightBgColor: 'rgba(2, 133, 182, 0.5)',
        headerFontSize: '12px',
        columnFontSize: '10px',
        gridBorder: 'solid 1px #303840',
        selectedColor: '#a89362',
        menuButtonColor: '#ffffff',
        menuButtonBgColor: '#040421',
        menuButtonHoverColor: '#ffffff',
        menuButtonHoverBgColor: '#040421',
        rowHoverBgColor: '#040421',
        summaryHeader: {
            bgColor: '#00205a',
        },
        summaryFooter: {
            bgColor: '#00205a',
        },
    },

    panel: {
        panelBgColor: '#00205a',
        titleColor: '#ffffff',
        titleBgColor: '#2585b6',
        titleFontSize: '20px',
        subPanel: {
            titleColor: '#ffffff',
            titleBgColor: '#2585b6',
            titleFontSize: '20px',
        },
    },
    form: {
        fieldBorder: 'solid 1px #454545',
        entryBorderColor: '#454545',
        viewBorderColor: '#454545',
        borderRadius: '10px',
        margin: '2px',
        padding: '5px',
    },
    action: {
        bgColor: 'transparent',
        borderColor: '#454545',
        hoverBgColor: '#040421',
        hoverColor: '#ffffff',
        hoverBorderColor: '#FFBE66',
        colors: {
            defaultColor: '#9aa9b2',
            warning: '#FFBE66',
            save: '#008C57',
            refresh: '#008C57',
            new: '#9aa9b2',
            newAction: '#0185b7',
            update: '#008C57',
            edit: '#008C57',
            cancel: '#9aa9b2',
            delete: '#B91B10',
        },
    },
    lookup: {
        headerColor: '#ffffff',
        columnColor: '#9aa9b2',
        headerFontSize: '14px',
        columnFontSize: '14px',
        hoverColor: '#9aa9b2',
        hoverBgColor: '#00205a',
    },
    money: {
        positiveColor: '#338a4b',
        negativeColor: '#c83331',
    },
    navigation: {
        topNavbarColor: '#fff',
        topNavbarBgColor: '#0a1e4d',
        gradient: ['#131a4e', '#040421', '#131a4e'],
        buttonColor: '#9aa9b2',
        buttonBgColor: '#131a4e',
        hoverButtonColor: '#9aa9b2',
        hoverButtonBgColor: '#131a4e',
        hamburgerColor: '#9aa9b2',
        borderColor: '#040421',
        dropdownMenu: {
            bgColor: '#131a4e',
            color: 'whitesmoke',
            hoverButtonColor: '#9aa9b2',
            hoverButtonBgColor: '#131a4e',
            leftBarColor: '#80a6e4',
        },
        sideMenu: {
            bgColor: '#131a4e',
            color: 'whitesmoke',
            hoverBgColor: '#182165',
            hoverColor: '#9aa9b2',
            rightBorderColor: '#9aa9b2',
            footer: {
                bgColor: '#131a4e',
                color: 'whitesmoke',
            },
            header: {
                avatar: {
                    bgColor: '#131a4e',
                    color: 'whitesmoke',
                },
                bgColor: '#131a4e',
                color: 'whitesmoke',
            },
        },
    },
    chart: [
        {
            borderColor: '#003f5c',
            backgroundColor: ['#003f5c', '#003f5c'],
        },
        {
            borderColor: '#2f4b7c',
            backgroundColor: ['#2f4b7c', '#2f4b7c'],
        },
        {
            borderColor: '#665191',
            backgroundColor: ['#665191', '#665191'],
        },
        {
            borderColor: '#a05195',
            backgroundColor: ['#a05195', '#a05195'],
        },
        {
            borderColor: '#d45087',
            backgroundColor: ['#d45087', '#d45087'],
        },
        {
            borderColor: '#f95d6a',
            backgroundColor: ['#f95d6a', '#f95d6a'],
        },
        {
            borderColor: '#ff7c43',
            backgroundColor: ['#ff7c43', '#ff7c43'],
        },
        {
            borderColor: '#ffa600',
            backgroundColor: ['#ffa600', '#ffa600'],
        },
    ],
    padding: {
        xs: '5px',
        s: '10px',
        m: '10px',
        l: '20px',
        xl: '40px',
    },
    margin: {
        s: '10px',
        m: '10px',
        l: '20px',
    },
    borderRadius: {
        s: '5px',
        m: '10px',
        l: '20px',
    },
    grid: {
        baseline: 5,
        gap: {
            s: 5,
            m: 10,
            l: 20,
        },
    },
    maxContentWidth: '1264px',
    maxPageWidth: '1500px',
    mediaQueryBreakPoints: {
        xs: 320,
        sm: 480,
        md: 768,
        lg: 1200,
    },
    typography: {
        fontFamilyRegular: '"SofiaProRegular", serif',
        fontFamilySemiBold: '"SofiaProSemiBold", serif',
        baseFontSize: '16px',
    },
    contentPadding: {
        desktop: '32px',
        mobile: '16px',
    },
    fontSizes: {
        headings: {
            xxl: '40px',
            xl: '32px',
            l: '28px',
            m: '24px',
            s: '20px',
            xs: '16px',
        },
        body: {
            l: '16px',
            m: '14px',
            s: '12px',
            xs: '10px',
            xxs: '8px',
        },
        tooltip: '15px',
    },
    fontWeights: {
        bold: 'bold',
        medium: 500,
        regular: 400,
    },
    marginMap: {
        sm: 4,
        md: 8,
        lg: 10,
        default: 0,
    },
}

export default defaultTheme
