// -------------- config
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SessionContextProps } from '../../../providers/session/types'
import { FieldConfigDTO, FormRowConfigDTO } from '../../types'
import { maintainTableService } from '../maintain-table/MaintainTableService'
import { DateSummaryRequestDTO, DateSummaryTableBO, SummaryRowDTO } from './types'

// -------------- states
interface GuslDateSummaryState {
    [id: string]: DateSummaryState
}

export interface DateSummaryState {
    code: string
    sessionContext: SessionContextProps | undefined
    tableData: SummaryRowDTO[] | undefined
    allFields: FieldConfigDTO[]
    label: string
    dataUrl: string
    fromDate: string
    hasNext: boolean
    hasPrevious: boolean
}

const initialState: GuslDateSummaryState = {}

// -------------- actions

export interface GuslDateSummaryInitPayload {
    code: string
    sessionContext: SessionContextProps
    allFields: FormRowConfigDTO[]
    tableData: SummaryRowDTO[] | undefined
    label: string
    dataUrl: string
    fromDate: string
    hasNext: boolean
    hasPrevious: boolean
}

export interface GuslDateSummaryCleanUpPayload {
    code: string
}

interface DateSummaryRequest {
    code: string
    url: string
    sessionContext: SessionContextProps
    abortController: AbortController
    fromDate: string
    next: boolean
}

export interface DateSummaryResponseWrapper {
    code: string
    response: DateSummaryTableBO
}

// -------------- utils
const loadInitValues = (entry: DateSummaryState, values: GuslDateSummaryInitPayload) => {
    console.log('loadInitValues', values)
    entry.code = values.code
    entry.sessionContext = values.sessionContext
    entry.allFields = maintainTableService.extractAllFieldsFromRows(values.allFields).filter((fld) => fld.displayInTable)
    entry.label = values.label
    entry.dataUrl = values.dataUrl
    entry.tableData = values.tableData
    entry.fromDate = values.fromDate
    entry.hasNext = values.hasNext
    entry.hasPrevious = values.hasPrevious
}

export const getDateSummaryData = createAsyncThunk('url', async (request: DateSummaryRequest) => {
    const response = await request.sessionContext.post<DateSummaryRequestDTO, DateSummaryTableBO>(
        request.url,
        { fromDate: request.fromDate, next: request.next },
        request.abortController
    )
    return { code: request.code, response: response?.data || {} }
})

const getState = (state: GuslDateSummaryState, code: string): DateSummaryState => {
    let entry: DateSummaryState = state[code]
    if (!entry) {
        entry = createDefault(code)
    }
    return entry
}

const createDefault = (code: string): DateSummaryState => {
    return {
        code: code,
        sessionContext: undefined,
        allFields: [],
        tableData: [],
        label: '',
        dataUrl: '',
        fromDate: '',
        hasNext: false,
        hasPrevious: false,
    }
}

const copyState = (entry: DateSummaryState): DateSummaryState => {
    const newState = {}
    for (const key in entry) {
        if (entry.hasOwnProperty(key)) {
            // @ts-ignore
            newState[key] = entry[key]
        }
    }
    return newState as DateSummaryState
}

const updateData = (inboundState: DateSummaryState, response: DateSummaryTableBO): DateSummaryState => {
    const entry: DateSummaryState = copyState(inboundState)
    if (response) {
        entry.tableData = response.content
        entry.label = response.label
        entry.dataUrl = response.dataUrl
        entry.fromDate = response.fromDate
        entry.hasNext = response.hasNext
        entry.hasPrevious = response.hasPrevious
    }
    return entry
}

// -------------- config

export const dateSummarySlice = createSlice({
    name: 'dateSummarySlice',
    initialState,
    reducers: {
        initDateSummary(state, action: PayloadAction<GuslDateSummaryInitPayload>) {
            const entry: DateSummaryState = getState(state, action.payload.code)
            loadInitValues(entry, action.payload)
            state[action.payload.code] = entry
        },
        cleanUpDateSummary(state, action: PayloadAction<GuslDateSummaryCleanUpPayload>) {
            delete state[action.payload.code]
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getDateSummaryData.fulfilled, (state, action: PayloadAction<DateSummaryResponseWrapper>) => {
            const entry: DateSummaryState = getState(state, action.payload.code)
            state[action.payload.code] = updateData(entry, action.payload.response)
        })
    },
})

export const { initDateSummary, cleanUpDateSummary } = dateSummarySlice.actions

export default dateSummarySlice.reducer
