import { ChildReference, FieldConfigDTO, FieldProperties, FormMode } from '../../types'

export const getLookupFieldProperties = (
    code: string,
    registerChildReference: (reference: ChildReference) => void,
    fieldConfig: FieldConfigDTO | undefined
): FieldProperties => {
    return {
        code: code,
        menuItem: undefined,
        formMode: FormMode.EDIT,
        isFirstField: true,
        reference: {
            name: 'realtickMapper',
            displayOrder: 1,
            register: registerChildReference,
        },
        fieldConfig: fieldConfig
            ? fieldConfig
            : {
                  name: 'symbol',
                  label: 'Symbol',
                  type: 'lookup',
              },
        data: '',
        onSubmitted: () => {},
        onChange: () => {}, //noop - picked up differently
        getCurrentRowData: () => {},
    }
}
/*
export const getQuantityFieldProperties = (registerChildReference: (reference: ChildReference) => void,
                                           onchange: (name: string, value: any) => void): FieldProperties => {
    return {
        code: 'qty',
        menuItem: undefined,
        formMode: FormMode.EDIT,
        onSubmitted: () => {
        },
        onChange: onchange,
        reference: {
            name: "quantity",
            displayOrder: 1,
            register: registerChildReference,
        },
        getCurrentRowData: () => {
        },
        fieldConfig: {
            name: 'quantity',
            label: 'Quantity',
            type: 'number'
        }
    }
}


export const getOrderTypeFieldProperties = (code: string, registerChildReference: (reference: ChildReference) => void,
                                            onchange: (name: string, value: any) => void): FieldProperties => {
    return {
        code: code,
        menuItem: undefined,
        formMode: FormMode.EDIT,
        onSubmitted: () => {
        },
        onChange: onchange,
        reference: {
            name: "orderType",
            displayOrder: 1,
            register: registerChildReference,
        },
        getCurrentRowData: () => {
        },
        fieldConfig: {
            name: 'orderType',
            label: 'Order Type',
            type: 'option',
            options: ['MARKET', 'LIMIT']
        }
    }
}

export const getValidityFieldProperties = (code: string, registerChildReference: (reference: ChildReference) => void,
                                           onchange: (name: string, value: any) => void): FieldProperties => {
    return {
        code: code,
        menuItem: undefined,
        formMode: FormMode.EDIT,
        onSubmitted: () => {
        },
        onChange: onchange,
        reference: {
            name: "validity",
            displayOrder: 1,
            register: registerChildReference,
        },
        getCurrentRowData: () => {
        },
        fieldConfig: {
            name: 'validity',
            label: 'Validity',
            type: 'option',
            options: ['DAY']
        }
    }
}
export const getLimitFieldProperties = (code: string, registerChildReference: (reference: ChildReference) => void,
                                        onchange: (name: string, value: any) => void): FieldProperties => {
    return {
        code: code,
        menuItem: undefined,
        formMode: FormMode.EDIT,
        onSubmitted: () => {
        },
        onChange: onchange,
        reference: {
            name: "limitPrice",
            displayOrder: 1,
            register: registerChildReference,
        },
        getCurrentRowData: () => {
        },
        fieldConfig: {
            name: 'limitPrice',
            label: 'Limit Price',
            type: 'number',
        }
    }
}
*/
