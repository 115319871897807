import React, { useContext, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { BlastContext } from '../../providers/blast/BlastContext'
import { SessionContext } from '../../providers/session/SessionContext'
import { cancelAbortController, RunOnceEffect, safeStream } from '../../utils/Utils'
import paginationService from '../common/gusl-table/PaginationService'
import LoadingSpinner from '../common/loading-spinner/LoadingSpinner'
import { TableRowDTO } from '../types'
import { cleanPagedTable, getPagedData, initPagedTable, InstanceState, pagedNextPage, resetQuery } from './pagedResponseSlice'
import { EndMessageStyled, PullDownToRefresh, ReleaseToRefreshStyled } from './styled'

interface PagedResponseProperties {
    code: string
    selectUrl: string
    renderItem: (item: any, idx: number) => React.JSX.Element
    infinityScroll?: boolean
}

const PagedResponse = ({ code, selectUrl, renderItem, infinityScroll = true }: PagedResponseProperties): React.ReactElement => {
    const sessionContext = React.useContext(SessionContext)
    const blastContext = useContext(BlastContext)
    const dispatch = useAppDispatch()

    const _pagedResponseSlice: InstanceState = useAppSelector((state) => state.pagedResponseSlice[code])

    RunOnceEffect(() => {
        return () => {
            dispatch(cleanPagedTable({ code: code }))
        }
    })

    useEffect(() => {
        if (_pagedResponseSlice?.performRefreshCount > 0) {
            refreshData()
                .then(() => {})
                .catch(() => {})
        }
    }, [_pagedResponseSlice?.performRefreshCount])

    const refreshData = (): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const abortController = new AbortController()
            dispatch(
                getPagedData({
                    code: code,
                    abortController: abortController,
                    pathParams: { code: code },
                })
            )
                .unwrap()
                .then(() => {
                    cancelAbortController(abortController)
                    resolve(true)
                })
                .catch((error: any) => {
                    console.error('error', error)
                    cancelAbortController(abortController)
                    reject(false)
                })
        })
    }

    RunOnceEffect(() => {
        dispatch(
            initPagedTable({
                code: code,
                infinityScroll: infinityScroll,
                sessionContext: sessionContext,
                blastContext: blastContext,
                selectUrl: selectUrl,
                lastQueryParams: paginationService.blankQueryParam(),
            })
        )
        refreshData().finally(() => {})

        return () => {}
    })

    const fetchData = (): Promise<boolean> => {
        dispatch(pagedNextPage({ code: code }))
        return refreshData()
    }

    const renderLoading = (): React.ReactElement => {
        return (
            <>
                {_pagedResponseSlice?.loading && (
                    <div>
                        <LoadingSpinner size={50} />
                    </div>
                )}
            </>
        )
    }

    const renderPullDownToRefresh = (): React.ReactElement => {
        return <PullDownToRefresh>&#8595; Pull down to refresh</PullDownToRefresh>
    }

    const renderReleaseToRefresh = (): React.ReactElement => {
        return <ReleaseToRefreshStyled>&#8593; Release to refresh</ReleaseToRefreshStyled>
    }

    const renderEndMessage = (): React.ReactElement => {
        return <EndMessageStyled>No more data</EndMessageStyled>
    }

    const onRefresh = () => {
        console.log('-- refresh -- ')
        dispatch(resetQuery({ code: code }))
        return refreshData()
    }

    return (
        <InfiniteScroll
            dataLength={_pagedResponseSlice?.content?.length || 0} //This is important field to render the next data
            next={fetchData}
            hasMore={_pagedResponseSlice?.hasMoreContent || true}
            scrollableTarget={'scrollable_' + code}
            loader={renderLoading()}
            endMessage={renderEndMessage()}
            refreshFunction={onRefresh}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={renderPullDownToRefresh()}
            releaseToRefreshContent={renderReleaseToRefresh()}
        >
            {safeStream(_pagedResponseSlice?.content).map((item: TableRowDTO, idx) => renderItem(item, idx))}
        </InfiniteScroll>
    )
}

export default PagedResponse
