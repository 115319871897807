import AutoNumeric, { Options } from 'autonumeric'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { BlastContext } from '../../../providers/blast/BlastContext'
import { SessionContext } from '../../../providers/session/SessionContext'
import { getSettings, performValidation } from '../../../services/ValidationService'
import { getStyle } from '../../../utils/CssUtils'
import { isZero, notBlank } from '../../../utils/TypeCheckers'
import { assignReferences, extractExtraFieldProperties, noop, RunOnceEffect, unSubscribe } from '../../../utils/Utils'
import { FieldProperties, FormMode } from '../../types'
import { watchBlastDeltas } from '../../ui/FieldUtils'
import FieldLabel from '../field-label/FieldLabel'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { ExtraFieldProperties } from '../money/types'
import { FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled, InputStyled } from '../text/styled'
import { NumberStyled } from './styled'

const getLanguage = () => new Intl.NumberFormat().resolvedOptions().locale

const formatAmount = (amount: number, min: number, max: number): string => {
    return amount.toLocaleString(getLanguage(), {
        minimumFractionDigits: min,
        maximumFractionDigits: max,
    })
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export const NumberField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const sessionContext = React.useContext(SessionContext)
    const blastContext = useContext(BlastContext)

    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'NumberField-' + new Date().getTime())
    const [formMode, setFormMode] = useState(properties.formMode)

    const [extraFieldProperties] = useState<ExtraFieldProperties>(() => extractExtraFieldProperties(properties))
    const [formValue, setFormValue] = useState<number>(
        _guslFormState?.getFieldValue(properties) || properties.data || (extraFieldProperties?.zeroAsSpace ? '' : '0')
    )

    const valueRef = useRef(properties?.data)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [submitted, setSubmitted] = useState(false)
    const [refreshCounter, setRefreshCounter] = useState<number>(1)
    const [fieldValue, setFieldValue] = useState<AutoNumeric | undefined>()
    const dispatch = useAppDispatch()

    const numOfDecimals: number = extraFieldProperties?.decimalPlaces || 0
    const maxNumberOfDecimals = 10
    let stepMap: {
        [index: number]: number
    } = {}

    Array.from({ length: maxNumberOfDecimals }).forEach((_, idx) => {
        stepMap[idx] = 1 / Math.pow(10, idx)
    })
    const step = stepMap[numOfDecimals]

    useEffect(() => {
        // console.log(`subscribe fld: ${properties.fieldConfig.name} bdc: ${properties.fieldConfig.blastDeltaCommand} kv: ${properties.fieldConfig.keyValue}  kf:${properties.fieldConfig.keyField} `)
        const [loaderSubscription, blastDeltaSubscription, valueObservable] = watchBlastDeltas(
            sessionContext,
            blastContext,
            properties,
            formMode
        )
        const valueSubscription = valueObservable.subscribe((newValue: any) => {
            // if ((properties?.fieldConfig?.keyValue||'').indexOf('BARC') > 0 && (properties.fieldConfig.name === 'sellPrice' || properties.fieldConfig.name === 'buyPrice')) {
            //     console.log(`kv: ${properties.fieldConfig.keyValue} new value ${properties.fieldConfig.name} ${newValue}`)
            // }

            setFormValue(newValue)
            setRefreshCounter(refreshCounter + 1)
        })
        return () => {
            unSubscribe(loaderSubscription)
            unSubscribe(blastDeltaSubscription)
            unSubscribe(valueSubscription)
        }
    }, [properties])

    useEffect(() => {
        window.requestAnimationFrame(function () {
            if (properties.isTableView) {
                return
            }
            try {
                if (valueRef?.current) {
                    if (!AutoNumeric.isManagedByAutoNumeric(valueRef?.current)) {
                        const options: Options = {
                            modifyValueOnWheel: false,
                            selectOnFocus: false,
                        }
                        if (extraFieldProperties?.decimalPlaces || extraFieldProperties?.decimalPlaces === 0) {
                            options.decimalPlaces = extraFieldProperties.decimalPlaces
                        }
                        if (extraFieldProperties.maximumFractionDigits) {
                            options.decimalPlaces = extraFieldProperties.maximumFractionDigits
                        }
                        if (extraFieldProperties.nonDecimal) {
                            options.decimalPlaces = 0
                        }
                        if (extraFieldProperties.noNegativeValue) {
                            options.minimumValue = '0'
                        }
                        if (notBlank(extraFieldProperties.maximumValue)) {
                            options.maximumValue = extraFieldProperties.maximumValue
                        }
                        if (notBlank(extraFieldProperties.minimumValue)) {
                            options.minimumValue = extraFieldProperties.minimumValue
                        }

                        const valueElement = new AutoNumeric(
                            valueRef.current,
                            properties?.data === 0 ? (extraFieldProperties?.zeroAsSpace ? '' : '0') : properties?.data,
                            options
                        )
                        valueElement.node().addEventListener('input', (event: Event) => {
                            // @ts-ignore
                            const value = parseFloat((event?.target?.value ? '' + event?.target?.value : '0').replaceAll(',', ''), 10)
                            try {
                                setFormValue(value)
                                properties.onChange(properties.fieldConfig.name, value)
                            } catch (err) {
                                // log.warn(className, 'WRN001', 'not a number', value);
                            }
                        })
                        setFieldValue(valueElement)
                    }
                }
            } catch (err) {
                console.error('error', err)
            }
        })
    }, [properties])

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setFormValue(properties?.data || (extraFieldProperties?.zeroAsSpace ? '' : '0'))
    }

    useEffect(() => {
        setFormValue(properties?.data || (extraFieldProperties?.zeroAsSpace ? '' : '0'))
    }, [properties])

    const doValidation = (fieldValue: any): boolean => {
        return performValidation(formMode, properties.menuItem?.code, properties.fieldConfig, fieldValue, setSubmitted, setErrorMessage)
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.fieldConfig.tableCss, '')
        if (!style['textAlign']) {
            style['textAlign'] = 'right'
        }
        if ((style['textAlign'] && style['textAlign'] === 'right') || !style['textAlign']) {
            style['float'] = 'right'
        }

        // let value = parseFloat(properties.data || '')
        let value = parseFloat('' + formValue || (extraFieldProperties?.zeroAsSpace ? '' : '0'))
        if (isNaN(value)) {
            value = 0
        } else {
            value = value * (extraFieldProperties.flip ? -1 : 1)
        }

        if (isZero(value) && (extraFieldProperties.zeroAsDash || extraFieldProperties.zeroAsSpace)) {
            return <div style={style}>{extraFieldProperties.zeroAsDash ? '-' : ' '}</div>
        }

        let sign = ''
        if (extraFieldProperties.withSign) {
            sign = value >= 0 ? '+' : ''
        } else if (extraFieldProperties.withNegative && value < 0) {
            sign = ''
        }

        let min = extraFieldProperties?.minimumFractionDigits || 0
        let max = extraFieldProperties?.maximumFractionDigits || 3

        // Override if both defined
        if (extraFieldProperties.decimalPlaces) {
            min = extraFieldProperties.decimalPlaces
            max = extraFieldProperties.decimalPlaces
        }

        let prefix = ''
        if (extraFieldProperties.prefix) {
            prefix = extraFieldProperties.prefix
        }
        let suffix = ''
        if (extraFieldProperties.suffix) {
            suffix = extraFieldProperties.suffix
        }

        const formattedValue = extraFieldProperties.noFormatting ? value : formatAmount(value, min, max)

        return (
            <NumberStyled
                colorise={extraFieldProperties.colorise || false}
                style={style}
                key={properties.fieldConfig.name + '_' + refreshCounter}
                value={value}
            >{`${prefix}${sign}${formattedValue}${suffix}`}</NumberStyled>
        )
    }

    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        return (
            <>
                {!hideField && (
                    <>
                        <FloatingFormStyled>
                            <FieldContentWrapperStyled inLine={properties.inline}>
                                <FieldContentStyled>
                                    <InputStyled
                                        type={'text'}
                                        id={properties?.fieldConfig?.name || 'def'}
                                        ref={valueRef}
                                        readOnly={disableField}
                                        disabled={disableField}
                                        required={required}
                                        submitted={submitted}
                                        noValue={!formValue}
                                        inLine={properties.inline}
                                    />
                                </FieldContentStyled>
                                {submitted && errorMessage && <small className="yellow">{errorMessage}</small>}
                            </FieldContentWrapperStyled>
                            <FieldLabel properties={properties} />
                        </FloatingFormStyled>
                    </>
                )}
            </>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
