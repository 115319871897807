import React, { useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { log } from '../../../services/LogService'
import { translateService } from '../../../services/TranslateService'
import { shouldHide } from '../../../services/ValidationService'
import { RunOnceEffect } from '../../../utils/Utils'
import { FieldProperties, FormMode } from '../../types'
import FieldLabel from '../field-label/FieldLabel'
import { GuslFormState } from '../maintain-form/guslFormSlice'

// import './OptionGroupField.css';

interface Permission {
    permission: string
    value: boolean
}

interface PermissionGroup {
    name: string
    permissions: Permission[]
}

export const OptionGroupsField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    {
        /*TODO GET THE ROLE SO THAT WE CAN DISPLAY ROLE'S PERMISSIONS*/
    }
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'OptionGroupsField-' + new Date().getTime())
    log.info(className, 'MSG001-properties', { properties })
    const [formMode, setFormMode] = useState(properties.formMode)

    // const [formValue, setFormValue] = useState<PermissionGroup[]>(properties?.data || []);
    const [formValue, setFormValue] = useState<PermissionGroup[]>(_guslFormState?.getFieldValue(properties) || properties.data || [])

    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [submitted, setSubmitted] = useState(false)

    const [expandedKeys, setExpandedKeys] = useState({})

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        // setTreeNodes(convertToTreeNode(mode))
    }

    const onRefresh = () => {}

    const doValidation = (fieldValue: any): boolean => {
        setSubmitted(true)

        const formData: PermissionGroup[] = fieldValue || [] // properties?.data || [];
        let haveValue = false
        formData.forEach((group) => {
            group?.permissions?.forEach((permission) => {
                if (permission.value) {
                    haveValue = true
                }
            })
        })

        if (!haveValue) {
            setErrorMessage(translateService.getTitle('NO_VALUE', 'No option selected'))
            return false
        }
        return true
    }

    RunOnceEffect(() => {
        // init
        properties.reference.changeMode = onFormModeChange
        properties.reference.doRefresh = onRefresh
        properties.reference.register(properties.reference)
        properties.reference.doValidation = doValidation
        return () => {
            // clean up
        }
    })

    const renderTableView = (): React.ReactElement => {
        return <></>
    }

    const renderFormView = (): React.ReactElement => {
        const hideField = shouldHide(formMode, properties.fieldConfig, formValue)
        // log.info(className,'MSG0034->render form view',{formMode,properties,formValue})
        return (
            <>
                {!hideField && (
                    <div className="">
                        <FieldLabel properties={properties} />
                        <div className="">{submitted && errorMessage && <small className="invalid">{errorMessage}</small>}</div>
                        {/*TODO GET THE ROLE SO THAT WE CAN DISPLAY ROLE'S PERMISSIONS*/}
                        {/*formMode:{formMode}*/}
                        {/*<PermissionsForm currentRole={'ADMIN_USER'} mode={formMode}/>*/}
                    </div>
                )}
            </>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
