import AutoNumeric from 'autonumeric'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { BlastContext } from '../../../providers/blast/BlastContext'
import { FieldDeltaDO, SimpleMessageBO } from '../../../providers/blast/commands'
import { EnvironmentContext } from '../../../providers/environment/EnvironmentContext'
import { environmentService } from '../../../providers/environment/EnvironmentService'
import { SessionContext } from '../../../providers/session/SessionContext'
import { SystemContext } from '../../../providers/system/SystemContext'
import { CONTENT_START_POSITION } from '../../../providers/theme/GuslThemeProvider'
import { log } from '../../../services/LogService'
import { isDefined } from '../../../utils/TypeCheckers'
import { RunOnceEffect, unSubscribe } from '../../../utils/Utils'
import { FieldProperties } from '../../types'
import { ComponentTitleWrapperStyled } from '../../ui/styled'
import LoadingSpinner from '../loading-spinner/LoadingSpinner'
import { LookupField } from '../lookup/LookupField'
import ActionButton from '../maintain-table/bootstrap/ActionButton'
import { TableTitleStyled } from '../maintain-table/styled'
import Money from '../money/money'
import { InputStyled } from '../text/styled'
import {
    BuySellState,
    initBuySellOrder,
    performCalculation,
    removeBuySellOrder,
    resetBuySellOrder,
    showError,
    updateBuySellOrder,
    updatePrice,
} from './buySellSlice'
import { getLookupFieldProperties } from './form-properties'
import {
    ActionButtonPanelStyled,
    ActionButtonsStyled,
    ActionButtonStyled,
    BlankLineStyled,
    BuyButtonStyled,
    BuySellBodyStyled,
    BuySellPanelFormWrapper,
    BuySellPanelStyled,
    BuySellSelectionPanelStyled,
    DisclaimerHeaderDivStyled,
    DisclaimerHeaderStyled,
    DisclaimerStyled,
    DisplayContainerStyled,
    DisplayFieldPanelStyled,
    DisplayFieldStyled,
    DisplayLabelStyled,
    DisplayValueStyled,
    ErrorPanelStyled,
    ErrorStyled,
    InlineInputStyled,
    InputWrapperStyled,
    LookupWrapperStyled,
    OrderTypeWrapperStyled,
    QuantityLabelStyled,
    QuantityWrapperStyled,
    RadioButtonContainerStyled,
    RadioButtonIconStyled,
    RadioButtonLabelStyled,
    SellButtonStyled,
    SymbolLabelStyled,
} from './styled'
import { BuySellOrder, OrderAction, PreOrderDTO, TickerDO } from './types'

interface BuySellFormProperties {
    ticker?: string
    actionParam?: string
    noTitle?: boolean
    side?: string
    isTradePanel?: boolean
    isCloseOut?: boolean
}

// code for form
const BUY_SELL_CODE = 'buy-sell'

export const BuySellFormOrig = (properties: BuySellFormProperties): React.ReactElement<BuySellFormProperties> => {
    const [className] = useState('BuySellForm-' + new Date().getTime())
    const systemContext = useContext(SystemContext)
    const sessionContext = useContext(SessionContext)
    const blastContext = useContext(BlastContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const _buySellSlice: BuySellState = useAppSelector((state) => state.buySellSlice[className])
    const [isIOS] = useState<boolean>(environmentContext.isNativeIOS())

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const params = useParams()

    const [initState, setInitState] = useState<boolean>(false)
    const [placeOrderError, setPlaceOrderError] = useState<boolean>(false)
    const isMobile = environmentContext.isMobileDevice()

    const quantityRef = useRef<any>()
    const limitRef = useRef<any>()
    const [quantityField, setQuantityField] = useState<AutoNumeric | undefined>()
    const [limitField, setLimitField] = useState<AutoNumeric | undefined>()

    const [systemReady, setSystemReady] = useState<boolean>(false)
    const [blastSubscription, setBlastSubscription] = useState<Subscription>()
    const [loading, setLoading] = useState<boolean>(false)

    const menuItem = systemContext
        .getSystemConfig()
        .menuGroups.filter((menuGroup) => menuGroup?.label === 'Trading')[0]
        ?.menuItems?.filter((menuItem) => menuItem?.code === 'NewOrder')[0]

    const registerChildReference = () => {}

    const [lookupFieldProperties, setLookupFieldProperties] = useState<any | undefined>(undefined)
    let stepMap: { [index: number]: number } = {}
    const numOfDecimals: number = 2
    const maxNumberOfDecimals = 10
    const contentElement = useRef(null)
    const [startPos, setStartPos] = useState<number>(CONTENT_START_POSITION)

    const [footerHeight, setFooterHeight] = useState<number>(0)
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription)
        }
    })

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                // @ts-ignore
                if (contentElement?.current?.offsetHeight) {
                    // @ts-ignore
                    const rect = contentElement?.current?.getBoundingClientRect()
                    if (rect) {
                        setStartPos(rect.y)
                    }
                }
            }, 100)
        })
    }, [contentElement])

    Array.from({ length: maxNumberOfDecimals }).forEach((_, idx) => {
        stepMap[idx] = 1 / Math.pow(10, idx)
    })
    const limitStep = stepMap[numOfDecimals]
    const quantityStep = stepMap[0]

    const getTickerMapping = (
        tickerParam: string,
        orderAction: OrderAction.NEW | OrderAction.CLOSE | OrderAction.TRADE,
        lookupProps: FieldProperties
    ) => {
        sessionContext
            .post<any, TickerDO[]>('/order-entry/ticker-mapping', {
                searchValue: tickerParam,
            })
            .then(
                (response) => {
                    if (response.data?.length > 0) {
                        performPreOrderQuery(response.data[0], orderAction)
                        if (orderAction === OrderAction.TRADE || orderAction === OrderAction.NEW) {
                            const value = { ...lookupProps }
                            value.data = response.data[0]?.symbol
                            setLookupFieldProperties(value)
                        }
                    } else {
                        dispatch(
                            showError({
                                code: className,
                                hasError: true,
                                errorMessage: 'Contract not mapped',
                                apiError: false,
                            })
                        )
                    }
                    setInitState(true)
                },
                (reason) => {
                    dispatch(
                        showError({
                            code: className,
                            hasError: true,
                            errorMessage: 'Failed to find ticker, contact support.',
                            apiError: true,
                        })
                    )
                    log.error(className, 'ERR001', reason)
                    setInitState(true)
                }
            )
    }

    // nice page covering options for AutoNumeric https://www.jqueryscript.net/other/Easy-Numbers-Currency-Formatting-Plugin-autoNumeric.html#google_vignette
    // https://docs.autonumeric.org/Documentation/static%20methods/#get
    useEffect(() => {
        window.requestAnimationFrame(function () {
            try {
                if (quantityRef?.current) {
                    if (!AutoNumeric.isManagedByAutoNumeric(quantityRef?.current)) {
                        const qtyElement = new AutoNumeric(quantityRef.current, _buySellSlice?.order?.getQuantity() || '', {
                            // currencySymbol: '$',
                            modifyValueOnWheel: false,
                            decimalPlaces: 0,
                            selectOnFocus: false,
                            minimumValue: '0',
                            // minimumValue: '1',
                        })
                        qtyElement.node().addEventListener('input', (event: Event) => {
                            // @ts-ignore
                            const value = parseInt((event?.target?.value ? '' + event?.target?.value : '0').replaceAll(',', ''), 10)
                            try {
                                // const number = parseFloat(event);
                                if (isDefined(value)) {
                                    dispatch(
                                        updateBuySellOrder({
                                            code: className,
                                            quantity: value || 0,
                                        })
                                    )
                                } else {
                                    dispatch(
                                        updateBuySellOrder({
                                            code: className,
                                            quantity: 0,
                                        })
                                    )
                                }
                            } catch (err) {
                                // log.warn(className, 'WRN001', 'not a number', value);
                            }
                        })
                        setQuantityField(qtyElement)
                    } else {
                        if (_buySellSlice?.order?.isCloseout() && quantityField && isDefined(_buySellSlice?.order?.getQuantity())) {
                            // @ts-ignore
                            quantityField.set(_buySellSlice.order.getQuantity())
                        }
                    }
                }
                if (limitRef?.current) {
                    if (!AutoNumeric.isManagedByAutoNumeric(limitRef?.current)) {
                        //buySellState?.order?.getQuantity() ||
                        const limitElement = new AutoNumeric(limitRef.current, _buySellSlice?.order?.getLimitPrice() || '', {
                            // currencySymbol: '$',
                            // rawValueDivisor <-- go for money
                            modifyValueOnWheel: false,
                            decimalPlaces: 8,
                            selectOnFocus: false,
                            minimumValue: '0',
                        })
                        limitElement.node().addEventListener('input', (event: Event) => {
                            // @ts-ignore
                            const value = parseFloat((event?.target?.value ? '' + event?.target?.value : '0').replaceAll(',', ''), 10)
                            try {
                                // const number = parseFloat(event);
                                if (isDefined(value)) {
                                    dispatch(
                                        updateBuySellOrder({
                                            code: className,
                                            limitPrice: value || 0,
                                        })
                                    )
                                } else {
                                    dispatch(
                                        updateBuySellOrder({
                                            code: className,
                                            limitPrice: 0,
                                        })
                                    )
                                }
                            } catch (err) {
                                // log.warn(className, 'WRN001', 'not a number', value);
                            }
                        })
                        setLimitField(limitElement)
                    }
                }
            } catch (err) {
                console.error('error', err)
            }
        })
    }, [className, systemReady, loading, _buySellSlice?.order?.getOrderType()])

    useEffect(() => {
        const tickerParam = params?.tickerId ? decodeURIComponent(params?.tickerId) : undefined
        const actionParam = params?.action ? decodeURIComponent(params?.action) : undefined

        let orderAction: OrderAction = OrderAction.NEW
        try {
            if (actionParam) {
                if (actionParam.toUpperCase() === 'NEW') {
                    orderAction = OrderAction.NEW
                } else if (actionParam.toUpperCase() === 'CLOSE') {
                    orderAction = OrderAction.CLOSE
                } else if (actionParam.toUpperCase() === 'POSITIONNEWORDER') {
                    orderAction = OrderAction.NEW
                } else if (actionParam.toUpperCase() === 'TRADE') {
                    // desktop watchlist to trade
                    // orderAction = OrderAction.TRADE
                    orderAction = OrderAction.NEW
                }
            }
        } catch (error) {
            console.error('Failed to convert', actionParam)
        }

        dispatch(
            initBuySellOrder({
                code: className,
                ticker: undefined,
                orderAction: orderAction,
                side: properties.side,
            })
        )

        if (tickerParam) {
            console.log('==> tickerParam', tickerParam)
            const lookupProps = getLookupFieldProperties(BUY_SELL_CODE, registerChildReference, getFieldConfig('realtickMapper'))

            // closeOrder
            getTickerMapping(tickerParam, orderAction, lookupProps)
        } else {
            setInitState(true)
        }

        let loaderSubscription = sessionContext.watchSystemReady().subscribe((systemReady: boolean) => {
            setSystemReady(systemReady)
            if (systemReady) {
                // set up form fields
                const lookupProps = getLookupFieldProperties(BUY_SELL_CODE, registerChildReference, getFieldConfig('realtickMapper'))

                if (properties.ticker) {
                    let ordAction = OrderAction.NEW
                    if (properties.actionParam) {
                        if (properties.actionParam.toUpperCase() === 'NEW') {
                            ordAction = OrderAction.NEW
                        } else if (properties.actionParam.toUpperCase() === 'CLOSE') {
                            ordAction = OrderAction.CLOSE
                        } else if (properties.actionParam.toUpperCase() === 'CLOSE') {
                            ordAction = OrderAction.CLOSE
                        } else if (properties.actionParam.toUpperCase() === 'TRADE') {
                            ordAction = OrderAction.TRADE
                        }
                    } else {
                        ordAction = OrderAction.NEW
                    }
                    getTickerMapping(properties.ticker, ordAction, lookupProps)
                }

                if (tickerParam) {
                    // closeOrder
                    getTickerMapping(tickerParam, orderAction, lookupProps)
                } else {
                    setLookupFieldProperties(lookupProps)
                }
            }
        })
        return () => {
            unSubscribe(loaderSubscription)
        }
    }, [className, dispatch])

    useEffect(() => {
        let loaded = true
        if (systemReady && loaded) {
            unSubscribe(blastSubscription)
            let blastDeltaSubscription: Subscription = blastContext
                .observeInboundCommands()
                .subscribe((message: SimpleMessageBO<FieldDeltaDO>) => {
                    dispatch(
                        updatePrice({
                            code: className,
                            ticker: message?.data?.keyValue,
                            bidPrice: message?.data?.value?.bid,
                            askPrice: message?.data?.value?.ask,
                        })
                    )
                })
            setBlastSubscription(blastDeltaSubscription)
        }

        return () => {
            unSubscribe(blastSubscription)
        }
    }, [className, systemReady])

    useEffect(() => {
        return () => {
            dispatch(removeBuySellOrder({ code: className }))
        }
    }, [className])

    const performPreOrderQuery = (ticker: TickerDO, orderAction: OrderAction) => {
        setLoading(true)
        sessionContext
            .post<any, PreOrderDTO>('/order-entry/pre-order', {
                id: ticker.id,
                orderAction,
            })
            .then(
                (response) => {
                    setLoading(true)
                    if (response.data.hasError) {
                        dispatch(
                            showError({
                                code: className,
                                hasError: true,
                                errorMessage: response.data.errorMessage,
                                apiError: true,
                            })
                        )
                        log.error(className, 'ERR004', response.data.errorMessage)
                    } else {
                        const data: PreOrderDTO = response.data
                        log.debug(className, 'PreOrder', data)
                        // const data: PreOrderDTO = TEST_DATA // response.data;
                        // console.log('quantityField',quantityField)
                        // if (quantityField) {
                        //     quantityField.set(data.quantity)
                        // }
                        dispatch(
                            initBuySellOrder({
                                code: className,
                                preOrder: data,
                                ticker: ticker,
                                orderAction: data.orderAction,
                                side: properties.side,
                            })
                        )
                        dispatch(
                            performCalculation({
                                code: className,
                            })
                        )
                    }
                },
                (reason) => {
                    setLoading(true)
                    dispatch(
                        showError({
                            code: className,
                            hasError: true,
                            errorMessage: 'Error with pre order, contact support.',
                            apiError: true,
                        })
                    )
                    log.error(className, 'ERR002', reason)
                }
            )
    }

    if (!systemContext.getSystemConfig().menuGroups.filter((menuGroup) => menuGroup.label === 'Trading').length) {
        return <></>
    }

    const fields = menuItem?.action?.rows[0].columns[0].fields

    const getFieldConfig = (_field: string) => {
        return fields?.filter((field) => field.name === _field)[0]
    }

    const reset = (internal: boolean, orderAction: OrderAction) => {
        if (orderAction === OrderAction.CLOSE) {
            navigate('/Position')
            return
        }

        dispatch(
            resetBuySellOrder({
                code: className,
            })
        )
        if (lookupFieldProperties?.reference?.doRefresh) {
            lookupFieldProperties.reference.doRefresh()
        }
    }

    const renderCloseOrderSymbol = (ticker: TickerDO | undefined): React.ReactElement => {
        return (
            <>
                <h4 className="fw-bold text-center text-light my-3">{ticker?.id}</h4>
            </>
        )
    }

    const renderBuySell = (buySellState: BuySellState): React.ReactElement => {
        const order: BuySellOrder | undefined = buySellState.order
        const ticker: TickerDO | undefined = buySellState.ticker
        const closeOrder: boolean = buySellState.orderAction === OrderAction.CLOSE
        const newOrder: boolean = buySellState.orderAction === OrderAction.NEW
        const tradeOrder: boolean = buySellState.orderAction === OrderAction.TRADE

        // a bit of a hack, you can only buy or sell more as a 'new order' for a position
        // const isPartOfCloseOut = isDefined(properties.ticker)

        // console.log('newOrder', newOrder)
        // console.log('tradeOrder', tradeOrder)
        // console.log('isPartOfCloseOut', properties.isCloseOut)
        // console.log('order?.getSide()', order?.getSide())
        const setSideHandler = (side: string) => {
            dispatch(
                updateBuySellOrder({
                    code: className,
                    side: side,
                })
            )
        }

        if (newOrder && !properties.isCloseOut) {
            return (
                <BuySellSelectionPanelStyled tickerId={ticker?.id || ''} id={'bssps'}>
                    <SellButtonStyled
                        role={'button'}
                        side={order?.getSide()}
                        tickerId={ticker?.id || ''}
                        onClick={() => {
                            setSideHandler('SELL')
                        }}
                    >
                        SELL {order?.getSellPrice() ? order?.getSellPrice() : ''} {order?.getSellPrice() ? buySellState.currency : ''}
                    </SellButtonStyled>
                    <BuyButtonStyled
                        role={'button'}
                        side={order?.getSide()}
                        tickerId={ticker?.id || ''}
                        onClick={() => {
                            setSideHandler('BUY')
                        }}
                    >
                        BUY {order?.getBuyPrice() ? order?.getBuyPrice() : ''} {order?.getBuyPrice() ? buySellState.currency : ''}
                    </BuyButtonStyled>
                </BuySellSelectionPanelStyled>
            )
        }
        // close out, or buying more
        if (order?.getSide() === 'BUY' || (tradeOrder && properties.isCloseOut && order?.getSide() === 'SELL')) {
            return (
                <BuySellSelectionPanelStyled tickerId={ticker?.id || ''}>
                    <BuyButtonStyled
                        role={'button'}
                        side={'BUY'}
                        tickerId={ticker?.id || ''}
                        onClick={() => {
                            setSideHandler('BUY')
                        }}
                    >
                        BUY {order?.getBuyPrice() ? order?.getBuyPrice() : ''} {order?.getBuyPrice() ? buySellState.currency : ''}
                    </BuyButtonStyled>
                </BuySellSelectionPanelStyled>
            )
        } else {
            return (
                <BuySellSelectionPanelStyled tickerId={ticker?.id || ''}>
                    <SellButtonStyled
                        role={'button'}
                        side={'SELL'}
                        tickerId={ticker?.id || ''}
                        onClick={() => {
                            setSideHandler('SELL')
                        }}
                    >
                        SELL {order?.getSellPrice() ? order?.getSellPrice() : ''} {order?.getSellPrice() ? buySellState.currency : ''}
                    </SellButtonStyled>
                </BuySellSelectionPanelStyled>
            )
        }
    }

    const renderTradeNote = (buySellState: BuySellState): React.ReactElement => {
        const order: BuySellOrder | undefined = buySellState.order
        return (
            <>
                {order?.isDelayed() && <ErrorStyled>{order?.getDelayedMessage()}</ErrorStyled>}
                {isDMA(order!) && <ErrorStyled>{order?.getDmaMessage()}</ErrorStyled>}
                {order?.isSpd() && <ErrorStyled>{order?.getSpdMessage()}</ErrorStyled>}
            </>
        )
    }
    const renderSymbolLookup = (buySellState: BuySellState): React.ReactElement => {
        const order: BuySellOrder | undefined = buySellState.order
        const ticker: TickerDO | undefined = buySellState.ticker
        const closeOrder: boolean = buySellState.orderAction === OrderAction.CLOSE
        const newOrder: boolean = buySellState.orderAction === OrderAction.NEW
        const tradeOrder: boolean = buySellState.orderAction === OrderAction.TRADE
        return (
            <LookupWrapperStyled id={'buy-sell-lookup-wrapper'}>
                <InlineInputStyled id={'buy-sell-lookup-inline'}>
                    <SymbolLabelStyled id={'buy-sell-lookup-symbol-label'}>Symbol</SymbolLabelStyled>
                    {!properties.noTitle && closeOrder && renderCloseOrderSymbol(ticker)}
                    {(newOrder || (lookupFieldProperties && tradeOrder)) && (
                        <LookupField
                            code={BUY_SELL_CODE}
                            formMode={lookupFieldProperties.formMode}
                            fieldConfig={lookupFieldProperties.fieldConfig}
                            menuItem={lookupFieldProperties.menuItem}
                            data={lookupFieldProperties.data}
                            onSubmitted={() => {}}
                            onChange={() => {}}
                            onReset={() => {
                                reset(false, buySellState.orderAction)
                            }}
                            getCurrentRowData={() => {}}
                            reference={lookupFieldProperties.reference}
                            setTicker={(selectedTicker: TickerDO) => {
                                const value = { ...lookupFieldProperties }
                                value.data = selectedTicker?.symbol
                                setLookupFieldProperties(value)
                                if (selectedTicker) {
                                    performPreOrderQuery(selectedTicker, buySellState.orderAction)
                                    // dispatch(updateTickerSelection({code: className, ticker: selectedTicker}))
                                }
                            }}
                            reset={0}
                            hasLabel={true}
                        />
                    )}
                </InlineInputStyled>
            </LookupWrapperStyled>
        )
    }

    const renderQuantity = (buySellState: BuySellState): React.ReactElement => {
        if (buySellState.apiError) {
            return <></>
        }

        const onDataInputChange = (value: string) => {
            try {
                const number = parseFloat(value)
                if (!isNaN(number)) {
                    dispatch(
                        updateBuySellOrder({
                            code: className,
                            quantity: number,
                        })
                    )
                } else {
                    dispatch(
                        updateBuySellOrder({
                            code: className,
                            quantity: 0,
                        })
                    )
                }
            } catch (err) {
                // log.warn(className, 'WRN001', 'not a number', value);
            }
        }

        return (
            <QuantityWrapperStyled>
                <InputWrapperStyled>
                    <InlineInputStyled>
                        <QuantityLabelStyled>Quantity</QuantityLabelStyled>
                        <InputStyled
                            type={'tel'}
                            ref={quantityRef}
                            style={{ width: '200px' }}
                            // step={quantityStep}
                            id={'quantity'}
                            placeholder={'Quantity'}
                            autoComplete={'off'}
                            // onChange={(e) => onDataInputChange(e.target.value)}
                            required={true}
                            submitted={false}
                            noValue={false}
                            // value={buySellState?.order?.getQuantity() || ''}
                        />
                        {/*{submitted && errorMessage &&*/}
                        {/*    <small className="yellow">{errorMessage}</small>}*/}
                    </InlineInputStyled>
                </InputWrapperStyled>
            </QuantityWrapperStyled>
        )
    }
    //                 {quantityField && fieldService.getFormTemplate(quantityField)}
    const renderOrderTypeAsRadio = (buySellState: BuySellState): React.ReactElement => {
        const isCloseOut = buySellState?.order?.isCloseout() || false
        const isMarket: boolean = (isCloseOut ? 'LIMIT' : buySellState?.order?.getOrderType() || 'MARKET') === 'MARKET'

        const onOrderType = (e: React.MouseEvent<HTMLElement, MouseEvent>, orderType: string) => {
            if (e) {
                e.stopPropagation()
            }
            dispatch(
                updateBuySellOrder({
                    code: className,
                    orderType: orderType,
                })
            )
        }

        const onDataInputChange = (value: string) => {
            try {
                const number = parseFloat(value)
                if (!isNaN(number)) {
                    dispatch(
                        updateBuySellOrder({
                            code: className,
                            limitPrice: number,
                        })
                    )
                } else {
                    dispatch(
                        updateBuySellOrder({
                            code: className,
                            limitPrice: 0,
                        })
                    )
                }
            } catch (err) {
                // log.warn(className, 'WRN001', 'not a number', value);
            }
        }

        return (
            <OrderTypeWrapperStyled>
                {!isCloseOut && (
                    <RadioButtonContainerStyled onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onOrderType(e, 'MARKET')}>
                        <RadioButtonIconStyled active={isMarket} />
                        <RadioButtonLabelStyled>Market</RadioButtonLabelStyled>
                    </RadioButtonContainerStyled>
                )}
                <RadioButtonContainerStyled>
                    <RadioButtonIconStyled
                        active={!isMarket}
                        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onOrderType(e, 'LIMIT')}
                    />
                    <RadioButtonLabelStyled onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onOrderType(e, 'LIMIT')}>
                        Limit
                    </RadioButtonLabelStyled>
                    {!isMarket && (
                        <InputWrapperStyled>
                            <InlineInputStyled>
                                <InputStyled
                                    type={'tel'}
                                    ref={limitRef}
                                    step={limitStep}
                                    id={'limitPrice'}
                                    placeholder={'Limit Price'}
                                    autoComplete={'off'}
                                    style={{ width: '200px' }}
                                    // onChange={(e) => onDataInputChange(e.target.value)}
                                    required={true}
                                    submitted={false}
                                    noValue={false}
                                    // value={buySellState?.order?.getLimitPrice() || ''}
                                />
                                {/*{submitted && errorMessage &&*/}
                                {/*    <small className="yellow">{errorMessage}</small>}*/}
                            </InlineInputStyled>
                        </InputWrapperStyled>
                    )}
                </RadioButtonContainerStyled>
            </OrderTypeWrapperStyled>
        )
    }

    const renderError = (buySellState: BuySellState): React.ReactElement => {
        return <ErrorPanelStyled>{buySellState.errorMessage}</ErrorPanelStyled>
    }

    const renderPlaceOrderError = (): React.ReactElement => {
        return (
            <>
                <ErrorStyled>Something went wrong, did you select all the fields?.</ErrorStyled>
            </>
        )
    }

    const isDMA = (order: BuySellOrder): Boolean => {
        isDefined(order)
        {
            if (order.isDelayed()) {
                return false
            }

            return order!.isDma()
        }

        return false
    }

    const renderPlaceOrderButtons = (buySellState: BuySellState): React.ReactElement => {
        const order: BuySellOrder | undefined = buySellState.order
        const ticker: TickerDO | undefined = buySellState.ticker

        const placeOrderHandler = async () => {
            setPlaceOrderError(false)

            if (menuItem?.action?.url) {
                sessionContext
                    .post<any, any>(menuItem?.action?.url, {
                        side: order!.getSide(),
                        realtickMapper: ticker,
                        quantity: order?.getQuantity(),
                        orderType: order!.getOrderType(),
                        validity: order!.getValidity(),
                        limitPrice: order!.getLimitPrice(),
                        price: order!.getPrice(),
                        concd: order!.getConcd(),
                        dma: isDMA(order!),
                    })
                    .then(
                        () => {
                            reset(true, buySellState.orderAction)
                            setPlaceOrderError(false)
                            navigate('/Order')
                        },
                        (reason) => {
                            setPlaceOrderError(true)
                            log.error(className, 'ERR003', reason)
                        }
                    )
            }
        }

        return (
            <>
                <ActionButtonPanelStyled
                    side={order?.getSide()}
                    isMobile={true}
                    canShowInfo={buySellState.canShowInfo}
                    tickerId={ticker?.id || ''}
                >
                    <ActionButtonsStyled>
                        <ActionButtonStyled>
                            <ActionButton
                                label={'Cancel'}
                                action={'warning'}
                                onClick={() => reset(true, buySellState.orderAction)}
                                icon={'fa-solid fa-cancel'}
                            />
                        </ActionButtonStyled>
                        {buySellState.canOrder && (
                            <ActionButtonStyled>
                                <ActionButton
                                    label={isDMA(order!) ? 'Place order' : 'Place care order'}
                                    action={'save'}
                                    onClick={placeOrderHandler}
                                    icon={'fa-solid fa-check'}
                                />
                            </ActionButtonStyled>
                        )}
                    </ActionButtonsStyled>
                    {buySellState.canOrder && <DisclaimerStyled>NB: Market price may vary from actual Execution price.</DisclaimerStyled>}
                </ActionButtonPanelStyled>
            </>
        )
    }

    const renderDisplayInfo = (buySellState: BuySellState): React.ReactElement => {
        const order: BuySellOrder | undefined = buySellState.order
        return (
            <>
                <DisplayContainerStyled>
                    <DisplayFieldPanelStyled isMobile={true}>
                        <DisplayFieldStyled>
                            <DisplayLabelStyled>Free equity</DisplayLabelStyled>
                            <DisplayValueStyled>
                                <Money amount={buySellState.freeEquity} key={'freeEquity'} />
                            </DisplayValueStyled>
                        </DisplayFieldStyled>

                        {buySellState.orderAction !== OrderAction.CLOSE && (
                            <DisplayFieldStyled>
                                <DisplayLabelStyled>Available equity</DisplayLabelStyled>
                                <DisplayValueStyled>
                                    <Money amount={buySellState.availableEquity} key={'availableEquity'} />
                                </DisplayValueStyled>
                            </DisplayFieldStyled>
                        )}

                        {buySellState.orderAction !== OrderAction.CLOSE && (
                            <DisplayFieldStyled>
                                <DisplayLabelStyled>Margin requirement ({(buySellState.margin || 0) * 100} %)</DisplayLabelStyled>
                                <DisplayValueStyled>
                                    <Money amount={buySellState.marginRequired} key={'marginRequired'} />
                                </DisplayValueStyled>
                            </DisplayFieldStyled>
                        )}

                        <DisplayFieldStyled>
                            <DisplayLabelStyled>Order Price</DisplayLabelStyled>
                            <DisplayValueStyled>
                                <span>
                                    {order?.getOrderPrice()?.toLocaleString('en-GB', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 8,
                                    })}
                                </span>
                            </DisplayValueStyled>
                        </DisplayFieldStyled>

                        <DisplayFieldStyled>
                            <DisplayLabelStyled>Quantity</DisplayLabelStyled>
                            <DisplayValueStyled>
                                <span className={buySellState.canOrder ? '' : 'invalid'}>{order?.getQuantity().toLocaleString()}</span>
                            </DisplayValueStyled>
                        </DisplayFieldStyled>

                        <DisplayFieldStyled>
                            <DisplayLabelStyled>Notional</DisplayLabelStyled>
                            <DisplayValueStyled>
                                <Money amount={buySellState.notionalPrice} key={'notionalPrice'} />
                            </DisplayValueStyled>
                        </DisplayFieldStyled>
                    </DisplayFieldPanelStyled>
                    {renderPlaceOrderButtons(buySellState)}
                </DisplayContainerStyled>
            </>
        )
    }

    const renderHeader = (buySellState: BuySellState): React.ReactElement => {
        return (
            <>
                <ComponentTitleWrapperStyled>
                    <TableTitleStyled isMobile={false} footerHeight={footerHeight}>
                        {buySellState.orderAction === OrderAction.CLOSE ? 'Close Out Form' : 'New Order Form'}
                        {isMobile && <DisclaimerHeaderDivStyled>*All GBP quotes in Pounds not pence.</DisclaimerHeaderDivStyled>}
                        {!isMobile && <DisclaimerHeaderStyled>*All GBP quotes in Pounds not pence.</DisclaimerHeaderStyled>}
                    </TableTitleStyled>
                </ComponentTitleWrapperStyled>
            </>
        )
    }

    const renderPage = (buySellState: BuySellState): React.ReactElement => {
        if (!buySellState.order) {
            return <></>
        }
        const isPartOfCloseOut = isDefined(properties.ticker)

        return (
            <div className="row-g-0" id={'bs_wrapper'}>
                {!properties.noTitle && renderHeader(buySellState)}
                <BuySellBodyStyled id={'bs_body'} isMobile={isMobile} ref={contentElement} startPos={startPos} footerHeight={footerHeight}>
                    <BuySellPanelFormWrapper
                        isMobile={isMobile}
                        isTradePanel={properties.isTradePanel}
                        isIOS={isIOS}
                        footerHeight={footerHeight}
                        id={'bs_panel_wrapper'}
                    >
                        <BuySellPanelStyled id={'bs_panel'}>
                            {placeOrderError && renderPlaceOrderError()}
                            {!isPartOfCloseOut && renderSymbolLookup(buySellState)}
                            {renderBuySell(buySellState)}
                            {renderTradeNote(buySellState)}
                            {renderQuantity(buySellState)}
                            {renderOrderTypeAsRadio(buySellState)}
                            {buySellState.hasError && renderError(buySellState)}
                            {buySellState.canShowInfo && renderDisplayInfo(buySellState)}
                            <BlankLineStyled>&nbsp;</BlankLineStyled>
                        </BuySellPanelStyled>
                    </BuySellPanelFormWrapper>
                </BuySellBodyStyled>
            </div>
        )
    }

    const mapStateToProps = (state: any): BuySellState => {
        return state.buySellSlice[className]
    }

    const ConnectedRenderPage = connect(mapStateToProps)(renderPage)
    return <>{!initState ? <LoadingSpinner /> : renderPage(_buySellSlice)}</>
}
/*
        <>
            {!initState ? <LoadingSpinner/> : <ConnectedRenderPage/>}
        </>

 */
