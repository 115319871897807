import React from 'react'
import { UserIconSvgStyled } from './styled'

export const UserIcon = (): React.ReactElement => {
    return (
        <UserIconSvgStyled width={'20px'}>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5 18.0141V16.3474C5 15.4634 5.35119 14.6155 5.97631 13.9904C6.60143 13.3653 7.44928 13.0141 8.33333 13.0141H11.6667C12.5507 13.0141 13.3986 13.3653 14.0237 13.9904C14.6488 14.6155 15 15.4634 15 16.3474V18.0141M6.66667 6.34743C6.66667 7.23149 7.01786 8.07933 7.64298 8.70446C8.2681 9.32958 9.11594 9.68077 10 9.68077C10.8841 9.68077 11.7319 9.32958 12.357 8.70446C12.9821 8.07933 13.3333 7.23149 13.3333 6.34743C13.3333 5.46338 12.9821 4.61553 12.357 3.99041C11.7319 3.36529 10.8841 3.0141 10 3.0141C9.11594 3.0141 8.2681 3.36529 7.64298 3.99041C7.01786 4.61553 6.66667 5.46338 6.66667 6.34743Z"
                    stroke="#000"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </UserIconSvgStyled>
    )
}
