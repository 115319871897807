import { CurrencyDTO, MoneyDTO, MoneyTO } from '../components/types'

const getLanguage = () => new Intl.NumberFormat().resolvedOptions().locale

export const applyDecimals = (n: number, decimalPlace: number) => {
    return n / Math.pow(10, decimalPlace)
}

export const formatAmount = (amount: number): string => {
    return amount.toLocaleString(getLanguage(), {
        //minimumFractionDigits: countDecimals(amount),
        minimumFractionDigits: 2,
    })
}

export const convertToMoney = (
    currencyMap: {
        [id: string]: CurrencyDTO
    },
    amount: MoneyDTO | MoneyTO | undefined
): MoneyDTO | MoneyTO | undefined => {
    if (!amount || !currencyMap) {
        return amount
    }
    // @ts-ignore
    if (amount.currency) {
        return amount
    }
    // @ts-ignore
    if (amount.c) {
        // @ts-ignore
        const code = amount.c
        const dto: MoneyDTO = {
            // @ts-ignore
            value: amount.v,
            currency: currencyMap[code],
        }
        return dto
    }

    return amount
}
