import { MoneyDTO } from '../../types'

export const PROPERTY_NAMES: string[] = [
    'showCurrency',
    'defaultZero',
    'colorise',
    'flip',
    'withSign',
    'withNegative',
    'zeroAsDash',
    'zeroAsSpace',
    'suffix',
    'decimalPlaces',
    'nonDecimal',
    'minimumFractionDigits',
    'maximumFractionDigits',
    'showOddsType',
    'showLogo',
    'noFormatting',
]

export type ExtraFieldProperties = {
    /**
     * money value
     */
    amount: MoneyDTO | any | undefined

    /**
     * Show Currency
     * @default false
     */
    showCurrency?: boolean

    /**
     * if amount is undefined show zero amount
     * currency symbol will be an issue
     * @default false
     */
    defaultZero?: boolean

    /**
     * apply colors to amount ('green' for +ve, 'red' for -ve)
     * @default false
     */
    colorise?: boolean

    /**
     * prefix multiply value by -1
     * @default false
     */
    flip?: boolean

    /**
     * prefix show + (+ve) and - (-ve) signs
     * @default false
     */
    withSign?: boolean

    /**
     * only show - (-ve) sign
     * @default false
     */
    withNegative?: boolean

    /**
     * when value is zero show dash
     * * @default false
     */
    zeroAsDash?: boolean

    /**
     * when value is zero show space
     * @default false
     */
    zeroAsSpace?: boolean

    /**
     * appended to value
     * @default false
     */
    suffix?: string

    /**
     * used when formatting number
     * @default 2
     */
    decimalPlaces?: number

    /**
     * Strip the pence
     */
    nonDecimal?: boolean

    /**
     * The minimum number of integer digits to use. A value with a smaller number of integer digits than this number will be left-padded with zeros (to the specified length) when formatted.
     * Possible values are from 1 to 21; the default is 1.
     */
    minimumFractionDigits?: number

    /**
     * The minimum number of fraction digits to use. Possible values are from 0 to 20; the default for plain number and percent formatting is 0
     */
    maximumFractionDigits?: number

    /**
     * sh0w odds type
     */
    showOddsType?: boolean

    /**
     * display logo
     */
    showLogo?: boolean

    /**
     * no formatting for numeric vales
     */
    noFormatting?: boolean

    /**
     * if true do not use styled
     */
    raw?: boolean
}
