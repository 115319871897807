import { SectorProperties } from 'grapesjs'
import { DecorationStyle } from './definitions/DecorationStyle'
import { DimensionStyle } from './definitions/DimensionStyle'
import { FlexStyle } from './definitions/FlexStyle'
import { GeneralStyle } from './definitions/GeneralStyle'
import { TransformStyle } from './definitions/TransformStyle'
import { TypographyStyle } from './definitions/TypographyStyle'

export interface StyleRegistryProperties {
    id: string
    sector: SectorProperties
}

export const StyleRegistry: StyleRegistryProperties[] = [
    {
        id: 'general',
        sector: GeneralStyle,
    },
    {
        id: 'typography',
        sector: TypographyStyle,
    },
    {
        id: 'decoration',
        sector: DecorationStyle,
    },
    {
        id: 'dimension',
        sector: DimensionStyle,
    },
    {
        id: 'flex',
        sector: FlexStyle,
    },
    {
        id: 'transform',
        sector: TransformStyle,
    },
]
