import React, { useContext, useState } from 'react'
import { BuySellState } from '../../buySellSlice'
import { BuySellOrder, TickerDO } from '../../types'
import { SessionContext } from '../../../../../providers/session/SessionContext'
import { SystemContext } from '../../../../../providers/system/SystemContext'
import { useNavigate } from 'react-router-dom'
import { log } from '../../../../../services/LogService'
import { ActionButtonPanelStyled, ActionButtonsStyled, ActionButtonStyled, DisclaimerStyled } from '../../styled_v2'
import ActionButton from '../../../maintain-table/bootstrap/ActionButton'

export interface renderPlaceOrderButtonsDO {
    buySellState: BuySellState
    setPlaceOrderError: Function
    isDMA: Function
    reset: Function
}

export default function RenderPlaceOrderButtons({ buySellState, setPlaceOrderError, isDMA, reset }: renderPlaceOrderButtonsDO) {
    const [className] = useState('RenderPlaceOrderButtons-' + new Date().getTime())
    const sessionContext = useContext(SessionContext)
    const systemContext = useContext(SystemContext)
    const navigate = useNavigate()
    const menuItem = systemContext
        .getSystemConfig()
        .menuGroups.filter((menuGroup) => menuGroup?.label === 'Trading')[0]
        ?.menuItems?.filter((menuItem) => menuItem?.code === 'NewOrder')[0]
    const order: BuySellOrder | undefined = buySellState.order
    const ticker: TickerDO | undefined = buySellState.ticker
    const placeOrderHandler = async () => {
        setPlaceOrderError(false)

        if (menuItem?.action?.url) {
            sessionContext
                .post<any, any>(menuItem?.action?.url, {
                    side: order!.getSide(),
                    realtickMapper: ticker,
                    quantity: order?.getQuantity(),
                    orderType: order!.getOrderType(),
                    validity: order!.getValidity(),
                    limitPrice: order!.getLimitPrice(),
                    price: order!.getPrice(),
                    concd: order!.getConcd(),
                    dma: isDMA(order!),
                })
                .then(
                    () => {
                        reset(true, buySellState.orderAction)
                        setPlaceOrderError(false)
                        navigate('/Order')
                    },
                    (reason) => {
                        setPlaceOrderError(true)
                        log.error(className, 'ERR003', reason)
                    }
                )
        }
    }

    return (
        <>
            <ActionButtonPanelStyled
                side={order?.getSide()}
                isMobile={true}
                canShowInfo={buySellState.canShowInfo}
                tickerId={ticker?.id || ''}
            >
                <ActionButtonsStyled>
                    <ActionButtonStyled>
                        <ActionButton
                            label={'Cancel'}
                            action={'warning'}
                            onClick={() => reset(true, buySellState.orderAction)}
                            icon={'fa-solid fa-cancel'}
                            extraClass={'xs-small'}
                        />
                    </ActionButtonStyled>
                    {buySellState.canOrder && (
                        <ActionButtonStyled>
                            <ActionButton
                                label={isDMA(order!) ? 'Place order' : 'Place care order'}
                                action={'save'}
                                onClick={placeOrderHandler}
                                icon={'fa-solid fa-check'}
                                extraClass={'xs-small'}
                            />
                        </ActionButtonStyled>
                    )}
                </ActionButtonsStyled>
                {buySellState.canOrder && <DisclaimerStyled>NB: Market price may vary from actual Execution price.</DisclaimerStyled>}
            </ActionButtonPanelStyled>
        </>
    )
}
