// import {classNames} from 'primereact/utils';
import React, { useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { hasValidation, shouldDisable, shouldHide } from '../../../services/ValidationService'
import { FieldProperties } from '../../types'
import FieldLabel from '../field-label/FieldLabel'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { SymbolFieldStyled } from './styled'

// copy me when creating a new field
// add me to (1) FieldService.ts
/* eslint-disable @typescript-eslint/no-unused-vars */
export const SymbolField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'SymbolField-' + new Date().getTime())
    const [formMode, setFormMode] = useState(properties.formMode)
    const [tableValue] = useState<any>({ __html: properties.data || '' })

    // const [formValue, setFormValue] = useState<string>(properties?.data || '');
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) || properties.data || '')

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [submitted, setSubmitted] = useState(false)

    const renderTableView = (): React.ReactElement => {
        return <SymbolFieldStyled textAlign={properties.fieldConfig.align} dangerouslySetInnerHTML={tableValue}></SymbolFieldStyled>
    }

    const renderFormView = (): React.ReactElement => {
        const onInputChange = (value: string) => {
            setFormValue(value)
            properties.onChange(properties.fieldConfig.name, value)
        }

        const hideField = shouldHide(formMode, properties.fieldConfig)
        const disableField: boolean = shouldDisable(formMode, properties.fieldConfig)
        const required = hasValidation(properties.fieldConfig?.validators)

        return (
            <>
                {!hideField && (
                    <div className="p-field p-grid">
                        <span className={'text-danger'}>PRComp</span>
                        <FieldLabel properties={properties} />
                        <div className="p-col-12 p-md-8">
                            <input
                                id={properties?.fieldConfig?.name || 'def'}
                                value={formValue}
                                onChange={(e) => onInputChange(e.target.value)}
                                required={required}
                                disabled={disableField}
                                className={'p-inputtext p-component' + submitted && !formValue ? 'p-invalid' : ''}
                            />
                            {submitted && !formValue && (
                                <small className="p-invalid">{properties?.fieldConfig?.label || ''} is required.</small>
                            )}
                        </div>
                    </div>
                )}
            </>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
