import React, { useContext, useEffect, useState } from 'react'
import { AlignDirection } from 'react-bootstrap/types'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../app/hooks'
import { EnvironmentContext } from '../../../../providers/environment/EnvironmentContext'
import { environmentService } from '../../../../providers/environment/EnvironmentService'
import { guslStorage } from '../../../../providers/session/GuslStorage'
import { SessionContext } from '../../../../providers/session/SessionContext'
import { SystemContext } from '../../../../providers/system/SystemContext'
import { GuslThemeContext } from '../../../../providers/theme/GuslThemeProvider'
import { log } from '../../../../services/LogService'
import { constructUrl, matchMediaTypeWithRequiredAndSensitivity } from '../../../../utils/Utils'
import { setActiveAction } from '../../../common/action-dialog/actionSlice'
import { ElementWithTooltip } from '../../../common/element-with/element-with-tooltip/ElementWithTooltip'
import { GlobalSearch } from '../../../common/global-search/GlobalSearch'
import { GlobalSearchDTO } from '../../../common/global-search/type'
import { ActionHandler } from '../../../common/maintain-table/ActionHandler'
import {
    ActionType,
    IMenuDTO,
    MediaType,
    NotificationPosition,
    NotificationType,
    SystemNotificationDTO,
    WidgetPanelProperties,
} from '../../../types'
import { openDialog } from '../left-hand-menu/footerOverlaySlice'
import { handleNavigationClicks } from '../site-tabs/handleNavigationClicks'
import { DropdownItemStyled, DropdownMenuStyled, DropdownStyled, DropdownToggledStyled, IconStyled, MenuImageStyled } from './styled'

type Props = {
    menuGroup: IMenuDTO[]
    isMobile?: boolean

    entityId?: string
    widgetPanelProperties?: WidgetPanelProperties
    rowData?: any
}
const MainToolbarDropdown = ({ menuGroup, isMobile, entityId, widgetPanelProperties, rowData }: Props): React.ReactElement => {
    const [className] = useState('MainToolbarDropdown-' + new Date().getTime())
    const [isEntered, setIsEntered] = useState<boolean>(false)
    const sessionContext = useContext(SessionContext)
    const systemContext = React.useContext(SystemContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const guslThemeContext = React.useContext(GuslThemeContext)

    const [mediaType, setMediaType] = useState<MediaType>(environmentContext.getCurrentMediaType(widgetPanelProperties))
    const [globalSearch] = useState<GlobalSearchDTO | undefined>(() => {
        if (menuGroup.length === 1) {
            if (menuGroup[0].menuItems) {
                return menuGroup[0]?.globalSearch
            }
        }
        return undefined
    })

    const dispatch = useAppDispatch()

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (isMobile) {
            setIsEntered(true)
        }
        return () => {
            // commented out because of changepassword not showing modal
            // dispatch(cleanUpActiveAction())
        }
    }, [menuGroup])

    const navigate = useNavigate()

    function logoutHandler() {
        sessionContext
            .signOut()
            .then(() => {})
            .catch((error: any) => console.log('logout error', error))
        navigate('/login')
    }

    function printScreenHandler() {
        window.print()
    }

    const commands: { [id: string]: Function } = {
        print: () => printScreenHandler(),
        logout: () => logoutHandler(),
    }

    const onItemClick = (event: React.MouseEvent, item: IMenuDTO) => {
        event.stopPropagation()
        if (!item || (item.menuItems && item.menuItems.length > 0)) {
            // it is a parent
            setIsEntered(!isEntered)
            return
        }
        handleNavigationClicks({ event, item, dispatch })
        setIsEntered(false)

        if (item.route === 'BetSlip' || (item.overlay && item.route)) {
            if (isMobile) {
                environmentService.closeSideMenu()
                dispatch(
                    openDialog({
                        title: item?.label || '',
                        route: 'RequestQuote' /*item.route*/,
                        placement: 'bottom',
                    })
                )
                return
            } else {
                dispatch(
                    openDialog({
                        title: item?.label || '',
                        route: 'RequestQuote' /*item.route*/,
                        placement: 'bottom',
                    })
                )
                return
            }
        }

        if (item.route) {
            navigate(item.route)
            return
        }
        if (item?.action?.command) {
            if (item?.action?.command === 'clear.ordered.fields') {
                guslStorage.clearOrderedFields()
                systemContext.toast({
                    type: NotificationType.SUCCESS,
                    position: NotificationPosition.BOTTOM_RIGHT,
                    message: 'Browser cache cleared',
                    autoCloseDuration: 2000,
                } as SystemNotificationDTO)
            }
            return
        }
        if (item.command) {
            commands[item.command]()
            return
        }

        if (item.commandFunction) {
            item.commandFunction(event, item)
            return
        }

        //inlineMenuGroupAction
        if (item.action) {
            if (item.action.actionType === ActionType.ROUTE && item.action?.route) {
                navigate(constructUrl(item.action.route || '', { id: entityId }))
            } else if (item.action.actionType === ActionType.DOWNLOAD_NO_PROMPT) {
                if (item.downloadFunction) {
                    item.downloadFunction(item.action)
                }
            } else if (item.action.actionType === ActionType.ACTION_ONLY) {
                if (item.actionOnlyFunction) {
                    item.actionOnlyFunction(item.action)
                } else {
                    const actionHandler: ActionHandler = new ActionHandler(navigate, sessionContext, systemContext, dispatch)
                    actionHandler
                        .handle('action', item.action, {}, { id: entityId })
                        .then((complete: boolean) => {
                            console.log('- complete')
                        })
                        .catch((errors) => {
                            console.log('errors', errors)
                        })
                }
            } else {
                const paramData: any = rowData || {}
                if (!paramData.id) {
                    paramData.id = entityId
                }
                console.log('--action-> ', item.action)
                console.log('--update-> ', item.action.url, paramData, constructUrl(item.action.url, paramData))
                console.log('--template-> ', item.action.templateUrl, paramData, constructUrl(item.action.templateUrl, paramData))

                dispatch(
                    setActiveAction({
                        action: {
                            ...item.action,
                            url: constructUrl(item.action.url, paramData),
                            templateUrl: constructUrl(item.action.templateUrl, paramData),
                        },
                    })
                )
                // dispatch(setActiveAction({
                //     ...item.action,
                //     url: constructUrl(item.action.url, {id: entityId}),
                //     templateUrl: constructUrl(item.action.templateUrl, {id: entityId})
                // }))
            }
            return
        }

        if (item.code) {
            log.info(className, 'MSG001', 'navi')
            navigate('' + item.code)
        }
    }

    const renderWithChildren = (): React.ReactElement => {
        const parent = menuGroup[0]
        const align: AlignDirection = 'end' === parent.align ? 'end' : 'start'
        const isFontAwesome = (parent.icon || '').startsWith('fa')
        const logoUrl =
            environmentService.getImageSrc(
                parent.icon || '',
                environmentService.getEnvironment()?.assetsUrl || '',
                guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).imageSuffix
            ) || ''
        return (
            <>
                <DropdownStyled align={align} $isEntered={isEntered} $isMobile={isMobile}>
                    <DropdownToggledStyled
                        onMouseEnter={() => {
                            setIsEntered(true)
                        }}
                        onMouseLeave={() => {
                            setIsEntered(false)
                        }}
                        onClick={() => {
                            setIsEntered(!isEntered)
                        }}
                    >
                        <div
                            onMouseEnter={() => {
                                setIsEntered(true)
                            }}
                            onMouseLeave={() => {
                                setIsEntered(true)
                            }}
                            onClick={(e) => onItemClick(e, parent)}
                        >
                            {parent.icon && parent.iconOnly ? (
                                <>
                                    {' '}
                                    {isFontAwesome && <IconStyled icon={parent.icon} $isItem={false} />}
                                    {!isFontAwesome && <MenuImageStyled src={logoUrl} alt="image" $isItem={false} />}
                                    {isMobile && parent.label}
                                </>
                            ) : (
                                parent.label
                            )}
                        </div>
                    </DropdownToggledStyled>
                    <div style={{ marginBottom: '20px' }}>
                        {isMobile &&
                            (parent.menuItems || [])
                                .filter((menuGroup) => matchMediaTypeWithRequiredAndSensitivity(menuGroup.mediaType, mediaType, true))
                                .map((item, idx) => (
                                    <DropdownItemStyled
                                        key={'c_' + idx}
                                        className={'ps-3 pt-2'}
                                        onClick={(e: React.MouseEvent<Element, MouseEvent>) => onItemClick(e, item)}
                                    >
                                        <div style={{ marginLeft: '20px' }}>
                                            {item.icon ? <IconStyled icon={item.icon} label={item.label} $isItem={false} /> : item.label}
                                        </div>
                                    </DropdownItemStyled>
                                ))}
                    </div>
                    {!isMobile && (
                        <DropdownMenuStyled
                            show={isEntered}
                            onMouseEnter={() => {
                                setIsEntered(true)
                            }}
                            onMouseLeave={() => {
                                setIsEntered(false)
                            }}
                            onClick={() => {
                                setIsEntered(false)
                            }}
                        >
                            {(parent.menuItems || []).map((item, idx) => (
                                <DropdownItemStyled key={'c_' + idx} idx={idx} onClick={(e: React.MouseEvent) => onItemClick(e, item)}>
                                    {item.icon ? <IconStyled icon={item.icon} label={item.label} $isItem={false} /> : item.label}
                                </DropdownItemStyled>
                            ))}
                        </DropdownMenuStyled>
                    )}
                </DropdownStyled>
            </>
        )
    }

    const renderGlobalSearch = (): React.ReactElement => {
        return <p>Global Search</p>
    }

    const renderNoChildren = (): React.ReactElement => {
        const parent = menuGroup[0]

        if (globalSearch) {
            return <GlobalSearch {...globalSearch} code={parent.code} />
        }

        const align: AlignDirection = 'end' === parent.align ? 'end' : 'start'
        return (
            <>
                <DropdownStyled align={align} $isEntered={isEntered} $isMobile={isMobile}>
                    <DropdownToggledStyled
                        onMouseEnter={() => {
                            setIsEntered(true)
                        }}
                        onMouseLeave={() => {
                            setIsEntered(false)
                        }}
                        onClick={() => {
                            setIsEntered(false)
                        }}
                    >
                        <div onClick={(e) => onItemClick(e, parent)}>
                            {parent.icon && parent.iconOnly ? (
                                typeof parent.action?.tooltip === 'undefined' ? (
                                    <IconStyled icon={parent.icon} $isItem={false} />
                                ) : (
                                    <ElementWithTooltip
                                        element={
                                            <span>
                                                {' '}
                                                <IconStyled icon={parent.icon} $isItem={false} />
                                            </span>
                                        }
                                        tooltip={<span>{parent.action?.tooltip}</span>}
                                    />
                                )
                            ) : (
                                <></>
                            )}
                            {parent.icon && !parent.iconOnly ? (
                                <>
                                    <IconStyled icon={parent.icon} $isItem={false} /> <>{parent.label}</>
                                </>
                            ) : (
                                <></>
                            )}
                            {!parent.icon ? <>{parent.label}</> : <></>}
                        </div>
                    </DropdownToggledStyled>
                </DropdownStyled>
            </>
        )
    }

    const menuHasChildren = (): boolean => {
        if (menuGroup.length === 1) {
            if (menuGroup[0].menuItems) {
                return menuGroup[0].menuItems.length > 0
            }
        }
        return false
    }

    const renderToolbarItems = (): React.ReactElement => {
        return <>{menuHasChildren() ? renderWithChildren() : renderNoChildren()}</>
    }

    return <>{renderToolbarItems()}</>
}
export default MainToolbarDropdown
