import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { SessionContextProps } from '../../../providers/session/types'
import { isObject } from '../../../utils/TypeCheckers'
import { clone, constructUrl, safeStream } from '../../../utils/Utils'
import { ActionCompletedBO, EndpointDTO, FieldConfigDTO, FieldProperties, TableRowDTO } from '../../types'
import { maintainTableService } from '../maintain-table/MaintainTableService'
import { Option } from '../option/OptionField'

export interface MasterGuslFormState {
    [id: string]: GuslFormState
}

export interface GuslFormState {
    code: string
    fields: FieldConfigDTO[]
    formData: any
    rowData: any
    counter: number
    primaryObjectName?: string | undefined
    getFieldValue: (properties: FieldProperties) => any
    getValueForField: (name: string) => any
    origFormData: any | undefined
}

export interface GuslFormInitState {
    code: string
    fields: FieldConfigDTO[]
    formData: any
    rowData: any
    panelCode?: string
}

export interface GuslFormCleanUpState {
    code: string
}

const initialState: MasterGuslFormState = {}

export interface GuslFormUpdateAction {
    code: string
    name: string
    value: any | undefined
    overwrite?: boolean | undefined
    panelName?: string | undefined
}

export interface NestedRowAction {
    code: string
    name: string
    append?: boolean
    rowIndex?: number
}

export interface NestedUpdateRowAction {
    code: string
    name: string
    value: any
    rowIndex: number
    parentFieldName: string | undefined
}

export interface GuslFormResetAction {
    code: string
}

export interface GuslFormCleanUpPayload {
    code: string
}

const getOptions = (fieldConfig: FieldConfigDTO): Option[] => {
    const options: Option[] = []
    if (fieldConfig.lookupCollection) {
        // blastContext.getCollection(fieldConfig.lookupCollection).forEach(item => {
        //     options.push({value: item.id, label: item.name})
        // })
    } else {
        ;(fieldConfig.options || []).forEach((option) => {
            options.push({ value: option, label: option })
        })
    }
    return options
}
const loadInitValues = (values: GuslFormInitState): GuslFormState => {
    let fields: FieldConfigDTO[] = clone(values.fields)
    safeStream(fields)
        .filter((field) => field.type === 'option')
        .forEach((fieldConfig) => {
            let fieldData: Option | undefined = undefined
            if (values.formData && fieldConfig?.name) {
                fieldData = values.formData[fieldConfig.name]
            }
            if (!fieldData) {
                const options = getOptions(fieldConfig)
                if (options && options.length > 0) {
                    values.formData[fieldConfig.name] = options[0].value
                }
            }
        })

    return {
        code: values.code,
        formData: values.formData,
        rowData: values.rowData,
        fields: fields.sort((a, b) => (a?.displayOrder || 0) - (b?.displayOrder || 0)),
        counter: 0,
        getFieldValue(properties: FieldProperties) {
            if (this.formData && properties?.fieldConfig?.name) {
                return this.formData[properties.fieldConfig.name]
            } else {
                return undefined
            }
        },
        getValueForField(name: string) {
            return this.formData ? this.formData[name] : undefined
        },
        origFormData: values.formData,
    }
}

const overwriteFieldValues = (fields: FieldConfigDTO[], formData: any, value: any) => {
    fields.forEach((fld) => {
        if (value.hasOwnProperty(fld.name)) {
            formData[fld.name] = value[fld.name]
        }
    })
}

const resetForm = (entry: GuslFormState): GuslFormState => {
    entry.formData = clone(entry.origFormData)
    entry.counter = entry.counter + 1
    return entry
}
const updateFieldValue = (entry: GuslFormState, action: GuslFormUpdateAction): GuslFormState => {
    const newFormData: any = entry.formData ? clone(entry.formData) : {}
    // json stringify loses the File type for upload with form data
    if (newFormData['filename']) {
        newFormData['filename'] = current(entry.formData)['filename']
    }

    // @ts-ignore
    // console.log(`action.panelName ${action.panelName} `, isObject(newFormData[action.panelName]), newFormData)

    if (action.panelName && isObject(newFormData[action.panelName])) {
        const panel: any = newFormData[action.panelName] || {}
        panel[action.name] = action.value
        newFormData[action.panelName] = panel
    } else {
        newFormData[action.name] = action.value
    }
    if (action?.overwrite && isObject(action.value)) {
        overwriteFieldValues(entry.fields, newFormData, action.value)
    }
    entry.formData = newFormData
    return entry
}

const createDefault = (code: string): GuslFormState => {
    return {
        code: code,
        fields: [],
        formData: {},
        rowData: {},
        counter: 0,
        getFieldValue(properties: FieldProperties) {
            if (this.formData && properties?.fieldConfig?.name) {
                return this.formData[properties.fieldConfig.name]
            } else {
                return undefined
            }
        },
        getValueForField(name: string) {
            return this.formData ? this.formData[name] : undefined
        },
        origFormData: undefined,
    }
}
const getState = (state: MasterGuslFormState, code: string): GuslFormState => {
    let entry: GuslFormState = state[code]
    if (!entry) {
        entry = createDefault(code)
    }
    return entry
}

export interface TemplateResponse {
    code: string
    response: any
}

interface TemplateRequest {
    code: string
    url: string
    sessionContext: SessionContextProps
    abortController: AbortController
    formData: any
    rowData: any
}

export interface UpdateDataResponse {
    code: string
    response: any
}

interface UpdateDataRequest {
    code: string
    endpoint: EndpointDTO
    sessionContext: SessionContextProps
    abortController: AbortController
    formData: any
    multipartForm?: boolean
}

export interface GetDataResponse {
    code: string
    response: any
    panelCode?: string | undefined
}

interface GetDataRequest {
    code: string
    url: string
    sessionContext: SessionContextProps
    abortController: AbortController
    formData: any
    rowData: any
    panelCode?: string | undefined
}

export const getData = createAsyncThunk('get-data-url', async (request: GetDataRequest, { rejectWithValue }) => {
    try {
        const response = await request.sessionContext.get<any>(
            constructUrl(request.url, request.formData, request.rowData),
            request.abortController
        )
        return {
            code: request.code,
            response: response?.data || {},
            panelCode: request.panelCode,
        } as GetDataResponse
    } catch (error) {
        return rejectWithValue(error)
    }
})

export const getTemplateData = createAsyncThunk('template-url', async (request: TemplateRequest, { rejectWithValue }) => {
    try {
        const response = await request.sessionContext.get<any>(
            constructUrl(request.url, request.formData, request.rowData),
            request.abortController
        )
        return {
            code: request.code,
            response: response?.data || {},
        } as TemplateResponse
    } catch (error) {
        return rejectWithValue(error)
    }
})

export const postFormData = createAsyncThunk('update-url', async (request: UpdateDataRequest, { rejectWithValue }) => {
    try {
        if (request.multipartForm) {
            const response = await request.sessionContext.upload<any, any>(request.endpoint.url, request.formData, request.abortController)
            return {
                code: request.code,
                response: response?.data || {},
            } as UpdateDataResponse
        } else {
            const response = await request.sessionContext.sendRequest<any, ActionCompletedBO>(
                request.endpoint,
                request.formData,
                request.abortController
            )
            return {
                code: request.code,
                response: response?.data || {},
            } as UpdateDataResponse
        }
    } catch (error) {
        console.error('postFormData error', error)
        return rejectWithValue(error)
    }
})

const copyState = (entry: GuslFormState): GuslFormState => {
    const newState = {}
    for (const key in entry) {
        if (entry.hasOwnProperty(key)) {
            // @ts-ignore
            newState[key] = entry[key]
        }
    }
    return newState as GuslFormState
}

const performUpdateFormData = (
    inboundState: GuslFormState,
    code: string,
    data: any,
    forcePrimary: boolean,
    panelCode?: string | undefined
): GuslFormState => {
    const entry: GuslFormState = copyState(inboundState)

    let primaryName = maintainTableService.extractPrimaryObjectName(data, current(inboundState.fields))

    // console.log('performUpdateFormData', primaryName, data)

    if (forcePrimary && !primaryName && panelCode) {
        primaryName = panelCode //  || code
    }
    if (primaryName && primaryName !== 'chart' && primaryName !== 'comments') {
        entry.primaryObjectName = primaryName
        entry.formData = { ...data[primaryName] }
        entry.origFormData = { ...data[primaryName] }
    } else {
        entry.formData = { ...data }
        entry.origFormData = { ...data }
    }
    entry.counter = entry.counter + 1
    return entry
}

const performAddNestedRow = (entry: GuslFormState, action: NestedRowAction) => {
    const newFormData: any = entry.formData ? clone(entry.formData) : {}
    let currentRows: TableRowDTO[] = newFormData[action.name] || []
    const newRow: TableRowDTO = {
        rowIndex: currentRows.length,
        id: '0',
    } as TableRowDTO

    if (action.append) {
        currentRows.push(newRow)
    } else {
        currentRows.unshift(newRow)
    }
    newFormData[action.name] = currentRows
    entry.counter = entry.counter + 1
    entry.formData = newFormData
    return entry
}

const performUpdateNestedRow = (entry: GuslFormState, action: NestedUpdateRowAction) => {
    try {
        if (!action.parentFieldName) {
            console.error('No parent field for ', action)
            return entry
        }
        const newFormData: any = entry.formData ? clone(entry.formData) : {}
        let currentRows: TableRowDTO[] = newFormData[action.parentFieldName] || []

        if (action.rowIndex >= 0 && action.rowIndex < currentRows.length) {
            let currentRow: any = currentRows[action.rowIndex]
            currentRow[action.name] = action.value
            currentRows[action.rowIndex] = currentRow
        }

        newFormData[action.parentFieldName] = currentRows
        // entry.counter = entry.counter + 1;
        entry.formData = newFormData
        return entry
    } catch (error) {
        console.error('Error', error)
        return entry
    }
}

const performRemoveNestedRow = (entry: GuslFormState, action: NestedRowAction) => {
    const newFormData: any = entry.formData ? clone(entry.formData) : {}
    let currentRows: TableRowDTO[] = newFormData[action.name] || []

    // @ts-ignore
    if (action.rowIndex >= 0 && action.rowIndex < currentRows.length) {
        // @ts-ignore
        currentRows.splice(action.rowIndex, 1)
    }
    newFormData[action.name] = currentRows
    entry.formData = newFormData
    entry.counter = entry.counter + 1
    return entry
}
export const guslFormSlice = createSlice({
    name: 'guslFormSlice',
    initialState,
    reducers: {
        initForm(state, action: PayloadAction<GuslFormInitState>) {
            let entry: GuslFormState = getState(state, action.payload.code)
            entry = loadInitValues(action.payload)
            state[action.payload.code] = entry
        },
        guslFormFieldChange(state, action: PayloadAction<GuslFormUpdateAction>) {
            const entry: GuslFormState = getState(state, action.payload.code)
            updateFieldValue(entry, action.payload)
            state[action.payload.code] = entry
        },
        resetFromData(state, action: PayloadAction<GuslFormResetAction>) {
            const entry: GuslFormState = getState(state, action.payload.code)
            resetForm(entry)
            state[action.payload.code] = entry
        },
        addNestedRow(state, action: PayloadAction<NestedRowAction>) {
            const entry: GuslFormState = getState(state, action.payload.code)
            performAddNestedRow(entry, action.payload)
            state[action.payload.code] = entry
        },
        updateNestedRow(state, action: PayloadAction<NestedUpdateRowAction>) {
            const entry: GuslFormState = getState(state, action.payload.code)
            performUpdateNestedRow(entry, action.payload)
            state[action.payload.code] = entry
        },
        removeNestedRow(state, action: PayloadAction<NestedRowAction>) {
            const entry: GuslFormState = getState(state, action.payload.code)
            performRemoveNestedRow(entry, action.payload)
            state[action.payload.code] = entry
        },
        cleanUpGuslForm(state, action: PayloadAction<GuslFormCleanUpState>) {
            delete state[action.payload.code]
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getTemplateData.fulfilled, (state: MasterGuslFormState, action: PayloadAction<TemplateResponse>) => {
            const entry: GuslFormState = getState(state, action.payload.code)
            // console.log('---------------- getTemplateData -------------')
            state[action.payload.code] = performUpdateFormData(entry, action.payload.code, action.payload.response, false)
        })

        builder.addCase(postFormData.fulfilled, (state: MasterGuslFormState, action: PayloadAction<UpdateDataResponse>) => {
            const entry: GuslFormState = getState(state, action.payload.code)
            state[action.payload.code] = performUpdateFormData(entry, action.payload.code, action.payload.response, false)
        })

        builder.addCase(getData.fulfilled, (state: MasterGuslFormState, action: PayloadAction<GetDataResponse>) => {
            const entry: GuslFormState = getState(state, action.payload.code)
            state[action.payload.code] = performUpdateFormData(
                entry,
                action.payload.code,
                action.payload.response,
                true,
                action.payload.panelCode
            )
        })
    },
})

export const { initForm, guslFormFieldChange, resetFromData, addNestedRow, updateNestedRow, removeNestedRow, cleanUpGuslForm } =
    guslFormSlice.actions

export default guslFormSlice.reducer
