import styled, { ThemeProps } from 'styled-components'
import Icon from '../bootstrap/Icon'
import { RowStyledProperties } from '../../gusl-table/styled/table/styled'

interface ChevronIconProperties extends ThemeProps<any> {
    isOpen: boolean
}

export const ChevronIconStyled = styled(Icon).attrs((props: ChevronIconProperties) => ({
    icon: props.isOpen ? 'fa fa-chevron-down' : 'fa fa-chevron-right',
}))<ChevronIconProperties>`
    color: ${(props) => props.theme.navigation.dropdownMenu.color};
`

export const ExpandedPanelStyled = styled.div.attrs((props: RowStyledProperties) => ({}))<RowStyledProperties>`
    // MK 06/09/2023 squashed table
    ${(props) => props.isSquashed && `width:calc(100vw - ${props.sideNavOpen ? '390' : '100'}px);`}
    ${(props) => !props.isSquashed && props.isNested && `width:calc(100vw - ${props.sideNavOpen ? `500` : `200`}px);`}
  background-color: ${(props) => props.theme.modal.innerBackground};
    color: ${(props) => props.theme.table.columnColor};
    padding: 10px;
    // border-right:  ${(props) => '1px solid ' + props.theme.modal.outerBackground} ;
    // border-left:  ${(props) => '1px solid ' + props.theme.modal.outerBackground} ;
    // border-bottom:  ${(props) => '1px solid ' + props.theme.modal.outerBackground};
`

export const CascadingTitleStyled = styled.h5.attrs((props) => ({}))`
    color: ${(props) => props.theme.table.titleColor};
    font-size: 16px;
`

export const CascadingPanelStyled = styled.div.attrs((props) => ({}))`
    background-color: ${(props) => props.theme.colors.background[1]} !important;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
`
