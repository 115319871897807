import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../../app/hooks'
import MGQuery from '../../../../../providers/session/MGQuery'
import { constructUrl } from '../../../../../utils/Utils'
import ErrorMsg from '../../../../ui/ErrorMsg'
import { addAllMustNots, removeAllMustNots } from '../../queryParamsSlice'
import MiniLoadingSpinner from '../../table-controls/MiniLoadingSpinner'
import { DefaultTextFilter } from '../default-text-filter/DefaultTextFilter'
import { FilterDO, FilterValueDO } from '../types'
import { TextFilterToolbarItemStyled, TextFilterToolbarStyled } from './styled'
import { log } from '../../../../../services/LogService'

export type TextFiltersDO = {
    code: string
    filter: FilterDO
    data?: any
}
export default function TextFilters({ code, filter, data }: TextFiltersDO) {
    const dispatch = useAppDispatch()

    const [className] = useState('TextFilters-' + new Date().getTime())
    const [filterValues, setFilterValues] = useState<FilterValueDO[]>([])
    console.log('=====>', filter.distinctUrl, data, constructUrl(filter.distinctUrl as string, data))

    const DistinctValuesRD = MGQuery({
        method: 'get',
        endpoint: constructUrl(filter.distinctUrl as string, data),
        queryParams: {},
    })
    const [refreshCounter, setRefreshCounter] = useState<number>(0)
    log.debug(className, 'MSG001', { distinctUrl: filter.distinctUrl, filter })
    useEffect(() => {
        if (DistinctValuesRD.isSuccess) {
            setFilterValues(DistinctValuesRD.data)
        }
    }, [DistinctValuesRD.isSuccess])

    if (DistinctValuesRD.isLoading) {
        return <MiniLoadingSpinner isLoading={DistinctValuesRD.isLoading} />
    }

    if (DistinctValuesRD.isError) {
        return <ErrorMsg />
    }

    const renderToolbar = (): React.ReactElement => {
        const onUnsetAll = (e: React.MouseEvent<HTMLDivElement>) => {
            const values = filterValues.map((filterValue) => filterValue.value)
            // MK 28/08/2023 fieldNameFromValues can be undefined => using filter.name instead
            // const fieldName = filterValues.length > 0 ? filterValues[0].fieldName : filter.name
            // todo do the same logic for distinctUrl in MultiLookupFieldAsTextFilter.tsx
            const fieldNameFromValues: string | undefined =
                filterValues.length > 0 && filterValues[0].fieldName ? filterValues[0].fieldName : undefined
            dispatch(
                addAllMustNots({
                    code,
                    fieldName: fieldNameFromValues || filter.name,
                    values,
                })
            )
            setRefreshCounter(refreshCounter + 1)
        }

        const onSetAll = (e: React.MouseEvent<HTMLDivElement>) => {
            const fieldName = filterValues.length > 0 ? filterValues[0].fieldName : filter.name
            dispatch(removeAllMustNots({ code, fieldName: fieldName }))
            setRefreshCounter(refreshCounter + 1)
        }

        return (
            <TextFilterToolbarStyled>
                <TextFilterToolbarItemStyled onClick={(e) => onSetAll(e)}>Set All</TextFilterToolbarItemStyled>
                <TextFilterToolbarItemStyled onClick={(e) => onUnsetAll(e)}>Unset All</TextFilterToolbarItemStyled>
            </TextFilterToolbarStyled>
        )
    }

    if (DistinctValuesRD.isSuccess) {
        console.log({ filterValues })
        return (
            <>
                {renderToolbar()}
                {filterValues.map((filterValue, idx) => (
                    <DefaultTextFilter
                        key={'tf_' + refreshCounter + '_' + idx}
                        code={code}
                        filter={{
                            ...filter,
                            value: filterValue.value,
                            label: filterValue.label,
                            fieldName: filterValue.fieldName || filter.name,
                        }}
                        label={filterValue.label}
                        count={filterValue.count}
                    />
                ))}
            </>
        )
    }
    return <></>
}
