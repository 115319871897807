import React, { useContext, useEffect, useState } from 'react'
import { Subscription } from 'rxjs'
import { BlastContext } from '../../../providers/blast/BlastContext'
import { SessionContext } from '../../../providers/session/SessionContext'
import { log } from '../../../services/LogService'
import { getStyle } from '../../../utils/CssUtils'
import { isDefined, notBlank, notDefined } from '../../../utils/TypeCheckers'
import { compare, constructUrl, RunOnceEffect, unSubscribe } from '../../../utils/Utils'
import { fieldService } from '../../FieldService'
import { GuslHtmlBlock } from '../../html-block/GuslHtmlBlock'
import { FieldConfigDTO, FormRowConfigDTO, PanelConfigDTO } from '../../types'
import LoadingSpinner from '../loading-spinner/LoadingSpinner'
import { maintainTableService } from '../maintain-table/MaintainTableService'
import { HeaderColumnDetailStyled, HeaderColumnHeaderStyled, HeaderTableStyled } from './styled'

type HeaderPanelProperties = {
    parentData: any
    panel: PanelConfigDTO
}

export const HeaderPanel = ({ parentData, panel }: HeaderPanelProperties): React.ReactElement => {
    const [className] = React.useState<string>(() => 'HeaderPanel-' + new Date().getTime())

    const [loaded, setLoaded] = useState<boolean>(true)
    const [data, setData] = useState<any>({})
    const sessionContext = useContext(SessionContext)
    const blastContext = useContext(BlastContext)

    const getServerData = (abortController: AbortController) => {
        // @ts-ignore
        sessionContext.get<any>(constructUrl(panel?.selectUrl, parentData), abortController).then(
            (response) => {
                setLoaded(true)
                setData(response.data)
            },
            (reason) => {
                setLoaded(true)
                log.error(className, 'ERR001', 'Error getting template data', reason)
            }
        )
    }

    useEffect(() => {
        let subscription: Subscription | undefined = undefined
        const refreshRate = (panel?.refreshRate || -1) === -1 && notBlank(panel.selectUrl) ? -1 : (panel?.refreshRate || 10) * 1000
        if (refreshRate > 0) {
            subscription = sessionContext.watchRefreshRate().subscribe((nextRefreshRate: number) => {
                if (nextRefreshRate !== -1 && refreshRate === nextRefreshRate) {
                    getServerData(new AbortController())
                }
            })
            sessionContext.registerRefreshRate(refreshRate)
        }
        return () => {
            unSubscribe(subscription)
            sessionContext.unregisterRefreshRate(refreshRate)
        }
    }, [panel])
    RunOnceEffect(() => {
        let abortController = new AbortController()
        let collectionSubscription: Subscription

        // let loaderSubscription = sessionContext.watchSystemReady().subscribe((systemReady: boolean) => {
        //     setSystemReady(systemReady)
        //
        // });
        if (notBlank(panel.selectUrl)) {
            getServerData(abortController)
        }
        if (notBlank(panel.blastCollection)) {
            const collectionParts = panel?.blastCollection?.split('/')
            if (collectionParts && collectionParts.length === 2) {
                const keys = collectionParts[1].split(':')
                if (keys && keys.length === 2) {
                    collectionSubscription = blastContext.observeCollection(collectionParts[0]).subscribe((collection: any[]) => {
                        const value = constructUrl(keys[1], parentData)
                        if (collection) {
                            collection
                                .filter((entity) => {
                                    return entity[keys[0]] === value
                                })
                                .forEach((entity) => setData(entity))
                        }
                    })
                    setLoaded(true)
                }
            }
        }

        if (notBlank(panel.blastTopic)) {
            blastContext.subscribeTopic(constructUrl(panel.blastTopic || '', parentData))
            setLoaded(true)
        }

        return () => {
            unSubscribe(collectionSubscription)
            abortController.abort()
            if (notBlank(panel.blastTopic)) {
                blastContext.unsubscribeTopic(panel.blastTopic)
            }
        }
    })

    const renderColumnHeader = (fld: FieldConfigDTO, idx: number): React.ReactElement => {
        const style = getStyle(fld.labelCss, '')
        return (
            <HeaderColumnHeaderStyled
                textAlign={fld.align}
                key={'hdr_' + idx}
                style={style}
                cellWidth={maintainTableService.isNarrowCell(fld) ? 40 : fld.cellWidth}
                className={fld.align === 'right' ? '' : 'ps-2'}
                onClick={() => {}}
            >
                <span>{fld.noLabel ? '' : fld.label}</span>
            </HeaderColumnHeaderStyled>
        )
    }

    const renderColumnDetail = (fld: FieldConfigDTO, idx: number): React.ReactElement => {
        const style = getStyle(fld.tableCss, '')
        if (notDefined(fld.keyValue) && isDefined(fld.keyField)) {
            try {
                fld.keyValue = data?.id
            } catch (err) {}
        }
        return (
            <HeaderColumnDetailStyled
                textAlign={fld.align}
                key={'hdr_det_' + idx + '_' + data?.id || ''}
                style={style}
                cellWidth={maintainTableService.isNarrowCell(fld) ? 40 : fld.cellWidth}
                className={fld.align === 'right' ? '' : 'ps-2'}
            >
                {fieldService.getTableField(fld, undefined, data)?.render()}
            </HeaderColumnDetailStyled>
        )
    }

    const renderFormRow = (row: FormRowConfigDTO, index: number): React.ReactElement => {
        return (
            <HeaderTableStyled key={'hdr_row_table' + index} id={'hdr_panel_row_table' + index}>
                <thead>
                    <tr>
                        {row.columns[0].fields
                            ?.filter((fld) => fld.displayInTable)
                            .sort((a, b) => compare(a.displayOrder, b.displayOrder))
                            .map((fld, idx) => renderColumnHeader(fld, idx))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {row.columns[0].fields
                            ?.filter((fld) => fld.displayInTable)
                            .sort((a, b) => compare(a.displayOrder, b.displayOrder))
                            .map((fld, idx) => renderColumnDetail(fld, idx))}
                    </tr>
                </tbody>
            </HeaderTableStyled>
        )
    }

    const renderPanel = (): React.ReactElement => {
        if (panel?.htmlBlockUrl) {
            return <GuslHtmlBlock htmlContentUrl={panel?.htmlBlockUrl} />
        } else {
            return <>{panel?.rows?.map((row, index) => renderFormRow(row, index))}</>
        }
    }

    return <>{loaded ? renderPanel() : <LoadingSpinner size={20} />}</>
}
