import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { EnvironmentContext } from '../../../providers/environment/EnvironmentContext'
import { environmentService } from '../../../providers/environment/EnvironmentService'
import { SessionContext } from '../../../providers/session/SessionContext'
import { isDefined } from '../../../utils/TypeCheckers'
import { cancelAbortController, RunOnceEffect, unSubscribe } from '../../../utils/Utils'
import { BackIconStyled } from '../../bespoke/sports-betting/landing-page/sliding-panel/styled'
import LoadingSpinner from '../loading-spinner/LoadingSpinner'
import { HeaderStyled, LegalHeaderWrapperStyled, LegalPolicyStyled, NotFoundStyled, PolicyStyled } from './styled'
import { LegalRequestDTO, LegalResponseDTO } from './types'

interface LegalProperties {
    code?: string
    noTitle?: boolean
    onClose?: () => void
}

export const LegalPage = ({ code, noTitle, onClose }: LegalProperties): React.ReactElement => {
    const location = useLocation()
    const navigate = useNavigate()
    const sessionContext = React.useContext(SessionContext)
    const environmentContext = React.useContext(EnvironmentContext)

    const [loaded, setLoaded] = useState<boolean>(false)
    const [notFound, setNotFound] = useState<boolean>(false)
    const [systemReady, setSystemReady] = useState<boolean>(false)
    const [content, setContent] = useState<string>('')
    const [title, setTitle] = useState<string>('')
    const isMobile = environmentContext.isMobileDevice()

    const [footerHeight, setFooterHeight] = useState<number>(0)
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription)
        }
    })

    RunOnceEffect(() => {
        let loaderSubscription = sessionContext.watchSystemReady().subscribe((systemReady: boolean) => {
            setSystemReady(systemReady)
        })
        return () => {
            unSubscribe(loaderSubscription)
        }
    })

    useEffect(() => {
        let policyCode = code
        let mounted = true
        let abortController = new AbortController()
        if (!policyCode) {
            const paths = location.pathname.split('/')
            if (paths.length > 2) {
                policyCode = paths[2]
            }
        }
        if (policyCode) {
            setLoaded(false)
            console.log('--- policyCode', policyCode)

            sessionContext
                .post<LegalRequestDTO, LegalResponseDTO>('/legal/v1/policy', {
                    code: policyCode,
                })
                .then((response) => {
                    setNotFound(false)
                    setLoaded(true)
                    setContent(response.data.contents)
                    setTitle(response.data.title)
                })
                .catch((error) => {
                    console.error('Error', error)
                    setNotFound(true)
                    setLoaded(true)
                })
        } else {
            setNotFound(true)
            setLoaded(true)
        }
        return () => {
            cancelAbortController(abortController)
            mounted = false
        }
    }, [location.pathname, systemReady])

    const onCloseClicked = () => {
        console.log('-- click on close', onClose)
        if (onClose) {
            console.log('-- click on close')
            onClose()
        }
    }

    const renderDocument = (): React.ReactElement => {
        return (
            <>
                {!noTitle && (
                    <LegalHeaderWrapperStyled width={window.innerWidth}>
                        {onClose && (
                            <BackIconStyled>
                                <i className="fas fa-chevron-left" onClick={() => onCloseClicked()} />
                            </BackIconStyled>
                        )}
                        <HeaderStyled width={window.innerWidth} hasOnClose={isDefined(onClose)}>
                            {title || ''}
                        </HeaderStyled>
                    </LegalHeaderWrapperStyled>
                )}
                <LegalPolicyStyled isMobile={isMobile} id={'policy'} noFooter={noTitle} internal={false} footerHeight={footerHeight}>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </LegalPolicyStyled>
            </>
        )
    }

    const renderNotFound = (): React.ReactElement => {
        const goHome = () => {
            navigate('/' + environmentService.getEnvironment()?.homePage || '/')
        }

        return (
            <>
                <NotFoundStyled>Failed to find that policy</NotFoundStyled>
                {/*<NotFoundLinkStyled  onClick={goHome}>Return home</NotFoundLinkStyled>*/}
            </>
        )
    }

    const renderPage = (): React.ReactElement => {
        return (
            <>
                <>{notFound ? renderNotFound() : renderDocument()}</>
            </>
        )
    }

    return <>{loaded && systemReady ? renderPage() : <LoadingSpinner />}</>
}
export { LegalPage as default }
