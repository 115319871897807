import { createGlobalStyle } from 'styled-components'
import { reset } from 'styled-reset'
import { BaseTheme } from '../../providers/theme/GuslThemeProvider'

// if you change font-family - needs to be imported in App.css
/*font-family: 'Shantell Sans', cursive !important;*/
/*font-family: 'Roboto', sans-serif !important;*/
/*font-family: 'Sofia Sans', sans-serif!important;*/
/*font-family: 'Roboto', sans-serif !important;*/

//TODO: Add mobile support and media query
const GlobalStyle = createGlobalStyle<{ theme: BaseTheme }>`
  ${reset}
  html,
  body,
  #root {
    font-size: ${({ theme }) => theme.typography.baseFontSize};
      // font-family: ${({ theme }) => theme.typography.fontFamilyRegular};
    font-family: "Open Sans", sans-serif !important;
    // font-family: "Product Sans", sans-serif !important;
    color: ${({ theme }) => theme.colors.light};
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    background-color: ${(props) => props.theme.colors.background[0]};
    //min-height: 100%;
    width: 100%;
    height: var(--internal-height);
    min-height: var(--internal-height);
  }
`
// @ts-ignore
export default GlobalStyle
