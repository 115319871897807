import { ArcElement, Chart as ChartJS, Legend, Title, Tooltip } from 'chart.js'
import React, { useRef, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { log } from '../../../services/LogService'
import ErrorMsg from '../../ui/ErrorMsg'
import { DEFAULT_CHART_OPTIONS } from '../chart-options/defaultChartOptions'
import { ChartProps } from '../types'
import displayColors from '../utils/gaugeDisplayColors'

ChartJS.register(ArcElement, Tooltip, Legend, Title)

export default function GaugeChart({ endpoint, serverData, chartFor }: ChartProps) {
    // @ts-ignore
    // serverData = {
    //         chartType: 'gauge',
    //         chartOptions: GAUGE_DEFAULT,
    //         chartLabel: 'Gauge default',
    //         labelAlign: 'start',
    //         insideText: '75%',
    //         showInsideText: true,
    //         showLabels: false,
    //         displayMinMax: false,
    //         minMax: {min: 0, max: 10},
    //         datasets: [
    //             {
    //                 //@ts-ignore
    //                 score: 7.7,
    //                 cutout: 70,
    //                 gaugeColors: ['#38ee11', '#9900aa', '#fa2299'],
    //                 inactiveColor: '#161617',
    //
    //             }
    //         ]
    //     }
    const chartLabel = serverData?.chartLabel
    const labelAlign = serverData?.labelAlign
    const insideText = serverData?.insideText
    const showInsideText = serverData?.showInsideText
    const displayMinMax = serverData?.displayMinMax
    const minMax = serverData?.minMax
    const showLabels = serverData?.showLabels

    const [className] = useState('GaugeChart-' + new Date().getTime())

    //log.info(className, 'MSG002->gauge serverData showLabels', showLabels, typeof serverData?.score, serverData)

    const chartRef = useRef<ChartJS>(null)
    const [chartData, setChartData] = useState({ datasets: [], labels: [] })
    const [options, setOptions] = useState(DEFAULT_CHART_OPTIONS['gauge'])
    const datasets: Array<any> = []

    // if (serverData) {
    //     // @ts-ignore
    //     serverData['datasets'] = [
    //         {
    //              // @ts-ignore
    //             score: 7.7,
    //             cutout: 70,
    //             gaugeColors: ['#38ee11', '#9900aa', '#fa2299'],
    //             inactiveColor: '#161617',
    //
    //         }
    //     ]
    // }
    try {
        // @ts-ignore
        serverData?.datasets?.forEach((dataset: { score: any; gaugeColors: any; inactiveColor: any; cutout: number }) => {
            const _colors = displayColors({
                score: dataset.score,
                maxScore: minMax.max,
                gaugeColors: dataset.gaugeColors,
                inactiveColor: dataset.inactiveColor,
            })
            datasets.push({
                data: Array.from({ length: minMax.max * 10 }).map((_) => 100),
                backgroundColor: _colors,
                borderColor: _colors,
                hoverBackgroundColor: _colors,
                borderWidth: 2,
                borderRadius: 0,
                cutout: dataset.cutout + '%',
            })
        })

        //log.info(className, 'MSG001->datasets', datasets)
        const data = { datasets }

        // @ts-ignore
        // @ts-ignore
        return (
            <>
                {chartLabel && <p className={'p-0 m-0 small text-light text-' + labelAlign}>{chartLabel}</p>}
                {
                    <div className={'row g-0 row-cols-3 text-light xs_text'} style={{ minHeight: 17 }}>
                        {showLabels &&
                            serverData?.datasets?.map((dataset: any, idx: number) => (
                                <div key={idx} className="col">
                                    <div key={idx} className="d-flex justify-content-start align-items-center">
                                        <div
                                            className={'small'}
                                            style={{
                                                width: 10,
                                                height: 10,
                                                backgroundColor: dataset.gaugeColors[0],
                                            }}
                                        />
                                        <span className={'ms-1'}>
                                            {dataset.label} ({dataset.score})
                                        </span>
                                    </div>
                                </div>
                            ))}
                    </div>
                }
                <div className={'relative'}>
                    {/*@ts-ignore*/}
                    <Doughnut ref={chartRef} options={options} data={data} type={'doughnut'} />

                    <div className="absolute-center text-center">
                        <h1 className={'fs-3 text-light'}>{showInsideText && insideText}</h1>
                    </div>
                </div>

                {displayMinMax && (
                    <p className={'px-5 d-flex justify-content-between text-light '}>
                        <>
                            <span>{minMax.min}</span>
                            <span>{minMax.max}</span>
                        </>
                    </p>
                )}
            </>
        )
    } catch (e) {
        log.error(className, 'MSG001', e, { endpoint })
        return <ErrorMsg />
    }
}
