import styled from 'styled-components'

interface BadgeStyledProperties {
    backgroundColor: string
}

export const BadgeStyled = styled.div.attrs((props: BadgeStyledProperties) => ({}))<BadgeStyledProperties>`
    display: inline-table;
    vertical-align: middle;
    width: 20px;
    height: 20px;

    background-color: ${(props) => props.backgroundColor || '#bbb'};
    border-radius: 50%;
    position: relative;
    top: -5px;
    left: 2px;
`

interface BadgeContentsStyledProperties {
    color: string
}

export const BadgeContentsStyled = styled.span.attrs((props: BadgeContentsStyledProperties) => ({}))<BadgeContentsStyledProperties>`
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: ${(props) => props.color || '#fff'};
    font-size: 10px;
`
