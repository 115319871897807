export const FRENCH_LOCALE = {
    everyText: 'chaque',
    emptyMonths: 'chaque mois',
    emptyMonthDays: 'chaque jour du mois',
    emptyMonthDaysShort: 'jour du mois',
    emptyWeekDays: 'chaque jour de la semaine',
    emptyWeekDaysShort: 'jour de la semaine',
    emptyHours: 'chaque heure',
    emptyMinutes: 'chaque minute',
    emptyMinutesForHourPeriod: 'chaque',
    yearOption: 'année',
    monthOption: 'mois',
    weekOption: 'semaine',
    dayOption: 'jour',
    hourOption: 'heure',
    minuteOption: 'minute',
    rebootOption: 'redémarrage',
    prefixPeriod: 'Chaque',
    prefixMonths: 'en',
    prefixMonthDays: 'le',
    prefixWeekDays: 'le',
    prefixWeekDaysForMonthAndYearPeriod: 'et',
    prefixHours: 'à',
    prefixMinutes: ':',
    prefixMinutesForHourPeriod: 'à',
    suffixMinutesForHourPeriod: 'minute(s)',
    errorInvalidCron: 'Expression cron invalide',
    clearButtonText: 'Effacer',
    // Order is important, the index will be used as value
    months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
    // Order is important, the index will be used as value
    weekDays: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    // cf: https://fr.wikipedia.org/wiki/Mois#Abr%C3%A9viations
    // Order is important, the index will be used as value
    altMonths: ['JAN', 'FÉV', 'MAR', 'AVR', 'MAI', 'JUN', 'JUL', 'AOÛ', 'SEP', 'OCT', 'NOV', 'DÉC'],
    // cf: http://bdl.oqlf.gouv.qc.ca/bdl/gabarit_bdl.asp?id=3617
    // Order is important, the index will be used as value
    altWeekDays: ['DIM', 'LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM'],
}
export const ENGLISH_VARIANT_LOCALE = {
    // everyText: 'all',
    // emptyHours: 'all hours',
    // emptyWeekDays: 'all days of the week',
    // emptyMonthDays: 'all days of the month',
    // emptyMonths: 'all months',
    // emptyMinutes: 'all minutes',
    // emptyMinutesForHourPeriod: 'all',
    // yearOption: 'years',
    // monthOption: 'months',
    // weekOption: 'weeks',
    // dayOption: 'days',
    // hourOption: 'hours',
    // minuteOption: 'minutes',
    // rebootOption: 'reboots',
    // prefixPeriod: 'All',
    clearButtonText: 'Clear',
}
export const NO_PREFIX_SUFFIX_LOCALE = {
    prefixPeriod: '',
    prefixMonths: '',
    prefixMonthDays: '',
    prefixWeekDays: '',
    prefixWeekDaysForMonthAndYearPeriod: '',
    prefixHours: '',
    prefixMinutes: 'minutes',
    prefixMinutesForHourPeriod: '',
    suffixMinutesForHourPeriod: '',
}
