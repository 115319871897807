import React, { MouseEventHandler } from 'react'
import { ActionButtonStyled } from '../gusl-table/styled/table-row-edit/styled'
import Icon from '../maintain-table/bootstrap/Icon'

type Props = {
    onClick?: MouseEventHandler<HTMLButtonElement>
    label: string
    action: string
    extraClass?: string
    icon?: string
}
export default function ActionButton({ onClick, label, action, extraClass, icon }: Props) {
    const actionClass = {
        default: 'text-light',
        save: 'ls-green',
        refresh: 'ls-green',
        new: 'text-light',
        update: 'ls-green',
        edit: 'ls-green',
        cancel: 'light_red',
    }[action]
    return (
        <ActionButtonStyled className={' ' + ' ' + extraClass} onClick={onClick}>
            {icon && (
                <>
                    <Icon icon={icon} className={'action_icon ' + icon} />
                    <>&nbsp;</>
                </>
            )}
            {label}
        </ActionButtonStyled>
    )
}
