export const TRANSLATIONS_ES: any = {
    maintain_table: {
        new: 'ES New',
        delete: 'ES Delete',
        export: 'ES Export',
        import: 'ES Import',
    },
    maintain_form: {
        edit: 'ES Edit',
        cancel: 'ES Cancel',
        save: 'ES Save',
    },
    country: {
        title: 'ES Country',
        name: {
            header: 'ES HDR Name',
            label: 'ES Name',
            validation: {
                not_null: 'Name is required.',
            },
        },
    },
}
