import React from 'react'
import { SortButtonStyled, SortButtonsWrapperStyled } from '../../styled_v2'
import { sortDirectionType } from '../types'

export interface SortButtonDO {
    onClick: Function
    sortDirection: sortDirectionType // sortDirectionType -> "ASC" | "DESC" | "NONE"
}

export default function SortButton({ onClick, sortDirection }: SortButtonDO) {
    return (
        <SortButtonsWrapperStyled>
            <SortButtonStyled onClick={onClick} active={sortDirection === 'ASC'}>
                &#9650;
            </SortButtonStyled>
            <SortButtonStyled onClick={onClick} active={sortDirection === 'DESC'}>
                &#9660;
            </SortButtonStyled>
        </SortButtonsWrapperStyled>
    )
}
