import React, { useContext, useEffect, useState } from 'react'
import { Subscription } from 'rxjs'
import { environmentService } from '../../../../providers/environment/EnvironmentService'
import { GuslTableProperties } from '../GuslTable'
import { GuslTableState } from '../guslTableSlice'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { canShowPagination, noop, RunOnceEffect, unSubscribe } from '../../../../utils/Utils'
import { EnvironmentContext } from '../../../../providers/environment/EnvironmentContext'
import { IMenuDTO, PaginationControl, PaginationMode, QueryParamsDTO, WidgetPanelProperties } from '../../../types'
import { ChangePaginationRows, changePaginationRows, GuslTableQueryParamState, updateQueryParams } from '../queryParamsSlice'
import paginationService, { ROWS_PER_PAGE } from '../PaginationService'
import { BackToTop, FromToStyled } from '../styled/pagination/styled'
import { translateService } from '../../../../services/TranslateService'
import {
    PaginationFirstStyled,
    PaginationLastStyled,
    PaginationNextStyled,
    PaginationPrevStyled,
    PaginationStyled,
} from '../styled/table-controls/styled'
import { RowsPerPageContainerStyled, RowsPerPageLookupStyled, RowsPerPageTableResponsiveStyled } from '../styled/table/styled'
import { OptionComboStyled, OptionTableContainerStyled } from '../../option/styled'
import { Option } from '../../option/OptionField'
import { RowsPerContentStyled } from '../../maintain-table/styled'
import { LookupColumnStyled, LookupTableBodyStyled, LookupTableRowStyled, LookupTableStyled } from '../../lookup/styled'
import { ElementWithTooltip } from '../../element-with/element-with-tooltip/ElementWithTooltip'
import ElementWithPopover from '../../element-with/element-with-popover/ElementWithPopover'
import MGMutation from '../../../../providers/session/MGMutation'
import { SessionContext } from '../../../../providers/session/SessionContext'
import { SystemContext } from '../../../../providers/system/SystemContext'

interface TablePaginationDO {
    code: GuslTableProperties['code']
    widgetPanelProperties?: WidgetPanelProperties
}
interface CountResponseDO {
    data: {
        count: number
    }
}
// MK 12/08/2023 moving pagination outside ListView into GuslTable
export default function TablePagination({ code, widgetPanelProperties }: TablePaginationDO) {
    const [className] = useState('TablePagination-' + new Date().getTime())
    const sessionContext = useContext(SessionContext)
    // MK 02-10-2024
    const systemContext = useContext(SystemContext)
    const [menuItem] = useState<IMenuDTO | undefined>(systemContext.getMenuConfig(code))
    const hasInfiniScroll: boolean | undefined = menuItem?.infiniScroll

    const environmentContext = useContext(EnvironmentContext)
    const isMobile = environmentContext.isMobileDevice(widgetPanelProperties)
    const dispatch = useAppDispatch()
    const tableState: GuslTableState = useAppSelector((state) => state.guslTableSlice[code])
    const currentContentTotal: number = tableState?.currentContentTotal || 0
    const responseTotal: number = tableState?.responseTotal || 0

    const queryParamsState: GuslTableQueryParamState = useAppSelector((state) => state.queryParamsSlice[code])
    const serverQueryParams: QueryParamsDTO | undefined = tableState?.serverQueryParams

    // const noCount : boolean | undefined = serverQueryParams?.noCount;
    const [totalCount, setTotalCount] = useState<number>(0)
    const [gettingCountError, setGettingCountError] = useState<boolean>(false)
    // MK 27/09/2023
    const [limit, setLimit] = useState<number | undefined>(queryParamsState?.queryParams.limit)
    const showMobileContent = tableState?.showMobileTableControls
    const [paginationControl, setPaginationControl] = useState<PaginationControl>({
        firstActive: false,
        prevActive: false,
        nextActive: false,
        lastActive: false,
        total: -1,
        from: 0,
        to: 0,
        hasPagination: false,
        hasActiveButtons: true,
    })

    const noCount: boolean = paginationControl.total === -2
    const [footerHeight, setFooterHeight] = useState<number>(0)
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription)
        }
    })

    useEffect(() => {
        if (queryParamsState) {
            setLimit(queryParamsState.queryParams.limit)
            setRowsPerPage({
                // MK 27/09/2023 persisting limit aka rows per table
                value: queryParamsState.queryParams.limit.toString(),
                label: queryParamsState.queryParams.limit.toString(),
            })
            setPaginationControl(paginationService.updatePaginatorControl(queryParamsState.queryParams, responseTotal))
        }
    }, [queryParamsState, responseTotal])

    const [rowsPerPageItems] = useState<Option[]>(() => {
        const options: Option[] = []
        ROWS_PER_PAGE.forEach((rowsPerPage) => {
            options.push({ value: rowsPerPage + '', label: rowsPerPage + '' })
        })
        return options
    })
    const [showPagination, setShowPagination] = useState(false)
    useEffect(() => {
        if (tableState) {
            setShowPagination(canShowPagination(environmentContext.getCurrentMediaType(widgetPanelProperties), tableState?.tableControl))
        }
    }, [tableState])
    const [rowsPerPageOpen, setRowsPerPageOpen] = useState<boolean>(false)
    const [rowsPerPage, setRowsPerPage] = useState<Option>({
        // MK 27/09/2023 persisting limit aka rows per table
        value: limit ? limit.toString() : ROWS_PER_PAGE[0] + '',
        label: limit ? limit.toString() : ROWS_PER_PAGE[0] + '',
    })

    function backToTopHandler() {
        dispatch(
            updateQueryParams({
                code: code,
                queryParams: {
                    ...queryParamsState.queryParams,
                    skip: 0,
                    limit: ROWS_PER_PAGE[1],
                }, // First page load
            })
        )
    }

    // not showing pagination on mobile... not sure why ... !showPagination
    if (!showPagination || !paginationControl.hasPagination) {
        return <></>
    }
    const onPaginationChange = (mode: PaginationMode) => {
        dispatch(
            updateQueryParams({
                code: code,
                queryParams: paginationService.updatePagination(queryParamsState.queryParams, paginationControl, mode),
            })
        )

        // setQueryParams(paginationService.updatePagination(tableQueryParams, paginationControl, mode))
        // dispatch(changePagination({code: state.code, mode: mode} as GuslTableChangePaginationPayload))
    }
    const onRowsPerPageChange = (option: Option) => {
        setRowsPerPage(option)
        dispatch(
            changePaginationRows({
                code: code,
                skip: 0,
                limit: parseInt(option.value, 10),
            } as ChangePaginationRows)
        )
        setRowsPerPageOpen(false)
    }

    const renderRowPerPage = (option: Option, idx: number) => {
        return (
            <LookupTableRowStyled
                role={'button'}
                key={'id_' + idx}
                id={'id_' + idx}
                active={false}
                lookupField={true}
                onClick={() => onRowsPerPageChange(option)}
            >
                <LookupColumnStyled textAlign={'right'} key={'hdr_row_' + idx}>
                    {option.label}
                </LookupColumnStyled>
            </LookupTableRowStyled>
        )
    }
    const renderRowsPerPage = () => {
        return <>{rowsPerPageItems && rowsPerPageItems.map((option, idx) => renderRowPerPage(option, idx))}</>
    }
    const renderRowsPerPageLookup = (): React.ReactElement => {
        return (
            <RowsPerPageLookupStyled>
                <OptionTableContainerStyled>
                    <RowsPerContentStyled footerHeight={footerHeight}>
                        <RowsPerPageTableResponsiveStyled>
                            <LookupTableStyled>
                                <LookupTableBodyStyled id={'lk_rowsperpage'} isOverFlown={false}>
                                    {renderRowsPerPage()}
                                </LookupTableBodyStyled>
                            </LookupTableStyled>
                        </RowsPerPageTableResponsiveStyled>
                    </RowsPerContentStyled>
                </OptionTableContainerStyled>
            </RowsPerPageLookupStyled>
        )
    }
    const renderRowsPerPageAsComboBox = (): React.ReactElement => {
        return (
            <>
                <RowsPerPageContainerStyled isMobile={isMobile} onClick={() => setRowsPerPageOpen(!rowsPerPageOpen)}>
                    {/*{!isMobile && <div className={''}>{translateService.getKey('table.pagination.rowsPerPage')}</div>}*/}
                    <OptionComboStyled>
                        <i className={'fa-solid fa-caret-down me-1'}></i> {rowsPerPage.label}
                    </OptionComboStyled>
                </RowsPerPageContainerStyled>
                {rowsPerPageOpen && renderRowsPerPageLookup()}
            </>
        )
    }

    function TotalCount() {
        return !gettingCountError ? (
            <div>Total Count: {totalCount}</div>
        ) : (
            <div className={'invalid xs-small'}>Error getting total count!</div>
        )
    }

    async function getTotalHandler() {
        setGettingCountError(false)
        if (totalCount === 0) {
            await MGMutation({
                //     @ts-ignore
                sessionContext,
                endpoint: `/${code.toLowerCase()}/count`,
                queryParams: {},
            })
                .then((response) => {
                    const countResponse: CountResponseDO = response as unknown as CountResponseDO
                    setTotalCount(countResponse.data.count)
                })
                .catch((e) => {
                    console.error(e)
                    setGettingCountError(true)
                })
        }
    }
    return (
        <div className={'pagination-wrapper'}>
            <div className={'pagination d-flex align-items-center ' + (isMobile ? ' justify-content-between ' : ' justify-content-start')}>
                {!hasInfiniScroll && (
                    <div className={'pagination-per-page'}>
                        {/*{renderRowsPerPageAsSelect()}*/}
                        {renderRowsPerPageAsComboBox()}
                    </div>
                )}
                <FromToStyled>
                    {!noCount ? (
                        translateService.getKey('table.pagination.showing', paginationControl)
                    ) : (
                        <div>
                            <ElementWithPopover
                                trigger={'click'}
                                element={
                                    <div
                                        role={'button'}
                                        onClick={() => {
                                            getTotalHandler().then(noop)
                                        }}
                                    >
                                        Showing {paginationControl.from} to {paginationControl.to} of ...
                                    </div>
                                }
                                popover={<TotalCount />}
                            />
                        </div>
                    )}

                    {/*{translateService.getKey('table.pagination.showing', paginationControl)}*/}
                    {/*{!isMobile ?  translateService.getKey('table.pagination.showing', paginationControl) :*/}
                    {/*    translateService.getKey('table.pagination.showing', paginationControl).replace("Showing","")}*/}
                </FromToStyled>
                {hasInfiniScroll && paginationControl.to >= 100 && (
                    <BackToTop>
                        {' '}
                        <div role={'button'} onClick={backToTopHandler}>
                            back to top
                        </div>
                    </BackToTop>
                )}

                {!hasInfiniScroll && (
                    <div className={'pagination-buttons'}>
                        {paginationControl.hasActiveButtons && (
                            <PaginationStyled>
                                <PaginationFirstStyled
                                    disabled={!paginationControl.firstActive}
                                    onClick={() => onPaginationChange(PaginationMode.FIRST)}
                                />
                                <PaginationPrevStyled
                                    disabled={!paginationControl.prevActive}
                                    onClick={() => onPaginationChange(PaginationMode.PREV)}
                                />
                                {currentContentTotal > 0 && (
                                    <PaginationNextStyled
                                        disabled={!paginationControl.nextActive}
                                        onClick={() => onPaginationChange(PaginationMode.NEXT)}
                                    />
                                )}

                                {!noCount && (
                                    <PaginationLastStyled
                                        disabled={!paginationControl.lastActive}
                                        onClick={() => onPaginationChange(PaginationMode.LAST)}
                                    />
                                )}
                            </PaginationStyled>
                        )}
                    </div>
                )}

                {/*{isMobile && <div className={'fs-5 pt-2 pe-2'}>*/}
                {/*    <ElementWithTooltip*/}
                {/*        element={<span><ActionIconStyled*/}
                {/*            onClick={() => {*/}
                {/*                dispatch(toggleMobileTableControls({code}))*/}
                {/*            }}*/}
                {/*        >*/}
                {/*                <Icon icon={"fa-solid fa-gear"}/>*/}
                {/*            </ActionIconStyled></span>}*/}
                {/*        tooltip={<span>{showMobileContent ? "Hide Controls" : "Show controls"}</span>}*/}
                {/*    />*/}
                {/*</div>}*/}
            </div>
        </div>
    )
}
