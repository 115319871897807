import { html } from '@codemirror/lang-html'
import CodeMirror from '@uiw/react-codemirror'
import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { inputClass } from '../../../pages/login/LoginUsernamePassword'
import { getSettings, performValidation } from '../../../services/ValidationService'
import { assignReferences, noop, RunOnceEffect } from '../../../utils/Utils'
import { FieldProperties, FormMode } from '../../types'
import FieldLabel from '../field-label/FieldLabel'
import './CodeField.css'
import { GuslFormState } from '../maintain-form/guslFormSlice'

export const CodeField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [formMode, setFormMode] = useState(properties.formMode)
    // const [formValue, setFormValue] = useState<string>(properties?.data || '');
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) || properties.data || '')

    const valueRef = useRef(properties?.data)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [submitted, setSubmitted] = useState(false)

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setFormValue(properties?.data || '')
    }

    useEffect(() => {
        setFormValue(properties?.data || '')
    }, [properties])

    const doValidation = (fieldValue: any): boolean => {
        return performValidation(
            formMode,
            properties.menuItem?.code,
            properties.fieldConfig,
            // valueRef?.current?.value,
            fieldValue,
            setSubmitted,
            setErrorMessage
        )
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        // should not be in a table
        return <></>
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const onChange = React.useCallback((value: any, viewUpdate?: any) => {
        setFormValue(value)
        properties.onChange(properties.fieldConfig.name, value)
        // properties.onChange(properties.fieldConfig.name, valueRef?.current?.value);
        // console.log('value:', value);
        // console.log('valueRef:', valueRef);
    }, [])

    const renderCodeView = (): React.ReactElement => {
        return (
            <div className="row g-0">
                <FieldLabel properties={properties} />
                <div className="code-wrapper">
                    <CodeMirror
                        ref={valueRef}
                        value={formValue}
                        height="200px"
                        // extensions={[javascript({ jsx: true })]}
                        extensions={[html()]}
                        //  extensions={[oneDarkTheme]}
                        onChange={onChange}
                        className={inputClass() + (submitted && !formValue ? 'yellow' : '')}
                    />
                </div>
                {submitted && errorMessage && <small className="yellow">{errorMessage}</small>}
            </div>
        )
    }

    const renderFormView = (): React.ReactElement => {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        return <>{!hideField && renderCodeView()}</>
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
