import { log } from '../../../services/LogService'
import { DEFAULT_CHART_OPTIONS } from '../chart-options/defaultChartOptions'
import { ChartDO } from '../types'
import checkDatasetsColors from './checkDatasetsColors'

type Props = {
    chartType: string
    demoVariation?: string
    endpoint?: string
    serverData?: ChartDO
    chart?: any
    chartFor?: string
}

export default function PrepareChartData({ chartType, endpoint, serverData, chart, chartFor }: Props) {
    const className = 'PrepareChartData-' + new Date().getTime()

    try {
        let datasets
        let options
        let labels
        let canvasHeight = undefined

        datasets = checkDatasetsColors({
            datasets: serverData?.datasets,
            chart,
            chartType,
            chartFor,
        })

        try {
            // @ts-ignore
            // serverData = setChartOptions({serverData, chartType, clientChartOptions})
            // log.info(className, 'MSG003->serverData xxx', {serverData:serverData?.chartOptions});
            // options = serverData?.chartOptions[chartType];
            /*todo get the theme and colors from the theme to color the ticks, grid...*/
            // options['scales']['x']['ticks']['color'] = '#fc0b51'
            /*not sure how to get function from the server*/
            // if (typeof options['plugins']['tooltip']!== "undefined") {
            //     options['plugins']['tooltip']['callbacks']['label'] = options['plugins']['tooltip']['callbacks']['label'].split("\"").join("")
            // }
            canvasHeight = serverData?.chartOptions?.[chartType]?.chart?.canvas.height
        } catch (e) {
            log.error(className, 'MSG001', 'using default chart options for ' + chartType, { error: e })
            options = DEFAULT_CHART_OPTIONS[chartType]
            /*todo get the theme and colors from the theme to color the ticks, grid...*/
            // options['scales']['x']['ticks']['color'] = '#fc0b51'
            // options['scales']['x']['ticks']['color'] = '#fc0b51'
        }
        labels = serverData?.labels || []

        // log.info('prepareChartsData','MSG001','ok')
        return { datasets, options, labels, canvasHeight }
    } catch (e) {
        log.error('prepareChartsData', 'MSG001', e)
        const datasets: undefined | any[] = []
        const labels: undefined | any[] = []
        const options = {}
        const canvasHeight = 300
        return { datasets, labels, options, canvasHeight }
    }
}
