export const GraphMessageType: any = {
    GRAPH_ADD_RESPONSE: 'graph-add',
    GRAPH_UPDATE_RESPONSE: 'graph-update',
    GRAPH_REMOVE_RESPONSE: 'graph-remove',
    GRAPH_INITIAL_LOAD_RESPONSE: 'graph-attach',
    GRAPH_FETCH_RESPONSE: 'graph-fetch',
    GRAPH_SCHEMA_RESPONSE: 'graph-schema',
    GRAPH_CLIENT_ATTACHMENTS_RESPONSE: 'graph-current_attachments',
    GRAPH_OK_RESPONSE: 'ok',
    GRAPH_FAIL_RESPONSE: 'fail',
    GRAPH_DETACH_RESPONSE: 'graph-detach',
    GRAPH_DETACH_ALL_RESPONSE: 'graph-detach_all',
}
