import React, { useContext, useState } from 'react'
import { FavQueryActionConfigDTO, FavQueryDTO, QueryParamsDTO } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { GuslTableQueryParamState, updateQueryParams } from '../queryParamsSlice'
import { ElementWithTooltip } from '../../element-with/element-with-tooltip/ElementWithTooltip'
import Icon from '../../maintain-table/bootstrap/Icon'
import ElementWithPopover from '../../element-with/element-with-popover/ElementWithPopover'
import FavQueryForm from './FavQueryForm'
import { queryParamsAreTheSame } from './functions'
import MGMutation from '../../../../providers/session/MGMutation'
import { SessionContext } from '../../../../providers/session/SessionContext'
import { TextFilterWrapperStyled } from '../styled/text-filter/styled'
import { ActionIconStyled } from '../styled/table-controls/styled'
import { environmentService } from '../../../../providers/environment/EnvironmentService'
import { handleFavQueriesUpdates } from '../guslTableSlice'

export interface FavQueryDO {
    favQuery: FavQueryDTO
    code: string
    functions: { [index: string]: Function }
    errorMsg: string
}

export default function FavQuery({ favQuery, code, functions, errorMsg }: FavQueryDO) {
    const [className] = useState('FavQuery-' + new Date().getTime())
    const [errMsg, setErrMsg] = useState<string>('')
    const sessionContext = useContext(SessionContext)
    const queryParamsState: GuslTableQueryParamState = useAppSelector((state) => state.queryParamsSlice[code])
    const currentQuery: QueryParamsDTO = queryParamsState.queryParams
    const dispatch = useAppDispatch()
    const isActive: boolean = queryParamsAreTheSame(favQuery.queryParams, currentQuery)
    const isDefault: boolean = favQuery.isDefault
    const isGlobal: FavQueryDTO['isGlobal'] = favQuery.isGlobal
    const favQueriesURLs: FavQueryActionConfigDTO = environmentService.getEnvironment()?.favQueriesUrls as FavQueryActionConfigDTO
    const deleteUrl: string = favQueriesURLs?.deleteUrl || 'needToDoBackoffice'

    // MK 08-01-2024 todo get this permission from the server

    const hasPermissionToSaveAsGlobal: boolean = false

    function selectFavQueryHandler() {
        dispatch(
            updateQueryParams({
                code: code,
                queryParams: favQuery.queryParams,
            })
        )
    }

    async function removeFavQueryHandler() {
        await MGMutation({
            // @ts-ignore
            sessionContext,
            endpoint: deleteUrl,
            queryParams: { id: favQuery.id },
        })
            .then((result) => {
                functions.setQueryRefresher(new Date().getTime())
                functions.toggleHandler()
                functions.toggleHandler()
                setErrMsg('')
                // MK 06-01-2024
                dispatch(handleFavQueriesUpdates({ code }))
            })
            .catch((e) => {
                setErrMsg('Something went wrong!')
            })
    }

    return (
        <TextFilterWrapperStyled>
            <div className={'row g-0 mb-1 d-flex align-items-center'}>
                <div className="col-10">
                    <div onClick={selectFavQueryHandler} role={'button'} style={{ whiteSpace: 'pre-line' }} className={'text-break '}>
                        {isGlobal && (
                            <ElementWithTooltip
                                element={
                                    <ActionIconStyled>
                                        <Icon icon={'fa-solid fa-users'} />
                                    </ActionIconStyled>
                                }
                                tooltip={<span>This is global query.</span>}
                            />
                        )}
                        {isActive ? (
                            <ElementWithTooltip
                                placement={'left'}
                                element={
                                    <span>
                                        {isDefault && <Icon icon={'fa-solid fa-thumbtack'} extraClass={'me-2'} />}
                                        {favQuery.queryName}
                                        <>&nbsp;&#x2713;</>
                                    </span>
                                }
                                tooltip={<span>Currently used query {isDefault && <>Default query</>}</span>}
                            />
                        ) : isDefault ? (
                            <ElementWithTooltip
                                placement={'left'}
                                element={
                                    <span>
                                        <Icon icon={'fa-solid fa-thumbtack'} extraClass={'me-2'} /> {favQuery.queryName}
                                    </span>
                                }
                                tooltip={<span>Default query</span>}
                            />
                        ) : (
                            <>{favQuery.queryName}</>
                        )}
                    </div>
                </div>
                <>
                    <div className="col-1" role={'button'} onClick={removeFavQueryHandler}>
                        <ElementWithTooltip
                            element={
                                <ActionIconStyled>
                                    <Icon icon={'fa-solid fa-xmark'} />
                                </ActionIconStyled>
                            }
                            tooltip={<span>Delete this query?</span>}
                        />
                    </div>
                    <div className="col-1" role={'button'}>
                        <ElementWithTooltip
                            element={
                                <span>
                                    <ElementWithPopover
                                        withClose
                                        trigger={'click'}
                                        placement={'left-start'}
                                        element={
                                            <ActionIconStyled>
                                                <Icon icon={'fa-solid fa-pencil'} />
                                            </ActionIconStyled>
                                        }
                                        popover={
                                            <FavQueryForm
                                                code={code}
                                                action={'edit'}
                                                favQuery={favQuery}
                                                functions={functions}
                                                errorMsg={errorMsg}
                                            />
                                        }
                                    />
                                </span>
                            }
                            tooltip={<span>Edit this query?</span>}
                        />
                    </div>
                </>
            </div>
            {errMsg ? <>{errMsg}</> : <></>}
        </TextFilterWrapperStyled>
    )
}
