import React, { useState } from 'react'
import { removeSpaces } from '../../../../utils/Utils'
import { BuySellState } from '../buySellSlice'
import { HeaderItemLabelStyled, HeaderItemStyled, HeaderItemValueProps, HeaderItemValueStyled } from '../styled_v2'
import ChangePerc from './ChangePerc'
import { NewTickerDO } from './types'

export interface HeaderItemDO {
    ticker: NewTickerDO
    label: string
    value: number
    valueType: 'lastPrice' | 'dailyHigh' | 'dailyLow' | 'change' | 'volume'
    buySellSlice: BuySellState
}

export default function HeaderItem({ ticker, label, value, valueType, buySellSlice }: HeaderItemDO) {
    const [className] = useState('HeaderItem-' + new Date().getTime())
    const colorMap: {
        [K in HeaderItemDO['valueType']]: HeaderItemValueProps['color']
    } = {
        lastPrice: 'light',
        dailyHigh: 'muted',
        dailyLow: 'muted',
        change: valueType === 'change' && value > 0 ? 'positive' : valueType === 'change' && value < 0 ? 'negative' : 'light',
        volume: 'muted',
    }

    const isMoney: boolean = ['lastPrice', 'dailyHigh', 'dailyLow'].includes(valueType)
    const isChange: boolean = valueType === 'change'
    const updatedTicker: NewTickerDO = { ...ticker }

    const renderMoney = (): React.ReactElement => {
        return (
            <div className={'d-flex align-items-center justify-content-start'}>
                <span>{updatedTicker[valueType]}</span>
                <>&nbsp;</>
                <span>{buySellSlice.currency}</span>
            </div>
        )
    }

    const renderChange = (): React.ReactElement => {
        return <ChangePerc value={value} multiplyBy100 />
    }
    const renderValue = (): React.ReactElement => {
        return <>{value}</>
    }

    return (
        <HeaderItemStyled id={removeSpaces(label)}>
            <HeaderItemValueStyled color={colorMap[valueType]}>
                {isMoney && renderMoney()}
                {isChange && renderChange()}
                {!isMoney && !isChange && renderValue()}
            </HeaderItemValueStyled>
            <HeaderItemLabelStyled>{label}</HeaderItemLabelStyled>
        </HeaderItemStyled>
    )
}
