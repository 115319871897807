import styled from 'styled-components'

export const CircleFieldStyled = styled.div.attrs((props) => ({}))`
    width: 12px;
`

export const CircleWrapperStyled = styled.div.attrs((props) => ({}))`
    text-align: center;
    margin: 0px auto;
    width: fit-content;
`
