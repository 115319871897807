import React, { useRef, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { getSettings, performValidation } from '../../../services/ValidationService'
import { getStyle } from '../../../utils/CssUtils'
import { assignReferences, noop, RunOnceEffect } from '../../../utils/Utils'
import { fieldService } from '../../FieldService'
import { ChildReference, FieldConfigDTO, FieldProperties, FormMode } from '../../types'
import FieldLabel from '../field-label/FieldLabel'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { FloatingFormStyled, TextFieldStyled } from '../text/styled'
import { ThemeCollectionFieldsContainerStyled, ThemeCollectionSubPanelStyled } from './styled'

export const ThemeCollectionField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'ThemeCollectionField-' + new Date().getTime())
    const [uniqueId] = React.useState<string>(() => 'id_' + Math.random())
    const valueRef = useRef(properties?.data)

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [submitted, setSubmitted] = useState(false)
    const [formMode, setFormMode] = useState(properties.formMode)
    // const [formData, setFormData] = useState<any>(properties?.data || {});
    const [formValue, setFormValue] = useState<any>(_guslFormState?.getFieldValue(properties) || properties.data || {})

    const [childReferences] = useState<Map<string, ChildReference>>(new Map())

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setFormValue(properties?.data || '')
        childReferences.forEach((reference) => {
            if (reference?.changeMode) {
                reference.changeMode(mode)
            }
        })
    }

    const doValidation = (fieldValue: any): boolean => {
        let passed = true
        childReferences.forEach((reference) => {
            if (reference.name && reference?.doValidation) {
                if (!reference.doValidation(fieldValue ? fieldValue[reference.name] : undefined)) {
                    passed = false
                }
            }
        })
        if (!passed) {
            setErrorMessage('Failed form validation')
            return false
        }

        return performValidation(
            formMode,
            properties.menuItem?.code,
            properties.fieldConfig,
            valueRef?.current?.value,
            setSubmitted,
            setErrorMessage
        )
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.fieldConfig.tableCss, '')

        return (
            <TextFieldStyled textAlign={properties.fieldConfig.align}>
                <span className="" style={style}></span>
            </TextFieldStyled>
        )
    }

    const renderField = (key: string, index: number, fieldConfig: FieldConfigDTO): React.ReactElement => {
        const registerChildReference = (reference: ChildReference) => {
            childReferences.set(reference.name, reference)
        }
        let firstField: number = -1

        const onSubmitted = (): void => {
            // do nothing
        }
        const onChange = (name: string, value: any): void => {
            // const currentFormData = {...formData}
            const currentFormData = formValue
            if (currentFormData) {
                // @ts-ignore
                currentFormData[name] = value
            }
            setFormValue(currentFormData)
        }
        const getCurrentRowData = (): any => {
            return formValue
        }

        return (
            <div key={fieldConfig.name}>
                {fieldService.getFormTemplate({
                    code: 'themeCollection',
                    formMode: formMode,
                    isFirstField: false,
                    fieldConfig,
                    menuItem: properties.menuItem,
                    data: formValue ? formValue[fieldConfig.name] : undefined,
                    onSubmitted,
                    onChange,
                    getCurrentRowData,
                    reference: {
                        name: key + '_' + fieldConfig.name,
                        displayOrder: index,
                        register: registerChildReference,
                    },
                })}
            </div>
        )
    }
    const renderFormView = (): React.ReactElement => {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        if (hideField) {
            return <></>
        }

        return (
            <>
                <FloatingFormStyled>
                    <ThemeCollectionSubPanelStyled>
                        <ThemeCollectionFieldsContainerStyled>
                            {properties.fieldConfig.innerFields?.map((fld: FieldConfigDTO, index: number) =>
                                renderField(properties.fieldConfig.name, index, fld)
                            )}
                        </ThemeCollectionFieldsContainerStyled>
                    </ThemeCollectionSubPanelStyled>
                    <FieldLabel properties={properties} />
                    {submitted && errorMessage && <small className="yellow">{errorMessage}</small>}
                </FloatingFormStyled>
            </>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
