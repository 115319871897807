import React, { useContext, useLayoutEffect, useRef, useState } from 'react'
import { useBlastDelta } from '../../providers/blast/hook/useBlastDelta'
import { SessionContext } from '../../providers/session/SessionContext'
import { constructUrl, RunOnceEffect } from '../../utils/Utils'
import { HeaderDTO } from '../types'

interface GuslHtmlBlockProperties {
    htmlContent?: string
    htmlContentUrl?: string
}

export const GuslHtmlBlock = (properties: GuslHtmlBlockProperties): React.ReactElement<GuslHtmlBlockProperties> => {
    const sessionContext = useContext(SessionContext)

    const elRef = useRef<HTMLDivElement>(null)
    const hasFiredRef = useRef<boolean>(false)
    useBlastDelta(elRef)

    const [headerData, setHeaderData] = useState<HeaderDTO | undefined>(undefined)

    const getHtmlBlock = () => {
        sessionContext
            .get<HeaderDTO>(constructUrl(properties?.htmlContentUrl || '', {}))
            .then((response) => {
                console.log('response', response.data)
                setHeaderData(response.data)
            })
            .catch((error) => {})
    }
    RunOnceEffect(() => {
        if (properties.htmlContentUrl) {
            getHtmlBlock()
        }
    })
    const toggleClicked = () => {
        console.log('toggle clicked')
    }
    useLayoutEffect(() => {
        // if (hasFiredRef.current) return;
        if (!elRef.current) return

        const range = document.createRange()
        range.selectNode(elRef.current)
        let documentFragment = undefined
        if (properties.htmlContent) {
            documentFragment = range.createContextualFragment(properties.htmlContent)
        } else if (headerData?.htmlBlock) {
            console.log('-- here --')
            // @ts-ignore
            documentFragment = range.createContextualFragment(headerData.htmlBlock)
        }
        if (documentFragment) {
            elRef.current.innerHTML = ''
            // @ts-ignore
            elRef.current.append(documentFragment)
            hasFiredRef.current = true
        }
    }, [])

    if (properties.htmlContent) {
        return <div ref={elRef} dangerouslySetInnerHTML={{ __html: properties.htmlContent }}></div>
    } else if (headerData?.htmlBlock) {
        return <div ref={elRef} dangerouslySetInnerHTML={{ __html: headerData.htmlBlock }}></div>
    } else {
        return <></>
    }
}
