import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { ActionDialogHeaderStyled, ActionDialogTitleStyled } from '../../../common/action-dialog/styled'
import { DraggableModalDialog } from '../../../common/draggable-modal/draggable-modal'
import { WysiwygTableContentStyled } from '../../../common/wysiwyg/styled'
import { updatePagedQueryParams } from '../../../paged-response/pagedResponseSlice'
import ArticleModal from '../article-modal/ArticleModal'
import { ArticleInlineDateStyled } from '../articles-view/styled'
import { ArticleTextStyle, OttoBoxNoBgStyled, OttoCol12, OttoCol6, OttoRow, ScrollBox } from '../styled'
import RegulationArticles from './components/RegulationArticles'
import RightsIssuesArticles from './components/RightsIssuesArticles'
import RumoursArticles from './components/RumoursArticles'
import SpinOffsArticles from './components/SpinOffsArticles'
import { OtherDealsState, selectedOtherDealArticle, updateQueryParams } from './otherDealsSlice'
import OtherDealsSearch from './search/OtherDealsSearch'
import { ArticleViewDialogBodyStyled, ArticleViewWrapperStyled, OtherDealGrid } from './styled'

interface OtherDealsViewProperties {
    code: string
}

const OtherDealsView = ({ code }: OtherDealsViewProperties): React.ReactElement => {
    const dispatch = useAppDispatch()

    const [contentsHeight, setContentsHeight] = useState('400px')
    const contentsElement = useRef(null)
    const _otherDealsState: OtherDealsState = useAppSelector((state) => state.otherDealsSlice)

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                if (contentsElement) {
                    // @ts-ignore
                    const rect = contentsElement?.current?.getBoundingClientRect()
                    if (rect) {
                        let h = window.innerHeight - rect.top - 50 - 44
                        setContentsHeight(h / 2 - 100 + 'px')
                    }
                }
            }, 100)
        })
    }, [code])

    const onHide = () => {
        dispatch(selectedOtherDealArticle({ article: undefined }))
    }

    const onSearchValueChange = (value: string | undefined) => {
        // dispatch(updateQueryParams({searchString: value}))
        dispatch(updatePagedQueryParams({ code: 'rumours', searchString: value }))
        dispatch(updatePagedQueryParams({ code: 'spin-off', searchString: value }))
        dispatch(updatePagedQueryParams({ code: 'rights-issue', searchString: value }))
        dispatch(updatePagedQueryParams({ code: 'regulation', searchString: value }))
        // refreshData()
    }

    return (
        <>
            <OtherDealGrid ref={contentsElement}>
                <OttoRow style={{ marginBottom: '20px' }}>
                    <OttoCol12>
                        <OtherDealsSearch onSearchValueChange={onSearchValueChange} />
                    </OttoCol12>
                </OttoRow>
                <OttoRow style={{ marginBottom: '40px' }}>
                    <OttoCol6>
                        <RumoursArticles code={'rumours'} contentsHeight={contentsHeight} />
                    </OttoCol6>
                    <OttoCol6>
                        <SpinOffsArticles code={'spin-off'} contentsHeight={contentsHeight} />
                    </OttoCol6>
                </OttoRow>
                <OttoRow style={{ marginBottom: '40px' }}>
                    <OttoCol6>
                        <RightsIssuesArticles code={'rights-issue'} contentsHeight={contentsHeight} />
                    </OttoCol6>
                    <OttoCol6>
                        <RegulationArticles code={'regulation'} contentsHeight={contentsHeight} />
                    </OttoCol6>
                </OttoRow>
            </OtherDealGrid>
            {_otherDealsState?.selectedArticle && (
                <ArticleModal articleId={_otherDealsState?.selectedArticle?.id || ''} onHideCallBack={onHide} />
            )}
        </>
    )
}
export default OtherDealsView
