import React, { useEffect, useRef, useState } from 'react'
import { DragDropContext, Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { EnvironmentContext } from '../../../providers/environment/EnvironmentContext'
import { environmentService } from '../../../providers/environment/EnvironmentService'
import { SessionContext } from '../../../providers/session/SessionContext'
import { GuslThemeContext } from '../../../providers/theme/GuslThemeProvider'
import { log } from '../../../services/LogService'
import { canShowOnForm, getSettings, performValidation } from '../../../services/ValidationService'
import { assignReferences, constructUrl, noop, RunOnceEffect, unSubscribe } from '../../../utils/Utils'
import { FieldConfigDTO, FieldProperties, FormMode, MediaType, TableRowDTO } from '../../types'
import FieldLabel from '../field-label/FieldLabel'
import { initColumnResize } from '../gusl-table/guslColumnResizeSlice'
import TableColumnHeader from '../gusl-table/TableColumnHeader'
import { addNestedRow, GuslFormState, removeNestedRow } from '../maintain-form/guslFormSlice'
import { ActionHeaderColumnStyled } from '../maintain-table/styled'
import { FieldContentStyled, FloatingFormStyled, HintStyled } from '../text/styled'
import {
    AddRowLinkStyled,
    DraggableRowStyled,
    NestedFieldContentStyled,
    NestedFieldContentWrapperStyled,
    NestedTableBodyStyled,
    NestedTableHeadStyled,
    NestedTableResponsiveStyled,
    NestedTableStyled,
} from './styled'
import { setScrollLeftPosition } from '../gusl-table/scrollSlice'

interface ReorderDTO {
    sourceIndex: number
    sourceRow: TableRowDTO

    destinationIndex: number
    destinationRow: TableRowDTO
}

export const NestedTableField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const [className] = React.useState<string>(() => 'NestedTableField-' + new Date().getTime())

    const dispatch = useAppDispatch()
    const environmentContext = React.useContext(EnvironmentContext)
    const guslThemeContext = React.useContext(GuslThemeContext)
    const sessionContext = React.useContext(SessionContext)

    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    const [formMode, setFormMode] = useState(properties.formMode)
    const [formValue, setFormValue] = useState<any[]>(_guslFormState?.getFieldValue(properties) || properties.data || [])

    const [fields] = useState<FieldConfigDTO[]>((properties.fieldConfig.innerFields || []).filter((fld) => fld.displayInTable))
    const [hasActions] = useState<boolean>((properties.fieldConfig?.actions?.length || 0) > 0)
    const [numberOfActions] = useState<number>(properties.fieldConfig?.actions?.length || 0)
    const topScrollBarPositionRef = useRef(0)
    const [disableColumnResize] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(true)
    const tableBodyElement = useRef(null)
    const [bodyHeight, setBodyHeight] = useState(300) // should be a value on field property
    const [mediaType, setMediaType] = useState<MediaType>(MediaType.Desktop)

    const [panelBgColor] = useState<string>(
        guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).panel.panelBgColor
    )
    const [grid] = useState<number>(2)
    const [lastIdx, setLastIdx] = useState<number>(1000)

    log.debug(className, 'MSG001', 'properties', { properties })

    useEffect(() => {
        const subscriptionResize = environmentContext.watchResize().subscribe(() => {})

        const subscriptionMediaType = environmentContext.watchMediaType().subscribe((mediaType: MediaType) => {
            setMediaType(mediaType)
        })
        return () => {
            unSubscribe(subscriptionMediaType)
            unSubscribe(subscriptionResize)
        }
    }, [])

    useEffect(() => {
        // dispatch(initField({
        //     code: properties.fieldConfig.name,
        //     actions: properties.fieldConfig.actions,
        //     fields: properties.fieldConfig?.innerFields || [],
        //     tableData: properties.data || [],
        //     onChange: properties.onChange,
        //     onArrayChange: properties.onArrayChange,
        //     getCurrentRowData: properties.getCurrentRowData
        //
        // }))
        dispatch(
            initColumnResize({
                code: properties.fieldConfig.name,
                resizedColumns: {},
                // MK 30-12-2023
                columnsResetAt: null,
            })
        )

        setLoading(false)

        // return (() => {
        //     dispatch(cleanUpNestedField({code: properties.fieldConfig.name}))
        // })
    }, [properties])

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                try {
                    // @ts-ignore
                    if (tableBodyElement?.current?.offsetHeight) {
                        // @ts-ignore
                        const rect = tableBodyElement?.current?.getBoundingClientRect()
                        if (rect) {
                            const h = window.innerHeight - rect.top - 30
                            setBodyHeight(h > 0 ? h : 500)
                        } else {
                            setBodyHeight(500)
                        }
                    }
                } catch (error) {
                    console.log('error', error)
                }
            }, 100)
        })
    }, [loading])

    const handleScroll = (e: { currentTarget: any }) => {
        const scrollLeft = e.currentTarget.scrollLeft
        if (scrollLeft !== topScrollBarPositionRef.current) {
            topScrollBarPositionRef.current = scrollLeft
        }
    }

    useEffect(() => {
        dispatch(
            setScrollLeftPosition({
                code: properties.fieldConfig.name,
                scrollLeftPosition: topScrollBarPositionRef.current,
            })
        )
    }, [topScrollBarPositionRef.current])

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setFormValue(properties?.data || [])
    }

    const doValidation = (fieldValue: any): boolean => {
        return performValidation(formMode, properties.menuItem?.code, properties.fieldConfig, fieldValue)
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        return <></>
    }

    const onRefresh = () => {
        if (properties.reLoad) {
            properties.reLoad()
        }
    }

    const renderColumnHeader = (fld: FieldConfigDTO, idx: number): React.ReactElement => {
        return (
            <TableColumnHeader
                code={properties.fieldConfig.name}
                fld={fld}
                formMode={formMode}
                topScrollBarPositionRef={topScrollBarPositionRef}
                key={'hdr_' + idx}
                disableColumnResize={disableColumnResize}
            />
        )
    }

    const onAddRow = (append: boolean) => {
        dispatch(
            addNestedRow({
                code: properties.code,
                name: properties.fieldConfig.name,
                append: append,
            })
        )
        // dispatch(addRow({code: properties.fieldConfig.name, append}))
    }

    const onChange = (name: string, value: any, rowIndex: number): void => {
        // this is row re-ordering change
        // dispatch(updateNestedRow({code: properties.code, name, value, rowIndex}))
    }

    const onDelete = (rowIndex: number): void => {
        dispatch(
            removeNestedRow({
                code: properties.code,
                name: properties.fieldConfig.name,
                rowIndex,
            })
        )
        //         setFormValue(_guslFormState?.getFieldValue(properties) || properties.data || [])
    }

    /*
            background: isDraggingOver ? panelBgColor : panelBgColor,

     */
    const getListStyle = (isDraggingOver: boolean) => ({
        padding: grid,
        width: '100%',
    })

    const updateDisplayOrderListOnServer = (reorder: ReorderDTO) => {
        if (properties.fieldConfig.reorderUrl) {
            sessionContext
                .post<ReorderDTO, any>(constructUrl(properties.fieldConfig.reorderUrl, properties.getCurrentRowData()), reorder)
                .then((response: any) => {
                    onRefresh()
                })
                .catch((error) => {
                    console.log('error', error)
                    // ignore
                })
        }
    }

    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        console.log('======================= FIX ME ====== result ', result)
        // updateDisplayOrderListOnServer({
        //     sourceIndex: result.source.index,
        //     sourceRow: state.getRow(result.source.index, state.tableData),
        //     destinationIndex: result.destination.index,
        //     destinationRow: state.getRow(result.destination.index, state.tableData)
        // })
        setLastIdx(1000)
    }

    const renderNestedTable = (): React.ReactElement => {
        const [hideField] = getSettings(formMode, properties.fieldConfig, formValue)
        const tableWrapperId = 'table_wrapper_' + properties.fieldConfig.name
        const editable = formMode === FormMode.NEW || formMode === FormMode.EDIT
        const canReorder = !editable && (properties.fieldConfig.canReorder || false)

        return (
            <>
                {!hideField && (
                    <>
                        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                            <Droppable droppableId="droppable">
                                {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                                    <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                                        <NestedTableResponsiveStyled id={'nested-table'}>
                                            <NestedTableStyled className={'table table-striped'} id={tableWrapperId}>
                                                <NestedTableHeadStyled>
                                                    <tr>
                                                        {hasActions && (
                                                            <ActionHeaderColumnStyled key={'hdr_actions'} numberOfActions={numberOfActions}>
                                                                Actions
                                                            </ActionHeaderColumnStyled>
                                                        )}
                                                        {fields
                                                            ?.filter((fieldConfig: FieldConfigDTO) => canShowOnForm(fieldConfig, formMode))
                                                            .map((fld, idx) => renderColumnHeader(fld, idx))}
                                                        <ActionHeaderColumnStyled key={'hdr_actions_remove'} numberOfActions={1}>
                                                            &nbsp;
                                                        </ActionHeaderColumnStyled>
                                                    </tr>
                                                </NestedTableHeadStyled>

                                                <NestedTableBodyStyled key={'nested_body' + _guslFormState?.counter}>
                                                    {formValue?.map((row: any, rowIndex: number) => (
                                                        <DraggableRowStyled
                                                            className={'d-flex justify-content-between'}
                                                            active={lastIdx === rowIndex}
                                                            code={properties.code}
                                                            fieldName={properties.fieldConfig.name}
                                                            formMode={formMode}
                                                            key={rowIndex}
                                                            // key={'id_' + rowIndex + '_' + row.id}
                                                            rowId={'' + rowIndex}
                                                            rowIndex={rowIndex}
                                                            // row={row}
                                                            fields={editable ? fields : fields}
                                                            onChange={onChange}
                                                            onDelete={onDelete}
                                                            menuItem={undefined}
                                                            conditionalRowDetails={row.conditionalRowDetails}
                                                            performTableRefresh={onRefresh}
                                                            mediaType={mediaType}
                                                            canReorder={canReorder}
                                                            canDelete={properties.fieldConfig.canDelete || false}
                                                        />
                                                    ))}
                                                    {provided.placeholder}
                                                </NestedTableBodyStyled>
                                            </NestedTableStyled>
                                        </NestedTableResponsiveStyled>
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                )}
            </>
        )
    }

    const renderAddRowButton = (append: boolean): React.ReactElement => {
        if (properties.fieldConfig.canAdd !== 'true') {
            return <></>
        }

        if (formMode !== FormMode.NEW && formMode !== FormMode.EDIT) {
            return <></>
        }
        return (
            <>
                <AddRowLinkStyled onClick={() => onAddRow(append)}>{append ? 'Append row' : 'Add row'}</AddRowLinkStyled>
                <br />
            </>
        )
    }

    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        return (
            <>
                {!hideField && (
                    <>
                        <FloatingFormStyled>
                            <NestedFieldContentWrapperStyled>
                                <NestedFieldContentStyled>
                                    {renderAddRowButton(false)}
                                    {renderNestedTable()}
                                    {renderAddRowButton(true)}
                                </NestedFieldContentStyled>
                                {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                                {/*{submitted && errorMessage &&*/}
                                {/*    <small className="yellow">{errorMessage}</small>}*/}
                            </NestedFieldContentWrapperStyled>
                            <FieldLabel properties={properties} />
                        </FloatingFormStyled>
                    </>
                )}
            </>
        )
    }

    // const mapStateToProps = (state: any): NestedFieldState => {
    //     return state.nestedFieldSlice[properties.fieldConfig.name]
    // }
    // const ConnectedTable = connect(mapStateToProps)(renderFormView);

    if (loading) {
        return <></>
    }
    return (
        <>
            <>{properties.isTableView ? renderTableView() : renderFormView()}</>
        </>
    )
}
