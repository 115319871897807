import styled, { ThemeProps } from 'styled-components'

export interface ActionButtonProperties extends ThemeProps<any> {
    onClick?: Function
    active?: boolean | undefined
    action?: string
    color?: string
    className?: string
    extraClass?: string
}

export const ActionButtonStyled = styled.button.attrs((props: ActionButtonProperties) => ({
    onClick: props.onClick,
    className: 'btn btn-sm ' + props.extraClass,
}))<ActionButtonProperties>`
    padding: 2px 8px 2px 8px;
    margin: 0 4px 0 4px;
    color: ${(props) => props.theme.colors.dark[1]};
    background-color: ${(props) => props.theme.colors.background[1]};
    :hover {
        cursor: pointer;
        color: ${(props) => props.theme.colors.dark[0]};
        background-color: ${(props) => props.theme.colors.accentColorLight};
    }
`

export const EditInputStyled = styled.div.attrs((props) => ({
    className: 'm-2 ',
}))`
    background-color: ${(props) => props.theme.table.panelBgColor};
`
