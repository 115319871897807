import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { BlastProps } from '../../../../providers/blast/BlastContext'
import { SessionContextProps } from '../../../../providers/session/types'
import { clone, constructUrl } from '../../../../utils/Utils'
import { QueryParamsDTO } from '../../../types'
import { ArticlesByDateDTO, OSSArticleDTO } from '../types'

export interface OtherDealsState {
    sessionContext: SessionContextProps | undefined
    blastContext: BlastProps | undefined
    selectUrl: string
    lastQueryParams: QueryParamsDTO | undefined
    refreshCounter: number

    rumours: ArticlesByDateDTO[]
    spinOffs: ArticlesByDateDTO[]
    regulation: ArticlesByDateDTO[]
    rightsIssue: ArticlesByDateDTO[]

    selectedArticle: OSSArticleDTO | undefined
}

const initialState: OtherDealsState = {
    sessionContext: undefined,
    blastContext: undefined,
    selectUrl: '',
    lastQueryParams: undefined,
    refreshCounter: 0,

    rumours: [],
    spinOffs: [],
    regulation: [],
    rightsIssue: [],
    selectedArticle: undefined,
}

export interface InitPayload {
    sessionContext: SessionContextProps
    blastContext: BlastProps
    selectUrl: string
    lastQueryParams: QueryParamsDTO | undefined
}

export interface OtherDealSelectedArticlePayload {
    article: OSSArticleDTO | undefined
}

export interface OtherDealQueryParamsPayload {
    searchString: string | undefined
}

interface OtherDealsRequest {
    url: string
    sessionContext: SessionContextProps
    abortController: AbortController
    pathParams?: any | undefined
}

export interface OtherDealsResponse {
    queryParams: QueryParamsDTO | undefined
    rumours: ArticlesByDateDTO[]
    spinOffs: ArticlesByDateDTO[]
    regulation: ArticlesByDateDTO[]
    rightsIssue: ArticlesByDateDTO[]
}

export interface OtherDealsResponseWrapper {
    response: OtherDealsResponse
}

export const getOtherDealsData = createAsyncThunk('other-deals-url', async (request: OtherDealsRequest, { getState }) => {
    // @ts-ignore
    const queryParams: QueryParamsDTO = getState()?.otherDealsSlice?.lastQueryParams
    const response = await request.sessionContext.post<QueryParamsDTO, OtherDealsResponse>(
        constructUrl(request.url, request.pathParams),
        queryParams,
        request.abortController
    )
    return { response: response?.data || {} }
})

const loadInitValues = (entry: OtherDealsState, values: InitPayload) => {
    entry.sessionContext = values.sessionContext
    entry.blastContext = values.blastContext
    entry.selectUrl = values.selectUrl
    entry.lastQueryParams = values.lastQueryParams
    entry.refreshCounter = 1
}
const performCleanUp = (entry: OtherDealsState) => {
    entry.sessionContext = undefined
    entry.blastContext = undefined
    entry.selectUrl = ''
    entry.lastQueryParams = undefined
    entry.refreshCounter = 0
    entry.selectedArticle = undefined
}

const updateData = (entry: OtherDealsState, response: OtherDealsResponse) => {
    entry.lastQueryParams = response.queryParams
    entry.refreshCounter = entry.refreshCounter + 1

    entry.rumours = response.rumours
    entry.spinOffs = response.spinOffs
    entry.regulation = response.regulation
    entry.rightsIssue = response.rightsIssue
}
export const otherDealsSlice = createSlice({
    name: 'otherDealsSlice',
    initialState,
    reducers: {
        initOtherDeals(state: any, action: PayloadAction<InitPayload>) {
            loadInitValues(state, action.payload)
        },
        selectedOtherDealArticle(state: any, action: PayloadAction<OtherDealSelectedArticlePayload>) {
            state.selectedArticle = action.payload.article
            state.refreshCounter = state.refreshCounter + 1
        },
        updateQueryParams(state: any, action: PayloadAction<OtherDealQueryParamsPayload>) {
            const params: QueryParamsDTO = clone(current(state.lastQueryParams))
            params.should = [
                {
                    field: 'content',
                    value: action.payload.searchString || '',
                    fuzzy: true,
                },
            ]
            state.lastQueryParams = params
        },
        cleanUpOtherDeals(state: any) {
            performCleanUp(state)
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getOtherDealsData.fulfilled, (state: any, action: PayloadAction<OtherDealsResponseWrapper>) => {
            updateData(state, action.payload.response)
        })
    },
})

export const { initOtherDeals, selectedOtherDealArticle, cleanUpOtherDeals, updateQueryParams } = otherDealsSlice.actions

export default otherDealsSlice.reducer
