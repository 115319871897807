import styled from 'styled-components'
import { HEADER_HEIGHT } from '../../../providers/theme/GuslThemeProvider'
import { Button } from '../../ui/button/button'

export const ToggleLabelStyled = styled.div.attrs((props) => ({}))`
    margin-left: 20px;

    a {
        color: ${(props) => props.theme.login.linkColor};
        font-size: 12px;
    }
`

interface GrapeContainerProperties {
    isDesignMode: boolean
}

export const GrapeContainerStyled = styled.div.attrs((props: GrapeContainerProperties) => ({
    id: 'grape-container',
}))<GrapeContainerProperties>`
    position: fixed;
    top: 100px;
    left: 0;
    width: 100%;
    height: 100vh;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 20;
    ${(props) => !props.isDesignMode && 'display:none'};
    ${(props) => props.isDesignMode && 'display:block'};

    .gjs-block-category.gjs-open {
        border-bottom: none !important;
    }

    .gjs-blocks-c {
        height: 100%;
    }

    .gjs-block {
        width: 45px !important;
        height: 45px !important;
        min-height: 45px !important;
    }
`

export const ArticleBuilderStyled = styled.div.attrs((props: GrapeContainerProperties) => ({
    id: 'article-builder',
}))<GrapeContainerProperties>`
    width: 100%;
    //padding-left: 10px;
    //padding-right: 10px;
    z-index: 20;

    // ${(props) => !props.isDesignMode && 'display:none'};
    // ${(props) => props.isDesignMode && 'display:block'};

    max-height: ${(props) => {
        return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (HEADER_HEIGHT + 16) + 'px)'
    }};
    height: ${(props) => {
        return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (HEADER_HEIGHT + 16) + 'px)'
    }};

    .gjs-block-category.gjs-open {
        border-bottom: none !important;
    }

    .gjs-blocks-c {
        height: 100%;
    }

    .gjs-one-bg {
        background-color: ${(props) => props.theme.navigation.sideMenu.bgColor};
    }
    .gjs-two-color {
        color: ${(props) => props.theme.navigation.sideMenu.color};
    }

    .gjs-block {
        /* changes size of icons */
        //width: 90px !important;
        //height: 90px !important;
        //min-height: 90px !important;
    }
    .gjs-block__media {
        width: 45px !important;
        height: 45px !important;
        min-height: 45px !important;
        text-align: center;
        margin: 0 auto;
    }
    .gjs-block-label {
        font-size: 14px;
        font-weight: bold;
    }
`

/*
    ${props => props.isEdit && 'width:0%'};
    ${props => props.isEdit && ''};
    ${props => props.isEdit && 'position: absolute;'};
    ${props => props.isEdit && 'top: 100px;'};
    ${props => props.isEdit && 'left: 0;'};
    ${props => props.isEdit && 'width: 100%;'};
    ${props => props.isEdit && 'height: 100vh;'};
    ${props => props.isEdit && 'padding-left: 10px;'};
    ${props => props.isEdit && 'padding-right: 10px;'};
    // ${props => props.isEdit && 'display:block'};
    // ${props => props.isEdit && 'display:none'};


    position: absolute;
    top: 100px;
    left: 0;
    //width: 100%;
    height: 100vh;
    padding-left: 10px;
    padding-right: 10px;

 */
export const ButtonStyled = styled(Button).attrs((props) => ({}))`
    color: ${(props) => props.theme.login.buttonFgColor};
    background-color: ${(props) => props.theme.login.buttonBgColor};
    //width: 100%;
    text-align: center;
    margin: auto;
`
