import React, { useEffect, useState } from 'react'
import Offcanvas, { OffcanvasProps } from 'react-bootstrap/Offcanvas'
import { Observable, Subscription } from 'rxjs'
import { EnvironmentContext } from '../../../../providers/environment/EnvironmentContext'
import { BaseTheme, GuslThemeContext } from '../../../../providers/theme/GuslThemeProvider'
import { RunOnceEffect, unSubscribe } from '../../../../utils/Utils'
import { OffCanvasBodyStyled, OffcanvasTitleStyled } from '../../../ui/navigation/navbar/styled'
import { maintainTableService } from '../../maintain-table/MaintainTableService'

export interface ElementWithOffCanvasDO {
    element: JSX.Element
    content: JSX.Element | JSX.Element[]
    title?: JSX.Element | JSX.Element[]
    close?: boolean
    open?: boolean

    onClose?: () => void

    onOpen?: () => void

    toggleSubject?: Observable<boolean>
}

export function ElementWithOffCanvas({
    element,
    content,
    title,
    placement,
    close,
    open,
    onOpen,
    onClose,
    toggleSubject,
}: ElementWithOffCanvasDO & OffcanvasProps) {
    const [className] = useState('ElementWithOffCanvas-' + new Date().getTime())
    const guslThemeContext = React.useContext(GuslThemeContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const [themeColors, setThemeColors] = useState<BaseTheme>(guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()))
    const currentCode = maintainTableService.getCodeFromUrl()
    const [show, setShow] = useState(false)

    RunOnceEffect(() => {
        let loaderSubscription: Subscription | undefined = undefined
        if (toggleSubject) {
            toggleSubject.subscribe((toggleValue: boolean) => {
                setShow(toggleValue)
            })
        }
        return () => {
            unSubscribe(loaderSubscription)
        }
    })

    const handleClose = () => {
        setShow(false)
        if (onClose) {
            onClose()
        }
    }
    const handleShow = () => {
        setShow(true)
        if (onOpen) {
            onOpen()
        }
    }

    useEffect(() => {
        handleClose()
    }, [currentCode, close])

    useEffect(() => {
        if (open) {
            handleShow()
        }
    }, [open])

    return (
        <>
            <div role={'button'} onClick={handleShow}>
                {element}
            </div>

            <Offcanvas
                show={show}
                onHide={handleClose}
                style={{
                    // backgroundColor: themeColors.colors.background[0],
                    backgroundColor: themeColors.navigation.sideMenu.bgColor,
                    width: '80%',
                    maxWidth: 300,
                    paddingRight: '10px',
                    paddingTop: 'env(safe-area-inset-top)',
                    // paddingTop: 'constant(safe-area-inset-top)'
                }}
                placement={placement}
            >
                <OffcanvasTitleStyled onClick={handleClose}>{title}</OffcanvasTitleStyled>
                <OffCanvasBodyStyled>{content}</OffCanvasBodyStyled>
            </Offcanvas>
        </>
    )
}
