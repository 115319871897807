import * as React from 'react'
import { useState } from 'react'
import { GuslTableProperties } from '../GuslTable'
import IndividualSplit from './IndividualSplit'

export default function SplitListView({ code, fields, selectUrl, label }: GuslTableProperties) {
    const [className] = useState('SplitListView-' + new Date().getTime())
    const numOfSplits = 2

    return (
        <div className={'row g-0'} style={{ marginTop: -30 }}>
            {Array.from({ length: numOfSplits }).map((_, idx) => (
                <IndividualSplit
                    key={idx}
                    code={code}
                    fields={fields}
                    selectUrl={selectUrl}
                    label={label}
                    name={'split-' + idx}
                    numOfSplits={numOfSplits}
                />
            ))}
        </div>
    )
}
