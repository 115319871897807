import { BehaviorSubject } from 'rxjs'
import { Attachment } from './graph/attachment'

export interface AttachmentInstance {
    path: string
    attachment?: Attachment
    numberOfAttachments: number

    data: any
}

export interface BlastGraphStore {
    [id: string]: AttachmentInstance
}

export class BlastException {
    constructor(public message: string) {}
}

export const BLAST_VERSION = '0.0.1'

export class EdgeToCustomerMessageBO {
    constructor(public cmd: string, public data: any, public hasError: boolean, public errorMessage: string) {}
}

export interface BlastServiceConfig {
    initialTimeout: number
    maxTimeout: number
    reconnectIfNotNormalClose: boolean
}

export interface BlastCommand {
    cmd: string
}

export interface ServerToClientMessageBO<T> {
    cmd: string
    data: T
    hasError?: boolean
    errorMessage?: string
}

export interface CollectionWsMessage<T> {
    action: CollectionAction
    status: Status
    data: T
    collection: string
}

export enum Status {
    OK = 'OK',
    ERROR = 'ERROR',
}

export enum CollectionAction {
    ADD = 'ADD',
    UPDATE = 'UPDATE',
    REMOVE = 'REMOVE',
    GET = 'GET',
    ALL = 'ALL',
    POPULATE = 'POPULATE',
    DROP_ALL = 'DROP_ALL',
    CLEAR = 'CLEAR',
}

export interface TickerCommandPayload<T> {
    correlationId?: number
    collection: string
    tickerId: number | string
    data?: T
    cmd: string
}

export class CacheEntity<K, V> {
    theCache = new Map<K, V>()
    theSubject: BehaviorSubject<V[]> = new BehaviorSubject([] as V[])

    public clear(): void {
        this.theCache.clear()
    }

    public add(key: K, value: V) {
        this.theCache.set(key, value)
    }

    public updateSubject(): void {
        this.theSubject.next(Array.from(this.theCache.values()))
    }

    public getValues(): V[] {
        return Array.from(this.theCache.values())
    }

    public watch(): BehaviorSubject<V[]> {
        return this.theSubject
    }

    public get(key: K): V | undefined {
        return this.theCache.get(key)
    }

    public dump(prefix: string) {
        console.log(prefix + ':', Array.from(this.theCache))
    }

    public dumpKeys(prefix: string) {
        console.log(prefix + ':', Array.from(this.theCache.keys()))
    }
}
