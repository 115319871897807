import React, { useRef, useState } from 'react'
import { Overlay, Tooltip } from 'react-bootstrap'

type Props = {
    children: JSX.Element | JSX.Element[]
    text: string | undefined
}

export default function WithTooltip({ children, text }: Props) {
    const [show, setShow] = useState(false)
    const target = useRef(null)

    return (
        <>
            <span ref={target} onClick={() => setShow(true)} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
                {children}
            </span>
            <Overlay target={target.current} show={show} placement="top">
                {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                        {text}
                    </Tooltip>
                )}
            </Overlay>
        </>
    )
}
