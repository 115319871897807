import React, { useState } from 'react'
import { FieldConfigDTO } from '../../../types'
import TextNumberEditInput from './inputs/TextNumberEditInput'
import DateEditInput from './inputs/DateEditInput'
import BooleanEditInput from './inputs/BooleanEditInput'
import OptionEditInput from './inputs/OptionEditInput'
import LookupEditInput from './inputs/LookupEditInput'
import { InlineEditInputCommonProps, InlineEditInputType, InlineTableInputProps } from './types'
import MultiSelectEditInput from './inputs/multi-select-edit-field/MultiSelectEditInput'

export default function InlineEditInputField({
    rowId,
    idx,
    fld,
    inputRefs,
    inlineFieldEditHandler,
    setFocus,
    defaultValue,
    row,
    code,
}: InlineTableInputProps) {
    const [className] = useState('InlineEditInputField-' + new Date().getTime())

    function getInputType(fld: FieldConfigDTO): InlineEditInputType {
        switch (fld.type) {
            case 'text':
            case 'code':
            case 'symbol':
            case 'textarea':
                return 'text'
            case 'number':
            case 'money':
            case 'new_money':
            case 'percent':
            case 'decimal':
                return 'number'
            case 'date':
            case 'date_time':
                return 'date'
            case 'option':
                return 'select'
            case 'multi_select':
                return 'multi_select'
            case 'lookup':
                return 'lookup'
            case 'checkbox':
                return 'checkbox'
            case 'radio':
                return 'radio'

            default:
                return 'text'
        }
    }

    const inputType: InlineEditInputType = getInputType(fld)
    const commonProps: InlineEditInputCommonProps = {
        key: 'ff-det_' + rowId + '_' + idx + '---' + fld.name,
        rowId: rowId,
        idx: idx,
        fld: fld,
        inputRefs: inputRefs,
        inlineFieldEditHandler: inlineFieldEditHandler,
        setFocus: setFocus,
        defaultValue: defaultValue,
        row,
        code,
    }
    let renderedComponent: React.JSX.Element
    switch (inputType) {
        case 'text':
        case 'number':
            renderedComponent = <TextNumberEditInput {...commonProps} />
            break
        case 'date':
            renderedComponent = <DateEditInput {...commonProps} />
            break
        case 'select':
            renderedComponent = <OptionEditInput {...commonProps} />
            break
        case 'checkbox':
        case 'radio':
            renderedComponent = <BooleanEditInput {...commonProps} />
            break
        case 'lookup':
            renderedComponent = <LookupEditInput {...commonProps} />
            break
        case 'multi_select':
            renderedComponent = <MultiSelectEditInput {...commonProps} />
            break

        default:
            renderedComponent = <TextNumberEditInput {...commonProps} /> // Render a default TextNumberEditInput if inputType doesn't match any case
    }
    return <>{renderedComponent}</>
}
