// import {
//     Chart as ChartJS,
//     Filler,
//     Legend,
//     LineElement,
//     PointElement,
//     RadialLinearScale,
//     Title,
//     Tooltip,
// } from 'chart.js';
import { Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js'
import React, { useEffect, useRef, useState } from 'react'
import { Scatter } from 'react-chartjs-2'
import { log } from '../../../services/LogService'
import ErrorMsg from '../../ui/ErrorMsg'
// @ts-ignore
import { ChartProps } from '../types'
import prepareChartData from '../utils/PrepareChartData'

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, Title)
type Props = {
    chartFor: string
    apiSlice: string
}

export default function ScatterChart({ endpoint, serverData, chartFor }: ChartProps) {
    const [className] = useState('ScatterChart-' + new Date().getTime())
    const chartRef = useRef<ChartJS>(null)
    const [chartData, setChartData] = useState({ datasets: [], labels: [] })
    const _options = serverData?.chartOptions?.scatter
    const [options, setOptions] = useState(_options)
    const _height = serverData?.chartOptions?.scatter?.chart?.canvas.height
    const [height, setHeight] = useState<string | number | undefined>(_height)

    try {
        useEffect(() => {
            const chart = chartRef.current
            if (!chart) {
                return
            }
            const { datasets, options, labels } = prepareChartData({
                chartType: 'scatter',
                endpoint,
                serverData,
                chart,
            })

            // @ts-ignore
            setChartData({ labels, datasets })
            setOptions(options)
        }, [chartFor, JSON.stringify(serverData)])

        /*@ts-ignore*/
        return <Scatter ref={chartRef} options={options} data={chartData} type={'scatter'} height={height} />
    } catch (e) {
        log.error(className, 'MSG001', { endpoint })
        return <ErrorMsg />
    }
}
