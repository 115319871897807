import React from 'react'

type Props = {
    status?: string | number
    message?: string
}

export default function ErrorMsg({ status, message }: Props) {
    /*COULD DISPLAY DIFFERENT MESSAGE DEPENDING ON STATUS*/

    return (
        <div className={'p-3 border border-danger error_msg'}>
            <p className="fw-bold text-center">Something went wrong!</p>
            {message && <p className="small text-center">{message}</p>}
        </div>
    )
}
