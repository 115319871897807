import { DropdownProps } from 'react-bootstrap/Dropdown'
import styled, { ThemeProps } from 'styled-components'
import { FOOTER_ICON_FONT_SIZE } from '../../../../providers/theme/GuslThemeProvider'
import Icon from '../../../common/maintain-table/bootstrap/Icon'

export const MenuItemsStyled = styled.div.attrs((props) => ({}))`
    margin-top: 20px;
`

interface MenuItemProps extends ThemeProps<any>, DropdownProps {
    idx: number
}

export const MenuItemStyled = styled.div.attrs((props: MenuItemProps) => ({
    id: 'mi_' + props.idx,
}))<MenuItemProps>`
    margin-left: 20px;
    margin-bottom: 15px;
    color: ${(props) => props.theme.navigation.sideMenu.color};
    padding: 5px;
    cursor: pointer;

    &:hover {
        color: ${(props) => props.theme.navigation.sideMenu.hoverColor};
        background: ${(props) => props.theme.navigation.sideMenu.hoverBgColor};
        border-radius: 5px;
    }
`

interface MenuGroupHeaderProps extends ThemeProps<any>, DropdownProps {
    $hasChildren: boolean
    idx: number
    isMobile?: boolean
}

export const MenuGroupHeaderStyled = styled.div.attrs((props: MenuGroupHeaderProps) => ({
    className: props.$hasChildren ? '' : '',
    id: 'mg_' + props.idx,
}))<MenuGroupHeaderProps>`
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 5px;
    cursor: pointer;
    padding-left: 5px;
`

interface MobileFooterButtonStyledProps extends ThemeProps<any> {
    active: boolean
}

export const MobileFooterButtonStyled = styled.div.attrs((props: MobileFooterButtonStyledProps) => ({}))<MobileFooterButtonStyledProps>`
  border-top: 1px solid ${(props) => props.theme.colors.dark[0]};
  border-right: 1px solid ${(props) => props.theme.colors.dark[0]};
  background-color: ${(props) => (props.active ? 'inherit' : props.theme.colors.dark[1])};

  margin: 0px auto;
  text-align: center;

  width: 100%;
  font-size: 12px;
  padding: 3px;
  
  img {
    width: 38px !important;
    vertical-align: middle;
  }

}
`

export const MobileFooterButtonLabelStyled = styled.div.attrs(
    (props: MobileFooterButtonStyledProps) => ({})
)<MobileFooterButtonStyledProps>`
    color: ${(props) => (props.active ? props.theme.navigation.hoverColor : props.theme.navigation.sideMenu.color)};
    font-size: 10px; // <-- 10 for nova, might impact alvar - may need a theme
    //padding-top: 10px; //  nova mobile footer
    padding-bottom: 5px;
    cursor: pointer;

    span {
        margin-left: 5px;
    }
`

export const MobileIconStyled = styled(Icon).attrs((props) => ({}))`
    font-size: ${(props) => FOOTER_ICON_FONT_SIZE}px;
    margin-left: 5px;
    line-height: 29px; // so icons line up with images nova
`
export const MobileFooterLogoStyled = styled.img.attrs((props) => ({}))`
    height: 30px; // so icons line up with images nova
`
