import React, { useEffect, useRef, useState } from 'react'
import { EnvironmentContext } from '../../providers/environment/EnvironmentContext'
import { SessionContext } from '../../providers/session/SessionContext'
import { SystemContext } from '../../providers/system/SystemContext'
import { CONTENT_START_POSITION } from '../../providers/theme/GuslThemeProvider'
import { RunOnceEffect } from '../../utils/Utils'
import { PolicyStyled } from '../common/legal-page/styled'
import LoadingSpinner from '../common/loading-spinner/LoadingSpinner'
import { TabContainerStyled, TabsContainerStyled, TabsStyledWrapperStyled } from '../common/tabbed-page/styled'
import { ActionCompletedBO, NotificationPosition, NotificationType } from '../types'
import {
    AcceptButtonStyled,
    AcceptButtonWrapperStyled,
    AcceptHeaderTextStyled,
    AcceptTextStyled,
    TandCTableContainerStyled,
    TAndCTableContentStyled,
    TermsContentStyled,
    TermsFooterStyled,
    TermsTabStyled,
} from './styled'
import { TermsContentDTO, TermsTabDTO } from './types'

const CURRENT_TERMS_URL = '/terms-and-conditions/current'

const NEXT_TERMS_URL = '/terms-and-conditions/next'

const ACCEPT_TERMS_URL = '/terms-and-conditions/accept'

export const TermsAndConditions = (): React.ReactElement => {
    const sessionContext = React.useContext(SessionContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const isMobile = environmentContext.isMobileDevice()
    const [termsContent, setTermsContent] = useState<TermsContentDTO | undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(true)
    const [activeTab, setActiveTab] = useState<TermsTabDTO | undefined>(undefined)
    const [refreshCounter, setRefreshCounter] = useState<number>(0)
    const systemContext = React.useContext(SystemContext)

    const contentElement = useRef(null)
    const [startPos, setStartPos] = useState<number>(CONTENT_START_POSITION)

    const footerElement = useRef(null)
    const [footerHeight, setFooterHeight] = useState<number>(30)

    const policyElement = useRef(null)
    const [policyStartPos, setPolicyStartPos] = useState<number>(CONTENT_START_POSITION)

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                if (footerElement) {
                    // @ts-ignore
                    const rect = footerElement?.current?.getBoundingClientRect()
                    if (rect) {
                        setFooterHeight(rect.height)
                    }
                }
                if (contentElement) {
                    // @ts-ignore
                    const rect = contentElement?.current?.getBoundingClientRect()
                    if (rect) {
                        setStartPos(rect.y)
                    }
                }
                if (policyElement) {
                    // @ts-ignore
                    const rect = policyElement?.current?.getBoundingClientRect()
                    if (rect) {
                        setPolicyStartPos(rect.y)
                    }
                }
            }, 100)
        })
    }, [contentElement, footerElement])

    const getTerms = (current: boolean) => {
        setLoading(true)
        sessionContext.post<any, TermsContentDTO>(NEXT_TERMS_URL, {}).then(
            (response) => {
                setLoading(false)
                setTermsContent(response.data)
                setActiveTab(response.data?.tabs[0])
            },
            (reason) => {
                setLoading(false)
            }
        )
    }

    RunOnceEffect(() => {
        getTerms(true)
    })

    const renderTabLabel = (tab: TermsTabDTO, idx: number, len: number): React.ReactElement => {
        const onTabClicked = () => {
            console.log('active =>', tab)
            setActiveTab(tab)
            setRefreshCounter(refreshCounter + 1)
        }

        const active: boolean = activeTab?.code === tab?.code

        return (
            <TermsTabStyled
                active={active}
                key={'tab_' + tab.code}
                isFirst={idx === 0}
                isLast={idx === len - 1}
                isMobile={isMobile}
                onClick={() => onTabClicked()}
            >
                {tab.label}
            </TermsTabStyled>
        )
    }

    const renderTabLabels = (): React.ReactElement => {
        // if (termsContent && termsContent.tabs.length <= 1) {
        //     return <></>
        // }
        return (
            <TabsStyledWrapperStyled isMobile={isMobile}>
                <TabsContainerStyled isMobile={isMobile}>
                    {termsContent && termsContent.tabs.map((tab, idx: number) => renderTabLabel(tab, idx, termsContent.tabs.length))}
                </TabsContainerStyled>
            </TabsStyledWrapperStyled>
        )
    }

    const renderInfo = (): React.ReactElement => {
        return <></>
    }

    const renderContents = (): React.ReactElement => {
        if (!activeTab) {
            return <></>
        }
        return (
            <TermsContentStyled key={'terms_content_' + refreshCounter} ref={policyElement}>
                <PolicyStyled id="policy" startPos={policyStartPos} footerHeight={footerHeight}>
                    <div dangerouslySetInnerHTML={{ __html: activeTab.content }} />
                </PolicyStyled>
            </TermsContentStyled>
        )
    }

    const onAccept = () => {
        sessionContext
            .post<any, ActionCompletedBO>(ACCEPT_TERMS_URL, {
                version: termsContent?.version,
            })
            .then(
                (response) => {
                    sessionContext
                        .renewSession()
                        .then(() => {
                            console.log('session - renewed')
                        })
                        .catch((err) => {
                            console.error('session not', err)
                        })
                    if (!response.data?.hideNotification) {
                        systemContext.toast({
                            type: NotificationType.SUCCESS,
                            position: NotificationPosition.BOTTOM_RIGHT,
                            noIcon: false,
                            noAutoClose: false,
                            message: response.data?.notificationMessage,
                            autoCloseDuration: 2000,
                        })
                    }
                },
                (reason) => {}
            )
    }

    const renderFooter = (): React.ReactElement => {
        return (
            <TermsFooterStyled ref={footerElement}>
                <AcceptHeaderTextStyled>{termsContent?.footerHeader || ''}</AcceptHeaderTextStyled>
                <AcceptTextStyled>{termsContent?.footerText || ''}</AcceptTextStyled>
                <AcceptButtonWrapperStyled>
                    <AcceptButtonStyled size={'xl'} tabIndex={3} type="submit" onClick={() => onAccept()}>
                        {termsContent?.footerButton || 'Accept'}
                    </AcceptButtonStyled>
                </AcceptButtonWrapperStyled>
            </TermsFooterStyled>
        )
    }

    const renderTabs = (): React.ReactElement => {
        return (
            <TandCTableContainerStyled ref={contentElement}>
                <TAndCTableContentStyled id="tandc_content" startPos={startPos} footerHeight={footerHeight}>
                    {renderTabLabels()}
                    <TabContainerStyled>{renderContents()}</TabContainerStyled>
                </TAndCTableContentStyled>
                {renderFooter()}
            </TandCTableContainerStyled>
        )
    }

    return <>{loading ? <LoadingSpinner /> : renderTabs()}</>
}
export { TermsAndConditions as default }
