import styled from 'styled-components'

export const UpArrowStyled = styled.i.attrs((props) => ({
    className: 'fa-solid fa-arrow-up',
}))`
    color: var(--positive);
`

export const DownArrowStyled = styled.i.attrs((props) => ({
    className: 'fa-solid fa-arrow-down',
}))`
    color: var(--negative);
`

export const LeftArrowStyled = styled.i.attrs((props) => ({
    className: 'fa-solid fa-caret-left',
}))`
    color: ${(props) => props.theme.table.columnColor};
    font-size: 14px;
`

export const RightArrowStyled = styled.i.attrs((props) => ({
    className: 'fa-solid fa-caret-right',
}))`
    color: ${(props) => props.theme.table.columnColor};
    font-size: 14px;
`

export const NoMovementWrapperStyled = styled.div.attrs((props) => ({}))``
