import { isMobile } from '../../../../utils/TypeCheckers'

export const DOUGHNUT_DEFAULT_OPTIONS = {
    maintainAspectRatio: false,
    cutout: '90%',
    spacing: 10,
    borderWidth: 1,
    borderRadius: 5,
    plugins: {
        // tooltip: {
        //        callbacks: {
        //            label: function (context: { dataset: { label: string; }; parsed: { y: number | bigint | null; }; }) {
        //
        //                console.log('context', context)
        //                let label = context.dataset.label || '';
        //
        //                if (label) {
        //                    label += ': ';
        //                }
        //                if (context.parsed.y !== null) {
        //                    label += ' ' + context.parsed.y;
        //                }
        //                return label;
        //            }
        //        }
        //    },
        legend: {
            display: true,
            position: isMobile ? ('bottom' as const) : ('left' as const),
            fontSize: 5,
            labels: {
                padding: isMobile ? 5 : 15,
                boxWidth: 8,
                boxHeight: 8,
                usePointStyle: true,
                rotation: 30,
                pointStyle: 'rectRounded',
                font: { size: 10 },

                filter: (
                    legendItem: { text: string },
                    data: {
                        labels: string | string[]
                        datasets: { labels: { [x: string]: any } }[]
                    }
                ) => {
                    //console.log('doughnut options', {legendItem, data})
                    //First, retrieve the data corresponding to that label
                    const label = legendItem.text
                    const lIndex = data.labels.indexOf(label)
                    const newText = data.datasets[0].labels[lIndex]
                    // @ts-ignore
                    const qtd = data.datasets[0].data[lIndex]
                    // Second, mutate the legendItem to include the new text
                    legendItem.text = `${newText}:(${qtd})`
                    //Third, the filter method expects a bool, so return true to show the modified legendItem in the legend
                    return true
                },
            },
        },
    },
}
