import React, { ChangeEventHandler, useState } from 'react'
import { FormMode, PermissionDTO, PermissionGroupDTO } from '../../types'
import Checkbox from '../maintain-table/bootstrap/Checkbox'
import { PermissionGroupStyled, PermissionStyled } from './styled'

type PermissionBlockProps = {
    permissionGroup: PermissionGroupDTO
    onChange: ChangeEventHandler<HTMLInputElement>
    disabledField: boolean
    formMode: FormMode
    MUST: string
}
export default function PermissionBlock({ permissionGroup, onChange, disabledField, formMode, MUST }: PermissionBlockProps) {
    const [className] = useState('PermissionBlock-' + new Date().getTime())

    function isRequiredChecked() {
        return permissionGroup.permissions.filter((perm) => perm.permission === permissionGroup.name + MUST).map((_perm) => _perm.value)[0]
    }

    const isRequiredPresent = typeof isRequiredChecked() !== 'undefined'

    function isDisabled(permission: { permission: string }) {
        return formMode === FormMode.VIEW
            ? disabledField
            : permission.permission.endsWith(MUST)
            ? undefined
            : !isRequiredChecked() && !permission.permission.endsWith(MUST)
    }

    return (
        <>
            <div key={permissionGroup.name} className={'col mb-1'}>
                <div className="card h-100 widget_blue p-0 border-muted ">
                    <div className="card-header border-bottom px-0">
                        <PermissionGroupStyled>{permissionGroup.name}</PermissionGroupStyled>
                    </div>
                    <div className="card-body">
                        {permissionGroup.permissions.map((permission: PermissionDTO, idx: React.Key | null | undefined) => (
                            <div key={permission.permission + '_' + idx} className={'row g-0'}>
                                <div className="col-1">
                                    <Checkbox
                                        inputId={'permission_' + permission.permission + '-' + idx}
                                        name={'permission_' + permission.permission}
                                        value={isRequiredPresent ? (isRequiredChecked() ? permission.value : false) : permission.value}
                                        checked={isRequiredPresent ? (isRequiredChecked() ? permission.value : false) : permission.value}
                                        disabled={isRequiredPresent ? isDisabled(permission) : disabledField}
                                        onChange={(e) => {
                                            // @ts-ignore
                                            onChange(permissionGroup, permission, e.target.checked)
                                        }}
                                    />
                                </div>
                                <div className="col-11">
                                    <PermissionStyled isRequired={isRequiredChecked() && permission.value}>
                                        {permission.permission.replace(permissionGroup.name + '_', '')}
                                    </PermissionStyled>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
