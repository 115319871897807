import DatePicker from 'react-datepicker'
import styled, { ThemeProps } from 'styled-components'

interface DateStyledProps extends ThemeProps<any> {
    noValue: boolean
    submitted: boolean
    inLine?: boolean
}

export const DateStyled = styled.div.attrs((props: DateStyledProps) => ({
    className:
        'form-control shadow mb-1 bg-transparent rounded-0 border-top-0 border-start-0 border-end-0 text-light ' +
        (props.submitted && props.noValue ? 'yellow' : ''),
}))<DateStyledProps>`
    color: ${(props) => props.theme.table.columnColor} !important;
    font-size: ${(props) => props.theme.table.columnFontSize};
`

export const DatePickerStyled = styled(DatePicker).attrs((props: DateStyledProps) => ({
    className:
        'form-control shadow mb-1 bg-transparent rounded-0 border-top-0 border-start-0 border-end-0 text-light ' +
        (props.submitted && props.noValue ? 'yellow' : ''),
}))<DateStyledProps>`
    color: ${(props) => (props.inLine ? '#000' : props.theme.table.columnColor)} !important;
    font-size: ${(props) => props.theme.table.columnFontSize};
`

export const DateFloatingFormStyled = styled.div.attrs((props) => ({
    // className: "form-floating"
}))``
