// @ts-nocheck
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CardPanelBO } from '../../../../common/card/types'

export interface PanelOpenState {
    opened: boolean
    row: any | undefined
    cardPanel: CardPanelBO | undefined
    actionsOpened: boolean
}

const initialState: PanelOpenState = {
    opened: false,
    cardPanel: undefined,
    actionsOpened: false,
    row: undefined,
}

export interface ActionOpenPayloadAction {}

export interface ActionClosedPayloadAction {}

export interface PanelOpenPayloadAction {
    cardPanel: CardPanelBO | undefined
    row: any | undefined
}

export interface PanelClosePayloadAction {}

export interface PanelOpenCleanUpPayload {}

const updateOpenPanelDetails = (entry: PanelOpenState, cardPanel: CardPanelBO, row: any | undefined) => {
    entry.opened = true
    entry.row = row
    entry.cardPanel = cardPanel
}

const removeOpenPanelDetails = (entry: PanelOpenState) => {
    entry.opened = false
    entry.row = undefined
    entry.cardPanel = undefined
}

export const slidingPanelOpenSlice = createSlice({
    name: 'slidingPanelOpenSlice',
    initialState,
    reducers: {
        openPanel(state, action: PayloadAction<PanelOpenPayloadAction>) {
            let entry: PanelOpenState = state
            // do not want action clicks to open panel
            if (!entry.actionsOpened) {
                updateOpenPanelDetails(entry, action.payload.cardPanel, action.payload.row)
            }
        },
        closePanel(state, action: PayloadAction<PanelClosePayloadAction>) {
            let entry: PanelOpenState = state
            removeOpenPanelDetails(entry)
        },
        cleanupPanel(state, action: PayloadAction<PanelOpenCleanUpPayload>) {
            // delete state[action.payload.rowId]
        },
        actionOpenPanel(state, action: PayloadAction<ActionOpenPayloadAction>) {
            let entry: PanelOpenState = state
            entry.actionsOpened = true
            console.log('setting (open) entry.actionsOpened', entry.actionsOpened)
        },
        actionClosePanel(state, action: PayloadAction<ActionOpenPayloadAction>) {
            let entry: PanelOpenState = state
            entry.actionsOpened = false
            console.log('setting (close) entry.actionsOpened', entry.actionsOpened)
        },
    },
})

export const { openPanel, closePanel, cleanupPanel, actionOpenPanel, actionClosePanel } = slidingPanelOpenSlice.actions

export default slidingPanelOpenSlice.reducer
