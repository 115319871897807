import styled from 'styled-components'

export const HeaderStyled = styled.header.attrs(() => ({
    id: 'olivetree_landing_page_hdr',
}))`
     {
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.37);
        background: linear-gradient(90deg, #0a4232, #579b88);
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        z-index: 1000;
    }
`
export const NavStyled = styled.nav.attrs(() => ({
    id: 'olivetree_nav',
}))`
    position: sticky;
    top: 0;
    z-index: 2;
    display: flex;
    height: 15vh;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 10rem;
`

export const SectionStyled = styled.section.attrs(() => ({
    id: 'section',
}))`
    height: 100vh;
    overflow: hidden;
`

export const LoginWrapperStyled = styled.div.attrs(() => ({
    id: 'login_wrapper',
}))`
    .login-wrapper {
        position: absolute;
        top: 30% !important;
        left: 40% !important;
        width: 400px;
        background-color: ${(props) => props?.theme?.login?.innerBackground || '#e5e7eb'};
        border: 5px;
        box-shadow: rgba(0, 0, 0, 0.75) 2px 4px 20px 0px;
        border-radius: 10px;
        padding: 20px;
    }
`

export const BackgroundImageStyled = styled.img.attrs(() => ({
    id: 'background_img',
}))`
    object-fit: cover;
    padding: 0;
`

export const BackgroundImageAsDivStyled = styled.div.attrs(() => ({
    id: 'background_img',
}))`
    background-size: cover;
    background-image: url('/assets/images/cover_london.webp');
`

export const LogoStyled = styled.img.attrs(() => ({
    id: 'logo_img',
}))`
    aspect-ratio: auto;
    width: 15vw;
    max-width: 100%;
    height: auto;
    display: block;
    vertical-align: middle;
`

export const MenuContainerStyled = styled.div.attrs(() => ({
    id: 'menu_container',
}))`
    display: flex;
    height: 100%;
    align-items: center;
    gap: 4rem;
`
export const HeaderMenuItemStyled = styled.a.attrs(() => ({
    id: 'hdr_menu',
}))`
    font-family: 'Work Sans', sans-serif;
    height: 4vh;
    padding-bottom: 0.75rem;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    --tw-text-opacity: 1;
    color: rgb(255 255 255);
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
        -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
        backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
        backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));

    &:hover {
        border-bottom: solid 4px rgb(255 255 255);
        color: rgb(255 255 255);
    }
`

export const LogoStyled2 = styled.img.attrs(() => ({
    id: 'img',
}))``
