import 'antd/dist/antd.css'
import React, { useEffect, useState } from 'react'
import { Cron, CronError } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'
import { useAppSelector } from '../../../app/hooks'
import { log } from '../../../services/LogService'
import { getSettings } from '../../../services/ValidationService'
import { getStyle } from '../../../utils/CssUtils'
import { assignReferences, noop, RunOnceEffect } from '../../../utils/Utils'
import { FieldProperties, FormMode } from '../../types'
import { ElementWithTooltip } from '../element-with/element-with-tooltip/ElementWithTooltip'
import FieldLabel from '../field-label/FieldLabel'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { FloatingFormStyled } from '../text/styled'
import './custom.css'
import { ENGLISH_VARIANT_LOCALE } from './locales'
import { CronFieldStyled, CronFieldWrapperStyled, CronInputStyled, CronSeparatorStyled, InfoIconStyled, TextFieldStyled } from './styled'
import { useCronReducer } from './utils'

export const CronField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = useState('CronField-' + new Date().getTime())

    // cron ↓
    const defaultValue = properties?.data || '* * * * *'
    const [values, dispatchValues] = useCronReducer(defaultValue)
    const [error, onError] = useState<CronError>()
    const defaultShortcuts: FieldProperties['shortcuts'] = ['@yearly', '@annually', '@monthly', '@weekly', '@daily', '@midnight', '@hourly']
    const defaultPeriods: FieldProperties['allowedPeriods'] = ['year', 'month', 'week', 'day', 'hour', 'minute']
    const defaultDropdowns: FieldProperties['allowedDropdowns'] = ['period', 'months', 'month-days', 'week-days', 'hours', 'minutes']
    const shortcuts: FieldProperties['shortcuts'] = properties?.shortcuts || defaultShortcuts
    const allowedPeriods: FieldProperties['allowedPeriods'] = properties?.allowedPeriods || defaultPeriods
    const allowedDropdowns: FieldProperties['allowedDropdowns'] = properties?.allowedDropdowns || defaultDropdowns

    /* orig ↓*/
    const [formMode, setFormMode] = useState(properties.formMode)
    // const [formValue, setFormValue] = useState<string>(properties?.data || values.cronValue);
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) || properties.data || values.cronValue)
    const [submitted, setSubmitted] = useState(false)

    log.info(className, 'MSG001', { formValue })

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setFormValue(properties?.data || '')
    }

    useEffect(() => {
        setFormValue(values.cronValue)
        properties.onChange(properties.fieldConfig.name, values.cronValue)
    }, [values.cronValue])

    const doValidation = (fieldValue: any): boolean => {
        setSubmitted(true)
        return typeof error === 'undefined' && fieldValue !== ''
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.fieldConfig.tableCss, '')

        return (
            <TextFieldStyled textAlign={properties.fieldConfig.align}>
                {properties.isNumber && (
                    <div className="text-right" style={style}>
                        {formValue}
                    </div>
                )}
                {!properties.isNumber && (
                    <span className="" style={style}>
                        {formValue}
                    </span>
                )}
            </TextFieldStyled>
        )
    }

    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        function onChangeHandler(inputValue: string) {
            dispatchValues({
                type: 'set_input_value',
                value: inputValue,
            })
        }

        function setValueHandler() {
            dispatchValues({
                type: 'set_cron_value',
                value: values.inputValue,
            })
        }

        return (
            <CronFieldWrapperStyled>
                {!hideField && (
                    <CronFieldStyled className="row g-0">
                        <div className="col-11">
                            <FloatingFormStyled>
                                <CronInputStyled
                                    /*orig ↓*/
                                    id={properties?.fieldConfig?.name || 'def'}
                                    disabled={disableField}
                                    readOnly={disableField}
                                    required={required}
                                    autoFocus={properties.isFirstField}
                                    /*cron ↓ */
                                    value={values.inputValue}
                                    onChange={(event) => {
                                        onChangeHandler(event.target.value)
                                    }}
                                    onBlur={setValueHandler}
                                    onPressEnter={setValueHandler}
                                    onMouseLeave={setValueHandler}
                                />
                                <FieldLabel properties={properties} />
                            </FloatingFormStyled>
                        </div>
                        <div className="col-1 d-flex justify-content-center align-items-center">
                            <CronSeparatorStyled />
                            <ElementWithTooltip
                                element={
                                    <span role={'button'}>
                                        <InfoIconStyled className={'fa-solid fa-info-circle ms-3'} />
                                    </span>
                                }
                                placement={'left-end'}
                                tooltip={
                                    <div className={'text-start'}>
                                        <div>
                                            Type cron expression or type a shortcut:
                                            {shortcuts.map((shortcut, idx) => (
                                                <span key={idx}>
                                                    <span className={'ms-1 text-warning'}>{shortcut}</span>
                                                    {idx + 1 !== shortcut.length ? ', ' : ''}
                                                </span>
                                            ))}{' '}
                                            .
                                        </div>
                                        <hr className={'my-1'} />
                                        <div>↓ Or use the FORM</div>
                                    </div>
                                }
                            />
                        </div>
                    </CronFieldStyled>
                )}
                {error && <p className={'text-warning px-2 small'}>{error.description} </p>}

                <Cron
                    value={values.cronValue}
                    setValue={(newValue: string) => {
                        dispatchValues({
                            type: 'set_values',
                            value: newValue,
                        })
                    }}
                    onError={onError}
                    displayError={true}
                    className={'cron-component'}
                    clearButtonAction={'empty'}
                    allowedPeriods={allowedPeriods}
                    allowedDropdowns={allowedDropdowns}
                    shortcuts={shortcuts}
                    locale={ENGLISH_VARIANT_LOCALE}
                    // locale={FRENCH_LOCALE}
                />
            </CronFieldWrapperStyled>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
