import React, { useContext, useEffect, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { BlastContext } from '../../../providers/blast/BlastContext'
import { SessionContext } from '../../../providers/session/SessionContext'
import { getSettings } from '../../../services/ValidationService'
import { assignReferences, noop, RunOnceEffect, unSubscribe } from '../../../utils/Utils'
import { FieldProperties, FormMode } from '../../types'
import { watchBlastDeltas } from '../../ui/FieldUtils'
import FieldLabel from '../field-label/FieldLabel'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled } from '../text/styled'
import { FavouriteSvgStyled, FavouriteWrapperStyled } from './styled'

export const FavouriteField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const sessionContext = React.useContext(SessionContext)
    const blastContext = useContext(BlastContext)

    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    const [formMode, setFormMode] = useState(properties.formMode)
    // const [formValue, setFormValue] = useState<boolean>(false);
    const [formValue, setFormValue] = useState<boolean>(_guslFormState?.getFieldValue(properties) || properties.data || '')
    const [refreshCounter, setRefreshCounter] = useState<number>(1)

    useEffect(() => {
        const [loaderSubscription, blastDeltaSubscription, valueObservable] = watchBlastDeltas(
            sessionContext,
            blastContext,
            properties,
            formMode
        )
        const valueSubscription = valueObservable.subscribe((newValue: any) => {
            if (newValue) {
                setFormValue(newValue)
                setRefreshCounter(refreshCounter + 1)
            }
        })
        return () => {
            unSubscribe(loaderSubscription)
            unSubscribe(blastDeltaSubscription)
            unSubscribe(valueSubscription)
        }
    }, [properties])

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setFormValue(properties?.data || false)
    }

    useEffect(() => {
        setFormValue(properties?.data || false)
    }, [properties])

    const doValidation = (fieldValue: any): boolean => {
        return true
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        const onFavouriteClick = () => {
            if (formMode !== FormMode.EDIT) {
                return
            }
            setFormValue(!formValue)
            properties.onChange(properties.fieldConfig.name, !formValue)
        }
        return <FavouriteWrapperStyled onClick={() => onFavouriteClick()}>{renderFavouriteIcon()}</FavouriteWrapperStyled>
    }

    const renderFavouriteIcon = (): React.ReactElement => {
        const stroke = formValue ? 'var(--negative)' : 'var(--column-color)'
        const fill = formValue ? 'var(--positive)' : 'var(--column-color)'
        const strokeWidth = formValue ? 50 : 20
        return (
            <FavouriteSvgStyled version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg">
                <g stroke={stroke} strokeWidth={strokeWidth} fill={fill}>
                    <path d="m474.85 5.2969c-47.727 0-92.156 20.895-124.52 57.961-32.367-37.066-76.781-57.961-124.52-57.961-94.844 0-172.02 83.895-172.02 187 0 180.88 277.14 350.86 288.93 358.01l7.2539 4.3984 7.4062-4.0898c11.82-6.5273 289.46-162.05 289.46-358.32 0-103.11-77.172-187-172.02-187zm-124.19 510.78c-43.406-27.875-265.64-177.76-265.64-323.78 0-84.402 63.168-153.07 140.8-153.07 44.332 0 85.188 22.027 112.09 60.445l12.414 17.738 12.422-17.73c26.898-38.418 67.754-60.445 112.09-60.445 77.641 0 140.8 68.672 140.8 153.07-0.007812 158.99-221.38 297.95-265 323.77z" />
                </g>
            </FavouriteSvgStyled>
        )
    }

    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        return (
            <>
                {!hideField && (
                    <FloatingFormStyled>
                        <FieldContentWrapperStyled>
                            <FieldContentStyled>{renderTableView()}</FieldContentStyled>
                        </FieldContentWrapperStyled>
                        <FieldLabel properties={properties} />
                    </FloatingFormStyled>
                )}
            </>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
