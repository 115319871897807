import React, { useContext, useState } from 'react'
import { SessionContext } from '../../providers/session/SessionContext'
import { GuslUser } from '../../providers/session/types'
import { RunOnceEffect } from '../../utils/Utils'

export interface GetLoggedInUserDO {}

export default function GetLoggedInUser() {
    const [className] = useState('GetLoggedInUser-' + new Date().getTime())
    const sessionContext = useContext(SessionContext)
    const [user, setUser] = useState<GuslUser>({ id: '', username: '' })

    RunOnceEffect(() => {
        sessionContext.watchLogin().subscribe((user: GuslUser | undefined | null) => {
            if (user) {
                setUser(user)
            }
        })
    })

    return user
}
