import React from 'react'
import { DateRangeUnitSelectorStyled } from '../../styled/date-range-selector/styled'
import { DateRangeUnitStyled } from '../../styled/date-range/styled'
import { DateRangeUnitProps } from '../types'

export default function DateRangeUnit({ active, onClick, label, inSelector }: DateRangeUnitProps) {
    return !inSelector ? (
        <DateRangeUnitStyled active={active} onClick={onClick}>
            {label}
        </DateRangeUnitStyled>
    ) : (
        <DateRangeUnitSelectorStyled active={active} onClick={onClick}>
            {label}
        </DateRangeUnitSelectorStyled>
    )
}
