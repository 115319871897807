import { Component, ComponentModelDefinition, Editor } from 'grapesjs'
import { sessionService } from '../../../../../../providers/session/SessionService'
import { TimetableContent } from './TimetableContent'

const category: string = 'Alvar'
const componentName: string = 'TimetableContentGjsComponent'
const label: string = 'Timetable'

export const TimetableContentGjsComponent = (editor: Editor, options = {}) => {
    const renderComponent = (cmp: ComponentModelDefinition & Component) => {
        let content: string = cmp.get('htmlContent')
        console.log('content', content)
        // content = '<div>' + content + '</div>';
        //
        // console.log('this.attributes.type',cmp.getAttributes().type)
        // if (cmp.getAttributes().type === 'style1') {
        //     content = content + '<style>' + STYLES + '</style>'
        // } else {
        //     content = content + '<style>' + STYLES_1 + '</style>'
        // }
        // const component: Component | Component [] = editor.addComponents(content);
        cmp.setAttributes({ content: content })
        // cmp.replaceWith(component)
        // return component
    }

    editor.Components.addType(componentName, {
        extend: 'base-react-component',
        model: {
            defaults: {
                component: TimetableContent,
                url: '/article/html-content-time-table',
                htmlContent: '',
                stylable: false,
                resizable: true,
                editable: false,
                draggable: true,
                droppable: true,
                attributes: {
                    mlsid: 'Default MLSID',
                    editable: false,
                    type: 'style1',
                    content: '',
                },
                traits: [
                    // {
                    //     type: 'number',
                    //     label: 'MLS ID',
                    //     name: 'mlsid'
                    // },
                    // {
                    //     type: 'checkbox',
                    //     label: 'All',
                    //     name: 'all'
                    // },
                    {
                        type: 'select', // Type of the trait
                        label: 'Type', // The label you will see in Settings
                        name: 'type', // The name of the attribute/property to use on component
                        options: [
                            { id: 'style1', name: 'style1' },
                            { id: 'style2', name: 'style2' },
                        ],
                    },
                ],
            },
            init() {
                const request: any = {
                    type: 'hi', // props.type
                }
                sessionService
                    .post<any, any>(this.attributes.url, request)
                    .then((response) => {
                        console.log('response', response?.data?.content)
                        this.set('htmlContent', response?.data?.content || '')
                    })
                    .catch((error: any) => {
                        console.log('error', error)
                        this.set('htmlContent', '<h3>we got an error</h3>')
                    })
                this.listenTo(this, 'change:htmlContent', this.handlePropChange)
                this.listenTo(this, 'change:attributes:type', this.handleTypeChange)
            },

            handlePropChange() {
                renderComponent(this)
            },
            handleTypeChange() {
                renderComponent(this)
            },
            updated(property, value, prevValue) {
                console.log('HtmlContent updated', 'property', property, 'value', value, 'prevValue', prevValue)
            },
            removed() {
                console.log('HtmlContent removed')
            },
        },
        isComponent: (el) => {
            return el.tagName === componentName.toUpperCase()
        },
    })

    editor.BlockManager.add(componentName, {
        media: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 15 15" xml:space="preserve"><g>
                   <text x="4px" y="12px" textanchor="middle" fill="#FFFFFF" strokewidth="5px" fontsize="100px" fontweight="bold">${componentName
                       .substring(0, 1)
                       .toUpperCase()}</text></g></svg>`,
        label: `<div class='gjs-fonts gjs-f-b1'>${label}</div>`,
        category: category,
        content: `<TimetableContent data-gjs-type="${componentName}"></TimetableContent>`,
    })
}

const STYLES: string = `
h1 {
    font-size: 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
    margin-bottom: 15px;
}

.g-table {
    table-layout: fixed;
    background: -webkit-linear-gradient(left, #25c481, #25b7c4);
    background: linear-gradient(to right, #25c481, #25b7c4);
    font-family: 'Roboto', sans-serif;
}

.g-thead {
    background-color: rgba(255, 255, 255, 0.3);
}

.g-tbbody {
    height: 300px;
    overflow-x: auto;
    margin-top: 0px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.g-th {
    padding: 20px 15px;
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
}

.g-td {
    padding: 15px;
    text-align: left;
    vertical-align: middle;
    font-weight: 300;
    font-size: 12px;
    color: #fff;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

`
const STYLES_1: string = `

.g-table {
  margin-bottom: 1.5em;
  border-spacing: 0;
  
  @media (min-width: $bp-bart) {
    font-size: .9em; 
  }
  
  @media (min-width: $bp-marge) {
    font-size: 1em; 
  }
}
  .g-thead {
    position: absolute;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    padding: 0;
    border: 0;
    height: 1px; 
    width: 1px; 
    overflow: hidden;
    
    @media (min-width: $bp-bart) {
      // Unhide <thead> on wide viewports
      position: relative;
      clip: auto;
      height: auto;
      width: auto;
      overflow: auto;
    }
}    
.g-th {
      background-color: rgba(38,137,13,1);
      border: 1px solid rgba(134,188,37,1);
      font-weight: normal;
      text-align: center;
      color: white;
      
      &:first-of-type {
        text-align: left; 
      }
}
  
  // Set these items to display: block for narrow viewports
  .g-tbody,
  .g-tr,
  .g-th,
  .g-td {
    display: block;
    padding: 0;
    text-align: left;
    white-space: normal;
  }
  
  .g-tr {   
    @media (min-width: $bp-bart) {
      // Undo display: block 
      display: table-row; 
    }
  }
  
  .g-th,
  .g-td {
    padding: .5em;
    vertical-align: middle;
    
    @media (min-width: $bp-lisa) {
      padding: .75em .5em; 
    }
    
    @media (min-width: $bp-bart) {
      // Undo display: block 
      display: table-cell;
      padding: .5em;
    }
    
    @media (min-width: $bp-marge) {
      padding: .75em .5em; 
    }
    
    @media (min-width: $bp-homer) {
      padding: .75em; 
    }
  }
  
  .g-tfoot {
    font-size: .8em;
    font-style: italic;
    
    @media (min-width: $bp-marge) {
      font-size: .9em;
    }
  }
  
  .g-tbody {
    @media (min-width: $bp-bart) {
      // Undo display: block 
      display: table-row-group; 
    }
}    
    .g-tr {
      margin-bottom: 1em;
      
      @media (min-width: $bp-bart) {
        // Undo display: block 
        display: table-row;
        border-width: 1px;
      }
      
      &:last-of-type {
        margin-bottom: 0; 
      }
      
      &:nth-of-type(even) {
        @media (min-width: $bp-bart) {
          background-color: rgba(0,0,0,.12);
        }
      }
    }
    
    .g-th[scope="row"] {
      background-color: rgba(38,137,13,1);
      color: white;
      
      @media (min-width: $bp-lisa) {
        border-left: 1px solid rgba(134,188,37,1);
        border-bottom: 1px solid rgba(134,188,37,1);
      }
      
      @media (min-width: $bp-bart) {
        background-color: transparent;
        color: rgba(0,0,0.87);
        text-align: left;
      }
    }
    
    .g-td {
      text-align: right;
      
      @media (min-width: $bp-bart) {
        border-left: 1px solid rgba(134,188,37,1);
        border-bottom: 1px solid rgba(134,188,37,1);
        text-align: center; 
      }
      
      &:last-of-type {
        @media (min-width: $bp-bart) {
          border-right: 1px solid rgba(134,188,37,1);
        } 
      }
    }
    
    .g-td[data-type=currency] {
      text-align: right; 
    }
    
    .g-td[data-title]:before {
      content: attr(data-title);
      float: left;
      font-size: .8em;
      color: rgba(0,0,0,.54);
      
      @media (min-width: $bp-lisa) {
        font-size: .9em; 
      }
      
      @media (min-width: $bp-bart) {
        // Don’t show data-title labels 
        content: none; 
      }
    } 
`
