export const TRANSLATIONS_EN: any = {
    welcome: 'Welcome to the tutorial',
    validation: {
        min: 'Minimum {{longValue}} characters required',
        max: 'Maximum {{longValue}} characters',
        required: 'Field is mandatory',
    },
    table: {
        pagination: {
            showing: ' {{from}} to {{to}} of {{total}}',
            rowsPerPage: 'Rows per page:',
            select: 'Select ...',
        },
    },
    form: {
        permissions: 'Heh Permissions',
    },
    login: {
        username: {
            label: 'Username',
            placeholder: 'Username',
            error: 'Username is required.',
        },
        password: {
            label: 'Passsword',
            placeholder: 'Password',
            error: 'Password is required.',
        },
        totp: {
            label: 'Google Authenticator Code',
            placeholder: 'Google Authenticator Code',
            error: 'Code is required.',
        },
        submit: 'Login',
    },
}
