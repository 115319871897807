import React, { lazy } from 'react'

import { Navigate, RouteObject } from 'react-router-dom'
import DealView, { DEAL_PAGE } from '../components/bespoke/otto-events/deal-view/DealView'
import OttoEvents, { OTTO_HOME } from '../components/bespoke/otto-events/OttoEvents'
// import {ArticleBuilder} from '../components/common/grapejs/article-builder/ArticleBuilder';
// import {LegalPage} from '../components/common/legal-page/LegalPage';
// import {GuslScheduler} from '../components/common/scheduler/GuslScheduler';
// import {SinglePage} from '../components/common/single-page/SinglePage';
// import {TermsAndConditions} from '../components/terms-and-conditions/TermsAndConditions';
// import {PageDirector} from '../components/ui/page-director/PageDirector';
import { IMenu, IMenuDTO, LayoutType } from '../components/types'
import JupiterAuthLayout from '../layouts/jupiter-layout/JupiterAuthLayout'
import JupiterLayout from '../layouts/jupiter-layout/JupiterLayout'
// import AlvarFeAuthLayout from '../layouts/alvar-fe-layout/AlvarFeAuthLayout';
// import AlvarFeLayout from '../layouts/alvar-fe-layout/AlvarFeLayout';
// import ApolloFeAuthLayout from '../layouts/apollo-layout/ApolloFeAuthLayout';
// import ApolloFeLayout from '../layouts/apollo-layout/ApolloFeLayout';
// import MercuryFeAuthLayout from '../layouts/mercury-layout/MercuryFeAuthLayout';
// import MercuryFeLayout from '../layouts/mercury-layout/MercuryFeLayout';
// import MobileAuthLayout from '../layouts/mobile-layout/MobileAuthLayout';
// import MobileLayout from '../layouts/mobile-layout/MobileLayout';
// import SupernovaFeAuthLayout from '../layouts/supernova-fe/SupernovaFeAuthLayout';
// import SupernovaFeLayout from '../layouts/supernova-fe/SupernovaFeLayout';
import { environmentService } from '../providers/environment/EnvironmentService'
import { IMenuService } from '../providers/system/types'
import { log } from '../services/LogService'
import { isDefined } from '../utils/TypeCheckers'
import { safeStream } from '../utils/Utils'
import { BESPOKE_PAGES_MAP, PRE_AUTH_PAGES_MAP, UI_CONSTANTS } from './ComponentMap'

const MobileAuthLayout = lazy(() => import('../layouts/mobile-layout/MobileAuthLayout'))
const MobileLayout = lazy(() => import('../layouts/mobile-layout/MobileLayout'))
const AlvarFeAuthLayout = lazy(() => import('../layouts/alvar-fe-layout/AlvarFeAuthLayout'))
const AlvarFeLayout = lazy(() => import('../layouts/alvar-fe-layout/AlvarFeLayout'))
const ApolloFeAuthLayout = lazy(() => import('../layouts/apollo-layout/ApolloFeAuthLayout'))
const ApolloFeLayout = lazy(() => import('../layouts/apollo-layout/ApolloFeLayout'))
const MercuryFeAuthLayout = lazy(() => import('../layouts/mercury-layout/MercuryFeAuthLayout'))
const MercuryFeLayout = lazy(() => import('../layouts/mercury-layout/MercuryFeLayout'))
const SupernovaFeAuthLayout = lazy(() => import('../layouts/supernova-fe/SupernovaFeAuthLayout'))
const SupernovaFeLayout = lazy(() => import('../layouts/supernova-fe/SupernovaFeLayout'))

const TabbedPage = lazy(() => import('../components/common/tabbed-page/TabbedPage'))
const ArticleBuilder = lazy(() => import('../components/common/grapejs/article-builder/ArticleBuilder'))
const LegalPage = lazy(() => import('../components/common/legal-page/LegalPage'))
const GuslScheduler = lazy(() => import('../components/common/scheduler/GuslScheduler'))
const SinglePage = lazy(() => import('../components/common/single-page/SinglePage'))
// const Dashboard = lazy(() => import( '../components/dashboard/Dashboard'));
const TermsAndConditions = lazy(() => import('../components/terms-and-conditions/TermsAndConditions'))
const PageDirector = lazy(() => import('../components/ui/page-director/PageDirector'))

class MenuService implements IMenuService {
    private className = 'MenuService-' + new Date().getTime()

    public createPreAuthenticatedRoutes = (): RouteObject[] => {
        const authRoutes = this.getPreAuthenticatedRoutes()
        log.info(this.className, 'MSG001', 'pre-auth-routes', authRoutes)
        return [authRoutes]
    }

    public getAuthenticationLayout = (): React.ReactNode => {
        const layout: LayoutType = environmentService.getEnvironment()?.layout || LayoutType.SUPERNOVA_FE
        switch (layout) {
            case LayoutType.APOLLO:
                return <ApolloFeAuthLayout />
            case LayoutType.MERCURY:
                return <MercuryFeAuthLayout />
            case LayoutType.SUPERNOVA_FE:
                return <SupernovaFeAuthLayout />
            case LayoutType.ALVAR_FE:
                return <AlvarFeAuthLayout />
            case LayoutType.MOBILE:
                return <MobileAuthLayout />
            case LayoutType.JUPITER:
                return <JupiterAuthLayout />
            default:
                return <SupernovaFeAuthLayout />
        }
    }

    private getPreAuthenticatedRoutes = (): RouteObject => {
        const preAuthRoutes: RouteObject = {
            path: '/',
            element: this.getAuthenticationLayout(),
            children: [],
        }

        Object.keys(PRE_AUTH_PAGES_MAP).forEach((key: string) => {
            PRE_AUTH_PAGES_MAP[key]?.forEach((route) => preAuthRoutes?.children?.push(route))
        })

        preAuthRoutes?.children?.push({
            path: '*',
            element: <Navigate to={UI_CONSTANTS.LOGIN} />,
        })
        return preAuthRoutes
    }

    public getLayout = (): React.ReactNode => {
        const layout = environmentService.getEnvironment()?.layout || 'vision-x'
        switch (layout.toLowerCase()) {
            case LayoutType.APOLLO:
                return <ApolloFeLayout />
            case LayoutType.MERCURY:
                return <MercuryFeLayout />
            case LayoutType.SUPERNOVA_FE:
                return <SupernovaFeLayout />
            case LayoutType.ALVAR_FE:
                return <AlvarFeLayout />
            case LayoutType.MOBILE:
                return <MobileLayout />
            case LayoutType.JUPITER:
                return <JupiterLayout />
            default:
                return <SupernovaFeLayout />
        }
    }

    public createPostAuthenticatedRoutes = (menuGroups: IMenuDTO[]): RouteObject[] => {
        log.info(this.className, 'MSG002', 'all-routes for menuGroups:', menuGroups)
        console.log('Environment', environmentService.getEnvironment())

        const mainPrefix = '/'

        const mainRoutes: RouteObject = {
            path: mainPrefix,
            element: this.getLayout(),
            children: [],
        }
        const bespokeRoutes: RouteObject = {
            path: '/bespoke',
            element: this.getLayout(),
            children: [],
        }

        safeStream(environmentService.getEnvironment()?.allowedBespokePages?.pages).forEach((bespokePage) => {
            if (OTTO_HOME === mainPrefix + bespokePage) {
                mainRoutes.children?.push({
                    path: mainPrefix + bespokePage + '/:tab?/:dealId?/:articleId?',
                    element: <OttoEvents />,
                })
                mainRoutes.children?.push({
                    path: DEAL_PAGE + '/:dealId?/:articleId?',
                    element: <DealView />,
                })
            }
        })

        mainRoutes.children?.push({
            path: mainPrefix + '/legal/:code',
            element: <LegalPage />,
        })

        mainRoutes.children?.push({
            path: mainPrefix + '/:code',
            element: <PageDirector />,
        })

        mainRoutes.children?.push({
            path: mainPrefix + '/:code/:id',
            element: <SinglePage />,
        })

        mainRoutes.children?.push({
            path: mainPrefix + '/:code/:id/:tab',
            element: <TabbedPage />,
        })

        mainRoutes.children?.push({
            path: '/terms',
            element: <TermsAndConditions />,
        })

        const width = window.innerWidth

        // mainRoutes.children?.push(
        //     {
        //         path: '/Widgets',
        //         element: <WidgetDashboardWrapper/>
        //     }
        // );
        //
        // mainRoutes.children?.push(
        //     {
        //         path: '/Dashboard',
        //         element: <Dashboard size={{width: width}}/>
        //     }
        // );

        mainRoutes.children?.push({
            path: '/ArticleBuilder/:id',
            element: <ArticleBuilder />,
        })
        mainRoutes.children?.push({
            path: '/ArticleBuilder',
            element: <ArticleBuilder />,
        })

        mainRoutes.children?.push({
            path: '/Calendar',
            element: <GuslScheduler />,
        })

        const routes: RouteObject[] = [this.getPreAuthenticatedRoutes()]

        if (mainRoutes?.children?.length) {
            routes.push(mainRoutes)
        }

        const bespokeSet = new Set()
        Object.keys(BESPOKE_PAGES_MAP).forEach((key: string) => {
            BESPOKE_PAGES_MAP[key].forEach((child) => {
                if (child.path) {
                    const parts = child?.path?.split('/')
                    if (parts?.length > 0) {
                        bespokeSet.add(parts[0])
                    }
                }
            })
        })

        bespokeSet.forEach((parentPath) => {
            const bespokeRoutes: RouteObject = {
                path: '/' + parentPath,
                element: this.getLayout(),
                children: [],
            }
            Object.keys(BESPOKE_PAGES_MAP).forEach((key: string) => {
                BESPOKE_PAGES_MAP[key].forEach((child) => {
                    if (child.path) {
                        const parts = child?.path?.split('/')
                        if (parts?.length > 0) {
                            if (parts[0] === parentPath) {
                                bespokeRoutes.children?.push(child)
                            }
                        }
                    }
                })
            })
            if (bespokeRoutes?.children?.length) {
                log.debug(this.className, 'bespokeRoutes', 'MSG0012', bespokeRoutes)
                routes.push(bespokeRoutes)
            }
        })

        // Object.keys(BESPOKE_PAGES_MAP).forEach((key: string) => {
        //     BESPOKE_PAGES_MAP[key]?.forEach(route => bespokeRoutes?.children?.push(route));
        // });
        //
        // if (bespokeRoutes?.children?.length) {
        //     log.debug(this.className, 'bespokeRoutes', 'MSG0012', bespokeRoutes)
        //     routes.push(bespokeRoutes);
        // }
        log.info(this.className, 'MSG003', 'post-auth routes:', routes)
        return routes
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public redirectTo = (
        currentPath: string,
        validSession: boolean,
        hasSession: boolean,
        loaded: boolean,
        systemLoaded: boolean,
        homePage?: string
    ): string | undefined => {
        // return 'dashboard';
        if (validSession && systemLoaded) {
            if (isDefined(currentPath) && currentPath === UI_CONSTANTS.LOGIN_PATH) {
                return UI_CONSTANTS.BASE_PATH + homePage
            }
            if (isDefined(currentPath) && currentPath !== '/') {
                // return '/test?' + currentPath;
                return currentPath
            }
            // return '/backoffice/dashboard';
            return UI_CONSTANTS.BASE_PATH + homePage
        } else if (!hasSession && currentPath !== UI_CONSTANTS.LOGIN) {
            // console.log('-------- here currentPath', currentPath, VISIONX_CONSTANTS.LEGAL_PATH, currentPath.startsWith(VISIONX_CONSTANTS.LEGAL_PATH))
            // if (isDefined(currentPath) && currentPath.startsWith(VISIONX_CONSTANTS.LEGAL_PATH)) {
            //     return currentPath // VISIONX_CONSTANTS.LEGAL_PATH;
            // }
            return UI_CONSTANTS.LOGIN_PATH
        } else if (!systemLoaded) {
            return UI_CONSTANTS.BASE_PATH + homePage
        }
        return undefined
    }

    public getMenuItemsForGroup = (groupName: string, menuGroups: IMenuDTO[]): IMenu[] => {
        return menuGroups
            .filter((menuGroup) => menuGroup.label === groupName)
            .map(
                (menuGroup) =>
                    menuGroup?.menuItems?.map((item) => {
                        return {
                            code: item.code,
                            label: item.label,
                            icon: item.icon || 'fas fa-user-friends',
                            route: '',
                        } as IMenu
                    }) || []
            )
            .flatMap((x: IMenu[]) => x)
    }
}

const menuService = new MenuService()

export { menuService }

export default MenuService
