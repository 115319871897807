import { PluginToLoad } from '@grapesjs/react/dist/utils/plugins'

/*
layout -> gjs-blocks-basic
 */
export const RegisteredPlugins: PluginToLoad[] = [
    {
        id: 'gjs-blocks-basic',
        src: 'https://unpkg.com/grapesjs-blocks-basic',
        options: {
            category: 'Common',
            //blocks: ['column1', 'column2', 'column3', 'column3-7','text', 'link', 'image'], // 'column1', 'column2', 'column3', 'column3-7' 'text', 'link', 'image', 'video', 'map'
            blocks: ['text', 'link', 'image'],
            flexGrid: false,
        },
    },
    {
        id: 'grapesjs-plugin-ckeditor',
        src: 'https://unpkg.com/grapesjs-plugin-ckeditor',
        options: {
            language: 'en',
            toolbarGroups: [
                { name: 'document', groups: ['mode', 'document', 'doctools'] },
                { name: 'clipboard', groups: ['clipboard', 'undo'] },
                {
                    name: 'editing',
                    groups: ['find', 'selection', 'spellchecker', 'editing'],
                },
                { name: 'forms', groups: ['forms'] },
                '/',
                { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                {
                    name: 'paragraph',
                    groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'],
                },
                { name: 'links', groups: ['links'] },
                { name: 'insert', groups: ['insert'] },
                '/',
                { name: 'styles', groups: ['styles'] },
                { name: 'colors', groups: ['colors'] },
                { name: 'tools', groups: ['tools'] },
                { name: 'others', groups: ['others'] },
                { name: 'about', groups: ['about'] },
            ],
            removeButtons: 'NewPage',
        },
    },
]
