import React, { useEffect, useRef, useState } from 'react'
import { Subscription } from 'rxjs'
import { useAppSelector } from '../../../../app/hooks'
import { EnvironmentContext } from '../../../../providers/environment/EnvironmentContext'
import { environmentService } from '../../../../providers/environment/EnvironmentService'
import { RunOnceEffect, unSubscribe } from '../../../../utils/Utils'
import { SummaryDO, SummaryType, WidgetPanelProperties } from '../../../types'
import { ComponentTitleWrapperStyled } from '../../../ui/styled'
import { GuslReport } from '../../gusl-report/GuslReport'
import { GuslTable } from '../../gusl-table/GuslTable'
import { maintainTableService } from '../MaintainTableService'
import { GuslTableWrapperStyled, TableTitleContainerStyled, TableTitleStyled } from '../styled'

type TableSummaryProperties = {
    code: string
    summary: SummaryDO
    isSummaryHeader?: boolean | undefined
    isSummaryFooter?: boolean | undefined
    widgetPanelProperties?: WidgetPanelProperties
}

export const ExpandedSummaryPanel = ({
    code,
    summary,
    isSummaryHeader,
    isSummaryFooter,
    widgetPanelProperties,
}: TableSummaryProperties): React.ReactElement => {
    const splitScreen: boolean = useAppSelector((state) => state.uiSlice.splitScreen)
    const environmentContext = React.useContext(EnvironmentContext)
    const isMobile = environmentContext.isMobileDevice(widgetPanelProperties)
    const guslWrapperElement = useRef(null)
    const [guslWrapperStartPos, setGuslWrapperStartPos] = useState(73)
    const [footerHeight, setFooterHeight] = useState<number>(0)
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription)
        }
    })

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                if (guslWrapperElement) {
                    // @ts-ignore
                    const rect = guslWrapperElement?.current?.getBoundingClientRect()
                    if (rect) {
                        setGuslWrapperStartPos(rect.y)
                    }
                }
            }, 100)
        })
    }, [guslWrapperElement])

    const renderTable = (): React.ReactElement => {
        // console.log('renderTable',{
        //     code,
        //     summary,
        //     isSummaryHeader,
        //     isSummaryFooter,
        // })
        return (
            // MK 11-01-2024 not needed as in ListView ~ line 1176 we have table wrapped in GuslTableWrapperStyled already.Double wrapping was causing horizontal scroll bar
            //   <GuslTableWrapperStyled
            //       ref={guslWrapperElement}
            //       id={code}
            //       key={code}
            //       isSummaryHeader={isSummaryHeader}
            //       isSummaryFooter={isSummaryFooter}
            //       startPos={guslWrapperStartPos}
            //       footerHeight={footerHeight}
            //   >
            <GuslTable
                code={code}
                key={code}
                label={summary.label}
                isSummaryHeader={isSummaryHeader}
                isSummaryFooter={isSummaryFooter}
                fields={maintainTableService.extractAllFieldsFromRows(summary.form)}
                tableData={summary.tableData || []}
                tableActions={summary.tableActions}
                rowActions={summary.rowActions}
                groupActions={summary.groupActions}
                selectUrl={undefined}
                refreshRate={false}
                blastDeltaCommand={summary.blastDeltaCommand}
                disableTableControls={summary.disableTableControls}
                disableColumnResize={summary.disableColumnResize}
            />
            // </GuslTableWrapperStyled>
        )
    }

    const renderLabel = (): React.ReactElement => {
        if (!summary.label) {
            return <></>
        }
        return (
            <>
                <ComponentTitleWrapperStyled isSummaryHeader={true} isSummaryFooter={true}>
                    <TableTitleContainerStyled
                        isSubHeader={true}
                        isSummaryHeader={false}
                        isSummaryFooter={false}
                        footerHeight={footerHeight}
                    >
                        <div className={'col d-flex align-items-center'}>
                            <TableTitleStyled isSubHeader={true} footerHeight={footerHeight} isMobile={isMobile}>
                                {summary.label || ''}
                            </TableTitleStyled>
                        </div>
                    </TableTitleContainerStyled>
                </ComponentTitleWrapperStyled>
            </>
        )
    }

    const renderReport = (): React.ReactElement => {
        return (
            <>
                {renderLabel()}
                <GuslTableWrapperStyled
                    ref={guslWrapperElement}
                    isReportSummary={true}
                    isSummaryHeader={isSummaryHeader}
                    isSummaryFooter={isSummaryFooter}
                    startPos={guslWrapperStartPos}
                    footerHeight={footerHeight}
                >
                    <GuslReport code={code} data={summary.report} />
                </GuslTableWrapperStyled>
            </>
        )
    }
    return (
        <>
            {summary.type === SummaryType.TABLE && !splitScreen && renderTable()}
            {summary.type === SummaryType.REPORT && !splitScreen && renderReport()}
        </>
    )
}
