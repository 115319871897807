import React, { MouseEvent, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { EnvironmentContext } from '../../../../providers/environment/EnvironmentContext'
import { environmentService } from '../../../../providers/environment/EnvironmentService'
import { IEnvironment } from '../../../../providers/environment/types'
import { SystemContext } from '../../../../providers/system/SystemContext'
import { RunOnceEffect, safeStream, unSubscribe } from '../../../../utils/Utils'
import { DEAL_PAGE } from '../../../bespoke/otto-events/deal-view/DealView'
import { PaginationMode } from '../../../types'
import LoadingSpinner from '../../loading-spinner/LoadingSpinner'
import { IFrameContainerStyled, IFrameScrollStyled, IframeViewerStyled } from '../../scheduler/viewers/styled'
import {
    closeGlobalSearchResults,
    closeResultDialog,
    GlobalSearchState,
    openResultDialog,
    paginationModeChange,
    rowsPerPageChange,
} from '../GlobalSearchSlice'
import { GuslPopover } from '../iframe-popover/GuslPopover'
import { Pagination } from '../pagination/Pagination'
import { GlobalSearchResponseDO, SearchResultType } from '../type'
import {
    CloseButtonStyled,
    ClosedStyled,
    DividerStyled,
    NoResultsStyled,
    PaginationBarStyled,
    ResultContainerStyled,
    ResultContentStyled,
    ResultDateStyled,
    ResultListContainerStyled,
    ResultListScrollContainerStyled,
    ResultPageStyled,
    ResultsPanelStyled,
    ResultsTitleWrapperStyled,
    ResultSummaryStyled,
    ResultTitleStyled,
    ResultTitleWrapperStyled,
    ResultWrapperStyled,
} from './styled'

export const GlobalSearchResults = (): React.ReactElement => {
    const navigate = useNavigate()
    const environmentContext = React.useContext(EnvironmentContext)
    const systemContext = React.useContext(SystemContext)
    const dispatch = useAppDispatch()

    const isMobile = environmentContext.isMobileDevice()
    const listElement = useRef(null)

    const [listStartPos, setListStartPos] = useState<number>(150)
    const [footerHeight, setFooterHeight] = useState<number>(0)

    const _globalSearchSlice: GlobalSearchState = useAppSelector((state) => state.globalSearchSlice)
    const [homePage, setHomePage] = useState<string | undefined>(undefined)
    const [ready, setReady] = useState<boolean>(false)
    const [systemLoaded, setSystemLoaded] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(false)
    const [popoverAnchorEl, setPopoverAnchorEl] = useState<Element | null>(null)
    const [isIFrameLoaded, setIFrameLoaded] = useState<boolean>(false)
    const iFrameRef = useRef<HTMLIFrameElement>(null)
    const iframeCurrent = iFrameRef.current
    const popupAnchorRef = useRef<HTMLIFrameElement>(null)

    const triggerPopoverAnchor = (el?: MouseEvent<Element>) => {
        setPopoverAnchorEl(el?.currentTarget || null)
    }

    useEffect(() => {
        iframeCurrent?.addEventListener('load', (e) => {
            setIFrameLoaded(true)
        })
        return () => {
            iframeCurrent?.removeEventListener('load', () => setIFrameLoaded(true))
        }
    }, [iframeCurrent])

    RunOnceEffect(() => {
        let subscription = environmentContext.watchEnvironment().subscribe((environment: IEnvironment) => {
            if (environment.loaded) {
                setHomePage(environment.homePage)
            }
        })
        let loaderSubscription = systemContext.watchSystemLoaded().subscribe((systemLoaded: boolean) => {
            if (systemLoaded) {
                systemContext.getGlobalSearchConfig()
            }
            setSystemLoaded(systemLoaded)
        })
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })

        return () => {
            unSubscribe(subscription)
            unSubscribe(loaderSubscription)
            unSubscribe(heightSubscription)
        }
    })

    useEffect(() => {
        setReady(true)
    }, [homePage, systemLoaded])

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                // @ts-ignore
                if (listElement?.current?.offsetHeight) {
                    // @ts-ignore
                    const rect = listElement?.current?.getBoundingClientRect()
                    if (rect) {
                        setListStartPos(rect.top)
                    } else {
                        setListStartPos(150)
                    }
                }
            }, 100)
        })
    }, [loaded])

    const CloseButton = (): React.ReactElement => {
        const onCloseClick = (e: React.MouseEvent<HTMLDivElement>) => {
            if (e) {
                e.stopPropagation()
            }
            dispatch(closeGlobalSearchResults({}))
        }

        return (
            <CloseButtonStyled onClick={(e) => onCloseClick(e)}>
                <i className={'fa-solid fa-close'} />
            </CloseButtonStyled>
        )
    }

    const Toolbar = (): React.ReactElement => {
        return (
            <ResultsTitleWrapperStyled>
                Search Results
                <CloseButton />
            </ResultsTitleWrapperStyled>
        )
    }

    const renderDialog = (): React.ReactElement => {
        if (_globalSearchSlice?.renderResultDialog) {
            return <>{_globalSearchSlice?.renderResultDialog(_globalSearchSlice?.dialogItem)}</>
        } else {
            return <>{renderDefaultDialog()}</>
        }
    }

    const renderDefaultDialog = (): React.ReactElement => {
        const hideDialog = () => {
            dispatch(closeResultDialog({}))
        }
        const fixUrl = (url: string): string => {
            if (url.startsWith('http')) {
                return url
            }
            return `${environmentService.getEnvironment()?.apiBase || ''}${url}?` //
        }

        const src: string = fixUrl(_globalSearchSlice?.dialogItem?.path || '')
        return (
            <GuslPopover
                anchorEl={popoverAnchorEl}
                title={_globalSearchSlice?.dialogItem?.title || ''}
                date={_globalSearchSlice?.dialogItem?.date || ''}
                onClose={() => hideDialog()}
                headerBgColor={_globalSearchSlice?.dialogItem?.headerBgColor}
                headerColor={_globalSearchSlice?.dialogItem?.headerColor}
                viewWidth={'600px'}
            >
                <IFrameContainerStyled height={'600px'}>
                    <IFrameScrollStyled>
                        <IframeViewerStyled
                            loaded={true}
                            sandbox="allow-scripts allow-modals allow-forms"
                            id={'iframeViewer_gs'}
                            key={'iframeViewer_gs'}
                            ref={iFrameRef}
                            src={src}
                        />
                    </IFrameScrollStyled>
                </IFrameContainerStyled>
                {/*<IFrameWrapperStyled>*/}
                {/*    <IframeStyled loaded={isIFrameLoaded} id={'iframe_gs'} ref={iFrameRef}*/}
                {/*                  src={src || ''}/>*/}
                {/*</IFrameWrapperStyled>*/}
            </GuslPopover>
        )
    }

    const renderClosed = (closed: boolean): React.ReactElement => {
        if (!closed) {
            return <></>
        }
        return <ClosedStyled>CLOSED</ClosedStyled>
    }

    const renderResult = (result: GlobalSearchResponseDO, idx: number): React.ReactElement => {
        const onResultClick = (e: React.MouseEvent<HTMLDivElement>) => {
            console.log(
                '--------------------- result',
                result.resultType,
                SearchResultType.DEAL,
                result.resultType === SearchResultType.DEAL
            )
            if (e) {
                e.stopPropagation()
            }
            if (result.type === SearchResultType.INTERNAL && result.path) {
                dispatch(closeGlobalSearchResults({}))
                navigate('/' + result.path)
            } else if (result.type === SearchResultType.IFRAME && result.path) {
                triggerPopoverAnchor(e)
                dispatch(openResultDialog({ item: result }))
            } else if (result.type === SearchResultType.BESPOKE_DIALOG) {
                triggerPopoverAnchor(e)
                dispatch(openResultDialog({ item: result }))
            } else if (result.resultType === SearchResultType.DEAL) {
                console.log('--------------------- here')
                dispatch(closeGlobalSearchResults({}))
                navigate(DEAL_PAGE + '/' + result.dealId)
            }
        }
        return (
            <ResultWrapperStyled key={'result_' + idx} onClick={(e) => onResultClick(e)}>
                <ResultTitleWrapperStyled>
                    <ResultTitleStyled dangerouslySetInnerHTML={{ __html: result.title || '' }} />
                    {renderClosed(result?.closed || false)}
                </ResultTitleWrapperStyled>
                {/*<ResultSummaryStyled ref={popupAnchorRef}> {result.summary}</ResultSummaryStyled>*/}
                <ResultSummaryStyled ref={popupAnchorRef} dangerouslySetInnerHTML={{ __html: result.summary }} />
                {result.content && <ResultContentStyled dangerouslySetInnerHTML={{ __html: result.content || '' }} />}
                {result.date && <ResultDateStyled>{result.date}</ResultDateStyled>}
                <DividerStyled />
            </ResultWrapperStyled>
        )
    }

    const ResultList = (): React.ReactElement => {
        if (_globalSearchSlice?.results?.length === 0) {
            return (
                <NoResultsStyled ref={listElement}>
                    <p>No results match the search.</p>
                </NoResultsStyled>
            )
        }
        return <div ref={listElement}>{safeStream(_globalSearchSlice.results).map((option, idx) => renderResult(option, idx))}</div>
    }

    const onChangePaginationRows = (numberRows: number) => {
        dispatch(rowsPerPageChange({ rowsPerPage: numberRows }))
    }

    const onChangePaginationMode = (mode: PaginationMode) => {
        dispatch(paginationModeChange({ mode: mode }))
    }

    const PaginationBar = (): React.ReactElement => {
        return (
            <PaginationBarStyled>
                <Pagination
                    queryParams={_globalSearchSlice.queryParams}
                    isMobile={isMobile}
                    paginationControl={_globalSearchSlice.paginationControl}
                    footerHeight={footerHeight}
                    onPaginationModeChange={onChangePaginationMode}
                    onPaginationRowsChange={onChangePaginationRows}
                />
            </PaginationBarStyled>
        )
    }

    const Results = (): React.ReactElement => {
        return (
            <ResultsPanelStyled
                isMobile={isMobile}
                id={'results_panel'}
                listStartPos={listStartPos}
                hasOrderByActions={false}
                footerHeight={footerHeight}
                searchOpen={false}
            >
                <ResultListContainerStyled listStartPos={listStartPos} footerHeight={footerHeight}>
                    <ResultListScrollContainerStyled listStartPos={listStartPos} footerHeight={footerHeight}>
                        <ResultList />
                    </ResultListScrollContainerStyled>
                    {/*<PaginationBar/>*/}
                </ResultListContainerStyled>
            </ResultsPanelStyled>
        )
    }

    const renderPage = (): React.ReactElement => {
        return (
            <ResultContainerStyled>
                <ResultPageStyled ref={listElement}>
                    <Toolbar />
                    <Results />
                    {_globalSearchSlice?.dialogOpened && renderDialog()}
                </ResultPageStyled>
            </ResultContainerStyled>
        )
    }

    return <>{!ready ? <LoadingSpinner /> : renderPage()}</>
}
