import { format } from 'date-fns'
import * as React from 'react'
import { useRef, useState } from 'react'
import { ElementWithTooltip } from '../../../../element-with/element-with-tooltip/ElementWithTooltip'
import { CustomDateCalendarStyled } from '../../../styled/date-range-selector/styled'
import { InputStyled } from '../../../styled/date-range/styled'
import { ErrorMsgStyled, LabelStyled, ResetStyled, SearchButtonStyled, WidgetHeadingStyled } from '../../../styled/side-panel/styled'
import { CustomDatesCalendarDO } from '../../types'

export default function CustomDatesCalendar({ code, field, handlers, setShow }: CustomDatesCalendarDO) {
    const [className] = useState('CustomDatesCalendar-' + new Date().getTime())
    const [noSelection, setNoSelection] = useState<boolean>(false)
    const [wrongDates, setWrongDates] = useState<boolean>(false)
    const [isCustomRangeOpen, setIsCustomRangeOpen] = useState<boolean>(false)
    const fromDateRef = useRef<HTMLInputElement>(null)
    const toDateRef = useRef<HTMLInputElement>(null)
    const hasPast = field.hasPast
    const hasFuture = field.hasFuture

    // https://date-fns.org/v2.29.3/docs/format
    let minMaxFormat: string = 'y-MM-dd'
    const todayForMinMax: string = format(new Date(), minMaxFormat)

    /*HANDLERS*/
    const setCurrentUnit = handlers['setCurrentUnit']
    const setCurrentNum = handlers['setCurrentNum']
    const setCustomDatesSelected = handlers['setCustomDatesSelected']
    const dispatchRangeQueryHandler = handlers['dispatchRangeQueryHandler']

    function selectDateRangeHandler() {
        const fromDate = fromDateRef?.current?.value as string
        const toDate = toDateRef?.current?.value as string

        if (!fromDate || !toDate) {
            setNoSelection(true)
            return
        }

        if (fromDate && toDate && fromDate > toDate) {
            setWrongDates(true)
            return
        }
        setWrongDates(false)
        setNoSelection(false)
        setCurrentUnit('')
        setCurrentNum(0)

        const toDt = new Date(toDate)
        toDt.setHours(23, 59, 59, 9999)
        dispatchRangeQueryHandler({ from: new Date(fromDate), to: toDt, unit: '' })
        setCustomDatesSelected(true)
        setShow(false)
    }

    function resetCustomDates() {
        if (fromDateRef.current !== null) {
            fromDateRef.current.value = ''
        }
        if (toDateRef.current !== null) {
            toDateRef.current.value = ''
        }
        setCustomDatesSelected(false)
    }

    function resetDateRangeHandler() {
        resetCustomDates()

        setCurrentUnit('')
        setCurrentNum(0)
        setWrongDates(false)
        setNoSelection(false)
    }

    return (
        <>
            <CustomDateCalendarStyled>
                <div className="col-md-3">
                    <WidgetHeadingStyled active={isCustomRangeOpen} extraClass={'px-2'}>
                        <span>Custom Dates</span> <br />
                        <ElementWithTooltip
                            element={<ResetStyled onClick={resetDateRangeHandler}>Reset</ResetStyled>}
                            tooltip={<span>Reset dates.</span>}
                        />
                    </WidgetHeadingStyled>
                </div>
                <div className="col-md-9">
                    <div className="row g-0 d-flex justify-content-end align-items-center">
                        <div className={'col'}>
                            <LabelStyled htmlFor={'fromDate'} fontSize={'12px'}>
                                {'From'}{' '}
                            </LabelStyled>
                            <br />
                            <InputStyled
                                ref={fromDateRef}
                                id={'fromDateRef'}
                                name={'fromDateRef'}
                                type="date"
                                min={hasPast ? undefined : todayForMinMax}
                                max={hasFuture ? undefined : todayForMinMax}
                            />
                        </div>
                        <div className={'col'}>
                            <LabelStyled htmlFor={'toDate'} fontSize={'12px'}>
                                {'To'}
                            </LabelStyled>
                            <br />
                            <InputStyled
                                ref={toDateRef}
                                id={'toDate'}
                                name={'toDate'}
                                type="date"
                                min={hasPast ? undefined : todayForMinMax}
                                max={hasFuture ? undefined : todayForMinMax}
                            />
                        </div>
                        <div className="col text-center">
                            <ElementWithTooltip
                                element={
                                    <SearchButtonStyled onClick={selectDateRangeHandler} notBordered notRounded>
                                        Search
                                    </SearchButtonStyled>
                                }
                                tooltip={<span>Search within selected date range.</span>}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-12 text-center">
                    {noSelection && <ErrorMsgStyled>Please, select From and To dates!</ErrorMsgStyled>}
                    {wrongDates && <ErrorMsgStyled>Start date is greater then end date!</ErrorMsgStyled>}
                </div>
            </CustomDateCalendarStyled>
        </>
    )
}
