import styled, { ThemeProps } from 'styled-components'

interface SvgStyledProps extends ThemeProps<any> {
    width: string
}

export const SvgStyled = styled.div.attrs((props: SvgStyledProps) => ({}))<SvgStyledProps>`
    text-align: center;
    margin: 0px auto;

    path,
    circle,
    polygon {
        fill: ${(props) => props.theme.table.columnColor};
        stroke: ${(props) => props.theme.table.columnColor};
    }
    width: ${(props) => props.width};
`
