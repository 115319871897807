import { decode } from 'html-entities'
import React from 'react'
import { MoneyDTO } from '../../types'
import { AmountStyled } from './styled'
import { ExtraFieldProperties } from './types'

const getLanguage = () => new Intl.NumberFormat().resolvedOptions().locale

const applyDecimals = (n: number, decimalPlace: number) => {
    return n / Math.pow(10, decimalPlace)
}

/**
 * assuming money has already had applyDecimals
 * @param n
 * @param displayPlaces
 */
const round = (n: number, displayPlaces: number) => {
    return Math.round((n + Number.EPSILON) * Math.pow(10, displayPlaces)) / Math.pow(10, displayPlaces)
}

const formatAmount = (amount: number, decimalPlaces: number): string => {
    return amount.toLocaleString(getLanguage(), {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    })
}

const Money: React.FC<ExtraFieldProperties> = ({
    amount,
    showCurrency = true,
    defaultZero = true,
    colorise = false,
    flip = false,
    withSign = false,
    withNegative = false,
    zeroAsDash = false,
    zeroAsSpace = false,
    decimalPlaces = 2,
    nonDecimal = false,
    banner = false,
    prefix = '',
    suffix = '',
    displayPlaces,
}) => {
    if (amount === undefined && !defaultZero) {
        return <></>
    }
    const symbol = showCurrency ? decode(amount?.currency?.symbol || '') : ''

    let moneyAmount: MoneyDTO = amount

    let value = applyDecimals(amount?.value || 0, decimalPlaces) * (flip ? -1 : 1)
    if (flip && amount?.value) {
        moneyAmount.value = moneyAmount.value * -1
    }

    if (displayPlaces) {
        value = round(value, displayPlaces)
        decimalPlaces = displayPlaces
    }
    if (nonDecimal) {
        value = Math.trunc(value)
        decimalPlaces = 0
    }

    if (value === 0 && (zeroAsDash || zeroAsSpace)) {
        return <>{zeroAsDash ? '-' : ' '}</>
    }

    let sign = ''
    if (withSign) {
        sign = value >= 0 ? '+' : '-'
    } else if (withNegative && value < 0) {
        sign = '-'
    }

    return (
        <AmountStyled colorise={colorise} banner={banner} amount={moneyAmount}>{`${prefix}${symbol}${sign}${formatAmount(
            value,
            decimalPlaces
        )}${suffix}`}</AmountStyled>
    )
}

export default Money
