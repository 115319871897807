import styled, { ThemeProps } from 'styled-components'

interface CohortLogoStyledProps extends ThemeProps<any> {}

export const CohortLogoStyled = styled.img.attrs((props: CohortLogoStyledProps) => ({}))<CohortLogoStyledProps>`
    width: 15px;
`

export const CohortLogoWrapperStyled = styled.div.attrs((props) => ({}))`
    vertical-align: middle;
    text-align: center;
    width: 100%;
`
