import { Component, Editor } from 'grapesjs'
import { sessionService } from '../../../../../../providers/session/SessionService'
import { HtmlContent } from './HtmlContent'

const category: string = 'Alvar'
const componentName: string = 'HtmlContentGjsComponent'
const label: string = 'UCIT'
export const HtmlContentGjsComponent = (editor: Editor, options = {}) => {
    editor.Components.addType(componentName, {
        extend: 'base-react-component',
        model: {
            defaults: {
                component: HtmlContent,
                url: '/article/html-content-ucit',
                htmlContent: '',
                stylable: true,
                resizable: true,
                editable: true,
                draggable: true,
                droppable: true,
                attributes: {
                    mlsid: 'Default MLSID',
                    editable: true,
                },
                traits: [
                    {
                        type: 'number',
                        label: 'MLS ID',
                        name: 'mlsid',
                    },
                    {
                        type: 'checkbox',
                        label: 'All',
                        name: 'all',
                    },
                    {
                        type: 'select', // Type of the trait
                        label: 'Type', // The label you will see in Settings
                        name: 'type', // The name of the attribute/property to use on component
                        options: [
                            { id: 'text', name: 'Text' },
                            { id: 'email', name: 'Email' },
                            { id: 'password', name: 'Password' },
                            { id: 'number', name: 'Number' },
                        ],
                    },
                ],
            },
            init() {
                this.listenTo(this, 'change:htmlContent', this.handlePropChange)
            },

            handlePropChange() {
                let content = this.get('htmlContent')
                const component: Component | Component[] = editor.addComponents(content)
                return component

                // Here inside view it is getting the old value. of "testprop" i.e '12345' but not
                //the new value
                //which is being fetched from server in the init() of model.
                // let comp1 = '<div>' +
                //     '<img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png" />' +
                //     '<span title="foo">' + prop + '</span>' +  '</div>';
                //
                // const component = editor.addComponents(comp1);
                // return component
            },
            updated(property, value, prevValue) {
                console.log('HtmlContent updated', 'property', property, 'value', value, 'prevValue', prevValue)
            },
            removed() {
                console.log('HtmlContent removed')
            },
        },
        isComponent: (el) => {
            return el.tagName === componentName.toUpperCase()
        },
    })

    editor.BlockManager.add(componentName, {
        media: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 15 15" xml:space="preserve"><g>
                   <text x="4px" y="12px" textanchor="middle" fill="#FFFFFF" strokewidth="5px" fontsize="100px" fontweight="bold">${componentName
                       .substring(0, 1)
                       .toUpperCase()}</text></g></svg>`,
        label: `<div class='gjs-fonts gjs-f-b1'>${label}</div>`,
        category: category,
        content: `<HtmlContent data-gjs-type="${componentName}"></HtmlContent>`,
    })
}
