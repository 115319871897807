export const LINE_DEFAULT_OPTIONS = {
    maintainAspectRatio: false,
    responsive: true,
    borderWidth: 1,
    pointRadius: 0,
    tension: 0.5,
    fill: true,
    plugins: {
        legend: {
            display: true,
            labels: {
                boxWidth: 8,
                boxHeight: 8,
                usePointStyle: true,
                pointStyle: 'triangle',
                font: { size: 10 },
            },
        },
    },
    scales: {
        x: {
            display: false,
            grid: {
                display: false,
            },
        },
        y: {
            display: false,
            grid: {
                display: false,
            },
        },
        r: {
            display: false,
            grid: {
                display: false,
            },
        },
    },
}
