import { Link, LinkProps } from 'react-router-dom'
import styled, { ThemeProps } from 'styled-components'
import { StyledProps } from '../../styles/types'

export interface LinkLabelProps extends LinkProps, StyledProps {}

export const LogoStyled = styled.img`
    width: 80%;
`

interface MainContainerProperties extends ThemeProps<any> {
    $extraClassName: string | undefined
}

export const MainContainer = styled.main.attrs((props: MainContainerProperties) => ({
    className: props.$extraClassName ? props.$extraClassName : '',
}))<MainContainerProperties>`
    display: grid;
    grid-row-gap: 20px;
    justify-items: center;
    background-color: ${(props) => props.theme.colors.background[0]};
    .login {
        background-color: ${(props) => props.theme.login.innerBackground};
    }
`
export const ForgottenDetailsStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.4em;
    cursor: pointer;
    h1 {
        color: ${(props) => props.theme.login.linkColor};
    }
`

export const WarningMessageTitleStyled = styled.h1`
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 10px;
`
export const WarningMessageContainerStyled = styled.div`
    color: ${(props) => props.theme.colors.background[0]};
    background-color: ${(props) => props.theme.colors.warning};
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    padding: 10px;
    text-align: center;
    line-height: 1.3em;
`
export const ContainerStyled = styled.footer`
    grid-area: footer;
    background-color: ${(props) => props.theme.colors.background[0]};
    padding: ${(props) => props.theme.padding.xl} ${(props) => props.theme.padding.m} ${(props) => props.theme.padding.l}
        ${(props) => props.theme.padding.m};
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const TitleStyled = styled.h2`
    text-align: center;
    color: ${(props) => props.theme.colors.light};
    padding: 10px;
`

export const CookiePolicyLink = styled(Link)<LinkLabelProps>`
    text-decoration: none;
    color: ${(props) => props.theme.colors.dark[1]};
    //text-align: center;
`

export const LicenceContainerStyled = styled.section`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 10px;
    background-color: var(--widget-blue);
    border-radius: 5px;
`
export const DescriptionStyled = styled.p`
    max-width: 600px;
    color: ${(props) => props.theme.table.columnColor};
    text-align: justify;
    line-height: 1.5em;
    font-size: 14px;
    p {
        padding-bottom: 10px;
    }
`
export const PaymentContainerStyled = styled.ul`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding-top: 10px;
    img {
        padding: 5px;
    }
`

export const NavsContainerStyled = styled.section`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 10px;
`

export const PaymentMethodImageStyled = styled.img``

export const BettingFooterStyled = styled.div.attrs((props) => ({}))`
    border-top: ${(props) => props.theme.form.fieldBorder};
`
