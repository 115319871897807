import styled, { ThemeProps } from 'styled-components'

interface ImageWrapperStyledProps extends ThemeProps<any> {
    width?: string
}

export const ImageStyled = styled.img.attrs((props: ImageWrapperStyledProps) => ({}))<ImageWrapperStyledProps>`
    // width: ${(props) => (props.width ? props.width : '40px')}; // gbw because of news images
    ${(props) => props.width && 'width: ' + props.width + ';'};
    vertical-align: middle;
    fill: currentColor;
`
