import styled from 'styled-components'
import { HEADER_HEIGHT, TAB_HEIGHT } from '../../providers/theme/GuslThemeProvider'
import { RowStyledProperties, TableStyledProps } from '../common/gusl-table/styled/table/styled'
import { TdProperties } from '../common/maintain-table/styled'

const displayTable = 'width: 100%;'

interface AlphaListWrapperProperties {
    isMobile: boolean
}

export const AlphaListTableStyled = styled.table.attrs((props) => ({
    className: props.className,
}))`
    table-layout: fixed;
    ${displayTable}
`

export const AlphaListTableHeadStyled = styled.thead.attrs((props) => ({
    className: props.className,
}))`
    ${displayTable}
`

export const AlphaListTableBodyStyled = styled.tbody.attrs((props: TableStyledProps) => ({}))`
    ${displayTable}
`

export const AlphaListTableRowStyled = styled.tr.attrs((props: RowStyledProperties) => ({}))<RowStyledProperties>``

export const AlphaListColumnStyled = styled.td.attrs((props: TdProperties) => ({}))<TdProperties>`
    color: ${(props) => props.theme.table.columnColor};
    font-size: ${(props) => props.theme.table.columnFontSize};
`
