import { ThreeDots } from 'react-loader-spinner'
import styled from 'styled-components'

interface ExternalAppProperties {
    coverUrl: string | undefined
    systemLoaded: boolean | undefined
}

/*
  ${props => (!props.coverUrl || props.systemLoaded) && 'background-color: ' + props.theme.colors.dark[0] + ';'}

 */
export const ExternalAppStyled = styled.div.attrs((props: ExternalAppProperties) => ({}))<ExternalAppProperties>`
    color: ${(props) => props.theme.table.headerColor};

    ${(props) => props.coverUrl && !props.systemLoaded && 'background-image: url(' + props.coverUrl + '); background-size: cover;'}
    ${(props) => (!props.coverUrl || props.systemLoaded) && 'background-color: ' + props.theme.colors.background[1] + ';'}
  height: var(--internal-height);
`

export const SplashContainerStyled = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.splash.background};
    display: grid;
    justify-items: center;
    justify-content: center;
    align-content: center;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
`

export const SplashWrapperStyled = styled.div.attrs((props) => ({}))`
    // position: relative;
    // left: ${(props) => props.theme.splash.left}};
    // top: ${(props) => props.theme.splash.top};
`

export const SpinnerContainerStyled = styled(ThreeDots).attrs((props) => ({
    size: props.theme.splash.size,
    sizeUnit: 'px',
    color: props.theme.spinner.color,
}))``

export const LogoStyled = styled.img`
    width: 100%;
`
export const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
}
export const deviceMin = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`,
}
export const deviceMax = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`,
}
export const CookieConsentStyled = styled.div`
    line-height: 25px;
`
export const PolicyLinkStyled = styled.span`
    text-decoration: none;
    color: ${(props) => props.theme.colors.dark[1]};

    :hover {
        text-decoration: underline;
    }
`
