import React, { useContext, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { BlastContext } from '../../../providers/blast/BlastContext'
import { EnvironmentContext } from '../../../providers/environment/EnvironmentContext'
import MGMutation from '../../../providers/session/MGMutation'
import MGQuery from '../../../providers/session/MGQuery'
import { SessionContext } from '../../../providers/session/SessionContext'
import { constructUrl, noop, splitPath } from '../../../utils/Utils'
import { FieldConfigDTO, InQueryDTO, WidgetPanelProperties } from '../../types'
import ElementWithPopover from '../element-with/element-with-popover/ElementWithPopover'
import { ElementWithTooltip } from '../element-with/element-with-tooltip/ElementWithTooltip'
import { GuslTableState, handleIndividualAdvancedSearchFields } from '../gusl-table/guslTableSlice'
import { GuslTableQueryParamState, handleIns, resetSingleIn } from '../gusl-table/queryParamsSlice'
import { ActionIconStyled } from '../gusl-table/styled/table-controls/styled'
import MiniLoadingSpinner from '../gusl-table/table-controls/MiniLoadingSpinner'
import {
    ControlContainerTextFilterStyled,
    LookupContainerTextFilterStyled,
    LookupDataItemStyled,
    LookupDataItemWrapperStyled,
    LookupInputTextFilterStyled,
    LookupItemStyled,
    MatchesStyledAsTextFilter,
    NoMatchStyled,
    ResetStyled,
    SelectAllWrapperStyled,
    SelectedFormValueStyled,
    SelectedFormValueTextFilterStyled,
    SelectedFormValueTextFilterWrapperStyled,
    SelectedFormValueWrapperContentStyled,
    SelectedFormValueWrapperStyled,
    SpacerStyled,
} from '../lookup/styled'
import { CurrentMatchesDO, IsSelectedDO, StartAgainDO } from '../lookup/types'
import Icon from '../maintain-table/bootstrap/Icon'

export interface MultiLookupFieldAsTextFilterDO {
    field: FieldConfigDTO
    code: string
    data: any
    widgetPanelProperties?: WidgetPanelProperties
}

export type SearchType = 'distinctUrl' | 'lookupCollection' | 'lookupSelectUrl' | 'lookupSearchUrl' | 'selectUrl'
export const MultiLookupFieldAsTextFilter = ({ field, code, data, widgetPanelProperties }: MultiLookupFieldAsTextFilterDO) => {
    const [className] = React.useState<string>(() => 'MultiLookupFieldAsTextFilter-' + new Date().getTime())
    // MK 11/08/2023
    const state: GuslTableState = useAppSelector((state) => state.guslTableSlice[code])
    const selectUrl: string = state.selectUrl
    const showTopTableFilters = state?.showTopFilters
    const [isBlockOpen, setIsBlockOpen] = useState<boolean>(showTopTableFilters || false)
    const isOptionTypeField: boolean = field.type === 'option'
    // MK 24/08/2023 on tabbed page we need to replace {id} with code
    const [_selectUrl, setSelectUrl] = useState<string>(selectUrl)
    const [entityCode, id, tabCode] = splitPath(window.location.pathname)
    useEffect(() => {
        if (id) {
            setSelectUrl(_selectUrl.replace('{id}', id))
        }
    }, [])

    // END OF 24/08/2023

    function openFilterBlockHandler() {
        setIsBlockOpen(!isBlockOpen)
    }

    // const systemContext = useContext(SystemContext);
    const sessionContext = useContext(SessionContext)
    const blastContext = useContext(BlastContext)
    const environmentContext = useContext(EnvironmentContext)
    const isMobile = environmentContext.isMobileDevice(widgetPanelProperties)
    const dispatch = useAppDispatch()
    // MK 27/08/2023 if (field.lookupSelectUrl) => we need to change displayField to "name"
    let displayField: string = field.name
    const queryParamState: GuslTableQueryParamState = useAppSelector((state) => state.queryParamsSlice[code])
    const resetAt: number | undefined = queryParamState.queryParams.resetAt
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [submitted, setSubmitted] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const [matchesOpen, setMatchesOpen] = useState<boolean>(false)
    const serverSearch: boolean = typeof field.lookupSearchUrl !== 'undefined'
    const blastSearch: boolean = field.lookupCollection !== ''
    const [loading, setLoading] = useState<boolean>(false)
    const [lookupData, setLookupData] = useState<any[]>([])
    const [formValue, setFormValue] = useState<string | undefined>(undefined)

    const [refreshRequest, setRefreshRequest] = useState<number>(0)
    const [searchValue, setSearchValue] = useState<string>(inputRef?.current?.value || '')
    let matches: string[] = []
    const referenceName = field.name
    const matchesWrapperId = 'matches_' + referenceName
    const matchesWrapper: HTMLElement | null = document.getElementById(matchesWrapperId)
    const scrollToTimeout: number = 500
    // MK 22/08/2023 when opening and closing individual filter, we need to preserve the values
    const currentIns: InQueryDTO[] | undefined = queryParamState.queryParams.ins?.filter((_in) => _in.field === field.name)
    let InValues = currentIns?.length ? currentIns[0].values : []
    let [multiFormValues, setMultiFormValues] = useState<any[]>([...InValues])
    const [inResetAt, setInResetAt] = useState<number>(0)

    // MK 28/08/2023
    const getSearchType = (field: FieldConfigDTO): SearchType => {
        if (field.distinctUrl) {
            return 'distinctUrl'
        } else if (field.lookupCollection) {
            return 'lookupCollection'
        } else if (field.lookupSelectUrl) {
            return 'lookupSelectUrl'
        } else if (field.lookupSearchUrl || field.selectUrl) {
            return 'selectUrl'
        } else {
            return 'selectUrl'
        }
    }

    const searchType: SearchType = getSearchType(field)
    const showArrow: boolean =
        isOptionTypeField ||
        (searchValue === '' && matchesOpen) ||
        searchType === 'distinctUrl' ||
        matches.length > 0 ||
        lookupData.length > 0

    const DistinctValuesRD = MGQuery({
        method: 'get',
        endpoint: constructUrl(field.distinctUrl as string, data),
        queryParams: {},
        dontRunQuery: searchType !== 'distinctUrl',
    })

    useEffect(() => {
        setMultiFormValues([])
        setMatchesOpen(false)
        matches = []
        setLookupData([])
        if (inputRef.current) {
            inputRef.current!.value = ''
        }

        setSearchValue('')
    }, [resetAt, inResetAt])

    useEffect(() => {
        setMultiFormValues(multiFormValues)
    }, [refreshRequest])

    // MK 23/08/2023 single In reset
    function singleInResetHandler() {
        setInResetAt(new Date().getTime())
        dispatch(resetSingleIn({ code, field: field.name }))
    }

    // END OF 23/08/2023 single In reset

    //
    // /** in overflown container with long list, we scroll back to
    //  * selected element that is deep down in the list
    //  * to avoid manual scrolling down again...*/
    function scrollToSelected(scrollTop: number) {
        try {
            if (document.getElementById(matchesWrapperId) !== null) {
                document.getElementById(matchesWrapperId)?.scrollTo({ top: scrollTop })
            }
        } catch (e) {
            /** dropdown is closed*/
        }
    }

    const rowSelected = (rowData: any) => {
        dispatch(handleIns({ code, field: displayField, value: rowData[displayField] }))
        setFormValue(format(rowData))
        const selected = format(rowData)

        if (!multiFormValues.includes(selected)) {
            multiFormValues.unshift(selected)
        }

        if (inputRef?.current?.value) {
            inputRef.current.value = ''
            setSearchValue('')
        }
        const scrollTop = document.getElementById(matchesWrapperId)?.scrollTop || 0

        setTimeout(scrollToSelected, scrollToTimeout, scrollTop)
    }

    function rowDeSelected(selected: string | undefined) {
        if (selected) {
            dispatch(handleIns({ code, field: displayField, value: selected as string }))
            multiFormValues = multiFormValues.filter((item) => item !== selected)
            console.log({ multiFormValues, selected })
            setMultiFormValues(multiFormValues)
        }
    }

    const getLookupData = () => {
        let data: any[] = []
        if (searchType === 'distinctUrl') {
            if (DistinctValuesRD.isSuccess) {
                setLookupData(DistinctValuesRD.data)
            }
        } else if (searchType === 'lookupCollection') {
            blastContext.getCollection(field.lookupCollection as string).forEach((item) => data.push(item))
            setLookupData(data)
            setLoading(false)
            // log.debug(className, 'MSG001', '=========> BLAST')
        } else if (searchType === 'lookupSelectUrl') {
            // log.debug(className, 'MSG001', '=========> LOOKUP URL')
            setLoading(true)
            sessionContext.get<any[]>(field.lookupSelectUrl as string).then(
                (response) => {
                    //console.log({response, data: response.data})
                    data = response.data || []
                    setLookupData(response.data || [])
                    setLoading(false)
                },
                (reason) => {
                    setErrorMessage('Could not load data')
                    setLoading(false)
                    // log.error(className, "ERR001", "Error getting template data", reason);
                }
            )
        }
        // MK 24/08/2023 _selectUrl => changed, on tabbed page replacing {id} with id from path
        else if (searchType === 'lookupSearchUrl' || searchType === 'selectUrl') {
            //log.debug(className, 'MSG001', '=========> LOOKUP SEARCH URL OR SELECT URL')
            setLoading(true)
            const searchValue: string | undefined = inputRef?.current?.value
            MGMutation({
                //  @ts-ignore
                sessionContext,
                endpoint: _selectUrl,
                queryParams: {
                    should: [{ field: field.name, value: searchValue as string, fuzzy: true }],
                    onlyFields: [displayField],
                    distinct: [displayField],
                },
            })
                .then((response) => {
                    //console.log({response})
                    setLoading(false)
                    // @ts-ignore
                    setLookupData(response.data.content || [])
                })
                .catch((e) => setErrorMessage('Could not load data'))

            // setLoading(true);
            // sessionContext.post<SearchRequestDTO, any[]>(field.lookupSearchUrl,
            //     {searchValue: inputRef?.current?.value as string})
            //     .then((response) => {
            //         data = response.data || [];
            //         // setLookupData(response.data || []);
            //         setLoading(false);
            //     }, reason => {
            //         log.error(className, "ERR001", "Error getting template data", reason);
            //         setErrorMessage('Could not load data');
            //         setLoading(false);
            //     });
        } else {
            setLoading(false)
        }

        return data
    }

    const format = (rowData: any): string => {
        return rowData[displayField]
    }

    //
    function showLookupDataHandler() {
        if (!lookupData.length || blastSearch) {
            getLookupData()
        }
        setMatchesOpen(!matchesOpen)
        setFormValue('')
        setSearchValue('')
        setErrorMessage('')
    }

    function isSelected({ rowData }: IsSelectedDO) {
        return multiFormValues.includes(format(rowData))
    }

    function isJustSelected({ rowData }: IsSelectedDO) {
        return multiFormValues[0] === format(rowData)
    }

    function inputHandler() {
        if (inputRef.current) {
            // log.debug(className, 'MSG00x------> input handler', {inputRef, field})
            if (matches.length && !matchesOpen) {
                setMatchesOpen(true)
            }
            setSearchValue(inputRef.current.value)
            /* LOOKUP URL*/
            if (field.lookupSelectUrl) {
                // log.debug(className, 'MSG002--->  LOOKUP URL')
                /*get data once*/
                if (inputRef.current.value.length === 1 && !lookupData.length) {
                    // log.debug(className, 'MSG002--->  LOOKUP URL-->GETTING DATA')
                    getLookupData()
                }
            } else if (field.lookupCollection || field.lookupSearchUrl || selectUrl) {
                /*BLAST | SERVER SEARCH*/
                // log.debug(className, 'MSG002--->  BLAST | SERVER SEARCH')
                /*look for data when search string is between 2-4 chars*/
                if (inputRef.current.value.length > 1 && inputRef.current.value.length < 5) {
                    // log.debug(className, 'MSG002--->  BLAST | SERVER SEARCH-->GETTING DATA')
                    getLookupData()
                }
            } else {
                // log.debug(className, 'MSG00x------> input handler', {inputRef, field}, selectUrl)
            }

            if (lookupData.length > 0) {
                setMatchesOpen(true)
            }
        }
    }

    //
    function canSelectAll({ collection }: CurrentMatchesDO) {
        let canSelectAll: boolean = false

        collection.forEach((item) => {
            if (!multiFormValues.includes(format(item))) {
                canSelectAll = true
            }
        })

        return canSelectAll
    }

    function closeIndividualFilter() {
        dispatch(handleIndividualAdvancedSearchFields({ code, field: field.name }))
    }

    function SelectAll({ collection }: CurrentMatchesDO) {
        const selectAll: boolean = canSelectAll({ collection })

        function selectAllHandler() {
            collection.forEach((rowData: any) => {
                selectAll ? rowSelected(rowData) : rowDeSelected(format(rowData))
            })
            setRefreshRequest(refreshRequest + 1)
        }

        return (
            <SelectAllWrapperStyled onClick={selectAllHandler}>
                <span>
                    {selectAll ? '' : 'de-'}select all {collection.length}
                </span>
            </SelectAllWrapperStyled>
        )
    }

    const displayFields: string[] = [displayField]

    function CurrentMatches({ collection }: CurrentMatchesDO) {
        // MK 27/08/2023 not sure... the results from lookupSelectUrl differ...
        if (searchType === 'lookupSelectUrl') {
            if (collection.length > 0) {
                if (typeof collection[0]['name'] !== 'undefined') {
                    displayField = 'name'
                } else if (typeof collection[0]['code'] !== 'undefined') {
                    displayField = 'code'
                }
            }
        }
        // MK 28/08/2023
        else if (searchType === 'distinctUrl') {
            const fieldNameFromValues: string | undefined =
                collection.length > 0 && collection[0].fieldName ? collection[0].fieldName : undefined
            displayField = fieldNameFromValues || field.name
        }

        function getDistinctMatches(collection: CurrentMatchesDO['collection']) {
            let _uniqueMatches: { [displayField: string]: string }[] = []
            collection.forEach((item) => {
                if (item.label) {
                    _uniqueMatches.push({ [displayField]: item.label })
                } else if (item.value) {
                    _uniqueMatches.push({ [displayField]: item.value })
                }
            })

            return _uniqueMatches
        }

        function getUniqueMatches(collection: CurrentMatchesDO['collection']) {
            const _uniqueMatches: Record<string, any> = {}

            collection.forEach((item) => {
                if (!_uniqueMatches[item[displayField]]) {
                    _uniqueMatches[item[displayField]] = item
                }
            })

            return Object.values(_uniqueMatches)
        }

        const uniqueMatches: CurrentMatchesDO['collection'] =
            searchType === 'distinctUrl' ? getDistinctMatches(collection) : getUniqueMatches(collection)

        return (
            <MatchesStyledAsTextFilter id={matchesWrapperId}>
                <SelectAll collection={uniqueMatches} />

                {uniqueMatches &&
                    uniqueMatches.map((rowData, idx) => (
                        <LookupDataItemWrapperStyled key={idx}>
                            {isSelected({ rowData }) && <StartAgain selected={rowData[displayField]} />}
                            <LookupDataItemStyled
                                isJustSelected={isJustSelected({ rowData })}
                                isSelected={isSelected({ rowData })}
                                onClick={() => (!isSelected({ rowData }) ? rowSelected(rowData) : noop())}
                            >
                                {displayFields.map((field, idx) => (
                                    <LookupItemStyled key={idx}>{rowData[displayField]}</LookupItemStyled>
                                ))}
                                {isSelected({ rowData }) && <>&#x2713;</>}
                            </LookupDataItemStyled>
                        </LookupDataItemWrapperStyled>
                    ))}
            </MatchesStyledAsTextFilter>
        )
    }

    function Matches() {
        // if (searchValue.length >= 2) {
        //
        //     matches = serverSearch ? lookupData : lookupData.filter((item) => {
        //         if(item && typeof item.name !== "undefined") {
        //             console.log({item, searchValue})
        //             item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        //             item.code.toLowerCase().includes(searchValue.toLowerCase())
        //         }
        //
        //         }
        //     )
        //
        //
        //     // // @ts-ignore
        //     // matches = lookupData.content ?? [];
        //
        // }

        matches = lookupData ?? []

        if (loading) {
            return <MiniLoadingSpinner isLoading={loading} />
        } else if (errorMessage?.length) {
            return (
                <NoMatchStyled>
                    {errorMessage} <StartAgain />
                </NoMatchStyled>
            )
        } else {
            // @ts-ignore
            if (inputRef?.current?.value?.length > 2 && !matches.length) {
                return (
                    <NoMatchStyled>
                        No matches <StartAgain />
                    </NoMatchStyled>
                )
            } else if (matches.length && matchesOpen) {
                return <CurrentMatches collection={matches} />
            } else if (matchesOpen) {
                return <CurrentMatches collection={lookupData} />
            } else {
                return <></>
            }
        }
    }

    console.log({ matches, lookupData, searchValue })

    function StartAgain({ selected }: StartAgainDO) {
        return (
            <ElementWithTooltip
                element={
                    <i
                        className={'fa-solid fa-xmark ms-3 me-1'}
                        onClick={() => {
                            setErrorMessage('')
                            if (inputRef?.current?.value) {
                                inputRef.current.value = ''
                                setSearchValue('')
                            }
                            rowDeSelected(selected)
                            const scrollTop = document.getElementById(matchesWrapperId)?.scrollTop || 0
                            setTimeout(scrollToSelected, scrollToTimeout, scrollTop)
                        }}
                    />
                }
                tooltip={<span>Remove</span>}
            />
        )
    }

    const renderDownArrow = (): React.ReactElement => {
        return (
            <ElementWithTooltip
                element={
                    <ActionIconStyled isMobile={isMobile} active={matchesOpen} onClick={showLookupDataHandler}>
                        <Icon icon={'me-2 fa-solid fa-angle-' + (matchesOpen ? 'down' : 'right')} />
                    </ActionIconStyled>
                }
                tooltip={<span>{matchesOpen ? 'Collapse' : 'Expand'}</span>}
            />
        )
    }

    const renderFormView = (): React.ReactElement => {
        return (
            <>
                <div className="row g-0">
                    {multiFormValues.slice(0, 2).map((formValue, idx) => (
                        <SelectedFormValueWrapperStyled key={idx} className={'col-4'}>
                            <SelectedFormValueWrapperContentStyled>
                                <ElementWithTooltip
                                    element={
                                        <SelectedFormValueTextFilterWrapperStyled isMulti>
                                            <SelectedFormValueTextFilterStyled>{formValue}</SelectedFormValueTextFilterStyled>
                                            <StartAgain selected={formValue} />
                                        </SelectedFormValueTextFilterWrapperStyled>
                                    }
                                    tooltip={<span>{formValue}</span>}
                                />
                            </SelectedFormValueWrapperContentStyled>
                        </SelectedFormValueWrapperStyled>
                    ))}

                    {/** all selected items in popover if > 2*/}
                    {multiFormValues.length > 2 && (
                        <>
                            <ElementWithPopover
                                placement={'left'}
                                trigger={'click'}
                                element={
                                    <SelectedFormValueWrapperStyled key={'all-selected'} role={'button'} className="col-3">
                                        <SelectedFormValueWrapperContentStyled>
                                            <SelectedFormValueStyled>
                                                <SpacerStyled>
                                                    <i className={'fa-regular fa-eye xs-small'} />
                                                    <span className={'xs-small'}> ({multiFormValues.length})</span>
                                                </SpacerStyled>
                                            </SelectedFormValueStyled>
                                        </SelectedFormValueWrapperContentStyled>
                                    </SelectedFormValueWrapperStyled>
                                }
                                popover={
                                    <>
                                        <ResetStyled role={'button'} onClick={singleInResetHandler}>
                                            Reset all ({multiFormValues.length})
                                        </ResetStyled>
                                        {multiFormValues.map((formValue, idx) => (
                                            <SelectedFormValueStyled key={idx} isMulti>
                                                <span> {formValue}</span>
                                                <StartAgain selected={formValue} />
                                            </SelectedFormValueStyled>
                                        ))}
                                    </>
                                }
                            />
                        </>
                    )}
                    {!showTopTableFilters && (
                        <ElementWithTooltip
                            element={
                                <SelectedFormValueWrapperStyled
                                    key={'close-filter'}
                                    role={'button'}
                                    onClick={closeIndividualFilter}
                                    className="col-1 ms-auto"
                                >
                                    <SelectedFormValueWrapperContentStyled>
                                        <div className={'d-flex justify-content-between align-items-center mt-1'}>
                                            <SpacerStyled>
                                                <ResetStyled>
                                                    <i className={'fa-solid fa-xmark'} />
                                                </ResetStyled>
                                            </SpacerStyled>
                                        </div>
                                    </SelectedFormValueWrapperContentStyled>
                                </SelectedFormValueWrapperStyled>
                            }
                            tooltip={<span>Close this filter</span>}
                        />
                    )}
                </div>

                <LookupContainerTextFilterStyled submitted={submitted} noValue={!formValue}>
                    <ControlContainerTextFilterStyled>
                        <LookupInputTextFilterStyled
                            onInput={inputHandler}
                            ref={inputRef}
                            type="text"
                            placeholder={!isOptionTypeField ? 'Start typing...' : 'Start typing or select...'}
                        />

                        <div className={'d-flex align-items-center'}>
                            {searchValue.length ? (
                                <ElementWithTooltip
                                    element={
                                        <span
                                            onClick={() => {
                                                setSearchValue('')
                                                setMatchesOpen(false)
                                                if (inputRef?.current?.value) {
                                                    inputRef.current.value = ''
                                                }
                                            }}
                                        >
                                            <i className={'fa-solid fa-xmark me-3'} />
                                        </span>
                                    }
                                    tooltip={<span>Clear search term</span>}
                                />
                            ) : (
                                <></>
                            )}

                            {showArrow && renderDownArrow()}
                        </div>
                    </ControlContainerTextFilterStyled>
                </LookupContainerTextFilterStyled>

                {/*<label htmlFor={field.name}>{field.label}</label>*/}
                {/*<FieldLabel label={field.label} htmlFor={field.name}/>*/}

                <Matches />
            </>
        )
    }
    //
    return (
        <>
            {/*<WidgetHeadingStyled active={isBlockOpen} onClick={openFilterBlockHandler}>*/}
            {/*    <ActionIconStyled isMobile={isMobile} active={isBlockOpen}>*/}
            {/*        <Icon icon={'me-2 fa-solid fa-angle-' + (isBlockOpen ? 'down' : 'right')}/>*/}
            {/*    </ActionIconStyled>*/}
            {/*    <span>{field.label}</span>*/}
            {/*</WidgetHeadingStyled>*/}
            {/*{isBlockOpen && renderFormView()}*/}
            {renderFormView()}
        </>
    )
}
