import React from 'react'
import { ButtonStyled, LinkStyled } from './styled'
import { ButtonProps, LinkButtonProps } from './types'

/**
 * The button component renders a simple Button or a Link if there is a URL where to navigate
 */
export const Button: React.FC<ButtonProps> = ({ children, type = 'button', ...props }) => {
    return <ButtonStyled {...props}>{children}</ButtonStyled>
}

export const LinkButton: React.FC<LinkButtonProps> = ({ children, ...props }) => {
    return <LinkStyled {...props}> {'' + children}</LinkStyled>
}
