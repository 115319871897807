import styled, { ThemeProps } from 'styled-components'
import { FormMode } from '../../../components/types'

interface LabelProperties {
    asUppercase: boolean
}

export const LabelStyled = styled.label.attrs((props: LabelProperties) => ({}))<LabelProperties>`
    ${(props) => props.asUppercase && 'text-transform: uppercase;'}
    font-weight: normal !important;
    color: ${(props) => props.theme?.login?.input?.labelColor || '#000'};
    font-size: ${(props) => props.theme?.login?.input?.fontSize || '14px'};
    padding: ${(props) => props.theme?.login?.input?.padding || '0px 0px 4px 10px'};
`

/*
    background-color: ${(props) => props?.theme?.login?.input?.bgColor || props.theme.colors.light};

 */
interface FieldContentStyledProperties {
    formMode?: FormMode
}

export const LoginFieldContentStyled = styled.div.attrs((props: FieldContentStyledProperties) => ({}))<FieldContentStyledProperties>`
    ${(props) => {
        if (props.formMode === FormMode.EDIT || props.formMode === FormMode.NEW) {
            return 'border: 1px solid ' + (props?.theme?.form?.entryBorderColor || props.theme.colors.accentColorMuted) + ' !important; '
        } else {
            return 'border: 1px solid ' + (props?.theme?.form?.viewBorderColor || props.theme.colors.accentColorMuted) + ' !important; '
        }
    }}

    ${(props) => 'margin: ' + (props?.theme?.form?.margin || '2px')};
    ${(props) => 'border-radius: ' + (props?.theme?.form?.borderRadius || '10px')};
    ${(props) => 'padding: ' + (props?.theme?.form?.padding || '5px')};
`

interface SvgStyledProps extends ThemeProps<any> {
    width: string
}

export const UserIconSvgStyled = styled.div.attrs((props: SvgStyledProps) => ({}))<SvgStyledProps>`
    text-align: center;
    margin: 0 auto;

    path,
    circle,
    polygon {
        stroke: ${(props) => props?.theme?.login?.input?.iconColor || props.theme.table.columnColor};
    }
    width: ${(props) => props.width};
`
/*
        fill: ${(props) => props?.theme?.login?.input?.iconColor ||  props.theme.table.columnColor};

 */
