import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { getSettings, performValidation } from '../../../services/ValidationService'
import { getStyle } from '../../../utils/CssUtils'
import { assignReferences, noop, RunOnceEffect } from '../../../utils/Utils'
import { FieldProperties, FormMode } from '../../types'
import FieldLabel from '../field-label/FieldLabel'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { FloatingFormStyled, InputStyled, TextFieldStyled } from '../text/styled'

export const OptionAsyncField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    const [formMode, setFormMode] = useState(properties.formMode)
    // const [formValue, setFormValue] = useState<string>(properties?.data || '');
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) || properties.data || '')
    const valueRef = useRef(properties?.data)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [submitted, setSubmitted] = useState(false)

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setFormValue(properties?.data || '')
    }

    useEffect(() => {
        setFormValue(properties?.data || '')
    }, [properties])

    const doValidation = (fieldValue: any): boolean => {
        return performValidation(formMode, properties.menuItem?.code, properties.fieldConfig, fieldValue, setSubmitted, setErrorMessage)
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.fieldConfig.tableCss, '')

        return (
            <TextFieldStyled textAlign={properties.fieldConfig.align}>
                {properties.isNumber && (
                    <div className="text-right" style={style}>
                        {formValue}
                    </div>
                )}
                {!properties.isNumber && (
                    <span className="" style={style}>
                        {formValue}
                    </span>
                )}
            </TextFieldStyled>
        )
    }

    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        // @ts-ignore
        return (
            <>
                {!hideField && (
                    <>
                        <FloatingFormStyled>
                            <InputStyled
                                type={properties?.fieldConfig?.label === 'Password' ? 'password' : 'text'}
                                id={properties?.fieldConfig?.name || 'def'}
                                ref={valueRef}
                                value={formValue}
                                autoFocus={properties.isFirstField}
                                readOnly={disableField}
                                disabled={disableField}
                                // onChange={(e) => onDataInputChange(e.target.value)}
                                required={required}
                                submitted={submitted}
                                noValue={!formValue}
                            />
                            <FieldLabel properties={properties} />
                            {submitted && errorMessage && <small className="yellow">{errorMessage}</small>}
                        </FloatingFormStyled>
                    </>
                )}
            </>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
