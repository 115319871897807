import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EnvironmentContext } from '../../providers/environment/EnvironmentContext'
import { SessionContext } from '../../providers/session/SessionContext'
import { arrayIsEmpty } from '../../utils/TypeCheckers'
import { ChevronIconStyled, ChildItemStyled, ChildItemWrapperStyled, ExpandableListItemWrapperStyled, ItemLabelStyled } from './styled'
import { ListItem } from './types'

interface ExpandableListItemProperties {
    item: ListItem
    depth: number
}

export const ExpandableListItem = ({ item, depth }: ExpandableListItemProperties): React.ReactElement<ExpandableListItemProperties> => {
    const environmentContext = React.useContext(EnvironmentContext)
    const sessionContext = React.useContext(SessionContext)

    const navigate = useNavigate()

    const isMobile = environmentContext.isMobileDevice()
    const [opened, setOpened] = useState<boolean>(item?.open || false)

    const renderChevron = (): React.ReactElement => {
        if (arrayIsEmpty(item.items)) {
            return <></>
        }
        const onIconClick = (e: React.MouseEvent<HTMLElement>) => {
            if (e) {
                e.stopPropagation()
            }
            if (item.items) {
                setOpened(!opened)
            } else if (item.route) {
                sessionContext.setQueryParams(JSON.stringify(item.queryParams))
                navigate('/' + item.route)
            }
        }
        return <ChevronIconStyled isOpen={opened} onClick={(e) => onIconClick(e)}></ChevronIconStyled>
    }

    const renderLabel = (): React.ReactElement => {
        const onRouteClick = (e: React.MouseEvent<HTMLDivElement>) => {
            if (e) {
                e.stopPropagation()
            }
            console.log('route clicked', item.route)
            // if (item.items) {
            //     setOpened(!opened)
            // } else if (item.route) {
            sessionContext.setQueryParams(JSON.stringify(item.queryParams))
            navigate('/' + item.route)
            // }
        }

        return (
            <ItemLabelStyled onClick={(e) => onRouteClick(e)} key={'elil_' + depth}>
                {item.label}
                {renderChevron()}
            </ItemLabelStyled>
        )
    }
    const renderChildren = (): React.ReactElement => {
        if (!item?.items) {
            return <></>
        }
        return (
            <ChildItemWrapperStyled key={'elic_' + depth}>
                {(item?.items || []).map((itm: ListItem, index) => (
                    <ChildItemStyled key={'elicc_' + depth + '_' + index}>
                        <ExpandableListItem item={itm} depth={depth + 1} key={'eli_' + depth + '_' + index} />
                    </ChildItemStyled>
                ))}
            </ChildItemWrapperStyled>
        )
    }

    return (
        <ExpandableListItemWrapperStyled isMobile={isMobile} depth={depth} key={'elix_' + depth}>
            {renderLabel()}
            {opened && renderChildren()}
        </ExpandableListItemWrapperStyled>
    )
}
