import { Modal } from 'react-bootstrap'
import Offcanvas from 'react-bootstrap/Offcanvas'
import styled, { ThemeProps } from 'styled-components'
import { IOS_TOP_STATUS_BAR_HEIGHT, CONTENT_START_POSITION } from '../../../providers/theme/GuslThemeProvider'

interface CardContainerProperties {
    isMobile?: boolean
}

export const CardContainerStyled = styled.div.attrs((props: CardContainerProperties) => ({}))<CardContainerProperties>`
  width: 100%;
  position: relative;
  overflow: hidden;
  display: inline-flex;
  box-sizing: border-box;
  border-bottom: ${(props) => (props.isMobile ? props.theme.form.fieldBorder : 'none')}
}

;

> div {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: inline-flex;
  box-sizing: border-box;
}
`

export const CardWrapperStyled = styled.div.attrs((props) => ({}))`
    height: 100%;
    width: 100%;
`

interface CardContentContainerProperties extends ThemeProps<any> {
    numberOfActions: number
    isLeftExpanded: boolean
    isRightExpanded: boolean
}

export const CardContentContainerStyled = styled.div.attrs((props: CardContentContainerProperties) => ({}))<CardContentContainerProperties>`
    width: 100%;
    display: inline-flex;
    // display: contents; //<- mobile cards
    align-items: center;
    justify-content: space-between;

    //position: absolute;
    //top: 0;
    //left: 0;
    //transition: all 0.25s ease;

    box-sizing: border-box;
    //padding-top: 1rem;
    //padding-bottom: 0.8rem;
    z-index: 2;

    transform: ${(props) => {
        if (props.isLeftExpanded) {
            return 'translateX(' + props.numberOfActions * -100 + 'px)'
        }
        if (props.isRightExpanded) {
            return 'translateX(' + props.numberOfActions * 100 + 'px)'
        }
        return 'translateX(0px)'
    }};
`

export const CardContentStyled = styled.div.attrs((props) => ({}))`
    width: 100%;
    // margin-left: 10px; // removed cut off table
`

export const DotsContainerStyled = styled.div.attrs((props: CardContainerProperties) => ({}))<CardContainerProperties>`
    text-align: center;
    display: flex;
    justify-content: end;
    align-items: end;
`

export const DotsWrapperStyled = styled.div.attrs((props) => ({}))`
    vertical-align: middle;
    text-align: center;
`

export const DotsButtonStyled = styled.button.attrs((props) => ({}))`
    min-width: 30px;
    width: 30px;
    appearance: none;
    border: none;
    box-sizing: border-box;
    color: inherit;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
`

interface CardActionsContainerProperties extends ThemeProps<any> {
    isExpanded: boolean
}

export const CardActionsLeftContainerStyled = styled.div.attrs((props: CardActionsContainerProperties) => ({
    role: 'region',
}))<CardActionsContainerProperties>`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
    display: ${(props) => (props.isExpanded ? 'flex' : 'none')};
`

export const CardActionsRightContainerStyled = styled.div.attrs((props: CardActionsContainerProperties) => ({
    role: 'region',
}))<CardActionsContainerProperties>`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;
    display: ${(props) => (props.isExpanded ? 'flex' : 'none')};
`

export const CardActionWrapperStyled = styled.div.attrs((props) => ({}))`
    height: 100%;
    margin: 5px;
`

export const CardActionButtonStyled = styled.button.attrs((props) => ({
    role: 'button',
}))`
    min-width: 50px;
    appearance: none;
    padding: 0;
    margin: 0;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 100%;
    padding: 5px;

    &:hover {
        text-decoration: underline;
    }
`

export const ActionMenuIconStyled = styled.i.attrs((props) => ({}))`
    width: 24px;
    height: 24px;
`

interface OffCanvasProperties {
    ismobile: string
    isios: string
    footerHeight: number
}

export const OffCanvasStyled = styled(Offcanvas).attrs((props: OffCanvasProperties) => ({
    id: 'offcanvas-styled',
}))<OffCanvasProperties>`
    height: ${(props) =>
        'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.footerHeight + CONTENT_START_POSITION) + 'px)'} !important;
    //max-width: 100% !important;
    background-color: ${(props) => props.theme.panel.panelBgColor};
    // padding: 0px !important;
    position: absolute;
    top: ${(props) => CONTENT_START_POSITION - 2}px !important;
    left: 0;
    width: 100% !important;
    border-left: ${(props) => 'solid 1px  ' + props.theme.colors.dark[0]};

    .offcanvas-body {
        padding: 0;
        overflow: hidden;
    }

    .offcanvas {
        width: 500px !important;
    }
`
export const CardPanelContainerStyled = styled.div.attrs((props) => ({}))`
    // no padding here please
`

export const ModalHeaderStyled = styled(Modal.Header).attrs((props) => ({
    className: 'text-light draggable-dialog-header',
}))`
    background-color: ${(props) => props.theme.panel.titleBgColor} !important;
`
export const ModalBodyStyled = styled(Modal.Body).attrs((props) => ({}))`
    padding: 0;
`

export const CardContentWrapperStyled = styled.div.attrs((props) => ({}))``
