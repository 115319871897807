import React, { useEffect, useState } from 'react'
import { Placement } from 'react-bootstrap/types'
import ElementWithPopover from '../element-with-popover/ElementWithPopover'
import { ElementWithTooltip } from '../element-with-tooltip/ElementWithTooltip'

type Props = {
    text: string
    maxLength?: number
    title?: string
    placement?: Placement | undefined
    as?: 'popover' | 'tooltip'
}
export default function ElementWithShortenedText({ text, maxLength = 30, title, placement, as }: Props) {
    const [className] = useState('ElementWithShortenedText-' + new Date().getTime())
    const shortenedText = text.substring(0, maxLength) + '...'
    const [isShortened, setIsShortened] = useState(false)
    useEffect(() => {
        if (text && text.length > maxLength) {
            setIsShortened(true)
        }
    }, [])

    const More = () => (
        <>
            {as === 'popover' ? (
                <ElementWithPopover
                    trigger={['click']}
                    placement={placement}
                    element={
                        <span role={'button'}>
                            <ElementWithTooltip
                                element={
                                    <>
                                        <span>{shortenedText}</span>
                                        <span className={'green'}>
                                            {' '}
                                            more <i className={'fa-solid fa-circle-info green'} />
                                        </span>
                                    </>
                                }
                                tooltip={<span>Click to see more!</span>}
                            />
                        </span>
                    }
                    popover={
                        <>
                            <div className="px-3 py-1">
                                <span>{title || 'Summary'}</span>
                            </div>
                            <div className=" rounded-0 p-3 ">
                                <div className="">{text}</div>
                            </div>
                        </>
                    }
                />
            ) : (
                <ElementWithTooltip
                    placement={placement ? placement : undefined}
                    element={<span>{shortenedText}</span>}
                    tooltip={<span>{text}</span>}
                />
            )}
        </>
    )

    try {
        if (isShortened) {
            return <More />
        } else {
            return <>{text}</>
        }
    } catch (e) {
        return <>{text}</>
    }
}
