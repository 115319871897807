import styled, { ThemeProps } from 'styled-components'

interface StatusBarBackgroundProperties extends ThemeProps<any> {
    isIOS: boolean
}

export const StatusBarBackgroundStyled = styled.div.attrs((props: StatusBarBackgroundProperties) => ({
    id: 'status-bar',
}))<StatusBarBackgroundProperties>`
    padding-top: env(safe-area-inset-top);
    padding-top: constant(safe-area-inset-top);
    background-color: ${(props) => props.theme.navigation.topNavbarBgColor};
`
