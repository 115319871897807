import { getRandomIntInclusive } from '../../../utils/randomNumber'
import { arrayNotEmpty } from '../../../utils/TypeCheckers'
import { ChartColor, CustomColorsProps, MasterDatasetsDO, SingleDatasetDO, TempBubbleDatasetDO } from '../types'
import applyCustomColors from './applyCustomColors'

import { defaultRadarColors } from './colorsAndGradients'

type Dataset = {
    datasets: Array<{
        backgroundColor: string | string[] | undefined
        borderColor: string | string[] | undefined
        label: string
        labels: Array<string>
        data: Array<number>
        type?: string
        name?: string
        pointRadius?: number | 2
    }>
    name: string
    label: string
    data: [{ [index: string]: { [index: string]: string } }] | Array<number>
    backgroundColor?: string | string[]
    borderColor?: string | string[]
    pointRadius?: number | 2
}
type TransformDatasetsProps = {
    chartType: string | undefined
    datasets: Dataset[]
    // chartFor?: string | undefined,
    singleChart?: string | undefined
    // customCharts?: Array<string> | undefined,
    mixedChart?: Array<{ [index: string]: string }> | undefined
    colors?: typeof CustomColorsProps
    themeColors?: ChartColor[]
}
export default function TransformDatasets({ chartType, datasets, singleChart, mixedChart, colors, themeColors }: TransformDatasetsProps) {
    const className = 'TransformDatasets-' + new Date().getTime()

    // log.info(className,'MSG001->customColors',{colors})
    let masterLabels: Array<string> = []
    let masterDatasets: Array<MasterDatasetsDO> = []

    let singleDataset: Array<SingleDatasetDO> = []
    let singleLabels: Array<string> = []
    let singleData: Array<number> = []
    let singleBackgroundColors: Array<string | string[] | undefined> = []
    let singleBorderColors: Array<string | string[] | undefined> = []

    let tempBubbleData: Array<number> = []
    let tempBubbleLabels: Array<string> = []
    let tempBubbleColors: Array<{
        [index: string]: string | string[] | undefined
    }> = []
    let tempBubbleDatasets: Array<TempBubbleDatasetDO> = []

    let tempRadarData: Array<number> = []

    // log.info(className,'MSG001',{chartType, datasets, singleChart, mixedChart})

    function getMixedChartType(label: string | undefined) {
        try {
            return mixedChart?.filter((item) => item.singleChart === label)[0]['type']
        } catch (e) {
            return 'line'
        }
    }

    /*master|single line, bar, pie, doughnut, polarArea, radar(single) || multiType */
    datasets?.forEach((dataset, idx) => {
        // @ts-ignore
        dataset = applyCustomColors({
            idx,
            // @ts-ignore
            dataset,
            // @ts-ignore
            chartType,
            // @ts-ignore
            colors,
            // @ts-ignore
            themeColors,
        })
        // @ts-ignore
        // log.info(className, 'MSG001', dataset)
        if (chartType === 'multiType') {
            masterLabels = Object.keys(dataset.datasets[0].data[0])
            masterDatasets.push({
                labels: Object.keys(dataset.datasets[0].data[0]),
                data: Object.values(dataset.datasets[0].data[0]),
                label: dataset.label + ':' + dataset.datasets[0].name,
                type: getMixedChartType(dataset.label),
                /*@ts-ignore*/
                // borderColor: '#0000ff'
            })
        }
        if (arrayNotEmpty(dataset.data)) {
            masterLabels = Object.keys(dataset.data[0])
            masterDatasets.push({
                labels: Object.keys(dataset.data[0]),
                data: Object.values(dataset.data[0]),
                label: dataset.name,
                backgroundColor: dataset.backgroundColor,
                borderColor: dataset.borderColor,
                pointRadius: dataset.pointRadius || 2,
            })
        }
    })

    if (chartType === 'multiType') {
        return { transformedDatasets: masterDatasets, labels: masterLabels }
    }

    /*master chart radar*/
    if (chartType === 'radar' && !singleChart) {
        datasets?.forEach((dataset) => {
            if (arrayNotEmpty(dataset.datasets)) {
                dataset.datasets.forEach((single) => {
                    // @ts-ignore
                    const value: number = Object.values(single.data[0]).reduce((prev, curr) => prev + curr)
                    masterLabels.push(dataset.label + ' - ' + single.name + ' (' + value + ') ')
                    tempRadarData.push(value)
                })
            }
        })

        masterDatasets.push({
            labels: masterLabels,
            data: tempRadarData,
            /*@ts-ignore*/
            backgroundColor: (colors && colors['radar']['master']['backgroundColor']) || 'transparent',
            /*@ts-ignore*/
            borderColor: (colors && colors['radar']['master']['borderColor']) || 'transparent',
            /*@ts-ignore*/
            pointBackgroundColor: (colors && colors['radar']['master']['pointBackgroundColor']) || 'green',
            pointRadius: 5,
            pointHoverRadius: 10,
            showLine: false,
        })
        return { transformedDatasets: masterDatasets, labels: masterLabels }
    }
    /*bubble chart*/
    if (chartType === 'bubble') {
        const maxChartHeight = 350

        datasets?.forEach((dataset) => {
            // @ts-ignore
            dataset = applyCustomColors({ dataset, chartType, colors, themeColors })
            if (singleChart && arrayNotEmpty(dataset.data)) {
                tempBubbleLabels.push(dataset.name)
                // @ts-ignore
                tempBubbleData.push(Object.values(dataset.data[0]).reduce((prev, curr) => prev + curr))
                tempBubbleColors.push({
                    backgroundColor: dataset.backgroundColor,
                    borderColor: dataset.borderColor,
                })
            } else if (!singleChart && arrayNotEmpty(dataset.datasets)) {
                dataset.datasets.forEach((single, idx) => {
                    tempBubbleLabels.push(dataset.label + ' - ' + single.name)
                    tempBubbleData.push(Object.values(single.data[0]).reduce((prev, curr) => (prev as number) + (curr as number)))
                    tempBubbleColors.push({
                        backgroundColor: single.backgroundColor,
                        borderColor: single.borderColor,
                    })
                })
            }
        })

        const maxValue: number = Math.max(...tempBubbleData)
        const unitSize: number = maxChartHeight / maxValue

        Array.from({ length: tempBubbleData.length }).forEach((_, idx) => {
            // log.info(className,'MSG001', {tempBubbleColors})
            tempBubbleDatasets.push({
                label: tempBubbleLabels[idx],
                realValue: tempBubbleData[idx],
                data: [
                    {
                        r: (tempBubbleData[idx] * unitSize) / 10,
                        x: getRandomIntInclusive({ min: 0, max: 100 }),
                        y: getRandomIntInclusive({ min: 0, max: maxValue }),
                    },
                ],
                ...tempBubbleColors[idx],
            })
        })
        return {
            transformedDatasets: tempBubbleDatasets,
            labels: tempBubbleLabels,
        }
    }

    if (singleChart) {
        if (chartType === 'line' || chartType === 'bar') {
            return { transformedDatasets: masterDatasets, labels: masterLabels }
        } else {
            if (arrayNotEmpty(masterDatasets)) {
                let _colors = {}
                masterDatasets.forEach((single) => {
                    //log.info(className, 'MSG003-->arcs single', single)
                    _colors = {
                        backgroundColor: single.backgroundColor,
                        borderColor: single.borderColor,
                    }
                    singleBackgroundColors.push(single.backgroundColor)
                    singleBorderColors.push(single.borderColor)
                    const value: number = single.data.reduce((prev: any, curr: any) => prev + curr)
                    chartType === 'radar'
                        ? singleLabels.push(single.label + ' (' + value + ') ')
                        : singleLabels.push(single.label as string)
                    singleData.push(single.data.reduce((prev: any, curr: any) => prev + curr))
                })

                //log.info(className, 'MSG003-->arcs single_colors', _colors)
                singleDataset.push({
                    data: singleData,
                    labels: singleLabels,
                    /*@ts-ignore*/
                    backgroundColor: chartType === 'radar' ? _singleRadarColor('backgroundColor', colors) : singleBackgroundColors,
                    /*@ts-ignore*/
                    borderColor: chartType === 'radar' ? _singleRadarColor('borderColor', colors) : singleBackgroundColors,
                    /*@ts-ignore*/
                    pointBackgroundColor:
                        /*@ts-ignore*/
                        chartType === 'radar' ? _singleRadarColor('pointBackgroundColor', colors) : singleBackgroundColors,
                    pointRadius: 3,
                    pointHoverRadius: 7,
                })
                // log.info(className, 'MSG006', {singleBackgroundColors})
            }
        }
    }

    return singleChart
        ? { transformedDatasets: singleDataset, labels: singleLabels }
        : { transformedDatasets: masterDatasets, labels: masterLabels }
}

function _singleRadarColor(colorName: string, colors: typeof CustomColorsProps) {
    /*@ts-ignore*/
    if (colors && colors['radar'] && colors['radar']['single'] && colors['radar']['single'][colorName]) {
        /*@ts-ignore*/
        return colors['radar']['single'][colorName]
    } else {
        return defaultRadarColors[colorName]
    }
}
