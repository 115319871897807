import React from 'react'
import { ExpandableListItem } from './ExpandableListItem'
import { ListItem } from './types'

interface ExpandableListProperties {
    items: ListItem[]
}

export const ExpandableList = ({ items }: ExpandableListProperties): React.ReactElement<ExpandableListProperties> => {
    return (
        <>
            {(items || []).map((item, idx) => (
                <ExpandableListItem item={item} key={'el_' + idx} depth={0} />
            ))}
        </>
    )
}
