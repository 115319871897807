import React, { useContext, useEffect, useState } from 'react'
import { useAppSelector } from '../../../../../app/hooks'
import { EnvironmentContext } from '../../../../../providers/environment/EnvironmentContext'
import { ADVANCED_SEARCH_CELL_WIDTH, setAdvancedCellWidth } from '../../../../../providers/theme/GuslThemeProvider'
import { cleanWidthFromStyle, getStyle, getWidthFromStyle, isElementOverFlown } from '../../../../../utils/CssUtils'
import { isDefined } from '../../../../../utils/TypeCheckers'
import { FieldConfigDTO, WidgetPanelProperties } from '../../../../types'
import { TopFilterIndividualArrowStyled, TopFilterIndividualStyled, TopFilterStyled } from '../../../maintain-table/styled'
import { TableColumnResizeState } from '../../guslColumnResizeSlice'
import { defaultCellWidth, GuslTableState } from '../../guslTableSlice'
import { InlineEditState } from '../../inline-edit/inlineEditSlice'
import { renderFilterTop } from '../Filters'

interface TableColumnDO {
    idx: number
    fld: FieldConfigDTO
    code: string
    topScrollBarPositionRef?: React.Ref<any>
    disableColumnResize?: boolean
    isIndividual?: boolean | undefined
    data: any
    widgetPanelProperties?: WidgetPanelProperties
}

type NestedLevelType = 'L1' | 'L2' | undefined
export default function TopFilter({
    idx,
    fld,
    code,
    topScrollBarPositionRef,
    disableColumnResize,
    isIndividual,
    data,
    widgetPanelProperties,
}: TableColumnDO) {
    const environmentContext = useContext(EnvironmentContext)
    const _columnResizeState: TableColumnResizeState = useAppSelector((state) => state.guslColumnResizeSlice[code])
    const state: GuslTableState = useAppSelector((state) => state.guslTableSlice[code])
    const inlineEditState: InlineEditState = useAppSelector((state) => state.inlineEditSlice[code])
    const inlineEdit: boolean = inlineEditState.inlineEdit

    // MK 26/08/2023 need to know which level is cascading table in for filter css properties ...
    // not sure if this is the most elegant solution...;-)
    // if there will > 2 levels, => need to revisit this logic...
    const isNested: boolean = state.isCascadingTable
    const [nestedLevel, setNestedLevel] = useState<NestedLevelType>(
        !isNested ? undefined : state.conditionalRowDetails?.expandUrl.includes('Level2') ? 'L1' : 'L2'
    )

    // END OF 26/08/2023 need to know which level is cascading table in for filter css properties ...

    // MK 19/08/2023
    const individualAdvancedSearchFields: string[] = state.individualAdvancedSearchFields
    const showAsIndividual: boolean | undefined = isIndividual && individualAdvancedSearchFields.includes(fld.name)
    // console.log({showAsIndividual, field:fld.name,individualAdvancedSearchFields})
    // END OF 19/08/2023

    // MK 18/09/2023 reverted back to state?.showTopFilters
    // because this TopFilter is used for individual as well as for all top filters
    // and it was showing as one of the top filters as not individual but part of all...
    const showTopTableFilters = state?.showTopFilters
    // const showTopTableFilters = fld.advancedFilter || false;
    const currentlyResizingHeaderName: string | undefined = _columnResizeState.resizeColumnFieldName
    const [colCssStyle] = useState<{
        [id: string]: any
    }>(cleanWidthFromStyle(fld.colStyle))
    const headerId: string = (isIndividual ? 'ind_' : '') + 'top_filter_hdr_' + fld.name + '_' + code

    // MK 19/08/2023 in the list view that is not overflown, we are setting width of filter according to list header width
    const listHeaderId: string = 'hdr_' + fld.name + '_' + code
    // console.log({listHeaderId})
    const listHeaderElement = document.getElementById(listHeaderId)
    const listHeaderWidth = listHeaderElement?.offsetWidth
    const tableWrapperId = 'table_wrapper_' + code
    const tableWrapperDiv = document.getElementById(tableWrapperId)
    const overFlownX = isElementOverFlown({
        element: tableWrapperDiv,
        direction: 'x',
    })

    // Get the position of the element
    const rect = listHeaderElement?.getBoundingClientRect()
    // MK 24/08/2023 on cascading tables todo need to adjust offsetTop..., need to consider that user can scroll and then click on filter icon...

    const offsetTop: number = 150
    const offsetTopNested: number = 40

    const offsetRightL1: number = !overFlownX ? 50 : 40
    const offsetRightL2: number = !overFlownX ? 50 : 90

    const offsetRightMap: {
        [level: string]: number
    } = {
        L1: offsetRightL1,
        L2: offsetRightL2,
        undefined: 0,
    }

    // Calculate the position for the new element
    const [newElementTop, setTop] = useState((rect?.top as number) - offsetTop) // Adjust as needed
    const [newElementRight, setRight] = useState(window.innerWidth - (rect?.right as number)) // Adjust as needed

    /*{
        "x": rect?.x,
        "y": rect?.y,
        "width": rect?.width,
        "height": rect?.height,
        "top": rect?.top,
        "right": rect?.right,
        "bottom": rect?.bottom,
        "left": rect?.left,
    }*/

    useEffect(() => {
        //  console.log({expandUrl: state, isNested, nestedLevel, off: offsetRightMap[nestedLevel as string]})

        setTop(isNested ? -offsetTopNested : (rect?.top as number) - offsetTop)
        setRight(window.innerWidth - (rect?.right as number) - offsetRightMap[nestedLevel as string])
        // console.log({
        //     field:fld.name,
        //     innerWidth:window.innerWidth,
        //     "x": rect?.x,
        //     "y": rect?.y,
        //     "width": rect?.width,
        //     "height": rect?.height,
        //     "top": rect?.top,
        //     "right": rect?.right,
        //     "bottom": rect?.bottom,
        //     "left": rect?.left,
        // })
    }, [rect])

    // END OF 19/08/2023
    //console.log({newElementTop,rect})
    const isMobile = environmentContext.isMobileDevice(widgetPanelProperties)
    const [cellWidth, setCellWidth] = useState<number | string | undefined>(() => {
        if (fld.tableCss && (fld.tableCss.indexOf('width:100%') >= 0 || fld.tableCss.indexOf('width: 100%') >= 0)) {
            return undefined
        } else {
            return (
                _columnResizeState.resizedColumns[fld.name] ||
                getWidthFromStyle(fld.style) ||
                getWidthFromStyle(getStyle(fld.tableCss)) ||
                fld.cellWidth ||
                defaultCellWidth
            )
        }
    })
    useEffect(() => {
        if (fld.tableCss && (fld.tableCss.indexOf('width:100%') >= 0 || fld.tableCss.indexOf('width: 100%') >= 0)) {
            setCellWidth(undefined)
        } else {
            setCellWidth(
                _columnResizeState.resizedColumns[fld.name] ||
                    getWidthFromStyle(fld.style) ||
                    getWidthFromStyle(getStyle(fld.tableCss)) ||
                    fld.cellWidth ||
                    defaultCellWidth
            )
        }
    }, [_columnResizeState.resizedColumns[fld.name]])

    // MK 23/08/2023
    const cellWidthToUse: number | string | undefined = !overFlownX
        ? ADVANCED_SEARCH_CELL_WIDTH
        : showAsIndividual
        ? ADVANCED_SEARCH_CELL_WIDTH
        : setAdvancedCellWidth(cellWidth, showTopTableFilters, showAsIndividual, fld.sortable, true, fld.type, inlineEdit)

    if (!fld.displayInTable) return <></>

    const filterProps: any = {
        key: 'hdr_' + headerId,
        id: headerId,
        beingResized: currentlyResizingHeaderName === fld.name,
        resizing: isDefined(currentlyResizingHeaderName),
        disableColumnResize: disableColumnResize,
        sortable: fld.sortable,
        style: colCssStyle,
        cellWidth: cellWidthToUse,
    }
    const individualFilterProps: any = {
        ...filterProps,
        top: newElementTop,
        right: newElementRight,
    }

    if (showAsIndividual) {
        return (
            <TopFilterIndividualStyled {...individualFilterProps}>
                <div>{renderFilterTop(fld, idx, code, data, widgetPanelProperties)}</div>
                <TopFilterIndividualArrowStyled className={'fa-solid fa-caret-down'} />
            </TopFilterIndividualStyled>
        )
    } else if (showTopTableFilters) {
        return (
            <TopFilterStyled {...filterProps}>
                <div>{renderFilterTop(fld, idx, code, data, widgetPanelProperties)}</div>
            </TopFilterStyled>
        )
    } else {
        return (
            <TopFilterStyled>
                <div>&nbsp;</div>
            </TopFilterStyled>
        )
    }
}
