import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { EnvironmentContext } from '../../../../../providers/environment/EnvironmentContext'
import { GuslThemeContext } from '../../../../../providers/theme/GuslThemeProvider'
import { RangeQueryDTO } from '../../../../types'
import Icon from '../../../maintain-table/bootstrap/Icon'
import { GuslTableState } from '../../guslTableSlice'
import { GuslTableQueryParamState, handleRangeQueries, resetSingleDateRange } from '../../queryParamsSlice'
import { FilterTypeWrapperStyled, WidgetHeadingStyled } from '../../styled/side-panel/styled'
import { ActionIconStyled } from '../../styled/table-controls/styled'
import {
    DateRangeUnitDO,
    DateType,
    DispatchRangeQueryHandlerDO,
    IndividualDateRangeFilterDO,
    PastFutureDateRangeUnitDO,
    RelativeDateParamsDO,
} from '../types'
import DateRangeSelector from './date-range-selector/DateRangeSelector'
import CalendarItemTooltip from './date-range-selector/selector-content/CalendarItemTooltip'
import { pastFutureRangeUnits } from './date-range-selector/selector-content/textUnits'
import { triggerElementStyle, wrapperStyle } from './date-range-selector/styles'
import DateRangeUnit from './DateRangeUnit'
import PaginationBtn from './PaginationBtn'
import { CustomDateStyled, CustomDatesWrapperStyled, DateRangeUnitStyled } from '../../styled/date-range/styled'
import { ElementWithTooltip } from '../../../element-with/element-with-tooltip/ElementWithTooltip'

export default function IndividualDateRangeFilter({ field, code, side }: IndividualDateRangeFilterDO) {
    const [className] = useState('IndividualDateRangeFilter-' + new Date().getTime())
    const dispatch = useAppDispatch()
    const hasPast = field?.hasPast
    const hasFuture = field?.hasFuture
    // MK 11/08/2023
    const state: GuslTableState = useAppSelector((state) => state.guslTableSlice[code])
    const queryParamsState: GuslTableQueryParamState = useAppSelector((state) => state.queryParamsSlice[code])
    // MK 22/08/2023 when opening and closing individual filter, we need to preserve the values
    const currentRange: RangeQueryDTO[] | undefined = queryParamsState.queryParams.rangeQueries?.filter(
        (rangeQuery) => rangeQuery.field === field.name
    )
    const showTopTableFilters = state?.showTopFilters
    const [isOpen, setIsOpen] = useState<boolean>(showTopTableFilters || false)
    const [currentUnit, setCurrentUnit] = useState<string>('')
    const [currentNum, setCurrentNum] = useState<number>(0)
    const [customDatesSelected, setCustomDatesSelected] = useState<boolean>(false)
    const guslThemeContext = useContext(GuslThemeContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const isMobile: boolean = environmentContext.isMobileDevice()
    const [themeColors] = useState(guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()))
    const isRightActive: boolean = hasFuture ? hasFuture : currentNum > 0
    const isLeftActive: boolean = hasPast ? hasPast : currentNum < 0
    const [units] = useState<DateRangeUnitDO[]>(field.units || [])
    const serverRangeQueries: RangeQueryDTO[] | undefined = state.serverRangeQueries
    const _serverRangeQuery: RangeQueryDTO[] = serverRangeQueries ? serverRangeQueries.filter((rQuery) => rQuery.field === field.name) : []
    let serverRangeQuery: RangeQueryDTO | undefined
    const [fromTo, setFromTo] = useState<string>('Date')
    const resetAt: number | undefined = queryParamsState?.queryParams?.resetAt

    useEffect(() => {
        setCurrentUnit('')
        setCurrentNum(0)
    }, [resetAt])

    // MK 22/08/2023 when opening and closing individual filter, we need to preserve the values
    function getDateType(dateString: RangeQueryDTO['from']): DateType {
        try {
            if (typeof dateString === 'string' || typeof dateString === 'number') {
                const date = new Date(dateString)
                // Check if the parsed date is a valid date and the input string is in ISO 8601 format
                if (!isNaN(date.getTime()) && typeof dateString === 'string' && dateString.includes('T')) {
                    return 'date'
                } else if (typeof dateString === 'string' && dateString.includes('now')) {
                    return 'relativeDate'
                }
            } else if (dateString instanceof Date) {
                return 'date'
            }
            return undefined
        } catch (e) {
            return undefined
        }
    }

    function getRelativeDateValues(timeString: string): RelativeDateParamsDO | null {
        // Check if the input starts with 'now'
        if (!timeString.startsWith('now')) {
            return null // Invalid input format
        }

        // Extract the parts of the input string
        const parts: string[] = timeString.split('/')

        if (parts.length !== 2) {
            return null // Invalid input format
        }

        const [prefix, datePart]: string[] = parts

        const isNegative: boolean | null = prefix.includes('+') ? true : prefix.includes('-') ? false : null
        let numberPart: string = prefix.replace('now', '').replace(/[+-]/g, '')
        const currentUnit: RelativeDateParamsDO['currentUnit'] = datePart[0] as RelativeDateParamsDO['currentUnit']

        if (!/^[smhdwMy]$/.test(currentUnit)) {
            return null // Invalid date unit
        }

        let currentNum = parseInt(numberPart)

        if (isNaN(currentNum)) {
            return null // Invalid number
        }

        if (isNegative !== null && currentNum !== 0) {
            currentNum *= isNegative ? -1 : 1
        }

        const direction: RelativeDateParamsDO['direction'] = currentNum > 0 ? 'past' : currentNum === 0 ? 'current' : 'future'

        return { currentNum, direction, currentUnit }
    }

    useEffect(() => {
        if (currentRange && currentRange.length) {
            const from: RangeQueryDTO['from'] = currentRange[0].from
            const to: RangeQueryDTO['to'] = currentRange[0].to
            const typeOfFrom: DateType = getDateType(from)
            const typeOfTo: DateType = getDateType(to)
            if (typeOfFrom === 'date' && typeOfTo === 'date') {
                setCustomDatesSelected(true)
            }
            if (typeOfFrom === 'relativeDate' && typeOfTo === 'relativeDate') {
                setCustomDatesSelected(false)
                const relativeDateValues: RelativeDateParamsDO | null = getRelativeDateValues(from as string)
                if (relativeDateValues) {
                    setCurrentUnit(relativeDateValues.currentUnit)
                    setCurrentNum(relativeDateValues.currentNum)
                }
            }
        }
    }, [queryParamsState.queryParams.rangeQueries])
    // END OF 22/08/2023

    if (_serverRangeQuery.length > 0) {
        serverRangeQuery = _serverRangeQuery[0]
    }

    const textRange: PastFutureDateRangeUnitDO = pastFutureRangeUnits.filter((unit) => unit.unit === currentUnit)[0]

    if (textRange?.textUnits) {
        if (!hasPast) {
            textRange.textUnits = textRange.textUnits.filter((unit) => unit.number <= 0)
        }
        if (!hasFuture) {
            textRange.textUnits = textRange.textUnits.filter((unit) => unit.number >= 0)
        }
    }

    function dispatchRangeQueryHandler({ from, to, unit }: DispatchRangeQueryHandlerDO) {
        dispatch(handleRangeQueries({ code, rangeQuery: { field: field.name, from, to } }))
        setCurrentUnit(unit)
    }

    // MK 14/08/2023
    function resetSingleDateRangeHandler() {
        dispatch(resetSingleDateRange({ code, field: field.name }))
        setCurrentUnit('')
        setCustomDatesSelected(false)
        setCurrentNum(0)
    }

    // END OF 14/08/2023
    function unitsRangeQueryHandler(currentUnit: string, currentNum: number) {
        if (fromTo === 'Start') {
            if (currentNum < 0) {
                dispatchRangeQueryHandler({
                    from: `now+${Math.abs(currentNum)}${currentUnit}/${currentUnit}`,
                    // to:`now+${Math.abs(currentNum)}${currentUnit}/${currentUnit}+4d/d`, /// new extra feature if required
                    unit: currentUnit,
                })
            } else {
                dispatchRangeQueryHandler({
                    from: `now-${currentNum}${currentUnit}/${currentUnit}`,
                    unit: currentUnit,
                })
            }
        } else if (fromTo === 'End') {
            if (currentNum < 0) {
                dispatchRangeQueryHandler({
                    to: `now+${Math.abs(currentNum)}${currentUnit}/${currentUnit}`,
                    unit: currentUnit,
                })
            } else {
                dispatchRangeQueryHandler({
                    to: `now-${currentNum}${currentUnit}/${currentUnit}`,
                    unit: currentUnit,
                })
            }
        } else if (fromTo === 'Date') {
            if (currentNum <= 0) {
                dispatchRangeQueryHandler({
                    from: `now+${Math.abs(currentNum)}${currentUnit}/${currentUnit}`,
                    to: `now+${Math.abs(currentNum) + 1}${currentUnit}/${currentUnit}`,
                    unit: currentUnit,
                })
            } else {
                dispatchRangeQueryHandler({
                    from: `now-${currentNum}${currentUnit}/${currentUnit}`,
                    to: `now-${currentNum - 1}${currentUnit}/${currentUnit}`,
                    unit: currentUnit,
                })
            }
        }

        setCurrentUnit(currentUnit)
        setCurrentNum(currentNum)
        setCustomDatesSelected(false)
    }

    function unitPaginationHandler(direction: string) {
        if (isLeftActive && direction === 'past') {
            setCurrentNum((prev) => prev + 1)
            if (currentUnit !== '') {
                unitsRangeQueryHandler(currentUnit, currentNum + 1)
            }
        } else if (isRightActive && direction === 'future') {
            setCurrentNum((prev) => prev - 1)
            if (currentUnit !== '') {
                unitsRangeQueryHandler(currentUnit, currentNum - 1)
            }
        }
    }

    const handlers: { [index: string]: Function } = {
        unitsRangeQueryHandler,
        dispatchRangeQueryHandler,
        setCurrentUnit,
        setCurrentNum,
        setFromTo,
        setCustomDatesSelected,
    }

    function displayDate(date: string | undefined, fromTo: 'from' | 'to'): string | undefined {
        let _date: string = ''
        try {
            if (date) {
                if (currentUnit === 'h') {
                    _date = date.replace('T', ' ')
                } else {
                    _date = date.split('T')[0]
                }
                return _date
            } else {
                return date
            }
        } catch (e) {
            return date
        }
    }

    const DisplayCustomDates = () => {
        return customDatesSelected ? (
            <CustomDatesWrapperStyled>
                {serverRangeQuery?.from && <CustomDateStyled>{displayDate(serverRangeQuery?.from as string, 'from')}</CustomDateStyled>}
                <CustomDateStyled className={'text-center'}>
                    <Icon icon={'fa-solid fa-arrow-right'} />
                </CustomDateStyled>
                {serverRangeQuery?.to && <CustomDateStyled>{displayDate(serverRangeQuery?.to as string, 'to')}</CustomDateStyled>}
            </CustomDatesWrapperStyled>
        ) : (
            <></>
        )
    }
    const DisplayInputMsg = () => {
        return (
            <div className={'col-10'}>
                {' '}
                {currentNum === 0 && currentUnit === 'd' ? (
                    'Today'
                ) : customDatesSelected ? (
                    <DisplayCustomDates />
                ) : currentUnit ? (
                    'Current'
                ) : (
                    <div>
                        <Icon icon={'fa-solid fa-arrow-down'} /> select unit or select date
                    </div>
                )}
            </div>
        )
    }

    // MK 21/08/2023 showing label on side filters, not on top filters
    return (
        <div className={'mb-2'} role={'button'}>
            {side && (
                <WidgetHeadingStyled
                    active={isOpen}
                    onClick={() => {
                        setIsOpen(!isOpen)
                    }}
                >
                    <ActionIconStyled isMobile={isMobile} active={isOpen}>
                        <Icon icon={'me-2 fa-solid fa-angle-' + (isOpen ? 'down' : 'right')} />
                    </ActionIconStyled>
                    <span>{field.label}</span>
                </WidgetHeadingStyled>
            )}

            {(isOpen || !side) && (
                <FilterTypeWrapperStyled>
                    <div className={'row g-0'}>
                        <div className="col">
                            <DateRangeSelector
                                code={code}
                                trigger={['click']}
                                width={window.screen.availWidth > 600 ? 600 : window.screen.availWidth - 10}
                                style={wrapperStyle(themeColors, window.screen.availWidth < 600)}
                                placement={'auto'}
                                field={field}
                                currentNum={currentNum}
                                currentUnit={currentUnit}
                                handlers={handlers}
                                fromTo={fromTo}
                                element={
                                    <div
                                        className={'d-flex justify-content-between align-items-center'}
                                        style={triggerElementStyle(themeColors)}
                                    >
                                        {currentNum !== 0 && currentUnit ? (
                                            <CalendarItemTooltip
                                                textRange={textRange}
                                                currentNum={currentNum}
                                                currentUnit={currentUnit}
                                                direction={currentNum > 0 ? 'past' : 'future'}
                                                fromTo={fromTo}
                                            />
                                        ) : (
                                            <DisplayInputMsg />
                                        )}
                                        <i className={'fa-regular fa-calendar-days small'} />
                                    </div>
                                }
                            />
                        </div>
                        {currentUnit && (
                            <>
                                <PaginationBtn
                                    isActive={isLeftActive}
                                    direction={'past'}
                                    onClick={() => {
                                        unitPaginationHandler('past')
                                    }}
                                    tooltip={
                                        <CalendarItemTooltip
                                            textRange={textRange}
                                            currentNum={currentNum + 1}
                                            currentUnit={currentUnit}
                                            direction={currentNum + 1 > 0 ? 'past' : 'future'}
                                            fromTo={fromTo}
                                        />
                                    }
                                />
                                <PaginationBtn
                                    isActive={isRightActive}
                                    direction={'future'}
                                    onClick={() => {
                                        unitPaginationHandler('future')
                                    }}
                                    tooltip={
                                        <CalendarItemTooltip
                                            textRange={textRange}
                                            currentNum={currentNum - 1}
                                            currentUnit={currentUnit}
                                            direction={currentNum - 1 > 0 ? 'past' : 'future'}
                                            fromTo={fromTo}
                                        />
                                    }
                                />
                            </>
                        )}
                    </div>
                    <div className="row g-0">
                        {/* MK 14/08/2023  */}
                        {(currentUnit || customDatesSelected) && (
                            <ElementWithTooltip
                                element={
                                    <DateRangeUnitStyled active={false} onClick={resetSingleDateRangeHandler}>
                                        <Icon icon={'fa-solid fa-xmark'} className={'col'} />
                                    </DateRangeUnitStyled>
                                }
                                tooltip={<span>Reset {field.label}</span>}
                            />
                        )}
                        {/* END OF MK 14/08/2023  */}

                        {units.map((unit, idx) => (
                            <DateRangeUnit
                                key={idx}
                                active={currentUnit === unit.unit}
                                onClick={() => {
                                    unitsRangeQueryHandler(unit.unit, currentNum)
                                }}
                                label={unit.label + (currentNum > 1 ? 's' : '')}
                            />
                        ))}
                    </div>
                </FilterTypeWrapperStyled>
            )}
        </div>
    )
}
