import styled, { ThemeProps } from 'styled-components'

interface TradePanelContentProperties extends ThemeProps<any> {
    isMobile?: boolean
    startPos: number
    footerHeight: number
}

export const TradePanelContentStyled = styled.div.attrs((props: TradePanelContentProperties) => ({}))<TradePanelContentProperties>`
    ${(props) =>
        props.isMobile &&
        'height: calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.startPos + props.footerHeight) + 'px)'};
    ${(props) => !props.isMobile && 'height: auto;'};
    ${(props) => props.isMobile && 'overflow: overlay;'};
`
