import * as React from 'react'
import { useContext, useState } from 'react'
import { EnvironmentContext } from '../../../../../../../providers/environment/EnvironmentContext'
import { GuslThemeContext } from '../../../../../../../providers/theme/GuslThemeProvider'
import { ElementWithTooltip } from '../../../../../element-with/element-with-tooltip/ElementWithTooltip'
import {
    CalendarDirectionStyled,
    CalendarItemNumberStyled,
    CalendarItemStyled,
    FromToSwitchItemStyled,
    FromToSwitchStyled,
    TextUnitStyled,
    TextUnitsWrapperStyled,
    UnitsWrapperStyled,
} from '../../../../styled/date-range-selector/styled'
import { DateRangeSelectorContentDO, DateRangeUnitDO, PastFutureDateRangeUnitDO } from '../../../types'
import CustomDatesCalendar from '../../custom-date-calendar/CustomDateCalendar'
import DateRangeUnit from '../../DateRangeUnit'
import CalendarItemTooltip from './CalendarItemTooltip'
import { pastFutureRangeUnits } from './textUnits'

export default function SelectorContent({ code, field, currentUnit, currentNum, handlers, setShow, fromTo }: DateRangeSelectorContentDO) {
    const [className] = useState<string>('SelectorContent-' + new Date().getTime())
    const guslThemeContext = useContext(GuslThemeContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const [themeColors] = useState(guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()))
    const units: DateRangeUnitDO[] = field?.units || []
    const setFromTo = handlers['setFromTo']
    const hasPast = field.hasPast
    const hasFuture = field.hasFuture
    const unitsRangeQueryHandler = handlers['unitsRangeQueryHandler']

    function dispatchFromToRangeQueryHandler(currentNum: number) {
        unitsRangeQueryHandler(currentUnit, currentNum)
        setShow(false)
    }

    const textRange: PastFutureDateRangeUnitDO = pastFutureRangeUnits.filter((unit) => unit.unit === currentUnit)[0]

    return (
        <div className={'row g-0'}>
            <div className="col-md-12 my-2">
                <CustomDatesCalendar handlers={handlers} field={field} setShow={setShow} code={code} />
            </div>
            {/* MK 15/08/2023 range queries must have from and to... */}
            {/* <UnitsWrapperStyled className="col-md-3">
                <FromToSwitchStyled>
                    <ElementWithTooltip
                        element={
                            <FromToSwitchItemStyled isActive={fromTo === 'Start'} onClick={() => {
                                setFromTo('Start')
                            }}>
                                Start
                            </FromToSwitchItemStyled>}
                        tooltip={<span>Search with Start date.</span>}/>
                    <ElementWithTooltip
                        element={<FromToSwitchItemStyled isActive={fromTo === 'End'} onClick={() => {
                            setFromTo('End')
                        }}>
                            End
                        </FromToSwitchItemStyled>}
                        tooltip={<span>Search with End date.</span>}/>
                    <ElementWithTooltip
                        element={<FromToSwitchItemStyled isActive={fromTo === 'Date'} onClick={() => {
                            setFromTo('Date')
                        }}>
                            Date
                        </FromToSwitchItemStyled>}
                        tooltip={<span>Search with Date.</span>}/>

                </FromToSwitchStyled>
            </UnitsWrapperStyled>*/}
            {/* END OF MK 15/08/2023 range queries must have from and to... */}

            <UnitsWrapperStyled className={'offset-md-3 col-md-9 d-flex justify-content-between'}>
                {units.map((unit, idx) => (
                    <DateRangeUnit
                        key={idx}
                        inSelector
                        active={currentUnit === unit.unit}
                        onClick={() => {
                            handlers['setCurrentUnit'](unit.unit)
                        }}
                        label={unit.label + 's'}
                    />
                ))}
            </UnitsWrapperStyled>

            <div
                className="col-md-3"
                style={{
                    borderRight: '0.5px solid ' + themeColors.colors.accentColorMuted,
                }}
            >
                <TextUnitsWrapperStyled>
                    {textRange?.textUnits?.map((unit, idx) => (
                        <ElementWithTooltip
                            key={idx}
                            element={
                                <TextUnitStyled
                                    onClick={() => {
                                        dispatchFromToRangeQueryHandler(unit.number)
                                    }}
                                >
                                    {unit.text}
                                </TextUnitStyled>
                            }
                            tooltip={
                                <span>
                                    {fromTo !== 'Date' ? fromTo : ''} date : {unit.text}
                                </span>
                            }
                        />
                    ))}
                </TextUnitsWrapperStyled>
            </div>
            <div className="col-md-9">
                <div className="row g-0">
                    {!textRange && (
                        <p className="text-center fw-bold mt-3 fs-5" style={{ color: themeColors.colors.accentColorMuted }}>
                            Select one of the units <br />
                            or custom date.
                        </p>
                    )}
                    {textRange && (
                        <>
                            {hasPast && (
                                <div className="col-md-6">
                                    {hasFuture && (
                                        <CalendarDirectionStyled className={'d-sm-none'}>
                                            {fromTo === 'Date' ? 'Past' : 'ago'}
                                        </CalendarDirectionStyled>
                                    )}

                                    <div className={'row g-0 m-2'} style={{ color: themeColors.colors.dark[0] }}>
                                        {Array.from({ length: textRange?.numberOfUnits || 0 }).map((_, idx) =>
                                            textRange.startPastFrom <= idx ? (
                                                <ElementWithTooltip
                                                    key={idx}
                                                    element={
                                                        <CalendarItemStyled
                                                            active={currentNum === idx && currentUnit === textRange.unit}
                                                            onClick={() => {
                                                                dispatchFromToRangeQueryHandler(idx)
                                                            }}
                                                        >
                                                            <CalendarItemNumberStyled>{idx}</CalendarItemNumberStyled>
                                                        </CalendarItemStyled>
                                                    }
                                                    tooltip={
                                                        <CalendarItemTooltip
                                                            textRange={textRange}
                                                            currentNum={idx}
                                                            currentUnit={currentUnit}
                                                            direction={'past'}
                                                            fromTo={fromTo}
                                                        />
                                                    }
                                                />
                                            ) : (
                                                <span key={idx} />
                                            )
                                        )}
                                    </div>
                                    {hasFuture && (
                                        <CalendarDirectionStyled className={'d-none d-sm-block'}>
                                            {fromTo === 'Date' ? 'Past' : 'ago'}
                                        </CalendarDirectionStyled>
                                    )}
                                </div>
                            )}
                            {hasFuture && (
                                <div className="col-md-6">
                                    {hasPast && <CalendarDirectionStyled>{fromTo === 'Date' ? 'Future' : 'In'}</CalendarDirectionStyled>}
                                    <div className={'m-2'}>
                                        {hasFuture && (
                                            <div className={'row g-0'}>
                                                {Array.from({
                                                    length: textRange?.numberOfUnits || 0,
                                                }).map((_, idx) =>
                                                    textRange.startFutureFrom <= idx ? (
                                                        <ElementWithTooltip
                                                            key={idx}
                                                            element={
                                                                <CalendarItemStyled
                                                                    active={currentNum === -idx}
                                                                    onClick={() => {
                                                                        dispatchFromToRangeQueryHandler(-idx)
                                                                    }}
                                                                >
                                                                    <CalendarItemNumberStyled>{idx}</CalendarItemNumberStyled>
                                                                </CalendarItemStyled>
                                                            }
                                                            tooltip={
                                                                <CalendarItemTooltip
                                                                    textRange={textRange}
                                                                    currentNum={idx}
                                                                    currentUnit={currentUnit}
                                                                    direction={'future'}
                                                                    fromTo={fromTo}
                                                                />
                                                            }
                                                        />
                                                    ) : (
                                                        <span key={idx} />
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
