import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { SystemContext } from '../../../providers/system/SystemContext'
import { cancelAbortController } from '../../../utils/Utils'
import { IMenuDTO, QueryParamsDTO, WidgetPanelProperties } from '../../types'
import { GuslTable, GuslTableProperties } from '../gusl-table/GuslTable'
import './MaintainTable.css'
import { maintainTableService } from './MaintainTableService'

type MaintainTableProperties = {
    menuItem?: IMenuDTO | undefined
    hideHeaderPanel?: boolean
    pathParams?: any

    isTabChild?: boolean
    queryParams?: QueryParamsDTO | undefined
    widgetPanelProperties?: WidgetPanelProperties
    fixedHeight?: number
}

const MaintainTable = (props: MaintainTableProperties): React.ReactElement => {
    const location = useLocation()
    const systemContext = React.useContext(SystemContext)
    const [className] = React.useState<string>(() => 'MaintainTable-' + new Date().getTime())
    const [notFound, setNotFound] = useState<boolean>(false)
    const [menuItem, setMenuItem] = useState<IMenuDTO | undefined>(props.menuItem)
    const code = menuItem?.code || className
    const fields = (menuItem && maintainTableService.extractAllFields(menuItem)) || []

    const createProperties = (code: string, menuItem: IMenuDTO | undefined): GuslTableProperties => {
        const tableProps: GuslTableProperties = {
            code: code,
            label: menuItem?.label,
            subLabel: maintainTableService.extractFirstChildTabLabel(menuItem),
            fields: fields,
            selectUrl: maintainTableService.extractSelectAllEndpoint(menuItem),
            tableActions: maintainTableService.extractTableActionsFromMenuItem(menuItem),
            rowActions: maintainTableService.extractRowActionsFromMenuItem(menuItem),
            groupActions: maintainTableService.extractGroupActionsFromMenuItem(menuItem),
            orderByActions: maintainTableService.extractOrderByActionsFromMenuItem(menuItem),
            conditionalRowDetails: maintainTableService.extractExpandableRowConfig(menuItem),
            createConfig: maintainTableService.extractCreateEntityMenu(menuItem),
            firstTab: maintainTableService.extractFirstChildTab(menuItem),
            hideHeaderPanel: props.hideHeaderPanel,
            pathParams: props.pathParams,
            refreshRate: menuItem?.refreshRate || false,
            collectionName: maintainTableService.extractBlastCollectionName(menuItem),
            headerUrl: menuItem?.entityGroup?.headerUrl,
            blastDeltaCommand: maintainTableService.extractBlastDeltaCommand(menuItem),
            highlightDelta: maintainTableService.extractHighlightDelta(menuItem),
            disableTableControls: maintainTableService.extractDisableTableControls(menuItem),
            disableColumnResize: maintainTableService.extractDisableColumnResize(menuItem),
            initialOrderBy: maintainTableService.extractInitialOrderBy(menuItem),
        }
        return tableProps
    }

    const [guslTableProperties, setGuslTableProperties] = useState<GuslTableProperties>(() => {
        return createProperties(code, menuItem)
    })

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setNotFound(false)
        setMenuItem(undefined)
        // let mounted = true;
        let abortController = new AbortController()
        const menu: IMenuDTO | undefined = props.menuItem ? props.menuItem : systemContext.getMenuConfigFromPath(location.pathname)
        if (menu) {
            setMenuItem(menu)
            setGuslTableProperties(createProperties(code, menu))
        } else {
            setNotFound(true)
        }

        return () => {
            cancelAbortController(abortController)
            // mounted = false;
        }
    }, [location.pathname, systemContext])

    const renderTable = (): React.ReactElement => {
        return menuItem ? (
            <GuslTable
                code={code}
                label={menuItem?.label}
                subLabel={maintainTableService.extractFirstChildTabLabel(menuItem)}
                fields={fields}
                selectUrl={maintainTableService.extractSelectAllEndpoint(menuItem)}
                tableActions={maintainTableService.extractTableActionsFromMenuItem(menuItem)}
                rowActions={maintainTableService.extractRowActionsFromMenuItem(menuItem)}
                groupActions={maintainTableService.extractGroupActionsFromMenuItem(menuItem)}
                orderByActions={maintainTableService.extractOrderByActionsFromMenuItem(menuItem)}
                conditionalRowDetails={maintainTableService.extractExpandableRowConfig(menuItem)}
                createConfig={maintainTableService.extractCreateEntityMenu(menuItem)}
                firstTab={maintainTableService.extractFirstChildTab(menuItem)}
                hideHeaderPanel={props.hideHeaderPanel}
                pathParams={props.pathParams}
                refreshRate={menuItem?.refreshRate || false}
                collectionName={maintainTableService.extractBlastCollectionName(menuItem)}
                headerUrl={menuItem?.entityGroup?.headerUrl}
                blastDeltaCommand={maintainTableService.extractBlastDeltaCommand(menuItem)}
                highlightDelta={maintainTableService.extractHighlightDelta(menuItem)}
                disableTableControls={maintainTableService.extractDisableTableControls(menuItem)}
                disableColumnResize={maintainTableService.extractDisableColumnResize(menuItem)}
                initialOrderBy={maintainTableService.extractInitialOrderBy(menuItem)}
                isTabChild={props.isTabChild}
                widgetPanelProperties={props.widgetPanelProperties}
                headerPanels={maintainTableService.extractHeaderPanels(menuItem)}
                fixedHeight={props.fixedHeight || maintainTableService.extractFixedHeight(menuItem)}
            />
        ) : (
            <></>
        )
    }

    const renderNotFound = (): React.ReactElement => {
        console.log('MaintainTable - failed to find page ', location.pathname)
        return <h5 className="p-error">Failed to find page</h5>
    }

    return <>{notFound ? renderNotFound() : renderTable()}</>
}
export default MaintainTable
