import styled, { ThemeProps } from 'styled-components'

export const IFrameWrapperStyled = styled.div.attrs((props) => ({}))``

interface IFrameProperties extends ThemeProps<any> {
    loaded: boolean
}

export const IframeStyled = styled.iframe.attrs((props: IFrameProperties) => ({
    allowFullScreen: true,
    width: '100%',
    height: 1000,
}))<IFrameProperties>`
    // display: ${(props) => (props.loaded ? 'block' : 'none')};
`
