import styled from 'styled-components'

export const ResultsTitleWrapperStyled = styled.div.attrs((props) => ({
    role: 'toolbar',
    id: 'gs_popover_anchor',
    className: 'row g-0 d-flex align-items-center justify-content-between',
}))`
    background-color: ${(props) => props.theme.table.titleBgColor};
    padding: ${(props) => props.theme.table.titlePadding};
    color: ${(props) => props.theme.table.titleColor};
    font-size: ${(props) => props.theme.table.titleFontSize};
`

export const CloseButtonStyled = styled.div.attrs((props) => ({}))`
    justify-content: flex-end !important;
    flex-basis: content;
`

interface ResultsPanelProperties {
    isMobile: boolean
    listStartPos: number
    searchOpen: boolean
    hasOrderByActions: boolean
    footerHeight: number
}

export const ResultsPanelStyled = styled.div.attrs((props: ResultsPanelProperties) => ({}))<ResultsPanelProperties>`
    // --> latest  background-color: ${(props) => props.theme.colors.background[0]};

    // background-color: ${(props) => props.theme.table.panelBgColor};
    // background-color: ${(props) => props.theme.colors.dark[0]};
    // background-color: ${(props) => props.theme.table.panelBgColor};
    // background-color: ${(props) => props.theme.colors.background[1]};

    background-color: #fff;

    height: ${(props) => {
        if (props.isMobile) {
            // return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.listStartPos + 10 - (props.searchOpen ? 40 : 0) - (props.hasOrderByActions ? 33 : 0) + (props.isMobile ? FOOTER_HEIGHT - FOOTER_ADJ : 0)) + 'px)'
            return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.listStartPos + props.footerHeight) + 'px)'
        }
        return (
            'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
            (props.listStartPos + 82 - (props.searchOpen ? 40 : 40) - (props.hasOrderByActions ? 33 : 0)) +
            'px)'
        )
        // return 'var(--internal-height)'
    }};

    overflow: ${(props) => {
        if (props.isMobile) {
            return 'auto'
        }
        return 'auto'
    }};
`

interface ResultListContainerProperties {
    isMobile?: boolean
    listStartPos: number
    footerHeight: number
}

const PAGINATION_BAR_HEIGHT = 50

export const ResultListContainerStyled = styled.div.attrs((props: ResultListContainerProperties) => ({
    id: 'result_list_container',
}))<ResultListContainerProperties>`
    height: ${(props) => {
        return (
            'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
            (props.listStartPos + props.footerHeight + PAGINATION_BAR_HEIGHT) +
            'px)'
        )
    }};

    // vvv portrait
    // min-height: 100%;
`
export const ResultListScrollContainerStyled = styled.div.attrs(
    (props: ResultListContainerProperties) => ({})
)<ResultListContainerProperties>`
    padding: 10px;
`
/*
    // height: ${props => {
    //     // if (props.isMobile) {
    //         return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.listStartPos + props.footerHeight) + 'px)'
    //     // }
    //     // return 'inherit'
    // }};
    // overflow: auto;

 */
export const ResultWrapperStyled = styled.div.attrs((props) => ({}))`
    margin-bottom: 5px;
    cursor: pointer;
`
export const ResultTitleWrapperStyled = styled.div.attrs((props) => ({}))`
    display: inline-block;
`

export const ResultTitleStyled = styled.div.attrs((props) => ({}))`
    font-size: 16px;
    text-decoration: underline;
    padding-bottom: 10px;
    color: ${(props) => props.theme.table.titleBgColor};
    display: inline;
`
export const ResultSummaryStyled = styled.div.attrs((props) => ({}))`
    font-size: 14px;
    padding-bottom: 5px;
    padding-top: 5px;
    color: #000;
`

export const ResultContentStyled = styled.div.attrs((props) => ({}))`
    font-size: 12px;
    padding-bottom: 5px;
    color: #000;
`

export const ResultDateStyled = styled.div.attrs((props) => ({
    className: 'text-muted',
}))`
    font-size: 10px;
`
export const PaginationBarStyled = styled.div.attrs((props) => ({}))`
    // border-top: ${(props) => '1px solid ' + props.theme.table.titleBgColor};
    border-top: 1px solid #605f5f;
`
export const DividerStyled = styled.div.attrs((props) => ({}))`
    width: 50%;
    margin: 0 auto;
    text-align: center;
    // border-bottom: ${(props) => '1px solid ' + props.theme.table.titleBgColor};
    padding-bottom: 20px;
`
export const ResultPageStyled = styled.div.attrs((props) => ({}))`
    border-radius: 5px;
    box-shadow: 10px 10px 50px 10px rgba(0, 0, 0, 0.25), 0 1px 1px 1px rgba(12, 17, 247, 0.15);
`
export const ResultContainerStyled = styled.div.attrs((props) => ({}))`
    padding: 10px;
`
export const ClosedStyled = styled.div.attrs((props) => ({}))`
    padding: 5px;
    color: white;
    background-color: red;
    border-radius: 5px;
    width: 55px;
    display: inline;
    margin-left: 20px;
    font-size: 12px;
`
export const NoResultsStyled = styled.div.attrs((props) => ({}))`
    padding: 10px;
    color: #000;
`
