import React, { useContext, useEffect, useState } from 'react'
import { Subscription } from 'rxjs'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { BlastContext } from '../../../../providers/blast/BlastContext'
import { EnvironmentContext } from '../../../../providers/environment/EnvironmentContext'
import MGMutation from '../../../../providers/session/MGMutation'
import MGQuery from '../../../../providers/session/MGQuery'
import { SessionContext } from '../../../../providers/session/SessionContext'
import { SystemContext } from '../../../../providers/system/SystemContext'
import { MediaType } from '../../../types'
import { ElementWithTooltip } from '../../element-with/element-with-tooltip/ElementWithTooltip'
import paginationService from '../../gusl-table/PaginationService'
import { initQueryParams } from '../../gusl-table/queryParamsSlice'
import Icon from '../../maintain-table/bootstrap/Icon'
import { BalanceStyled, BoxStyled, ErrorStyled, WidgetWrapperStyled } from '../styled_v2'
import BSSpinner from './acuity-widgets/BSSpiner'
import { WatchListDTO, WatchListItemDO } from './types'
import { closeBlastListener, DataState, initDateTable, startBlastListen } from './watch-list/bespokeDataSlice'

export interface BSListDO {
    performPreOrderQuery: Function
    queryRefresher: number
    setWLQueryRefresher: Function
    setCurrentlySelectedFavorites: Function
}

const STORE_CODE: string = 'watchlist'
export default function BSList({ performPreOrderQuery, queryRefresher, setWLQueryRefresher, setCurrentlySelectedFavorites }: BSListDO) {
    const [className] = useState('BSList-' + new Date().getTime())
    const [watchListItems, setWatchListItems] = useState<WatchListItemDO[]>([])
    const sessionContext = useContext(SessionContext)
    const systemContext = React.useContext(SystemContext)
    const blastContext = useContext(BlastContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const [systemReady, setSystemReady] = useState<boolean>(false)

    const _state: DataState = useAppSelector((state) => state.bespokeDataSlice[STORE_CODE])

    const dispatch = useAppDispatch()

    const [mediaType, setMediaType] = useState<MediaType>(environmentContext.getCurrentMediaType(undefined))

    useEffect(() => {
        let loaded = true
        let blastDeltaSubscription: Subscription
        let loaderSubscription = sessionContext.watchSystemReady().subscribe((systemReady: boolean) => {
            setSystemReady(systemReady)
            dispatch(startBlastListen({ code: STORE_CODE }))
        })
        return () => {
            dispatch(closeBlastListener({ code: STORE_CODE }))
        }
    }, [STORE_CODE])

    useEffect(() => {
        let abortController = new AbortController()

        if (systemReady) {
            dispatch(
                initQueryParams({
                    code: STORE_CODE,
                    queryParams: paginationService.blankQueryParam(),
                })
            )

            dispatch(
                initDateTable({
                    code: STORE_CODE,
                    sessionContext: sessionContext,
                    blastContext: blastContext,
                    label: 'Watch List',
                    tableData: [],
                    selectUrl: '/watchlist/paged',
                    tableActions: [],
                    rowActions: [],
                    groupActions: [],
                    orderByActions: [],
                    onRefresh: undefined,
                    mediaType: mediaType,
                })
            )
        }
    }, [systemReady])

    const WatchListRD: WatchListDTO = MGQuery({
        method: 'post',
        endpoint: '/watchlist/paged',
        queryParams: { limit: -1, queryRefresher },
    }) as unknown as WatchListDTO

    // console.log(WatchListRD);

    useEffect(() => {
        if (WatchListRD.isSuccess && WatchListRD.data) {
            setWatchListItems(WatchListRD.data.content)
            setCurrentlySelectedFavorites(WatchListRD.data.content)
        }
    }, [WatchListRD.isSuccess, WatchListRD.data])

    async function removeFromWatchListHandler(watchlistId: string) {
        await MGMutation({
            // @ts-ignore
            sessionContext,
            endpoint: `/watchlist/${watchlistId}/delete`,
            queryParams: {},
        })
            .then((response) => {
                setWLQueryRefresher(new Date().getTime())
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const displayFields: string[] = ['symbol', 'lastPrice', 'change']
    return (
        <WidgetWrapperStyled>
            Watch List
            {WatchListRD.isLoading && !watchListItems.length && (
                <div>
                    <div className={' text-center'}>
                        <div className={' mt-5'}>
                            <BSSpinner isLoading={WatchListRD.isLoading} />
                        </div>
                    </div>
                </div>
            )}
            {WatchListRD.isError && (
                <div>
                    <div className={' text-center'}>
                        <div className={' mt-5'}>
                            <ErrorStyled>Something went wrong!</ErrorStyled>
                        </div>
                    </div>
                </div>
            )}
            {WatchListRD.isSuccess && watchListItems.length > 0 && (
                <BoxStyled>
                    <div className="table-responsive" style={{ height: 650 }}>
                        <table className={'table table-sm'}>
                            <thead className={'sticky-top bg-dark'}>
                                <tr>
                                    <th>
                                        <BalanceStyled>Remove</BalanceStyled>
                                    </th>
                                    <th>
                                        <BalanceStyled>Symbol</BalanceStyled>
                                    </th>
                                    <th>
                                        <BalanceStyled>Last Price</BalanceStyled>
                                    </th>
                                    <th>
                                        <BalanceStyled>% Change</BalanceStyled>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {watchListItems.map((ticker, idx) => (
                                    <tr key={idx} className={' p-0'}>
                                        <td
                                            onClick={() => {
                                                removeFromWatchListHandler(ticker.id)
                                            }}
                                        >
                                            <ElementWithTooltip
                                                element={
                                                    <div>
                                                        <Icon icon={'fa-solid fa-star'} className={'xs-small'} extraClass={'invalid'} />
                                                    </div>
                                                }
                                                tooltip={<span className={'xs-small'}>{'Remove from watchlist'}</span>}
                                            />
                                        </td>
                                        {displayFields.map((field, idx) => (
                                            <td
                                                role={'button'}
                                                key={idx}
                                                className={' py-0 xs-small'}
                                                onClick={() => {
                                                    performPreOrderQuery(ticker, 'NEW', 'bbgTicker')
                                                }}
                                            >
                                                {ticker[field as keyof WatchListItemDO]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </BoxStyled>
            )}
            {WatchListRD.isSuccess && watchListItems.length === 0 && (
                <div className={' mt-5'}>
                    <BoxStyled>
                        <p className="small text-center">No Items In The Watchlist Yet!</p>
                    </BoxStyled>
                </div>
            )}
        </WidgetWrapperStyled>
    )
}
