import { isMobile } from '../../../../utils/TypeCheckers'

export const POLAR_AREA_DEFAULT_OPTIONS = {
    maintainAspectRatio: false,
    borderWidth: 1,
    borderRadius: 0,
    plugins: {
        legend: {
            display: true,
            position: isMobile ? ('bottom' as const) : ('left' as const),
            labels: {
                padding: isMobile ? 5 : 15,
                boxWidth: 8,
                boxHeight: 8,
                usePointStyle: true,
                rotation: 30,
                pointStyle: 'rectRot',
                font: { size: 10 },

                filter: (legendItem: { text: string }, data: { labels: any; datasets: { data: { [x: string]: any } }[] }) => {
                    // First, retrieve the data corresponding to that label
                    const label = legendItem.text
                    const lIndex = data.labels.indexOf(label)
                    // @ts-ignore
                    const newText = data.datasets[0].labels[lIndex]
                    // @ts-ignore
                    const qtd = data.datasets[0].data[lIndex]
                    // Second, mutate the legendItem to include the new text
                    legendItem.text = `${newText}:(${qtd})`
                    // Third, the filter method expects a bool, so return true to show the modified legendItem in the legend
                    return true
                },
            },
        },
    },
    scales: {
        r: {
            ticks: {
                // color: '#9aa9b2',
                showLabelBackdrop: false,
                font: {
                    size: 0,
                },
            },
            pointLabels: {
                display: true,
                centerPointLabels: true,
                font: {
                    size: 10,
                },
            },
        },
    },
}
