import { GuslErrors } from '../components/types'
import { GuslUser, SessionContextProps } from '../providers/session/types'
import { arrayIsEmpty, arrayNotEmpty, isBlank } from '../utils/TypeCheckers'
import { LogMessage } from './types'

export enum LOG_LEVEL {
    DEBUG,
    INFO,
    WARN,
    ERROR,
}

const FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
}

class LogService {
    logLevel: LOG_LEVEL = LOG_LEVEL.DEBUG
    instanceId: string = 'LogService-' + new Date().getTime()

    cacheMessages: LogMessage[] = []

    user: GuslUser = {
        id: 'anon',
        username: 'anon',
    }

    systemLoaded: boolean = false

    sessionContext: SessionContextProps | undefined = undefined

    public constructor() {
        setTimeout(() => this.flushLogs(), 10000)
    }

    public setSystemLoaded() {
        this.systemLoaded = true
    }

    public setSessionContext(sessionContext: SessionContextProps) {
        this.sessionContext = sessionContext
    }

    public setUser(user: GuslUser) {
        if (user) {
            this.user = user
        } else {
            this.user = {
                id: 'anon',
                username: 'anon',
            }
        }
    }

    /**
     * ClassName and no message ids then whole close is ignored
     * if has messageId, then only ignores where is match
     * aim: server can send these to an individual players
     */
    ignoreClasses: { [id: string]: string[] } = {
        App: [],
        AppWrapper: [],
        // EnvironmentProvider: [],
        // BlastService: [],
        // BlastProvider: [],
        //'SessionProvider': [],
        SessionStorage: [],
        // 'SystemProvider': [],
        // DataCache: ['MSG001', 'MSG002', 'MSG010'],
        // EnvironmentProvider: [],
        Login: ['MSG006'],
        MenuService: [],
        MaintainTableDataService: [],
        MaintainTableService: [],
        ReportUtils: [],
        NestedTableField: [],
        Table: [],
        // DateField: [],
        TextField: [],
    }

    private postLogs(cache: LogMessage[]) {
        if (this.sessionContext) {
            this.sessionContext
                .post<any, any>('/ui-logs', {
                    messages: cache,
                })
                .then(() => {})
                .catch((errors: GuslErrors) => {
                    console.error('error', errors)
                })
        }
    }

    public flushLogs() {
        if (this.sessionContext && this.systemLoaded) {
            if (this.cacheMessages.length > 50) {
                // must be in a loop
                this.cacheMessages = []
            } else if (this.cacheMessages.length > 0) {
                const cacheCopy: LogMessage[] = Object.assign([], this.cacheMessages)
                this.cacheMessages = []
                this.postLogs(cacheCopy)
            }
        }
    }

    public setIgnoreClasses(classes: { [id: string]: string[] }) {
        this.ignoreClasses = classes
    }

    private ignoreMessage(className: string, messageId: string): boolean {
        if (isBlank(className)) {
            return false
        }
        const messageIds: string[] = this.ignoreClasses[className.split('-')[0]]
        if (!messageIds) {
            return false
        }
        if (arrayIsEmpty(messageIds)) {
            return true
        }
        return messageIds.includes(messageId)
    }

    public setLogLevel(level: number) {
        this.logLevel = level
    }

    private hasConsole(): boolean {
        return console !== undefined
    }

    private split(className: string): [string, string] {
        const splits = className.split('-')
        if (splits?.length === 2) {
            return [splits[0], splits[1]]
        }
        return [className, '']
    }

    public debug(className: string, messageId: string, ...args: any[]) {
        if (this.hasConsole() && this.logLevel < 1 && !this.ignoreMessage(className, messageId)) {
            const message: any = `%c DEBUG[${new Date().toLocaleDateString('en-GB', FORMAT_OPTIONS)}] [${className}] [${messageId}] `
            const array: Array<any> = Object.assign([], arrayNotEmpty(args) ? args : [])
            array.unshift('background: #2c9c91; color: #fafafa')
            array.unshift(message)
            console.log.apply(console, array)
            const [clazz, instanceId] = this.split(className)
            this.cacheMessages.push({
                userId: this.user.id,
                username: this.user.username,
                level: 'DEBUG',
                className: clazz,
                instanceId: instanceId,
                messageId: messageId,
                args: args,
                localLogTime: new Date().toLocaleDateString('en-GB', FORMAT_OPTIONS),
            })
        }
    }

    public info(className: string, messageId: string, ...args: any[]) {
        this.infoLogging(true, className, messageId, args)
    }

    public infoNoConsole(className: string, messageId: string, ...args: any[]) {
        this.infoLogging(false, className, messageId, args)
    }

    public infoLogging(logConsole: boolean, className: string, messageId: string, ...args: any[]) {
        if (this.hasConsole() && this.logLevel < 2 && !this.ignoreMessage(className, messageId)) {
            const message: any = `%c INFO[${new Date().toLocaleDateString('en-GB', FORMAT_OPTIONS)}] [${className}] [${messageId}] `
            const array: Array<any> = Object.assign([], arrayNotEmpty(args) ? args : [])
            array.unshift('background: #6FA8DC; color: #fafafa')
            array.unshift(message)
            console.log.apply(console, array)
            // try {
            //     console.log(className + ' ' + messageId + ' ' + (args ? JSON.stringify(args) : ''))
            //     /* eslint-disable @typescript-eslint/no-unused-vars */
            // } catch (err) {
            //     // ignore
            // }
            const [clazz, instanceId] = this.split(className)
            this.cacheMessages.push({
                userId: this.user.id,
                username: this.user.username,
                level: 'INFO',
                className: clazz,
                instanceId: instanceId,
                messageId: messageId,
                args: args,
                localLogTime: new Date().toLocaleDateString('en-GB', FORMAT_OPTIONS),
            })
        }
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public warn(className: string, messageId: string, ...args: any[]) {
        if (this.hasConsole() && this.logLevel < 3 && !this.ignoreMessage(className, messageId)) {
            const message: any = `%c WARN[${new Date().toLocaleDateString('en-GB', FORMAT_OPTIONS)}] [${className}] [${messageId}] `
            const array: Array<any> = Object.assign([], arrayNotEmpty(args) ? args : [])
            array.unshift('background: #cf8232; color: #fafafa')
            array.unshift(message)

            console.error.apply(console, array)
            const [clazz, instanceId] = this.split(className)
            this.cacheMessages.push({
                userId: this.user.id,
                username: this.user.username,
                level: 'WARN',
                className: clazz,
                instanceId: instanceId,
                messageId: messageId,
                args: args,
                localLogTime: new Date().toLocaleDateString('en-GB', FORMAT_OPTIONS),
            })
        }
    }

    public error(className: string, messageId: string, ...args: any[]) {
        if (this.hasConsole() && this.logLevel < 4 && !this.ignoreMessage(className, messageId)) {
            const message: any = `%c ERROR[${new Date().toLocaleDateString('en-GB', FORMAT_OPTIONS)}] [${className}] [${messageId}] `
            const array: Array<any> = Object.assign([], arrayNotEmpty(args) ? args : [])
            array.unshift('background:#F44336; color: #fafafa')
            array.unshift(message)
            console.error.apply(console, array)
            try {
                console.error(className + ' ' + messageId + ' ' + (args ? JSON.stringify(args) : ''))
                /* eslint-disable @typescript-eslint/no-unused-vars */
            } catch (err) {
                // ignore
            }

            const [clazz, instanceId] = this.split(className)
            this.cacheMessages.push({
                userId: this.user.id,
                username: this.user.username,
                level: 'ERROR',
                className: clazz,
                instanceId: instanceId,
                messageId: messageId,
                args: args,
                localLogTime: new Date().toLocaleDateString('en-GB', FORMAT_OPTIONS),
            })
        }
    }
}

const log = new LogService()

export { log }

export default LogService
