import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { SessionContext } from '../../../../../providers/session/SessionContext'
import { RunOnceEffect } from '../../../../../utils/Utils'
import TabbedPage from '../../../tabbed-page/TabbedPage'
import { BuySellState, updateBlotterPath } from '../../buySellSlice'
import { BlotterContainerStyled } from './styled'

interface BlotterProperties {
    code: string
}

export const Blotter = (properties: BlotterProperties): React.ReactElement<BlotterProperties> => {
    const sessionContext = React.useContext(SessionContext)
    const dispatch = useAppDispatch()

    const _buySellSlice: BuySellState = useAppSelector((state) => state.buySellSlice[properties.code])
    const [refreshCounter, setRefreshCounter] = useState<number>(0)

    const onTabClicked = (entity: string | undefined, entityId: string | undefined, tabCode: string | undefined) => {
        console.log(`tab clicked /${entity}/${entityId}/${tabCode}`)
        dispatch(
            updateBlotterPath({
                code: properties.code,
                path: `/${entity}/${entityId}/${tabCode}`,
            })
        )
        setRefreshCounter(refreshCounter + 1)
    }

    RunOnceEffect(() => {
        dispatch(
            updateBlotterPath({
                code: properties.code,
                path: '/Blotter/ui/OpenTrades',
            })
        )
        setRefreshCounter(refreshCounter + 1)
    })

    return (
        <BlotterContainerStyled>
            <TabbedPage
                key={'blotter_tp_' + refreshCounter}
                noHeader={true}
                fixedHeight={400}
                path={_buySellSlice.blotterPath}
                queryParams={sessionContext.getQueryParams()}
                onTabClicked={onTabClicked}
            />
        </BlotterContainerStyled>
    )
}
