import React, { useContext, useEffect, useId, useRef, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { SessionContext } from '../../../providers/session/SessionContext'
import { getSettings, validate } from '../../../services/ValidationService'
import { assignReferences, extractExtraFieldProperties, noop, RunOnceEffect } from '../../../utils/Utils'
import { fieldService } from '../../FieldService'
import { ChildReference, FieldProperties, FormMode, MoneyDTO } from '../../types'
import FieldLabel from '../field-label/FieldLabel'
import LoadingSpinner from '../loading-spinner/LoadingSpinner'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled } from '../text/styled'
import {
    AmountContainerStyled,
    ButtonsContainerStyled,
    RadioButtonContainerStyled,
    RadioButtonContentStyled,
    RadioButtonIconStyled,
    RadioButtonLabelStyled,
} from './styled'
import { RiskGainRadioBO, RiskGainRequestDO } from './types'

const CALC_URL = '/sportsbookbet/get-risk-gain'

export const RiskGainRadioField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const uniqueId = useId() // properties?.uniqueId ||
    const sessionContext = useContext(SessionContext)

    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    const [formMode, setFormMode] = useState(properties.formMode)
    const [formValue, setFormValue] = useState<RiskGainRadioBO | undefined>(
        _guslFormState?.getFieldValue(properties) || properties.data || {}
    )
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [submitted, setSubmitted] = useState(false)
    const valueRef = useRef(properties?.data)
    const [loading, setLoading] = useState(false)

    const extraFieldProperties = extractExtraFieldProperties(properties)
    const [childReferences] = useState<Map<string, ChildReference>>(new Map())

    const [selectedButton, setSelectedButton] = useState<string>(
        _guslFormState?.getFieldValue(properties)?.isGain || false ? 'gain' : 'risk'
    )

    const riskFieldConfig = {
        name: 'risk',
        label: 'Risk',
        type: 'money',
        extraFieldProperties: extraFieldProperties,
    }
    const gainFieldConfig = {
        name: 'gain',
        label: 'Gain',
        type: 'money',
        extraFieldProperties: extraFieldProperties,
    }

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setFormValue(properties?.data)
    }

    useEffect(() => {
        setFormValue(properties?.data)
    }, [properties])

    const doValidation = (fieldValue: any): boolean => {
        setSubmitted(true)
        const message = validate(
            properties.menuItem?.code,
            properties.fieldConfig.name,
            properties.fieldConfig.validators,
            'checkbox',
            fieldValue
        )
        if (message) {
            setErrorMessage(message)
            return false
        }
        return true
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const onSubmitted = (): void => {
        console.log('-- onSubmitted')
    }

    const calcRiskGain = (data: RiskGainRadioBO) => {
        setLoading(true)
        sessionContext
            .post<RiskGainRequestDO, RiskGainRequestDO>(CALC_URL, {
                odds: _guslFormState?.getValueForField('odds'),
                risk: data.risk,
                gain: data.gain,
                useGain: data.useGain,
            } as RiskGainRequestDO)
            .then((response) => {
                const latest = {
                    risk: response.data.risk,
                    gain: response.data.gain,
                    useGain: data.useGain,
                }
                setFormValue(latest)
                properties.onChange(properties.fieldConfig.name, latest)

                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                console.error('Error in getting header', error)
            })
    }

    const renderTableView = (): React.ReactElement => {
        return (
            <>
                {selectedButton === 'risk' && fieldService.getTableField(riskFieldConfig, properties.menuItem, formValue)?.render()}
                {selectedButton === 'gain' && fieldService.getTableField(gainFieldConfig, properties.menuItem, formValue)?.render()}
            </>
        )
    }

    const onRadioClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, type: string) => {
        setSelectedButton(type)
        const data = {
            odds: _guslFormState?.getValueForField('odds'),
            risk: _guslFormState?.getFieldValue(properties).risk as MoneyDTO,
            gain: _guslFormState?.getFieldValue(properties).gain as MoneyDTO,
            useGain: selectedButton === 'gain',
        }
        calcRiskGain(data)
    }

    const renderRadioButton = (label: string, type: string): React.ReactElement => {
        return (
            <RadioButtonContainerStyled>
                <RadioButtonIconStyled
                    active={selectedButton === type}
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onRadioClick(e, type)}
                />
                <RadioButtonLabelStyled onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onRadioClick(e, type)}>
                    {label}
                </RadioButtonLabelStyled>
            </RadioButtonContainerStyled>
        )
    }

    const getCurrentRowData = () => {
        return properties.data
    }
    const registerChildReference = (reference: ChildReference) => {
        childReferences.set(reference.name, reference)
    }

    const onChangeHandler = (name: string, value: any): void => {
        const gain = name === 'gain' ? value : formValue?.gain
        const risk = name === 'risk' ? value : formValue?.risk

        const latest = {
            risk: risk,
            gain: gain,
            useGain: selectedButton === 'gain',
        }
        setFormValue(latest)
        properties.onChange(properties.fieldConfig.name, latest)
        // calcRiskGain(latest)
    }

    const renderMoneyEntry = (): React.ReactElement => {
        const fieldData = formValue ? formValue[selectedButton === 'risk' ? 'risk' : 'gain'] : undefined
        const fieldConfig = selectedButton === 'risk' ? riskFieldConfig : gainFieldConfig

        return (
            <AmountContainerStyled id={'amt_' + selectedButton} key={'amt_' + selectedButton}>
                {fieldService.getFormTemplate({
                    code: properties.code,
                    formMode: formMode,
                    fieldConfig,
                    // menuItem: properties.menuItem,
                    data: fieldData,
                    onSubmitted,
                    onChange: (name: string, value: any) => onChangeHandler(name, value),
                    getCurrentRowData: getCurrentRowData,
                    isDialog: false,
                    menuItem: undefined,
                    reference: {
                        name: fieldConfig.name,
                        displayOrder: 1,
                        register: registerChildReference,
                    },
                    reLoad: properties.reLoad,
                    inline: true,
                })}
            </AmountContainerStyled>
        )
    }

    const renderRiskGain = (): React.ReactElement => {
        const editable = formMode === FormMode.NEW || formMode === FormMode.EDIT
        if (!editable) {
            return renderTableView()
        }

        return (
            <ButtonsContainerStyled>
                <RadioButtonContainerStyled>
                    {renderRadioButton('Risk', 'risk')}
                    {renderRadioButton('Gain', 'gain')}
                </RadioButtonContainerStyled>
                <RadioButtonContentStyled>
                    {loading && <LoadingSpinner />}
                    {!loading && renderMoneyEntry()}
                </RadioButtonContentStyled>
            </ButtonsContainerStyled>
        )
    }
    const renderFormView = (): React.ReactElement => {
        const onDataInputChange = (value: string) => {}
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        if (!hideField && properties.inline) {
            return <></>
        }

        return (
            <>
                {!hideField && (
                    <>
                        <FloatingFormStyled>
                            <FieldContentWrapperStyled>
                                <FieldContentStyled formMode={formMode}>{renderRiskGain()}</FieldContentStyled>
                                {submitted && errorMessage && <small className="yellow">{errorMessage}</small>}
                            </FieldContentWrapperStyled>
                            <FieldLabel properties={properties} />
                        </FloatingFormStyled>
                    </>
                )}
            </>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
