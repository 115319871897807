import styled, { ThemeProps } from 'styled-components'
import { SaveRowsInfoMessageDO } from './SaveRowsInfoMessage'
import { noop } from '../../../../utils/Utils'

interface InfoIconProps extends ThemeProps<any> {
    level: SaveRowsInfoMessageDO['level']
    icon: string
    onClick?: Function | undefined
}

interface MessageWrapperProps extends ThemeProps<any> {
    level: SaveRowsInfoMessageDO['level']
}

interface InfoMessageProps extends ThemeProps<any> {}

interface InfoTitleProps extends ThemeProps<any> {}

export const MessageWrapperStyled = styled.div.attrs((props: MessageWrapperProps) => ({
    className: 'text-center',
}))<MessageWrapperProps>`
    border: 2px solid
        ${(props) => {
            switch (props.level) {
                case 'initial':
                    return props.theme.action.colors.newAction
                case 'loading':
                    return props.theme.action.colors.refresh
                case 'success':
                    return props.theme.action.colors.refresh
                case 'noRows':
                    return props.theme.action.colors.warning
                case 'error':
                    return props.theme.action.colors.delete
                default:
                    return 'inherit'
            }
        }};
    border-radius: 5px;
    padding: 5px 10px;
`
export const InfoIconStyled = styled.i.attrs((props: InfoIconProps) => ({
    className: props.icon,
}))<InfoIconProps>`
    color: ${(props) => {
        switch (props.level) {
            case 'initial':
                return props.theme.action.colors.newAction
            case 'loading':
                return props.theme.action.colors.refresh
            case 'success':
                return props.theme.action.colors.refresh
            case 'noRows':
                return props.theme.action.colors.warning
            case 'error':
                return props.theme.action.colors.delete
            default:
                return 'inherit'
        }
    }};
    font-size: 20px;
`

export const InfoTitleStyled = styled.p.attrs((props: InfoTitleProps) => ({}))<InfoTitleProps>`
    color: ${(props) => props.theme.table.headerColor} !important;
    font-size: 14px;
    margin: 10px 0;
`

export const InfoMessageStyled = styled.div.attrs((props: InfoMessageProps) => ({}))<InfoMessageProps>`
    color: ${(props) => props.theme.table.headerColor} !important;
    font-size: 12px;
`
export const SaveIconStyled = styled.i.attrs((props: InfoIconProps) => ({
    className: props.icon,
    onClick: props.onClick ? props.onClick : noop,
}))<InfoIconProps>`
    color: ${(props) => props.theme.colors.accentColor} !important;
    cursor: pointer;
`
