import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BlastProps } from '../../../../providers/blast/BlastContext'
import { SessionContextProps } from '../../../../providers/session/types'
import { constructUrl } from '../../../../utils/Utils'
import { QueryParamsDTO } from '../../../types'
import { ArticlesByDateDTO, DealData, OSSArticleDTO } from '../types'

interface OttoTablesState {
    [id: string]: OttoTableState
}

const initialState: OttoTablesState = {}

export interface OttoTableState {
    code: string
    sessionContext: SessionContextProps | undefined
    blastContext: BlastProps | undefined
    label: string | undefined
    selectUrl: string
    lastQueryParams: QueryParamsDTO | undefined

    recommendations: any[]
    general: ArticlesByDateDTO[]
    currentArticle: OSSArticleDTO | undefined
    popular: OSSArticleDTO[]
    refreshCounter: number
}

export interface OttoTableInitPayload {
    code: string
    sessionContext: SessionContextProps
    blastContext: BlastProps
    label: string | undefined
    selectUrl: string
    lastQueryParams: QueryParamsDTO | undefined
}

export interface OttoTableIncrementRefreshCounterPayload {
    code: string
}

export interface OttoTableSelectedPayload {
    code: string
    currentArticle: OSSArticleDTO
}

export interface OttoTableUnSelectedPayload {
    code: string
}

export interface OttoTableCleanUpPayload {
    code: string
}

const createDefault = (code: string): OttoTableState => {
    return {
        code: code,
        sessionContext: undefined,
        blastContext: undefined,
        label: undefined,
        selectUrl: '',
        recommendations: [],
        general: [],
        popular: [],
        lastQueryParams: undefined,
        currentArticle: undefined,
        refreshCounter: 0,
    }
}
const getState = (state: OttoTablesState, code: string): OttoTableState => {
    let entry: OttoTableState = state[code]
    if (!entry) {
        entry = createDefault(code)
    }
    return entry
}
const loadInitValues = (entry: OttoTableState, values: OttoTableInitPayload, code: string) => {
    entry.code = code
    entry.sessionContext = values.sessionContext
    entry.blastContext = values.blastContext
    entry.label = values.label
    entry.selectUrl = values.selectUrl
    entry.lastQueryParams = values.lastQueryParams
}

interface DataRequest {
    code: string
    url: string
    queryParams: QueryParamsDTO
    sessionContext: SessionContextProps
    abortController: AbortController
    pathParams?: any | undefined
}

interface DataResponse {
    queryParams: QueryParamsDTO | undefined
    recommendations: any[]
    general: ArticlesByDateDTO[]
    currentArticle: OSSArticleDTO
    popular: OSSArticleDTO[]
}

export interface OttoTableDataResponseWrapper {
    code: string
    response: DataResponse
}

export const getOttoTableArticles = createAsyncThunk('otto-table-url', async (request: DataRequest) => {
    const req: any = {
        ...request.pathParams,
    }
    const response = await request.sessionContext.post<QueryParamsDTO, DataResponse>(
        constructUrl(request.url, request.pathParams),
        req,
        request.abortController
    )
    return { code: request.code, response: response?.data || {} }
})

interface DealRequest {
    code: string
    url: string
    sessionContext: SessionContextProps
    abortController: AbortController
    pathParams?: any | undefined
}

export interface DealResponseWrapper {
    code: string
    response: DealData
}

const copyState = (entry: OttoTableState): OttoTableState => {
    const newState = {}
    for (const key in entry) {
        if (entry.hasOwnProperty(key)) {
            // @ts-ignore
            newState[key] = entry[key]
        }
    }
    return newState as OttoTableState
}

const updateData = (inboundState: OttoTableState, response: DataResponse): OttoTableState => {
    const entry: OttoTableState = copyState(inboundState)
    if (response) {
        entry.recommendations = response.recommendations
        entry.general = response.general
        entry.lastQueryParams = response.queryParams
        entry.currentArticle = response.currentArticle
        entry.popular = response.popular || []
    }
    return entry
}

export const ottoTableSlice = createSlice({
    name: 'ottoTableSlice',
    initialState,
    reducers: {
        initOttoTable(state: any, action: PayloadAction<OttoTableInitPayload>) {
            const code = action.payload.code
            const entry: OttoTableState = getState(state, code)
            loadInitValues(entry, action.payload, code)
            state[action.payload.code] = entry
        },
        selectedOttoTableArticle(state: any, action: PayloadAction<OttoTableSelectedPayload>) {
            const code = action.payload.code
            const entry: OttoTableState = getState(state, code)
            entry.currentArticle = action.payload.currentArticle
            entry.refreshCounter = entry.refreshCounter + 1
        },
        unSelectOttoTableArticle(state: any, action: PayloadAction<OttoTableUnSelectedPayload>) {
            const code = action.payload.code
            const entry: OttoTableState = getState(state, code)
            entry.currentArticle = undefined
            entry.refreshCounter = entry.refreshCounter + 1
        },
        cleanUpOttoTables(state, action: PayloadAction<OttoTableCleanUpPayload>) {
            delete state[action.payload.code]
        },
        incrementOttoTableRefreshCounter(state: any, action: PayloadAction<OttoTableIncrementRefreshCounterPayload>) {
            const code = action.payload.code
            const entry: OttoTableState = getState(state, code)
            // entry.selectedArticle = undefined
            entry.refreshCounter = entry.refreshCounter + 1
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getOttoTableArticles.fulfilled, (state: any, action: PayloadAction<OttoTableDataResponseWrapper>) => {
            const entry: OttoTableState = getState(state, action.payload.code)
            state[action.payload.code] = updateData(entry, action.payload.response)
        })
    },
})

export const { initOttoTable, selectedOttoTableArticle, unSelectOttoTableArticle, cleanUpOttoTables, incrementOttoTableRefreshCounter } =
    ottoTableSlice.actions

export default ottoTableSlice.reducer
