import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { BlastProps } from '../../providers/blast/BlastContext'
import { SessionContextProps } from '../../providers/session/types'
import { clone, constructUrl } from '../../utils/Utils'
import paginationService from '../common/gusl-table/PaginationService'
import { PageResponseDTO, QueryParamsDTO, TableRowDTO } from '../types'

interface PagedResponseState {
    [code: string]: InstanceState
}

const initialState: PagedResponseState = {}

export interface InstanceState {
    code: string
    sessionContext: SessionContextProps | undefined
    blastContext: BlastProps | undefined
    selectUrl: string
    lastQueryParams: QueryParamsDTO | undefined
    content: TableRowDTO[]
    loading: boolean
    refreshCounter: number
    infinityScroll: boolean
    hasMoreContent: boolean
    performRefreshCount: number
}

interface InitPayload {
    code: string
    sessionContext: SessionContextProps
    blastContext: BlastProps
    selectUrl: string
    lastQueryParams: QueryParamsDTO | undefined
    infinityScroll?: boolean
}

interface FunctionPayload {
    code: string
}

export interface QueryParamsPayload {
    code: string
    searchString: string | undefined
}

const loadInitValues = (entry: InstanceState, values: InitPayload) => {
    entry.sessionContext = values.sessionContext
    entry.blastContext = values.blastContext
    entry.selectUrl = values.selectUrl
    entry.lastQueryParams = values.lastQueryParams
    entry.infinityScroll = values.infinityScroll || false
    entry.hasMoreContent = true
    entry.refreshCounter = 1
}

interface PagedDataRequest {
    code: string
    abortController: AbortController
    pathParams?: any | undefined
}

export interface PageResponseWrapper {
    code: string
    response: PageResponseDTO
}

export const getPagedData = createAsyncThunk('popular-articles-url', async (request: PagedDataRequest, { getState }) => {
    // @ts-ignore
    const state: PagedResponseState = getState()?.pagedResponseSlice
    let entry: InstanceState = state[request.code]
    const sessionContext: SessionContextProps | undefined = entry.sessionContext

    const queryParams: QueryParamsDTO = entry.lastQueryParams || paginationService.blankQueryParam()
    const url = entry.selectUrl
    const response = await sessionContext?.post<QueryParamsDTO, PageResponseDTO>(
        constructUrl(url, request.pathParams),
        queryParams,
        request.abortController
    )
    return {
        code: request.code,
        response: response?.data || {},
    } as PageResponseWrapper
})

const updateNextPage = (entry: InstanceState) => {
    if (entry.lastQueryParams) {
        entry.lastQueryParams.skip = entry.lastQueryParams.skip + entry.lastQueryParams.limit
    }
}

const performReset = (entry: InstanceState) => {
    if (entry.lastQueryParams) {
        entry.lastQueryParams.skip = 0
        entry.content = []
        entry.loading = true
    }
}

const updateData = (entry: InstanceState, response: PageResponseDTO) => {
    entry.lastQueryParams = response.queryParams
    if ((response?.content?.length || 0) + (response?.queryParams?.skip || 0) >= (response?.queryParams?.limit || 0)) {
        entry.hasMoreContent = false
    }

    const hasShould: boolean = (entry.lastQueryParams?.should?.length || 0) > 0
    if (entry.infinityScroll && !hasShould) {
        entry.content.push(...response.content)
    } else {
        entry.content = response.content
    }
    entry.loading = false
}

const performQueryParamsUpdate = (entry: InstanceState, payload: QueryParamsPayload) => {
    const params: QueryParamsDTO = clone(current(entry.lastQueryParams))
    params.should = [
        {
            field: 'content',
            value: payload.searchString || '',
            fuzzy: true,
        },
    ]
    entry.lastQueryParams = params
    entry.performRefreshCount = entry.performRefreshCount + 1
}

const createDefault = (code: string): InstanceState => {
    return {
        code: code,
        sessionContext: undefined,
        blastContext: undefined,
        selectUrl: '',
        content: [],
        lastQueryParams: undefined,
        refreshCounter: 0,
        loading: false,
        infinityScroll: false,
        hasMoreContent: true,
        performRefreshCount: 0,
    }
}

const getState = (state: PagedResponseState, code: string): InstanceState => {
    let entry: InstanceState = state[code]
    if (!entry) {
        entry = createDefault(code)
    }
    return entry
}

export const pagedResponseSlice = createSlice({
    name: 'pagedResponseSlice',
    initialState,
    reducers: {
        initPagedTable(state: any, action: PayloadAction<InitPayload>) {
            const code = action.payload.code
            const entry: InstanceState = getState(state, code)
            loadInitValues(entry, action.payload)
            state[action.payload.code] = entry
        },
        cleanPagedTable(state: any, action: PayloadAction<FunctionPayload>) {
            delete state[action.payload.code]
        },
        pagedNextPage(state: any, action: PayloadAction<FunctionPayload>) {
            const code = action.payload.code
            const entry: InstanceState = getState(state, code)
            updateNextPage(entry)
        },
        resetQuery(state: any, action: PayloadAction<FunctionPayload>) {
            const code = action.payload.code
            const entry: InstanceState = getState(state, code)
            performReset(entry)
        },
        updatePagedQueryParams(state: any, action: PayloadAction<QueryParamsPayload>) {
            const code = action.payload.code
            const entry: InstanceState = getState(state, code)
            performQueryParamsUpdate(entry, action.payload)
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPagedData.fulfilled, (state: any, action: PayloadAction<PageResponseWrapper>) => {
            const code = action.payload.code
            const entry: InstanceState = getState(state, code)
            updateData(entry, action.payload.response)
        })
    },
})

export const { initPagedTable, cleanPagedTable, pagedNextPage, resetQuery, updatePagedQueryParams } = pagedResponseSlice.actions

export default pagedResponseSlice.reducer
