import React, { MouseEventHandler } from 'react'
import Icon from './Icon'

type Props = {
    label: string
    action: string
    onClick?: MouseEventHandler<HTMLButtonElement>
    extraClass?: string
    icon?: string
}
export default function ActionButton({ onClick, label, action, extraClass, icon }: Props) {
    const actionClass = {
        default: 'text-light',
        warning: 'invalid',
        save: 'ls-green',
        refresh: 'ls-green',
        new: 'text-light',
        update: 'ls-green',
        edit: 'ls-green',
        cancel: 'light_red',
        delete: 'light_red',
    }[action]
    return (
        <button className={'btn btn-sm   px-2 mx-2 action_btn_border ' + actionClass + ' ' + extraClass} onClick={onClick}>
            {icon && (
                <>
                    <Icon icon={icon} className={' me-2 action_icon ' + icon} />
                    <>&nbsp;</>
                </>
            )}
            {label}
        </button>
    )
}

/*
type Props = {
    icon?: string,
    label?: string,
    className?: string,
    onClick?: MouseEventHandler<HTMLSpanElement>
}
export default function ActionButton({icon, label, className, onClick}: Props) {
    return (
        <div role={'button'}
              className={ 'button ' + ( className ? className : ' text-light ')}
              onClick={onClick}>

            {icon && <Icon icon={icon} className={'action_icon'}/>}
            {label && <>&nbsp;{label}</>}
        </div>
    );
}
*/
