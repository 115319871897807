import styled, { ThemeProps } from 'styled-components'

interface PermissionFieldStyledProps extends ThemeProps<any> {
    noValue: boolean
    submitted: boolean
}

export const PermissionFieldStyled = styled.div.attrs((props: PermissionFieldStyledProps) => ({
    className:
        'form-control  mb-1 bg-transparent rounded-0 border-top-0 border-start-0 border-end-0 text-light ' +
        (props.submitted && props.noValue ? 'yellow' : ''),
}))<PermissionFieldStyledProps>`
    height: 760px !important;
    padding-bottom: 40px !important;
`

export const PermissionWrapperStyled = styled.div.attrs((props) => ({
    className: 'row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 row-cols-xxl-6 mt-3 g-3',
}))`
    margin-right: 5px;
`

export const PermissionScrollStyled = styled.div.attrs((props) => ({}))`
    overflow-x: hidden;
    height: 720px;
    //width:85vw;
    // background-color: ${(props) => props.theme.panel.panelBgColor};
`

export const PermissionGroupStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.table.columnColor} !important ;
    font-size: 12px;
    font-weight: bold;
    word-wrap: break-word !important;
    word-break: break-word !important;
    text-align: center;
`

interface PermissionStyledProps extends ThemeProps<any> {
    isRequired: boolean
}

export const PermissionStyled = styled.div.attrs((props: PermissionStyledProps) => ({}))<PermissionStyledProps>`
    padding-left: 10px;
    padding-top: 6px;
    font-size: 10px;
    color: ${(props) => (props.isRequired ? props.theme.table.selectedColor : props.theme.table.columnColor)} !important ;
    font-weight: ${(props) => (props.isRequired ? 'bold' : 'inherit')};
    word-wrap: break-word !important;
    word-break: break-word !important;
`
