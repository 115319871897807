import { NAKED_CHART_OPTIONS } from '../nakedChartOptions'

export const SCATTER_DEFAULT_OPTION = {
    ...NAKED_CHART_OPTIONS,
    ...{
        maintainAspectRatio: false,
        borderWidth: 1,
        plugins: {
            legend: {
                display: false,
            },
        },
    },
}
