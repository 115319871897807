import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import React from 'react'
import { SessionContextProps } from '../../../providers/session/types'
import { clone, constructUrl, safeStream } from '../../../utils/Utils'
import { MatchQueryDTO, PaginationControl, PaginationMode, QueryParamsDTO } from '../../types'
import paginationService from '../gusl-table/PaginationService'
import { GlobalSearchResponseDO, SearchFieldsDTO } from './type'

export interface InitGlobalSearchPayload {
    selectUrl: string
    queryParams?: QueryParamsDTO
    searchFields: SearchFieldsDTO[]
    currentNavigationPath: string
    autoSearchLength: number | undefined
    renderResultDialog?: (item: any) => React.JSX.Element
}

export interface UpdateSearchStringForGlobalSearchPayload {
    searchString: string | undefined
}

export interface ResetQueryParamsForGlobalSearchPayload {}

export interface CloseResultsPayload {}

export interface ToggleFieldForGlobalSearchPayload {
    field: SearchFieldsDTO
}

export interface RowsPerPageChangedPayload {
    rowsPerPage: number
}

export interface PaginationModeChangePayload {
    mode: PaginationMode
}

export interface OpenResultDialogPayload {
    item: GlobalSearchResponseDO
}

export interface CloseResultDialogPayload {}

export interface ToggleFuzzyPayload {}

export interface GlobalSearchState {
    selectUrl: string
    queryParams: QueryParamsDTO
    origSearchFields: SearchFieldsDTO[]
    searchFields: SearchFieldsDTO[]
    results: GlobalSearchResponseDO[]
    refreshCount: number
    loaded: boolean
    resultPanelOpened: boolean
    selectedFields: SearchFieldsDTO[]
    origQueryParams: QueryParamsDTO
    currentNavigationPath: string
    paginationControl: PaginationControl
    dialogOpened: boolean
    dialogItem: GlobalSearchResponseDO | undefined
    autoSearchLength: number | undefined
    refreshSearchRequestCounter: number
    renderResultDialog?: (item: any) => React.JSX.Element
}

const initialState: GlobalSearchState = {
    selectUrl: '',
    queryParams: paginationService.blankQueryParam(),
    origQueryParams: paginationService.blankQueryParam(),
    searchFields: [],
    selectedFields: [],
    origSearchFields: [],
    results: [],
    refreshCount: 0,
    loaded: false,
    resultPanelOpened: false,
    currentNavigationPath: '',
    paginationControl: {
        firstActive: false,
        prevActive: false,
        nextActive: false,
        lastActive: false,
        total: -1,
        from: 0,
        to: 0,
        hasPagination: false,
        hasActiveButtons: true,
    },
    dialogOpened: false,
    dialogItem: undefined,
    autoSearchLength: undefined,
    refreshSearchRequestCounter: 0,
}

const updateQueryParamsWithShouldValues = (searchFields: SearchFieldsDTO[], searchValue: string | undefined): MatchQueryDTO[] => {
    const shoulds: MatchQueryDTO[] = []
    if (searchValue) {
        safeStream(searchFields)
            .filter((field: SearchFieldsDTO) => field.selected)
            .forEach((field: SearchFieldsDTO) => {
                if (field.field) {
                    shoulds.push({
                        field: field.field,
                        value: searchValue || '',
                        fuzzy: true,
                    })
                }
            })
    }
    return shoulds
}
const setDisplayIndicatorOnSelectedFields = (searchFields: SearchFieldsDTO[]): SearchFieldsDTO[] => {
    const displayMap: { [id: string]: SearchFieldsDTO } = {}
    safeStream(searchFields)
        .sort((a: SearchFieldsDTO, b: SearchFieldsDTO) => {
            if (a.selected && !b.selected) {
                return 1
            } else if (b.selected && !a.selected) {
                return -1
            }
            return a.displayOrder - b.displayOrder
        })
        .forEach((searchField: SearchFieldsDTO) => {
            const cloned = clone(searchField)
            let selectable = true

            // we want to check, that this search field is not in a previous disabled field list
            Object.keys(displayMap).forEach((id: string) => {
                const entry: SearchFieldsDTO = displayMap[id]
                if (entry?.disableFields?.includes(entry.label)) {
                    selectable = false
                    cloned.selected = false
                }
            })
            cloned.selectable = selectable
            displayMap[cloned.label] = cloned
        })
    return Object.values(displayMap).sort((a, b) => a.displayOrder - b.displayOrder)
}
const performInitGlobalSearch = (state: GlobalSearchState, values: InitGlobalSearchPayload) => {
    state.selectUrl = values.selectUrl
    state.searchFields = setDisplayIndicatorOnSelectedFields(values.searchFields)
    state.selectedFields = getSelectedFields(state.searchFields)
    state.queryParams = values.queryParams || paginationService.blankQueryParam()
    state.paginationControl = paginationService.updatePaginatorControl(state.queryParams, state.queryParams.total || 0)
    state.origQueryParams = values.queryParams || paginationService.blankQueryParam()
    state.currentNavigationPath = values.currentNavigationPath
    state.refreshCount = state.refreshCount + 1
    state.autoSearchLength = values.autoSearchLength
    state.refreshSearchRequestCounter = 0
    state.renderResultDialog = values.renderResultDialog
}

const createFieldMap = (searchFields: SearchFieldsDTO[]): { [id: string]: SearchFieldsDTO } => {
    const displayMap: { [id: string]: SearchFieldsDTO } = {}
    safeStream(searchFields).forEach((searchField: SearchFieldsDTO) => {
        const cloned = clone(searchField)
        displayMap[cloned.label] = cloned
    })
    return displayMap
}

const getSelectedFields = (searchFields: SearchFieldsDTO[]): SearchFieldsDTO[] => {
    return safeStream(searchFields).filter((searchField) => searchField.selected)
}

const performToggleFieldSelection = (state: GlobalSearchState, values: ToggleFieldForGlobalSearchPayload) => {
    const fieldMap: { [id: string]: SearchFieldsDTO } = createFieldMap(state.searchFields)
    const entry = fieldMap[values.field.label]
    if (entry) {
        entry.selected = !values.field.selected
        if (entry.selected) {
            safeStream(entry.disableFields).forEach((fieldLabel: string) => {
                const other = fieldMap[fieldLabel]
                if (other) {
                    other.selected = false
                }
            })
        }
    }
    state.searchFields = Object.values(fieldMap)
    state.selectedFields = getSelectedFields(state.searchFields)
    state.queryParams.should = updateQueryParamsWithShouldValues(state.searchFields, state.queryParams.searchString || '')

    state.refreshCount = state.refreshCount + 1
    state.refreshSearchRequestCounter = state.refreshSearchRequestCounter + 1
}

const performUpdateSearchString = (state: GlobalSearchState, values: UpdateSearchStringForGlobalSearchPayload) => {
    state.queryParams.searchString = values.searchString || ''
    state.queryParams.should = updateQueryParamsWithShouldValues(state.searchFields, values.searchString || '')
    state.queryParams.skip = 0
    state.paginationControl = paginationService.updatePaginatorControl(state.queryParams, state.queryParams.total || 0)
    state.refreshCount = state.refreshCount + 1
    state.refreshSearchRequestCounter = state.refreshSearchRequestCounter + 1
}

const performRowsPerPageChange = (state: GlobalSearchState, values: RowsPerPageChangedPayload) => {
    state.queryParams.limit = values.rowsPerPage
    state.queryParams.skip = 0
    state.refreshCount = state.refreshCount + 1
    state.refreshSearchRequestCounter = state.refreshSearchRequestCounter + 1
}

const performPaginationModeChange = (state: GlobalSearchState, values: PaginationModeChangePayload) => {
    const queryParams: QueryParamsDTO = paginationService.updatePagination(state.queryParams, state.paginationControl, values.mode)
    state.queryParams = queryParams
    state.paginationControl = paginationService.updatePaginatorControl(queryParams, queryParams.total || 0)
    state.refreshCount = state.refreshCount + 1
    state.refreshSearchRequestCounter = state.refreshSearchRequestCounter + 1
}

const performReset = (state: GlobalSearchState) => {
    state.selectedFields = state.origSearchFields
    state.selectedFields = getSelectedFields(state.searchFields)
    state.queryParams = state.origQueryParams
    state.paginationControl = paginationService.updatePaginatorControl(state.queryParams, state.queryParams.total || 0)
    state.refreshCount = state.refreshCount + 1
    state.resultPanelOpened = false

    // state.refreshSearchRequestCounter = state.refreshSearchRequestCounter + 1
}

const performOpenDialog = (state: GlobalSearchState, values: OpenResultDialogPayload) => {
    state.dialogOpened = true
    state.dialogItem = values.item
    state.refreshCount = state.refreshCount + 1
}

const performCloseDialog = (state: GlobalSearchState, values: CloseResultDialogPayload) => {
    state.dialogOpened = false
    state.dialogItem = undefined
    state.refreshCount = state.refreshCount + 1
}

const performToggleFuzzy = (state: GlobalSearchState, values: ToggleFuzzyPayload) => {
    state.queryParams.fuzzy = !state.queryParams.fuzzy
    state.refreshSearchRequestCounter = state.refreshSearchRequestCounter + 1
}

interface GlobalSearchRequest {
    url: string
    queryParams: QueryParamsDTO
    sessionContext: SessionContextProps
    abortController: AbortController
}

export interface GlobalSearchResponseContent {
    content: GlobalSearchResponseDO[]
    queryParams?: QueryParamsDTO
    total: number
}

export interface GlobalSearchResponseWrapper {
    response: GlobalSearchResponseContent
}

export const getGlobalSearchResults = createAsyncThunk('global-search-url', async (request: GlobalSearchRequest) => {
    const response = await request.sessionContext.post<QueryParamsDTO, GlobalSearchResponseContent>(
        constructUrl(request.url, {}),
        request.queryParams,
        request.abortController
    )
    return { response: response?.data || {} }
})

const performUpdateResults = (state: GlobalSearchState, values: GlobalSearchResponseContent) => {
    state.queryParams = values.queryParams || paginationService.blankQueryParam()
    state.results = values.content
    state.resultPanelOpened = true

    state.queryParams.searchString = ''
    state.queryParams.skip = 0
    state.paginationControl = paginationService.updatePaginatorControl(state.queryParams, values.total || 0)
}
const performCloseResults = (state: GlobalSearchState) => {
    state.resultPanelOpened = false
    state.queryParams.searchString = ''
    state.queryParams.skip = 0
    state.paginationControl = paginationService.updatePaginatorControl(state.queryParams, state.queryParams.total || 0)
    state.refreshCount = state.refreshCount + 1
}

export const globalSearchSlice = createSlice({
    name: 'globalSearchSlice',
    initialState,
    reducers: {
        initGlobalSearch(state, action: PayloadAction<InitGlobalSearchPayload>) {
            performInitGlobalSearch(state, action.payload)
        },

        updateGlobalSearch(state, action: PayloadAction<UpdateSearchStringForGlobalSearchPayload>) {
            performUpdateSearchString(state, action.payload)
        },
        resetGlobalSearch(state, action: PayloadAction<ResetQueryParamsForGlobalSearchPayload>) {
            performReset(state)
        },
        setGlobalSearchSelectionField(state, action: PayloadAction<ToggleFieldForGlobalSearchPayload>) {
            performToggleFieldSelection(state, action.payload)
        },
        closeGlobalSearchResults(state, action: PayloadAction<CloseResultsPayload>) {
            performCloseResults(state)
        },
        rowsPerPageChange(state, action: PayloadAction<RowsPerPageChangedPayload>) {
            performRowsPerPageChange(state, action.payload)
        },
        paginationModeChange(state, action: PayloadAction<PaginationModeChangePayload>) {
            performPaginationModeChange(state, action.payload)
        },
        openResultDialog(state, action: PayloadAction<OpenResultDialogPayload>) {
            performOpenDialog(state, action.payload)
        },
        closeResultDialog(state, action: PayloadAction<CloseResultDialogPayload>) {
            performCloseDialog(state, action.payload)
        },
        toggleFuzzy(state, action: PayloadAction<ToggleFuzzyPayload>) {
            performToggleFuzzy(state, action.payload)
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getGlobalSearchResults.fulfilled, (state, action: PayloadAction<GlobalSearchResponseWrapper>) => {
            performUpdateResults(state, action.payload.response)
        })
    },
})
export const {
    initGlobalSearch,
    updateGlobalSearch,
    resetGlobalSearch,
    setGlobalSearchSelectionField,
    closeGlobalSearchResults,
    rowsPerPageChange,
    paginationModeChange,
    openResultDialog,
    closeResultDialog,
    toggleFuzzy,
} = globalSearchSlice.actions

export default globalSearchSlice.reducer
