import { BehaviorSubject, Observable, Subject, Subscriber } from 'rxjs'
import { SystemNotificationDTO } from '../../components/types'
import { log } from '../../services/LogService'
import { arrayNotEmpty, isObject } from '../../utils/TypeCheckers'
import { guslStorage } from '../session/GuslStorage'
// import {CustomerBalanceDTO} from '../../features/wallet/api/types'
import { JWTTokens } from '../session/types'
import { BlastService } from './BlastService'
import { ChatMessageBO, PlayerLoginBO, SimpleMessageBO, TopicSubscribe, TopicUnsubscribe } from './commands'
import { BlastCommand, CollectionAction, CollectionWsMessage, ServerToClientMessageBO, TickerCommandPayload } from './types'

export class DataCache {
    public static COLLECTION_WS_CMD: string = 'collection.action'
    public static NOTIFICATION_WS_CMD: string = 'notification.system'
    public static CLEAR_ORDERED_FIELDS_WS_CMD: string = 'clear.orderedfields'
    public static THEME_CHANGED_CMD: string = 'theme.changed'
    public static TIMEZONE_CHANGED_CMD: string = 'timezone.changed'

    // theCacheMap: CacheEntity<String, any> = new CacheEntity<String, any>()

    private cacheMap: Map<string, Map<number, any>> = new Map<string, Map<number, any>>()
    private allElementSubject: Map<string, Subject<any>> = new Map<string, Subject<any[]>>()
    private singleElementSubject: Map<string, Subject<any>> = new Map<string, Subject<any>>()
    private forceRefreshSubject: Subject<string | undefined> = new BehaviorSubject<string | undefined>(undefined)

    private commandObservers: Subscriber<SimpleMessageBO<any>>[] = []
    private connectionObservers: Subscriber<boolean>[] = []

    blastServiceSubject: Subject<BlastService | undefined> = new BehaviorSubject<BlastService | undefined>(undefined)

    notificationSubscriber!: Subscriber<SystemNotificationDTO>
    notificationObservable = new Observable((observer: Subscriber<SystemNotificationDTO>) => {
        this.notificationSubscriber = observer
    })

    // commandSubscriber!: Subscriber<SimpleMessageBO<any>>
    // commandObservable = new Observable(
    //     (observer: Subscriber<SimpleMessageBO<any>>) => {
    //         this.commandSubscriber = observer
    //     },
    // )

    // balanceSubject: BehaviorSubject<CustomerBalanceDTO> = new BehaviorSubject(
    //   {} as CustomerBalanceDTO,
    // )
    //
    // balanceSubscriber!: Subscriber<CustomerBalanceDTO>
    // balanceObservable: Observable<CustomerBalanceDTO> = new Observable(
    //   (observer: Subscriber<CustomerBalanceDTO>) => {
    //     this.balanceSubscriber = observer
    //   },
    // )

    blastService: BlastService | undefined = undefined // = new BlastService(`${configuration.edgeWsUrl}`)
    blastConnected: boolean = false
    blastConnecting: boolean = false

    public connectedWatcher: BehaviorSubject<boolean>
    cachedOutbound: BlastCommand[] = []
    currentSessionToken: string = ''
    currentPlayerId: number = 0
    instanceId: string = '[DataCache_'
    wsEndpoint: string = ''

    constructor() {
        try {
            this.instanceId += Math.random().toString(36).substr(2, 9) + '] '
        } catch (err) {}
        this.connectedWatcher = new BehaviorSubject<boolean>(false)
    }

    public setEndpoint(wsEndpoint: string) {
        this.wsEndpoint = wsEndpoint
    }

    public connectWithToken(tokenId: string) {
        if (this.blastService) {
            const oldService = this.blastService
            // this.blastService.close(true)
            oldService.close(true)
        }
        log.info('DataCache', 'MSG100', 'blast service', this.wsEndpoint)

        this.blastService = new BlastService(`${this.wsEndpoint}?token=${tokenId}`, false)
        this.blastServiceSubject.next(this.blastService)
        this.performConnect()
    }

    public connectWithJwtTokens(tokens: JWTTokens) {
        this.blastService = new BlastService(`${this.wsEndpoint}`, false)
        this.blastServiceSubject.next(this.blastService)
        this.connectedWatcher.subscribe((connected: boolean) => {
            if (connected) {
                this.login(tokens)
            }
        })
        this.performConnect()
    }

    public performConnect() {
        if (this.blastService) {
            this.blastService.onOpen((msg: any) => {
                log.info('DataCache', 'MSG002', 'blast connected', msg)

                const self = this
                self.blastConnected = true
                this.blastConnecting = false

                self.connectedWatcher.next(true)
                self.connectionObservers.forEach((observer) => observer.next(true))
                self.sendCachedMessages()
                this.ping()
            })

            /**
             * Callback when server is disconnected
             */
            this.blastService.onClose((msg: any) => {
                log.info('DataCache', 'MSG003', 'Blast disconnected', msg)
                const self = this
                self.blastConnected = false
                self.blastConnecting = false
                self.connectedWatcher.next(false)
                self.connectionObservers.forEach((observer) => observer.next(false))
            })

            this.blastService.onMessage((msg: any) => {
                // we received a message

                try {
                    let inboundMessage: ServerToClientMessageBO<any> = isObject(msg)
                        ? (JSON.parse(JSON.stringify(msg)) as ServerToClientMessageBO<any>)
                        : (JSON.parse(msg) as ServerToClientMessageBO<any>)
                    const self = this
                    log.info('DataCache', 'MSG004', 'Inbound', inboundMessage)

                    self.handleMessage(inboundMessage)
                } catch (err) {
                    // log.error('DataCache', 'ERR003', 'message is not json', err, msg)
                }

                // const self = this
                // if (isObject(msg)) {
                //     let inboundMessage: EdgeToCustomerMessageBO = JSON.parse(
                //         JSON.stringify(msg),
                //     ) as EdgeToCustomerMessageBO
                //     log.debug('DataCache', 'MSG004', 'message received', inboundMessage)
                //     self.postProcessMessage(inboundMessage)
                // } else {
                //     try {
                //         let inboundMessage: EdgeToCustomerMessageBO = JSON.parse(
                //             msg,
                //         ) as EdgeToCustomerMessageBO
                //         self.postProcessMessage(inboundMessage)
                //     } catch (err) {
                //         log.error('DataCache', 'MSG005', 'message is not json', err, msg)
                //     }
                // }
            })

            this.blastService.onError((msg: any) => {
                log.error('DataCache', 'ERR003', 'blast error', msg)
                const self = this
                self.blastConnected = false
                self.blastConnecting = false
            })
            log.info('DataCache', 'MSG009', 'Blast connecting...')

            this.blastService.connect(true)
        } else {
            log.error('DataCache', 'ERR002', 'No Blast service')
        }
    }

    public login = (tokens: JWTTokens) => {
        if (this.blastService) {
            this.blastService.send(new PlayerLoginBO(tokens.sessionToken, tokens.userToken))
        }
    }

    private clearCollection(collection: string) {
        const collectionMap = this.getOrCreateCollectionMap(collection)
        collectionMap.clear()
        this.updateAllElementSubject(collection)
        this.forceRefreshSubject.next(collection)
        // this.forceRefreshSubject.next(undefined)
    }

    private handlePopulateCollectionMessage(collection: string, content: any[]) {
        const collectionMap = this.getOrCreateCollectionMap(collection)
        collectionMap.clear()
        ;(content as any[]).forEach((entry) => {
            collectionMap.set(entry.id, entry)
        })
        this.updateAllElementSubject(collection)
        this.forceRefreshSubject.next(collection)
        // this.forceRefreshSubject.next(undefined);
    }

    public getCollectionAsObservable(collection: string): Observable<any[]> {
        return this.getOrCreateAllElementSubject(collection).asObservable()
    }

    public getCollection(collection: string) {
        const values = this.cacheMap.get(collection)?.values()
        return values ? Array.from(values) : null
    }

    private updateAllElementSubject(collection: string) {
        const values = this.getCollection(collection)
        if (values) {
            this.getOrCreateAllElementSubject(collection).next(values)
        }
    }

    private getOrCreateAllElementSubject(collection: string): Subject<any[]> {
        let subject = this.allElementSubject.get(collection)
        if (!subject) {
            subject = new BehaviorSubject([])
            this.allElementSubject.set(collection, subject)
        }
        return subject
    }

    private getOrCreateSingleElementSubject(collection: string): Subject<any> {
        let subject = this.singleElementSubject.get(collection)
        if (!subject) {
            subject = new BehaviorSubject(undefined)
            this.singleElementSubject.set(collection, subject)
        }
        return subject
    }

    private getOrCreateCollectionMap(collection: string): Map<number, any> {
        let tMap = this.cacheMap.get(collection)
        if (!tMap) {
            tMap = new Map<number, any>()
            this.cacheMap.set(collection, tMap)
        }
        return tMap
    }

    private handleCollectionWsMessage(dataFromServer: ServerToClientMessageBO<any>) {
        const messageData = dataFromServer.data as CollectionWsMessage<any>
        switch (messageData.action) {
            case CollectionAction.DROP_ALL:
                Object.keys(messageData.data).forEach((key) => {
                    const collectionContent: any[] = messageData.data[key] || []
                    this.handlePopulateCollectionMessage(key, collectionContent)
                })
                break
            case CollectionAction.CLEAR:
                this.clearCollection(messageData.collection)
                break
            case CollectionAction.REMOVE:
                this.getOrCreateCollectionMap(messageData.collection).delete(messageData.data.id)
                this.getOrCreateSingleElementSubject(messageData.collection).next(messageData)
                this.updateAllElementSubject(messageData.collection)
                break
            case CollectionAction.ADD:
                this.getOrCreateCollectionMap(messageData.collection).set(messageData.data.id, messageData.data)
                this.getOrCreateSingleElementSubject(messageData.collection).next(messageData)
                this.updateAllElementSubject(messageData.collection)
                break
            case CollectionAction.UPDATE:
                this.getOrCreateCollectionMap(messageData.collection).set(messageData.data.id, messageData.data)
                this.getOrCreateSingleElementSubject(messageData.collection).next(messageData)
                this.updateAllElementSubject(messageData.collection)
                break

            case CollectionAction.POPULATE:
                this.clearCollection(messageData.collection)
                this.handlePopulateCollectionMessage(messageData.collection, messageData.data)
                break
            case CollectionAction.ALL:
                this.handlePopulateCollectionMessage(messageData.collection, messageData.data)
                break
        }
    }

    ping() {
        setTimeout(() => {
            if (this.blastConnected) {
                this.blastService?.send({ cmd: 'ping' } as TickerCommandPayload<any>)
            }
            this.ping()
        }, 60000)
    }

    private handleMessage(dataFromServer: ServerToClientMessageBO<any>) {
        log.debug('DataCache', 'MSG010', 'handleMessage', dataFromServer)
        // console.log('handleCollectionWsMessage messageData:',dataFromServer.cmd,dataFromServer)

        switch (dataFromServer.cmd) {
            case DataCache.COLLECTION_WS_CMD:
                this.handleCollectionWsMessage(dataFromServer)
                break
            case DataCache.NOTIFICATION_WS_CMD:
                this.handleNotificationWsMessage(dataFromServer)
                break
            case DataCache.CLEAR_ORDERED_FIELDS_WS_CMD:
                guslStorage.clearOrderedFields()
                break
            default:
                this.handleCommandMessage(dataFromServer)
        }
    }

    private handleNotificationWsMessage(dataFromServer: ServerToClientMessageBO<any>) {
        const messageData = dataFromServer.data as SystemNotificationDTO
        this.notificationSubscriber.next(messageData)
    }

    private handleCommandMessage(dataFromServer: SimpleMessageBO<any>) {
        const messageData = dataFromServer as SimpleMessageBO<any>
        log.info('DataCache', 'MSG001', 'handleCommand', dataFromServer)

        // if (dataFromServer?.data?.keyValue?.indexOf('BARC') > 0) {
        //     console.log('dataFromServer', dataFromServer)
        // }

        this.commandObservers.forEach((observer: Subscriber<SimpleMessageBO<any>>) => observer.next(messageData))
        // this.commandSubscriber.next(messageData)
    }

    public observeCommands = () => {
        let commandObservable = new Observable((observer: Subscriber<SimpleMessageBO<any>>) => {
            this.commandObservers.push(observer)
        })
        return commandObservable
    }

    public observeConnection = () => {
        let connectionObservable = new Observable((observer: Subscriber<boolean>) => {
            this.connectionObservers.push(observer)
            observer.next(this.connectedWatcher.value)
        })
        return connectionObservable
    }

    public sendChatMessage = (msg: string) => {
        if (this.blastService) {
            this.blastService.send(new ChatMessageBO(msg))
        }
    }

    public subscribeTopic = (topicName: string) => {
        if (this.blastService) {
            this.blastService.send(new TopicSubscribe(topicName))
        }
    }

    public disconnect = () => {
        if (this.blastService) {
            this.blastService.close(true)
        }
    }
    public unsubscribeTopic = (topicName: string) => {
        if (this.blastService) {
            this.blastService.send(new TopicUnsubscribe(topicName))
        }
    }

    private sendCachedMessages(): void {
        if (arrayNotEmpty(this.cachedOutbound)) {
            const cacheCopy: BlastCommand[] = Object.assign([], this.cachedOutbound)
            this.cachedOutbound = []
            cacheCopy.forEach((message: BlastCommand) => {
                if (this.blastService) {
                    this.blastService.send(message)
                }
            })
        }
    }
}

export default DataCache
