export const TypographyStyle: any = {
    name: 'Typography',
    open: false,
    properties: [
        'font-family',
        'font-size',
        'font-weight',
        'letter-spacing',
        'color',
        'line-height',
        {
            extend: 'text-align',
            options: [
                { id: 'left', label: 'Left', className: 'fa fa-align-left' },
                { id: 'center', label: 'Center', className: 'fa fa-align-center' },
                { id: 'right', label: 'Right', className: 'fa fa-align-right' },
                { id: 'justify', label: 'Justify', className: 'fa fa-align-justify' },
            ],
        },
        {
            property: 'text-decoration',
            type: 'radio',
            default: 'none',
            options: [
                { id: 'none', label: 'None', className: 'fa fa-times' },
                { id: 'underline', label: 'underline', className: 'fa fa-underline' },
                {
                    id: 'line-through',
                    label: 'Line-through',
                    className: 'fa fa-strikethrough',
                },
            ],
        },
        'text-shadow',
    ],
}
