import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../app/hooks'
import { inputClass } from '../../../pages/login/LoginUsernamePassword'
import { hasValidation, shouldDisable, shouldHide } from '../../../services/ValidationService'
import { createRouteWithQueryParams, extractExtraFieldProperties } from '../../../utils/Utils'
import { FieldProperties } from '../../types'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { ExtraFieldProperties } from '../money/types'
//import './Badge.css'

/* eslint-disable @typescript-eslint/no-unused-vars */
export const BadgeField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const [className] = useState('BadgeField-' + new Date().getTime())
    const [extraFieldProperties] = useState<ExtraFieldProperties>(() => extractExtraFieldProperties(properties))
    const navigate = useNavigate()

    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [formMode, setFormMode] = useState(properties.formMode)
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) || properties.data || '')

    const route = createRouteWithQueryParams(properties.rowData, extraFieldProperties)

    function navigateHandler() {
        if (route) {
            //log.debug(className, 'MSG002', {route})
            navigate(route)
        }
    }

    const renderTableView = (): React.ReactElement => {
        return (
            <span className="badge bg-primary" role={extraFieldProperties.route ? 'button' : 'default'} onClick={navigateHandler}>
                {properties.data}
            </span>
        )
    }

    const renderFormView = (): React.ReactElement => {
        let hideField = shouldHide(formMode, properties.fieldConfig)
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const disableField: boolean = shouldDisable(formMode, properties.fieldConfig)
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const required = hasValidation(properties.fieldConfig?.validators)

        hideField = true // hide it
        return (
            <>
                {!hideField && (
                    <div className={'form-floating'}>
                        <label className={'fw-bold py-0 pt-1'} htmlFor={properties.fieldConfig.label}>
                            {properties.fieldConfig.label}
                        </label>
                        <input
                            id={properties.fieldConfig.label}
                            className={'form-control ' + inputClass('invalid text-center')}
                            type="text"
                            disabled
                            value={'Not implemented'}
                        />
                    </div>
                )}
            </>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
