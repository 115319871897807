import styled, { ThemeProps } from 'styled-components'
import { TANDC_FOOTER_HEIGHT } from '../../providers/theme/GuslThemeProvider'
import { TabProperties } from '../common/tabbed-page/styled'
import { Button } from '../ui/button/button'

export const TermsContentStyled = styled.div.attrs((props) => ({}))`
    padding: 10px;
`
export const DocumentStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.table.columnColor} !important;

    h1 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 26px;
    }

    p {
    }
`

export const TermsTabStyled = styled.div.attrs((props: TabProperties) => ({}))<TabProperties>`
    :hover {
        cursor: pointer;
    }

    color: ${(props) => (props.active ? props.theme.colors.light : props.theme.colors.dark[1])};
    font-size: 14px;
    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
    ${(props) => props.active && 'border-bottom: solid 2px ' + props.theme.colors.light + ';'}

    margin-right: ${(props) => (props.isMobile ? '0px' : '10px')};
    padding: ${(props) => (props.isMobile ? '0px 10px 0px 10px' : '0px')};
    line-height: ${(props) => (props.isMobile ? '22px' : 'inherit')};
    padding-bottom: ${(props) => (props.isMobile ? 'inherit' : '5px')};
    display: inline-block;
`
export const TermsFooterStyled = styled.div.attrs((props) => ({}))`
    background-color: #fff;
    color: #000;
    height: ${(props) => TANDC_FOOTER_HEIGHT}px;
    display: block;
    width: 100vw;
    position: fixed;
    bottom: 0px;
    z-index: 5000;
`

export const AcceptHeaderTextStyled = styled.div.attrs((props) => ({}))`
    padding: 15px 10px 5px 10px;
    text-align: center;
    margin: auto;
    font-size: 16px;
    font-weight: bold;
`
export const AcceptTextStyled = styled.div.attrs((props) => ({}))`
    padding: 10px 10px 15px 10px;
    text-align: center;
    margin: auto;
    font-size: 16px;
`

export const AcceptButtonWrapperStyled = styled.div.attrs((props) => ({}))`
    padding: 3px 0 5px 0;
    text-align: center;
    margin: auto;
    font-size: 16px;
`

export const AcceptButtonStyled = styled(Button).attrs((props) => ({}))`
    width: 50%;
    max-width: 200px;
    color: ${(props) => props.theme.login.buttonFgColor};
    background-color: ${(props) => props.theme.login.buttonBgColor};
    text-align: center;
    margin: auto;
`
export const TandCTableContainerStyled = styled.div.attrs((props) => ({}))`
    background-color: ${(props) => props.theme.panel.panelBgColor};
`

interface TableTitleProperties extends ThemeProps<any> {
    startPos: number
    footerHeight: number
}

export const TAndCTableContentStyled = styled.div.attrs((props: TableTitleProperties) => ({}))<TableTitleProperties>`
    ${(props) =>
        'height: calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.startPos - 40 + props.footerHeight) + 'px)'};
    ${(props) => 'overflow: overlay;'};
`
