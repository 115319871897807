import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { getSettings, performValidation } from '../../../services/ValidationService'
import { getStyle } from '../../../utils/CssUtils'
import { assignReferences, noop, RunOnceEffect } from '../../../utils/Utils'
import { FieldProperties, FormMode } from '../../types'
import FieldLabel from '../field-label/FieldLabel'
import { TableColumnResizeState } from '../gusl-table/guslColumnResizeSlice'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { maintainTableService } from '../maintain-table/MaintainTableService'
import { FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled } from '../text/styled'

export const RuleActionField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    // const [className] = React.useState<string>(() => 'TextFormField-' + new Date().getTime());
    const [formMode, setFormMode] = useState(properties.formMode)
    // const [formValue, setFormValue] = useState<string>(properties?.data || '');
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) || properties.data || '')
    const valueRef = useRef(properties?.data)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [submitted, setSubmitted] = useState(false)

    // if you want ellipsis on text field ... need to pass cellWidth to TextFieldStyled
    const code: string = maintainTableService.getCodeFromUrl()
    const _columnResizeState: TableColumnResizeState = useAppSelector((state) => state.guslColumnResizeSlice[code])
    const cellWidth: number | string | undefined = _columnResizeState?.resizedColumns[properties.fieldConfig.name]

    console.log('action-->', properties.fieldConfig.name, properties.data)
    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setFormValue(properties?.data || '')
    }

    useEffect(() => {
        setFormValue(properties?.data || '')
    }, [properties])

    const doValidation = (fieldValue: any): boolean => {
        return performValidation(formMode, properties.menuItem?.code, properties.fieldConfig, fieldValue, setSubmitted, setErrorMessage)
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.fieldConfig.tableCss, '')

        return <p style={style}>action</p>
    }

    const renderActions = (): React.ReactElement => {
        return <p>actions</p>
    }

    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        return (
            <>
                {!hideField && (
                    <>
                        <FloatingFormStyled>
                            <FieldContentWrapperStyled>
                                <FieldContentStyled>{renderActions()}</FieldContentStyled>
                                {submitted && errorMessage && <small className="yellow">{errorMessage}</small>}
                            </FieldContentWrapperStyled>
                            <FieldLabel properties={properties} />
                        </FloatingFormStyled>
                    </>
                )}
            </>
        )
    }
    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
