import { FavQueryDTO, IMenuDTO, QueryParamsDTO } from '../../../types'

export function validateQueryName(favQueries: FavQueryDTO[], action: string, favQuery: FavQueryDTO) {
    let validationErrorMsg = ''
    const maxNameLength: number = 100
    const name: string = favQuery.queryName

    if (!name) {
        validationErrorMsg = 'Missing the name!'
    }
    if (action !== 'edit' && favQueries.filter((favQuery) => favQuery.queryName === name).length > 0) {
        validationErrorMsg = 'Name already exists!'
    }
    if (name.length > maxNameLength) {
        validationErrorMsg = `Max ${maxNameLength} characters!`
    }

    return { validationErrorMsg }
}

export function getUrlForTheAction(menuItem: IMenuDTO, action: string, code: string) {
    try {
        return menuItem?.favQueries?.urls.filter((url) => url.action === action)[0].url.replace('{code}', code)
    } catch (e) {
        console.error('could not get the url for ' + code)
        return 'undefined'
    }
}

export function queryParamsAreTheSameOld(query1: QueryParamsDTO, query2: QueryParamsDTO) {
    const fieldsToCheck: string[] = ['skip', 'limit', 'fuzzy']
    const arrayFieldsToCheck: string[] = ['musts', 'mustNots', 'should', 'orderBys', 'rangeQueries']

    let areTheSame: boolean = true
    fieldsToCheck.forEach((field) => {
        // @ts-ignore
        if (query1[field] !== query2[field]) {
            areTheSame = false
        }
    })

    arrayFieldsToCheck.forEach((field) => {
        // @ts-ignore
        const array1 = query1[field]
        // @ts-ignore
        const array2 = query2[field]

        if (array1 && array2) {
            /*todo need to figure out how to properly compare arrays of objects
             *  as this approach won't return correct results all the time, for example if array has same elements but in different order*/
            // @ts-ignore
            if (JSON.stringify(array1) !== JSON.stringify(array2)) {
                areTheSame = false
            }
        }
    })

    return areTheSame
}

export function queryParamsAreTheSame(query1: QueryParamsDTO, query2: QueryParamsDTO): boolean {
    // console.log('-----------> queryParamsAreTheSame', query1, query2)
    const fieldsToCheck: string[] = ['skip', 'limit', 'fuzzy']

    // "orderBys", <-- I think no need to check this
    const arrayFieldsToCheck: string[] = ['musts', 'mustNots', 'should', 'rangeQueries', 'ins']

    const areArraysEqual = (arr1: any[], arr2: any[]): boolean => {
        const filteredArr1 = arr1.filter((element: any) => element.field != 'bettorId')
        const filteredArr2 = arr2.filter((element: any) => element.field != 'bettorId')

        if (filteredArr1.length !== filteredArr2.length) {
            // console.log('===> areArraysEqual diif lengths', filteredArr1, filteredArr2)
            return false
        }

        const sortedArr1 = filteredArr1.slice().sort()
        const sortedArr2 = filteredArr2.slice().sort()

        for (let i = 0; i < sortedArr1.length; i++) {
            if (
                JSON.stringify(sortedArr1[i])?.replace(',"caseInsensitive":false', '') !==
                JSON.stringify(sortedArr2[i])?.replace(',"caseInsensitive":false', '')
            ) {
                // console.log(`${JSON.stringify(sortedArr1[i])?.replace(',\"caseInsensitive\":false','')} vs ${JSON.stringify(sortedArr2[i])?.replace(',\"caseInsensitive\":false','') } => ${(JSON.stringify(sortedArr1[i])?.replace(',\"caseInsensitive\":false','') === JSON.stringify(sortedArr2[i])?.replace(',\"caseInsensitive\":false','') )}`)
                return false
            }
        }
        return true
    }

    let areTheSame: boolean = true
    fieldsToCheck.forEach((field) => {
        if (query1[field as keyof QueryParamsDTO] !== query2[field as keyof QueryParamsDTO]) {
            areTheSame = false
        }
    })

    arrayFieldsToCheck.forEach((field) => {
        // @ts-ignore
        const array1 = query1[field]
        // @ts-ignore
        const array2 = query2[field]

        if (array1 && array2) {
            if (!areArraysEqual(array1, array2)) {
                // console.log('1st check', field, array1, array2, areArraysEqual(array1, array2))
                areTheSame = false
            }
        } else if ((array1 && array1.length > 0 && !array2) || (!array1 && array2 && array2.length > 0)) {
            // console.log('2nd check', field, array1, array2)
            areTheSame = false
        }
    })

    return areTheSame
}
