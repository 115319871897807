import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../../app/hooks'
import { SessionContext } from '../../../providers/session/SessionContext'
import { RunOnceEffect, unSubscribe } from '../../../utils/Utils'
import TableSpinner from '../../common/gusl-table/spinner/TableSpinner'
import ArticlesView from './articles-view/ArticlesView'
import OttoTable from './content/OttoTable'
import { DealOpenState } from './deal-view/dealOpenSlice'
import DealView from './deal-view/DealView'
import SecondaryOttoNav, { ALL_EVENT, getTabCode, HOME, OTHER } from './navigation/SecondaryOttoNav'
import './resources/OttoEvents.css'
import OtherDealsView from './other-deals-view/OtherDealsView'
import { OttoTicker } from './otto-ticker/OttoTicker'
import { OttoContentStyled, TabWrapperStyle, TickerWrapperStyle } from './styled'

export const OTTO_HOME = '/bespoke/otto'

export interface OttoEventsDO {}

export default function OttoEvents({}: OttoEventsDO) {
    const sessionContext = React.useContext(SessionContext)
    const params = useParams()
    const tabParam = params?.tab ? decodeURIComponent(params?.tab) : undefined
    const dealId = params?.dealId ? decodeURIComponent(params?.dealId) : undefined
    const articleId = params?.articleId ? decodeURIComponent(params?.articleId) : undefined

    const _dealOpenSlice: DealOpenState = useAppSelector((state) => state.dealOpenSlice)
    const [code] = useState<string>('events')

    const currentTab = getTabCode(tabParam)

    const [systemReady, setSystemReady] = useState<boolean>(false)

    RunOnceEffect(() => {
        let loaderSubscription = sessionContext.watchSystemReady().subscribe((systemReady: boolean) => {
            setSystemReady(systemReady)
            if (dealId) {
                // dispatch(setDealOpenState({opened: true}))
            }
        })
        return () => {
            unSubscribe(loaderSubscription)
        }
    })

    const renderHome = (): React.ReactElement => {
        return <ArticlesView key={'home_' + code} code={code} height={'250px'} articleId={articleId} dealId={dealId} useMaxHeight={true} />
    }

    const renderDealTable = (): React.ReactElement => {
        if (_dealOpenSlice.opened) {
            return <></>
        }
        return <OttoTable key={'table_' + currentTab + '_' + _dealOpenSlice?.opened} />
    }

    const renderCalendar = (): React.ReactElement => {
        if (_dealOpenSlice.opened) {
            return <></>
        }
        return <OttoTable key={'table_' + currentTab + '_' + _dealOpenSlice?.opened} hasLeftPanel={false} />
    }

    const renderOther = (): React.ReactElement => {
        if (_dealOpenSlice.opened) {
            return <></>
        }
        return <OtherDealsView code={'other'} key={'other_' + currentTab + '_' + _dealOpenSlice?.opened} />
    }

    if (!systemReady) {
        return <TableSpinner />
    }

    const renderTab = (): React.ReactElement => {
        if (!_dealOpenSlice?.opened) {
            if (currentTab === HOME) {
                return <>{renderHome()}</>
            } else if (currentTab === ALL_EVENT) {
                return <>{renderCalendar()}</>
            } else if (currentTab === OTHER) {
                return <>{renderOther()}</>
            } else {
                return <>{renderDealTable()}</>
            }
        } else {
            return (
                <>
                    <DealView code={code} />
                </>
            )
        }
    }

    if (!systemReady) {
        return <p>Loading...</p>
    }
    return (
        <OttoContentStyled>
            <SecondaryOttoNav />
            <TickerWrapperStyle>
                <OttoTicker />
            </TickerWrapperStyle>
            <TabWrapperStyle>{renderTab()}</TabWrapperStyle>
        </OttoContentStyled>
    )
}
