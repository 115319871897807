import styled from 'styled-components'

export const TickerHeadingLabelStyled = styled.div.attrs((props) => ({
    id: 'ticker-label',
}))`
    width: 152px;
    font-size: 12px;
    //padding-top: 8px;
    padding-left: 4px;
    font-weight: 600;
    line-height: 15px;
    text-align: left;
    color: #ffffff;
    display: inline;
    height: 46px;
    vertical-align: middle;
    margin: 0 auto;
`
export const TickerHeaderWrapperStyled = styled.div.attrs((props) => ({
    id: 'ticker-wrapper',
}))`
    display: inline-block;
    background-color: #6f4bf2;
    width: 152px;
    border-radius: 12px;
    padding-left: 5px;
    vertical-align: middle;
    padding-top: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    text-align: center;
    height: 46px;
    margin: 0 auto;
    margin-left: -4px;
    margin-top: -2px;
`

export const TickerContainerStyled = styled.div.attrs((props) => ({
    id: 'ticker-container',
}))`
    padding-top: 11px;
    display: inline-block;
    text-align: center;
`

export const TickerHeadingIconStyled = styled.div.attrs((props) => ({
    id: 'ticker-icon',
}))`
    color: #ffffff;
    display: inline;
`
