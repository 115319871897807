import { SplashScreen } from '@capacitor/splash-screen'

class SplashScreenService {
    public hideSplash = async () => {
        return SplashScreen.hide()
    }

    public showSplash = async () => {
        return SplashScreen.show({
            autoHide: false,
            // showDuration: 2000,
            // autoHide: true,
        })
    }
}

const splashScreenService = new SplashScreenService()

export { splashScreenService }

export default SplashScreenService
