import React, { useState } from 'react'
import { isDefined } from '../../../utils/TypeCheckers'
import { validateWidth } from '../../../utils/Utils'
import { FormColumnConfigDTO, FormMode, FormPanels, IMenuDTO } from '../../types'
import { maintainTableService } from '../maintain-table/MaintainTableService'
import MaintainNewEntity from './MaintainNewEntity'
import MaintainPanel from './MaintainPanel'
import {
    AllPanelsContainerStyled,
    DialogPanelStyled,
    MaintainPanelWrapperStyled,
    NewEditModeStyled,
    PanelContainerStyled,
    PanelHeaderStyled,
    PanelHeaderWrapperStyled,
    PaneListStyled,
    PanelTitleStyled,
    PanelWrapperStyled,
} from './styled'
import { MaintainFormProperties } from './types'

const MaintainForm = (properties: MaintainFormProperties): React.ReactElement => {
    const [panels] = useState<FormPanels>(maintainTableService.extractFormPanels(properties.menuItem))
    const [menuItem] = useState<IMenuDTO | undefined>(maintainTableService.extractMenuItem(properties.menuItem))

    const [code] = useState<string>(properties?.code || properties?.menuItem?.code || 'default')
    const closeDialog = (refresh?: boolean) => {
        properties.closeDialog(refresh)
        if (properties.refreshTable) {
            properties.refreshTable()
        }
    }

    const renderPanelHeader = (panel: FormColumnConfigDTO): React.ReactElement => {
        return (
            <PanelHeaderStyled>
                <PanelTitleStyled>{panel.title}</PanelTitleStyled>
            </PanelHeaderStyled>
        )
    }

    const renderPanelTitle = (panel: FormColumnConfigDTO): React.ReactElement => {
        return <h4>{maintainTableService.getTitle(properties.data, properties.menuItem?.label)}</h4>
    }

    const renderPanel = (panel: FormColumnConfigDTO, index: number): React.ReactElement => {
        // const multiplePanels = (panels?.panels?.length || 0) > 0;
        return (
            <PanelWrapperStyled
                key={'panel_wrapper_' + index}
                id={'panel_wrapper_' + index}
                fxFlex={panel.fxFlex}
                width={panel.width}
                height={panel.height}
                isDialog={properties.isDialog}
            >
                {/*{havePanelHeader ? renderPanelHeader(panel) : renderPanelTitle(panel)}*/}
                <MaintainPanelWrapperStyled>
                    <MaintainPanel
                        code={panel?.code || 'panel_' + index}
                        canEdit={isDefined(panel?.updateUrl)}
                        controlOnTop={properties.controlOnTop}
                        isDialog={properties.isDialog}
                        key={'panel_' + index}
                        menuItem={menuItem}
                        panel={panel}
                        data={properties.data}
                        rowData={properties.rowData}
                        formMode={properties.formMode}
                        widgetPanelProperties={properties.widgetPanelProperties}
                        closeDialog={closeDialog}
                    />
                </MaintainPanelWrapperStyled>
            </PanelWrapperStyled>
        )
    }

    const renderViewEditMode = (): React.ReactElement => {
        return (
            <PaneListStyled>{panels?.panels.map((panel: FormColumnConfigDTO, index: number) => renderPanel(panel, index))}</PaneListStyled>
        )
    }
    const renderNewEditMode = (): React.ReactElement => {
        const createEntityProperties = maintainTableService.extractCreateEntityDetails(properties.menuItem)
        createEntityProperties.minModalHeight = properties.minModalHeight
        createEntityProperties.minModalWidth = validateWidth(properties.minModalWidth, window.innerWidth)

        return (
            <NewEditModeStyled>
                {/*{!properties.isDialog &&*/}
                {/*    <h4 className={'widget_heading small mb-3'}>*/}
                {/*        {maintainTableService.getTitle(properties.data, createEntityProperties.label)}*/}
                {/*    </h4>}*/}
                {!properties.isDialog && (
                    <PanelHeaderWrapperStyled hasTitle={true} hasBanner={true}>
                        <div className="col d-flex align-items-center">
                            <PanelTitleStyled>
                                {maintainTableService.getTitle(properties.data, createEntityProperties.label)}
                            </PanelTitleStyled>
                        </div>
                    </PanelHeaderWrapperStyled>
                )}

                <MaintainPanelWrapperStyled>
                    <MaintainNewEntity
                        code={properties?.code || createEntityProperties?.code}
                        isDialog={properties.isDialog}
                        controlOnTop={false}
                        formMode={FormMode.NEW}
                        data={properties.data}
                        rowData={properties.rowData}
                        menuItem={properties.menuItem}
                        formProperties={createEntityProperties}
                        closeDialog={closeDialog}
                        widgetPanelProperties={properties.widgetPanelProperties}
                    />
                </MaintainPanelWrapperStyled>
            </NewEditModeStyled>
        )
    }

    const renderPanels = (): React.ReactElement => {
        // I would combine into one if I knew how to have conditional styles
        if (properties.isDialog) {
            return (
                <DialogPanelStyled key={'dialog_container'}>
                    {properties.formMode === FormMode.NEW ? renderNewEditMode() : renderViewEditMode()}
                </DialogPanelStyled>
            )
        } else {
            return (
                <PanelContainerStyled key={'panel_container'}>
                    {properties.formMode === FormMode.NEW ? renderNewEditMode() : renderViewEditMode()}
                </PanelContainerStyled>
            )
        }
    }

    const renderActionBar = (): React.ReactElement => {
        return <></>
    }

    const renderForm = (): React.ReactElement => {
        return <AllPanelsContainerStyled>{renderPanels()}</AllPanelsContainerStyled>
    }

    const renderHeader = (): React.ReactElement => {
        return <div className="">{renderActionBar()}</div>
    }

    const renderFooter = (): React.ReactElement => {
        return <div className=""></div>
    }

    return (
        <div className="row g-0" id={'maintain_form_wrap_' + code} key={'maintain_form_wrap_' + code}>
            <div className="col-md-12" id={'maintain_form_wrapper'} key={'maintain_form_wrapper'}>
                {renderForm()}
            </div>
        </div>
    )
}
export default MaintainForm
