import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BlastProps } from '../../../providers/blast/BlastContext'
import { SessionContextProps } from '../../../providers/session/types'
import { constructUrl, safeStream } from '../../../utils/Utils'
import { QueryParamsDTO } from '../../types'
import { ArticlesByDateDTO, DealData, EventsByDateDTO, OSSArticleDTO, UpcomingEventDTO } from './types'

interface OttoEventsState {
    [id: string]: OttoEventState
}

const initialState: OttoEventsState = {}

export interface OttoEventState {
    code: string
    sessionContext: SessionContextProps | undefined
    blastContext: BlastProps | undefined
    label: string | undefined
    selectUrl: string
    lastQueryParams: QueryParamsDTO | undefined

    latestPublished: ArticlesByDateDTO[]
    upcomingEvents: EventsByDateDTO[]
    selectedEvent: OSSArticleDTO | UpcomingEventDTO | undefined
    dealData: DealData | undefined
    refreshCounter: number
}

export interface OttoEventInitPayload {
    code: string
    sessionContext: SessionContextProps
    blastContext: BlastProps
    label: string | undefined
    selectUrl: string
    lastQueryParams: QueryParamsDTO | undefined
}

export interface OttoEventSelectedPayload {
    code: string
    selectedEvent: OSSArticleDTO | UpcomingEventDTO
}

export interface OttoEventUnSelectedPayload {
    code: string
}

export interface OttoEventCleanUpPayload {
    code: string
}

const createDefault = (code: string): OttoEventState => {
    return {
        code: code,
        sessionContext: undefined,
        blastContext: undefined,
        label: undefined,
        selectUrl: '',
        latestPublished: [],
        upcomingEvents: [],
        lastQueryParams: undefined,
        selectedEvent: undefined,
        dealData: undefined,
        refreshCounter: 0,
    }
}
const getState = (state: OttoEventsState, code: string): OttoEventState => {
    let entry: OttoEventState = state[code]
    if (!entry) {
        entry = createDefault(code)
    }
    return entry
}
const loadInitValues = (entry: OttoEventState, values: OttoEventInitPayload, code: string) => {
    entry.code = code
    entry.sessionContext = values.sessionContext
    entry.blastContext = values.blastContext
    entry.label = values.label
    entry.selectUrl = values.selectUrl
    entry.lastQueryParams = values.lastQueryParams
}

interface DataRequest {
    code: string
    url: string
    queryParams: QueryParamsDTO
    sessionContext: SessionContextProps
    abortController: AbortController
    pathParams?: any | undefined
}

interface DataResponse {
    queryParams: QueryParamsDTO | undefined
    latestPublished: ArticlesByDateDTO[]
    upcomingEvents: EventsByDateDTO[]
    currentArticle: OSSArticleDTO
}

export interface DataResponseWrapper {
    code: string
    response: DataResponse
}

export const getArticlesData = createAsyncThunk('otto-events-url', async (request: DataRequest) => {
    const response = await request.sessionContext.post<QueryParamsDTO, DataResponse>(
        constructUrl(request.url, request.pathParams),
        request.queryParams,
        request.abortController
    )
    return { code: request.code, response: response?.data || {} }
})

interface DealRequest {
    code: string
    url: string
    sessionContext: SessionContextProps
    abortController: AbortController
    pathParams?: any | undefined
}

export interface DealResponseWrapper {
    code: string
    response: DealData
}

export const getDealData = createAsyncThunk('otto-deal', async (request: DealRequest) => {
    // return {
    //     code: request.code,
    //     response: {
    //         chartData: undefined,
    //         latestPublished: latestlatestPublished,
    //         upcomingEvents: upcomingEvents,
    //     } as DealData
    // } as DealResponseWrapper
    const response = await request.sessionContext.post<any, DealData>(
        constructUrl(request.url, request.pathParams),
        {},
        request.abortController
    )
    return {
        code: request.code,
        response: response.data,
    } as DealResponseWrapper
})

const copyState = (entry: OttoEventState): OttoEventState => {
    const newState = {}
    for (const key in entry) {
        if (entry.hasOwnProperty(key)) {
            // @ts-ignore
            newState[key] = entry[key]
        }
    }
    return newState as OttoEventState
}

const updateSelected = (entry: OttoEventState, response: DataResponse): void => {
    if (entry.selectedEvent) {
        if (response.latestPublished && response.latestPublished.length > 0) {
            safeStream(response.latestPublished).forEach((articlesByDate) => {
                safeStream(articlesByDate.articles)
                    .filter((article) => article.id === entry?.selectedEvent?.id)
                    .forEach((article) => (entry.selectedEvent = article))
            })
        }
        if (response.upcomingEvents && response.upcomingEvents.length > 0) {
            safeStream(response.upcomingEvents).forEach((eventsByDate) => {
                safeStream(eventsByDate.articles)
                    .filter((event) => event.id === entry?.selectedEvent?.id)
                    .forEach((event) => (entry.selectedEvent = event))
            })
        }
    }
}

const updateData = (inboundState: OttoEventState, response: DataResponse): OttoEventState => {
    const entry: OttoEventState = copyState(inboundState)
    if (response) {
        entry.latestPublished = response.latestPublished
        entry.upcomingEvents = response.upcomingEvents
        entry.lastQueryParams = response.queryParams
        entry.selectedEvent = response.currentArticle

        // if (!entry.selectedEvent) {
        //     if (response.latestPublished && response.latestPublished.length > 0) {
        //         entry.selectedEvent = response?.latestPublished[0]?.articles[0] || undefined
        //     } else if (response.upcomingEvents && response.upcomingEvents.length > 0) {
        //         entry.selectedEvent = response?.upcomingEvents[0]?.events[0] || undefined
        //     }
        // } else {
        updateSelected(entry, response)
        // }
    }
    return entry
}
const updateDealData = (inboundState: OttoEventState, response: DealData): OttoEventState => {
    const entry: OttoEventState = copyState(inboundState)
    if (response) {
        entry.dealData = response
    }
    return entry
}

export const ottoEventsSlice = createSlice({
    name: 'ottoEventsSlice',
    initialState,
    reducers: {
        initOttoEvents(state: any, action: PayloadAction<OttoEventInitPayload>) {
            const code = action.payload.code
            const entry: OttoEventState = getState(state, code)
            loadInitValues(entry, action.payload, code)
            state[action.payload.code] = entry
        },
        selectedOttoEvent(state: any, action: PayloadAction<OttoEventSelectedPayload>) {
            const code = action.payload.code
            const entry: OttoEventState = getState(state, code)
            entry.selectedEvent = action.payload.selectedEvent
            entry.refreshCounter = entry.refreshCounter + 1
        },
        unSelectOttoEvent(state: any, action: PayloadAction<OttoEventUnSelectedPayload>) {
            const code = action.payload.code
            const entry: OttoEventState = getState(state, code)
            entry.selectedEvent = undefined
            entry.refreshCounter = entry.refreshCounter + 1
        },
        cleanUpOttoEvents(state, action: PayloadAction<OttoEventCleanUpPayload>) {
            delete state[action.payload.code]
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getArticlesData.fulfilled, (state: any, action: PayloadAction<DataResponseWrapper>) => {
            const entry: OttoEventState = getState(state, action.payload.code)
            state[action.payload.code] = updateData(entry, action.payload.response)
        })
        builder.addCase(getDealData.fulfilled, (state: any, action: PayloadAction<DealResponseWrapper>) => {
            const entry: OttoEventState = getState(state, action.payload.code)
            state[action.payload.code] = updateDealData(entry, action.payload.response)
        })
    },
})

export const { initOttoEvents, selectedOttoEvent, unSelectOttoEvent, cleanUpOttoEvents } = ottoEventsSlice.actions

export default ottoEventsSlice.reducer
