import { Dropdown } from 'react-bootstrap'
import styled from 'styled-components'

export const DropDownMenuStyled = styled(Dropdown.Menu).attrs((props) => ({}))`
    color: ${(props) => props.theme.table.columnColor};
    background-color: ${(props) => props.theme.colors.background[0]};
`

export const ChartMenuBarStyled = styled(Dropdown.Item).attrs((props) => ({}))``

export const SelectionItemStyled = styled.div.attrs((props) => ({
    className: 'px-1 d-flex justify-content-start align-items-center',
}))`
    font-size: 14px;
    color: ${(props) => props.theme.table.columnColor};
    line-height: 40px;
`
export const ChartNameStyled = styled.div.attrs((props) => ({
    className: '',
}))`
    font-size: 14px;
    color: ${(props) => props.theme.table.columnColor};
    line-height: 40px;
`

export const ChartTitleStyled = styled.div.attrs((props) => ({
    className: 'col  px-2 mt-1 primary_text',
}))`
    text-align: center;
    font-size: 14px;
`
