import styled, { ThemeProps } from 'styled-components'
import { isDefined } from '../../../utils/TypeCheckers'
import { DecimalDO } from '../../types'
import { ExtraFieldProperties } from '../money/types'

interface DecimalStyledProps extends ThemeProps<any> {
    decimal: DecimalDO | undefined
    extraProperties: ExtraFieldProperties
    inline: boolean
}

export const DecimalStyled = styled.div.attrs((props: DecimalStyledProps) => ({}))<DecimalStyledProps>`
    color: ${(props) => {
        // let value = props.theme.table.columnColor
        let value = 'inherit'
        if (isDefined(props?.extraProperties)) {
            try {
                // @ts-ignore
                const properties = JSON.parse(props?.extraProperties)
                if (properties.colorise) {
                    if (props.decimal && props.decimal.v) {
                        value = props.decimal.v < 0 ? props.theme.money.negativeColor : props.theme.money.positiveColor
                    }
                }
                /* eslint-disable @typescript-eslint/no-unused-vars */
            } catch (err) {
                // console.log('error extract extra properties', err)
            }
        }
        return value
    }};
    // color: ${(props) => props.theme.table.columnColor} !important;
    // font-size: ${(props) => props.theme.table.columnFontSize};

    ${(props) => props.inline && 'display:inline;'}
`
