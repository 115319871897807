import { BlastException } from '../types'
import { PathDetails } from './path-details'

export class CollectionTraversor {
    public static findList(path: string, data: any): any[] {
        // console.log('path',path,'data',data);
        if (Object.prototype.toString.call(data) === '[object Array]') {
            return this.traverseUntilFinalList(path, data)
        } else {
            const pathDetails: PathDetails[] = PathDetails.splitPath(path)

            if (pathDetails.length === 1 && pathDetails[0].getKeyField() === undefined && pathDetails[0].getCollection()) {
                // @ts-ignore
                return data[pathDetails[0].getCollection()]
            }

            let resultList = []
            for (let index = 0; index < pathDetails.length; index++) {
                const key = pathDetails[index].getCollection()
                if (pathDetails[index].getKeyField() === undefined && key) {
                    resultList = data[key]
                } else {
                    data = this.findARecord(index, pathDetails, data)
                }
            }
            return resultList === undefined ? [] : resultList
        }
    }

    public static findRecord(path: string, data: any): any {
        if (Object.prototype.toString.call(data) === '[object Array]') {
            return this.traverseUntilFinalRecord(path, data)
        } else {
            const pathDetails: PathDetails[] = PathDetails.splitPath(path)
            for (let index = 0; index < pathDetails.length; index++) {
                data = this.findARecord(index, pathDetails, data)
            }
            return data
        }
    }

    public static findRecordInList(keyField: string | undefined, keyValue: string | undefined, list: any[]): any {
        if (list && keyField && keyValue) {
            for (let index = 0; index < list.length; index++) {
                const id: any = list[index][keyField]
                if (id !== undefined) {
                    if (this.matches(id, keyValue)) {
                        return list[index]
                    }
                }
            }
        }
        throw new BlastException('[findRecordInList] failed to find record - field [' + keyField + ' ] value [' + keyValue + ']')
    }

    public static findRecordIndexInList(keyField: string | undefined, keyValue: string | undefined, list: any[]): number {
        if (list && keyField && keyValue) {
            for (let index = 0; index < list.length; index++) {
                const id: any = list[index][keyField]
                if (id !== undefined) {
                    if (this.matches(id, keyValue)) {
                        return index
                    }
                }
            }
        }
        throw new BlastException('[findRecordIndexInList] failed to find record - field [' + keyField + ' ] value [' + keyValue + ']')
    }

    public static findARecord(index: number, pathDetails: PathDetails[], dataSubset: any): any {
        // @ts-ignore
        const list: any[] = pathDetails[index].getCollection() ? dataSubset[pathDetails[index].getCollection()] : []
        return this.findRecordInList(pathDetails[index].getKeyField(), pathDetails[index].getKeyValue(), list)
    }

    public static traverseUntilFinalRecord(path: string, list: any[]): any {
        const pathDetails: PathDetails[] = PathDetails.splitPath(path)

        let data: any = this.findRecordInList(pathDetails[0].getKeyField(), pathDetails[0].getKeyValue(), list)
        for (let index = 1; index < pathDetails.length; index++) {
            data = this.findARecord(index, pathDetails, data)
        }
        return data
    }

    public static traverseUntilFinalList(path: string, list: any[]): any[] {
        const pathDetails: PathDetails[] = PathDetails.splitPath(path)

        // asking for a list with no details so return original list
        if (pathDetails[0].getKeyField() === undefined) {
            return list
        }

        let resultList: any[] = []

        let data: any = this.findRecordInList(pathDetails[0].getKeyField(), pathDetails[0].getKeyValue(), list)

        for (let index = 1; index < pathDetails.length; index++) {
            // there is no key field or we are on last path element
            if (pathDetails[index].getKeyField() === undefined || index === pathDetails.length - 1) {
                // @ts-ignore
                resultList = data[pathDetails[index].getCollection()]
            } else {
                data = this.findARecord(index, pathDetails, data)
            }
        }

        return resultList === undefined ? [] : resultList
    }

    public static matches(one: any, two: any): boolean {
        if (typeof one === 'number') {
            if (typeof two === 'number') {
                return one === two
            } else {
                return one === Number(two)
            }
        } else {
            if (typeof two === 'string') {
                return one === two
            } else {
                return one === String(two)
            }
        }
    }
}
