import { BaseTheme } from '../providers/theme/GuslThemeProvider'
import { Colour } from './types'

export const getColours = (theme: BaseTheme, colour?: Colour) => {
    switch (colour) {
        default:
        case 'primary':
            return `        
        background-color: ${theme.colors.primary[0]};
        color: ${theme.colors.light};        
      `
        case 'primaryLight':
            return `        
        background-color: ${theme.colors.primary[1]};        
        color: ${theme.colors.light};
      `

        case 'secondary':
            return `        
        background-color: ${theme.colors.secondary[0]};
        color: ${theme.colors.light};               
      `
        case 'error':
            return `        
        background-color: ${theme.colors.error};
        color: ${theme.colors.light};                   
      `
        case 'warning':
            return `        
        background-color: ${theme.colors.warning};  
        color: ${theme.colors.light};                 
      `
        case 'notification':
            return `        
        background-color: ${theme.colors.notification};  
        color: ${theme.colors.light};              
      `
        case 'dark':
            return `        
        background-color: ${theme.colors.dark[0]};       
        color: ${theme.colors.light};         
      `
        case 'inverse':
            return `        
        background-color: ${theme.colors.light};     
        color: ${theme.colors.primary[0]};        
      `
    }
}

export const getHoverColours = (theme: BaseTheme, colour?: Colour) => {
    switch (colour) {
        default:
        case 'primary':
            return `        
        &:hover {
           background-color: ${theme.colors.light};
           color: ${theme.colors.primary[0]};
        }
      `
        case 'primaryLight':
            return ` 
        &:hover {       
          background-color: ${theme.colors.light};
          color: ${theme.colors.primary[1]};
        }
      `

        case 'secondary':
            return `                
        &:hover {
           background-color: ${theme.colors.dark[0]};
           color: ${theme.colors.light};
        }       
      `
        case 'error':
        case 'warning':
        case 'notification':
            return `                
        &:hover {
           filter: brightness(120%); 
        }           
      `
        case 'dark':
            return `                
        &:hover {
          background-color: ${theme.colors.primary[1]};
          color: ${theme.colors.light};
        }
      `
        case 'inverse':
            return `                
        &:hover {
          background-color: ${theme.colors.dark[0]};
          color: ${theme.colors.light};
        }
      `
    }
}
