import React, { useEffect, useRef, useState } from 'react'
import { Subscription } from 'rxjs'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { EnvironmentContext } from '../../../providers/environment/EnvironmentContext'
import { environmentService } from '../../../providers/environment/EnvironmentService'
import { SessionContext } from '../../../providers/session/SessionContext'
import { SystemContext } from '../../../providers/system/SystemContext'
import { HEADER_HEIGHT } from '../../../providers/theme/GuslThemeProvider'
import { log } from '../../../services/LogService'
import { translateService } from '../../../services/TranslateService'
import { canShowOnForm, shouldDisable } from '../../../services/ValidationService'
import { cancelAbortController, clone, constructUrl, getErrorMessage, RunOnceEffect, unSubscribe } from '../../../utils/Utils'
import { fieldService } from '../../FieldService'
import {
    ActionResponseTableDO,
    ChildReference,
    EndpointDTO,
    FieldConfigDTO,
    FormMode,
    NotificationPosition,
    NotificationType,
    TableRowDTO,
} from '../../types'
import { GuslReport } from '../gusl-report/GuslReport'
import { GuslTable } from '../gusl-table/GuslTable'
import LoadingSpinner from '../loading-spinner/LoadingSpinner'
import Icon from '../maintain-table/bootstrap/Icon'
import { ActionButtonStyled } from '../maintain-table/bootstrap/styled'
import { maintainTableService } from '../maintain-table/MaintainTableService'
import { ReportDO } from '../report/types'
import {
    getTemplateData,
    guslFormFieldChange,
    GuslFormState,
    initForm,
    postFormData,
    resetFromData,
    TemplateResponse,
    UpdateDataResponse,
} from './guslFormSlice'
import {
    ActionButtonContainerStyles,
    ErrorMessageWrapperStyled,
    MaintainNewContainerStyled,
    PanelFormStyled,
    PanelFormWrapper,
    PanelHeaderWrapperStyled,
    PanelTitleStyled,
    PopupDescriptionStyled,
    ReportWrapperStyled,
    TableWrapperStyled,
} from './styled'
import { MaintainNewEntityProperties } from './types'

const MaintainNewEntity = (properties: MaintainNewEntityProperties): React.ReactElement => {
    const id = properties?.code || 'new_form'
    const systemContext = React.useContext(SystemContext)
    const sessionContext = React.useContext(SessionContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const dispatch = useAppDispatch()

    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[id])

    const [className] = React.useState<string>(() => 'MaintainNewEntity-' + new Date().getTime())
    const [primaryObjectName, setPrimaryObjectName] = useState<any>(undefined)
    const [loading, setLoading] = useState<boolean>(true)
    const [childReferences] = useState<Map<string, ChildReference>>(new Map())
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [counter, setCounter] = useState<number>(0)
    const [report, setReport] = useState<ReportDO | undefined>(undefined)
    const isMobile = environmentContext.isMobileDevice(properties.widgetPanelProperties)
    const [currentAbortController, setCurrentAbortController] = useState<AbortController | undefined>(undefined)
    const contentElement = useRef(null)
    const [formStartPos, setFormStartPos] = useState<number>(HEADER_HEIGHT)

    const reportContentElement = useRef(null)
    const [reportStartPos, setReportStartPos] = useState<number>(HEADER_HEIGHT)

    const [tableDefinition, setTableDefinition] = useState<ActionResponseTableDO | undefined>()
    const [footerHeight, setFooterHeight] = useState<number>(0)
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription)
        }
    })

    RunOnceEffect(() => {
        dispatch(
            initForm({
                code: id,
                fields: properties.formProperties.fields,
                formData: properties.data || {},
                rowData: properties.rowData || {},
            })
        )
    })

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                if (contentElement) {
                    // @ts-ignore
                    const rect = contentElement?.current?.getBoundingClientRect()
                    if (rect) {
                        setFormStartPos(rect.y)
                    }
                }

                if (reportContentElement) {
                    // @ts-ignore
                    const rect = reportContentElement?.current?.getBoundingClientRect()
                    if (rect) {
                        setReportStartPos(rect.y)
                    }
                }
            }, 100)
        })
    }, [loading, contentElement])

    const performGetTemplateData = () => {
        if (properties.formProperties.templateUrl) {
            const abortController = new AbortController()
            setCurrentAbortController(abortController)
            dispatch(
                getTemplateData({
                    code: id,
                    url: properties.formProperties.templateUrl,
                    sessionContext: sessionContext,
                    abortController: abortController,
                    formData: _guslFormState?.formData || properties.data,
                    rowData: _guslFormState?.rowData || properties.rowData,
                })
            )
                .unwrap()
                .then((reply: TemplateResponse) => {
                    setLoading(false)
                })
                .catch((error: any) => {
                    setLoading(false)
                    log.error(className, 'ERR001', error)
                })
        }
    }

    useEffect(() => {
        let mounted = true
        let abortController = new AbortController()
        if (properties.formProperties.templateUrl) {
            performGetTemplateData()
        } else {
            // setFormData(properties.data);
            setLoading(false)
        }
        return () => {
            cancelAbortController(abortController)
            mounted = false
        }
    }, []) // systemContext

    const performInsert = () => {
        if (properties.formProperties.updateEndpoint) {
            const abortController = new AbortController()
            setCurrentAbortController(abortController)

            const endpoint: EndpointDTO = {
                url: constructUrl(properties.formProperties.updateEndpoint.url, _guslFormState.formData, _guslFormState.rowData),
                method: properties.formProperties.updateEndpoint.method,
            }
            dispatch(
                postFormData({
                    code: id,
                    endpoint: endpoint,
                    sessionContext: sessionContext,
                    abortController: abortController,
                    formData: maintainTableService.createRequestBody(_guslFormState.formData, _guslFormState.primaryObjectName),
                })
            )
                .unwrap()
                .then((reply: UpdateDataResponse) => {
                    let closeDialog: boolean = true
                    if (reply?.response?.report) {
                        setReport(reply?.response?.report)
                        closeDialog = false
                    }
                    if (reply?.response?.notificationMessage) {
                        const msg = reply?.response?.notificationMessage || 'Record added'
                        systemContext.toast({
                            type: NotificationType.SUCCESS,
                            position: NotificationPosition.BOTTOM_RIGHT,
                            noIcon: false,
                            noAutoClose: false,
                            message: msg,
                            autoCloseDuration: 2000,
                        })
                    }
                    if (reply?.response?.table) {
                        setTableDefinition(reply?.response?.table)
                        closeDialog = false
                        dispatch(
                            guslFormFieldChange({
                                code: id,
                                name: 'quickEntry',
                                value: reply.response.table.originalFormData,
                                overwrite: false,
                            })
                        )
                    }

                    setLoading(false)

                    if (reply?.response?.reloadTemplate) {
                        performGetTemplateData()
                    } else {
                        if (closeDialog) {
                            properties.closeDialog(closeDialog)
                        }
                    }

                    setCounter(counter + 1)
                    childReferences.forEach((reference) => {
                        if (reference.formSaved) {
                            reference.formSaved(reply?.response)
                        }
                    })
                })
                .catch((error: any) => {
                    log.error(className, 'ERR001', error)
                    setErrorMessage(getErrorMessage(error))
                    setLoading(false)
                })
        }
    }

    const performMultipartForm = () => {
        if (properties.formProperties.updateEndpoint) {
            sessionContext
                .upload(
                    constructUrl(properties.formProperties.updateEndpoint.url, properties.data, _guslFormState.rowData),
                    maintainTableService.createRequestBody(_guslFormState.formData, primaryObjectName || 'data', true)
                )
                .then((response) => {
                    // @ts-ignore
                    if (response.data?.notificationMessage) {
                        // @ts-ignore
                        const msg = response.data?.notificationMessage || 'Data imported'
                        systemContext.toast({
                            type: NotificationType.SUCCESS,
                            position: NotificationPosition.BOTTOM_RIGHT,
                            noIcon: false,
                            noAutoClose: false,
                            message: msg,
                            autoCloseDuration: 2000,
                        })
                    }
                    setLoading(false)
                    childReferences.forEach((reference) => {
                        if (reference.formSaved) {
                            reference.formSaved(response.data)
                        }
                    })

                    // @ts-ignore
                    if (response.data?.reloadTemplate) {
                        performGetTemplateData()
                    } else {
                        properties.closeDialog(true)
                    }
                })
                .catch((error) => {
                    setErrorMessage(getErrorMessage(error))
                    setLoading(false)
                })
        }
    }

    const renderActionBar = (): React.ReactElement => {
        const onCancel = () => {
            properties.closeDialog()
            dispatch(
                resetFromData({
                    code: id,
                })
            )
        }

        const saveCurrentRow = () => {
            let passed = true
            childReferences.forEach((reference) => {
                if (reference.doValidation) {
                    if (!reference.doValidation(_guslFormState.formData[reference.name])) {
                        passed = false
                    }
                }
            })
            if (!passed) {
                setErrorMessage('Failed form validation')
                return
            }
            setErrorMessage(undefined)

            if (properties.formProperties.updateEndpoint) {
                setLoading(true)
                if (properties?.menuItem?.singleForm?.multipartForm) {
                    performMultipartForm()
                } else {
                    performInsert()
                }
            } else {
                log.warn(className, 'WRN001', 'No update endpoint defined to save data')
            }
        }

        const cancelIcon = maintainTableService.getIconForCancel(properties?.menuItem?.singleForm?.cancelIcon)
        const cancelColorTheme = properties?.menuItem?.singleForm?.cancelColorTheme || 'defaultColor'
        const saveIcon = maintainTableService.getIconForSave(properties?.menuItem?.singleForm?.saveIcon)
        const saveColorTheme = properties?.menuItem?.singleForm?.saveColorTheme || 'save'
        return (
            <ActionButtonContainerStyles controlOnTop={properties.controlOnTop}>
                <ActionButtonStyled onClick={onCancel} withMargin isAction colorName={cancelColorTheme}>
                    {cancelIcon && (
                        <>
                            <Icon icon={cancelIcon} className={'action_icon ' + cancelIcon} />
                            <>&nbsp;</>
                        </>
                    )}
                    <span>{maintainTableService.getLabelForCancel(properties?.menuItem?.singleForm?.cancelButton)}</span>
                </ActionButtonStyled>
                <ActionButtonStyled onClick={saveCurrentRow} withMargin isAction colorName={saveColorTheme}>
                    {saveIcon && (
                        <>
                            <Icon icon={saveIcon} className={'action_icon ' + saveIcon} />
                            <>&nbsp;</>
                        </>
                    )}
                    <span>{maintainTableService.getLabelForSave(properties?.menuItem?.singleForm?.saveButton)}</span>
                </ActionButtonStyled>
            </ActionButtonContainerStyles>
        )
    }
    const renderErrorMessage = (): React.ReactElement => {
        if (errorMessage) {
            return (
                <ErrorMessageWrapperStyled>
                    <small className="invalid">{errorMessage}</small>
                </ErrorMessageWrapperStyled>
            )
        }
        return <></>
    }

    const renderFields = (): React.ReactElement => {
        const onSubmitted = (): void => {}

        const onArrayChange = (parentName: string, childName: string, value: any, rowIndex: number): void => {
            // This is wrong - nested table entry
            const currentFormData = _guslFormState.formData
            if (currentFormData) {
                const rows: TableRowDTO[] = clone(currentFormData[parentName] || [])
                if (rowIndex >= 0 && rowIndex < (rows?.length || 0)) {
                    const newRow: any = clone(rows[rowIndex])
                    newRow[childName] = value
                    rows[rowIndex] = newRow
                    // currentFormData[parentName]=rows
                }
                // setFormData(currentFormData)
            }
        }

        const onChange = (name: string, value: any, overwrite?: boolean): void => {
            dispatch(
                guslFormFieldChange({
                    code: id,
                    name: name,
                    value: value,
                    overwrite: overwrite,
                    panelName: properties?.data?.details ? 'details' : undefined,
                })
            )
        }

        const getCurrentRowData = (): any => {
            return _guslFormState?.formData || {}
        }

        const registerChildReference = (reference: ChildReference) => {
            childReferences.set(reference.name, reference)
        }

        let firstField: number = -1
        // console.log('newEntity _guslFormState.code',_guslFormState?.code)
        return (
            <div id={'frm_new_wrapper'} key={'frm_new_wrapper'}>
                {_guslFormState?.fields
                    .filter((fieldConfig: FieldConfigDTO) => canShowOnForm(fieldConfig, FormMode.NEW))
                    .map((fieldConfig: FieldConfigDTO, index: number) => {
                        if (firstField < 0) {
                            if (!shouldDisable(FormMode.NEW, fieldConfig)) {
                                firstField = index
                            }
                        }
                        return (
                            <div id={'frm_new'} key={fieldConfig.name + counter}>
                                {fieldService.getFormTemplate({
                                    code: _guslFormState.code,
                                    formMode: FormMode.NEW,
                                    isFirstField: index === firstField,
                                    fieldConfig,
                                    menuItem: properties.menuItem,
                                    data: _guslFormState?.formData[fieldConfig.name],
                                    rowData: _guslFormState?.formData,
                                    onSubmitted,
                                    onChange,
                                    onArrayChange,
                                    getCurrentRowData,
                                    isDialog: properties.isDialog,
                                    reference: {
                                        name: fieldConfig.name,
                                        displayOrder: index,
                                        register: registerChildReference,
                                    },
                                    counter: counter,
                                })}
                            </div>
                        )
                    })}
            </div>
        )
    }

    const renderResponseAsATable = (): React.ReactElement => {
        return (
            <TableWrapperStyled>
                <GuslTable
                    code={'new_entry_response'}
                    key={'new_entry_response'}
                    label={tableDefinition?.title || ''}
                    isSummaryHeader={false}
                    isSummaryFooter={false}
                    fields={tableDefinition?.rows ? tableDefinition?.rows[0].columns[0].fields : []}
                    tableData={tableDefinition?.content || []}
                    tableActions={[]}
                    rowActions={[]}
                    groupActions={[]}
                    selectUrl={undefined}
                    refreshRate={false}
                />
            </TableWrapperStyled>
        )
    }
    const renderResponseAsReport = (): React.ReactElement => {
        const onClose = () => {
            properties.closeDialog()
        }
        const cancelIcon = maintainTableService.getIconForCancel(properties?.menuItem?.singleForm?.cancelIcon)
        const cancelColorTheme = properties?.menuItem?.singleForm?.cancelColorTheme || 'defaultColor'

        return (
            <ReportWrapperStyled
                key={'form_' + counter}
                id={'panel-new-form-rep'}
                ref={reportContentElement}
                // startFormPos={reportStartPos}
            >
                <PanelFormStyled isEdit={true} isMobile={isMobile}>
                    <GuslReport code={id} data={report} />
                </PanelFormStyled>
                <ActionButtonContainerStyles controlOnTop={properties.controlOnTop}>
                    <ActionButtonStyled onClick={onClose} withMargin isAction colorName={cancelColorTheme}>
                        {cancelIcon && (
                            <>
                                <Icon icon={'fa-xmark fa-solid'} className={'action_icon'} />
                                <>&nbsp;</>
                            </>
                        )}
                        <span>Close</span>
                    </ActionButtonStyled>
                </ActionButtonContainerStyles>
            </ReportWrapperStyled>
        )
    }

    const renderForm = (): React.ReactElement => {
        if (report || tableDefinition?.hideForm) {
            return <></>
        }
        return (
            <PanelFormWrapper
                key={'form_' + counter}
                id={'panel-new-form-' + counter}
                isMobile={isMobile}
                isEdit={true}
                ref={contentElement}
                startFormPos={formStartPos}
                footerHeight={footerHeight}
            >
                <PanelFormStyled isEdit={true} isMobile={isMobile} key={'panel_form_' + counter}>
                    {renderFields()}
                </PanelFormStyled>
            </PanelFormWrapper>
        )
    }

    const renderPanelHeader = (): React.ReactElement => {
        return (
            <>
                {
                    <PanelHeaderWrapperStyled hasTitle={true} hasBanner={true}>
                        <div className="col d-flex align-items-center">
                            <PanelTitleStyled>
                                {translateService.getTitle(properties.menuItem?.code, properties?.actionConfig?.popUpTitle)}
                            </PanelTitleStyled>
                        </div>
                    </PanelHeaderWrapperStyled>
                }
            </>
        )
    }

    const renderPage = (): React.ReactElement => {
        return (
            <MaintainNewContainerStyled
                id={'maintain_new_container'}
                key={'maintain_new_container_' + (_guslFormState?.counter || 'a')}
                height={properties?.actionConfig?.minModalHeight || properties?.formProperties?.minModalHeight}
                width={properties?.actionConfig?.minModalWidth || properties?.formProperties?.minModalWidth}
            >
                <div className="">
                    {properties.controlOnTop && properties.formMode === FormMode.NEW && !report && renderActionBar()}
                    {properties.actionConfig?.popUpDescription && (
                        <PopupDescriptionStyled>{properties.actionConfig?.popUpDescription}</PopupDescriptionStyled>
                    )}
                </div>
                {renderErrorMessage()}
                {loading ? <LoadingSpinner /> : renderForm()}
                {report && renderResponseAsReport()}
                <div className="">
                    {!properties.controlOnTop && properties.formMode === FormMode.NEW && !report && renderActionBar()}
                    {properties.formMode === FormMode.ACTION_DIALOG && !report && renderActionBar()}
                </div>
                {tableDefinition && renderResponseAsATable()}
            </MaintainNewContainerStyled>
        )
    }

    return <>{loading ? <LoadingSpinner /> : renderPage()}</>
}
export default MaintainNewEntity
