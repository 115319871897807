import styled, { ThemeProps } from 'styled-components'
import { WidgetPanelProperties } from '../../types'

export const TabbedPageMenuBarStyled = styled.div.attrs((props) => ({
    className: 'col order-first order-md-last col-md-3 col-lg-3 col-xl-2',
}))`
    background-color: ${(props) => props.theme.navigation.sideMenu.bgColor};
    position: relative;
    z-index: 2;
`

export const GroupedActionsMenuBarStyled = styled.div.attrs((props) => ({}))`
    font-size: 12px;
    //margin-bottom: 10px;
    //margin-top: 20px;
    line-height: 25px;
`

interface GroupedActionProperties {
    active: boolean
}

export const GroupedActionsWrapperStyled = styled.div.attrs((props: GroupedActionProperties) => ({
    id: 'group_actions',
}))<GroupedActionProperties>`
    display: inline-block;
    padding-left: 15px;
    color: ${(props) => (props.active ? props.theme.table.columnColor : props.theme.colors.dark[1])};

    &:hover {
        text-decoration: ${(props) => (props.active ? 'underline' : 'none')};
    }
`
export const GroupedActionsBlankRowStyled = styled.div.attrs((props) => ({
    id: 'group_action_blank_row',
}))`
    line-height: 25px;
`

export const GroupedActionsTextStyled = styled.div.attrs((props) => ({}))`
    display: inline;
    font-size: 12px;
    margin-left: 5px;
`

interface TableTitleProperties extends ThemeProps<any> {
    isSummaryHeader?: boolean | undefined
    isSummaryFooter?: boolean | undefined
    isSubHeader?: boolean | undefined
    isReportSummary?: boolean | undefined
    tableStartPos: number
    isMobile?: boolean
    isFirstTable?: boolean

    isTabChild?: boolean
    isNested?: boolean

    width?: number
    height?: number
    footerHeight: number

    isWidget?: boolean
    widgetPanelProperties?: WidgetPanelProperties
}

/*
  ${props => props.isMobile && !props.isFirstTable && 'height: calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.tableStartPos + (props.isMobile ? FOOTER_HEIGHT + 10 : 10)) + 'px)'};

 */
export const ListTableContentStyled = styled.div.attrs((props: TableTitleProperties) => ({}))<TableTitleProperties>`
    ${(props) =>
        props.isMobile &&
        !props.isFirstTable &&
        'height: calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.tableStartPos + props.footerHeight) + 'px)'};
    ${(props) => !props.isMobile && 'height: auto;'};
    ${(props) => props.isMobile && 'overflow: overlay;'};

    ${(props) =>
        props.isWidget &&
        props?.widgetPanelProperties?.height &&
        'max-height: ' + (props?.widgetPanelProperties?.height - (props.tableStartPos - (props.widgetPanelProperties?.y || 0))) + 'px;'};
    ${(props) =>
        props.isWidget &&
        props?.widgetPanelProperties?.height &&
        'height: ' + (props?.widgetPanelProperties?.height - (props.tableStartPos - (props.widgetPanelProperties?.y || 0))) + 'px;'};

    ${(props) => props.isWidget && 'overflow-x: clip;'};
`
export const TableSpinnerWrapperStyled = styled.div.attrs((props) => ({}))`
    width: 100%;
`
