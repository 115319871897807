import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../app/hooks'
import { BlastContext } from '../../../providers/blast/BlastContext'
import { EnvironmentContext } from '../../../providers/environment/EnvironmentContext'
import { environmentService } from '../../../providers/environment/EnvironmentService'
import { SessionContext } from '../../../providers/session/SessionContext'
import { GuslThemeContext } from '../../../providers/theme/GuslThemeProvider'
import { getSettings } from '../../../services/ValidationService'
import { assignReferences, extractExtraFieldProperties, noop, RunOnceEffect, unSubscribe } from '../../../utils/Utils'
import { BreadcrumbDO, BreadcrumbsDO, FieldProperties, FormMode } from '../../types'
import { watchBlastDeltas } from '../../ui/FieldUtils'
import FieldLabel from '../field-label/FieldLabel'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { ExtraFieldProperties } from '../money/types'
import { FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled } from '../text/styled'
import { BreadcrumbStyled, BreadcrumbWrapperStyled, SlashStyled } from './styled'

export const BreadcrumbField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const sessionContext = React.useContext(SessionContext)
    const blastContext = useContext(BlastContext)

    const navigate = useNavigate()

    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'CohortLogoField-' + new Date().getTime())

    const environmentContext = React.useContext(EnvironmentContext)
    const guslThemeContext = React.useContext(GuslThemeContext)

    const [trueColor] = useState<string>(
        guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).money.positiveColor
    )
    const [falseColor] = useState<string>(
        guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).money.negativeColor
    )

    const [formMode, setFormMode] = useState(properties.formMode)
    // const [formValue, setFormValue] = useState<BreadcrumbsDO>(properties?.data || []);
    const [formValue, setFormValue] = useState<BreadcrumbsDO>(_guslFormState?.getFieldValue(properties) || properties.data || [])

    const [refreshCounter, setRefreshCounter] = useState<number>(1)
    const [extraFieldProperties] = useState<ExtraFieldProperties>(extractExtraFieldProperties(properties))

    useEffect(() => {
        const [loaderSubscription, blastDeltaSubscription, valueObservable] = watchBlastDeltas(
            sessionContext,
            blastContext,
            properties,
            formMode
        )
        const valueSubscription = valueObservable.subscribe((newValue: any) => {
            if (newValue) {
                setFormValue(newValue)
                setRefreshCounter(refreshCounter + 1)
            }
        })
        return () => {
            unSubscribe(loaderSubscription)
            unSubscribe(blastDeltaSubscription)
            unSubscribe(valueSubscription)
        }
    }, [properties])

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setFormValue(properties?.data || [])
    }

    useEffect(() => {
        setFormValue(properties?.data || [])
    }, [properties])

    const doValidation = (fieldValue: any): boolean => {
        return true
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        const onBreadcrumbClick = (crumb: BreadcrumbDO) => {
            // console.log('onBreadcrumbClick', crumb)
            if (crumb.route) {
                sessionContext.setQueryParams(JSON.stringify(crumb.queryParams))
                navigate('/' + crumb.route)
            }
        }
        return (
            <BreadcrumbWrapperStyled>
                {formValue?.crumbs?.map((crumb: BreadcrumbDO, idx: number) => (
                    <BreadcrumbStyled
                        key={properties.fieldConfig.name + '_' + idx + '_' + refreshCounter}
                        onClick={() => onBreadcrumbClick(crumb)}
                    >
                        <SlashStyled idx={idx}>{idx > 0 ? ' / ' : ''}</SlashStyled>
                        {crumb.label}
                    </BreadcrumbStyled>
                ))}
            </BreadcrumbWrapperStyled>
        )
    }
    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        return (
            <>
                {!hideField && (
                    <FloatingFormStyled>
                        <FieldContentWrapperStyled>
                            <FieldContentStyled>{renderTableView()}</FieldContentStyled>
                        </FieldContentWrapperStyled>
                        <FieldLabel properties={properties} />
                    </FloatingFormStyled>
                )}
            </>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
