import React, { useContext, useEffect, useState } from 'react'
import { BlastContext } from '../../../../providers/blast/BlastContext'
import { safeStream, unSubscribe } from '../../../../utils/Utils'
import { IMenuDTO, MenuItemBadgeMessageBO } from '../../../types'
import { DEFAULT_BADGE_BG_COLOR, DEFAULT_BADGE_COLOR } from '../../FieldUtils'
import WithTooltip from '../../WithTooltip'
import { BadgeContentsStyled, BadgeStyled } from './styled'

export type BadgeProperties = {
    menuItem: IMenuDTO
}

export const Badge = (properties: BadgeProperties): React.ReactElement => {
    const blastContext = useContext(BlastContext)

    const [badgeValue, setBadgeValue] = useState<number>(0)
    const [color, setColor] = useState<string>(DEFAULT_BADGE_COLOR)
    const [backgroundColor, setBackgroundColor] = useState<string>(DEFAULT_BADGE_BG_COLOR)
    const [tooltip, setTooltip] = useState<string | undefined>(undefined)

    useEffect(() => {
        const activeUsersSubscription = blastContext.observeCollection('menuitem.badges').subscribe((data: MenuItemBadgeMessageBO[]) => {
            safeStream(data)
                .filter((menuItemBadgeMessage) => menuItemBadgeMessage.menuCode === properties?.menuItem?.code)
                .forEach((menuItemBadgeMessage) => {
                    setBadgeValue(menuItemBadgeMessage.value)
                    setColor(menuItemBadgeMessage.color)
                    setBackgroundColor(menuItemBadgeMessage.backgroundColor)
                    setTooltip(menuItemBadgeMessage.tooltip)
                })
        })
        return () => {
            unSubscribe(activeUsersSubscription)
        }
    }, [blastContext])

    if (badgeValue === 0) {
        return <></>
    }
    return (
        <BadgeStyled backgroundColor={backgroundColor}>
            <BadgeContentsStyled color={color}>
                <WithTooltip text={tooltip}>
                    <span>{badgeValue}</span>
                </WithTooltip>
            </BadgeContentsStyled>
        </BadgeStyled>
    )
}
