import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { getSettings, performValidation } from '../../../services/ValidationService'
import { getStyle } from '../../../utils/CssUtils'
import { assignReferences, extractExtraFieldProperties, noop, RunOnceEffect } from '../../../utils/Utils'
import { FieldProperties, FormMode } from '../../types'
import FieldLabel from '../field-label/FieldLabel'
import { TableColumnResizeState } from '../gusl-table/guslColumnResizeSlice'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { maintainTableService } from '../maintain-table/MaintainTableService'
import { ExtraFieldProperties } from '../money/types'
import { FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled, HintStyled, TextFieldStyled } from '../text/styled'
import { TextAreaStyled } from './styled'

export const TextAreaField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    // const [className] = React.useState<string>(() => 'TextFormField-' + new Date().getTime());
    const [formMode, setFormMode] = useState(properties.formMode)
    // const [formValue, setFormValue] = useState<string>(properties?.data || '');
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) || properties.data || '')

    const valueRef = useRef(properties?.data)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [submitted, setSubmitted] = useState(false)
    const [extraFieldProperties] = useState<ExtraFieldProperties>(() => extractExtraFieldProperties(properties))
    const [numberRows] = useState<number>(() => {
        const numRows = extractExtraFieldProperties(properties)?.rows || 10
        const lines = formValue.split('\n').length || 0
        console.log('lines', lines, numRows)
        // return (lines > 0 && lines  < numRows) ? lines + 2 : numRows
        return numRows
    })

    // if you want ellipsis on text field ... need to pass cellWidth to TextFieldStyled
    const code: string = maintainTableService.getCodeFromUrl()
    const _columnResizeState: TableColumnResizeState = useAppSelector((state) => state.guslColumnResizeSlice[code])
    const cellWidth: number | string | undefined = _columnResizeState?.resizedColumns[properties.fieldConfig.name]

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setFormValue(properties?.data || '')
    }

    useEffect(() => {
        setFormValue(properties?.data || '')
    }, [properties])

    const doValidation = (fieldValue: any): boolean => {
        return performValidation(formMode, properties.menuItem?.code, properties.fieldConfig, fieldValue, setSubmitted, setErrorMessage)
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.fieldConfig.tableCss, '')

        return (
            <TextFieldStyled textAlign={properties.fieldConfig.align} cellWidth={cellWidth}>
                {/*<div className="" style={style} dangerouslySetInnerHTML={{__html: formValue}}></div>*/}

                <TextAreaStyled
                    style={style}
                    cols={4}
                    id={properties?.fieldConfig?.name || 'def'}
                    key={properties?.fieldConfig?.name || 'def'}
                    ref={valueRef}
                    value={formValue}
                    autoFocus={properties.isFirstField}
                    readOnly={true}
                    disabled={true}
                    submitted={submitted}
                    noValue={!formValue}
                />
            </TextFieldStyled>
        )
    }

    const renderFormView = (): React.ReactElement => {
        const onDataInputChange = (value: string) => {
            if (properties.isNumber) {
                try {
                    const number = parseInt(value, 10)
                    if ('' + number === value) {
                        setFormValue(value)
                        properties.onChange(properties.fieldConfig.name, parseInt(value, 10))
                    }
                } catch (err) {
                    // log.warn(className, 'WRN001', 'not a number', value);
                }
            } else {
                setFormValue(value)
                properties.onChange(properties.fieldConfig.name, valueRef?.current?.value)
            }
        }

        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        //console.log('properties.fieldConfig', properties.fieldConfig)
        // @ts-ignore

        return (
            <>
                {!hideField && (
                    <>
                        <FloatingFormStyled>
                            <FieldContentWrapperStyled>
                                <FieldContentStyled>
                                    <TextAreaStyled
                                        cols={extraFieldProperties.cols}
                                        rows={numberRows}
                                        id={properties?.fieldConfig?.name || 'def'}
                                        key={properties?.fieldConfig?.name || 'def'}
                                        ref={valueRef}
                                        value={formValue}
                                        autoFocus={properties.isFirstField}
                                        readOnly={disableField}
                                        disabled={disableField}
                                        onChange={(e) => onDataInputChange(e.target.value)}
                                        required={required}
                                        submitted={submitted}
                                        noValue={!formValue}
                                    />
                                </FieldContentStyled>
                                {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                                {submitted && errorMessage && <small className="yellow">{errorMessage}</small>}
                            </FieldContentWrapperStyled>
                            <FieldLabel properties={properties} />
                        </FloatingFormStyled>
                    </>
                )}
            </>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
