import React, { useContext, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { columnSettingsClosed, filtersClosed, sidePanelClosed } from '../../../../../features/ui/uiSlice'
import { EnvironmentContext } from '../../../../../providers/environment/EnvironmentContext'
import { SystemContext } from '../../../../../providers/system/SystemContext'
import { canShowColumnSettings, canShowFilters, canShowRefresh, canShowSearch } from '../../../../../utils/Utils'
import { IMenuDTO, QueryParamsDTO } from '../../../../types'
import { ElementWithTooltip } from '../../../element-with/element-with-tooltip/ElementWithTooltip'
import { maintainTableService } from '../../../maintain-table/MaintainTableService'
import { TableControlsDO } from '../../filters/types'
import GetDefaultSearchableFields from '../../GetDefaultSerarchableFields'
import { resetColumnHeaders, TableColumnResizeState } from '../../guslColumnResizeSlice'
import {
    resetSearchableField,
    setSearchString,
    toggleColumnsSettings,
    toggleFilters,
    toggleSearchBox,
    toggleTopFilters,
} from '../../guslTableSlice'
import { InlineEditState, toggleInlineEdit } from '../../inline-edit/inlineEditSlice'
import SaveRowsButton from '../../inline-edit/SaveRowsButton'
import { ROWS_PER_PAGE } from '../../PaginationService'
import { GuslTableQueryParamState, refreshListView, resetFilters } from '../../queryParamsSlice'
import { ActionIconStyled } from '../../styled/table-controls/styled'
import { SidePanelsControlStyled } from './styled'

// MK 27/09/2023 added withLimit to persist rows per table
export function hasFiltersApplied(queryParams: QueryParamsDTO | undefined, withLimit: boolean = false) {
    if (!queryParams) {
        return false
    }

    if (withLimit) {
        if (queryParams?.limit !== ROWS_PER_PAGE[0]) {
            return true
        }
    }

    let filtersApplied: boolean = false
    // MK 11/08/2023 added ins
    const filters: Array<keyof QueryParamsDTO> = ['should', 'musts', 'mustNots', 'rangeQueries', 'ins' /*,"orderBys"*/]

    filters.forEach((filter) => {
        try {
            /*@ts-ignore*/
            if (queryParams && queryParams[filter] && queryParams[filter].length) {
                filtersApplied = true
            }
        } catch (e) {
            //pass
        }
    })

    return filtersApplied
}

export function hasOrderBysApplied(queryParams: QueryParamsDTO | undefined) {
    if (!queryParams) {
        return false
    }
    let oderBysApplied: boolean = false
    const filters: Array<keyof QueryParamsDTO> = ['orderBys']

    filters.forEach((filter) => {
        try {
            /*@ts-ignore*/
            if (queryParams && queryParams[filter] && queryParams[filter].length) {
                oderBysApplied = true
            }
        } catch (e) {
            //pass
        }
    })

    return oderBysApplied
}

export default function SidePanelControls({
    code,
    state,
    haveFilters,
    isHeader,
    isFooter,
    hasAdvancedFilters,
    widgetPanelProperties,
}: TableControlsDO) {
    const [className] = useState('SidePanelControls-' + new Date().getTime())
    const environmentContext = useContext(EnvironmentContext)
    const systemContext = useContext(SystemContext)

    const [menuItem] = useState<IMenuDTO | undefined>(systemContext.getMenuConfig(code))

    const hasInlineEdit: boolean = menuItem?.updateMany !== undefined && menuItem.updateMany.length > 0

    const dispatch = useAppDispatch()
    const queryParamsState: GuslTableQueryParamState = useAppSelector((state) => state.queryParamsSlice[code])
    const _columnResizeState: TableColumnResizeState = useAppSelector((state) => state.guslColumnResizeSlice[code])
    // MK 17-02-2024
    const inlineEditState: InlineEditState = useAppSelector((state) => state.inlineEditSlice[code])
    const isInlineEditMode: boolean = inlineEditState?.inlineEdit

    const isMobile = environmentContext.isMobileDevice(widgetPanelProperties)
    const orderByField: string | undefined = queryParamsState?.queryParams?.orderBys?.[0]?.field
    const orderByOrder: string | undefined = queryParamsState?.queryParams?.orderBys?.[0]?.order

    const [showTableFilters] = useState<boolean>(state.showFilters)
    const [showTopTableFilters] = useState<boolean>(state.showTopFilters)
    const [showColumnsSettings] = useState<boolean>(state.showColumnsSettings)
    const [showSearchBox] = useState<boolean>(state.showSearchBox)
    const [haveSearchBox] = useState<boolean>(state.haveSearchBox)
    const defaultSearchableFields = GetDefaultSearchableFields({ code })
    // MK 12/08/2023 it is failing when _columnResizeState -> undefined
    const areColumnsResized = _columnResizeState ? Object.keys(_columnResizeState.resizedColumns).length > 0 : false
    const filtersApplied: boolean = hasFiltersApplied(queryParamsState?.queryParams)
    const oderBysApplied: boolean = hasOrderBysApplied(queryParamsState?.queryParams)
    const [showColumnSettings] = useState<boolean>(
        canShowColumnSettings(environmentContext.getCurrentMediaType(widgetPanelProperties), state.tableControl)
    )
    const [showFilter] = useState<boolean>(
        canShowFilters(environmentContext.getCurrentMediaType(widgetPanelProperties), state.tableControl)
    )
    const [showRefresh] = useState<boolean>(
        canShowRefresh(environmentContext.getCurrentMediaType(widgetPanelProperties), state.tableControl)
    )
    const [showSearch] = useState<boolean>(canShowSearch(environmentContext.getCurrentMediaType(widgetPanelProperties), state.tableControl))
    const fields = (menuItem && maintainTableService.extractAllFields(menuItem)) || []
    let orderByLabel: string | undefined
    if (orderByField) {
        try {
            orderByLabel = fields.filter((field) => field.name === orderByField)[0].label
        } catch (e) {
            orderByLabel = orderByField
            //pass
        }
    }

    function toggleFiltersHandler() {
        if (state.showFilters) {
            dispatch(sidePanelClosed())
            dispatch(filtersClosed())
        }
        dispatch(toggleFilters({ code: code }))
    }

    function toggleTopFiltersHandler() {
        dispatch(toggleTopFilters({ code: code }))
    }

    function toggleColumnSettingsHandler() {
        if (state.showColumnsSettings) {
            dispatch(sidePanelClosed())
            dispatch(columnSettingsClosed())
        }
        dispatch(toggleColumnsSettings({ code: code }))
    }

    function resetFiltersHandler() {
        // MK 27/09/2023
        const searchElement = document.getElementById('search_' + code) as HTMLInputElement | null
        if (searchElement) {
            searchElement.value = ''
        }

        dispatch(resetFilters({ code }))
        dispatch(resetSearchableField({ code, defaultSearchableFields }))
        dispatch(setSearchString({ code, searchString: '' }))
    }

    function resetColumnsHeadersHandler() {
        dispatch(resetColumnHeaders({ code: code }))
        dispatch(refreshListView({ code }))
    }

    return (
        <SidePanelsControlStyled isMobile={isMobile}>
            {isInlineEditMode && <SaveRowsButton code={code} widgetPanelProperties={widgetPanelProperties} />}
            {hasInlineEdit && (
                <ActionIconStyled isMobile={isMobile} active={inlineEditState?.inlineEdit || false}>
                    <ElementWithTooltip
                        element={
                            <i
                                role={'button'}
                                onClick={() => dispatch(toggleInlineEdit({ code }))}
                                className={'fa-solid fa-pencil p-1 me-2  '}
                            />
                        }
                        tooltip={<span>Toggle inline edit</span>}
                    />
                </ActionIconStyled>
            )}
            {hasAdvancedFilters && !isMobile && (
                <>
                    <ActionIconStyled active={state.showTopFilters} isMobile={isMobile} filtersApplied={filtersApplied}>
                        <ElementWithTooltip
                            element={
                                <i
                                    data-cy={'filters'}
                                    role={'button'}
                                    onClick={toggleTopFiltersHandler}
                                    className={'fa-solid fa-magnifying-glass-plus me-2 '}
                                />
                            }
                            tooltip={<span>{state.showTopFilters ? 'Close' : 'Open'} advanced filters</span>}
                        />
                    </ActionIconStyled>
                </>
            )}
            {areColumnsResized && (
                <ActionIconStyled isMobile={isMobile}>
                    <ElementWithTooltip
                        element={
                            <i
                                role={'button'}
                                onClick={resetColumnsHeadersHandler}
                                className={'fa-solid fa-table-columns fa-rotate-180 p-1 me-2  '}
                            />
                        }
                        tooltip={<span>Reset resized columns</span>}
                    />
                </ActionIconStyled>
            )}
            {showRefresh && !isHeader && !isFooter && (
                <ActionIconStyled isMobile={isMobile}>
                    <ElementWithTooltip
                        element={
                            <i
                                className={'fa-solid fa-refresh me-2'}
                                onClick={() => {
                                    dispatch(refreshListView({ code }))
                                }}
                            />
                        }
                        tooltip={<span>Refresh data.</span>}
                    />
                </ActionIconStyled>
            )}
            {showSearch && haveSearchBox && (
                <ActionIconStyled isMobile={isMobile} active={showSearchBox}>
                    <ElementWithTooltip
                        element={
                            <i
                                className={'fa-solid fa-search me-2'}
                                onClick={() => {
                                    dispatch(toggleSearchBox({ code }))
                                }}
                            />
                        }
                        tooltip={<span>Search</span>}
                    />
                </ActionIconStyled>
            )}
            {showFilter && haveFilters && (
                <>
                    <ActionIconStyled active={state.showFilters} isMobile={isMobile} filtersApplied={filtersApplied}>
                        <ElementWithTooltip
                            element={
                                <i
                                    data-cy={'filters'}
                                    role={'button'}
                                    onClick={toggleFiltersHandler}
                                    className={'fa-solid fa-filter me-2 '}
                                />
                            }
                            tooltip={<span>{state.showFilters ? 'Close' : 'Open'} filters</span>}
                        />
                    </ActionIconStyled>
                    <ActionIconStyled isMobile={isMobile}>
                        <ElementWithTooltip
                            element={<i className={'fa-solid fa-filter-circle-xmark me-2'} onClick={resetFiltersHandler} />}
                            tooltip={<span>Reset filters.</span>}
                        />
                    </ActionIconStyled>
                </>
            )}

            {showColumnSettings && (
                <ActionIconStyled active={showColumnsSettings} filtersApplied={oderBysApplied}>
                    <ElementWithTooltip
                        element={<i role={'button'} onClick={toggleColumnSettingsHandler} className={'fa-solid fa-sliders p-1  '} />}
                        tooltip={
                            <span>
                                {showColumnsSettings ? 'Close' : 'Open'} Columns Setting
                                {orderByField && orderByOrder && (
                                    <span>
                                        {' '}
                                        <br />
                                        Ordered by : {orderByLabel} <br />
                                        Order: {orderByOrder}
                                    </span>
                                )}
                            </span>
                        }
                    />
                </ActionIconStyled>
            )}
        </SidePanelsControlStyled>
    )
}
