import styled, { ThemeProps } from 'styled-components'

interface ActionProperties extends ThemeProps<any> {
    onClick: Function
    isActive: boolean
}

export const ActionStyled = styled.span.attrs((props: ActionProperties) => ({
    onClick: props.onClick,
}))<ActionProperties>`
    color: ${(props) =>
        props.color
            ? props.active
                ? props.theme.colors.background[1]
                : props.color
            : props.isActive
            ? props.theme.colors.light
            : props.theme.colors.dark[1]};

    background-color: ${(props) => (props.isActive ? props.theme.colors.accentColorMuted : props.theme.colors.dark[0])};
    font-size: 12px;
    margin: 5px 3px;
    border-radius: 2px;
    padding: 4px 5px;
    text-align: center;
    :hover {
        cursor: pointer;
        color: ${(props) => props.theme.colors.light};
        background-color: ${(props) => props.theme.colors.accentColorMuted};
    }
    ////

    // color:${(props) => (props.isActive ? props.theme.colors.dark[0] : props.theme.colors.accentColorLight)};
    // background-color:${(props) => (props.isActive ? props.theme.colors.accentColorLight : 'transparent')};
    //border-radius:3px;
    //margin:5px 5px;
    //padding:2px 5px;
    //font-size:12px;
    // :hover{
    //     background-color:${(props) => props.theme.colors.accentColorLight};
    //     color:${(props) => props.theme.colors.dark[0]};
    // }
`
