import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import React from 'react'
import { Layout, Layouts } from 'react-grid-layout'
import { guslStorage } from '../../providers/session/GuslStorage'
import { SystemProps } from '../../providers/system/SystemContext'
import { clone, safeStream } from '../../utils/Utils'
import { WidgetDO } from '../types'

// Define a type for the slice state
export interface DashboardState {
    widgets: WidgetDO[]
    openWidgets: WidgetDO[]
    menuWidgets: WidgetDO[]
    instanceCounter: number
    layouts: Layouts | undefined
    breakpoints: any
    cols: any
    rowHeight: number
    createWidget?: (widget: WidgetDO) => React.JSX.Element
    children: React.JSX.Element[]
    scale: number
    refreshCounter: number
    width: number
    ready: boolean
}

// Define the initial state using that type
const initialState: DashboardState = {
    widgets: [],
    openWidgets: [],
    menuWidgets: [],
    instanceCounter: 1,
    layouts: undefined,
    breakpoints: { xlg: 3800, lg: 3800, md: 996, sm: 768, xs: 480, xxs: 0 },
    cols: { xlg: 36, lg: 36, md: 10, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 30,
    children: [],
    scale: 1.0,
    refreshCounter: 0,
    width: 1,
    ready: false,
}

export interface DashboardInitPayload {
    systemContext: SystemProps
    createWidget: (widget: WidgetDO) => React.JSX.Element
}

export interface DashboardOpenPayload {
    code: string
}

export interface DashboardClosePayload {
    instanceId: number
}

export interface LayoutChangedPayload {
    layout: ReactGridLayout.Layout[]
}

export interface LayoutResizedPayload {
    layouts: ReactGridLayout.Layouts
}

export interface ScaleChangePayload {
    scale: number
    instanceId: number
}

export interface PageResizedPayload {
    width: number
}

export interface ContainerWidthChangePayload {
    containerWidth: number
    margin: [number, number]
    cols: number
    containerPadding: [number, number]
}

const loadInitValues = (state: DashboardState, action: DashboardInitPayload) => {
    state.createWidget = action.createWidget

    let counter = 1
    state.widgets = safeStream(action.systemContext.getAllWidgets()).map((widget: WidgetDO) => {
        const cloned = clone(widget)
        cloned.instanceId = counter++
        cloned.i = 'widget_' + widget.code + '_' + cloned.instanceId
        cloned.refreshCounter = 1
        cloned.scale = widget.scale || 1
        return cloned
    })
    state.instanceCounter = counter
    state.openWidgets = safeStream(state.widgets).filter((widget) => widget.open)
    if (state.createWidget) {
        // @ts-ignore
        state.children = safeStream(state.openWidgets).map((widget) => state.createWidget(widget))
    }
    updateWidgets(state)
    loadLayout(state)
    state.ready = true
}

const updateWidgets = (state: DashboardState) => {
    // state.openWidgets = safeStream(state.widgets).filter(widget => widget.open)
    // console.log('state.widgets', state.widgets)
    // console.log('state.openWidgets', state.openWidgets)
    // loadLayout(state)
}
const performCloseWidget = (state: DashboardState, action: DashboardClosePayload) => {
    state.openWidgets = safeStream(state.openWidgets).filter((widget: WidgetDO) => widget.instanceId !== action.instanceId)
    updateWidgets(state)
}

const performOpenWidget = (state: DashboardState, action: DashboardOpenPayload) => {
    // simple
    const newWidget: WidgetDO = clone(safeStream(state.widgets).find((widget: WidgetDO) => widget.code === action.code))
    newWidget.open = true
    state.openWidgets.push(newWidget)
    updateWidgets(state)

    // const matchingWidgets: WidgetDO[] = safeStream(state.openWidgets).filter((widget: WidgetDO) => widget.code === action.code)
    // if (matchingWidgets.length === 0) {
    //     // no matching widget open - open one
    //     safeStream(state.widgets).filter((widget: WidgetDO) => widget.code === action.code).forEach((widget: WidgetDO) => widget.open = true)
    //     updateWidgets(state)
    // } else {
    //
    // }
    // const widget:WidgetDO = safeStream(state.widgets).find((widget: WidgetDO) => widget.code === action.code)
}

const performLayoutChanged = (state: DashboardState, action: LayoutChangedPayload) => {
    //console.log('-- performLayoutChanged')
    //state.refreshCounter = state.refreshCounter + 1
    // const layouts = {
    //     lg: action.layout
    // }
    // guslStorage.saveDashboardLayout("layouts", layouts)
    // if (state.layouts) {
    //     state.layouts['lg'] = action.layout
    // }
}
const performLayoutResized = (state: DashboardState, action: LayoutResizedPayload) => {
    // console.log('-- performLayoutResized', action)
    if (state.layouts) {
        state.layouts['lg'] = action.layouts['lg']
    }
    guslStorage.saveDashboardLayout('layouts', state.layouts)
    // state.refreshCounter = state.refreshCounter + 1
    // safeStream(state.widgets).forEach(widget => widget.refreshCounter  = widget.refreshCounter  + 1)
}
const performContainerWidthChange = (state: DashboardState, action: ContainerWidthChangePayload) => {
    // console.log('performContainerWidthChange', state.width, action.containerWidth, state.width === action.containerWidth)
    // if (state.width === action.containerWidth) {
    //     console.log('performContainerWidthChange', action)
    // }
}
const performScaleChange = (state: DashboardState, action: ScaleChangePayload) => {
    // state.scale = action.scale
    // console.log('------------- performScaleChange')
    safeStream(state.openWidgets)
        .filter((widget: WidgetDO) => widget.instanceId === action.instanceId)
        .forEach((widget) => (widget.scale = 2))
}

const performPageResize = (state: DashboardState, action: PageResizedPayload) => {
    // console.log('performPageResize', state.width, action.width, state.width === action.width)
    state.width = action.width
    state.refreshCounter = state.refreshCounter + 1
}

const loadLayout = (state: DashboardState) => {
    // const fromStorage = guslStorage.getDashboardLayout("layouts")
    // if (fromStorage) {
    //     state.layouts = fromStorage
    //     return
    // }

    const lg: Layout[] = safeStream(state.openWidgets).map((widget: any, idx: number) => {
        const layout: any = {
            i: widget.i,
        }
        const layoutProperties = ['x', 'y', 'w', 'h', 'minW', 'maxW', 'minH', 'maxH', 'draggable', 'resizable'] // , 'bounded'
        for (let i = 0; i < layoutProperties.length; i++) {
            if (widget[layoutProperties[i]] && widget[layoutProperties[i]] !== -1) {
                layout[layoutProperties[i]] = widget[layoutProperties[i]]
                // layout['w'] = 2
                // layout['w'] = 4
                // layout['h'] = 14
            } else if (layoutProperties[i] === 'x') {
                layout[layoutProperties[i]] = 0
            }
        }
        return layout
    })
    state.layouts = {
        lg: lg,
    }
}

export const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState,
    reducers: {
        initDashboard(state, action: PayloadAction<DashboardInitPayload>) {
            loadInitValues(state, action.payload)
        },
        openWidget(state, action: PayloadAction<DashboardOpenPayload>) {
            performOpenWidget(state, action.payload)
        },
        closeWidget(state, action: PayloadAction<DashboardClosePayload>) {
            performCloseWidget(state, action.payload)
        },
        layoutChanged(state, action: PayloadAction<LayoutChangedPayload>) {
            performLayoutChanged(state, action.payload)
        },
        layoutResized(state, action: PayloadAction<LayoutResizedPayload>) {
            performLayoutResized(state, action.payload)
        },
        containerWidthChange(state, action: PayloadAction<ContainerWidthChangePayload>) {
            performContainerWidthChange(state, action.payload)
        },
        scaleChange(state, action: PayloadAction<ScaleChangePayload>) {
            performScaleChange(state, action.payload)
        },
        pageResized(state, action: PayloadAction<PageResizedPayload>) {
            performPageResize(state, action.payload)
        },
    },
})

export const { initDashboard, openWidget, closeWidget, layoutChanged, layoutResized, containerWidthChange, scaleChange, pageResized } =
    dashboardSlice.actions

export default dashboardSlice.reducer
