import { toast } from 'react-toastify'
import { ToastContent, ToastOptions, TypeOptions } from 'react-toastify/dist/types'
import { NotificationType, SystemNotificationDTO } from '../components/types'

const NotificationPositionMap: { [id: string]: any } = {
    TOP_LEFT: toast.POSITION.TOP_LEFT,
    TOP_RIGHT: toast.POSITION.TOP_RIGHT,
    TOP_CENTER: toast.POSITION.TOP_CENTER,
    BOTTOM_LEFT: toast.POSITION.BOTTOM_LEFT,
    BOTTOM_RIGHT: toast.POSITION.BOTTOM_RIGHT,
    BOTTOM_CENTER: toast.POSITION.BOTTOM_CENTER,
}

export const extractToastMessage = (notification: SystemNotificationDTO): ToastContent => {
    return notification.message
}
export const extractToastOptions = (notification: SystemNotificationDTO): ToastOptions => {
    let type: TypeOptions = 'info'
    switch (notification.type) {
        case NotificationType.SUCCESS:
            type = 'success'
            break
        case NotificationType.WARNING:
            type = 'warning'
            break
        case NotificationType.INFO:
            type = 'info'
            break
        case NotificationType.ERROR:
            type = 'error'
            break
    }
    let position = NotificationPositionMap[notification.position]
    if (!position) {
        position = toast.POSITION.BOTTOM_RIGHT
    }
    let autoClose: any = 5000
    if (notification.noAutoClose) {
        autoClose = false
    } else if (notification.autoCloseDuration) {
        autoClose = notification.autoCloseDuration
    }
    const options: ToastOptions = {
        type: type,
        position: position,
        autoClose: autoClose,
        theme: 'light',
    }
    return options
}
