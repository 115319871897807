import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { SessionContext } from '../../../../providers/session/SessionContext'
import { cancelAbortController, getCssValue, RunOnceEffect } from '../../../../utils/Utils'
import { ActionDialogHeaderStyled, ActionDialogTitleStyled } from '../../../common/action-dialog/styled'
import { DraggableModalDialog } from '../../../common/draggable-modal/draggable-modal'
import { closeGlobalSearchResults, closeResultDialog } from '../../../common/global-search/GlobalSearchSlice'
import TableSpinner from '../../../common/gusl-table/spinner/TableSpinner'
import { WysiwygTableContentStyled } from '../../../common/wysiwyg/styled'
import { ArticleInlineDateStyled, GoToRouteStyled } from '../articles-view/styled'
import { ArticleViewDialogBodyStyled, ArticleViewWrapperStyled } from '../other-deals-view/styled'
import { ArticleTextStyle, OttoBoxNoBgStyled, ScrollBox } from '../styled'
import { ArticleModalState, cleanUpArticleModal, getArticle } from './articleModalSlice'

interface ArticleModalProperties {
    articleId: string
    returnUrl?: string
    routeUrl?: string
    routeLabel?: string
    onHideCallBack?: () => void
}

const ArticleModal = ({ articleId, returnUrl, routeUrl, routeLabel, onHideCallBack }: ArticleModalProperties): React.ReactElement => {
    const [loading, setLoading] = useState<boolean>(true)
    const sessionContext = React.useContext(SessionContext)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const _articleModalSlice: ArticleModalState = useAppSelector((state) => state.articleModalSlice)

    useEffect(() => {
        const abortController = new AbortController()
        dispatch(
            getArticle({
                sessionContext: sessionContext,
                abortController: abortController,
                articleId: articleId,
            })
        )
            .unwrap()
            .then(() => {
                setLoading(false)
            })
            .catch((error: any) => {
                console.error('error', error)
                setLoading(false)
            })

        return () => {
            cancelAbortController(abortController)
            dispatch(cleanUpArticleModal({}))
        }
    }, [articleId])

    RunOnceEffect(() => {
        let root = document.documentElement
        // root.style.setProperty('--modal-width', '700px');
        root.style.setProperty('--modal-width', 'calc(100vw - 100px)')
        root.style.setProperty('--modal-height', 'calc(100vh - 250px)')
        root.style.setProperty('--modal-margin-top', '130px')
    })

    const onHide = () => {
        if (returnUrl) {
            navigate(returnUrl || '')
        } else if (onHideCallBack) {
            onHideCallBack()
        } else {
            dispatch(closeResultDialog({}))
        }
    }

    const renderRoute = (): React.ReactElement => {
        if (!routeUrl) {
            return <></>
        }
        const gotToRoute = () => {
            dispatch(closeResultDialog({}))
            dispatch(closeGlobalSearchResults({}))
            navigate(routeUrl)
        }

        return <GoToRouteStyled onClick={() => gotToRoute()}>{routeLabel}</GoToRouteStyled>
    }

    const renderArticle = (): React.ReactElement => {
        return (
            <div className={'p-2'}>
                <ArticleInlineDateStyled>
                    {_articleModalSlice?.selectedArticle?.articleDate} - {_articleModalSlice?.selectedArticle?.articleTime || ''}
                </ArticleInlineDateStyled>
                <ScrollBox height={'calc(100vh - 300px)'}>
                    <ArticleTextStyle>
                        <WysiwygTableContentStyled
                            dangerouslySetInnerHTML={{
                                __html: _articleModalSlice?.selectedArticle?.content || '',
                            }}
                        ></WysiwygTableContentStyled>
                    </ArticleTextStyle>
                </ScrollBox>
                {renderRoute()}
            </div>
        )
    }
    const renderSpinner = (): React.ReactElement => {
        return <TableSpinner />
    }

    return (
        <Modal
            dialogAs={DraggableModalDialog}
            centered
            show={true}
            keyboard={true}
            onHide={() => onHide()}
            backdrop={true}
            dialogClassName="modal-wrapper"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <ActionDialogHeaderStyled closeButton={true}>
                <ActionDialogTitleStyled>{_articleModalSlice?.selectedArticle?.subject}</ActionDialogTitleStyled>
            </ActionDialogHeaderStyled>
            <ArticleViewDialogBodyStyled>
                <ArticleViewWrapperStyled>
                    <OttoBoxNoBgStyled>
                        {!loading && renderArticle()}
                        {loading && renderSpinner()}
                    </OttoBoxNoBgStyled>
                </ArticleViewWrapperStyled>
            </ArticleViewDialogBodyStyled>
        </Modal>
    )
}
export default ArticleModal
