import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { BlastContext } from '../../../../providers/blast/BlastContext'
import { SessionContext } from '../../../../providers/session/SessionContext'
import { cancelAbortController, RunOnceEffect, safeStream, unSubscribe } from '../../../../utils/Utils'
import paginationService from '../../../common/gusl-table/PaginationService'
import { QueryParamsDTO } from '../../../types'
import ArticleModal from '../article-modal/ArticleModal'
import { CollectionHeaderStyled } from '../content/styled'
import { DealOpenState, setDealOpenState } from '../deal-view/dealOpenSlice'
import { DEAL_PAGE } from '../deal-view/DealView'
import { GENERAL, HOLDCO, PRE_EVENT, RISK_ARB, SHARE_CLASS } from '../navigation/SecondaryOttoNav'
import { cleanUpOttoEvents, getArticlesData, initOttoEvents, OttoEventState } from '../ottoEventsSlice'
import { BoxStyled, OttoBoxStyled, OttoCol6, OttoRow, ScrollBox } from '../styled'
import { ArticlesByDateDTO, EventsByDateDTO, OSSArticleDTO, UpcomingEventDTO } from '../types'
import {
    ArticleDateStyled,
    ArticleDealNameStyled,
    ArticleSubjectStyled,
    ArticleSummaryStyled,
    ArticleTimeStyled,
    ArticleTitleDealStyled,
    ArticleTitleStyled,
    ArticleTitleWrapperStyled,
} from './styled'

interface ArticlesViewProperties {
    code: string
    height: string // height of latest published and upcoming
    hideClose?: boolean
    useMaxHeight?: boolean
    articleId?: string | undefined
    dealId?: string | undefined
}

const REFRESH_RATE = 10000
const ArticlesView = ({
    code,
    height,
    hideClose = false,
    useMaxHeight = false,
    dealId,
    articleId,
}: ArticlesViewProperties): React.ReactElement => {
    const sessionContext = React.useContext(SessionContext)
    const blastContext = useContext(BlastContext)
    //    console.log(`tabParam: ${tabParam} dealId: ${dealId} articleId: ${articleId} `)

    const navigate = useNavigate()

    const dispatch = useAppDispatch()
    const _ottoEventsSlice: OttoEventState = useAppSelector((state) => state.ottoEventsSlice[code])
    const _dealOpenSlice: DealOpenState = useAppSelector((state) => state.dealOpenSlice)

    const [selectUrl] = useState<string>(code !== 'events' ? '/otto-articles/{id}/get' : '/otto-articles/paged')

    const articleContentsElement = useRef(null)
    const [articleStartPos, setArticleStartPos] = useState(73)

    const [contentsHeight, setContentsHeight] = useState(height)
    const contentsElement = useRef(null)

    const [articleIdForModal, setArticleIdForModal] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (useMaxHeight) {
            window.requestAnimationFrame(function () {
                setTimeout(() => {
                    if (contentsElement) {
                        // @ts-ignore
                        const rect = contentsElement?.current?.getBoundingClientRect()
                        if (rect) {
                            let h = window.innerHeight - rect.top - 80
                            setContentsHeight(h + 'px')
                        }
                    }
                }, 100)
            })
        }
    }, [code])

    RunOnceEffect(() => {
        dispatch(
            initOttoEvents({
                code: code,
                sessionContext: sessionContext,
                blastContext: blastContext,
                label: '',
                selectUrl: selectUrl,
                lastQueryParams: paginationService.blankQueryParam(),
            })
        )
        refreshData(paginationService.blankQueryParam(), articleId ? '/otto-articles/{id}/get' : undefined)

        sessionContext.registerRefreshRate(REFRESH_RATE)
        let subscription: Subscription | undefined = undefined
        subscription = sessionContext.watchRefreshRate().subscribe((nextRefreshRate: number) => {
            if (nextRefreshRate !== -1 && REFRESH_RATE === nextRefreshRate && !_dealOpenSlice.opened) {
                refreshData(
                    _ottoEventsSlice?.lastQueryParams || paginationService.blankQueryParam(),
                    articleId ? '/otto-articles/{id}/get' : undefined
                )
            }
        })

        return () => {
            sessionContext.unregisterRefreshRate(REFRESH_RATE)
            unSubscribe(subscription)
        }
    })

    const refreshData = (queryParams: QueryParamsDTO, url?: string | undefined) => {
        const abortController = new AbortController()
        dispatch(
            getArticlesData({
                code: code,
                url: url ? url : selectUrl,
                queryParams: queryParams,
                sessionContext: sessionContext,
                abortController: abortController,
                pathParams: { id: articleId || code },
            })
        )
            .unwrap()
            .then(() => {})
            .catch((error: any) => {
                console.error('error', error)
            })

        return () => {
            cancelAbortController(abortController)
            dispatch(cleanUpOttoEvents({ code: code }))
        }
    }

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                if (articleContentsElement) {
                    // @ts-ignore
                    const rect = articleContentsElement?.current?.getBoundingClientRect()
                    if (rect) {
                        setArticleStartPos(rect.y)
                    }
                }
            }, 100)
        })
    }, [articleContentsElement])

    const toggleOpenDeal = (opened: boolean) => {
        dispatch(setDealOpenState({ opened: opened }))
        if (!opened) {
            refreshData(_ottoEventsSlice?.lastQueryParams || paginationService.blankQueryParam())
        }
    }

    const onEventClick = (selectedEvent: OSSArticleDTO | UpcomingEventDTO) => {
        // @ts-ignore
        if (selectedEvent?.dealType === 'GENERAL') {
            setArticleIdForModal(selectedEvent.id)
        } else {
            if (useMaxHeight) {
                navigate(DEAL_PAGE + '/' + selectedEvent.dealId + '/' + selectedEvent.id)
            }
        }
    }
    const renderDealType = (article: OSSArticleDTO): React.ReactElement => {
        let dealType = ''
        if (article.dealType) {
            switch (article.dealType) {
                case 'HOLD_CO':
                    dealType = HOLDCO
                    break
                case 'RISK_ARB':
                    dealType = RISK_ARB
                    break
                case 'SHARE_CLASS':
                    dealType = SHARE_CLASS
                    break
                case 'PRE_EVENT':
                    dealType = PRE_EVENT
                    break
                case 'GENERAL':
                    dealType = GENERAL
                    break
            }
            return <ArticleTitleDealStyled>{dealType}</ArticleTitleDealStyled>
        } else {
            return <ArticleTitleDealStyled></ArticleTitleDealStyled>
        }
    }

    const renderArticleTitleOld = (article: OSSArticleDTO, idx: number): React.ReactElement => {
        return (
            <ArticleDealNameStyled>
                {article.dealName}
                {renderDealType(article)}
            </ArticleDealNameStyled>
        )
    }

    const renderArticleTitle = (article: OSSArticleDTO, idx: number): React.ReactElement => {
        return (
            <ArticleTitleWrapperStyled>
                <div>
                    {renderDealType(article)}
                    <ArticleTimeStyled>{article.articleTime}</ArticleTimeStyled>
                </div>
                <ArticleDealNameStyled>{article.dealName}</ArticleDealNameStyled>
            </ArticleTitleWrapperStyled>
        )
    }

    const renderLatestPublishedTitle = (article: OSSArticleDTO, idx: number): React.ReactElement => {
        return (
            <ArticleTitleStyled
                key={'t_' + idx + article.id}
                large={useMaxHeight}
                active={!useMaxHeight && article === _ottoEventsSlice?.selectedEvent}
                onClick={() => onEventClick(article)}
            >
                {renderArticleTitle(article, idx)}
                <ArticleSummaryStyled>
                    {/*<ArticleTimeStyled>{article.articleTime}</ArticleTimeStyled>*/}
                    {article?.subject && (
                        <ArticleSubjectStyled dangerouslySetInnerHTML={{ __html: article?.subject || '' }}></ArticleSubjectStyled>
                    )}
                </ArticleSummaryStyled>
            </ArticleTitleStyled>
        )
    }

    const renderUpcomingEventsTitle = (article: OSSArticleDTO, idx: number): React.ReactElement => {
        return (
            <ArticleTitleStyled
                key={'t_' + idx + article.id}
                large={useMaxHeight}
                active={article === _ottoEventsSlice?.selectedEvent}
                onClick={() => {
                    if (useMaxHeight) {
                        onEventClick(article)
                    }
                }}
            >
                <ArticleTimeStyled>{article.articleTime}</ArticleTimeStyled> <span>{article.dealName}</span>
                {article?.subject && <ArticleSubjectStyled>{article?.subject}</ArticleSubjectStyled>}
            </ArticleTitleStyled>
        )
    }

    const renderLatestPublished = (): React.ReactElement => {
        return (
            <OttoBoxStyled id="latest_published" position={'left'}>
                <CollectionHeaderStyled>Latest Published</CollectionHeaderStyled>
                <ScrollBox height={contentsHeight}>
                    {safeStream(_ottoEventsSlice?.latestPublished).map((articlesByDate: ArticlesByDateDTO, idx: number) => (
                        <BoxStyled key={idx}>
                            <ArticleDateStyled large={useMaxHeight}>{articlesByDate.publishedDate}</ArticleDateStyled>
                            {safeStream(articlesByDate.articles).map((article: OSSArticleDTO, idx) =>
                                renderLatestPublishedTitle(article, idx)
                            )}
                        </BoxStyled>
                    ))}
                </ScrollBox>
            </OttoBoxStyled>
        )
    }

    const renderUpcomingEvents = (): React.ReactElement => {
        return (
            <OttoBoxStyled id="upcoming_events" position={'right'}>
                <CollectionHeaderStyled>Upcoming Events</CollectionHeaderStyled>
                <ScrollBox height={contentsHeight}>
                    {safeStream(_ottoEventsSlice?.upcomingEvents).map((eventsByDate: EventsByDateDTO, idx) => (
                        <BoxStyled key={idx}>
                            <ArticleDateStyled large={useMaxHeight}>{eventsByDate.publishedDate}</ArticleDateStyled>
                            {safeStream(eventsByDate.events).map((event: UpcomingEventDTO, idx) => renderUpcomingEventsTitle(event, idx))}
                        </BoxStyled>
                    ))}
                </ScrollBox>
            </OttoBoxStyled>
        )
    }

    return (
        <>
            <OttoRow ref={contentsElement} style={{ backgroundColor: 'rgb(236 238 240)' }}>
                <OttoCol6>{renderLatestPublished()}</OttoCol6>
                <OttoCol6>{renderUpcomingEvents()}</OttoCol6>
            </OttoRow>
            {articleId && _ottoEventsSlice?.selectedEvent && <ArticleModal articleId={articleId} returnUrl={DEAL_PAGE + '/' + dealId} />}
            {articleIdForModal && <ArticleModal articleId={articleIdForModal} onHideCallBack={() => setArticleIdForModal(undefined)} />}
        </>
    )
}
export default ArticlesView
