import React, { useState } from 'react'
import { log } from '../../../../services/LogService'
import { MiniLoadingSpinnerStyled } from '../styled/table/styled'

export interface MiniLoadingSpinnerDO {
    isLoading: boolean | undefined
}

export default function MiniLoadingSpinner({ isLoading }: MiniLoadingSpinnerDO) {
    const [className] = useState('MiniLoadingSpinner-' + new Date().getTime())
    return isLoading ? (
        <MiniLoadingSpinnerStyled>
            <i className={'fa-solid fa-circle fa-beat-fade'} />
            <i className={'fa-solid fa-circle fa-beat-fade mx-3'} />
            <i className={'fa-solid fa-circle fa-beat-fade'} />
        </MiniLoadingSpinnerStyled>
    ) : (
        <span>&nbsp;</span>
    )
}
