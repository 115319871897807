import React, { useState } from 'react'
import { useAppSelector } from '../../../../app/hooks'
import Icon from '../../maintain-table/bootstrap/Icon'
import { GuslTableState } from '../guslTableSlice'
import { WidgetHeadingStyled } from '../styled/side-panel/styled'
import { ActionIconStyled } from '../styled/table-controls/styled'
import CheckboxFilters from './checkbox-filters/CheckboxFilters'
import IndividualNumberFilter from './number-range/IndividualNumberFilter'
import { OptionFilter } from './option-filter/OptionFilter'
import TextFilters from './text-filters/TextFilters'
import { FilterDO } from './types'

export type FilterBlockDO = {
    filter: FilterDO
    code: string
    side?: boolean | undefined
    data?: any
}
export default function FilterBlock({ filter, code, side, data }: FilterBlockDO) {
    // MK 11/08/2023
    const state: GuslTableState = useAppSelector((state) => state.guslTableSlice[code])
    const showTopTableFilters = state?.showTopFilters
    const [isBlockOpen, setIsBlockOpen] = useState<boolean>(showTopTableFilters || false)

    function openFilterBlockHandler() {
        setIsBlockOpen(!isBlockOpen)
    }

    const renderFilters = (): React.ReactElement => {
        // console.log('=-----------> data', data)
        switch (filter.type) {
            case 'text':
                return <TextFilters filter={filter} code={code} data={data} />
            case 'number':
                return <IndividualNumberFilter key={filter.name} filter={filter} code={code} />
            case 'option':
                return <OptionFilter key={filter.name} filter={filter} code={code} />
            case 'checkbox':
                return <CheckboxFilters filter={filter} code={code} />
            default:
                return <></>
        }
    }
    // MK 21/08/2023 showing label on side filters, not on top filters
    return (
        <div className={''} role={'button'}>
            {side && (
                <>
                    <WidgetHeadingStyled active={isBlockOpen} onClick={openFilterBlockHandler}>
                        <ActionIconStyled active={isBlockOpen}>
                            <Icon icon={'me-2 fa-solid fa-angle-' + (isBlockOpen ? 'down' : 'right')} />
                        </ActionIconStyled>
                        <span>{filter.label}</span>
                    </WidgetHeadingStyled>
                </>
            )}
            {(isBlockOpen || !side) && renderFilters()}
        </div>
    )
}
