/** Converts Radians into Degrees
 * @param {number} rad Radians to convert.
 * @returns {number} Radians in terms of Degrees
 */
import { isArray } from '../../../utils/TypeCheckers'

export function RadiansToDegrees(rad: number): number {
    if (rad < 0) {
        return parseFloat((360.0 + (rad + 180 / Math.PI)).toFixed(2))
    } else {
        return parseFloat((rad * (180 / Math.PI)).toFixed(2))
    }
}

/** Converts Degrees into Radians
 * @param {number} degrees Degrees to convert.
 * @returns {number} Degrees in terms of Radians
 */
export function DegreesToRadians(degrees: number) {
    return degrees * (Math.PI / 180)
}

/**
 * Finds a Point along a circle at the specified angle.
 * @param {Point} originPoint Center of the circle.
 * @param {number} radius Radius of the circle to find the point upon.
 * @param {number} angleRadians Angle (in radians) to find the point along the circle.
 * @returns
 */
export function FindPointOnCircle(originPoint: Point, radius: number, angleRadians: number) {
    return new Point(radius * Math.cos(angleRadians) + originPoint.X, radius * Math.sin(angleRadians) + originPoint.Y)
}

/** Represents a 2D point */
export class Point {
    X: number
    Y: number

    constructor(x: number, y: number) {
        /** X-Axis position
         * @type {number}
         */
        this.X = x || 0
        /** Y-Axis position
         *  @type {number} */
        this.Y = y || 0
    }

    FindOnCircleFromHere(radius: number, angleRadians: number) {
        return FindPointOnCircle(this, radius, angleRadians)
    }

    /** Moves the canvas path to this Point
     * @param {CanvasRenderingContext2D} context Reference to the HTML Canvas 2D context
     */
    MoveTo(context: CanvasRenderingContext2D) {
        context.moveTo(this.X, this.Y)
    }

    /** Draws a line on the canvas to this Point
     * @param {CanvasRenderingContext2D} context Reference to the HTML Canvas 2D context
     */
    LineTo(context: CanvasRenderingContext2D) {
        context.lineTo(this.X, this.Y)
    }
}

/** Represents a rectangle made of two points */
export class Box {
    Start: Point
    End: Point

    constructor(start: Point, end: Point) {
        /** Starting point for the rectangle.
         * @type {Point}
         */
        this.Start = start

        /** Ending point for the rectangle.
         * @type {Point}
         */
        this.End = end
    }
}

/** Processes a color option into either a color code or Canvas gradient
 * @param {object} option Reference to the color option
 * @param {CanvasRenderingContext2D} context Reference to an HTML Canvas 2D Context
 * @param {Box} box Bounding box for a gradient on the HTML Canvas
 */
export function ProcessColor(
    option: string | string[] | undefined,
    context?: CanvasRenderingContext2D,
    box?: Box
): string | CanvasGradient | undefined {
    if (option === undefined) {
        return undefined
    }
    if (typeof option === 'string') {
        return option
    } else {
        if (typeof context === 'undefined') {
            console.error('Gradient colors require a reference to the Canvas 2D Context')
            return undefined
        }
        if (box === undefined) {
            return undefined
        }

        const grd = context.createLinearGradient(box.Start.X, box.Start.Y, box.End.X, box.End.Y)
        if (isArray(option)) {
            const colors = option as string[]
            for (let len = colors.length, n = 0; n < len; n++) {
                grd.addColorStop(n, colors[n])
            }
        }

        // const steps: string[] = Object.getOwnPropertyNames(option);
        // for (let len = steps.length, n = 0; n < len; n++) {
        //     grd.addColorStop(parseInt(steps[n], 10), option[parseInt(steps[n], 10)]);
        // }
        return grd
    }
}
