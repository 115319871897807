import { SectorProperties } from 'grapesjs'

export const GeneralStyle: any = {
    name: 'General',
    open: false,
    properties: [
        {
            extend: 'float',
            type: 'radio',
            default: 'none',
            options: [
                { value: 'none', className: 'fa fa-times' },
                { value: 'left', className: 'fa fa-align-left' },
                { value: 'right', className: 'fa fa-align-right' },
            ],
        },
        'display',
        { extend: 'position', type: 'select' },
        'top',
        'right',
        'left',
        'bottom',
    ],
}
