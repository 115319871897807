import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { handleSiteTabs, IsActiveDO } from '../../../../features/ui/uiSlice'
import { SystemContext } from '../../../../providers/system/SystemContext'
import { isElementOverFlown } from '../../../../utils/CssUtils'
import { CloseTabIconStyled, OpenTabStyled, OpenTabWrapper } from '../../../common/gusl-table/styled/split-list-view/styled'
import { TabsHeaderStyled } from '../../../common/gusl-table/styled/tabs/styled'
import { maintainTableService } from '../../../common/maintain-table/MaintainTableService'
import { IMenuDTO } from '../../../types'

type NavigationHandlerDO = {
    tab: IMenuDTO & IsActiveDO
}
type RemoveOpenTabHandlerDO = {
    tab: IMenuDTO & IsActiveDO
    idx: number
}
export default function SiteTabs() {
    const dispatch = useAppDispatch()
    const systemContext = useContext(SystemContext)
    const navigate = useNavigate()
    const siteTabs: (IMenuDTO & IsActiveDO)[] = useAppSelector((state) => state.uiSlice.siteTabs)
    const currentCode = maintainTableService.getCodeFromUrl()

    const [menuItems] = useState<(IMenuDTO & IsActiveDO)[]>(() => {
        return maintainTableService.extractMenuItemsFromMenuGroups(systemContext.getSystemConfig()?.menuGroups || [])
    })

    const isNavigationItem: boolean = menuItems.filter((menuItem) => menuItem.code === currentCode).length === 1

    const activeTab: IMenuDTO & IsActiveDO = useAppSelector((state) => state.uiSlice.activeTab)

    const [currentTab, setCurrentTab] = useState<(IMenuDTO & IsActiveDO) | undefined>(
        isNavigationItem ? menuItems.filter((tab) => tab.code === currentCode)[0] : undefined
    )

    useEffect(() => {
        if (isNavigationItem) {
            const currentTab: IMenuDTO & IsActiveDO = menuItems.filter((tab) => tab.code === currentCode)[0]

            setCurrentTab(currentTab)

            if (siteTabs.length < 2) {
                const currentMenuItem: IMenuDTO = menuItems.filter((menuItem) => menuItem.code === currentCode)[0]

                dispatch(handleSiteTabs({ item: currentMenuItem, action: 'first' }))
            }
        }
    }, [currentCode])

    function navigateHandler(tab: IMenuDTO & IsActiveDO) {
        navigate(tab.route || '/' + tab.code)
    }

    function navigationHandler({ tab }: NavigationHandlerDO) {
        dispatch(handleSiteTabs({ item: tab, action: 'add' }))
        navigateHandler(tab)
    }

    function removeOpenTabHandler({ tab, idx }: RemoveOpenTabHandlerDO) {
        const tabToNavigate: IMenuDTO & IsActiveDO = uniqueSiteTabs[idx !== 0 ? idx - 1 : idx + 1]
        dispatch(handleSiteTabs({ item: tab, action: 'remove' }))
        if (tab.isActive) {
            navigateHandler(tabToNavigate)
        }
    }

    const key = 'code'
    let uniqueSiteTabs = [...new Map(siteTabs.map((item) => [item[key], item])).values()]

    useEffect(() => {
        uniqueSiteTabs = [...new Map(siteTabs.map((item) => [item[key], item])).values()]
    }, [currentCode])

    const tabsWrapper = document.getElementById('site-tabs-' + currentCode)
    const isOverFlown = isElementOverFlown({
        element: tabsWrapper,
        direction: 'x',
    })

    return siteTabs.length > 1 ? (
        <TabsHeaderStyled key={'site-tabs-' + currentCode} id={'site-tabs-' + currentCode}>
            {siteTabs.map((tab, idx) => (
                <OpenTabWrapper key={idx} isLast={idx + 1 === siteTabs.length} isActive={tab.isActive as boolean} isOverFlown={isOverFlown}>
                    <OpenTabStyled
                        onClick={
                            tab.isActive
                                ? () => {}
                                : () => {
                                      navigationHandler({
                                          tab: activeTab.code === tab.code ? (currentTab as IMenuDTO) : tab,
                                      })
                                  }
                        }
                    >
                        {tab.label}
                    </OpenTabStyled>
                    <CloseTabIconStyled
                        onClick={() => {
                            removeOpenTabHandler({ tab, idx })
                        }}
                    >
                        <i className={'fa-solid fa-close'} />
                    </CloseTabIconStyled>
                </OpenTabWrapper>
            ))}
        </TabsHeaderStyled>
    ) : (
        <></>
    )
}

export function hasActionOrCommand(item: IMenuDTO) {
    return item.action || item.command || item.commandFunction
}
