import React, { useState } from 'react'
import { InlineTableInputProps } from '../types'

export default function BooleanEditInput({
    rowId,
    idx,
    fld,
    inputRefs,
    inlineFieldEditHandler,
    setFocus,
    defaultValue,
}: InlineTableInputProps) {
    const [className] = useState('BooleanEditInput-' + new Date().getTime())

    return (
        <div className={' d-flex align-items-center justify-content-evenly'}>
            <select
                key={'ff-det_' + rowId + '_' + idx + '---' + fld.name}
                ref={(el) => (inputRefs.current[idx] = el!)}
                id={'ff-det_' + rowId + '_' + idx + '---' + fld.name}
                style={{ color: 'black' }}
                /*@ts-ignore*/
                defaultValue={(defaultValue || 'false') as InlineTableInputProps['defaultValue']}
                onChange={(e) => {
                    inlineFieldEditHandler(e, fld)
                }}
            >
                <option value="true">Yes</option>
                <option value="false">No</option>
            </select>
        </div>
    )
}
