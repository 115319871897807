import styled from 'styled-components'
import { getTextAlign } from '../../../utils/CssUtils'
import { ColumnFieldProperties } from '../maintain-table/styled'

export const HtmlFieldStyled = styled.div.attrs((props) => ({}))`
    max-height: 200px;
    overflow: auto;

    div {
        line-height: 20px;
        span {
            padding: 5px;
            color: red;
        }
    }
`
