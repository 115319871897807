import { Col } from 'react-bootstrap'
import styled from 'styled-components'
import { Button } from '../../components/ui/button/button'

export const LoginCardStyled = styled.div.attrs((props) => ({
    className: 'login-card',
    id: 'login-card',
}))`
    width: 100%;
`
export const UsernameCardStyled = styled.div`
    width: 100%;
    height: 70px;
`
export const PasswordCardStyled = styled.div`
    width: 100%;
    height: 100px;
`
export const TotpCardStyled = styled.div`
    width: 100%;
`

export const UsernameStyled = styled.div`
    display: grid;
    width: 100%;
`

export const TotpStyled = styled.div`
    display: grid;
    width: 100%;
`

export const PasswordStyled = styled.div`
    // display: inline-flex;
    display: grid;
`

interface FormProperties {
    noModal?: boolean | undefined
}

export const FormStyled = styled.div.attrs((props: FormProperties) => ({}))<FormProperties>`
    ${(props) => props.noModal && 'padding:0px 40px 0px 40px;'}

    display: flex;
    flex-direction: column;
    // width: 90%;

    h1 {
        color: ${(props) => props.theme.colors.error};
        font-size: 1.1em;
        font-weight: bold;
    }

    label {
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: ${(props) => props.theme.login.fontColor};
    }
`

export const FieldWrapperStyled = styled.div.attrs(() => ({}))`
    align-items: center;
    display: inline-flex;
    height: 24px;
    flex: 1 0 auto;
    justify-content: center;
    min-width: 24px;
    width: 24px;
`

export const IconWrapperStyled = styled(Col).attrs(() => ({}))`
    max-width: 30px;
    margin-top: 10px;
`

export const LoginUsernameIconWrapperStyled = styled.span.attrs(() => ({}))`
    float: right;
    padding-left: 10px;
    padding-bottom: 2px;
    font-size: 12px;
`

export const UsernameIconStyled = styled.i.attrs(() => ({
    className: 'fas fa-user',
}))`
    font-size: 20px;
    padding: 5px;
    color: ${(props) => props.theme.login.buttonBgColor};
`

export const PasswordIconStyled = styled.i.attrs(() => ({
    className: 'fas fa-lock',
}))`
    font-size: 20px;
    padding: 5px;
    color: ${(props) => props.theme.login.buttonBgColor};
`

export const TotpIconStyled = styled.i.attrs(() => ({
    className: 'fas fa-key',
}))`
    font-size: 20px;
    padding: 5px;
    color: ${(props) => props.theme.login.fontColor};
`
/*
    background-color: ${(props) => props?.theme?.login?.input?.bgColor || props.theme.colors.light};
    color: ${(props) => props?.theme?.login?.input?.fgColor || props.theme.colors.dark[0]};
    border: ${(props) => props?.theme?.login?.input?.fieldBorder || '1px solid ' + props.theme.colors.background[0]};
    outline-color: ${(props) => props.theme.colors.primary[0]};
    border-radius: ${(props) => props?.theme?.login?.input?.borderRadius || '3px'};
    font-size: 16px;
    margin-bottom: 10px;
    height: 45px;
    padding: 0 10px;
    width: 100%;

 */
export const FieldStyled = styled.input``

export const ErrorMessageStyled = styled.h2`
    color: ${(props) => props.theme.colors.error};
    margin-bottom: 10px;
    align-self: left;
    font-weight: bold;
`
export const FormErrorMessageStyled = styled.h2`
    color: ${(props) => props.theme.colors.error};
    margin-top: 10px;
    margin-bottom: 10px;
    align-self: left;
    font-weight: bold;
`

interface TogglePasswordProperties {
    capitalise?: boolean | undefined
}

export const TogglePasswordStyled = styled.div.attrs((props: TogglePasswordProperties) => ({}))<TogglePasswordProperties>`
    text-align: right;
    width: 100%;
    margin-bottom: 12px;
    margin-top: -5px;

    ${(props) => props.capitalise && 'text-transform: uppercase;'}
    a {
        color: ${(props) => props.theme.login.linkColor};
        font-size: 12px;
    }
`

export const LoginButtonTextStyled = styled.div`
    vertical-align: middle;
`

interface LoginButtonProperties {
    noModal?: boolean | undefined
}

export const LoginButtonStyled = styled(Button).attrs((props: LoginButtonProperties) => ({}))<LoginButtonProperties>`
    ${(props) => props.noModal && 'width: 50%;'}

    color: ${(props) => props.theme.login.buttonFgColor};
    background-color: ${(props) => props.theme.login.buttonBgColor};
    //width: 100%;
    text-align: center;
    margin: auto;
    border-radius: 12px;

    &:hover {
        color: ${(props) => props.theme.login.buttonFgColor};
        background-color: ${(props) => props.theme.login.buttonBgColor};
        font-weight: 800;
    }
`

/*
    height: 64px;
    padding: 10px 10px 10px 10px;
    font-size: 1.75rem;

 */
export const LoginTitleBlockStyled = styled.div.attrs((props) => ({
    id: 'login-title',
}))`
    ${(props) => {
        console.log('props.theme?.login?.titlePanel', props.theme?.login?.titlePanel)
        return undefined
    }}

    color: ${(props) => props.theme?.login?.titlePanel?.fgColor || props.theme.login.buttonFgColor};
    background-color: ${(props) => props.theme?.login?.titlePanel?.bgColor || props.theme.login.buttonBgColor};
    height: ${(props) => props.theme?.login?.titlePanel?.height || '64px'};
    padding: ${(props) => props.theme?.login?.titlePanel?.padding || '10px 10px 10px 10px'};
    font-size: ${(props) => props.theme?.login?.titlePanel?.titleFontSize || '1.75rem'};
    ${(props) => props.theme?.login?.titlePanel?.fontWeight && 'font-weight: ' + props.theme?.login?.titlePanel?.fontWeight};

    line-height: 43.71px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: -20px;
    margin-left: -20px;
`

interface LoginTextProperties {
    noModal?: boolean | undefined
}

/*
    color: ${(props) => props.theme.login.linkColor};
    padding: 10px;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: .0071428571em;
    ${(props) => props.noModal && 'font-size: 16px; font-weight: bold;padding: 2px 2px 60px 2px;text-align:center;margin:auto;'}

 */
export const LoginTextStyled = styled.div.attrs((props: LoginTextProperties) => ({
    id: 'login-text',
}))<LoginTextProperties>`
    color: ${(props) => props.theme?.login?.loginText?.fgColor || props.theme.login.linkColor};
    background-color: ${(props) => props.theme?.login?.loginText?.bgColor || 'inherit'};
    padding: ${(props) => props.theme?.login?.loginText?.padding || '10px'};
    font-size: ${(props) => props.theme?.login?.loginText?.fontSize || '.875rem'};
    font-weight: ${(props) => props.theme?.login?.loginText?.fontWeight || '400'};
    text-align:center;
    margin:auto;    
}`

export const VersionStyled = styled.div.attrs((props) => ({}))`
    color: var(--muted);
    font-size: 10px;
    text-align: right;
    margin-top: 10px;
`

export const LoginPanelStyled = styled.div.attrs((props) => ({}))``

interface LoginWrapperProperties {
    noModal?: boolean
}

export const LoginWrapperStyled = styled.div.attrs((props: LoginWrapperProperties) => ({
    className: 'login-wrapper',
}))<LoginWrapperProperties>`
    position: absolute;
    top: 40%;
    left: 30px;
    width: 400px;
    background-color: ${(props) => props?.theme?.login?.innerBackground || 'rgb(215, 254, 212)'};

    border: 5px;
    box-shadow: rgba(0, 0, 0, 0.75) 2px 4px 20px 0px;
    border-radius: 10px;
    padding: 20px;
`
