import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import { translateService } from '../../../services/TranslateService'
import { isBlank } from '../../../utils/TypeCheckers'
import {
    ErrorMessageStyled,
    FieldStyled,
    IconWrapperStyled,
    LoginUsernameIconWrapperStyled,
    UsernameCardStyled,
    UsernameIconStyled,
    UsernameStyled,
} from '../styled'
import { LabelStyled, LoginFieldContentStyled } from './styled'
import { UserIcon } from './UserIcon'

type UsernameProps = {
    value: string
    hasLabel: boolean
    asUppercase: boolean
    hasIcon: boolean
    autoFocus: boolean
    onNextField: () => void
    onChange: (value: string) => void
    formSubmitted: boolean
}
const Username = (props: UsernameProps): React.ReactElement => {
    const label = translateService.getValue('login.username.label', 'Username')
    const errorMessage = translateService.getValue('login.username.error', 'Username is required.')
    const placeholder = translateService.getValue('login.username.placeholder', 'Username')

    return (
        <UsernameCardStyled>
            {props.hasLabel && (
                <LabelStyled htmlFor="username" asUppercase={props.asUppercase}>
                    {label}
                </LabelStyled>
            )}
            <UsernameStyled>
                <Container fluid>
                    <Row>
                        {props.hasIcon && (
                            <IconWrapperStyled>
                                <UsernameIconStyled />{' '}
                            </IconWrapperStyled>
                        )}
                        <Col style={{ paddingLeft: '0px' }}>
                            <LoginFieldContentStyled>
                                <FieldStyled
                                    name="username"
                                    type="text"
                                    placeholder={placeholder}
                                    tabIndex={1}
                                    autoFocus={props.autoFocus}
                                    required
                                    value={props.value}
                                    onChange={(e) => {
                                        props.onChange(e.target.value)
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            props.onNextField()
                                        }
                                    }}
                                />
                                {!props.hasIcon && (
                                    <LoginUsernameIconWrapperStyled>
                                        <UserIcon />
                                    </LoginUsernameIconWrapperStyled>
                                )}
                            </LoginFieldContentStyled>
                        </Col>
                    </Row>
                    <Row>
                        <Col>{props.formSubmitted && isBlank(props.value) && <ErrorMessageStyled>{errorMessage}</ErrorMessageStyled>}</Col>
                    </Row>
                </Container>
            </UsernameStyled>
        </UsernameCardStyled>
    )
}

export default Username
