import * as React from 'react'
import { useContext, useState } from 'react'
import { SystemContext } from '../../../../providers/system/SystemContext'
import { FieldConfigDTO } from '../../../types'
import { maintainTableService } from '../../maintain-table/MaintainTableService'
import { GuslTableProperties } from '../GuslTable'
import { ListView } from '../ListView'
import { IndividualSplitStyled } from '../styled/split-list-view/styled'
import SplitHeader from './SplitHeader'

export type SplitProps = {
    name: string
    numOfSplits: number
}
export type listViewDO = {
    label: string
    selectUrl: string | undefined
    code: string | undefined
    fields: FieldConfigDTO[]
}
export default function IndividualSplit({ code, fields, selectUrl, label, name, numOfSplits }: GuslTableProperties & SplitProps) {
    const [className] = useState('IndividualSplit-' + new Date().getTime())
    const codeExt = '-' + name
    const [currentCode, setCode] = useState<string>(code + codeExt)
    const [currentFields, setFields] = useState<FieldConfigDTO[] | undefined>(fields)
    const [currentSelectUrl, setSelectUrl] = useState<string | undefined>(selectUrl)
    const [currentLabel, setLabel] = useState<string | undefined>(label)
    const systemContext = useContext(SystemContext)
    const splitHeight = window.innerHeight / numOfSplits - 60
    const tBodyHeight = splitHeight - 130
    const systemConfig = systemContext.getSystemConfig()
    const menuItems = systemConfig.menuGroups[0].menuItems
    const [listViewData] = useState<Map<string, listViewDO>>(new Map())
    const labels: string[] = []

    /*not sure if you want every table in split view or not
     *
     * if not you can set new property on menuItem "inSplitView" ....
     * and we can filter those out here ....
     * menuItems?.filter(menuItem => menuItem.inSplitView).forEach(....*/

    menuItems?.forEach((menuItem) => {
        const label = menuItem.label
        const selectUrl = maintainTableService.extractSelectAllEndpoint(menuItem)
        const code = menuItem.code
        const fields = maintainTableService.extractAllFields(menuItem) || []
        labels.push(label)
        listViewData.set(label, { label, selectUrl, code, fields })
    })

    function changeTableHandler(label: string) {
        const selectedTable = listViewData.get(label)
        setCode(selectedTable?.code + codeExt)
        setFields(selectedTable?.fields)
        setSelectUrl(selectedTable?.selectUrl)
        setLabel(selectedTable?.label)
    }

    return (
        <>
            <SplitHeader code={currentCode} currentLabel={currentLabel} labels={labels} onClick={changeTableHandler} />
            <IndividualSplitStyled splitHeight={splitHeight}>
                <ListView
                    code={currentCode}
                    fields={currentFields}
                    selectUrl={currentSelectUrl}
                    label={currentLabel}
                    isInSplit
                    height={tBodyHeight}
                />
            </IndividualSplitStyled>
        </>
    )
}
