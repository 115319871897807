import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { format, parseISO } from 'date-fns'
import { SessionContextProps } from '../../providers/session/types'
import { clone, constructUrl, safeStream } from '../../utils/Utils'
import paginationService from '../common/gusl-table/PaginationService'
import { ProcessedEvent } from '../common/scheduler/common/types'
import { QueryParamsDTO, WidgetHtmlResponseDO } from '../types'

export interface WidgetState {
    code: string
    uniqueId: string
    queryParams: QueryParamsDTO
    refreshCount: number
    src: string | undefined
    loaded: boolean
    events?: ProcessedEvent[]
    hideMonth?: boolean
    hideWeek?: boolean
    hideDay?: boolean
    view: 'month' | 'week' | 'day'
}

export interface WidgetInitPayload {
    code: string
    src: string | undefined
    // uniqueId: string,
    // queryParams: QueryParamsDTO,
    // refreshCount: number
}

export interface UpdateWidgetContentPayload {
    code: string
    src: string
    queryParams: QueryParamsDTO
}

export interface ResetQueryParamsPayload {
    code: string
}

export interface QueryParamsSearchPayload {
    code: string
    searchString: string
}

export interface UpdateQueryParamsForWidgetPayload {
    code: string
    queryParams: QueryParamsDTO
}

interface WidgetMainState {
    [id: string]: WidgetState
}

const initialState: WidgetMainState = {}

const createDefault = (code: string): WidgetState => {
    return {
        code: code,
        uniqueId: 'x',
        queryParams: paginationService.blankQueryParam(),
        refreshCount: 0,
        src: undefined,
        loaded: false,
        view: 'week',
    }
}
const getWidgetState = (state: WidgetMainState, code: string): WidgetState => {
    let entry: WidgetState = state[code]
    if (!entry) {
        entry = createDefault(code)
    }
    return entry
}

const loadInitValues = (entry: WidgetState, values: WidgetInitPayload) => {
    entry.code = values.code
    entry.uniqueId = 'x'
    entry.queryParams = paginationService.blankQueryParam()
    entry.refreshCount = 0
    if (values.src && values.src.startsWith('http')) {
        entry.src = values.src
        entry.loaded = true
    }
}

const updateWidgetQueryParams = (entry: WidgetState, values: UpdateQueryParamsForWidgetPayload) => {
    entry.queryParams = values.queryParams
    entry.refreshCount = entry.refreshCount + 1
}
const resetWidgetQueryParams = (entry: WidgetState, values: ResetQueryParamsPayload) => {
    entry.queryParams = paginationService.blankQueryParam()
    entry.refreshCount = entry.refreshCount + 1
}
const updateSearchQueryParams = (entry: WidgetState, values: QueryParamsSearchPayload) => {
    const qp = clone(entry.queryParams)
    qp.searchString = values.searchString
    entry.queryParams = qp
    console.log('entry.queryParams', entry.queryParams)
    // entry.refreshCount = entry.refreshCount + 1
}

const updateWidgetContent = (entry: WidgetState, values: UpdateWidgetContentPayload) => {
    entry.queryParams = values.queryParams
    entry.src = values.src
    entry.refreshCount = entry.refreshCount + 1
}

interface TableDataRequest {
    code: string
    url: string
    queryParams: QueryParamsDTO
    sessionContext: SessionContextProps
    abortController: AbortController
    pathParams?: any | undefined
}

export interface WidgetResponseWrapper {
    code: string
    response: WidgetHtmlResponseDO
}

export const getContentFromServer = createAsyncThunk('widget-url', async (request: TableDataRequest) => {
    const response = await request.sessionContext.post<QueryParamsDTO, WidgetHtmlResponseDO>(
        constructUrl(request.url, request.pathParams),
        request.queryParams,
        request.abortController
    )
    return { code: request.code, response: response?.data || {} }
})

const copyState = (entry: WidgetState): WidgetState => {
    const newState = {}
    for (const key in entry) {
        if (entry.hasOwnProperty(key)) {
            // @ts-ignore
            newState[key] = entry[key]
        }
    }
    return newState as WidgetState
}

const updateData = (inboundState: WidgetState, response: WidgetHtmlResponseDO): WidgetState => {
    const entry: WidgetState = copyState(inboundState)
    if (response) {
        entry.src = 'data:text/html;charset=utf-8,' + encodeURI(response.content)
        entry.queryParams = response?.queryParams || paginationService.blankQueryParam()
        entry.refreshCount = inboundState.refreshCount + 1
        entry.uniqueId = response?.uniqueId || 'x'
        entry.loaded = true

        if (response.events) {
            const events: ProcessedEvent[] = []
            safeStream(response.events).forEach((e) =>
                events.push({
                    ...e,
                    start: parseISO(e.start),
                    end: parseISO(e.end),
                })
            )
            entry.events = events
        }
    }
    return entry
}

export const widgetSlice = createSlice({
    name: 'widgetSlice',
    initialState,
    reducers: {
        initWidget(state, action: PayloadAction<WidgetInitPayload>) {
            const code = action.payload.code
            const entry: WidgetState = getWidgetState(state, code)
            loadInitValues(entry, action.payload)
            state[action.payload.code] = entry
        },
        updateContent(state, action: PayloadAction<UpdateWidgetContentPayload>) {
            const code = action.payload.code
            const entry: WidgetState = getWidgetState(state, code)
            updateWidgetContent(entry, action.payload)
            state[action.payload.code] = entry
        },
        updateQueryParams(state, action: PayloadAction<UpdateQueryParamsForWidgetPayload>) {
            const code = action.payload.code
            const entry: WidgetState = getWidgetState(state, code)
            updateWidgetQueryParams(entry, action.payload)
            state[action.payload.code] = entry
        },
        resetQueryParams(state, action: PayloadAction<ResetQueryParamsPayload>) {
            const code = action.payload.code
            const entry: WidgetState = getWidgetState(state, code)
            resetWidgetQueryParams(entry, action.payload)
            state[action.payload.code] = entry
        },
        addSearchString(state, action: PayloadAction<QueryParamsSearchPayload>) {
            const code = action.payload.code
            const entry: WidgetState = getWidgetState(state, code)
            updateSearchQueryParams(entry, action.payload)
            state[action.payload.code] = entry
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getContentFromServer.fulfilled, (state, action: PayloadAction<WidgetResponseWrapper>) => {
            const code = action.payload.code
            const entry: WidgetState = getWidgetState(state, code)
            state[action.payload.code] = updateData(entry, action.payload.response)
        })
    },
})
export const { initWidget, updateQueryParams, resetQueryParams, addSearchString, updateContent } = widgetSlice.actions

export default widgetSlice.reducer
