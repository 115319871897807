import React, { useEffect, useState } from 'react'
import { Observable } from 'rxjs'
import { EnvironmentContext } from '../../providers/environment/EnvironmentContext'
import { SessionContext } from '../../providers/session/SessionContext'
import { GuslThemeContext } from '../../providers/theme/GuslThemeProvider'
import { log } from '../../services/LogService'
import { isArray, notDefined } from '../../utils/TypeCheckers'
import { ChartRequestDTO } from '../types'
import ErrorMsg from '../ui/ErrorMsg'
import ChartLoaderPlaceholder from './ChartLoaderPlaceholder'
import ChartPanel from './ChartPanel'
import { ChartColor, ChartDO, CustomColorsProps, GuslChartProps } from './types'

import { checkInitialChartType } from './utils/checkInitialChartType'
import { getChart } from './utils/GetChartByType'

export default function GuslChart({
    endpoint,
    defaultViewDays,
    defaultChartType,
    singleChart,
    customCharts,
    singleValue,
    mixedChart,
    hidePanel,
    clientChartOptions,
    chart,
    colors,
}: GuslChartProps) {
    const [className] = useState('GuslChart-' + new Date().getTime())
    const [customColors, setCustomColors] = useState<typeof CustomColorsProps | undefined>(colors)
    const [serverData, setServerData] = useState<ChartDO | undefined>(undefined)
    const [rangeQuery, setRangeQuery] = useState<{ [index: string]: string } | undefined>({})
    const [timeUnitQuery, setTimeUnitQuery] = useState<{ [index: string]: string } | undefined>({})
    const [chartType, setChartType] = useState<string>('')
    const [chartFor, setChartFor] = useState<string | undefined>('')
    const [singleChartName, setSingleChartName] = useState<string | undefined>(singleChart)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const sessionContext = React.useContext(SessionContext)
    const guslThemeContext = React.useContext(GuslThemeContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const [themeColors, setThemeColors] = useState<ChartColor[]>(
        guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).chart
    )

    let chartDataServer: ChartDO

    try {
        useEffect(() => {
            setIsLoading(true)

            if (chart && Object.keys(chart).length) {
                let data: ChartDO = JSON.parse(JSON.stringify(chart))
                chartDataServer = data

                data['initialChartType'] = checkInitialChartType(data)

                setServerData(data)
                setSingleChartName(singleChart)

                if (data['colors']) {
                    setCustomColors(data['colors'])
                }

                setChartFor(singleChart ? singleChart : data?.initialDataset)
                /*@ts-ignore*/
                setChartType(defaultChartType ? defaultChartType : chartDataServer?.chartType)
                setIsLoading(false)
                setIsSuccess(true)
                // log.info(className,'MSG001',{chart})
            } else if (endpoint) {
                if (endpoint.indexOf('gusl-dev-mk') < 0) {
                    const request: ChartRequestDTO = {
                        rangeQuery: rangeQuery,
                        timeUnitQuery: timeUnitQuery,
                        singleChart: singleChart,
                        customCharts: customCharts,
                        singleValue: singleValue,
                        mixedChart: mixedChart,
                    } as ChartRequestDTO
                    sessionContext.post<ChartRequestDTO, ChartDO>(endpoint, request).then(
                        (response) => {
                            const data = response.data

                            // chartDataServer = data.chart_data2
                            data['initialChartType'] = checkInitialChartType(data)

                            // @ts-ignore
                            // data['initialDataset'] = data['newDatasets'][0]
                            let chartDataServer = data
                            setServerData(chartDataServer)

                            if (data['colors']) {
                                setCustomColors(data['colors'])
                            }

                            // @ts-ignore
                            if (Object.keys(rangeQuery).length) {
                                setRangeQuery(rangeQuery)
                            }
                            // @ts-ignore
                            if (Object.keys(timeUnitQuery).length) {
                                setTimeUnitQuery(timeUnitQuery)
                            }
                            setSingleChartName(singleChart)
                            /*@ts-ignore*/
                            setChartFor(singleChart ? singleChart : chartDataServer?.initialDataset)
                            /*@ts-ignore*/
                            setChartType(defaultChartType ? defaultChartType : chartDataServer?.chartType)
                            setIsLoading(false)
                            setIsSuccess(true)
                        },
                        (reason) => {
                            setIsSuccess(false)
                            setIsError(true)
                            log.error(className, 'ERR001', reason)
                        }
                    )
                } else {
                    fetch(endpoint, {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            rangeQuery: rangeQuery,
                            timeUnitQuery: timeUnitQuery,
                            singleChart: singleChart,
                            customCharts: customCharts,
                            singleValue: singleValue,
                            mixedChart: mixedChart,
                        } as ChartRequestDTO),
                    })
                        .then((response) => response.json())
                        .then((data: ChartDO) => {
                            // chartDataServer = data.chart_data2
                            data['initialChartType'] = checkInitialChartType(data)
                            chartDataServer = data

                            setServerData(chartDataServer)

                            if (data['colors']) {
                                setCustomColors(data['colors'])
                            }

                            // @ts-ignore
                            if (Object.keys(rangeQuery).length) {
                                setRangeQuery(rangeQuery)
                            }
                            // @ts-ignore
                            if (Object.keys(timeUnitQuery).length) {
                                setTimeUnitQuery(timeUnitQuery)
                            }
                            setSingleChartName(singleChart)
                            /*@ts-ignore*/
                            setChartFor(singleChart ? singleChart : chartDataServer?.initialDataset)
                            /*@ts-ignore*/
                            setChartType(defaultChartType ? defaultChartType : chartDataServer?.chartType)
                            setIsLoading(false)
                            setIsSuccess(true)
                        })
                        .catch((error) => {
                            setIsSuccess(false)
                            setIsError(true)

                            log.error(className, 'MSG00x', error)
                        })
                }
            }
        }, [rangeQuery, timeUnitQuery, chart])

        /*FOR CHANGING CHART FOR EXAMPLE falsePositive to priority on MASTER/CUSTOM CHART TYPE*/
        const observable = new Observable((subscriber) => {
            subscriber.next(chartFor)
        })

        return (
            <>
                {isError && <ErrorMsg />}

                {isSuccess && (
                    <>
                        {!hidePanel && !serverData?.hidePanel && (
                            <ChartPanel
                                setChartType={setChartType}
                                setRangeQuery={setRangeQuery}
                                setTimeUnitQuery={setTimeUnitQuery}
                                setChartFor={setChartFor}
                                timeUnitQuery={timeUnitQuery}
                                chartType={chartType}
                                rangeQuery={rangeQuery}
                                serverData={serverData ? serverData : ({} as ChartDO)}
                                endpoint={endpoint}
                                chartFor={chartFor}
                                singleChart={singleChart}
                                customCharts={customCharts}
                                mixedChart={mixedChart}
                            />
                        )}
                        <div className={' mt-3'}>
                            {getChart({
                                endpoint,
                                serverData,
                                chartType: chartType || serverData?.initialChartType,
                                chartFor,
                                observable,
                                singleChart,
                                customCharts,
                                singleValue,
                                mixedChart,
                                clientChartOptions,
                                colors: customColors,
                                themeColors,
                            })}
                        </div>
                    </>
                )}
                {(isLoading || notDefined(serverData)) && <ChartLoaderPlaceholder />}
            </>
        )
    } catch (e) {
        log.error(className, 'MSG003', {
            endpoint,
            defaultViewDays,
            defaultChartType,
            error: e,
        })
        return <ErrorMsg />
    }
}
