import { Editor } from 'grapesjs'

export const OssSubHeader = (editor: Editor) => {
    editor.Blocks.add('oss-sub-header', {
        label: 'Sub Header',
        category: 'OSS',
        attributes: { class: 'oss-sub-header' },
        media: `<svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 125" x="0px" y="0px"><path d="M21,24a4,4,0,0,0-4,4V72a4,4,0,0,0,8,0V53H45V72a4,4,0,0,0,8,0V28a4,4,0,0,0-8,0V45H25V28A4,4,0,0,0,21,24Z"/><path d="M79,68H70.06l8.83-6.07A9.48,9.48,0,0,0,83,54.12a9.34,9.34,0,0,0-1.84-5.61C79.6,46.45,76.58,44,71,44c-8.08,0-11.31,5.3-11.91,8.16v.05h0a4,4,0,0,0,7.84,1.59.94.94,0,0,1,0,.13C67,53.48,67.72,52,71,52c1.26,0,2.95.23,3.78,1.33a1.33,1.33,0,0,1,.22.78,1.48,1.48,0,0,1-.64,1.22L61.87,63.92A6.62,6.62,0,0,0,65.62,76H79a4,4,0,0,0,0-8ZM66.92,53.8h0Z"/></svg>`,
        content: {
            type: 'text',
            content: 'sub-header',
            style: { padding: '10px', 'font-size': '18px', 'font-weight': 'bold' },
        },
    })
}
