import { Input } from 'antd'
import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler } from 'react'
import styled, { ThemeProps } from 'styled-components'
import { getTextAlign } from '../../../utils/CssUtils'
import { ColumnFieldProperties } from '../maintain-table/styled'

export const TextFieldStyled = styled.div.attrs((props: ColumnFieldProperties) => ({}))<ColumnFieldProperties>`
    text-align: ${(props) => getTextAlign(props.textAlign)};
`

interface InputStyledProps extends ThemeProps<any> {
    className: string
    onChange: ChangeEventHandler<HTMLInputElement> & Function
    onBlur: FocusEventHandler<HTMLInputElement> & Function
    onPressEnter: KeyboardEventHandler<HTMLInputElement> & Function
}

export const CronInputStyled = styled(Input).attrs((props: InputStyledProps) => ({
    className: 'form-control mb-1 bg-transparent cron',
    onChange: props.onChange,
    onBlur: props.onBlur,
    onPressEnter: props.onPressEnter,
}))<InputStyledProps>`
    color: ${(props) => props.theme.table.columnColor} !important ;
    border: none !important;
    font-size: ${(props) => props.theme.table.columnFontSize};
`
export const CronFieldStyled = styled.div.attrs((props) => ({}))`
    border-bottom: ${(props) => props.theme.form.fieldBorder} !important;
`

export const CronFieldWrapperStyled = styled.div.attrs((props) => ({}))`
    border: ${(props) => props.theme.form.fieldBorder} !important;
    background-color: ${(props) => props.theme.colors.background[1]};
`
export const CronSeparatorStyled = styled.span.attrs((props) => ({}))`
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    background-color: hsl(0, 0%, 80%);
    margin-bottom: 14px;
    margin-top: 14px;
    width: 1px;
    box-sizing: border-box;
`
export const InfoIconStyled = styled.i.attrs((props) => ({}))`
    color: ${(props) => props.theme.colors.light};
`
export const FloatingFormStyled = styled.div.attrs((props) => ({
    className: 'form-floating',
}))``
