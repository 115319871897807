import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { log } from '../../../services/LogService'
import { isDefined } from '../../../utils/TypeCheckers'
import { CurrencyDTO, MoneyDTO } from '../../types'
import { BuySellOrder, Order, OrderAction, PreOrderDTO, TickerDO } from './types'

export interface InitPayload {
    code: string
    preOrder?: PreOrderDTO | undefined
    ticker: TickerDO | undefined
    orderAction: OrderAction

    side: string | undefined
}

export interface UpdatePricePayload {
    code: string
    ticker: string | undefined
    bidPrice?: number
    askPrice?: number
}

export interface UpdateTickerPayload {
    code: string
    ticker: TickerDO | undefined
    orderAction: OrderAction
}

export interface ErrorPayload {
    code: string
    hasError: boolean
    errorMessage?: string
    apiError?: boolean
}

export interface PerformCalculationPayload {
    code: string
}

export interface ResetBuySellPayload {
    code: string
}

export interface UpdateBuySellPayload {
    code: string
    side?: string
    quantity?: number
    limitPrice?: number
    orderType?: string
    validity?: string
    hasError?: boolean
    errorMessage?: string
}

export interface RemovePayload {
    code: string
}

export interface BlotterPathPayload {
    code: string
    path: string
}

export interface BuySellState {
    code: string
    order: BuySellOrder | undefined
    ticker: TickerDO | undefined
    orderAction: OrderAction
    canShowInfo: boolean
    canOrder: boolean
    hasError: boolean
    apiError: boolean
    errorMessage: string
    margin?: number | undefined
    freeEquity?: MoneyDTO | undefined
    currency?: string | undefined
    freeEquityCurrency?: CurrencyDTO | undefined
    marginRequired?: MoneyDTO | undefined
    notionalPrice?: MoneyDTO | undefined
    availableEquity?: MoneyDTO | undefined
    blotterPath?: string | undefined
}

interface MasterBuySellState {
    [id: string]: BuySellState
}

const initialState: MasterBuySellState = {} as MasterBuySellState

export const createDefault = (code: string): BuySellState => {
    return {
        code: code,
        order: undefined,
        ticker: undefined,
        orderAction: OrderAction.NEW,
        hasError: false,
        errorMessage: '',
        canShowInfo: false,
        canOrder: false,
        apiError: false,
    }
}

const loadInitValues = (entry: BuySellState, values: InitPayload): BuySellState => {
    entry = createDefault(values.code)
    entry.code = values.code
    entry.order = new Order(values.preOrder || ({} as PreOrderDTO))
    entry.ticker = values.ticker
    entry.orderAction = values.orderAction
    entry.hasError = false
    entry.errorMessage = ''

    if (values.preOrder) {
        entry.margin = values.preOrder.margin / 100
        entry.freeEquity = values.preOrder.freeEquity
        entry.currency = values.preOrder.ccy
        entry.freeEquityCurrency = values.preOrder.freeEquity?.currency
        entry.marginRequired = {
            currency: values.preOrder.freeEquity?.currency,
            value: 0,
        }
        entry.notionalPrice = {
            currency: values.preOrder.freeEquity?.currency,
            value: 0,
        }
        entry.availableEquity = {
            currency: values.preOrder.freeEquity?.currency,
            value: values.preOrder.freeEquity?.value,
        }

        if (values.side) {
            entry.order.setSide(values.side)
        }

        if (values.orderAction === OrderAction.CLOSE) {
            entry.order.setQuantity(values.preOrder.quantity) // close order
        } else {
            entry.order.setOrderType(values.preOrder.orderType)
            entry.order.setValidity(values.preOrder.validity)
        }

        if (isDefined(values.preOrder.side)) {
            entry.order.setSide(values.preOrder.side)
        }
    }
    return entry
}

const getOrderState = (state: MasterBuySellState, code: string): BuySellState => {
    let entry: BuySellState = state[code]
    if (!entry) {
        entry = createDefault(code)
    }
    return entry
}

const performShowError = (entry: BuySellState, payload: ErrorPayload): BuySellState => {
    entry.hasError = payload.hasError
    entry.errorMessage = payload.errorMessage || ''
    entry.apiError = payload.apiError || false
    return entry
}

const performUpdateTickerSelection = (entry: BuySellState, payload: UpdateTickerPayload): BuySellState => {
    entry.ticker = payload.ticker
    if (entry.order) {
        entry.order.setTicker(entry.ticker ? entry.ticker.id : '')
    }
    entry.orderAction = payload.orderAction
    return entry
}

const performUpdatePrice = (entry: BuySellState, payload: UpdatePricePayload): BuySellState => {
    if (entry.order && entry.order.getTicker() === payload.ticker) {
        const order = entry.order.clone()
        if (payload?.askPrice) {
            order.setBuyPrice(payload.askPrice)
        }
        if (payload?.bidPrice) {
            order.setSellPrice(payload.bidPrice)
        }
        // If delayed then turn if off
        if (order.isDelayed()) {
            order.setNoLongerDelayed()
        }

        entry.order = order
    }
    return entry
}

const performResetBuySellOrder = (entry: BuySellState, payload: ResetBuySellPayload): BuySellState => {
    entry = createDefault(payload.code)
    entry.order = new Order({} as PreOrderDTO)
    return entry
}

const updateEntry = (entry: BuySellState, payload: UpdateBuySellPayload): BuySellState => {
    // console.log('change ', payload)
    log.info('BuySell', 'MSG001', 'payload', payload)
    log.flushLogs()

    if (entry.apiError) {
        return entry
    }
    entry.hasError = payload.hasError || false
    entry.errorMessage = payload.errorMessage || ''

    if (entry.order) {
        // whe have a change?
        let haveChange: boolean = false
        const order = entry.order.clone()

        if (payload?.side && entry.order.getSide() !== payload?.side) {
            haveChange = true
            order.setSide(payload.side)
        }

        if (isDefined(payload?.quantity)) {
            // @ts-ignore
            order.setQuantity(payload.quantity)
            haveChange = true
        }

        if (isDefined(payload?.limitPrice)) {
            // @ts-ignore
            order.setLimitPrice(payload.limitPrice)
            haveChange = true
        }

        if (payload?.orderType && entry.order.getOrderType() !== payload?.orderType) {
            order.setOrderType(payload.orderType)
            haveChange = true
            if (payload?.orderType === 'LIMIT') {
                order.setLimitPrice(0)
            }
        }

        if (payload?.validity && entry.order.getValidity() !== payload?.validity) {
            order.setValidity(payload.validity)
            haveChange = true
        }

        if (haveChange) {
            entry.order = order
        }
        performValidation(entry)
    }
    return entry
}
const differentMoney = (a: MoneyDTO | undefined, b: MoneyDTO | undefined): boolean => {
    if (!a && !b) {
        return false
    } else if (a && !b) {
        return true
    } else if (!a && b) {
        return true
    } else {
        // @ts-ignore
        if (a.currency !== b.currency) {
            return true
        } else {
            // @ts-ignore
            return a.value !== b.value
        }
    }
}
const performValidation = (entry: BuySellState) => {
    if (entry.order) {
        const order: BuySellOrder = entry.order

        if (!entry.ticker) {
            entry.errorMessage = 'Please select a ticker.'
            entry.hasError = true
            return
        }

        if (entry.order?.getSide() && !entry.ticker) {
            entry.errorMessage = 'Please select the ticker first.'
            entry.hasError = true
            return
        }

        if (!entry.order?.getSide()) {
            entry.errorMessage = 'Please select SELL or BUY.'
            entry.hasError = true
            return
        }

        if (order.isCloseout() && order.isShort()) {
            if (order.getQuantity() > order.getMaxQuantity()) {
                entry.errorMessage = 'Reduce quantity, max reached.'
                entry.hasError = true
                entry.canOrder = false
                return
            }
        }
        // if (isNaN(_limitPrice) || _limitPrice === 0) {
        if (order.getOrderType() === 'LIMIT' && !(order.getLimitPrice() > 0)) {
            entry.errorMessage = 'Limit must be greater than zero'
            entry.hasError = true
            entry.canOrder = false
            // return
        }

        if (order.getQuantity() < 1) {
            entry.errorMessage = 'Quantity must be greater than  zero'
            entry.hasError = true
            // return
        }

        if (order.isCloseout()) {
            if (order.getQuantity() > order.getOriginalQuantity()) {
                entry.hasError = true
                entry.errorMessage = 'Quantity cannot be greater than position quantity.'
                entry.canOrder = false
                return
            }
        }

        // free Equity Check
        if (entry.freeEquityCurrency) {
            const currency: CurrencyDTO = current(entry.freeEquityCurrency)

            // update notionalPrice if changed
            const newNotionalPrice: MoneyDTO = {
                value: order.getNotional(),
                currency: currency,
            }
            const currentNotionalPrice: MoneyDTO | undefined = current(entry.notionalPrice)
            if (differentMoney(newNotionalPrice, currentNotionalPrice)) {
                entry.notionalPrice = newNotionalPrice
            }

            // if (order?.isCloseout()) {
            //     if (!entry.canShowInfo) {
            //         entry.canShowInfo = true
            //     }
            //     return
            // }

            // update margin if changed
            const newMarginRequired: MoneyDTO = {
                value: order.getNotional() * (entry.margin || 0),
                currency: currency,
            }
            const currentMarginRequired: MoneyDTO | undefined = current(entry.marginRequired)
            if (differentMoney(newMarginRequired, currentMarginRequired)) {
                entry.marginRequired = newMarginRequired
            }

            // update AvailableEquity if changed
            // POR-523
            // was value: entry.freeEquity!.value - (entry.marginRequired!.value + order.getNotional()),
            // s/be value: entry.freeEquity!.value - entry.marginRequired!.value

            const newAvailableEquity: MoneyDTO = {
                value: entry.freeEquity!.value - entry.marginRequired!.value,
                currency: currency,
            }
            const currentAvailableEquity: MoneyDTO | undefined = current(entry.availableEquity)
            if (differentMoney(newAvailableEquity, currentAvailableEquity)) {
                entry.availableEquity = newAvailableEquity
            }

            if (order?.getSide() === 'BUY') {
                if (!order.isCloseout() && entry.availableEquity!.value < 0) {
                    entry.hasError = true
                    entry.errorMessage = 'Not enough free equity to proceed.'
                    entry.canOrder = false
                    return
                }
            }

            if (order?.getSide() === 'SELL') {
                if (!order.isCloseout() && entry.availableEquity!.value < 0) {
                    entry.hasError = true
                    entry.errorMessage = 'Not enough free equity to proceed.'
                    entry.canOrder = false
                    return
                }
            }
        }
        if (!entry.canShowInfo) {
            entry.canShowInfo = true
        }

        // only change if different
        if (order.getOrderType() === 'LIMIT' && order.getLimitPrice() > 0 && entry.order.getQuantity() > 0) {
            if (!entry.canOrder) {
                entry.canOrder = true
            }
        } else if (order.getOrderType() === 'MARKET' && entry.order.getQuantity() > 0) {
            if (!entry.canOrder) {
                entry.canOrder = true
            }
        } else {
            if (entry.canOrder) {
                entry.canOrder = false
            }
        }
    }
}
const performFreeEquityCheck = (entry: BuySellState): BuySellState => {
    performValidation(entry)
    return entry
}

// config
export const buySellSlice = createSlice({
    name: 'buySellSlice',
    initialState,
    reducers: {
        initBuySellOrder(state: MasterBuySellState, action: PayloadAction<InitPayload>) {
            const entry: BuySellState = getOrderState(state, action.payload.code)
            state[action.payload.code] = loadInitValues(entry, action.payload)
            return state
        },
        updateBuySellOrder(state: MasterBuySellState, action: PayloadAction<UpdateBuySellPayload>) {
            const entry: BuySellState = getOrderState(state, action.payload.code)
            updateEntry(entry, action.payload)
            // state[action.payload.code] = updateEntry(entry, action.payload)
            // return state
        },
        resetBuySellOrder(state: MasterBuySellState, action: PayloadAction<ResetBuySellPayload>) {
            const entry: BuySellState = getOrderState(state, action.payload.code)
            state[action.payload.code] = performResetBuySellOrder(entry, action.payload)
            // state[action.payload.code] = updateEntry(entry, action.payload)
            // return state
        },
        updatePrice(state: MasterBuySellState, action: PayloadAction<UpdatePricePayload>) {
            const entry: BuySellState = getOrderState(state, action.payload.code)
            performUpdatePrice(entry, action.payload)
        },
        updateTickerSelection(state: MasterBuySellState, action: PayloadAction<UpdateTickerPayload>) {
            const entry: BuySellState = getOrderState(state, action.payload.code)
            performUpdateTickerSelection(entry, action.payload)
        },
        showError(state: MasterBuySellState, action: PayloadAction<ErrorPayload>) {
            const entry: BuySellState = getOrderState(state, action.payload.code)
            performShowError(entry, action.payload)
        },
        performCalculation(state: MasterBuySellState, action: PayloadAction<PerformCalculationPayload>) {
            const entry: BuySellState = getOrderState(state, action.payload.code)
            performFreeEquityCheck(entry)
        },
        removeBuySellOrder(state, action: PayloadAction<RemovePayload>) {
            delete state[action.payload.code]
        },
        updateBlotterPath(state, action: PayloadAction<BlotterPathPayload>) {
            const entry: BuySellState = getOrderState(state, action.payload.code)
            entry.blotterPath = action.payload.path
        },
    },
})

export const {
    initBuySellOrder,
    removeBuySellOrder,
    updateBuySellOrder,
    updatePrice,
    updateTickerSelection,
    resetBuySellOrder,
    showError,
    performCalculation,
    updateBlotterPath,
} = buySellSlice.actions

export default buySellSlice.reducer
