import React, { useContext } from 'react'
import { Subscription } from 'rxjs'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { BlastContext } from '../../../../../providers/blast/BlastContext'
import { FieldDeltaDO, SimpleMessageBO } from '../../../../../providers/blast/commands'
import { SessionContext } from '../../../../../providers/session/SessionContext'
import { RunOnceEffect, unSubscribe } from '../../../../../utils/Utils'
import { BetSlipState, quoteUpdate } from '../../betSlipSlice'
import { Quote } from '../../components/Quote'
import { BrokerBetsStyled, BrokerNoBetsStyled } from '../../styled'
import { QuoteDO } from '../../types'

interface BrokerageTabProperties {
    onClose?: () => void
}

export const BrokerageTab = ({ onClose }: BrokerageTabProperties): React.ReactElement<BrokerageTabProperties> => {
    const dispatch = useAppDispatch()

    const blastContext = useContext(BlastContext)
    const sessionContext = useContext(SessionContext)

    RunOnceEffect(() => {
        let blastDeltaSubscription: Subscription | undefined = undefined
        let loaderSubscription = sessionContext.watchSystemReady().subscribe((systemReady: boolean) => {
            blastDeltaSubscription = blastContext.observeInboundCommands().subscribe((message: SimpleMessageBO<FieldDeltaDO>) => {
                if (systemReady && message && message.cmd === 'table.delta' && message.data && message.data.tableKey === 'quote') {
                    const quote: QuoteDO = message?.data?.value
                    dispatch(quoteUpdate({ quote: quote }))
                }
            })
        })
        return () => {
            unSubscribe(loaderSubscription)
            unSubscribe(blastDeltaSubscription)
        }
    })

    const _betSlipState: BetSlipState = useAppSelector((state) => state.betSlipSlice)

    if (Object.values(_betSlipState?.quotes).length === 0) {
        return <BrokerNoBetsStyled>You have no existing quote requests, select an event.</BrokerNoBetsStyled>
    }
    return (
        <BrokerBetsStyled>
            {Object.values(_betSlipState?.quotes).map((quote: QuoteDO, idx: number) => (
                <Quote quote={quote} key={'quote_' + quote.id} onClose={onClose} />
            ))}
        </BrokerBetsStyled>
    )
}
