import React, { useContext } from 'react'
import { EnvironmentContext } from '../../../../../providers/environment/EnvironmentContext'
import { SystemContext } from '../../../../../providers/system/SystemContext'
import { FlexList } from '../../../../flex-list/FlexList'
import { IMenuDTO, QueryParamsDTO } from '../../../../types'
import { BetSlipTabScrollContainerStyled, OpenBetsTabWrapperStyled } from '../../styled'

interface OpenBetsTabProperties {
    onClose?: () => void
}

export const OpenBetsTab = ({}: OpenBetsTabProperties): React.ReactElement<OpenBetsTabProperties> => {
    const environmentContext = useContext(EnvironmentContext)
    const systemContext = useContext(SystemContext)

    // let menu: IMenuDTO | undefined = systemContext.getChildMenuConfig('Main', 'open-bets-bet-slip');
    let menu: IMenuDTO | undefined = systemContext.getMenuConfig('open-bets-bet-slip')
    if (menu) {
        menu = { ...menu, noLabel: true }
    }
    const isMobile = environmentContext.isMobileDevice()

    const queryParams: QueryParamsDTO = { skip: 0, limit: 30 }
    if (!menu) {
        return <></>
    }
    return (
        <OpenBetsTabWrapperStyled isMobile={isMobile}>
            <BetSlipTabScrollContainerStyled>
                <FlexList menuItem={menu} queryParams={queryParams} />
            </BetSlipTabScrollContainerStyled>
        </OpenBetsTabWrapperStyled>
    )
}
