import styled from 'styled-components'

export const ReportWrapperStyled = styled.div.attrs((props) => ({
    className: 'form-control  mb-1 bg-transparent rounded-0 border-top-0 border-start-0 border-end-0 ',
}))`
    color: ${(props) => props.theme.table.columnColor} !important ;
    font-size: ${(props) => props.theme.table.columnFontSize};

    height: 100% !important;
`

// vvvv removed positions in alvar
// margin-right: 10px;

/*
        padding:20px;
events ^^
 */
export const ReportContainerStyled = styled.div.attrs((props) => ({
    className: 'row g-0',
}))``

export const InputWrapperStyled = styled.div.attrs((props) => ({}))``
