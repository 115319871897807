import React, { useState } from 'react'
import LoadingSpinner from '../../loading-spinner/LoadingSpinner'
import { SpinnerPlacementStyled } from './styled'

interface TableSpinnerDO {
    size?: number | undefined
}

export default function TableSpinner({ size = 50 }: TableSpinnerDO) {
    const [className] = useState('TableSpinner-' + new Date().getTime())

    return (
        <SpinnerPlacementStyled>
            <LoadingSpinner size={size} />
        </SpinnerPlacementStyled>
    )
    // return (
    //     <ElementWithModal open hideHeader centered element={<></>}
    //                       modalContent={<div><LoadingSpinner size={size}/> <br/> <p className={'small text-center'}>loading...</p></div>}
    //                       size={'sm'}/>
    // );
}
