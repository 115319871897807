import { format, parseISO } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { BlastContext } from '../../providers/blast/BlastContext'
import { environmentService } from '../../providers/environment/EnvironmentService'
import { unSubscribe } from '../../utils/Utils'
import { ActiveUsersState, toggleActiveUsers } from './activeUsersToggleSlice'
import {
    CloseIconStyled,
    DetailColumnStyled,
    DetailHeaderStyled,
    DetailTableStyled,
    DisplayFeContainerStyled,
    DisplayFeDetailsStyled,
    FeDetailsStyled,
    FeHeaderDetailsStyled,
    FeUsersStyled,
    MessageStyled,
} from './styled'

interface FEBlastLoggedInDetailsDO {
    id: string
    avatar?: string
    username?: string
    nickname?: string
    since?: string
}

export const ActiveFeUsers = (): React.ReactElement => {
    const blastContext = React.useContext(BlastContext)

    const [showActiveFEUsers] = useState<boolean>(() => {
        return environmentService.getEnvironment()?.showActiveFEUsers || false
    })
    const [users, setUsers] = useState<FEBlastLoggedInDetailsDO[]>([])
    const state: ActiveUsersState = useAppSelector((state) => state.activeUsersToggleSlice)
    const dispatch = useAppDispatch()

    const [blastConnected, setBlastConnected] = useState<boolean>(false)

    useEffect(() => {
        const activeUsersSubscription = blastContext.observeCollection('fe.users').subscribe((data: any[]) => {
            setUsers(data)
        })
        const blastConnectionSubscription = blastContext.observeConnection().subscribe((connected: boolean) => {
            setBlastConnected(connected)
        })
        return () => {
            unSubscribe(activeUsersSubscription)
            unSubscribe(blastConnectionSubscription)
        }
    }, [blastContext])

    const displayUserDetails = (): React.ReactElement => {
        if (users.length === 0) {
            return <MessageStyled>No clients logged in.</MessageStyled>
        }
        return (
            <>
                <DetailTableStyled numberRows={users.length}>
                    <thead>
                        <tr>
                            <DetailHeaderStyled>User</DetailHeaderStyled>
                            {/*<DetailHeaderStyled>User</DetailHeaderStyled>*/}
                            <DetailHeaderStyled>Name</DetailHeaderStyled>
                            <DetailHeaderStyled align={'right'}>Since</DetailHeaderStyled>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => {
                            return (
                                <tr key={user.id}>
                                    <DetailColumnStyled>{user.id}</DetailColumnStyled>
                                    {/*<DetailColumnStyled>{user.username}</DetailColumnStyled>*/}
                                    <DetailColumnStyled>{user.nickname}</DetailColumnStyled>
                                    <DetailColumnStyled align={'right'}>
                                        {user.since ? format(parseISO(user.since), 'HH:mm dd-MM') : ''}
                                    </DetailColumnStyled>
                                </tr>
                            )
                        })}
                    </tbody>
                </DetailTableStyled>
            </>
        )
    }

    const showUserDetails = (): React.ReactElement => {
        return (
            <DisplayFeContainerStyled>
                <DisplayFeDetailsStyled>
                    <FeHeaderDetailsStyled>
                        Current logged in clients
                        <CloseIconStyled
                            role={'button'}
                            onClick={() =>
                                dispatch(
                                    toggleActiveUsers({
                                        showFEUsers: false,
                                        showBOUsers: false,
                                    })
                                )
                            }
                        />
                    </FeHeaderDetailsStyled>
                    <FeDetailsStyled>{displayUserDetails()}</FeDetailsStyled>
                </DisplayFeDetailsStyled>
            </DisplayFeContainerStyled>
        )
    }

    const renderUsers = (): React.ReactElement => {
        return (
            <>
                <FeUsersStyled
                    onClick={() =>
                        dispatch(
                            toggleActiveUsers({
                                showFEUsers: !state.showFEUsers,
                                showBOUsers: false,
                            })
                        )
                    }
                >
                    {users.length + (users.length === 0 ? ' Clients' : ' Client')}{' '}
                </FeUsersStyled>
                {state.showFEUsers && showUserDetails()}
            </>
        )
    }

    if (!showActiveFEUsers) {
        return <></>
    }
    return <>{blastConnected ? renderUsers() : <></>}</>
}
