import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SessionContextProps } from '../../../providers/session/types'
import { constructUrl } from '../../../utils/Utils'
import { ActionCompletedBO, CurrencyDTO, MoneyDTO } from '../../types'
import {
    AllTabs,
    BetPlacedDTO,
    BrokerageTab,
    BrokerBetRequestDTO,
    ButtonAction,
    ButtonState,
    OpenBetSlipResponseDTO,
    OpenBetsTab,
    OutcomeInfoDO,
    ParlaysTab,
    QuoteCancelRequestDTO,
    QuoteDO,
    QuoteRequestDTO,
    QuoteState,
    SelectedEventDTO,
    SingleTab,
    TabDO,
} from './types'

export interface BetSlipInitState {}

export interface BetSlipTabSelectedAction {
    tab: TabDO
}

export interface BetSlipQuoteUpdateAction {
    quote: QuoteDO
}

export interface OutcomeSelectedAction {
    quoteId: string
    outcomeSelected: OutcomeInfoDO
}

export interface RiskAmountChangedAction {
    quoteId: string
    riskAmount: MoneyDTO
}

export interface QuoteStateChangeAction {
    quoteId: string
    quoteState: QuoteState
}

export interface RemoveQuoteAction {
    quoteId: string
}

export interface BetSlipState {
    currentTab: TabDO
    tabs: TabDO[]
    selectedEvent: SelectedEventDTO | undefined

    quoteEntry: QuoteDO | undefined

    currency: CurrencyDTO // <-- this should be on the quote

    quotes: { [id: string]: QuoteDO }

    currentQuotes: QuoteDO[]
}

const getInitialState = (): BetSlipState => {
    // vvvv temporary
    const tabs: TabDO[] = [SingleTab, ParlaysTab, BrokerageTab, OpenBetsTab]

    return {
        tabs: tabs,
        currentTab: BrokerageTab,
        selectedEvent: undefined,
        quoteEntry: undefined,
        quotes: {},
        currentQuotes: [],
        currency: { code: 'USD' },
    }
}

interface EventRequest {
    eventId: string
    marketId: string | undefined
    url: string
    sessionContext: SessionContextProps
    abortController: AbortController
}

interface QuoteRequest {
    quoteId: string // <-- this is a temp id

    eventId: string
    marketId: string | undefined
    outcomeId: string | undefined

    riskAmount: MoneyDTO
    url: string
    sessionContext: SessionContextProps
    abortController: AbortController
}

interface QuoteCancelRequest {
    eventId: string

    marketId: string

    quoteId: string
    url: string
    sessionContext: SessionContextProps
    abortController: AbortController
}

interface StrikeQuoteRequest {
    quoteId: string
    selectedOutcome: OutcomeInfoDO
    url: string
    sessionContext: SessionContextProps
    abortController: AbortController
}

interface OpenBetSlipRequest {
    url: string
    sessionContext: SessionContextProps
    abortController: AbortController

    eventId?: string
    marketId?: string
}

export interface BetRequestResponseWrapper {
    eventId: string

    marketId: string

    response: SelectedEventDTO
}

export interface QuoteRequestResponseWrapper {
    response: QuoteDO
    tempQuoteId: string
}

export interface CancelQuoteRequestResponseWrapper {
    response: ActionCompletedBO
}

export interface StrikeQuoteRequestResponseWrapper {
    quoteId: string
    response: BetPlacedDTO
}

export interface OpenBetSlipResponseWrapper {
    quoteId: string
    response: OpenBetSlipResponseDTO

    eventId: string

    marketId: string
}

const getQuoteState = (quote: QuoteDO, prevState?: QuoteState): QuoteState => {
    if (quote) {
        if (quote.status === 'REQUESTED') {
            return QuoteState.REQUESTED
        }
        if (quote.status === 'REQUEST_WAITING') {
            return QuoteState.REQUEST_WAITING
        }
        if (quote.status === 'EXPIRED') {
            return QuoteState.EXPIRED
        }
        if (quote.status === 'TRADER_CANCELLED') {
            return QuoteState.TRADER_CANCELLED
        }
        if (quote.status === 'CUSTOMER_CANCELLED') {
            return QuoteState.CUSTOMER_CANCELLED
        }
        if (quote.status === 'ACCEPTING') {
            if (prevState === 'STRIKING') {
                return QuoteState.STRIKING
            }
            return QuoteState.ACCEPTING
        }
    }
    return QuoteState.INIT
}

const performOutcomeSelected = (state: BetSlipState, quoteId: string, outcome: OutcomeInfoDO) => {
    if (quoteId) {
        const quote = state.quotes[quoteId]
        if (quote) {
            quote.selectedOutcome = outcome
            quote.buttonAction = ButtonAction.STRIKE_BET
        }
    }
}
const performRiskAmountChanged = (state: BetSlipState, quoteId: string, riskAmount: MoneyDTO) => {
    if (quoteId) {
        const quote = state.quotes[quoteId]
        if (quote) {
            quote.riskAmount = riskAmount
        }
    }
}

const performQuoteStateChange = (state: BetSlipState, quoteId: string, quoteState: QuoteState) => {
    if (quoteId) {
        const quote = state.quotes[quoteId]
        if (quote) {
            quote.quoteState = quoteState
            if (quoteState === QuoteState.REQUESTING) {
                state.quotes[quoteId] = quote
            }
        }
    }
}

const performQuoteUpdate = (state: BetSlipState, quote: QuoteDO, tempQuoteId?: string) => {
    if (quote && quote.id) {
        if (tempQuoteId) {
            delete state.quotes[tempQuoteId]
        }

        const previousQuoteState: QuoteDO | undefined = state.quotes[quote.id]

        quote.refreshCounter = (quote.refreshCounter || 0) + 1

        const quoteState: QuoteState = getQuoteState(quote, previousQuoteState?.quoteState)
        quote.quoteState = quoteState
        if (quoteState === QuoteState.ACCEPTING && previousQuoteState) {
            quote.selectedOutcome = previousQuoteState.selectedOutcome
            quote.buttonAction = previousQuoteState.buttonAction
        }
        if (quote?.outcomes?.outcomes) {
            quote.outcomes.outcomes = quote?.outcomes?.outcomes.sort((a, b) => {
                const orderA = a?.displayOrder || 10
                const orderB = b?.displayOrder || 10
                return orderA - orderB
            })
        }

        state.quotes[quote.id] = quote

        if (
            quoteState === QuoteState.EXPIRED ||
            quoteState === QuoteState.CANCELLING ||
            quoteState === QuoteState.TRADER_CANCELLED ||
            quoteState === QuoteState.NETWORK_ERROR ||
            quoteState === QuoteState.CUSTOMER_CANCELLED
        ) {
            // clear catch all timeout
            console.log('------------------------------ ', quoteState)
            quote.selectedOutcome = undefined
            quote.buttonAction = ButtonAction.REQUEST
            quote.refreshCounter = (quote.refreshCounter || 0) + 1

            // setQuote(undefined)
            // setAcceptTimeout(-1)
            // clearTimeout(timeoutHandler);
        }
    }
}

export const openBetSlip = createAsyncThunk('open-bet-slip', async (request: OpenBetSlipRequest, { rejectWithValue }) => {
    try {
        const response = await request.sessionContext.post<any, OpenBetSlipResponseDTO>(
            constructUrl(request.url, {}),
            {
                eventId: request.eventId,
                marketId: request.marketId,
            },
            request.abortController
        )
        const selectedEvent: SelectedEventDTO | undefined = response?.data?.event
        if (selectedEvent && selectedEvent.outcomes) {
            selectedEvent.outcomes = selectedEvent.outcomes.sort((a, b) => {
                const orderA = a?.displayOrder || 10
                const orderB = b?.displayOrder || 10
                return orderA - orderB
            })
            selectedEvent.refreshCounter = 1
        }

        return {
            response: response?.data,
            eventId: request.eventId,
            marketId: request.marketId || '',
        } as OpenBetSlipResponseWrapper
    } catch (error) {
        return rejectWithValue(error)
    }
})

export const getEventData = createAsyncThunk('get-event', async (request: EventRequest, { rejectWithValue }) => {
    try {
        const response = await request.sessionContext.post<BrokerBetRequestDTO, SelectedEventDTO>(
            constructUrl(request.url, {}),
            {
                eventId: request.eventId,
                marketId: request.marketId || '',
            },
            request.abortController
        )

        const selectedEvent: SelectedEventDTO | undefined = response?.data
        if (selectedEvent && selectedEvent.outcomes) {
            selectedEvent.outcomes = selectedEvent.outcomes.sort((a, b) => {
                const orderA = a?.displayOrder || 10
                const orderB = b?.displayOrder || 10
                return orderA - orderB
            })
            selectedEvent.refreshCounter = 1
        }

        return {
            eventId: request.eventId,
            marketId: request.marketId || '',
            response: selectedEvent,
            buttonState: ButtonState.INIT,
            quoteState: QuoteState.INIT,
        } as BetRequestResponseWrapper
    } catch (error) {
        return rejectWithValue(error)
    }
})

export const requestQuote = createAsyncThunk('request-quote', async (request: QuoteRequest, { rejectWithValue }) => {
    try {
        const response = await request.sessionContext.post<QuoteRequestDTO, QuoteDO>(
            constructUrl(request.url, {}),
            {
                eventId: request.eventId,
                marketId: request.marketId || '',
                outcomeId: request.outcomeId,
                riskAmount: request.riskAmount,
            },
            request.abortController
        )
        const quote: QuoteDO = response?.data
        if (quote?.outcomes?.outcomes) {
            quote.outcomes.outcomes = quote?.outcomes?.outcomes.sort((a, b) => {
                const orderA = a?.displayOrder || 10
                const orderB = b?.displayOrder || 10
                return orderA - orderB
            })
        }

        quote.refreshCounter = 1
        quote.quoteState = QuoteState.REQUESTING

        return {
            response: quote,
            tempQuoteId: request.quoteId,
        } as QuoteRequestResponseWrapper
    } catch (error) {
        return rejectWithValue(error)
    }
})

export const cancelQuote = createAsyncThunk('cancel-quote', async (request: QuoteCancelRequest, { rejectWithValue }) => {
    try {
        const response = await request.sessionContext.post<QuoteCancelRequestDTO, ActionCompletedBO>(
            constructUrl(request.url, {}),
            {
                eventId: request.eventId,
                marketId: request.marketId || '',
                id: request.quoteId,
            },
            request.abortController
        )
        return {
            response: response?.data,
        } as CancelQuoteRequestResponseWrapper
    } catch (error) {
        return rejectWithValue(error)
    }
})

export const strikeQuote = createAsyncThunk('strike-quote', async (request: StrikeQuoteRequest, { rejectWithValue }) => {
    try {
        const response = await request.sessionContext.post<any, BetPlacedDTO>(
            constructUrl(request.url, {}),
            {
                ...request.selectedOutcome,
                quoteId: request.quoteId,
            },
            request.abortController
        )
        return {
            response: response?.data,
            quoteId: request.quoteId,
        } as StrikeQuoteRequestResponseWrapper
    } catch (error) {
        return rejectWithValue(error)
    }
})

export const betSlipSlice = createSlice({
    name: 'betSlipSlice',
    initialState: getInitialState(),
    reducers: {
        betSlipTabSelected(state, action: PayloadAction<BetSlipTabSelectedAction>) {
            if (action?.payload?.tab) {
                state.currentTab = action?.payload?.tab
            }
        },
        quoteUpdate(state, action: PayloadAction<BetSlipQuoteUpdateAction>) {
            performQuoteUpdate(state, action.payload.quote)
        },
        outcomeSelected(state, action: PayloadAction<OutcomeSelectedAction>) {
            if (action.payload.outcomeSelected) {
                performOutcomeSelected(state, action.payload.quoteId, action.payload.outcomeSelected)
            }
        },
        riskAmountChanged(state, action: PayloadAction<RiskAmountChangedAction>) {
            if (action.payload.riskAmount) {
                performRiskAmountChanged(state, action.payload.quoteId, action.payload.riskAmount)
            }
        },
        changeQuoteState(state, action: PayloadAction<QuoteStateChangeAction>) {
            performQuoteStateChange(state, action.payload.quoteId, action.payload.quoteState)
        },
        removeQuote(state, action: PayloadAction<RemoveQuoteAction>) {
            if (action.payload.quoteId) {
                delete state.quotes[action.payload.quoteId]
            }
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(openBetSlip.fulfilled, (state, action: PayloadAction<OpenBetSlipResponseWrapper>) => {
                const userTabs: TabDO[] = []
                ;(action.payload?.response?.allowedTabs || []).forEach((tabCode: string) => {
                    AllTabs.filter((tab) => tab.code === tabCode).forEach((tab) => userTabs.push(tab))
                })
                state.tabs = userTabs
                state.currentTab = BrokerageTab

                if (action.payload?.response?.currentQuotes) {
                    const userQuotes: { [id: string]: QuoteDO } = {}
                    action.payload?.response?.currentQuotes?.forEach((quote) => (userQuotes[quote.id] = quote))
                    state.quotes = { ...userQuotes }
                }
                if (action.payload.response?.event) {
                    state.selectedEvent = action.payload.response.event
                    state.currency = {
                        code: action.payload.response.event.currencyCode,
                    }

                    state.quotes['-1'] = {
                        id: '-1',
                        eventId: action.payload.eventId,
                        marketId: action.payload.marketId,
                        breadCrumbs: action.payload.response.event.breadCrumbs,
                        outcomes: { outcomes: action.payload.response.event.outcomes },
                        quoteState: QuoteState.INIT,
                        riskAmount: {
                            currency: { code: action.payload.response.event.currencyCode },
                            value: 0,
                        },
                        eventName: action.payload.response.event.eventName,
                    }
                }
            })

            .addCase(getEventData.fulfilled, (state, action: PayloadAction<BetRequestResponseWrapper>) => {
                state.selectedEvent = action.payload.response
                state.currency = { code: action.payload.response.currencyCode }

                // tabs s/be set by the API call
                state.tabs = [SingleTab, ParlaysTab, BrokerageTab, OpenBetsTab]
                state.currentTab = BrokerageTab

                // state.quoteEntry =
                state.quotes['-1'] = {
                    id: '-1',
                    eventId: action.payload.eventId,
                    marketId: action.payload.marketId,
                    breadCrumbs: action.payload.response.breadCrumbs,
                    outcomes: { outcomes: action.payload.response.outcomes },
                    quoteState: QuoteState.INIT,
                    riskAmount: {
                        currency: { code: action.payload.response.currencyCode },
                        value: 0,
                    },
                    eventName: action.payload.response.eventName,
                }
            })

        builder.addCase(requestQuote.fulfilled, (state, action: PayloadAction<QuoteRequestResponseWrapper>) => {
            performQuoteUpdate(state, action.payload.response, action.payload.tempQuoteId)
        })

        // ------------ cancel
        builder.addCase(cancelQuote.pending, (state, action) => {})
        builder.addCase(cancelQuote.fulfilled, (state, action: PayloadAction<CancelQuoteRequestResponseWrapper>) => {})

        builder.addCase(strikeQuote.fulfilled, (state, action: PayloadAction<StrikeQuoteRequestResponseWrapper>) => {
            delete state.quotes[action.payload.quoteId]
        })
    },
})

export const { betSlipTabSelected, quoteUpdate, outcomeSelected, riskAmountChanged, changeQuoteState, removeQuote } = betSlipSlice.actions

export default betSlipSlice.reducer
