import React from 'react'
import { BuySellState } from '../../buySellSlice'
import { InputStyled, QuantityLabelStyled } from '../../styled_v2'

export interface RenderQuantityDO {
    buySellState: BuySellState
    quantityRef: React.MutableRefObject<any>
}

export default function RenderQuantity({ buySellState, quantityRef }: RenderQuantityDO) {
    if (buySellState.apiError) {
        return <></>
    }
    return (
        <div className={'d-flex align-items-center justify-content-between'}>
            <QuantityLabelStyled>Quantity</QuantityLabelStyled>
            <InputStyled
                type={'tel'}
                autoFocus={true}
                step={2}
                ref={quantityRef}
                id={'quantity'}
                placeholder={'Quantity'}
                autoComplete={'off'}
                required={true}
            />
        </div>
    )
}
