import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SessionContextProps } from '../../../../providers/session/types'
import { constructUrl } from '../../../../utils/Utils'
import { OSSArticleDTO } from '../types'

export interface ArticleModalState {
    selectedArticle: OSSArticleDTO | undefined
}

const initialState: ArticleModalState = {
    selectedArticle: undefined,
}

export interface ArticleModalCleanUpPayload {}

interface DataRequest {
    articleId: string
    sessionContext: SessionContextProps
    abortController: AbortController
}

interface DataResponse {
    currentArticle: OSSArticleDTO
}

export interface GetArticleResponseWrapper {
    response: DataResponse
}

export const getArticle = createAsyncThunk('get-article-url', async (request: DataRequest) => {
    const response = await request.sessionContext.post<any, DataResponse>(
        constructUrl('/otto-articles/{articleId}/get', {
            articleId: request.articleId,
        }),
        {},
        request.abortController
    )
    return { response: response?.data || {} }
})

export const articleModalSlice = createSlice({
    name: 'articleModalSlice',
    initialState,
    reducers: {
        cleanUpArticleModal(state, action: PayloadAction<ArticleModalCleanUpPayload>) {
            state.selectedArticle = undefined
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getArticle.fulfilled, (state, action: PayloadAction<GetArticleResponseWrapper>) => {
            state.selectedArticle = action.payload.response.currentArticle
        })
    },
})

export const { cleanUpArticleModal } = articleModalSlice.actions

export default articleModalSlice.reducer
