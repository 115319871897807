import { Editor } from 'grapesjs'

export const OssComment = (editor: Editor, options = {}) => {
    editor.Components.addType('cmp-text', {
        isComponent: (el) => el.classList?.contains('oss-text'),
        model: {
            defaults: {
                class: 'ossy-comment',
                type: 'text',
                name: 'Oss Text', // Simple custom name
                draggable: false,
            },
        },
        view: {
            tagName: () => 'oss-comment',
        },
    })

    editor.Components.addType('cmp-link', {
        isComponent: (el) => el.classList?.contains('oss-link'),
        model: {
            defaults: {
                class: 'ossy-comment',
                type: 'link',
                name: 'Oss link', // Simple custom name
                draggable: false,
            },
        },
    })

    /*
            content: `<div class="oss-comment" data-gjs-type="oss-text">
<div data-gjs-type="cmp-text" class="oss-text oss-symbol">Symbol</div>
<div data-gjs-type="cmp-text" class="oss-text oss-comment">Comment</div>
</div>
<style>
         .oss-symbol { font-weight:bold; margin-right: 10px; background-color: rgb(188 192 240);;}
         .oss-comment { display:flex;}
        </style>`,

     */

    editor.Blocks.add('oss-comment', {
        label: 'Comment',
        category: 'OSS',
        attributes: { fred: 'oss-comment' },
        media: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 125" style="enable-background:new 0 0 100 100;" xml:space="preserve"><g><polygon points="23.77,63.33 43.19,63.33 58.34,78.48 61.17,75.65 44.85,59.33 27.77,59.33 27.77,24.94 72.23,24.94 72.23,59.33    59.76,59.33 59.76,63.33 76.23,63.33 76.23,20.94 23.77,20.94  "/></g></svg>`,
        content: {
            name: 'oss-comment',
            class: 'ossx-comment',
            type: 'text',
            content: `<div class="oss-comment" data-gjs-type="oss-text">
<div data-gjs-type="cmp-text" class="oss-text oss-symbol">Symbol</div>
<div data-gjs-type="cmp-text" class="oss-text oss-comment">Comment</div>
</div>
<style>
         .oss-symbol { font-weight:bold; margin-right: 10px; background-color: rgb(188 192 240);;}
         .oss-comment { display:flex;}
        </style>`,
            style: { padding: '10px' },
            editable: true,
            traits: [
                // Strings are automatically converted to text types
                'name', // Same as: { type: 'text', name: 'name' }
                'placeholder',
                {
                    type: 'select', // Type of the trait
                    label: 'Type', // The label you will see in Settings
                    name: 'type', // The name of the attribute/property to use on component
                    options: [
                        { id: 'text', name: 'Text' },
                        { id: 'email', name: 'Email' },
                        { id: 'password', name: 'Password' },
                        { id: 'number', name: 'Number' },
                    ],
                },
                {
                    type: 'checkbox',
                    name: 'required',
                },
            ],
            // As by default, traits are binded to attributes, so to define
            // their initial value we can use attributes
            attributes: { type: 'text', required: true },
        },
    })
}
