import styled from 'styled-components'

interface SidePanelsControlProperties {
    isMobile: boolean
}

export const SidePanelsControlStyled = styled.div.attrs((props: SidePanelsControlProperties) => ({
    id: 'side-panel-control',
}))<SidePanelsControlProperties>`
    ${(props) => {
        if (!props.isMobile && props.theme?.filterControls?.bgColor) {
            return 'background-color: ' + props.theme.filterControls.bgColor + ';'
        } else {
            return undefined
        }
    }}

    ${(props) => {
        if (!props.isMobile && props.theme?.filterControls?.gridBorder) {
            return ' border: ' + props.theme.filterControls.gridBorder + ';'
        } else {
            return undefined
        }
    }}

    width: fit-content;
    ${(props) => !props.isMobile && 'margin: 10px;'}
    ${(props) => props.isMobile && 'margin-left: 8px;'}
    ${(props) => !props.isMobile && 'padding: 7px;'}
    ${(props) => !props.isMobile && 'border-radius: 5px;'}
    float: inline-end;
`
