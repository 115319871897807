import styled, { ThemeProps } from 'styled-components'
import { flipY, TableStyledProps } from '../common/gusl-table/styled/table/styled'

export const CohortSelectorContainerStyled = styled.div.attrs((props) => ({}))`
    margin-left: 50px;
    margin-top: 10px;
    display: inline-block;
`
export const CohortLookupStyled = styled.div.attrs((props) => ({
    id: props.id,
}))`
    z-index: 20;
    width: 150px;
    position: relative;
    left: -92px;
    top: 20px;
    height: 20px;

    ::-webkit-scrollbar {
        width: var(--scrollbar-width);
        height: var(--scrollbar-height);
    }
    ::-webkit-scrollbar-track {
        background: ${(props) => props.theme.table.panelBgColor};
    }
    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.dark[0]};
        border: 1px solid ${(props) => props.theme.colors.dark[1]};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${(props) => props.theme.table.titleBgColor};
    }
`

export interface CohortContainerStyledProps extends ThemeProps<any> {
    isMobile?: boolean
}

export const CohortContainerStyled = styled.div.attrs((props: CohortContainerStyledProps) => ({
    className: 'd-flex align-items-center justify-content-start mx-2',
}))<CohortContainerStyledProps>`
    //width: 60px;
    margin-top: ${(props) => (props.isMobile ? '7px' : '-10px')} !important;
`
export const CohortTableResponsiveStyled = styled.div.attrs((props: TableStyledProps) => ({
    className: props.className,
    id: props.id,
}))<TableStyledProps>`
    // ${flipY}   ;
    display: block;
    max-height: 200px;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-right: 0px;
`
