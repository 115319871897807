import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { guslStorage } from '../../../providers/session/GuslStorage'
import { GuslTableCleanUpPayload, GuslTableColumnWidthPayload, GuslTableResizedColumnNamePayload } from './guslTableSlice'

// -------------- actions
export interface ResizeColumnInitPayload {
    code: string
    resizedColumns: { [index: string]: number }
    columnsResetAt: number | null
}

// -------------- states
export interface TableColumnResizeState {
    code: string
    resizeColumnFieldName: string | undefined
    resizedColumns: { [index: string]: number | string }
    // MK 30-12-2023
    columnsResetAt: number | null
}

interface MasterTableResizeState {
    [id: string]: TableColumnResizeState
}

const initialState: MasterTableResizeState = {}

// -------------- utils

const loadInitValues = (values: ResizeColumnInitPayload): TableColumnResizeState => {
    return {
        code: values.code,
        resizeColumnFieldName: undefined,
        resizedColumns: values.resizedColumns,
        columnsResetAt: null,
    }
}

const createDefault = (code: string): TableColumnResizeState => {
    return {
        code: code,
        resizeColumnFieldName: undefined,
        resizedColumns: {},
        columnsResetAt: null,
    }
}
const getState = (state: MasterTableResizeState, code: string): TableColumnResizeState => {
    let entry: TableColumnResizeState = state[code]
    if (!entry) {
        entry = createDefault(code)
    }
    return entry
}

// -------------- config
export const guslColumnResizeSlice = createSlice({
    name: 'guslColumnResizeSlice',
    initialState,
    reducers: {
        initColumnResize(state, action: PayloadAction<ResizeColumnInitPayload>) {
            state[action.payload.code] = loadInitValues(action.payload)
            // console.log("loading initial values", action.payload.code, action.payload.resizedColumns);
        },
        setColumnHeaderWidth(state, action: PayloadAction<GuslTableColumnWidthPayload>) {
            const code: string = action.payload.code
            const entry: TableColumnResizeState = getState(state, code)
            // const cols: { [index: string]: number } = {... current(entry.resizedColumns)};
            // try {
            //     guslStorage.saveResizedColumns(code, cols);
            //     cols[action.payload.fld.name] = action.payload.width;
            //     entry.resizedColumns = cols;
            // } catch (err)  {
            //     console.log('err',err)
            // }
            // console.log('cols', cols)
            console.log('received')
            entry.resizedColumns[action.payload.fld.name] = action.payload.width
            guslStorage.saveResizedColumns(code, entry.resizedColumns)
            state[action.payload.code] = entry
        },
        resetColumnHeaders(state, action: PayloadAction<GuslTableCleanUpPayload>) {
            const code: string = action.payload.code
            const entry: TableColumnResizeState = getState(state, code)
            entry.resizedColumns = {}
            // MK 30-12-2023
            entry.columnsResetAt = new Date().getTime()
            guslStorage.resetResizedColumns(code)
            state[action.payload.code] = entry
        },
        setCurrentlyResizingColumnName(state, action: PayloadAction<GuslTableResizedColumnNamePayload>) {
            const code: string = action.payload.code
            const entry: TableColumnResizeState = getState(state, code)
            entry.resizeColumnFieldName = action.payload.fldName
            state[action.payload.code] = entry
        },
    },
})
export const { initColumnResize, setColumnHeaderWidth, resetColumnHeaders, setCurrentlyResizingColumnName } = guslColumnResizeSlice.actions

export default guslColumnResizeSlice.reducer
