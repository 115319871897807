import React, { useState } from 'react'
import LoadingSpinner from '../../common/loading-spinner/LoadingSpinner'
import { BetSlip } from './BetSlip'

interface RequestQuoteProperties {
    code?: string
    rowData?: any
    properties?: any
    onClose?: () => void
}

export const RequestQuote = ({
    code,
    rowData,
    properties,
    onClose,
}: RequestQuoteProperties): React.ReactElement<RequestQuoteProperties> => {
    const [className] = useState('RequestQuote-' + new Date().getTime())

    const [loaded, setLoaded] = useState<boolean>(true)

    const renderPanel = (): React.ReactElement => {
        console.log('eventId', rowData?.id)
        console.log('marketId', properties?.marketId)
        return <BetSlip onClose={onClose} eventId={rowData?.id} marketId={properties?.marketId} />
    }
    return <>{!loaded ? <LoadingSpinner /> : renderPanel()}</>
}
