import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../app/hooks'
import { SessionContext } from '../../../../providers/session/SessionContext'
import { cancelAbortController, RunOnceEffect, unSubscribe } from '../../../../utils/Utils'
import { Ticker } from '../../../ticker/Ticker'
import ArticleModal from '../article-modal/ArticleModal'
import { DEAL_PAGE } from '../deal-view/DealView'
import { ItemStyled, TickerWrapperStyled } from './styled'

const REFRESH_RATE = 10000

export const OttoTicker = (): React.ReactElement => {
    const sessionContext = React.useContext(SessionContext)
    const navigate = useNavigate()

    const [tickerData, setTickerData] = useState<{ id: string; value: string }[]>([])
    const [label, setLabel] = useState<string | undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(true)
    const dispatch = useAppDispatch()

    const [systemReady, setSystemReady] = useState<boolean>(false)
    const [lastDealId, setLastDealId] = useState<string | undefined>(undefined)
    const [articleIdForModal, setArticleIdForModal] = useState<string | undefined>(undefined)

    RunOnceEffect(() => {
        let loaderSubscription = sessionContext.watchSystemReady().subscribe((systemReady: boolean) => {
            setSystemReady(systemReady)
        })
        sessionContext.registerRefreshRate(REFRESH_RATE)
        let refreshSubscription = sessionContext.watchRefreshRate().subscribe((nextRefreshRate: number) => {
            if (nextRefreshRate !== -1 && REFRESH_RATE === nextRefreshRate) {
                refreshData()
            }
        })

        return () => {
            unSubscribe(loaderSubscription)
            unSubscribe(refreshSubscription)
        }
    })

    const refreshData = () => {
        let abortController = new AbortController()
        sessionContext
            .post<
                any,
                {
                    label: string
                    tickers: { id: string; value: string }[]
                }
            >('/otto-articles/get-ticker-data', {}, abortController)
            .then((response) => {
                setLoading(false)
                if (response?.data) {
                    setTickerData(response?.data?.tickers)
                    setLabel(response?.data?.label)
                }
            })
            .catch((error) => {
                setLoading(false)
                return () => {
                    cancelAbortController(abortController)
                }
            })
    }

    useEffect(() => {
        let mounted = true
        let abortController = new AbortController()
        if (systemReady) {
            refreshData()
        }
        return () => {
            cancelAbortController(abortController)
            mounted = false
        }
    }, [systemReady])

    const onItemClicked = (item: { id: string; value: string; type: string }) => {
        if (item?.type && item?.type === 'DEAL') {
            navigate(DEAL_PAGE + '/' + item.id) //  + '/' + selectedEvent.id)

            // let code = 'events'
            // dispatch(setDealOpenState({opened: false}))
            // dispatch(selectedOttoEvent({code: code, selectedEvent: {dealId: item.id, id: item.id}}))
            // dispatch(setDealOpenState({opened: true}))
            // setLastDealId(item.id)
        } else if (item?.type && item?.type === 'ARTICLE') {
            setArticleIdForModal(item.id)
        }
    }
    const renderItem = (item: { id: string; value: string; type: string }) => {
        return <ItemStyled onClick={() => onItemClicked(item)}>{item.value}</ItemStyled>
    }

    return (
        <>
            {systemReady && tickerData.length > 0 && (
                <TickerWrapperStyled>
                    <Ticker
                        label={label}
                        component={renderItem}
                        data={tickerData}
                        speed={50} /* 60 or less */
                        delay={0}
                        loop={true}
                        direction={'normal'}
                        keyName="_id"
                        tickerStyle={{
                            backgroundColor: '#ffffff',
                            width: '100%',
                            height: '46px',
                            zIndex: 99,
                            borderRadius: '12px',
                            // padding: '5px',
                            marginTop: '5px',
                            padding: '2px 4px 2px 4px',
                            fontFamily: 'Inter, sans-serif',
                        }}
                    />
                </TickerWrapperStyled>
            )}
            {articleIdForModal && <ArticleModal articleId={articleIdForModal} onHideCallBack={() => setArticleIdForModal(undefined)} />}
        </>
    )
}
