import { arrayNotEmpty } from '../../../utils/TypeCheckers'
import { ChartDO } from '../types'

export function checkInitialChartType(chart_data: ChartDO) {
    try {
        const initialChartType = chart_data.initialChartType
        const availableChartTypes = chart_data.chartTypeMenu?.map((item) => item.type)
        //console.log('======> availableChartTypes',availableChartTypes)
        if (availableChartTypes && !arrayNotEmpty(availableChartTypes)) return initialChartType
        return availableChartTypes?.indexOf(initialChartType) === -1 ? availableChartTypes[0] : initialChartType
    } catch (e) {
        return 'line'
    }
}
