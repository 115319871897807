import React, { MouseEventHandler } from 'react'
import { ComponentTitleWrapperStyled } from '../../../ui/styled'
import { ElementWithTooltip } from '../../element-with/element-with-tooltip/ElementWithTooltip'
import { TableSidePanelTitleStyled } from '../styled/side-panel/styled'
import { ActionIconStyled } from '../styled/table-controls/styled'

export type SidePanelTitleProps = {
    title: string
    onCloseClick: MouseEventHandler<HTMLElement>
}
export const SidePanelTitle = ({ title, onCloseClick }: SidePanelTitleProps) => {
    return (
        <ComponentTitleWrapperStyled isSidePanel>
            <TableSidePanelTitleStyled>{title}</TableSidePanelTitleStyled>
            <ActionIconStyled active isMobile={false}>
                <ElementWithTooltip
                    element={<i role={'button'} onClick={onCloseClick} className={'fa-solid fa-xmark me-3'} />}
                    tooltip={<span>Close</span>}
                />
            </ActionIconStyled>
        </ComponentTitleWrapperStyled>
    )
}
