import styled, { ThemeProps } from 'styled-components'
import Icon from '../common/maintain-table/bootstrap/Icon'

interface ExpandableListItemWrapperProperties {
    isMobile: boolean
    depth: number
}

export const ExpandableListItemWrapperStyled = styled.div.attrs(
    (props: ExpandableListItemWrapperProperties) => ({})
)<ExpandableListItemWrapperProperties>`
    padding-left: ${(props) => props.depth * 20}px;
`

export const ChildItemWrapperStyled = styled.div.attrs((props) => ({}))``

export const ChildItemStyled = styled.div.attrs((props) => ({}))``

export const ItemLabelStyled = styled.div.attrs((props) => ({}))`
    padding: 10px;
`

interface ChevronIconProperties extends ThemeProps<any> {
    isOpen: boolean
}

export const ChevronIconStyled = styled(Icon).attrs((props: ChevronIconProperties) => ({
    icon: props.isOpen ? 'fa fa-chevron-down' : 'fa fa-chevron-right',
}))<ChevronIconProperties>`
    color: ${(props) => props.theme.navigation.dropdownMenu.color};
    float: right;
`
