import styled from 'styled-components'
import { HEADER_HEIGHT, LOGIN_IMAGE_HEIGHT, TANDC_FOOTER_HEIGHT } from '../../../providers/theme/GuslThemeProvider'

export const NotFoundStyled = styled.h5.attrs(() => ({}))`
    padding: 0 10px 20px 10px;
`

interface LegalHeaderWrapperProperties {
    width: number
}

export const LegalHeaderWrapperStyled = styled.div.attrs((props: LegalHeaderWrapperProperties) => ({}))<LegalHeaderWrapperProperties>`
    display: inline-block;
    color: ${(props) => props.theme.table.titleColor};
    font-size: ${(props) => props.theme.table.titleFontSize};
    background-color: ${(props) => props.theme.table.titleBgColor};
    width: ${(props) => props.width}px;
    height: 38px;
    border: none;
`

interface HeaderProperties {
    width: number
    hasOnClose: boolean
}

export const HeaderStyled = styled.div.attrs((props: HeaderProperties) => ({}))<HeaderProperties>`
    ${(props) => !props.hasOnClose && 'margin: 5px 15px 5px 10px;'}
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: ${(props) => props.width - 70}px;
    position: relative;
    top: 2px;
`

export const PolicyTitleStyled = styled.div.attrs((props) => ({
    role: 'toolbar',
    className: 'row g-0 d-flex align-items-center justify-content-between ',
}))`
    display: inline !important;
    background-color: ${(props) => props.theme.table.titleBgColor};
    padding: ${(props) => props.theme.table.titlePadding};
    color: ${(props) => props.theme.table.titleColor};
    font-size: ${(props) => props.theme.table.titleFontSize};
`

interface LegalPolicyProperties {
    isMobile: boolean
    noFooter: boolean | undefined
    internal: boolean
    footerHeight: number
}

export const LegalPolicyStyled = styled.div.attrs((props: PolicyProperties) => ({}))<LegalPolicyProperties>`
    height: ${(props) => {
        if (props.isMobile && props.internal) {
            return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (HEADER_HEIGHT + TANDC_FOOTER_HEIGHT) + 'px)'
        }
        if (props.isMobile) {
            if (props.noFooter) {
                return 'height: var(--internal-height)'
            } else {
                return (
                    'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
                    (HEADER_HEIGHT + props.footerHeight + (props.noFooter ? LOGIN_IMAGE_HEIGHT : 0)) +
                    'px)'
                )
            }
        }
        return 'inherit'
    }};
    padding: 0 10px 20px 10px;
    background-color: ${(props) => props.theme.colors.dark[0]};

    color: ${(props) => props.theme.table.columnColor};
    font-size: 12px;

    overflow: auto;

    div {
        margin-top: 20px;
    }

    p {
        line-height: 1.5;
        font-size: 14px;
    }

    a {
        padding: 20px;
        text-decoration: underline;
    }

    ul {
        margin-left: 10px;
        list-style: disc;
    }

    li {
        margin-left: 40px;
        line-height: 1.5;
        font-size: 14px;
    }

    h1 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 18px;
    }

    h2 {
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 16px;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: ${(props) => props.theme.table.columnColor};
    }

    strong {
        font-size: 16px;
        font-weight: bold;
    }
`

interface PolicyProperties {
    startPos: number
    footerHeight: number
}

export const PolicyStyled = styled.div.attrs((props: PolicyProperties) => ({}))<PolicyProperties>`
    padding: 0 10px 20px 10px;
    background-color: ${(props) => props.theme.colors.dark[0]};

    color: ${(props) => props.theme.table.columnColor};
    font-size: 12px;

    ${(props) =>
        'height: calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.startPos + 20 + props.footerHeight) + 'px);'};
    overflow: auto;

    div {
        margin-top: 20px;
    }

    p {
        line-height: 1.5;
        font-size: 14px;
    }

    a {
        padding: 20px;
        text-decoration: underline;
    }

    ul {
        margin-left: 10px;
        list-style: disc;
    }

    li {
        margin-left: 40px;
        line-height: 1.5;
        font-size: 14px;
    }

    h1 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 18px;
    }

    h2 {
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 16px;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: ${(props) => props.theme.table.columnColor};
    }

    strong {
        font-size: 16px;
        font-weight: bold;
    }
`
