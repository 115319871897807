import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import React, { useEffect, useRef, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { log } from '../../../services/LogService'
import ErrorMsg from '../../ui/ErrorMsg'
import { ChartProps } from '../types'
import prepareChartData from '../utils/PrepareChartData'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export function BarChart({ endpoint, serverData, chartFor }: ChartProps) {
    const [className] = useState('BarChart-' + new Date().getTime())
    const chartRef = useRef<ChartJS>(null)
    const [chartData, setChartData] = useState({ datasets: [], labels: [] })
    const _options = serverData?.chartOptions?.bar
    const [options, setOptions] = useState(_options)
    const _height = serverData?.chartOptions?.bar?.chart?.canvas.height
    const [height, setHeight] = useState<string | number | undefined>(_height)

    try {
        useEffect(() => {
            const chart = chartRef.current
            if (!chart) {
                return
            }

            const { datasets, options, labels, canvasHeight } = prepareChartData({
                chartType: 'bar',
                endpoint,
                serverData,
                chart,
                chartFor,
            })
            // @ts-ignore
            setChartData({ labels, datasets })
            setOptions(options)
            setHeight(canvasHeight)
        }, [chartFor, JSON.stringify(serverData)])

        // @ts-ignore
        return <Bar ref={chartRef} data={chartData} options={options} type={'bar'} height={height} />
    } catch (e) {
        log.error(className, 'MSG001', { endpoint })
        return <ErrorMsg />
    }
}
