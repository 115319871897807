import React, { MouseEventHandler } from 'react'

type Props = {
    icon?: string
    label?: string
    className?: string
    onClick?: MouseEventHandler<HTMLElement> | undefined
    extraClass?: string /// alias for className
}
/*..???.. to fontawesome*/
// export const iconMap: { [index: string]: string } = {
//     'pi pi-fw pi-eye': 'fa fa-eye',
//     'fa fa-eye': 'fa fa-eye',
//     'fa-solid fa-eye': 'fa-solid fa-eye',
//     'fa-solid fa-pencil': 'fa-solid fa-pencil',
//     'fa-solid fa-lock': 'fa-solid fa-lock',
//     'pi pi-fw pi-pencil': 'fa fa-pencil',
//     'pi pi-fw pi-refresh': 'fas fa-sync',
//     'pi pi-fw pi-plus': 'fas fa-plus',
//     'fas fa-times': 'fas fa-times',
//     'pi pi-times': 'fas fa-times',
//     'fas fa-ban': 'fas fa-ban',
//     'fas fa-check-circle': 'fas fa-check-circle',
//     'fas fa-check': 'fas fa-check',
//     'pi pi-check': 'pi pi-check',
//     'fas fa-angle-double-down': 'fas fa-angle-double-down',
//     'pi pi-plus': 'fas fa-angle-double-down',
//     'pi pi-minus': 'fas fa-angle-double-up',
//     'fas fa-angle-double-up': 'fas fa-angle-double-up',
//     'pi pi-arrow-circle-right': 'fas fa-lock',
//     'fas fa-caret-down': 'fas fa-caret-down',
//     'fas fa-caret-up': 'fas fa-caret-up',
//     'fa-solid fa-print':'fa-solid fa-print',
//     'fa-solid fa-gear':'fa-solid fa-gear',
//     'fa-solid fa-user':'fa-solid fa-user',
//     'fa-solid fa-users':'fa-solid fa-users',
//     'fa-solid fa-users-between-lines':'fa-solid fa-users-between-lines',
//     'fa-solid fa-power-off':'fa-solid fa-power-off',
//     'fa-solid fa-bars':'fa-solid fa-bars',
//     'fa-solid fa-xmark':'fa-solid fa-xmark',
//     'fa-solid fa-download':'fa-solid fa-download',
//     'fa-solid fa-trash-can':'fa-solid fa-trash-can',
//     'fa-solid fa-message':'fa-solid fa-message',
//     'fa-solid fa-caret-down':'fa-solid fa-caret-down',
//     'fa-solid fa-envelope':'fa-solid fa-envelope'
//
// }
// d-flex
const Icon = ({ icon, label, className, onClick, extraClass }: Props): React.ReactElement => {
    if (icon) {
        const _icon = icon ? <i role={'button'} className={extraClass + ' ' + className + ' ' + icon} onClick={onClick} /> : <></>
        return typeof label !== 'undefined' && icon ? (
            <span className={' align-items-center'}>
                {_icon} <span className={'ps-3'}>{label}</span>
            </span>
        ) : (
            _icon
        )
    }
    return (
        <span className={'align-items-center'}>
            <span className={'ps-3'}>{label}</span>
        </span>
    )
    // const _icon = icon ? <i role={'button'} className={className + ' ' + (icon)} onClick={onClick}/> : <></>;
    // return (
    //     (typeof label !== "undefined" && icon) ? <div className={'d-flex  align-items-center'}>
    //         {_icon} <span className={'ps-3'}>{label}</span></div> : _icon
    // )
}
export default Icon

// export default function Icon({icon,label, className, onClick}: Props) {
//
//     const [classNameX] = useState('Icon-'+new Date().getTime());
//     //log.info(classNameX,'MSG001',icon)
//
//     const _icon = <i role={'button'} className={className + ' ' + (icon)} onClick={onClick}/>;
//     return (
//        (typeof label !== "undefined" && icon) ? <div className={'d-flex  align-items-center'} >
//            {_icon} <span className={'ps-3'}>{label}</span></div> : _icon
//     )
// }
