import React, { useEffect, useState } from 'react'
import { isDefined } from '../../../utils/TypeCheckers'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { GuslScrollState, setScrollLeftPosition } from './scrollSlice'
import { GuslTableQueryParamState } from './queryParamsSlice'

interface ScrollManagerDO {
    code: string
    isIndividual: boolean
}

// MK 30/08/2023 handling scroll event here, instead of ListView
export default function ScrollManager({ code, isIndividual }: ScrollManagerDO) {
    const [className] = useState('ScrollManager-' + new Date().getTime())
    const dispatch = useAppDispatch()
    const scrollState: GuslScrollState = useAppSelector((state) => state.scrollSlice[code])
    const scrollLeftPosition = scrollState?.scrollLeftPosition
    const queryParamsState: GuslTableQueryParamState = useAppSelector((state) => state.queryParamsSlice[code])
    const queryParams = queryParamsState?.queryParams
    const topFiltersId: string = (isIndividual ? 'ind_' : '') + 'top_filters_id_' + code
    const wrapper = document.getElementById(topFiltersId)
    const element: HTMLElement | null = document.getElementById('table_wrapper_' + code)
    const [scrollLeft, setScrollLeft] = useState<number>(0)

    useEffect(() => {
        if (scrollLeftPosition && element) {
            setScrollLeft(scrollLeftPosition)
            element.scrollLeft = scrollLeftPosition
        }

        const handleScroll = (event: Event) => {
            const target = event.target as HTMLElement
            setScrollLeft(target.scrollLeft)
        }

        if (element) {
            element.addEventListener('scroll', handleScroll)

            return () => {
                if (scrollLeftPosition) {
                    setScrollLeft(scrollLeft)
                    dispatch(setScrollLeftPosition({ code, scrollLeftPosition: 0 }))
                }
                element.removeEventListener('scroll', handleScroll)
            }
        }
    }, [element?.getBoundingClientRect()])

    // MK 12/08/2023 to imitate main list scroll...
    useEffect(() => {
        if (isDefined(wrapper)) {
            wrapper!.scrollTo({
                left: scrollLeft || 0,
            })
        }
    }, [scrollLeft, scrollLeftPosition])

    // MK 29/08/2023 on queryParams change we will re-scroll to previous scroll position
    useEffect(() => {
        if (element) {
            element!.scrollTo({
                left: scrollLeft || 0,
            })
        }
    }, [queryParams, element])
    return null
}
