import React, { useState } from 'react'
import { Subscription } from 'rxjs'
import { EnvironmentContext } from '../../providers/environment/EnvironmentContext'
import { environmentService } from '../../providers/environment/EnvironmentService'
import { SessionContext } from '../../providers/session/SessionContext'
import { CohortDTO, UserCohortsDTO } from '../../providers/session/types'
import { log } from '../../services/LogService'
import { RunOnceEffect, unSubscribe } from '../../utils/Utils'
import { PreOrderDTO } from '../common/buy-sell-form/types'
import { LookupColumnStyled, LookupTableBodyStyled, LookupTableRowStyled, LookupTableStyled } from '../common/lookup/styled'
import { TableContentStyled } from '../common/maintain-table/styled'
import { OptionComboStyled, OptionTableContainerStyled } from '../common/option/styled'
import { CohortContainerStyled, CohortLookupStyled, CohortSelectorContainerStyled, CohortTableResponsiveStyled } from './styled'

interface CohortProperties {
    userCohorts: UserCohortsDTO
}

export const CohortSelector = (properties: CohortProperties): React.ReactElement<CohortProperties> => {
    const [className] = useState('CohortSelector-' + new Date().getTime())
    const sessionContext = React.useContext(SessionContext)
    const environmentContext = React.useContext(EnvironmentContext)

    const [selectorOpen, setSelectorOpen] = useState<boolean>(false)
    const isMobile = environmentContext.isMobileDevice()

    const [allowedCohorts] = useState<CohortDTO[]>(properties?.userCohorts?.allowedCohorts || [])
    const [footerHeight, setFooterHeight] = useState<number>(0)
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription)
        }
    })

    const onCohortChange = (cohort: CohortDTO) => {
        if (cohort && cohort.id !== properties?.userCohorts?.currentCohort?.logo) {
            sessionContext.post<any, PreOrderDTO>('/cohort/change', { cohortId: cohort.id }).then(
                (response) => {
                    // @ts-ignore
                    // window.location = '/'; // <-- go to home
                    window.location.reload()
                },
                (reason) => {
                    log.error(className, 'ERR002', reason)
                }
            )
        }
        setSelectorOpen(false)
    }

    const renderCohortItem = (cohort: CohortDTO, idx: number) => {
        return (
            <LookupTableRowStyled
                role={'button'}
                key={'id_' + idx}
                id={'id_' + idx}
                active={false}
                lookupField={true}
                onClick={() => onCohortChange(cohort)}
            >
                <LookupColumnStyled textAlign={'left'} key={'hdr_row_' + idx}>
                    {cohort.name}
                </LookupColumnStyled>
            </LookupTableRowStyled>
        )
    }

    const renderCohortList = () => {
        return <>{allowedCohorts.length > 0 && allowedCohorts.map((cohort, idx) => renderCohortItem(cohort, idx))}</>
    }

    const renderCohortLookup = (): React.ReactElement => {
        return (
            <CohortLookupStyled>
                <OptionTableContainerStyled>
                    <TableContentStyled footerHeight={footerHeight}>
                        <CohortTableResponsiveStyled>
                            <LookupTableStyled>
                                <LookupTableBodyStyled id={'lk_cohorts'} isOverFlown={false}>
                                    {renderCohortList()}
                                </LookupTableBodyStyled>
                            </LookupTableStyled>
                        </CohortTableResponsiveStyled>
                    </TableContentStyled>
                </OptionTableContainerStyled>
            </CohortLookupStyled>
        )
    }

    const renderCohort = (): React.ReactElement => {
        return (
            <>
                <CohortContainerStyled isMobile={isMobile} onClick={() => setSelectorOpen(!selectorOpen)}>
                    <OptionComboStyled>
                        <i className={'fa-solid fa-caret-down me-1'}></i> {properties?.userCohorts?.currentCohort?.name || ''}
                    </OptionComboStyled>
                    {selectorOpen && renderCohortLookup()}
                </CohortContainerStyled>
            </>
        )
    }

    return <>{properties?.userCohorts?.currentCohort && <CohortSelectorContainerStyled>{renderCohort()}</CohortSelectorContainerStyled>}</>
}
