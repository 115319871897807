import React from 'react'
import { InfoIconStyled, InfoMessageStyled, InfoTitleStyled, MessageWrapperStyled } from './styled'

export const SuccessMessage: React.FC<{ message: string }> = ({ message }) => {
    return (
        <MessageWrapperStyled level={'success'}>
            <InfoIconStyled icon={'fa-solid fa-check'} level={'success'} />
            <InfoTitleStyled>Well done...</InfoTitleStyled>
            <InfoMessageStyled>{message}</InfoMessageStyled>
        </MessageWrapperStyled>
    )
}
export const NoRowsMessage: React.FC = () => {
    return (
        <MessageWrapperStyled level={'noRows'}>
            <InfoIconStyled icon={'fa-solid fa-exclamation'} level={'noRows'} />
            <InfoTitleStyled>No rows to update....</InfoTitleStyled>
            <InfoMessageStyled>Make sure that you have updated at least one field in one row.</InfoMessageStyled>
        </MessageWrapperStyled>
    )
}
export const ErrorMessage: React.FC<{ message: string }> = ({ message }) => {
    return (
        <MessageWrapperStyled level={'error'}>
            <InfoIconStyled icon={'fa-solid fa-xmark'} level={'error'} />
            <InfoTitleStyled>Oooops...</InfoTitleStyled>
            <InfoMessageStyled>{message}</InfoMessageStyled>
        </MessageWrapperStyled>
    )
}

export const InitialMessage: React.FC = () => {
    return (
        <MessageWrapperStyled level={'initial'}>
            <InfoIconStyled icon={'fa-solid fa-info'} level={'initial'} />
            <InfoTitleStyled>How to modify table data.</InfoTitleStyled>
            <InfoMessageStyled>
                <ul className={'list-unordered'}>
                    <li>Click on a fields you want to update.</li>
                    <li>Change the values.</li>
                    <li>Click this button to save changes.</li>
                </ul>
                <hr />
                <p className="xs-small">Field values must not be left empty and should differ from their initial values.</p>
            </InfoMessageStyled>
        </MessageWrapperStyled>
    )
}

export const LoadingMessage: React.FC = () => {
    return (
        <MessageWrapperStyled level={'loading'}>
            <InfoIconStyled icon={'fa-solid fa-xmark'} level={'loading'} />
            <InfoTitleStyled>Working on it...</InfoTitleStyled>
            <InfoMessageStyled>Won't take long...;-)</InfoMessageStyled>
        </MessageWrapperStyled>
    )
}

export interface SaveRowsInfoMessageDO {
    level: 'initial' | 'loading' | 'success' | 'noRows' | 'error'
    message?: string | undefined
}

export default function SaveRowsInfoMessage({ level, message }: SaveRowsInfoMessageDO) {
    const messageMap: {
        [level in SaveRowsInfoMessageDO['level']]: React.JSX.Element
    } = {
        initial: <InitialMessage />,
        loading: <LoadingMessage />,
        success: message ? <SuccessMessage message={message} /> : <div>Update successful</div>,
        noRows: <NoRowsMessage />,
        error: message ? <ErrorMessage message={message as string} /> : <div>Something went wrong!</div>,
    }

    return messageMap[level]
}
