import React, { useContext, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { BlastContext } from '../../../providers/blast/BlastContext'
import { EnvironmentContext } from '../../../providers/environment/EnvironmentContext'
import { SessionContext } from '../../../providers/session/SessionContext'
import { getSettings, performValidation } from '../../../services/ValidationService'
import { getStyle } from '../../../utils/CssUtils'
import { isDefined } from '../../../utils/TypeCheckers'
import {
    assignReferences,
    canColorise,
    canColoriseText,
    noop,
    replaceSpaceWithDash,
    RunOnceEffect,
    unSubscribe,
} from '../../../utils/Utils'
import { FieldPositionProperties, FieldProperties, FormMode } from '../../types'
import { handleResize, handleWindowChange, initFieldPositionProperties, watchBlastDeltas } from '../../ui/FieldUtils'
import { TableContentStyled } from '../date-summary/styled'
import { ElementWithTooltip } from '../element-with/element-with-tooltip/ElementWithTooltip'
import FieldLabel from '../field-label/FieldLabel'
import {
    ControlContainerStyled,
    DownArrowStyled,
    DownArrowSvgPathStyled,
    DownArrowSvgStyled,
    IndicatorsContainerStyled,
    LookupColumnStyled,
    LookupTableBodyStyled,
    LookupTableResponsiveStyled,
    LookupTableRowStyled,
    LookupTableStyled,
    MatchesStyled,
    SeparatorStyled,
} from '../lookup/styled'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { FieldContentStyled, FieldContentWrapperStyled, FieldErrorStyled, FloatingFormStyled, HintStyled } from '../text/styled'
import { toggleOptionFieldOpen } from './optionFieldSlice'
import {
    OptionComboStyled,
    OptionContainerStyled,
    OptionTableContainerStyled,
    OptionTableFieldStyled,
    OptionTableValueStyled,
    OptionViewStyled,
} from './styled'

export interface Option {
    readonly label: string
    readonly value: string
}

export const OptionField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'OptionField-' + new Date().getTime())

    const blastContext = React.useContext(BlastContext)
    const sessionContext = useContext(SessionContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const dispatch = useAppDispatch()

    const [refreshCounter, setRefreshCounter] = useState<number>(1)

    const [dropdownItems] = useState<Option[]>(() => {
        const options: Option[] = []
        if (properties.fieldConfig.lookupCollection) {
            blastContext.getCollection(properties.fieldConfig.lookupCollection).forEach((item) => {
                options.push({ value: item.id, label: item.name })
            })
        } else {
            ;(properties.fieldConfig.options || []).forEach((option) => {
                options.push({ value: option, label: option })
            })
        }
        return options
    })

    const [formMode, setFormMode] = useState(properties.formMode)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [submitted, setSubmitted] = useState(false)

    const [statusClassName, setStatusClassName] = useState<string>('')
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const lookupElement = useRef(null)
    const [oldResize, setOldResize] = useState<number | undefined>(undefined)
    const [fieldPositionProperties, setFieldPositionProperties] = useState<FieldPositionProperties>(() =>
        initFieldPositionProperties(environmentContext, properties)
    )

    RunOnceEffect(() => {
        const subscriptionResize = handleResize(
            environmentContext,
            lookupElement,
            oldResize,
            fieldPositionProperties,
            setFieldPositionProperties,
            setOldResize
        )
        return () => {
            unSubscribe(subscriptionResize)
        }
    })

    useEffect(() => {
        handleWindowChange(lookupElement, fieldPositionProperties, setFieldPositionProperties)
    }, [formMode])

    useEffect(() => {
        const [loaderSubscription, blastDeltaSubscription, valueObservable] = watchBlastDeltas(
            sessionContext,
            blastContext,
            properties,
            formMode
        )
        const valueSubscription = valueObservable.subscribe((newValue: any) => {
            // MK 18/08/2023
            if (formMode !== FormMode.NEW) {
                setFormValue(loadInitialValue(newValue))
                setRefreshCounter(refreshCounter + 1)
            }
        })
        return () => {
            unSubscribe(loaderSubscription)
            unSubscribe(blastDeltaSubscription)
            unSubscribe(valueSubscription)
        }
    }, [properties])

    const updateStatusClass = (option: Option): void => {
        let extraClass = ''
        if (canColorise(properties.fieldConfig)) {
            extraClass +=
                ' ' +
                replaceSpaceWithDash(properties.fieldConfig.name.toLowerCase()) +
                '-badge status-' +
                replaceSpaceWithDash(option?.value?.toLowerCase()) +
                ' '
        }
        if (canColoriseText(properties.fieldConfig)) {
            extraClass +=
                ' ' +
                replaceSpaceWithDash(properties.fieldConfig.name.toLowerCase()) +
                '-text-' +
                replaceSpaceWithDash(option?.value?.toLowerCase()) +
                ' '
        }
        setStatusClassName(extraClass)
    }

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        // GW commented out Edit was displaying old value
        // const initialValue: Option = loadInitialValue(properties?.data);
        // setFormValue(initialValue)
        // properties.onChange(properties.fieldConfig.name, initialValue.value);
        // updateStatusClass(initialValue)
    }

    const loadInitialValue = (data?: string | undefined): Option => {
        let returnItem: Option = { label: '', value: '' }
        if (isDefined(data)) {
            dropdownItems.forEach((item) => {
                if (data === item.value) {
                    returnItem = item
                }
            })
        } else if (dropdownItems && dropdownItems.length > 0) {
            returnItem = dropdownItems[0]
        }
        return returnItem
    }

    const [tableValue, setTableValue] = useState<Option>({
        label: properties?.data || '',
        value: properties?.data || '',
    })

    const [formValue, setFormValue] = useState<Option>(() => {
        if (FormMode.VIEW === formMode) {
            return { label: properties.data, value: properties.data }
        } else {
            return _guslFormState?.getFieldValue(properties)
        }
    })

    useEffect(() => {
        const initialValue: Option = loadInitialValue(properties?.data)
        if (initialValue) {
            // gbw 03-07-24 - simple form init value not loaded as a form value
            // if (formMode !== FormMode.NEW) {
            setFormValue(initialValue)
            properties.onChange(properties.fieldConfig.name, initialValue.value)
            updateStatusClass(initialValue)
            // }
        }
        setTableValue(initialValue)
        // setRefreshCounter(refreshCounter + 1)
    }, [properties.formMode, properties.data])

    const doValidation = (fieldValue: any): boolean => {
        return performValidation(formMode, properties.menuItem?.code, properties.fieldConfig, fieldValue, setSubmitted, setErrorMessage)
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.fieldConfig.tableCss, '')
        return (
            <OptionTableFieldStyled
                textAlign={properties.fieldConfig.align}
                style={style}
                key={'op_fld_' + properties.fieldConfig.name + '_' + refreshCounter}
            >
                <OptionTableValueStyled className={statusClassName} key={'opt_' + properties.fieldConfig.name}>
                    {tableValue?.label || ''}
                </OptionTableValueStyled>
            </OptionTableFieldStyled>
        )
    }

    const showLookupDataHandler = (): void => {
        setIsOpen(!isOpen)
        setErrorMessage('')
    }

    const renderDownArrow = (): React.ReactElement => {
        return (
            <ElementWithTooltip
                element={
                    <IndicatorsContainerStyled isActive={isOpen} onClick={showLookupDataHandler}>
                        <SeparatorStyled />
                        <DownArrowStyled>
                            <DownArrowSvgStyled>
                                <DownArrowSvgPathStyled />
                            </DownArrowSvgStyled>
                        </DownArrowStyled>
                    </IndicatorsContainerStyled>
                }
                tooltip={<span>{isOpen ? 'Collapse' : 'Expand'}</span>}
            />
        )
    }

    const renderOption = (option: Option, idx: number) => {
        const optionSelected = (option: Option) => {
            dispatch(toggleOptionFieldOpen({ id: undefined }))
            setFormValue(option)
            properties.onChange(properties.fieldConfig.name, option.value)
            setTableValue(option)
            updateStatusClass(option)
            setIsOpen(false)
        }

        return (
            <LookupTableRowStyled
                role={'button'}
                key={'id_' + idx}
                id={'id_' + idx}
                active={false}
                lookupField={true}
                onClick={() => optionSelected(option)}
            >
                <LookupColumnStyled key={'hdr_row_' + idx}>{option.label}</LookupColumnStyled>
            </LookupTableRowStyled>
        )
    }

    const renderOptions = () => {
        return <>{dropdownItems && dropdownItems.map((option, idx) => renderOption(option, idx))}</>
    }

    const renderComboBoxOptions = (): React.ReactElement => {
        return (
            <MatchesStyled>
                <OptionTableContainerStyled>
                    <TableContentStyled>
                        <LookupTableResponsiveStyled>
                            <LookupTableStyled>
                                <LookupTableBodyStyled id={'lk_' + properties?.fieldConfig?.name} isOverFlown={false}>
                                    {renderOptions()}
                                </LookupTableBodyStyled>
                            </LookupTableStyled>
                        </LookupTableResponsiveStyled>
                    </TableContentStyled>
                </OptionTableContainerStyled>
            </MatchesStyled>
        )
    }

    const renderComboBox = (): React.ReactElement => {
        return (
            <>
                <ControlContainerStyled onClick={showLookupDataHandler} {...fieldPositionProperties}>
                    <OptionComboStyled>{renderTableView()}</OptionComboStyled>
                    {renderDownArrow()}
                </ControlContainerStyled>
                {isOpen && renderComboBoxOptions()}
            </>
        )
    }

    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        if (hideField) {
            return <></>
        }

        if (formMode === FormMode.VIEW || disableField) {
            return (
                <>
                    <FloatingFormStyled>
                        <FieldContentWrapperStyled>
                            <FieldContentStyled>
                                <OptionViewStyled>{renderTableView()}</OptionViewStyled>
                            </FieldContentStyled>
                            {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                        </FieldContentWrapperStyled>
                        <FieldLabel properties={properties} />
                    </FloatingFormStyled>
                </>
            )
        }

        return (
            <FloatingFormStyled ref={lookupElement}>
                <FieldContentWrapperStyled {...fieldPositionProperties}>
                    <FieldContentStyled>
                        <OptionContainerStyled submitted={submitted} noValue={!formValue}>
                            {renderComboBox()}
                        </OptionContainerStyled>
                    </FieldContentStyled>
                    {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                    <FieldErrorStyled submitted={submitted} errorMessage={errorMessage}>
                        {errorMessage}
                    </FieldErrorStyled>
                </FieldContentWrapperStyled>
                <FieldLabel properties={properties} />
            </FloatingFormStyled>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
