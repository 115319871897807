import { PathDetails } from './path-details'
import { PathParameters } from './path-parameters'

export class GraphRequest {
    _correlationId: number | undefined
    _onFulFilled: Function | undefined
    _onError: Function | undefined
    _requestTime: number | undefined

    _command: string
    _key: string | undefined
    _data: any
    _pathParameters: PathParameters | undefined
    _collection: any
    _collectionList: any[]
    _attachmentId: string | undefined

    constructor(command: string, key?: string, data?: any, pathParameters?: PathParameters, attachmentId?: string) {
        if (!key) {
            PathDetails.splitPath(key || '')
        }

        this._command = command
        this._key = key
        this._data = data
        this._pathParameters = pathParameters
        this._attachmentId = attachmentId
        this._collectionList = []
    }

    setCommand(command: string) {
        this._command = command
    }

    setKey(key: string) {
        this._key = key
    }

    setParameters(pathParameters: PathParameters) {
        this._pathParameters = pathParameters
    }

    setData(data: any) {
        this._data = data
    }

    setCollection(collection: any) {
        this._collection = collection
    }

    setCollectionList(collectionList: any) {
        this._collectionList = collectionList
    }

    setCorrelationInfo(correlationId: number, onFulFilled: Function, onError: Function) {
        this._correlationId = correlationId
        this._onFulFilled = onFulFilled
        this._onError = onError
        this._requestTime = new Date().getTime()
    }

    getMessage(): any {
        const message: any = {
            cmd: this._command,
            correlationId: this._correlationId,
        }

        if (this._key) {
            message['key'] = this._key
        }
        if (this._pathParameters) {
            message['parameters'] = this._pathParameters.getParameters()
        }
        if (this._attachmentId) {
            message['attachmentId'] = this._attachmentId
        }
        if (this._data) {
            message['data'] = this._data
        }
        return message
    }
}
