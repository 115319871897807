import { SessionContext } from './SessionContext'

export type Props = {
    sessionContext: typeof SessionContext
    endpoint: string
    queryParams: any
    controller?: AbortController
}
export default function MGMutation({ sessionContext, endpoint, queryParams, controller }: Props) {
    return new Promise<any[]>((resolve, reject) => {
        // @ts-ignore
        sessionContext.post<ModulesRequestsDTO, ModulesRequestsDTO>(endpoint, queryParams, controller).then(
            (response: { status: number; data: { content: any } }) => {
                // @ts-ignore
                resolve(response || [])
                return
            },
            (reason: any) => {
                reject(reason)
                return
            }
        )
    })
}
