import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select/base'
import { useAppSelector } from '../../../app/hooks'
import { BlastContext } from '../../../providers/blast/BlastContext'
import { EnvironmentContext } from '../../../providers/environment/EnvironmentContext'
import { BaseTheme, GuslThemeContext } from '../../../providers/theme/GuslThemeProvider'
import { log } from '../../../services/LogService'
import { getSettings, performValidation } from '../../../services/ValidationService'
import { isDefined } from '../../../utils/TypeCheckers'
import { assignReferences, canColorise, canColoriseText, noop, RunOnceEffect } from '../../../utils/Utils'
import { FieldProperties, FormMode } from '../../types'
import FieldLabel from '../field-label/FieldLabel'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { OptionContainerStyled, OptionViewStyled, SelectWrapperStyled } from '../option/styled'
import { OptionFieldStyles } from '../option/types'
import { FloatingFormStyled } from '../text/styled'

export interface Option {
    readonly label: string
    readonly value: string
}

export const BuySellField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'OptionField-' + new Date().getTime())

    const blastContext = React.useContext(BlastContext)
    const guslThemeContext = React.useContext(GuslThemeContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const [themeColors, setThemeColors] = useState<BaseTheme>(guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()))

    const valueRef = useRef(properties?.data)
    const [formMode, setFormMode] = useState(properties.formMode)
    const [menuOpen, setMenuOpen] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [submitted, setSubmitted] = useState(false)
    const [dropdownItems] = useState<Option[]>(() => {
        const options: Option[] = []
        if (properties.fieldConfig.lookupCollection) {
            blastContext.getCollection(properties.fieldConfig.lookupCollection).forEach((item) => {
                options.push({ value: item.id, label: item.name })
            })
        } else {
            ;(properties.fieldConfig.options || []).forEach((option) => {
                options.push({ value: option, label: option })
            })
        }
        return options
    })

    const [colorise] = useState<boolean>(canColorise(properties.fieldConfig))
    const [coloriseText] = useState<boolean>(canColoriseText(properties.fieldConfig))

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        const initialValue = loadInitialValue()
        setFormValue(initialValue)
        properties.onChange(properties.fieldConfig.name, initialValue.value)
    }

    const loadInitialValue = (): Option => {
        let returnItem: Option = { label: '', value: '' }
        if (isDefined(properties?.data)) {
            dropdownItems.forEach((item) => {
                if (properties?.data === item.value) {
                    returnItem = item
                }
            })
        } else {
            log.info(className, 'MSG003', 'loadInitialValue', dropdownItems[0])
            return dropdownItems[0]
            //return {value: '', label: ''};
        }
        log.info(className, 'MSG003', 'loadInitialValue', returnItem)
        return returnItem
    }

    const [formValue, setFormValue] = useState<Option>(() => {
        return loadInitialValue()
    })

    useEffect(() => {
        const initialValue = loadInitialValue()
        setFormValue(initialValue)
        properties.onChange(properties.fieldConfig.name, initialValue.value)
    }, [properties])

    const doValidation = (fieldValue: any): boolean => {
        log.info(className, 'MSG003', 'doValidation', valueRef.current?.props?.value, formValue)
        return performValidation(formMode, properties.menuItem?.code, properties.fieldConfig, fieldValue, setSubmitted, setErrorMessage)
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        const value = '' + properties.data || ''

        const getClassName = () => {
            let extraClass = ''
            if (colorise) {
                extraClass += 'status-badge status-' + value.toLowerCase()
            }
            if (coloriseText) {
                extraClass += 'status-text-' + value.toLowerCase()
            }
            return extraClass
        }

        return (
            <div className={'pt-2 pe-2  pb-2 d-flex justify-content-start'}>
                <span className={getClassName()}>{value}</span>
            </div>
        )
    }

    const onDataInputChange = (option: Option) => {
        log.info(className, 'MSG003', 'onDataInputChange', valueRef?.current?.value, formValue, option)
        setFormValue(option)
        properties.onChange(properties.fieldConfig.name, option.value)
    }

    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        if (hideField) {
            return <></>
        }

        if (formMode === FormMode.VIEW || disableField) {
            return (
                <>
                    <FloatingFormStyled>
                        <OptionViewStyled>{renderTableView()}</OptionViewStyled>
                        <FieldLabel properties={properties} />
                    </FloatingFormStyled>
                </>
            )
        }

        return (
            <FloatingFormStyled>
                <OptionContainerStyled submitted={submitted} noValue={!formValue}>
                    <SelectWrapperStyled>
                        <Select
                            options={[...dropdownItems]}
                            ref={valueRef}
                            placeholder={'Select...'}
                            /*@ts-ignore*/
                            styles={OptionFieldStyles}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    text: themeColors.colors.light,
                                    primary25: themeColors.colors.dark[1],
                                    primary: themeColors.colors.dark[0],
                                },
                            })}
                            menuIsOpen={menuOpen}
                            inputValue={''}
                            /*@ts-ignore*/
                            onChange={(option) => {
                                onDataInputChange(option as Option)
                            }}
                            onInputChange={(e) => {}}
                            onMenuOpen={() => {
                                setMenuOpen(true)
                            }}
                            onMenuClose={() => {
                                setMenuOpen(false)
                            }}
                            value={formValue}
                        />
                    </SelectWrapperStyled>
                </OptionContainerStyled>
                <FieldLabel properties={properties} />
                {submitted && errorMessage && <small className="invalid">{errorMessage}</small>}
            </FloatingFormStyled>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
