// @ts-nocheck
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TableRowState } from './tableRowSlice'

export interface RowExpanderToggleAction {
    id: string
    state: TableRowState
}

interface RowExpanderState {
    [id: string]: RowExpanderActionState
}

export interface RowExpanderActionState {
    opened: boolean
    state: TableRowState
}

const initialState: RowExpanderState = {
    // showInline: false,
    // activeItem: undefined
}

export const rowExpanderSlice = createSlice({
    name: 'rowExpanderSlice',
    initialState,
    reducers: {
        toggleRowExpander(state, action: PayloadAction<RowExpanderToggleAction>) {
            let entry: RowExpanderActionState = state[action.payload.id]
            if (!entry) {
                entry = {
                    opened: false,
                    state: action.payload.state,
                }
            }
            entry.opened = !entry.opened
            state[action.payload.id] = entry
        },
    },
})

export const { toggleRowExpander } = rowExpanderSlice.actions

export default rowExpanderSlice.reducer
