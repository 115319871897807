import styled, { ThemeProps } from 'styled-components'

export const RadioButtonContainerStyled = styled.div.attrs((props) => ({}))`
    padding-top: 5px;

    //:last-child {
    //  padding-top: 10px;
    //}

    display: flex;
`

interface RadioButtonProperties extends ThemeProps<any> {
    active: boolean
}

export const RadioButtonIconStyled = styled.i.attrs((props: RadioButtonProperties) => ({
    className: props.active ? 'fa-regular fa-circle-dot' : 'fa-regular fa-circle',
}))<RadioButtonProperties>`
    line-height: 24px;
    color: ${(props) => props.theme.table.headerColor} !important;
    font-size: ${(props) => props.theme.table.headerFontSize};
`

export const RadioButtonLabelStyled = styled.div.attrs((props) => ({}))`
    padding-left: 10px;
    width: 97px;
    line-height: 24px;
    color: ${(props) => props.theme.table.headerColor} !important;
    font-size: ${(props) => props.theme.table.headerFontSize};
`
export const AmountContainerStyled = styled.div.attrs((props) => ({}))``

export const ButtonsContainerStyled = styled.div.attrs((props) => ({}))``
export const RadioButtonContentStyled = styled.div.attrs((props) => ({}))``
