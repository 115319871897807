import { BAR_DEFAULT_OPTIONS } from './bar/barDefaultOptions'
import { BUBBLE_DEFAULT_OPTION } from './bubble/bubbleDefaultOptions'
import { DOUGHNUT_DEFAULT_OPTIONS } from './doughnut/doughnutDefaultOptions'
import { GAUGE_DEFAULT } from './gauge/gaugeDefault'
import { LINE_DEFAULT_OPTIONS } from './line/lineDefaultOptions'
import { MULTI_TYPE_DEFAULT_OPTION } from './multi-type/multiTpeDefaultOptions'
import { PIE_DEFAULT_OPTIONS } from './pie/pieDefaultOptions'
import { POLAR_AREA_DEFAULT_OPTIONS } from './polarArea/polarAreaDefaultOptions'
import { RADAR_DEFAULT_OPTIONS } from './radar/radarDefaultOptions'
import { SCATTER_DEFAULT_OPTION } from './scatter/scatterDefaultOptions'

export const DEFAULT_CHART_OPTIONS: { [index: string]: any } = {
    line: LINE_DEFAULT_OPTIONS,
    bar: BAR_DEFAULT_OPTIONS,
    doughnut: DOUGHNUT_DEFAULT_OPTIONS,
    pie: PIE_DEFAULT_OPTIONS,
    polarArea: POLAR_AREA_DEFAULT_OPTIONS,
    gauge: GAUGE_DEFAULT,
    bubble: BUBBLE_DEFAULT_OPTION,
    radar: RADAR_DEFAULT_OPTIONS,
    scatter: SCATTER_DEFAULT_OPTION,
    multiType: MULTI_TYPE_DEFAULT_OPTION,
}
export const CHART_TYPES = Object.keys(DEFAULT_CHART_OPTIONS)
