import { FieldConfigDTO } from '../../types'

const COLORS_FIELD_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'primary',
        label: 'primary',
        type: 'colors',
    },
    {
        name: 'secondary',
        label: 'secondary',
        type: 'colors',
    },
    {
        name: 'background',
        label: 'background',
        type: 'colors',
    },
    {
        name: 'dark',
        label: 'dark',
        type: 'colors',
    },
    {
        name: 'bespoke',
        label: 'bespoke',
        type: 'colors',
    },
    {
        name: 'light',
        label: 'light',
        type: 'color',
    },
    {
        name: 'error',
        label: 'error',
        type: 'color',
    },
    {
        name: 'warning',
        label: 'warning',
        type: 'color',
    },
    {
        name: 'notification',
        label: 'notification',
        type: 'color',
    },
    {
        name: 'accentColor',
        label: 'accentColor',
        type: 'color',
    },
    {
        name: 'accentColorLight',
        label: 'accentColorLight',
        type: 'color',
    },
    {
        name: 'accentColorMuted',
        label: 'accentColorMuted',
        type: 'color',
    },
]

const BODY_FIELD_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'background',
        label: 'Background',
        type: 'color',
    },
]

const SPLASH_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'background',
        label: 'Background',
        type: 'color',
    },
    {
        name: 'color',
        label: 'Colour',
        type: 'color',
    },
    {
        name: 'size',
        label: 'Size',
        type: 'number',
    },
    {
        name: 'left',
        label: 'Left',
        type: 'pixel',
    },
    {
        name: 'top',
        label: 'Top',
        type: 'font_size',
    },
]

const SPINNER_FIELD_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'color',
        label: 'Colour',
        type: 'color',
    },
]

const MODAL_FIELD_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'outerBackground',
        label: 'OuterBackground',
        type: 'color',
    },
    {
        name: 'innerBackground',
        label: 'InnerBackground',
        type: 'color',
    },
]

const LOGIN_FIELD_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'fontColor',
        label: 'fontColor',
        type: 'color',
    },
    {
        name: 'linkColor',
        label: 'linkColor',
        type: 'color',
    },
    {
        name: 'buttonBgColor',
        label: 'buttonBgColor',
        type: 'color',
    },
    {
        name: 'buttonFgColor',
        label: 'buttonFgColor',
        type: 'color',
    },
    {
        name: 'outerBackground',
        label: 'outerBackground',
        type: 'color',
    },
    {
        name: 'innerBackground',
        label: 'innerBackground',
        type: 'color',
    },
]

const TABLE_FIELD_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'panelBgColor',
        label: 'panelBgColor',
        type: 'color',
    },
    {
        name: 'titleColor',
        label: 'titleColor',
        type: 'color',
    },
    {
        name: 'titleBgColor',
        label: 'titleBgColor',
        type: 'color',
    },
    {
        name: 'titlePadding',
        label: 'titlePadding',
        type: 'text',
    },
    {
        name: 'titleFontSize',
        label: 'titleFontSize',
        type: 'font_size',
    },
    {
        name: 'headerColor',
        label: 'headerColor',
        type: 'color',
    },
    {
        name: 'columnColor',
        label: 'columnColor',
        type: 'color',
    },
    {
        name: 'gridBorder',
        label: 'gridBorder',
        type: 'text',
    },
    {
        name: 'selectedColor',
        label: 'selectedColor',
        type: 'color',
    },
    {
        name: 'headerFontSize',
        label: 'headerFontSize',
        type: 'font_size',
    },
    {
        name: 'columnFontSize',
        label: 'columnFontSize',
        type: 'font_size',
    },
    {
        name: 'menuButtonColor',
        label: 'menuButtonColor',
        type: 'color',
    },
    {
        name: 'menuButtonBgColor',
        label: 'menuButtonBgColor',
        type: 'color',
    },
    {
        name: 'menuButtonHoverColor',
        label: 'menuButtonHoverColor',
        type: 'color',
    },
    {
        name: 'menuButtonHoverBgColor',
        label: 'menuButtonHoverBgColor',
        type: 'color',
    },
    {
        name: 'rowHoverBgColor',
        label: 'rowHoverBgColor',
        type: 'color',
    },
    {
        name: 'summaryHeader',
        label: 'summaryHeader',
        type: 'theme_collection',
        innerFields: [
            {
                name: 'bgColor',
                label: 'bgColor',
                type: 'color',
            },
        ],
    },
    {
        name: 'summaryFooter',
        label: 'summaryFooter',
        type: 'theme_collection',
        innerFields: [
            {
                name: 'bgColor',
                label: 'bgColor',
                type: 'color',
            },
        ],
    },
]

const PANEL_FIELD_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'panelBgColor',
        label: 'panelBgColor',
        type: 'color',
    },
    {
        name: 'titleColor',
        label: 'titleColor',
        type: 'color',
    },
    {
        name: 'titleBgColor',
        label: 'titleBgColor',
        type: 'color',
    },
    {
        name: 'titleFontSize',
        label: 'titleFontSize',
        type: 'font_size',
    },
    {
        name: 'subPanel',
        label: 'subPanel',
        type: 'theme_collection',
        innerFields: [
            {
                name: 'titleColor',
                label: 'titleColor',
                type: 'color',
            },
            {
                name: 'titleBgColor',
                label: 'titleBgColor',
                type: 'color',
            },
            {
                name: 'titleFontSize',
                label: 'titleFontSize',
                type: 'font_size',
            },
        ],
    },
]
const FORM_FIELD_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'fieldBorder',
        label: 'fieldBorder',
        type: 'text',
    },
]
const HEADER_PANEL_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'fgColor',
        label: 'fgColor',
        type: 'text',
    },
    {
        name: 'bgColor',
        label: 'fgColor',
        type: 'text',
    },
    {
        name: 'border',
        label: 'border',
        type: 'text',
    },
    {
        name: 'borderRadius',
        label: 'borderRadius',
        type: 'text',
    },
]

const ACTION_FIELD_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'bgColor',
        label: 'bgColor',
        type: 'color',
    },
    {
        name: 'borderColor',
        label: 'borderColor',
        type: 'color',
    },
    {
        name: 'hoverBgColor',
        label: 'hoverBgColor',
        type: 'color',
    },
    {
        name: 'hoverColor',
        label: 'hoverColor',
        type: 'color',
    },
    {
        name: 'hoverBorderColor',
        label: 'hoverBorderColor',
        type: 'color',
    },
    {
        name: 'colors',
        label: 'colors',
        type: 'theme_collection',
        innerFields: [
            {
                name: 'defaultColor',
                label: 'defaultColor',
                type: 'color',
            },
            {
                name: 'warning',
                label: 'warning',
                type: 'color',
            },
            {
                name: 'save',
                label: 'save',
                type: 'color',
            },
            {
                name: 'refresh',
                label: 'refresh',
                type: 'color',
            },
            {
                name: 'newAction',
                label: 'newAction',
                type: 'color',
            },
            {
                name: 'update',
                label: 'update',
                type: 'color',
            },
            {
                name: 'edit',
                label: 'edit',
                type: 'color',
            },
            {
                name: 'cancel',
                label: 'cancel',
                type: 'color',
            },
            {
                name: 'delete',
                label: 'delete',
                type: 'color',
            },
        ],
    },
]
const LOOKUP_FIELD_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'headerColor',
        label: 'headerColor',
        type: 'color',
    },
    {
        name: 'columnColor',
        label: 'columnColor',
        type: 'color',
    },
    {
        name: 'headerFontSize',
        label: 'headerFontSize',
        type: 'font_size',
    },
    {
        name: 'columnFontSize',
        label: 'columnFontSize',
        type: 'font_size',
    },
    {
        name: 'hoverColor',
        label: 'hoverColor',
        type: 'color',
    },
    {
        name: 'hoverBgColor',
        label: 'hoverBgColor',
        type: 'color',
    },
]
const MONEY_FIELD_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'positiveColor',
        label: 'positiveColor',
        type: 'color',
    },
    {
        name: 'negativeColor',
        label: 'negativeColor',
        type: 'color',
    },
]
const NAVIGATION_FIELD_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'topNavbarColor',
        label: 'topNavbarColor',
        type: 'color',
    },
    {
        name: 'topNavbarBgColor',
        label: 'topNavbarBgColor',
        type: 'color',
    },
    {
        name: 'gradient',
        label: 'gradient',
        type: 'colors',
    },
    {
        name: 'buttonColor',
        label: 'buttonColor',
        type: 'color',
    },
    {
        name: 'buttonBgColor',
        label: 'buttonBgColor',
        type: 'color',
    },
    {
        name: 'hoverButtonColor',
        label: 'hoverButtonColor',
        type: 'color',
    },
    {
        name: 'hoverButtonBgColor',
        label: 'hoverButtonBgColor',
        type: 'color',
    },
    {
        name: 'hamburgerColor',
        label: 'hamburgerColor',
        type: 'color',
    },
    {
        name: 'borderColor',
        label: 'borderColor',
        type: 'color',
    },
    {
        name: 'dropdownMenu',
        label: 'dropdownMenu',
        type: 'theme_collection',
        innerFields: [
            {
                name: 'bgColor',
                label: 'bgColor',
                type: 'color',
            },
            {
                name: 'color',
                label: 'color',
                type: 'color',
            },
            {
                name: 'hoverButtonColor',
                label: 'hoverButtonColor',
                type: 'color',
            },
            {
                name: 'hoverButtonBgColor',
                label: 'hoverButtonBgColor',
                type: 'color',
            },
            {
                name: 'leftBarColor',
                label: 'leftBarColor',
                type: 'color',
            },
        ],
    },
    {
        name: 'sideMenu',
        label: 'sideMenu',
        type: 'theme_collection',
        innerFields: [
            {
                name: 'bgColor',
                label: 'bgColor',
                type: 'color',
            },
            {
                name: 'color',
                label: 'color',
                type: 'color',
            },
            {
                name: 'hoverBgColor',
                label: 'hoverBgColor',
                type: 'color',
            },
            {
                name: 'hoverColor',
                label: 'hoverColor',
                type: 'color',
            },
            {
                name: 'rightBorderColor',
                label: 'rightBorderColor',
                type: 'color',
            },
            {
                name: 'header',
                label: 'header',
                type: 'theme_collection',
                innerFields: [
                    {
                        name: 'bgColor',
                        label: 'bgColor',
                        type: 'color',
                    },
                    {
                        name: 'color',
                        label: 'color',
                        type: 'color',
                    },
                    {
                        name: 'avatar',
                        label: 'avatar',
                        type: 'theme_collection',
                        innerFields: [
                            {
                                name: 'bgColor',
                                label: 'bgColor',
                                type: 'color',
                            },
                            {
                                name: 'color',
                                label: 'color',
                                type: 'color',
                            },
                        ],
                    },
                ],
            },
            {
                name: 'footer',
                label: 'footer',
                type: 'theme_collection',
                innerFields: [
                    {
                        name: 'bgColor',
                        label: 'bgColor',
                        type: 'color',
                    },
                    {
                        name: 'color',
                        label: 'color',
                        type: 'color',
                    },
                ],
            },
        ],
    },
]

const PADDING_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'xs',
        label: 'xs',
        type: 'font_size',
    },
    {
        name: 's',
        label: 's',
        type: 'font_size',
    },
    {
        name: 'm',
        label: 'm',
        type: 'font_size',
    },
    {
        name: 'l',
        label: 'l',
        type: 'font_size',
    },
    {
        name: 'xl',
        label: 'xl',
        type: 'font_size',
    },
]
const MARGIN_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 's',
        label: 's',
        type: 'font_size',
    },
    {
        name: 'm',
        label: 'm',
        type: 'font_size',
    },
    {
        name: 'l',
        label: 'l',
        type: 'font_size',
    },
]
const BORDER_RADIUS_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 's',
        label: 's',
        type: 'font_size',
    },
    {
        name: 'm',
        label: 'm',
        type: 'font_size',
    },
    {
        name: 'l',
        label: 'l',
        type: 'font_size',
    },
]
const GRID_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'baseline',
        label: 'baseline',
        type: 'number',
    },
    {
        name: 'gap',
        label: 'gap',
        type: 'theme_collection',
        innerFields: [
            {
                name: 's',
                label: 's',
                type: 'number',
            },
            {
                name: 'm',
                label: 'm',
                type: 'number',
            },
            {
                name: 'l',
                label: 'l',
                type: 'number',
            },
        ],
    },
]
const MEDIA_QUERY_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'xs',
        label: 'xs',
        type: 'number',
    },
    {
        name: 'sm',
        label: 'sm',
        type: 'number',
    },
    {
        name: 'md',
        label: 'md',
        type: 'number',
    },
    {
        name: 'lg',
        label: 'lg',
        type: 'number',
    },
]
const TYPOGRAPHY_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'fontFamilyRegular',
        label: 'fontFamilyRegular',
        type: 'text',
    },
    {
        name: 'fontFamilySemiBold',
        label: 'fontFamilySemiBold',
        type: 'text',
    },
    {
        name: 'baseFontSize',
        label: 'baseFontSize',
        type: 'font_size',
    },
]
const CONTENT_PADDING_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'desktop',
        label: 'desktop',
        type: 'font_size',
    },
    {
        name: 'mobile',
        label: 'mobile',
        type: 'font_size',
    },
]
const FONT_SIZES_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'headings',
        label: 'headings',
        type: 'theme_collection',
        innerFields: [
            {
                name: 'xxl',
                label: 'xxl',
                type: 'font_size',
            },
            {
                name: 'xl',
                label: 'xl',
                type: 'font_size',
            },
            {
                name: 'l',
                label: 'l',
                type: 'font_size',
            },
            {
                name: 'm',
                label: 'm',
                type: 'font_size',
            },
            {
                name: 's',
                label: 's',
                type: 'font_size',
            },
            {
                name: 'xs',
                label: 'xs',
                type: 'font_size',
            },
        ],
    },
    {
        name: 'body',
        label: 'body',
        type: 'theme_collection',
        innerFields: [
            {
                name: 'l',
                label: 'l',
                type: 'font_size',
            },
            {
                name: 'm',
                label: 'm',
                type: 'font_size',
            },
            {
                name: 's',
                label: 's',
                type: 'font_size',
            },
            {
                name: 'xs',
                label: 'xs',
                type: 'font_size',
            },
            {
                name: 'xxs',
                label: 'xxs',
                type: 'font_size',
            },
        ],
    },
]
const FONT_WEIGHTS_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'bold',
        label: 'bold',
        type: 'text',
    },
    {
        name: 'medium',
        label: 'medium',
        type: 'number',
    },
    {
        name: 'regular',
        label: 'regular',
        type: 'number',
    },
]
const MARGIN_MAP_PROPERTIES: FieldConfigDTO[] = [
    {
        name: 'sm',
        label: 'sm',
        type: 'number',
    },
    {
        name: 'md',
        label: 'md',
        type: 'number',
    },
    {
        name: 'lg',
        label: 'lg',
        type: 'number',
    },
    {
        name: 'defaultMargin',
        label: 'defaultMargin',
        type: 'number',
    },
]
export const THEME_DEFINITION_ALL_FIELD: {
    [id: string]: FieldConfigDTO[] | FieldConfigDTO
} = {
    imageSuffix: {
        name: 'imageSuffix',
        label: 'imageSuffix',
        type: 'text',
    },
    headerPanel: HEADER_PANEL_PROPERTIES,
    action: ACTION_FIELD_PROPERTIES,
    body: BODY_FIELD_PROPERTIES,
    borderRadius: BORDER_RADIUS_PROPERTIES,
    colors: COLORS_FIELD_PROPERTIES,
    contentPadding: CONTENT_PADDING_PROPERTIES,
    fontSizes: FONT_SIZES_PROPERTIES,
    fontWeights: FONT_WEIGHTS_PROPERTIES,
    form: FORM_FIELD_PROPERTIES,
    grid: GRID_PROPERTIES,
    login: LOGIN_FIELD_PROPERTIES,
    lookup: LOOKUP_FIELD_PROPERTIES,
    margin: MARGIN_PROPERTIES,
    marginMap: MARGIN_MAP_PROPERTIES,
    mediaQueryBreakPoints: MEDIA_QUERY_PROPERTIES,
    modal: MODAL_FIELD_PROPERTIES,
    money: MONEY_FIELD_PROPERTIES,
    navigation: NAVIGATION_FIELD_PROPERTIES,
    padding: PADDING_PROPERTIES,
    panel: PANEL_FIELD_PROPERTIES,
    spinner: SPINNER_FIELD_PROPERTIES,
    splash: SPLASH_PROPERTIES,
    table: TABLE_FIELD_PROPERTIES,
    typography: TYPOGRAPHY_PROPERTIES,
    maxContentWidth: {
        name: 'maxContentWidth',
        label: 'maxContentWidth',
        type: 'font_size',
    },
    maxPageWidth: {
        name: 'maxPageWidth',
        label: 'maxPageWidth',
        type: 'font_size',
    },
}
