import React from 'react'
import { log } from '../services/LogService'
import { isDefined } from '../utils/TypeCheckers'
import { GuslTableState } from './common/gusl-table/guslTableSlice'
import { TableRowState } from './common/maintain-table/table-row/tableRowSlice'
import { TypeNotFound } from './common/TypeNotFound/TypeNotFound'
import { FIELD_COMPONENT_MAP } from './FieldComponentMap'
import { ChildReference, EndpointDTO, FieldConfigDTO, FieldProperties, FormMode, IMenuDTO, MenuType } from './types'

class FieldService {
    public getTableField = (
        fieldConfig: FieldConfigDTO,
        menuItem: IMenuDTO | undefined,
        rowData: any,
        reLoad?: () => void,
        tableCode?: string,
        tableState?: GuslTableState,
        tableRowState?: TableRowState,
        inline?: boolean
        // link?: boolean | undefined /// temporary until we get type "link"
    ): any => {
        const registerChildReference = (reference: ChildReference) => {}

        const getCurrentRowData = () => {
            return rowData
        }
        return {
            render: (): React.ReactNode => {
                const properties: FieldProperties = {
                    code: 'table',
                    menuItem: menuItem,
                    formMode: FormMode.VIEW,
                    isTableView: true,
                    fieldConfig: fieldConfig,
                    rowData: rowData,
                    inline: inline || false,
                    data: rowData ? rowData[fieldConfig.name] : undefined,
                    onSubmitted: () => {},
                    onChange: (name: string, value: any, overwrite?: boolean) => {},
                    getCurrentRowData,
                    reference: {
                        name: fieldConfig.name,
                        displayOrder: 1,
                        register: registerChildReference,
                    },
                    reLoad: reLoad,
                    uniqueId: rowData?.id,
                    tableCode: tableCode,
                    tableState: tableState,
                    tableRowState: tableRowState,
                }
                if (fieldConfig?.type) {
                    const component = FIELD_COMPONENT_MAP[fieldConfig.type.toLowerCase()]
                    if (isDefined(component)) {
                        //log.error('FieldService', 'MSG600', 'Found component',{properties},React.createElement(component, properties))
                        return React.createElement(component, properties)
                    }
                }
                return (
                    <>
                        <span className="p-column-title">Not found [{properties.fieldConfig.type}]</span>
                    </>
                )
                // return TypeNotFound.TABLE_FIELD(properties);
            },
        }
    }

    public getBespokeInstance = (componentId: string, properties?: any): React.ReactElement => {
        const component = FIELD_COMPONENT_MAP[componentId]
        if (isDefined(component)) {
            const element: any = React.createElement(component, properties)
            return <>{element}</>
        }
        return TypeNotFound.FORM_FIELD(properties).element
    }
    private getInstance = (component: any, properties: FieldProperties): React.ReactElement => {
        const element: any = React.createElement(component, properties)
        // console.log('---------> element',element,properties)
        return <>{element}</>
    }

    public getFormTemplate = (properties: FieldProperties): React.ReactElement => {
        if (properties.fieldConfig?.type) {
            const component = FIELD_COMPONENT_MAP[properties.fieldConfig.type.toLowerCase()]
            if (isDefined(component)) {
                //  log.info('FieldService field properties', 'MSG001',properties.fieldConfig.type.toLowerCase() )
                return this.getInstance(component, properties)
            }
        }
        return TypeNotFound.FORM_FIELD(properties).element
    }

    public extractSinglePageFields = (menuItem: IMenuDTO, onlyTableFields?: boolean): FieldConfigDTO[] => {
        if (!menuItem) {
            return []
        }
        if (menuItem.singlePage?.rows) {
            return menuItem.singlePage.rows.flatMap((row) => {
                return row.columns?.flatMap((col) => {
                    return col.fields
                        .filter((fld) => {
                            if (onlyTableFields === undefined) {
                                return true
                            } else if (onlyTableFields) {
                                return fld.displayInTable === true
                            } else {
                                return fld.displayInTable === undefined || !fld.displayInTable
                            }
                        })
                        .flatMap((fld) => fld)
                })
            })
        }
        return []
    }
    public extractSinglePageEndpoint = (menu: IMenuDTO): EndpointDTO | undefined => {
        if (menu.menuType === MenuType.SINGLE_PAGE) {
            return menu.singlePage?.selectUrl
        }
        log.warn('SinglePage', 'MSG003', 'failed to extract endpoint', menu)
        return undefined
    }
}

const fieldService = new FieldService()

export { fieldService }

export default FieldService
