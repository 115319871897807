import React, { useEffect, useState } from 'react'
import MGQuery from '../../../../../providers/session/MGQuery'
import { InlineTableInputProps, LookupObjectDO } from '../types'
import { extractValues } from '../functions/extractValuesFromObjectForDropdown'
import { safeStringify } from '../functions/safeStringify'
import { addToLookupOptions, InlineEditState } from '../inlineEditSlice'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'

export default function LookupEditInput({
    rowId,
    idx,
    fld,
    inputRefs,
    inlineFieldEditHandler,
    setFocus,
    defaultValue,
    row,
    code,
}: InlineTableInputProps) {
    const [className] = useState('LookupEditInput-' + new Date().getTime())
    const dispatch = useAppDispatch()

    const [options, setOptions] = useState<string[]>([])
    /**
     * List of accepted keys for extracting values from a LookupObjectDO object for select options.
     * update as needed...
     */
    const acceptedKeys: (keyof LookupObjectDO)[] = ['name', 'id', 'code', 'value']
    const [selectedValue, setSelectedValue] = useState(defaultValue)
    const inlineEditState: InlineEditState = useAppSelector((state) => state.inlineEditSlice[code])
    const lookupOptions: InlineEditState['lookupOptions'] = inlineEditState.lookupOptions
    const optionsForTheField = lookupOptions.find((options) => options.fieldName === fld.name)?.options

    const LookupRD = MGQuery({
        method: 'get',
        endpoint: fld.lookupSelectUrl as string,
        queryParams: {},
        dontRunQuery: optionsForTheField && optionsForTheField.length > 0,
    })

    useEffect(() => {
        if (LookupRD.isSuccess && LookupRD.data) {
            dispatch(
                addToLookupOptions({
                    code,
                    fieldName: fld.name,
                    options: LookupRD.data,
                })
            )
        }
    }, [LookupRD.data, LookupRD.isSuccess])

    useEffect(() => {
        if (optionsForTheField) {
            setOptions(extractValues(optionsForTheField, acceptedKeys))
        }
    }, [optionsForTheField])
    return (
        <div className={' d-flex align-items-center justify-content-evenly'}>
            <select
                key={'ff-det_' + rowId + '_' + idx + '---' + fld.name}
                ref={(el) => (inputRefs.current[idx] = el!)}
                id={'ff-det_' + rowId + '_' + idx + '---' + fld.name}
                style={{ color: 'black' }}
                /*@ts-ignore*/
                value={selectedValue}
                onChange={(e) => {
                    setSelectedValue(e.target.value)
                    inlineFieldEditHandler(e, fld)
                }}
            >
                {options.map((option, idx2) => (
                    <option key={idx2} value={optionsForTheField ? safeStringify(optionsForTheField[idx2]) : option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    )
}
