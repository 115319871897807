import styled from 'styled-components'

export const SearchboxWrapperStyled = styled.div.attrs((props) => ({
    className: 'input-group m-0 p-0 mt-1',
}))`
    input[type='text'] {
        border: 1px solid ${(props) => props.theme.colors.accentColorMuted} !important;
        //border-radius: 5px !important;
    }
`
export const SearchboxContainerStyled = styled.div.attrs((props) => ({
    id: 'searchbox_container',
}))``

interface SearchIconProperties {
    active: boolean
}

export const SearchIconStyled = styled.i.attrs((props: SearchIconProperties) => ({
    className: 'fa-solid fa-search me-2',
}))<SearchIconProperties>`
    color: ${(props) => (props.active ? props.theme.table.headerColor : props.theme.colors.dark[1])};
`

export const SearchInputStyled = styled.input.attrs((props) => ({
    className: 'form-control border-0 rounded-0',
}))`
    color: ${(props) => props.theme.table.columnColor} !important;
`
