/**
 * Safely converts an object into a JSON string, handling circular references.
 *
 * This function uses a replacer function to recursively traverse the object's properties during JSON stringification.
 * It detects and handles circular references by maintaining a cache of encountered objects.
 *
 * @param obj The LookupObjectDO | MultiSelectObjectDO object to stringify.
 * @returns A JSON string representation of the object, handling circular references safely.
 */
export function safeStringify<T extends Record<string, any>>(obj: T) {
    const cache: Set<object> = new Set()
    return JSON.stringify(obj, (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (cache.has(value)) {
                // Circular reference found, discard key
                return
            }
            cache.add(value)
        }
        return value
    })
}
