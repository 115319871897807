import React from 'react'
import { TickerContainerStyled, TickerHeaderWrapperStyled, TickerHeadingIconStyled, TickerHeadingLabelStyled } from './styled' // @ts-ignore
import styles from './ticker.module.css'

interface TickerProperties {
    label?: string | undefined
    data: { id: string; value: string }[]
    component?: any
    keyName: string
    speed: number
    delay: number
    direction: 'normal' | 'reverse' | 'alternate' | 'alternate-reverse'
    tickerClassName?: string
    itemClassName?: string
    tickerTextClassName?: string
    tickerStyle?: any
    itemStyle?: any
    loop: boolean
}

export const Ticker = ({
    label,
    data,
    component,
    keyName,
    speed,
    delay,
    direction,
    tickerClassName,
    itemClassName,
    tickerTextClassName,
    tickerStyle,
    itemStyle,
    loop,
}: TickerProperties): React.ReactElement => {
    const renderHeader = (): React.ReactElement => {
        return (
            <TickerHeaderWrapperStyled>
                <TickerContainerStyled>
                    <TickerHeadingIconStyled>
                        <i className="fa-solid fa-file-lines"></i>
                    </TickerHeadingIconStyled>
                    {label && <TickerHeadingLabelStyled>{label}</TickerHeadingLabelStyled>}
                </TickerContainerStyled>
            </TickerHeaderWrapperStyled>
        )
    }

    return (
        <div id="ticker" style={tickerStyle}>
            {renderHeader()}
            <div
                className={[styles.newsTicker, tickerClassName].join(' ')}
                style={{
                    // marginLeft: `${(label?.length || 0) * 7 + 4}px`,
                    marginLeft: '152px',
                    marginTop: '-46px',
                    backgroundColor: '#ffffff',
                    height: '46px',
                }}
            >
                <div
                    className={styles.tickerContent}
                    style={{
                        animationDuration: `${data.length * (61 - speed)}s`,
                        animationDelay: `${delay}s`,
                        animationDirection: direction,
                        animationIterationCount: loop ? 'infinite' : 1,
                    }}
                >
                    {component
                        ? data.map((item, index) => {
                              return (
                                  <div
                                      key={'id_' + item.id + '_' + index}
                                      className={[styles.tickerItem, itemClassName].join(' ')}
                                      style={itemStyle}
                                  >
                                      {component(item, index)}
                                  </div>
                              )
                          })
                        : data.map((item, index) => {
                              return (
                                  <div
                                      key={'ticker-item-' + index}
                                      style={itemStyle}
                                      className={[styles.tickerItem, itemClassName].join(' ')}
                                  >
                                      <div className={[styles.tickerText, tickerTextClassName].join(' ')}>{item.value}</div>
                                  </div>
                              )
                          })}
                </div>
            </div>
        </div>
    )
}
