import React from 'react'
import { HomePageStyled, HomePageTextStyled } from './styled'

export const HomePage = (): React.ReactElement => {
    return (
        <HomePageStyled>
            <HomePageTextStyled>Home Page</HomePageTextStyled>
        </HomePageStyled>
    )
}
