import React, { useState } from 'react'
import './sample.module.css'
import { sessionService } from '../../../../../../providers/session/SessionService'
import { RunOnceEffect } from '../../../../../../utils/Utils'

export const TimetableContent = (props: any): React.ReactElement => {
    console.log('props', props)
    const [htmlContent, setHtmlContent] = useState<string>('')

    RunOnceEffect(() => {
        const request: any = {
            type: 'hi', // props.type
        }

        sessionService
            .post<any, any>('/article/html-content-time-table', request)
            .then((response) => {
                console.log('response', response?.data?.content)
                setHtmlContent(response?.data?.content || '')
            })
            .catch((error: any) => {
                console.log('error', error)
                setHtmlContent('<h3>we got an error</h3>')
            })
    })
    return (
        <>
            <style>{props.type === 'style1' ? STYLES : STYLES_1}</style>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
        </>
    )
}
const STYLES_1: string = `
.g-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size:10px;
}

.g-td, .g-th {
  border: 1px solid #ddd;
  padding: 8px;
}

.g-tr:nth-child(even){background-color: #f2f2f2;}

.g-tr:hover {background-color: #ddd;}

.g-th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}`

const STYLES: string = `
h1 {
    font-size: 30px;
    color: #f00;
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
    margin-bottom: 15px;
}

.g-table {
    table-layout: fixed;
    background: -webkit-linear-gradient(left, #25c481, #25b7c4);
    background: linear-gradient(to right, #25c481, #25b7c4);
    font-family: 'Roboto', sans-serif;
}

.g-thead {
    background-color: rgba(255, 255, 255, 0.3);
}

.g-tbody {
    height: 300px;
    overflow-x: auto;
    margin-top: 0px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.g-th {
    padding: 20px 15px;
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
}

.g-td {
    padding: 15px;
    text-align: left;
    vertical-align: middle;
    font-weight: 300;
    font-size: 12px;
    color: #fff;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

`
