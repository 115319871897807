import React, { useEffect, useState } from 'react'
import {
    InputGroupIconStyled,
    InputGroupIconWrapperStyled,
    InputGroupInputStyled,
    InputGroupResultsWrapperStyled,
} from '../../../styled_v2'
import EquitiesTableRow from './EquitiesTableRow'
import { OrderAction } from '../../../types'
import { NewTickerDO, sortDirectionType } from '../../types'
import BSSpinner from '../../acuity-widgets/BSSpiner'
import SortButton from '../SortButton'

export interface TickersTableDO {
    searchHandler: Function
    tickers: NewTickerDO[]
    setTickers: Function
    performPreOrderQuery: Function
    setWLQueryRefresher: Function
    currentlySelectedFavorites: NewTickerDO[]
}

export default function EquitiesTable({
    searchHandler,
    tickers,
    setTickers,
    performPreOrderQuery,
    setWLQueryRefresher,
    currentlySelectedFavorites,
}: TickersTableDO) {
    const [className] = useState('TickersTable-' + new Date().getTime())
    const [orderByField, setOrderByField] = useState<string>('')
    const [sortDirection, setSortDirection] = useState<sortDirectionType>('ASC')
    const [renderedTickers, setRenderedTickers] = useState<React.JSX.Element[]>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (renderedTickers.length === tickers.length) {
            setLoading(false)
        }
    }, [renderedTickers, tickers])

    useEffect(() => {
        setRenderedTickers(
            tickers.map((ticker, idx) => (
                <EquitiesTableRow
                    key={ticker.id}
                    ticker={ticker}
                    performPreOrderQuery={() => {
                        performPreOrderQuery(ticker, OrderAction.NEW)
                    }}
                    setWLQueryRefresher={setWLQueryRefresher}
                    currentlySelectedFavorites={currentlySelectedFavorites}
                />
            ))
        )
    }, [tickers, performPreOrderQuery])

    function toTitleCase(input: string): string {
        return input.charAt(0).toUpperCase() + input.substr(1)
    }

    function formatWord(word: string): string {
        // If the word is all lowercase, return it with the first letter capitalized
        if (/^[a-z]+$/.test(word)) {
            return toTitleCase(word)
        }

        // Split camelCase words into separate words
        const words = word.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ')

        // Apply title casing to each word
        const formattedWords = words.map((w) => toTitleCase(w.toLowerCase()))

        // Join the formatted words with space
        return formattedWords.join(' ')
    }

    const handleHeaderClick = (field: string) => {
        // @ts-ignore
        if (field === orderByField) {
            // If the same field is clicked again, toggle sorting direction
            setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC')
        } else {
            // If a different field is clicked, set sorting direction to 'desc'
            setSortDirection('DESC')
            // @ts-ignore
            setOrderByField(field)
        }
    }
    useEffect(() => {
        const sortedTickers = [...tickers].sort((a, b) => {
            // @ts-ignore
            if (a[orderByField] !== undefined && b[orderByField] !== undefined) {
                // If both objects have the orderByField, compare them
                // @ts-ignore
                return sortDirection === 'ASC' ? a[orderByField] - b[orderByField] : b[orderByField] - a[orderByField]
                // @ts-ignore
            } else if (a[orderByField] !== undefined) {
                // If only the first object has orderByField, move it up
                return -1
                // @ts-ignore
            } else if (b[orderByField] !== undefined) {
                // If only the second object has orderByField, move it up
                return 1
            }
            // If neither object has orderByField, maintain order
            return 0
        })
        setTickers(sortedTickers)
    }, [orderByField, sortDirection])

    function getSortDirection(field: string): string {
        let _sortDirection: string = 'NONE'
        if (field !== orderByField) {
            return _sortDirection
        }
        return sortDirection.toUpperCase()
    }

    return (
        <div key={''}>
            <div className={'w-100'}>
                <div className="input-group mb-3">
                    <InputGroupIconWrapperStyled>
                        <InputGroupIconStyled className={'fa-solid fa-search'} />
                    </InputGroupIconWrapperStyled>
                    <InputGroupInputStyled
                        type="text"
                        id={'tickerSearch'}
                        autoFocus={true}
                        onChange={(e) => {
                            searchHandler(e)
                        }}
                        placeholder="Search..."
                        aria-label="Search"
                        aria-describedby="tickerSearch"
                    />
                    <InputGroupResultsWrapperStyled>
                        <small className={'xs-small'}>{tickers.length} results</small>
                    </InputGroupResultsWrapperStyled>
                </div>
            </div>
            <div className="table-responsive mt-3" style={{ height: 400 }}>
                <table className={'table table-sm table-striped'}>
                    <thead className={'sticky-top bg-dark'}>
                        <tr className={'xs-small'}>
                            <th></th>
                            <th>Ticker</th>
                            <th>Name</th>
                            {/*<th>Currency</th>*/}
                            {['change'].map((field, idx) => (
                                <th role={'button'} className={''} key={idx} onClick={() => handleHeaderClick(field)}>
                                    <div className={'d-flex align-items-center'}>
                                        <span>{formatWord(field)}</span>
                                        <SortButton
                                            key={field}
                                            onClick={() => handleHeaderClick(field)}
                                            sortDirection={getSortDirection(field) as sortDirectionType}
                                        />
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {loading && (
                            <tr>
                                <td colSpan={7} className={' text-center'}>
                                    <div className={' mt-5'}>
                                        <BSSpinner isLoading={loading} />
                                    </div>
                                </td>
                            </tr>
                        )}
                        {!loading && <>{renderedTickers}</>}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
