import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { environmentService } from '../../../../providers/environment/EnvironmentService'
import MGQuery from '../../../../providers/session/MGQuery'
import { SessionContext } from '../../../../providers/session/SessionContext'
import { RunOnceEffect, unSubscribe } from '../../../../utils/Utils'
import { FavQueryActionConfigDTO, FavQueryDTO } from '../../../types'
import ElementWithPopover from '../../element-with/element-with-popover/ElementWithPopover'
import { ElementWithTooltip } from '../../element-with/element-with-tooltip/ElementWithTooltip'
import Icon from '../../maintain-table/bootstrap/Icon'
import { GuslTableState, resetSearchableField, setSearchString, toggleFavQueries } from '../guslTableSlice'
import { resetFilters } from '../queryParamsSlice'
import { FavQueriesWrapperStyled } from '../styled/side-panel/styled'
import { ActionIconStyled } from '../styled/table-controls/styled'
import FavQuery, { FavQueryDO } from './FavQuery'
import FavQueryForm from './FavQueryForm'
import GetDefaultSearchableFields from '../GetDefaultSerarchableFields'
import { log } from '../../../../services/LogService'
import { FavQueriesApiResponseDO } from './types'

interface FavQueriesWrapperDO {
    code: string
}

export default function FavQueriesWrapper({ code }: FavQueriesWrapperDO) {
    const [className] = useState('FavQueriesWrapper-' + new Date().getTime())
    const state: GuslTableState = useAppSelector((state) => state.guslTableSlice[code])
    const [queryRefresher, setQueryRefresher] = useState<number>(0)
    const [errorMsg, setErrorMsg] = useState<string>('')
    const isOpen: boolean = state.favQueriesOpen
    const dispatch = useAppDispatch()
    const defaultSearchableFields = GetDefaultSearchableFields({ code })
    const favouriteQueries: FavQueryDTO[] = state.favQueries

    function resetFiltersHandler() {
        dispatch(resetFilters({ code }))
        dispatch(resetSearchableField({ code, defaultSearchableFields }))
        dispatch(setSearchString({ code, searchString: '' }))
    }

    function toggleHandler() {
        dispatch(toggleFavQueries({ code }))
    }

    const functions: FavQueryDO['functions'] = {
        setErrorMsg,
        setQueryRefresher,
        toggleHandler,
    }
    return (
        <FavQueriesWrapperStyled>
            <div className="col-12 d-flex justify-content-between align-items-center px-1">
                <ElementWithTooltip
                    placement={'left-end'}
                    element={
                        <div role={'button'} onClick={toggleHandler} key={queryRefresher}>
                            <ActionIconStyled active={isOpen}>
                                <Icon icon={'fa-solid fa-heart'} /> ({favouriteQueries.length})
                            </ActionIconStyled>
                        </div>
                    }
                    tooltip={
                        <span>
                            {favouriteQueries.length ? (
                                'Favorite queries'
                            ) : (
                                <span>
                                    You can save current filters to your favourites by clicking on the save icon.
                                    <Icon icon={'fa-solid fa-save'} />
                                </span>
                            )}
                        </span>
                    }
                />
                <div className=" d-flex justify-content-between align-items-center">
                    <ElementWithPopover
                        withClose
                        element={
                            <div>
                                <ElementWithTooltip
                                    placement={'top-start'}
                                    element={
                                        <div role={'button'}>
                                            <ActionIconStyled>
                                                <Icon icon={'fa-solid fa-save'} extraClass={'me-2'} />
                                            </ActionIconStyled>
                                        </div>
                                    }
                                    tooltip={<span role={'button'}>Save current query to your favourites.</span>}
                                />
                            </div>
                        }
                        trigger={'click'}
                        placement={'left-start'}
                        popover={<FavQueryForm code={code} action={'add'} functions={functions} errorMsg={errorMsg} />}
                    />

                    <ActionIconStyled>
                        <ElementWithTooltip
                            element={<i className={'fa-solid fa-filter-circle-xmark'} onClick={resetFiltersHandler} />}
                            tooltip={<span>Reset filters.</span>}
                        />
                    </ActionIconStyled>
                </div>
            </div>
            {isOpen ? (
                <div className="col-12 mx-0 px-0 mt-3">
                    {favouriteQueries.map((favQuery, idx) => (
                        <FavQuery key={idx} favQuery={favQuery} code={code} functions={functions} errorMsg={errorMsg} />
                    ))}
                </div>
            ) : (
                <></>
            )}
        </FavQueriesWrapperStyled>
    )
}
