import React from 'react'
import { log } from '../../../services/LogService'
import { FieldConfigDTO, FieldProperties } from '../../types'
import { RadialMeter } from './RadialMeter'
import { RadialMeterDTO } from './types'

export class RadialMeterField {
    public static renderNoData = (fieldConfig: FieldConfigDTO): React.ReactElement => {
        return (
            <div className="card" key={fieldConfig.name}>
                <h5 className="centerText">{fieldConfig.label}</h5>
                <p>No Data found</p>
            </div>
        )
    }

    public static renderMeter = (fieldConfig: FieldConfigDTO, data: RadialMeterDTO): React.ReactElement => {
        log.info('RadialMeterField', 'MSG001', 'renderMeter Data', data)
        return (
            <div key={fieldConfig.name}>
                <RadialMeter key={fieldConfig.name} {...{ fieldConfig, data }} />
            </div>
        )
    }

    public static FORM_FIELD = (properties: FieldProperties) => {
        log.info('RadialMeterField', 'MSG001', 'FORM_FIELD properties', properties)
        if (properties.radialMeterData) {
            return {
                element: RadialMeterField.renderMeter(properties.fieldConfig, properties.radialMeterData),
            }
        } else {
            return {
                element: RadialMeterField.renderNoData(properties.fieldConfig),
            }
        }
    }
}
