import React, { MouseEventHandler, useState } from 'react'
import { HoverInnerPanelStyled, HoverLinkStyled, HoverOuterPanelStyled } from './styled'

interface HoverableProperties {
    link: React.ReactElement<any>
    panel: React.ReactElement<any>
}

interface PanelProperties {
    handleMouseOver: MouseEventHandler<HTMLDivElement>
    handleMouseOut: MouseEventHandler<HTMLDivElement>
    link: React.ReactElement
    isHovering: boolean
}

export const HoverablePanel = (properties: HoverableProperties): React.ReactElement<HoverableProperties> => {
    const [isHovering, setIsHovering] = useState(false)
    const handleMouseOver = () => {
        setIsHovering(true)
    }

    const handleMouseOut = () => {
        setIsHovering(false)
    }

    const renderPanel = (): React.ReactElement<PanelProperties> => {
        return (
            <HoverOuterPanelStyled>
                <HoverInnerPanelStyled onMouseOut={handleMouseOut} onMouseLeave={handleMouseOut}>
                    {properties.panel}
                </HoverInnerPanelStyled>
            </HoverOuterPanelStyled>
        )
    }

    return (
        <div>
            <HoverableDiv
                handleMouseOver={handleMouseOver}
                handleMouseOut={handleMouseOut}
                link={properties.link}
                isHovering={isHovering}
            />
            {isHovering && renderPanel()}
        </div>
    )
}

const HoverableDiv = (properties: PanelProperties): React.ReactElement<PanelProperties> => {
    return (
        <HoverLinkStyled
            onMouseOver={properties.handleMouseOver}
            onMouseOut={properties.handleMouseOut}
            onMouseLeave={properties.handleMouseOut}
        >
            {!properties.isHovering && properties.link}
        </HoverLinkStyled>
    )
}
