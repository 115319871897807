import { useSpring } from '@react-spring/web'
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import ActionDialog from '../../../components/common/action-dialog/ActionDialog'
import Icon from '../../../components/common/maintain-table/bootstrap/Icon'
import { LoginIconPlaceholderStyled } from '../../../components/common/password/styled'
import { ForgottenDetailsLabelStyled, ForgottenDetailsStyled } from '../../../layouts/mobile-layout/styled'
import { environmentService } from '../../../providers/environment/EnvironmentService'
import { AuthType } from '../../../providers/environment/types'
import { translateService } from '../../../services/TranslateService'
import { FieldStyled, IconWrapperStyled, PasswordCardStyled, PasswordIconStyled, PasswordStyled } from '../styled'
import { LabelStyled, LoginFieldContentStyled } from './styled'

type PasswordProps = {
    value: string
    hasLabel: boolean
    asUppercase: boolean
    hasIcon: boolean
    autoFocus: boolean
    onNextField: () => void
    onChange: (value: string) => void
    formSubmitted: boolean
}
const Password = (props: PasswordProps): React.ReactElement => {
    const label = translateService.getValue('login.password.label', 'Password')
    const placeholder = translateService.getValue('login.password.placeholder', 'Password')
    const [isPasswordRevealed, setIsPasswordRevealed] = useState<boolean>(false)
    const [showForgottenDetail, setShowForgottenDetail] = useState<boolean>(false)

    const [isDisplayHelp, setIsDisplayHelp] = React.useState<boolean>(false)
    const contentProps = useSpring({
        to: {
            opacity: isDisplayHelp ? 1 : 0,
            marginTop: isDisplayHelp ? 0 : -10,
        },
    })

    const renderForgottenDetailsPanel = (): React.ReactElement => {
        return (
            <>
                {showForgottenDetail && environmentService.getEnvironment()?.forgottenDetails && (
                    <ActionDialog
                        action={environmentService.getEnvironment()?.forgottenDetails}
                        cannotClose={true}
                        closeDialog={() => {
                            setShowForgottenDetail(false)
                        }}
                    />
                )}
            </>
        )
    }

    const renderForgottenDetails = (): React.ReactElement => {
        if (
            !environmentService.getEnvironment()?.authType ||
            environmentService.getEnvironment()?.authType === AuthType.MSAL ||
            environmentService.getEnvironment()?.authType === AuthType.GOOGLE_SSO
        ) {
            return <></>
        } else {
            return (
                <ForgottenDetailsStyled capitalise={false}>
                    <ForgottenDetailsLabelStyled
                        onClick={() =>
                            environmentService.getEnvironment()?.forgottenDetails
                                ? setShowForgottenDetail(true)
                                : setIsDisplayHelp((showDisplay) => !showDisplay)
                        }
                    >
                        Forgot your password?
                    </ForgottenDetailsLabelStyled>
                </ForgottenDetailsStyled>
            )
        }
    }
    return (
        <PasswordCardStyled>
            {props.hasLabel && (
                <LabelStyled htmlFor="password" asUppercase={props.asUppercase}>
                    {label}
                </LabelStyled>
            )}
            <PasswordStyled>
                <Container fluid>
                    <Row>
                        {props.hasIcon && (
                            <IconWrapperStyled>
                                <PasswordIconStyled />{' '}
                            </IconWrapperStyled>
                        )}
                        <Col style={{ paddingLeft: '0px' }}>
                            <LoginFieldContentStyled>
                                <FieldStyled
                                    name="password"
                                    type={!isPasswordRevealed ? 'password' : 'text'}
                                    placeholder={placeholder}
                                    tabIndex={2}
                                    autoFocus={props.autoFocus}
                                    value={props.value}
                                    onChange={(e) => {
                                        props.onChange(e.target.value)
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            props.onNextField()
                                        }
                                    }}
                                />
                                {!props.hasIcon && (
                                    <LoginIconPlaceholderStyled active={!isPasswordRevealed}>
                                        <Icon
                                            icon={isPasswordRevealed ? 'fa fa-eye-slash' : 'fa fa-eye'}
                                            onClick={() => {
                                                setIsPasswordRevealed(!isPasswordRevealed)
                                            }}
                                        />
                                    </LoginIconPlaceholderStyled>
                                )}
                            </LoginFieldContentStyled>
                        </Col>
                    </Row>
                    <Row>
                        <Col>{renderForgottenDetails()}</Col>
                    </Row>
                </Container>
            </PasswordStyled>
            {renderForgottenDetailsPanel()}
        </PasswordCardStyled>
    )
}

export default Password
