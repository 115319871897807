import styled from 'styled-components'

export const CommentsContainerStyled = styled.div.attrs((props) => ({}))`
    // z-index: 30050;
    z-index: inherit;
    position: relative;
    left: -100px;
    top: 20px;
`

export const CommentsDetailsStyled = styled.div.attrs((props) => ({}))`
    background-color: ${(props) => props.theme.modal.innerBackground};
    border: ${(props) => 'solid 1px ' + props.theme.navigation.borderColor};
    min-width: 300px;
    max-width: 450px;
    max-height: 450px;
    position: absolute;
    top: 0px;
`
export const CommentsHeaderDetailsStyled = styled.div.attrs((props) => ({}))`
    background-color: ${(props) => props.theme.modal.outerBackground};
    border-bottom: ${(props) => 'solid 1px ' + props.theme.table.headerColor};
    padding: 5px;
`
export const CommentsInfoStyled = styled.div.attrs((props) => ({}))`
    background-color: ${(props) => props.theme.colors.dark[0]};
    padding: 5px;
    max-height: 200px;
    overflow: auto;
`

export const CommentTableStyled = styled.div.attrs((props) => ({}))`
    //&:hover {
    //  text-decoration: underline
    //}
`
