// @ts-nocheck
import { useEffect, useState } from 'react'
import { IError } from '../Login'
import { googleService } from './GoogleService'

const useGoogleLogin = ({
    onSuccess = (res: any) => {},
    onAutoLoadFinished = () => {},
    onFailure = (error: IError) => {},
    onRequest = () => {},
    onScriptLoadFailure,
    clientId,
    cookiePolicy,
    loginHint,
    hostedDomain,
    autoLoad,
    isSignedIn,
    fetchBasicProfile,
    redirectUri,
    discoveryDocs,
    uxMode,
    scope,
    accessType,
    responseType,
    jsSrc = 'https://apis.google.com/js/api.js',
    prompt,
}) => {
    const [loaded, setLoaded] = useState(false)

    function handleSigninSuccess(res) {
        /*
          offer renamed response keys to names that match use
        */
        const basicProfile = res.getBasicProfile()
        const authResponse = res.getAuthResponse(true)
        res.googleId = basicProfile.getId()
        res.tokenObj = authResponse
        res.tokenId = authResponse.id_token
        res.accessToken = authResponse.access_token
        res.profileObj = {
            googleId: basicProfile.getId(),
            imageUrl: basicProfile.getImageUrl(),
            email: basicProfile.getEmail(),
            name: basicProfile.getName(),
            givenName: basicProfile.getGivenName(),
            familyName: basicProfile.getFamilyName(),
        }
        // @ts-ignore
        onSuccess(res)
    }

    function signIn(e) {
        if (e) {
            e.preventDefault() // to prevent submit if used within form
        }
        if (loaded) {
            // @ts-ignore
            const GoogleAuth = window.gapi.auth2.getAuthInstance()
            const options = {
                prompt,
            }
            onRequest()
            if (responseType === 'code') {
                // @ts-ignore
                GoogleAuth.grantOfflineAccess(options).then(
                    // @ts-ignore
                    (res) => onSuccess(res),
                    // @ts-ignore
                    (err) => onFailure(err)
                )
            } else {
                GoogleAuth.signIn(options).then(
                    (res) => handleSigninSuccess(res),
                    // @ts-ignore
                    (err) => onFailure(err)
                )
            }
        }
    }

    useEffect(() => {
        let unmounted = false
        const onLoadFailure = onScriptLoadFailure || onFailure
        // @ts-ignore
        googleService.load(
            document,
            'script',
            'google-login',
            jsSrc,
            () => {
                const params = {
                    client_id: clientId,
                    cookie_policy: cookiePolicy,
                    login_hint: loginHint,
                    hosted_domain: hostedDomain,
                    fetch_basic_profile: fetchBasicProfile,
                    discoveryDocs,
                    ux_mode: uxMode,
                    redirect_uri: redirectUri,
                    scope,
                    access_type: accessType,
                }

                if (responseType === 'code') {
                    params.access_type = 'offline'
                }

                // @ts-ignore
                window.gapi.load('auth2', () => {
                    // @ts-ignore
                    const GoogleAuth = window.gapi.auth2.getAuthInstance()
                    if (!GoogleAuth) {
                        // @ts-ignore
                        window.gapi.auth2.init(params).then(
                            (res) => {
                                if (!unmounted) {
                                    setLoaded(true)
                                    const signedIn = isSignedIn && res.isSignedIn.get()
                                    // @ts-ignore
                                    onAutoLoadFinished(signedIn)
                                    if (signedIn) {
                                        handleSigninSuccess(res.currentUser.get())
                                    }
                                }
                            },
                            (err) => {
                                setLoaded(true)
                                // @ts-ignore
                                onAutoLoadFinished(false)
                                onLoadFailure(err)
                            }
                        )
                    } else {
                        GoogleAuth.then(
                            () => {
                                if (unmounted) {
                                    return
                                }
                                if (isSignedIn && GoogleAuth.isSignedIn.get()) {
                                    setLoaded(true)
                                    // @ts-ignore
                                    onAutoLoadFinished(true)
                                    handleSigninSuccess(GoogleAuth.currentUser.get())
                                } else {
                                    setLoaded(true)
                                    // @ts-ignore
                                    onAutoLoadFinished(false)
                                }
                            },
                            (err) => {
                                // @ts-ignore
                                onFailure(err)
                            }
                        )
                    }
                })
            },
            (err) => {
                onLoadFailure(err)
            }
        )

        return () => {
            unmounted = true
            googleService.remove(document, 'google-login')
        }
    }, [])

    useEffect(() => {
        if (autoLoad) {
            // @ts-ignore
            signIn()
        }
    }, [loaded])

    return { signIn, loaded }
}

export default useGoogleLogin
