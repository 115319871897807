import React, { memo, useEffect, useState } from 'react'
import MGQuery from '../../../../../../providers/session/MGQuery'
import { InlineTableInputProps, MultiSelectObjectDO, MultiSelectObjectSelectionDO } from '../../types'
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks'
import {
    addToDefaultSelectedOptions,
    addToMultiSelectOptions,
    FieldInEditModeDO,
    InlineEditState,
    removeFromDefaultSelectedOptions,
    updateEditFields,
} from '../../inlineEditSlice'
import './MultiSelect.css'
import ElementWithPopover from '../../../../element-with/element-with-popover/ElementWithPopover'
import { extractValues } from '../../functions/extractValuesFromObjectForDropdown'
import { safeStringify } from '../../functions/safeStringify'
import { Placement } from 'react-bootstrap/types'

const MultiSelectEditInput: React.FC<InlineTableInputProps> = memo(
    ({ rowId, idx, fld, inputRefs, inlineFieldEditHandler, setFocus, defaultValue, row, code }: InlineTableInputProps) => {
        const [className] = useState('MultiSelectEditInput-' + new Date().getTime())
        const dispatch = useAppDispatch()
        // @ts-ignore
        const defaultSelections: MultiSelectObjectSelectionDO = row[fld.name as string]
        const defaultSelected: MultiSelectObjectDO[] = defaultSelections ? defaultSelections.selections : []

        useEffect(() => {
            dispatch(
                addToDefaultSelectedOptions({
                    code,
                    fieldName: fld.name,
                    options: defaultSelected,
                    rowId,
                })
            )
        }, [rowId])

        const inlineEditState: InlineEditState = useAppSelector((state) => state.inlineEditSlice[code])
        const fieldsInEditMode: InlineEditState['fieldsInEditMode'] = [...inlineEditState.fieldsInEditMode]
        const multiSelectOptions: InlineEditState['multiSelectOptions'] = [...inlineEditState.multiSelectOptions]
        const optionsForTheField = multiSelectOptions.find((options) => options.fieldName === fld.name && options.rowId === rowId)?.options
        const defaultMultiSelectOptionsRedux: InlineEditState['defaultMultiSelectOptions'] = [...inlineEditState.defaultMultiSelectOptions]
        const defaultMultiSelectOptionsForTheField = defaultMultiSelectOptionsRedux.find(
            (options) => options.fieldName === fld.name && options.rowId === rowId
        )?.options

        const [options, setOptions] = useState<string[]>([])
        /**
         * List of accepted keys for extracting values from a MultiSelectObjectDO object for select options.
         * update as needed...
         */
        const acceptedKeys: (keyof MultiSelectObjectDO)[] = ['label', 'value']
        const [selectedOptions, setSelectedOptions] = useState<MultiSelectObjectDO[]>(defaultMultiSelectOptionsForTheField || [])
        const [deSelectedOptions, setDeSelectedOptions] = useState<MultiSelectObjectDO[]>([])

        const [currentFieldSelectedOptions, setCurrentFieldSelectedOptions] = useState<MultiSelectObjectDO[]>([])
        const currentFieldInEditModeFromRedux: FieldInEditModeDO | undefined = fieldsInEditMode.find(
            (field) => field.name === fld.name && field.rowId === rowId
        )
        const [placement, setPlacement] = useState<Placement>('bottom')
        const [placement2, setPlacement2] = useState<Placement>('left-end')

        useEffect(() => {
            if (currentFieldInEditModeFromRedux && currentFieldInEditModeFromRedux.value) {
                setCurrentFieldSelectedOptions(JSON.parse(currentFieldInEditModeFromRedux.value as string))
            }
        }, [currentFieldInEditModeFromRedux])

        const LookupRD = MGQuery({
            method: 'get',
            endpoint: fld.lookupSelectUrl as string,
            queryParams: {},
            dontRunQuery: optionsForTheField && optionsForTheField.length > 0,
        })

        useEffect(() => {
            if (LookupRD.isSuccess && LookupRD.data) {
                const combinedOptions: MultiSelectObjectDO[] = [...defaultSelected, ...LookupRD.data]
                const uniqueOptionsSet: Set<string> = new Set()
                const options: MultiSelectObjectDO[] = combinedOptions.filter((option) => {
                    const key = `${option.label}:${option.value}`
                    if (uniqueOptionsSet.has(key)) {
                        return false
                    }
                    uniqueOptionsSet.add(key)
                    return true
                })
                dispatch(addToMultiSelectOptions({ code, fieldName: fld.name, options, rowId }))
            }
        }, [LookupRD.data, LookupRD.isSuccess])

        useEffect(() => {
            if (optionsForTheField) {
                setOptions(extractValues(optionsForTheField, acceptedKeys))
            }
        }, [optionsForTheField, rowId])

        function getSelectedOptions(): MultiSelectObjectDO[] {
            const allSelected: MultiSelectObjectDO[] = [...selectedOptions, ...currentFieldSelectedOptions]
                .filter((obj) => !deSelectedOptions.includes(obj))
                .filter((obj) => obj !== null)

            const uniqueObjectsSet: Set<string> = new Set(allSelected.map((obj) => JSON.stringify(obj)))

            return Array.from(uniqueObjectsSet).map((str) => JSON.parse(str))
        }

        const allSelectedCombined: MultiSelectObjectDO[] = getSelectedOptions()

        const toggleOption = (option: MultiSelectObjectDO) => {
            const isAlreadySelected = allSelectedCombined.find((opt) => option.label === opt.label && option.value === opt.value)

            if (isAlreadySelected) {
                const filtered = [...allSelectedCombined].filter((opt) => !(opt.value === option.value && opt.label === option.label))

                dispatch(
                    removeFromDefaultSelectedOptions({
                        code,
                        fieldName: fld.name,
                        option,
                        rowId,
                    })
                )

                dispatch(
                    updateEditFields({
                        code,
                        field: {
                            id: row['id'],
                            type: fld.type,
                            name: fld.name,
                            value: safeStringify(filtered),
                            rowId,
                        },
                    })
                )
                setDeSelectedOptions((prev) => [...prev, option])
                setSelectedOptions(filtered)
            } else {
                const filtered = [...allSelectedCombined].filter((opt) => opt.value !== option.value || opt.label !== option.label)
                setDeSelectedOptions((prev) => prev.filter((opt) => !(opt.value === option.value && opt.label === option.label)))
                setSelectedOptions([...filtered, option])
            }
        }

        function handler(): void {
            if (allSelectedCombined.length) {
                dispatch(
                    updateEditFields({
                        code,
                        field: {
                            id: row['id'],
                            type: fld.type,
                            name: fld.name,
                            value: safeStringify(allSelectedCombined),
                            rowId,
                        },
                    })
                )
            }
        }

        function isSelected(idx: number): boolean {
            return allSelectedCombined.some((option) => isEqual(option, optionsForTheField ? optionsForTheField[idx] : {}))
        }

        function isEqual(option1: MultiSelectObjectDO, option2: MultiSelectObjectDO): boolean {
            return option1.label === option2.label && option1.value === option2.value
        }

        useEffect(() => {
            const handleFirstMouseDown = (event: MouseEvent) => {
                const clickPosition = event.clientY
                const viewportHeight = window.innerHeight
                const threshold = 350
                const distanceFromBottom = viewportHeight - clickPosition

                if (distanceFromBottom <= threshold) {
                    setPlacement('top')
                    setPlacement2('left-end')
                } else {
                    setPlacement('bottom')
                    setPlacement2('left-start')
                }

                window.removeEventListener('mousedown', handleFirstMouseDown)
            }
            window.addEventListener('mousedown', handleFirstMouseDown)

            return () => {
                window.removeEventListener('mousedown', handleFirstMouseDown)
            }
        }, [])

        return (
            <>
                <ElementWithPopover
                    trigger={['click']}
                    placement={placement}
                    element={
                        <div className="d-flex align-items-center justify-content-between multiple-select">
                            <div className="selected-options w-100">
                                {allSelectedCombined.length > 0 && (
                                    <ElementWithPopover
                                        placement={placement2}
                                        element={<div className={'num-of-selected'}>{allSelectedCombined.length} selected</div>}
                                        popover={
                                            <div>
                                                {allSelectedCombined.map((option) => (
                                                    <div key={option.value} className=" selected-option">
                                                        {option.value}
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    />
                                )}
                            </div>
                            <i className="fa-solid fa-angle-down caret-icon" />
                        </div>
                    }
                    popover={
                        <div className={' d-flex align-items-center justify-content-start'}>
                            <div
                                className={'options'}
                                style={{
                                    width: 'auto',
                                    minHeight: 100,
                                    maxHeight: 300,
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                }}
                                onMouseLeave={handler}
                            >
                                {options.map((option, idx) => (
                                    <div
                                        key={idx}
                                        onClick={() => toggleOption(optionsForTheField ? optionsForTheField[idx] : {})}
                                        className={'option d-flex align-items-center justify-content-start'}
                                    >
                                        <div style={{ minWidth: 20 }} className={''}>
                                            {isSelected(idx) && <i className={'fa-solid fa-check selected-icon'} />}
                                        </div>
                                        <div>{option}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                />
            </>
        )
    }
)

export default MultiSelectEditInput
