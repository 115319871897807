import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface MasterCardActionState {
    [id: string]: CardActionOpenState
}

export interface CardActionOpenState {
    code: string
    opened: boolean
    bespokePage: string | undefined
    route: string | undefined

    rowData?: any | undefined
    properties?: any | undefined
}

export interface CardActionInitState {
    code: string
}

const initialState: MasterCardActionState = {}

export interface CardActionOpenPayloadAction {
    code: string
    opened: boolean
    bespokePage: string | undefined
    route: string | undefined
    rowData?: any
    properties?: any
}

export interface CardActionCleanUpPayload {
    code: string
}

const loadInitValues = (values: CardActionInitState): CardActionOpenState => {
    return {
        code: values.code,
        opened: false,
        bespokePage: undefined,
        route: undefined,
    }
}

const createDefault = (code: string): CardActionOpenState => {
    return {
        code: code,
        opened: false,
        bespokePage: undefined,
        route: undefined,
    }
}
const getState = (state: MasterCardActionState, code: string): CardActionOpenState => {
    let entry: CardActionOpenState = state[code]
    if (!entry) {
        entry = createDefault(code)
    }
    return entry
}

export const cardActionSlice = createSlice({
    name: 'cardActionSlice',
    initialState,
    reducers: {
        initCardPanel(state, action: PayloadAction<CardActionInitState>) {
            const entry: CardActionOpenState = getState(state, action.payload.code)
            if (!entry) {
                state[action.payload.code] = loadInitValues(action.payload)
            }
        },
        openCardPanel(state, action: PayloadAction<CardActionOpenPayloadAction>) {
            const entry: CardActionOpenState = getState(state, action.payload.code)
            entry.opened = true
            entry.bespokePage = action.payload.bespokePage
            entry.route = action.payload.route
            entry.rowData = action.payload.rowData
            entry.properties = action.payload.properties

            state[action.payload.code] = entry
        },
        panelClosed(state, action: PayloadAction<CardActionCleanUpPayload>) {
            if (action.payload.code === '') {
                Object.keys(state).forEach((key) => {
                    delete state[key]
                })
                state = {}
            } else {
                const entry: CardActionOpenState = getState(state, action.payload.code)
                entry.opened = false
                entry.bespokePage = undefined
                entry.route = undefined
                entry.rowData = undefined
                entry.properties = undefined
                state[action.payload.code] = entry
            }
        },
        cleanUpCardPanel(state, action: PayloadAction<CardActionInitState>) {
            delete state[action.payload.code]
        },
    },
})

export const { initCardPanel, openCardPanel, panelClosed, cleanUpCardPanel } = cardActionSlice.actions

export default cardActionSlice.reducer
