import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// @ts-ignore
import googleLogo from '../../assets/icons/google.svg'
import { environmentService } from '../../providers/environment/EnvironmentService'
import { guslStorage } from '../../providers/session/GuslStorage'
import { SessionContext } from '../../providers/session/SessionContext'
import { SignInResponseDTO } from '../../providers/session/types'
import { log } from '../../services/LogService'
import useGoogleLogin from './google/use-google-login'
import { IError } from './Login'
import { LoginButtonStyled, LoginButtonTextStyled } from './styled'
import { LoginProps } from './types'

const LoginGoogleSSO = (props: LoginProps): React.ReactElement => {
    const sessionContext = React.useContext(SessionContext)
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const refreshTokenSetup = (res: any) => {
        log.debug('Login', 'MSG001', ' refreshTokenSetup res', res)
        // Timing to renew access token
        let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000

        const refreshToken = async () => {
            log.debug('Login', 'MSG002', 'refreshToken: refreshing')
            const newAuthRes = await res.reloadAuthResponse()
            refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000
            log.debug('Login', 'MSG003', 'newAuthRes', newAuthRes)
            // saveUserToken(newAuthRes.access_token);  <-- save new token
            localStorage.setItem('authToken', newAuthRes.id_token)
            sessionContext
                .loginWithGoogleSsoToken(newAuthRes.id_token)
                /* eslint-disable @typescript-eslint/no-unused-vars */
                // .then((response: SignInResponseDTO) => {
                .then(() => {
                    log.debug('Login', 'MSG004', 'user logged in')
                })
            // Set up the other timer after the first one
            setTimeout(refreshToken, refreshTiming)
        }

        // Setup first refresh timer
        setTimeout(refreshToken, refreshTiming)
    }

    const onSuccess = (res: any) => {
        log.debug('Login', 'MSG005', 'Login Success: currentUser:', res)
        guslStorage.setGoogleToken(res.tokenId)
        sessionContext
            .loginWithGoogleSsoToken(res.tokenId)
            .then((response: SignInResponseDTO) => {
                log.debug('Login', 'MSG006', 'Login response:', response)
                // if (!response.ok) {
                //     log.error('Login', 'MSG007', 'Error in Google SSO login (' + (response.messages ? response.messages[0] : '') + ')');
                //     setErrorMessage('Error in Google SSO login (' + (response.messages ? response.messages[0] : '') + ')');
                // } else {
                // all good
                log.debug('Login', 'MSG008', 'User logged in', response)
                // sessionManager.setAvatar(res.);
                navigate('/' + (environmentService.getEnvironment()?.homePage || ''))
                // }
            })
            .catch((error: any) => {
                setErrorMessage('Error in Google SSO login (' + error + ')')
            })

        refreshTokenSetup(res)
    }

    const onFailure = (error: IError) => {
        console.log('Login failed: res:', error)
        setErrorMessage(error.details)
    }

    const onRequest = () => {
        setErrorMessage('')
    }

    // @ts-ignore
    const { signIn } = useGoogleLogin({
        onSuccess,
        onFailure,
        clientId: environmentService.getGoogleSSOClientId(),
        isSignedIn: true,
        accessType: 'offline',
        onRequest,
    })

    const renderErrorMessage = (): React.ReactElement => {
        if (errorMessage) {
            return (
                <div className="p-grid p-fluid error-container">
                    <div className="p-col-12 p-tag p-tag-danger error-text">{errorMessage}</div>
                </div>
            )
        }
        return <></>
    }

    return (
        <LoginButtonTextStyled className="details-container">
            {/*{props.environment?.loginUiProperties?.loginSmallLogo && <div className="p-col-12">*/}
            {/*    <img src={props.environment?.loginUiProperties?.loginSmallLogo} alt="logo"*/}
            {/*         className="small-login-logo"/>*/}
            {/*</div>}*/}
            <LoginButtonStyled onClick={signIn} className="button-login">
                <img src={googleLogo} alt="google login" className="icon small-login-logo" />
                <LoginButtonTextStyled>Sign in with Google</LoginButtonTextStyled>
            </LoginButtonStyled>
            {renderErrorMessage()}
            <div className="p-col-12"></div>
        </LoginButtonTextStyled>
    )
}

export default LoginGoogleSSO
