import { useEffect } from 'react'
import { Subscription } from 'rxjs'
import { ExtraFieldProperties } from '../components/common/money/types'
import {
    ActionConfigDTO,
    ChildReference,
    FieldConfigDTO,
    FieldProperties,
    FormMode,
    GuslErrors,
    IMenuDTO,
    MatchQueryDTO,
    MediaType,
    OrderByActionDO,
    QueryParamsDTO,
    TableControlsDTO,
    WidgetPanelProperties,
} from '../components/types'
import { log } from '../services/LogService'
import { arrayIsEmpty, isBlank, isDefined, isObject, notDefined } from './TypeCheckers'

// eslint-disable-next-line react-hooks/exhaustive-deps
export const RunOnceEffect = (fun: () => void) => useEffect(fun, [])

export const assignReferences = (
    reference: ChildReference,
    onFormModeChange: (mode: FormMode) => void,
    onRefresh: () => void,
    doValidation: (fieldValue: any) => boolean,
    onFormSaved?: (rowData: any) => void
) => {
    reference.changeMode = onFormModeChange
    reference.doRefresh = onRefresh
    reference.doValidation = doValidation
    reference.formSaved = onFormSaved
    reference.register(reference)
}

export const safeStream = (array: any[] | undefined | null): any[] => {
    return array || []
}
export const clone = (value: any): any => {
    try {
        return JSON.parse(JSON.stringify(value || {}))
    } catch (error) {
        console.error('Error cloning', error)
        return {}
    }
}

export const compare = (a: number | undefined, b: number | undefined): number => {
    if (!a && !b) {
        return 0
    } else if (!a) {
        return -1
    } else if (!b) {
        return 1
    } else {
        return a - b
    }
}

export const compareString = (a: string | undefined, b: string | undefined): number => {
    // @ts-ignore
    if (a > b) {
        return 1
        // @ts-ignore
    } else if (b > a) {
        return -1
    } else {
        return 0
    }
}

export const extractExtraFieldProperties = (properties: FieldProperties): ExtraFieldProperties => {
    const extraProperties: ExtraFieldProperties = {
        amount: properties?.data,
        showCurrency: false,
        defaultZero: false,
        colorise: false,
        flip: false,
        withSign: false,
        withNegative: false,
        zeroAsDash: false,
        zeroAsSpace: false,
        showOddsType: false,
        showLogo: false,
        route: '',
        queryParams: { skip: 0, limit: -1 },
        cols: 120,
        rows: 6,
        lookupModalCreateCode: undefined,
        // decimalPlaces: 0
    }
    try {
        if (isBlank(properties.fieldConfig?.properties || '')) {
            return extraProperties
        }
        const fldProperties: { [id: string]: any } = JSON.parse(properties.fieldConfig?.properties || '')
        for (const origKey in fldProperties) {
            if (fldProperties.hasOwnProperty(origKey)) {
                // @ts-ignore
                extraProperties[origKey] = fldProperties[origKey]
            }
        }
    } catch (error) {
        console.error('====>[', properties.fieldConfig.name, '[ [', properties.fieldConfig?.properties, ']')
        log.error('extractExtraFieldProperties', 'MSG001', properties.fieldConfig?.properties, error)
    }

    return extraProperties
}

export const createRouteWithQueryParams = (rowData: any, extraFieldProperties: ExtraFieldProperties): string => {
    let route = ''
    if (!extraFieldProperties.route) {
        return route
    }
    try {
        substituteMatchQuery(rowData, extraFieldProperties)

        route = '/' + extraFieldProperties.route + '?queryParams=' + JSON.stringify(extraFieldProperties.queryParams)
    } catch (e) {
        console.error('====> error [', extraFieldProperties, rowData, e, ']')
        route = ''
    }

    return route
}

export const substituteMatchQuery = (rowData: any, extraFieldProperties: ExtraFieldProperties): void => {
    let matchQuery: MatchQueryDTO

    if (extraFieldProperties?.queryParams?.musts) {
        for (matchQuery of extraFieldProperties?.queryParams?.musts) {
            matchQuery.value = rowData[matchQuery.field]
        }
    }

    if (extraFieldProperties?.queryParams?.mustNots) {
        for (matchQuery of extraFieldProperties?.queryParams?.mustNots) {
            matchQuery.value = rowData[matchQuery.field]
        }
    }

    if (extraFieldProperties?.queryParams?.should) {
        for (matchQuery of extraFieldProperties?.queryParams?.should) {
            matchQuery.value = rowData[matchQuery.field]
        }
    }
}

export const getCssValue = (cssVariable: string): string => {
    const docStyle = getComputedStyle(document.documentElement)
    if (docStyle) {
        return docStyle.getPropertyValue(cssVariable)
    }
    return ''
}

export const canColorise = (fieldConfig: FieldConfigDTO): boolean => {
    if (notDefined(fieldConfig)) {
        return false
    }
    try {
        const properties = JSON.parse(fieldConfig.properties)
        return properties?.colorise || false
    } catch (error) {
        return false
    }
}

export const canColoriseText = (fieldConfig: FieldConfigDTO): boolean => {
    if (notDefined(fieldConfig)) {
        return false
    }
    try {
        const properties = JSON.parse(fieldConfig.properties)
        return properties?.coloriseText || false
    } catch (error) {
        return false
    }
}

export const noop = () => {}

export const getErrorMessage = (error: any): string => {
    // console.log('error', error)

    if (notDefined(error)) {
        return 'ERR001 Server Error'
    }

    let guslErrors: GuslErrors | undefined
    if (isDefined(error?.errors)) {
        guslErrors = error
    } else {
        if (notDefined(error.data)) {
            console.log('ERR002 Server Error', error.data)
            return 'ERR002 Server Error'
        } else {
            guslErrors = error.data
        }
    }
    console.log('guslErrors', guslErrors)

    if (!guslErrors) {
        console.log('ERR003 Server Error', error.data)
        return 'ERR003 Server Error'
    }
    if (arrayIsEmpty(guslErrors?.errors)) {
        console.log('ERR004 Server Error', guslErrors)
        return 'ERR004 Server Error'
    }

    let errorMessage = guslErrors?.errors[0].message || 'Server Error'

    for (let x = 0; x < 5; x++) {
        if (errorMessage.indexOf('{' + x + '}') >= 0) {
            if (guslErrors.errors[0]?.parameters.length > x) {
                errorMessage = errorMessage.replace('{' + x + '}', guslErrors.errors[0].parameters[x])
            } else {
                return errorMessage
            }
        } else {
            return errorMessage
        }
    }
    return errorMessage
}

export const getErrorMessageKey = (error: any): string => {
    if (notDefined(error.data)) {
        return 'server.error'
    }

    let guslErrors: GuslErrors = error.data

    if (notDefined(guslErrors)) {
        return 'server.error'
    }
    if (arrayIsEmpty(guslErrors?.errors)) {
        return 'server.error'
    }
    let messageKey = guslErrors.errors[0].messageKey || 'server.error'

    for (let x = 0; x < 5; x++) {
        if (messageKey.indexOf('{' + x + '}') >= 0) {
            if (guslErrors.errors[0].parameters.length > x) {
                messageKey = messageKey.replace('{0}', guslErrors.errors[0].messageKey)
            } else {
                return messageKey
            }
        } else {
            return messageKey
        }
    }
    return messageKey
}

export interface IMountedMonitor {
    getValue: () => boolean
    isMounted: (logging?: boolean) => boolean
}

export const MountedMonitor = (className?: string, logging?: boolean): IMountedMonitor => {
    let mounted = false
    RunOnceEffect(() => {
        mounted = true
        if (className && logging) {
            log.info(className, 'MON001', 'Mounted')
        }
        return () => {
            mounted = false
            if (className && logging) {
                log.info(className, 'MON002', 'Unmounted')
            }
        }
    })
    return {
        getValue: () => {
            return mounted
        },
        isMounted: (logging?: boolean) => {
            if (logging) {
                log.info(className || '', 'MON003', 'Mounted:', mounted)
            }
            return mounted
        },
    }
}

export const unSubscribe = (subscription?: Subscription) => {
    try {
        if (subscription) {
            subscription.unsubscribe()
        }
    } catch (error) {
        log.error('unSubscribe', 'ERR001', 'Unsubscribed failed', error)
    }
}

export const cancelAbortController = (abortController: AbortController | undefined) => {
    try {
        if (abortController) {
            abortController.abort()
        }
    } catch (error) {
        log.error('cancelAbortController', 'ERR001', 'Abort failed', error)
    }
}

/*
    At let the Voodoo commence
 */
export const parseFunction = (func: String) => {
    let regEx = /function *\(([^()]*)\)[ \n\t]*{(.*)}/gim
    let match = regEx.exec(func.replace(/\n/g, ' '))

    if (match) {
        // new Function params ..., body
        // "function (a, b) { return a + b; }"

        /* eslint-disable no-new-func */
        return new Function(...match[1].split(','), match[2])
    }

    return null
}

export const replaceSpaceWithDash = (label?: string | undefined): string => {
    return (label || '').replace(/ /g, '-')
}

export const constructLabel = (label: string, data: any): string => {
    return constructUrl(label, data)
}

export const buildPathFromRef = (fieldConfig: FieldConfigDTO, rowData: any): string | undefined => {
    if (!fieldConfig?.ref) {
        return undefined
    }
    let path = '/'

    if (fieldConfig?.ref?.code) {
        if (fieldConfig?.ref?.code.indexOf('{') >= 0) {
            path += constructUrl(fieldConfig?.ref?.code, rowData)
        } else {
            path += fieldConfig?.ref?.code
        }
    }
    if (fieldConfig?.ref?.entity) {
        path += '/' + constructUrl(fieldConfig?.ref?.entity, rowData)
    }
    if (fieldConfig?.ref?.tab) {
        if (fieldConfig?.ref?.tab.indexOf('{') >= 0) {
            path += '/' + constructUrl(fieldConfig?.ref?.tab, rowData)
        } else {
            path += '/' + fieldConfig?.ref?.tab
        }
    }
    return path
}
export const constructUrl = (baseUrl: string, data: any | undefined, rowData?: any): string => {
    let url = baseUrl

    let regex = /{([^}]+)}/g

    let result: RegExpExecArray | null
    /* eslint-disable no-cond-assign */
    while ((result = regex.exec(baseUrl))) {
        const idx: number = result[1].indexOf('.')
        const key = result[1]
        if (idx > 0) {
            const parent = key.substring(0, idx)
            const child = key.substring(idx + 1)
            if (parent && child && data && !!data[parent]) {
                url = url.replace(`{${key}}`, encodeURIComponent(data[parent][child]))
            }
            if (parent && child && rowData && !!rowData[parent]) {
                url = url.replace(`{${key}}`, encodeURIComponent(rowData[parent][child]))
            }
        } else {
            if (data && !!data[key]) {
                url = url.replace(`{${key}}`, encodeURIComponent(data[key]))
            }
            if (rowData && !!rowData[key]) {
                url = url.replace(`{${key}}`, encodeURIComponent(rowData[key]))
            }
        }
    }

    // if (data) {
    //     Object.keys(data)
    //         .filter(k => !!data[k])
    //         .forEach(k => {
    //             const idx = k.indexOf(".")
    //             if (idx > 0) {
    //                 url = url.replace(`{${k}}`, encodeURIComponent(data[k.substring(0, idx)][k.substring(idx)]));
    //
    //             } else {
    //                 url = url.replace(`{${k}}`, encodeURIComponent(data[k]));
    //             }
    //         });
    // }
    // if (rowData) {
    //     Object.keys(rowData)
    //         .filter(k => !!rowData[k])
    //         .forEach(k => {
    //             const idx = k.indexOf(".")
    //             if (idx > 0) {
    //                 url = url.replace(`{${k}}`, encodeURIComponent(rowData[k.substring(0, idx)][k.substring(idx)]));
    //             } else {
    //                 url = url.replace(`{${k}}`, encodeURIComponent(rowData[k]));
    //             }
    //         });
    // }
    return url
}

export const substituteValues = (baseUrl: string, data: any | undefined, rowData?: any): string => {
    let url = baseUrl
    if (data) {
        Object.keys(data)
            .filter((k) => !!data[k])
            .forEach((k) => {
                url = url.replace(`{${k}}`, data[k])
            })
    }
    if (rowData) {
        Object.keys(rowData)
            .filter((k) => !!rowData[k])
            .forEach((k) => {
                url = url.replace(`{${k}}`, rowData[k])
            })
    }
    return url
}

export const isNumber = (str: string | undefined): boolean => {
    if (!str) {
        return false
    }
    if (typeof str !== 'string') {
        return false
    }

    if (str.trim() === '') {
        return false
    }

    return !Number.isNaN(Number(str))
}

const isRegExp = (re: any) => {
    return re instanceof RegExp
}
const escapeRegExp = (val: string) => {
    let reRegExpChar = /[\\^$.*+?()[\]{}|]/g,
        reHasRegExpChar = RegExp(reRegExpChar.source)

    return val && reHasRegExpChar.test(val) ? val.replace(reRegExpChar, '\\$&') : val
}
const isString = (value: any) => {
    return typeof value === 'string'
}
/* eslint-disable @typescript-eslint/no-unused-vars */
const flatten = (array: any[]) => {
    let newArray: any[] = []

    array.forEach(function (item) {
        if (Array.isArray(item)) {
            newArray = newArray.concat(item)
        } else {
            newArray.push(item)
        }
    })

    return newArray
}

export const replaceStringWithElement = (str: string, match: string | RegExp, fn: Function) => {
    let curCharStart = 0
    let curCharLen = 0

    if (str === '') {
        return str
    } else if (!str || !isString(str)) {
        throw new TypeError('First argument to react-string-replace#replaceString must be a string')
    }

    let re = match

    if (!isRegExp(re)) {
        // @ts-ignore
        re = new RegExp('(' + escapeRegExp(re) + ')', 'gi')
    }

    let result = str.split(re)

    // Apply fn to all odd elements
    for (let i = 1, length = result.length; i < length; i += 2) {
        /** @see {@link https://github.com/iansinnott/react-string-replace/issues/74} */
        if (result[i] === undefined || result[i - 1] === undefined) {
            console.warn(
                'reactStringReplace: Encountered undefined value during string replacement. Your RegExp may not be working the way you expect.'
            )
            continue
        }

        curCharLen = result[i].length
        curCharStart += result[i - 1].length
        result[i] = fn(result[i], i, curCharStart)
        curCharStart += curCharLen
    }

    return result
}

export const addImageSuffix = (logoUrl?: string, suffix?: string): string | undefined => {
    if (!suffix || !logoUrl) {
        return logoUrl
    }
    const period = logoUrl.lastIndexOf('.')
    if (period < 0) {
        return logoUrl + '-' + suffix
    }
    return logoUrl.substring(0, period) + '-' + suffix + logoUrl.substring(period)
}

export const areMenusMediaTypeSensitive = (menus: IMenuDTO[]): boolean => {
    let allMatch: boolean = true
    menus.forEach((menu) => {
        if (menu.mediaType && menu.mediaType !== MediaType.Laptop) {
            allMatch = false
        }
    })
    return !allMatch
}

export const areFieldsMediaTypeSensitive = (fields: FieldConfigDTO[]): boolean => {
    let allMatch: boolean = true
    fields.forEach((fld) => {
        if (fld.mediaType !== MediaType.Laptop) {
            // <-- default now laptop
            allMatch = false
        }
    })
    return !allMatch
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export const areActionsMediaTypeSensitive = (actions: ActionConfigDTO[], widgetPanelProperties?: WidgetPanelProperties): boolean => {
    let allMatch: boolean = true
    actions.forEach((action) => {
        if (action.mediaType !== MediaType.Laptop) {
            allMatch = false
        }
    })
    return !allMatch
}

export const areOrderByActionsMediaTypeSensitive = (actions: OrderByActionDO[]): boolean => {
    let allMatch: boolean = true
    actions.forEach((action) => {
        if (action.mediaType !== MediaType.Laptop) {
            allMatch = false
        }
    })
    return !allMatch
}

export const matchMediaTypeWithField = (field: FieldConfigDTO, mediaType: MediaType, mediaTypeSensitive: boolean): boolean => {
    return matchMediaTypeWithRequiredAndSensitivity(field.mediaType, mediaType, mediaTypeSensitive)
}
export const matchMediaTypeWithAction = (action: ActionConfigDTO, mediaType: MediaType, mediaTypeSensitive: boolean = false): boolean => {
    return matchMediaTypeWithRequiredAndSensitivity(action.mediaType, mediaType, mediaTypeSensitive)
}

export const matchMediaTypeWithOrderByAction = (
    action: OrderByActionDO,
    mediaType: MediaType,
    mediaTypeSensitive: boolean = false
): boolean => {
    return matchMediaTypeWithRequiredAndSensitivity(action.mediaType, mediaType, mediaTypeSensitive)
}

export const matchMediaTypeWithRequired = (requiredMediaType: MediaType | undefined, currentMediaType: MediaType): boolean => {
    return matchMediaTypeWithRequiredAndSensitivity(requiredMediaType, currentMediaType, true)
}

export const haveChildrenWithMediaTypeWithRequiredAndSensitivity = (
    menuItem: IMenuDTO,
    currentMediaType: MediaType,
    mediaTypeSensitive: boolean
): boolean => {
    if (!menuItem || arrayIsEmpty(menuItem.menuItems)) {
        return false
    }
    return countChildrenWithMediaTypeWithRequiredAndSensitivity(menuItem, currentMediaType, mediaTypeSensitive) > 0
}

export const countChildrenWithMediaTypeWithRequiredAndSensitivity = (
    menuItem: IMenuDTO,
    currentMediaType: MediaType,
    mediaTypeSensitive: boolean
): number => {
    let count = 0
    safeStream(menuItem.menuItems).forEach((item) => {
        if (matchMediaTypeWithRequiredAndSensitivity(item.mediaType, currentMediaType, mediaTypeSensitive)) {
            count++
        }
    })
    return count
}

export const matchMediaTypeWithRequiredAndSensitivity = (
    requiredMediaType: MediaType | undefined,
    currentMediaType: MediaType,
    mediaTypeSensitive: boolean
): boolean => {
    if (!mediaTypeSensitive) {
        return true
    }
    if (!requiredMediaType || !currentMediaType) {
        return true
    }
    if (requiredMediaType === MediaType.NotMobileOrTablet || requiredMediaType === MediaType.Never) {
        return false
    }

    if (currentMediaType === MediaType.Mobile) {
        return (
            requiredMediaType === MediaType.Mobile ||
            requiredMediaType === MediaType.MobileOnly ||
            requiredMediaType === MediaType.MobileAndTabletOnly
        )
    } else if (currentMediaType === MediaType.Tablet) {
        return (
            requiredMediaType === MediaType.Mobile ||
            requiredMediaType === MediaType.Tablet ||
            requiredMediaType === MediaType.TabletOnly ||
            requiredMediaType === MediaType.MobileAndTabletOnly
        )
    } else if (currentMediaType === MediaType.Laptop) {
        return (
            requiredMediaType === MediaType.Mobile ||
            requiredMediaType === MediaType.Tablet ||
            requiredMediaType === MediaType.Laptop ||
            requiredMediaType === MediaType.LaptopOnly
        )
    } else if (currentMediaType === MediaType.Desktop) {
        return (
            requiredMediaType === MediaType.Mobile ||
            requiredMediaType === MediaType.Tablet ||
            requiredMediaType === MediaType.Laptop ||
            requiredMediaType === MediaType.Desktop ||
            requiredMediaType === MediaType.DesktopOnly
        )
    } else if (currentMediaType === MediaType.ExtraLarge) {
        return (
            requiredMediaType === MediaType.Mobile ||
            requiredMediaType === MediaType.Tablet ||
            requiredMediaType === MediaType.Laptop ||
            requiredMediaType === MediaType.Desktop ||
            requiredMediaType === MediaType.ExtraLarge ||
            requiredMediaType === MediaType.ExtraLargeOnly
        )
    }
    return false
}

export const canShowPagination = (currentMediaType: MediaType, tableControl: TableControlsDTO): boolean => {
    return matchMediaTypeWithRequired(tableControl.pagination, currentMediaType)
}

export const canShowColumnSettings = (currentMediaType: MediaType, tableControl: TableControlsDTO): boolean => {
    return matchMediaTypeWithRequired(tableControl.columnSettings, currentMediaType)
}

export const canShowFilters = (currentMediaType: MediaType, tableControl: TableControlsDTO): boolean => {
    return matchMediaTypeWithRequired(tableControl.filters, currentMediaType)
}

export const canShowRefresh = (currentMediaType: MediaType, tableControl: TableControlsDTO): boolean => {
    return matchMediaTypeWithRequired(tableControl.refresh, currentMediaType)
}

export const canShowResize = (currentMediaType: MediaType, tableControl: TableControlsDTO): boolean => {
    return matchMediaTypeWithRequired(tableControl.resize, currentMediaType)
}

export const canShowSearch = (currentMediaType: MediaType, tableControl: TableControlsDTO): boolean => {
    return matchMediaTypeWithRequired(tableControl.search, currentMediaType)
}

export const getTableControls = (
    currentMediaType: MediaType,
    tableControl: TableControlsDTO
): [boolean, boolean, boolean, boolean, boolean, boolean, boolean] => {
    const showPagination = matchMediaTypeWithRequired(tableControl.pagination, currentMediaType)
    const showColumnSettings = matchMediaTypeWithRequired(tableControl.columnSettings, currentMediaType)
    const showFilter = matchMediaTypeWithRequired(tableControl.filters, currentMediaType)
    const showRefresh = matchMediaTypeWithRequired(tableControl.refresh, currentMediaType)
    const showResize = matchMediaTypeWithRequired(tableControl.resize, currentMediaType)
    const showSearch = matchMediaTypeWithRequired(tableControl.search, currentMediaType)
    const infinityScroll: boolean = tableControl?.infinityScroll || false
    return [showPagination, showColumnSettings, showFilter, showRefresh, showResize, showSearch, infinityScroll]
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export const groupListByKey = (list: any[], key: string, orderBy?: string): { [id: string]: any[] } => {
    return list.reduce(function (rv, x) {
        ;(rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
    }, {})
}

export const parse = (properties: string | undefined): any => {
    if (isBlank(properties)) {
        return {}
    }
    try {
        // @ts-ignore
        return JSON.parse(properties)
    } catch (error) {
        return {}
    }
}

export const splitPath = (path: string | undefined): [string | undefined, string, string | undefined] => {
    if (path) {
        const paths: string[] = path.substring(1).split('/')
        let entity
        let id
        let tab: string | undefined = undefined

        if (paths.length >= 1) {
            entity = paths[0]
        }
        if (paths.length >= 2) {
            id = paths[1]
        }
        if (paths.length >= 3) {
            tab = paths[2]
        }
        if (!id) {
            id = 'ui'
        }
        return [entity, id, tab]
    } else {
        return ['', 'ui', undefined]
    }
}

export const getTimezones = (): string[] => {
    // @ts-ignore
    const timezones: string[] = Intl.supportedValuesOf('timeZone')
    return timezones
}

export const validateWidth = (preferredWidth: string | undefined, maxWidth: number): string => {
    if (!preferredWidth) {
        return '100%'
    }
    if (preferredWidth.indexOf('%') > 0) {
        return preferredWidth
    }
    if (preferredWidth.indexOf('px') > 0) {
        const split = preferredWidth.split('px')
        if (parseInt(split[0]) > maxWidth) {
            return maxWidth - 22 + 'px'
        }
    }
    return preferredWidth
}

export const queryPartsMatch = (matchQuery1: MatchQueryDTO[] | undefined, matchQuery2: MatchQueryDTO[] | undefined): boolean => {
    if (!matchQuery1 && !matchQuery2) {
        return true
    }
    if ((matchQuery1 && matchQuery1.length === 0 && !matchQuery2) || (!matchQuery1 && matchQuery2 && matchQuery2.length === 0)) {
        return true
    }
    // @ts-ignore
    const sorted1: MatchQueryDTO[] =
        matchQuery1?.slice().sort((a: MatchQueryDTO, b: MatchQueryDTO) => compareString(a?.field, b?.field)) || []

    // @ts-ignore
    const sorted2: MatchQueryDTO[] =
        matchQuery2?.slice().sort((a: MatchQueryDTO, b: MatchQueryDTO) => compareString(a?.field, b?.field)) || []
    if (sorted1.length !== sorted2.length) {
        return false
    }
    let fieldsMatch = true
    for (let x = 0; x < sorted1.length; x++) {
        if (sorted1[x].field !== sorted2[x].field) {
            fieldsMatch = false
        }
        if (sorted1[x].value !== sorted2[x].value) {
            fieldsMatch = false
        }
    }
    return fieldsMatch
}

export const doQueryParamsMatch = (params1: QueryParamsDTO, params2: QueryParamsDTO): boolean => {
    if (!params1 && !params2) {
        return true
    }
    let match = true

    if (!queryPartsMatch(params1?.should, params2?.should)) {
        match = false
    }

    if (!queryPartsMatch(params1?.musts, params2?.musts)) {
        match = false
    }

    if (!queryPartsMatch(params1?.mustNots, params2?.mustNots)) {
        match = false
    }
    // console.log('doQueryParamsMatch', match, params1, params2)
    return match
}

export const removeSpaces = (value: string | undefined): string => {
    if (value) {
        return value.replace(' ', '_')
    }
    return ''
}

export const hasDisplayWhenSelected = (innerFields?: FieldConfigDTO[]): boolean => {
    if (!innerFields || innerFields.length === 0) {
        return false
    }
    let hasDisplayWhenSelected: boolean = false
    safeStream(innerFields).forEach((fld) => {
        if (fld.displayWhenSelected) {
            hasDisplayWhenSelected = true
        }
    })
    return hasDisplayWhenSelected
}

/**
 *
 * @param innerFields
 * @param data
 * @return array first is image field, second is concated DisplayWhenSelected fields
 */
export const buildDisplayWhenSelected = (
    innerFields: FieldConfigDTO[] | undefined,
    data: any | undefined
): [string | undefined, string] => {
    if (!data) {
        return [undefined, '']
    }
    if (!isObject(data)) {
        return [undefined, data]
    }
    let output: string = ''
    let imageUrl: string = ''
    safeStream(innerFields)
        .filter((fld: FieldConfigDTO) => fld.displayWhenSelected)
        .slice()
        .sort((a: FieldConfigDTO, b: FieldConfigDTO) => (a?.displayOrder || -1) - (b?.displayOrder || -1))
        .forEach((fld: FieldConfigDTO) => {
            if (data[fld.name]) {
                if (fld.type === 'image') {
                    imageUrl = data[fld.name]
                } else {
                    output += data[fld.name] + ' '
                }
            }
        })
    return [imageUrl, output]
}

export const mapSinglePageFields = (menuItem: IMenuDTO | undefined): { [id: string]: FieldConfigDTO } => {
    const map: { [id: string]: FieldConfigDTO } = {}
    safeStream(menuItem?.singlePage?.rows).map((row) =>
        safeStream(row.columns).map((col) => safeStream(col.fields).map((fld: FieldConfigDTO) => (map[fld.name] = fld)))
    )
    return map
}
const addDateElement = (element: string, label: string, dateFormat: string, options: any, value?: string) => {
    if (dateFormat.indexOf(element) >= 0) {
        options[label] = value || 'numeric'
    }
}

const getDateOptions = (dateFormat: string, timeZone?: string | undefined) => {
    const tz = timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
    const options: any = {
        timeZone: tz,
    }
    addDateElement('yyyy', 'year', dateFormat, options)
    addDateElement('yy', 'year', dateFormat, options) // , '2-digit'
    addDateElement('YYYY', 'year', dateFormat, options)
    addDateElement('YY', 'year', dateFormat, options) // , '2-digit'
    addDateElement('MM', 'month', dateFormat, options)
    addDateElement('MMM', 'month', dateFormat, options, 'short')
    addDateElement('MMMM', 'month', dateFormat, options, 'long')
    addDateElement('DD', 'day', dateFormat, options)
    addDateElement('dd', 'day', dateFormat, options)
    addDateElement('DDD', 'weekday', dateFormat, options, 'short')
    addDateElement('ddd', 'weekday', dateFormat, options, 'short')
    addDateElement('hh', 'hour', dateFormat, options)
    addDateElement('HH', 'hour', dateFormat, options)
    addDateElement('mm', 'minute', dateFormat, options)
    addDateElement('ss', 'second', dateFormat, options)

    return options
}
export const getDateFormat = (dateFormat: string | undefined, timeZone?: string | undefined, debug?: boolean): string => {
    const dateStr = dateFormat || 'dd/MM/yyyy'
    const tz = timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
    const options: any = getDateOptions(dateStr, tz)

    // ok, so this is a hack - could not find a way of extracting DD/MM/YYYY from locale
    const year = 2222
    const month = 12
    const day = 15
    const hour = 14
    const minute = 13
    const second = 11

    // DD-MM-YYYY HH:mm:ss
    const date = new Date(year, month - 1, day, hour, minute, second)
    const formattedDate = new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale, options).format(date)
    // formatToTimeZone
    const retVal = formattedDate
        .replace(',', '')
        .replace(`${year}`, 'yyyy')
        .replace(`${year}`, 'yy') // yes
        .replace(`${month}`, 'MM')
        .replace(`${month}`, 'MMM')
        .replace(`Sun`, 'eeee')
        .replace(`Dec`, 'MMM')
        .replace(`${day}`, 'dd')
        .replace(`${hour}`, 'HH')
        .replace(`${minute}`, 'mm')
        .replace(`${second}`, 'ss')
    if (debug) {
        console.log('formattedDate ->', dateStr, date, options, retVal)
    }

    return retVal
}

export const formatDate = (date: Date, dateFormat: string | undefined, timeZone?: string | undefined) => {
    const dateStr = dateFormat || 'DD/MM/YYY'
    // const dateStr = 'ddd DD MMM HH:mm'
    const tz = timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
    const options: any = getDateOptions(dateStr, tz)

    try {
        // 'en-US'
        // This bloody thing doesn't understand millis, there is fractionalSeconds, but not at the release that we are using
        let retVal: string = new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale, options).format(date)

        if (dateStr.indexOf('.SSS') >= 0) {
            retVal = retVal + '.' + date.getMilliseconds()
        }

        // @ts-ignore
        return retVal.replaceAll(',', '')
    } catch (error) {
        // console.log('error',error)
        return ''
    }
}
