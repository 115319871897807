import React from 'react'
import { environmentService } from '../../../providers/environment/EnvironmentService'
// import {useHistory} from 'react-router-dom'
import { ModalContainerStyled, ModalWrapperStyled } from './styled'
import { ModalProps } from './types'

/*
 * Inspired on:
 *  https://blog.logrocket.com/building-a-modal-module-for-react-with-react-router/
 *  https://reactrouter.com/web/example/modal-gallery
 */
// const Modal = (props: ModalProps): React.ReactElement => {

const GuslModal: React.FC<ModalProps> = ({ extraClassName, dismissible = true, children }) => {
    // const history = useHistory()

    const onCloseModalHandler = (event: React.MouseEvent<HTMLElement>) => event.stopPropagation()

    const onModalWrapperClick = (event: React.MouseEvent<HTMLElement>) => dismissible // && history.goBack()

    // @ts-ignore
    return (
        <ModalWrapperStyled
            onClick={onModalWrapperClick}
            $extraClassName={extraClassName}
            id={'modal_wrapper'}
            noModal={environmentService.getEnvironment()?.loginUiProperties?.noModal}
            coverUrl={environmentService.getEnvironment()?.coverUrl}
        >
            <ModalContainerStyled
                onClick={onCloseModalHandler}
                $extraClassName={extraClassName}
                id={'modal_container'}
                noModal={environmentService.getEnvironment()?.loginUiProperties?.noModal}
            >
                {children}
            </ModalContainerStyled>
        </ModalWrapperStyled>
    )
}

export default GuslModal
