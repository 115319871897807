import React from 'react'
import { SvgStyled } from '../../../../styled-icons/styled'
import { IconsStyled } from '../styled'

export const RumourIcon = (): React.ReactElement => {
    return (
        <IconsStyled width={'20px'}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.99981 10.5H16.9998M8.99981 14.5H13.9998M12.3998 3.5C13.4448 3.50065 14.4666 3.80788 15.3386 4.38362C16.2107 4.95936 16.8947 5.77831 17.3058 6.739C18.0018 6.81747 18.6756 7.03225 19.2885 7.37109C19.9015 7.70993 20.4418 8.1662 20.8785 8.71383C21.3151 9.26146 21.6397 9.88974 21.8335 10.5628C22.0274 11.2358 22.0868 11.9405 22.0083 12.6365C21.9298 13.3325 21.7151 14.0062 21.3762 14.6192C21.0374 15.2322 20.5811 15.7725 20.0335 16.2092C19.4858 16.6458 18.8576 16.9704 18.1845 17.1642C17.5115 17.3581 16.8068 17.4175 16.1108 17.339C15.6043 18.235 14.7905 18.9173 13.8199 19.2598C12.8492 19.6022 11.7875 19.5817 10.8308 19.202L6.99981 21.5V18.366C6.60846 18.2755 6.24258 18.0978 5.92949 17.8462C5.61639 17.5946 5.36415 17.2755 5.19159 16.9128C5.01903 16.5501 4.93059 16.153 4.93288 15.7514C4.93517 15.3497 5.02812 14.9537 5.20481 14.593C4.25006 14.0438 3.51326 13.1829 3.11801 12.1548C2.72277 11.1267 2.69314 9.99389 3.03409 8.94653C3.37505 7.89917 4.06584 7.00093 4.99056 6.40252C5.91529 5.80411 7.01769 5.54194 8.11281 5.66C8.60866 4.98976 9.25482 4.44519 9.99937 4.07005C10.7439 3.69491 11.5661 3.49967 12.3998 3.5Z"
                    stroke="#6038F2"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </IconsStyled>
    )
}

export const RightsIssueIcon = (): React.ReactElement => {
    return (
        <IconsStyled width={'20px'}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M20.8758 11.01C20.5844 9.2737 19.7901 7.66106 18.5913 6.37169C17.3925 5.08232 15.8419 4.17286 14.1315 3.75592C12.421 3.33897 10.6258 3.43284 8.96816 4.02592C7.31052 4.619 5.86322 5.68523 4.80547 7.0926C3.74772 8.49997 3.12597 10.1867 3.0172 11.9438C2.90843 13.701 3.31742 15.4515 4.19353 16.9786C5.06964 18.5057 6.37439 19.7423 7.94624 20.5352C9.51809 21.3282 11.288 21.6428 13.0368 21.44M3.59982 9.49996H20.3998M3.59982 15.5H13.4998"
                    stroke="#6038F2"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.4997 3.5C9.81501 6.19961 8.92188 9.31787 8.92188 12.5C8.92188 15.6821 9.81501 18.8004 11.4997 21.5M12.4997 3.5C14.188 6.20536 15.0814 9.33105 15.0777 12.52M20.9997 15.5H18.4997C18.1018 15.5 17.7203 15.658 17.439 15.9393C17.1577 16.2206 16.9997 16.6022 16.9997 17C16.9997 17.3978 17.1577 17.7794 17.439 18.0607C17.7203 18.342 18.1018 18.5 18.4997 18.5H19.4997C19.8975 18.5 20.279 18.658 20.5603 18.9393C20.8416 19.2206 20.9997 19.6022 20.9997 20C20.9997 20.3978 20.8416 20.7794 20.5603 21.0607C20.279 21.342 19.8975 21.5 19.4997 21.5H16.9997M18.9997 21.5V22.5M18.9997 14.5V15.5"
                    stroke="#6038F2"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </IconsStyled>
    )
}

export const SpinOffIcon = (): React.ReactElement => {
    return (
        <IconsStyled width={'20px'}>
            <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 11H11L7.5 6H1M19 1H11L7.505 6" stroke="#6038F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </IconsStyled>
    )
}
export const RegulationIcon = (): React.ReactElement => {
    return (
        <IconsStyled width={'20px'}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6 9L10 13L17.407 20.385C17.8024 20.7783 18.3373 20.999 18.895 20.999C19.4527 20.999 19.9876 20.7783 20.383 20.385C21.206 19.566 21.206 18.238 20.383 17.418L13 10L9 6M3 21H10"
                    stroke="#6038F2"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.79285 15.793L3.20685 12.207C3.01938 12.0194 2.91406 11.7651 2.91406 11.5C2.91406 11.2348 3.01938 10.9805 3.20685 10.793L5.49985 8.49997L5.99985 8.99997L8.99985 5.99997L8.49985 5.49997L10.7928 3.20697C10.9804 3.0195 11.2347 2.91418 11.4998 2.91418C11.765 2.91418 12.0193 3.0195 12.2068 3.20697L15.7928 6.79297C15.9803 6.9805 16.0856 7.23481 16.0856 7.49997C16.0856 7.76514 15.9803 8.01944 15.7928 8.20697L13.4998 10.5L12.9998 9.99997L9.99985 13L10.4998 13.5L8.20685 15.793C8.01932 15.9804 7.76501 16.0858 7.49985 16.0858C7.23468 16.0858 6.98038 15.9804 6.79285 15.793Z"
                    stroke="#6038F2"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </IconsStyled>
    )
}
