import styled, { ThemeProps } from 'styled-components'

interface IFrameContainerProperties {
    height?: string
}
export const IFrameContainerStyled = styled.div.attrs((props: IFrameContainerProperties) => ({}))<IFrameContainerProperties>`
    max-height: ${(props) => (props.height ? props.height : '600px')};
    ${(props) => (props.height ? 'height: ' + props.height + ';' : false)}
    overflow: auto;
`
export const IFrameScrollStyled = styled.div.attrs((props) => ({}))`
    height: 100%;
    overflow: hidden;
`

interface IFrameProperties extends ThemeProps<any> {
    loaded: boolean
}

export const IframeViewerStyled = styled.iframe.attrs((props: IFrameProperties) => ({
    allowFullScreen: true,
    width: '100%',
    height: '100%',
}))<IFrameProperties>`
    display: ${(props) => (props.loaded ? 'block' : 'none')};
`
