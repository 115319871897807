import { format, parseISO } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { BlastContext } from '../../providers/blast/BlastContext'
import { environmentService } from '../../providers/environment/EnvironmentService'
import { unSubscribe } from '../../utils/Utils'
import { ActiveUsersState, toggleActiveUsers } from '../active-fe-users/activeUsersToggleSlice'
import {
    CloseIconStyled,
    DetailColumnStyled,
    DetailHeaderStyled,
    DetailTableStyled,
    DisplayFeContainerStyled,
    DisplayFeDetailsStyled,
    FeDetailsStyled,
    FeHeaderDetailsStyled,
    FeUsersStyled,
    MessageStyled,
} from '../active-fe-users/styled'
import { ElementWithTooltip } from '../common/element-with/element-with-tooltip/ElementWithTooltip'
import {
    BlastConnectedStyled,
    UserAvatarStyled,
    UserAvatarWrapperStyled,
    UserInitialStyled,
    UserInitialWrapperStyled,
    UsersStyled,
} from './styled'

interface BOUserDetailsDO {
    id: string
    avatar?: string
    username?: string
    nickname?: string
    since?: string
}

export const ActiveUsers = (): React.ReactElement => {
    const blastContext = React.useContext(BlastContext)

    const [showActiveUsers] = useState<boolean>(() => {
        return environmentService.getEnvironment()?.showActiveUsers || false
    })

    const [showAvatar] = useState<boolean>(() => {
        return environmentService.getEnvironment()?.showActiveUserAvatar || false
    })
    const state: ActiveUsersState = useAppSelector((state) => state.activeUsersToggleSlice)
    const dispatch = useAppDispatch()

    const [users, setUsers] = useState<BOUserDetailsDO[]>([])

    const [blastConnected, setBlastConnected] = useState<boolean>(false)

    useEffect(() => {
        const activeUsersSubscription = blastContext.observeCollection('active.users').subscribe((data: any[]) => {
            // log.debug('ActiveUsers', 'MSG001', 'logged in users', data)
            setUsers(data)
        })
        const blastConnectionSubscription = blastContext.observeConnection().subscribe((connected: boolean) => {
            setBlastConnected(connected)
        })
        return () => {
            unSubscribe(activeUsersSubscription)
            unSubscribe(blastConnectionSubscription)
        }
    }, [blastContext])

    const renderDisconnected = (): React.ReactElement => {
        return (
            <>
                <BlastConnectedStyled connected={false}>
                    <div>Blast disconnected</div>
                </BlastConnectedStyled>
            </>
        )
    }

    const displayUserDetails = (): React.ReactElement => {
        if (users.length === 0) {
            return <MessageStyled>No admin users logged in.</MessageStyled>
        }
        return (
            <>
                <DetailTableStyled numberRows={users.length}>
                    <thead>
                        <tr>
                            <DetailHeaderStyled>&nbsp;</DetailHeaderStyled>
                            {/*<DetailHeaderStyled>User</DetailHeaderStyled>*/}
                            <DetailHeaderStyled>Name</DetailHeaderStyled>
                            {/*<DetailHeaderStyled align={'right'}>Since</DetailHeaderStyled>*/}
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => {
                            return (
                                <tr key={user.id}>
                                    <DetailColumnStyled>
                                        <UserInitialWrapperStyled key={user.id}>
                                            <UserInitialStyled>
                                                {user.nickname?.substring(0, 1) || user.username?.substring(0, 1) || ''}
                                            </UserInitialStyled>
                                        </UserInitialWrapperStyled>
                                    </DetailColumnStyled>
                                    {/*<DetailColumnStyled>{user.username}</DetailColumnStyled>*/}
                                    <DetailColumnStyled>{user.nickname}</DetailColumnStyled>
                                    {/*<DetailColumnStyled*/}
                                    {/*    align={'right'}>{user.since ? format(parseISO(user.since), 'HH:mm dd-MM') : ''}</DetailColumnStyled>*/}
                                </tr>
                            )
                        })}
                    </tbody>
                </DetailTableStyled>
            </>
        )
    }
    const showUserDetails = (): React.ReactElement => {
        return (
            <DisplayFeContainerStyled>
                <DisplayFeDetailsStyled>
                    <FeHeaderDetailsStyled>
                        Current logged in admin users
                        <CloseIconStyled
                            role={'button'}
                            onClick={() =>
                                dispatch(
                                    toggleActiveUsers({
                                        showFEUsers: false,
                                        showBOUsers: false,
                                    })
                                )
                            }
                        />
                    </FeHeaderDetailsStyled>
                    <FeDetailsStyled>{displayUserDetails()}</FeDetailsStyled>
                </DisplayFeDetailsStyled>
            </DisplayFeContainerStyled>
        )
    }

    const renderAvatars = (): React.ReactElement => {
        return (
            <UserAvatarWrapperStyled>
                {users?.map((user) => {
                    return showAvatar ? (
                        <UserAvatarStyled key={user.id} src={user.avatar} alt={user.username} />
                    ) : (
                        <UserInitialWrapperStyled key={user.id}>
                            <ElementWithTooltip
                                tooltip={<span>{user.nickname || user.username || ''}</span>}
                                placement={'bottom'}
                                element={
                                    <UserInitialStyled>
                                        {user.nickname?.substring(0, 1) || user.username?.substring(0, 1) || ''}
                                    </UserInitialStyled>
                                }
                            />
                        </UserInitialWrapperStyled>
                    )
                })}
            </UserAvatarWrapperStyled>
        )
    }

    const renderSummary = (): React.ReactElement => {
        return (
            <>
                <FeUsersStyled
                    onClick={() =>
                        dispatch(
                            toggleActiveUsers({
                                showFEUsers: false,
                                showBOUsers: true,
                            })
                        )
                    }
                >
                    {users.length + (users.length === 0 ? ' Admin' : ' Admin')}{' '}
                </FeUsersStyled>
            </>
        )
    }

    const renderUsers = (): React.ReactElement => {
        return (
            <>
                <UsersStyled
                    onClick={() =>
                        dispatch(
                            toggleActiveUsers({
                                showFEUsers: false,
                                showBOUsers: !state.showBOUsers,
                            })
                        )
                    }
                >
                    {users?.length > 10 ? renderSummary() : renderAvatars()}
                </UsersStyled>
                {state.showBOUsers && showUserDetails()}
            </>
        )
    }

    if (!showActiveUsers) {
        return <></>
    }
    return <>{blastConnected ? renderUsers() : renderDisconnected()}</>
}
