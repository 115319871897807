import React, { useContext, useEffect, useState } from 'react'
// import {
//     DragDropContext,
//     Draggable,
//     DraggingStyle,
//     Droppable,
//     DroppableProvided,
//     DroppableStateSnapshot, NotDraggingStyle
// } from 'react-beautiful-dnd';
import Draggable from 'react-draggable'

import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { EnvironmentContext } from '../../../providers/environment/EnvironmentContext'
import { GuslThemeContext, setAdvancedCellWidth } from '../../../providers/theme/GuslThemeProvider'
import { log } from '../../../services/LogService'
import { cleanWidthFromStyle, getStyle, getWidthFromStyle } from '../../../utils/CssUtils'
import { isDefined, notDefined } from '../../../utils/TypeCheckers'
import { FieldConfigDTO, FormMode, InQueryDTO, MatchQueryDTO, QueryParamsDTO, RangeQueryDTO } from '../../types'
import ElementWithPopover from '../element-with/element-with-popover/ElementWithPopover'
import { maintainTableService } from '../maintain-table/MaintainTableService'
import {
    ColumnHeaderLeftPartStyled,
    ColumnHeaderPartWrapperStyled,
    ColumnHeaderRightPartStyled,
    ColumnHeaderStyled,
    NumOfFiltersAppliedStyled,
    ResizerContainerMobileStyled,
    ResizerContainerStyled,
    ResizerContentMobileStyled,
    ResizerControlStyled,
    ResizerControlWrapperStyled,
    ResizerFldLabelStyled,
} from '../maintain-table/styled'
import { setColumnHeaderWidth, setCurrentlyResizingColumnName, TableColumnResizeState } from './guslColumnResizeSlice'
import { defaultCellWidth, GuslTableState, minResizableCellWidth } from './guslTableSlice'
import { GuslTableQueryParamState, updateQueryParams } from './queryParamsSlice'
import { setScrollLeftPosition } from './scrollSlice'
import { InlineEditState } from './inline-edit/inlineEditSlice'
import SortButton from '../buy-sell-form/v2_components/search-table/SortButton'
import { sortDirectionType } from '../buy-sell-form/v2_components/types'

interface TableColumnDO {
    fld: FieldConfigDTO
    code: string
    topScrollBarPositionRef: React.Ref<any>
    disableColumnResize?: boolean
    formMode?: FormMode
}

const HEADER_WIDth_BUFFER = 10
export default function TableColumnHeader({ fld, code, topScrollBarPositionRef, disableColumnResize, formMode }: TableColumnDO) {
    const className = 'TableColumnHeader-' + new Date().getTime()
    const environmentContext = useContext(EnvironmentContext)
    const guslThemeContext = React.useContext(GuslThemeContext)

    const dispatch = useAppDispatch()
    const _columnResizeState: TableColumnResizeState = useAppSelector((state) => state.guslColumnResizeSlice[code])

    const queryParamsState: GuslTableQueryParamState = useAppSelector((state) => state.queryParamsSlice[code])
    const inlineEditState: InlineEditState = useAppSelector((state) => state.inlineEditSlice[code])
    const inlineEdit: boolean | undefined = inlineEditState?.inlineEdit

    // MK 12/08/2023  for cell width when top search is open
    const state: GuslTableState = useAppSelector((state) => state.guslTableSlice[code])
    const hasAdvancedFilter = fld.advancedFilter || false // state?.showTopFilters;
    // END OF MK 12/08/2023  for cell width when top search is open
    // MK 29/09/2023
    const showTopTableFilters = state?.showTopFilters

    // MK 23/08/2023
    const individualAdvancedSearchFields: string[] | undefined = state?.individualAdvancedSearchFields
    const isIndividualFilterShown: boolean | undefined = individualAdvancedSearchFields && individualAdvancedSearchFields.includes(fld.name)
    // END OF 23/08/2023

    // MK 23/08/2023 displaying num of filters applied on a field
    const queryParams: QueryParamsDTO = queryParamsState?.queryParams
    const insFieldTypes: string[] = ['text', 'option', 'lookup']
    const rangeQueryFieldTypes: string[] = ['date', 'date_time', 'number']
    const mustNotsFieldTypes: string[] = ['checkbox', 'option']
    const [numberOfFiltersApplied, setNumberOfFiltersApplied] = useState<number>(0)
    // MK 18/09/2023 added
    // else { setNumberOfFiltersApplied(0); }

    function getNumOfFiltersApplied(field: FieldConfigDTO) {
        if (insFieldTypes.includes(field.type)) {
            const filter: InQueryDTO[] | undefined = queryParams?.ins?.filter((_in) => _in.field === field.name)
            if (filter && filter.length > 0) {
                setNumberOfFiltersApplied(filter[0].values.length)
            } else {
                setNumberOfFiltersApplied(0)
            }
        }
        if (rangeQueryFieldTypes.includes(field.type)) {
            const filter: RangeQueryDTO[] | undefined = queryParams?.rangeQueries?.filter((rangeQuery) => rangeQuery.field === field.name)
            if (filter && filter.length) {
                setNumberOfFiltersApplied(1)
            } else {
                setNumberOfFiltersApplied(0)
            }
        }
        if (mustNotsFieldTypes.includes(field.type)) {
            const filter: MatchQueryDTO[] | undefined = queryParams?.mustNots?.filter((mustNot) => mustNot.field === field.name)
            if (filter && filter.length) {
                setNumberOfFiltersApplied(1)
            } else {
                setNumberOfFiltersApplied(0)
            }
        }
    }

    useEffect(() => {
        getNumOfFiltersApplied(fld)
    }, [queryParams])

    // END OF 23/08/2023 displaying num of filters applied on a field

    function isOrderedBy() {
        let isOrderedBy = false
        const orderBys = queryParamsState?.queryParams?.orderBys
        // @ts-ignore
        // console.log({orderBys:orderBys[0]?.order,name:fld.name})
        try {
            if (orderBys?.length) {
                isOrderedBy = orderBys[0].field === fld.name
            }
        } catch (e) {
            log.error(className, 'MSG-E-001', 'orderBy detection error')
        }

        return isOrderedBy
    }

    function getOrderByDirection(): string {
        const orderBys = queryParamsState?.queryParams?.orderBys

        let orderByDirection: string = 'NONE'

        if (orderBys?.length && orderBys[0].field === fld.name) {
            orderByDirection = orderBys[0].order
        }

        return orderByDirection as sortDirectionType
    }

    const currentlyResizingHeaderName: string | undefined = _columnResizeState?.resizeColumnFieldName
    const [cssStyle] = useState<{ [id: string]: any }>(cleanWidthFromStyle(fld.style))
    const [colCssStyle] = useState<{ [id: string]: any }>(() => {
        let css = cleanWidthFromStyle(fld.colStyle)
        if (
            notDefined(css.backgroundColor) &&
            isDefined(guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).table.headerBgColor)
        ) {
            css.backgroundColor = guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).table.headerBgColor
        }
        return css
    })

    const textAlign = maintainTableService.extractTextAlignWithFormMode(fld, formMode)
    const headerId: string = 'hdr_' + fld.name + '_' + code
    const isMobile = environmentContext.isMobileDevice()
    const [counter, setCounter] = useState<number>(0)
    const [performanceCounter, setPerformanceCounter] = useState<number>(0)
    const [cellWidth, setCellWidth] = useState<number | string | undefined>(() => {
        if (fld.tableCss && (fld.tableCss.indexOf('width:100%') >= 0 || fld.tableCss.indexOf('width: 100%') >= 0)) {
            return undefined
        } else {
            // console.log(`hdr: name: ${fld.name} a: ${_columnResizeState?.resizedColumns[fld.name]} b: ${getWidthFromStyle(fld.style,10)} c: ${getWidthFromStyle(getStyle(fld.tableCss),HEADER_WIDth_BUFFER)} d: ${fld.cellWidth} e: ${defaultCellWidth}`)
            return (
                _columnResizeState?.resizedColumns[fld.name] ||
                getWidthFromStyle(fld.style, HEADER_WIDth_BUFFER) ||
                getWidthFromStyle(getStyle(fld.tableCss), HEADER_WIDth_BUFFER) ||
                fld.cellWidth ||
                defaultCellWidth
            )
        }
    })

    const alreadyResized: boolean = typeof _columnResizeState?.resizedColumns[fld.name] !== 'undefined'

    useEffect(() => {
        if (fld.tableCss && (fld.tableCss.indexOf('width:100%') >= 0 || fld.tableCss.indexOf('width: 100%') >= 0)) {
            setCellWidth(undefined)
        } else {
            setCellWidth(
                _columnResizeState?.resizedColumns[fld.name] ||
                    getWidthFromStyle(fld.style, HEADER_WIDth_BUFFER) ||
                    getWidthFromStyle(getStyle(fld.tableCss), HEADER_WIDth_BUFFER) ||
                    fld.cellWidth ||
                    defaultCellWidth
            )
        }
    }, [_columnResizeState?.resizedColumns[fld.name]])

    // console.log({field:fld.name,cellWidth,fld})

    const resizeStep: number = 15 // on mobile, on click, number of pixels to resize + or -
    const withPerformanceFix: boolean = false // only desktop, mobile resize is on click
    const dragStep = 15 // every nth drag movement, only relevant when withPerformanceFix = true ;
    function windowDragEndHandler() {
        if (currentlyResizingHeaderName === fld.name) {
            dispatch(setCurrentlyResizingColumnName({ code, fldName: undefined }))
            window.removeEventListener('dragend', windowDragEndHandler)
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        if (currentlyResizingHeaderName === fld.name) {
            window.addEventListener('dragend', windowDragEndHandler)
        }
        return () => {
            abortController.abort()
        }
    }, [currentlyResizingHeaderName])

    if (!fld.displayInTable) return <></>

    const onChangeOrderBy = (fld: FieldConfigDTO) => {
        const orderBys = maintainTableService.inCurrentOrderBy(fld, queryParamsState.queryParams)
            ? maintainTableService.toggleOrderBy(fld, queryParamsState.queryParams)
            : maintainTableService.getOrderBy(fld)
        dispatch(
            updateQueryParams({
                code: code,
                queryParams: {
                    ...queryParamsState.queryParams,
                    orderBys: orderBys,
                    skip: 0,
                },
            })
        )
        dispatch(
            setScrollLeftPosition({
                code,
                // @ts-ignore
                scrollLeftPosition: topScrollBarPositionRef!.current,
            })
        )
    }

    const header = document.getElementById(headerId)

    function setColumnHeaderWidthHandler(event: any, end: boolean = false, start: boolean = false) {
        try {
            if (start) {
                setCounter(0)
                setPerformanceCounter(0)
                return
            }
            const rect = header!.getBoundingClientRect()
            const width = event.clientX - rect.left
            if (width > minResizableCellWidth) {
                dispatcherHandler(width, end, start)
            }
        } catch (e) {
            log.error(className, 'MSG001', "can't resize " + fld.name)
        }
    }

    function dispatcherHandler(width: number, end: boolean, start: boolean) {
        if (!end) {
            setCounter((prev) => prev + 1)
            if (withPerformanceFix && counter % dragStep === 0) {
                setPerformanceCounter((prev) => prev + 1)
                dispatch(setColumnHeaderWidth({ code, fld, width }))
                //log.debug(className, `MSG001","====dispatching every ${dragStep}th======`)
            }
            // safari: last width is wrong
            if (!withPerformanceFix) {
                dispatch(setColumnHeaderWidth({ code, fld, width }))
                // log.warn(className, "MSG002", "====dispatching always======")
            }
        }
        if (withPerformanceFix && (!currentlyResizingHeaderName || currentlyResizingHeaderName !== fld.name)) {
            //  log.debug(className, "MSG003", "====setting CurrentlyResizingColumnName once======")
            dispatch(
                setCurrentlyResizingColumnName({
                    code,
                    fldName: end ? undefined : fld.name,
                })
            )
        }
        if (!withPerformanceFix) {
            dispatch(
                setCurrentlyResizingColumnName({
                    code,
                    fldName: end ? undefined : fld.name,
                })
            )
            // log.warn(className, "MSG004", "====setting CurrentlyResizingColumnName always======")
        }
        if (end) {
            //  log.debug(className, `MSG001`, `---DOM updated----> ${withPerformanceFix ? performanceCounter : counter} times`)
        }
    }

    function resizeOnClickHandler(expand: boolean = false, reset: boolean = false) {
        if (reset) {
            dispatch(setCurrentlyResizingColumnName({ code, fldName: undefined }))
            dispatch(
                setColumnHeaderWidth({
                    code,
                    fld,
                    width: getWidthFromStyle(fld.style) || fld.cellWidth || defaultCellWidth,
                })
            )
        } else {
            dispatch(setCurrentlyResizingColumnName({ code, fldName: fld.name }))
            let cellWidthUpdated = cellWidth
            // sometimes it is a string...
            if (typeof cellWidth === 'string') {
                cellWidthUpdated = parseInt(cellWidth)
            }
            if (expand) {
                dispatch(
                    setColumnHeaderWidth({
                        code,
                        fld,
                        width: (cellWidthUpdated as number) + resizeStep,
                    })
                )
            } else {
                if ((cellWidthUpdated as number) >= minResizableCellWidth) {
                    dispatch(
                        setColumnHeaderWidth({
                            code,
                            fld,
                            width: (cellWidthUpdated as number) - resizeStep,
                        })
                    )
                }
            }
        }
    }

    function MobileResizer() {
        return (
            <>
                <ResizerContentMobileStyled>
                    <ResizerControlWrapperStyled
                        className="col-4 text-start"
                        onClick={() => {
                            resizeOnClickHandler(false)
                        }}
                    >
                        <ResizerControlStyled className="fa-solid fa-right-to-bracket fa-rotate-180 ms-2" />
                    </ResizerControlWrapperStyled>
                    <ResizerControlWrapperStyled
                        className="col-4 text-center"
                        onClick={() => {
                            resizeOnClickHandler(false, true)
                        }}
                    >
                        <ResizerControlStyled className="fa-solid fa-undo" />
                    </ResizerControlWrapperStyled>
                    <ResizerControlWrapperStyled
                        className="col-4 text-end mx-auto"
                        onClick={() => {
                            resizeOnClickHandler(true)
                        }}
                    >
                        <ResizerControlStyled className="fa-solid fa-right-to-bracket me-2" />
                    </ResizerControlWrapperStyled>
                </ResizerContentMobileStyled>
                <ResizerFldLabelStyled className={'small text-center'}>{fld.label}</ResizerFldLabelStyled>
            </>
        )
    }

    const renderDraggableOriginal = (): React.ReactElement => {
        return isMobile ? (
            <ElementWithPopover
                _title={<p className={'small text-center'}>Resize</p>}
                closeHandler={() => {
                    dispatch(setCurrentlyResizingColumnName({ code, fldName: undefined }))
                }}
                withClose
                trigger={'click'}
                placement={'top-start'}
                element={<ResizerContainerMobileStyled />}
                popover={<MobileResizer />}
            />
        ) : (
            <ResizerContainerStyled
                onDragStart={(event) => {
                    setColumnHeaderWidthHandler(event, false, true)
                }}
                onDrag={(event) => {
                    setColumnHeaderWidthHandler(event)
                }}
                onDragEnd={(event) => {
                    setColumnHeaderWidthHandler(event, true)
                }}
                draggable
            ></ResizerContainerStyled>
        )
    }

    const renderDraggableDev = (): React.ReactElement => {
        return (
            <>
                <Draggable
                    axis="x"
                    defaultClassName="DragHandle"
                    defaultClassNameDragging="DragHandleActive"
                    onDrag={(event, { deltaX }) => {
                        setColumnHeaderWidthHandler(event)
                    }}
                    // position={{ x: 0 }}
                    // zIndex={999}
                >
                    <span className="DragHandleIcon">⋮</span>
                </Draggable>
            </>
        )
    }

    let showAsc = true
    let showDesc = true
    if (maintainTableService.inCurrentOrderBy(fld, queryParamsState?.queryParams)) {
        showAsc = maintainTableService.isAscOrderBy(fld, queryParamsState.queryParams)
        showDesc = maintainTableService.isDescOrderBy(fld, queryParamsState.queryParams)
    }
    //console.log({name:fld.name,_cellWidth:cellWidth,cellWidth:setAdvancedCellWidth(cellWidth, showTopTableFilters, isIndividualFilterShown, fld.sortable, true, fld.type, inlineEdit)})
    return (
        <ColumnHeaderStyled
            key={'hdr_'}
            id={headerId}
            beingResized={currentlyResizingHeaderName === fld.name}
            resizing={isDefined(currentlyResizingHeaderName)}
            disableColumnResize={disableColumnResize}
            sortable={fld.sortable}
            style={colCssStyle}
            // cellWidth={setAdvancedCellWidth(cellWidth, showTopTableFilters, isIndividualFilterShown, fld.sortable, true, fld.type, inlineEdit)}
            cellWidth={
                alreadyResized
                    ? setAdvancedCellWidth(
                          cellWidth,
                          showTopTableFilters,
                          isIndividualFilterShown,
                          fld.sortable,
                          true,
                          fld.type,
                          inlineEdit
                      )
                    : undefined
            }
            className={' ' + (fld.align === 'right' ? '' : 'ps-2 ')}
        >
            <ColumnHeaderPartWrapperStyled>
                <ColumnHeaderLeftPartStyled textAlign={textAlign} disableColumnResize={disableColumnResize}>
                    {!isMobile && (
                        <span>
                            {fld.noLabel ? <span>&nbsp;</span> : fld.label}
                            {numberOfFiltersApplied !== 0 && (
                                <NumOfFiltersAppliedStyled>{numberOfFiltersApplied}</NumOfFiltersAppliedStyled>
                            )}
                        </span>
                    )}
                    {fld.sortable && (
                        <SortButton
                            key={fld.name}
                            onClick={() => onChangeOrderBy(fld)}
                            sortDirection={getOrderByDirection() as sortDirectionType}
                        />
                    )}

                    {isMobile && (
                        <span className={'ms-1'}>
                            {fld.noLabel ? <span>&nbsp;</span> : fld.label}
                            {numberOfFiltersApplied !== 0 && (
                                <NumOfFiltersAppliedStyled>{numberOfFiltersApplied}</NumOfFiltersAppliedStyled>
                            )}
                        </span>
                    )}
                </ColumnHeaderLeftPartStyled>

                {!disableColumnResize && (
                    <ColumnHeaderRightPartStyled>
                        <div>{renderDraggableOriginal()}</div>
                    </ColumnHeaderRightPartStyled>
                )}
            </ColumnHeaderPartWrapperStyled>
        </ColumnHeaderStyled>
    )
}
