import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { environmentService } from '../../providers/environment/EnvironmentService'
import { SessionContext } from '../../providers/session/SessionContext'
import { RunOnceEffect, unSubscribe } from '../../utils/Utils'
import { OlivetreeLogin } from './olivetree-login/OlivetreeLogin'

export const JupiterAuthLayout = (): React.ReactElement => {
    const sessionContext = React.useContext(SessionContext)

    const [systemReady, setSystemReady] = useState<boolean>(false)

    const [bespokeLandingPage, setBespokeLandingPage] = useState<string | undefined>(undefined)

    RunOnceEffect(() => {
        let loaderSubscription = sessionContext.watchSystemReady().subscribe((systemReady: boolean) => {
            setSystemReady(systemReady)
            setBespokeLandingPage(environmentService.getEnvironment()?.bespokeLandingPage)
        })
        return () => {
            unSubscribe(loaderSubscription)
        }
    })

    if (!systemReady) {
        return <></>
    }
    if (bespokeLandingPage && bespokeLandingPage === 'OlivetreeLogin') {
        return <OlivetreeLogin />
    } else {
        return (
            <div id={'jupiter-auth'}>
                <Outlet />
            </div>
        )
    }
}

export default JupiterAuthLayout
