// @ts-ignore
import { HttpHeaders } from '@capacitor-community/http/dist/esm/definitions'
import { Jodit } from 'jodit'
import 'jodit/esm/plugins/resizer/resizer'
import 'jodit/esm/plugins/paste-from-word/paste-from-word'
import 'jodit/esm/plugins/source/source'
import 'jodit/esm/plugins/fullsize/fullsize'
import 'jodit/esm/plugins/line-height/line-height'
import 'jodit/esm/plugins/copy-format/copy-format'
import 'jodit/esm/plugins/preview/preview'
import 'jodit/esm/plugins/format-block/format-block'
import 'jodit/esm/plugins/tab/tab'
import 'jodit/esm/plugins/indent/indent'
import 'jodit/esm/plugins/print/print'
import 'jodit/esm/plugins/justify/justify'
import 'jodit/esm/plugins/symbols/symbols'
import 'jodit/esm/plugins/select/select'
import 'jodit/esm/plugins/clean-html/clean-html'
import 'jodit/es2015/jodit.min.css'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useAppSelector } from '../../../app/hooks'
import { BlastContext } from '../../../providers/blast/BlastContext'
import { EnvironmentContext } from '../../../providers/environment/EnvironmentContext'
import { SessionContext } from '../../../providers/session/SessionContext'
import { sessionService } from '../../../providers/session/SessionService'
import { getSettings, validate } from '../../../services/ValidationService'
import { assignReferences, extractExtraFieldProperties, RunOnceEffect } from '../../../utils/Utils'
import { FieldProperties, FormMode } from '../../types'
import { ActionDialogHeaderStyled, ActionDialogTitleStyled } from '../action-dialog/styled'
import { DraggableModalDialog } from '../draggable-modal/draggable-modal'
import FieldLabel from '../field-label/FieldLabel'
import { ImageActionDialogBodyStyled } from '../image/styled'
import { GuslFormState } from '../maintain-form/guslFormSlice'

import { FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled, HintStyled } from '../text/styled'
import { WysiwygStyled, WysiwygTableContentStyled, WysiwygTableValueStyled, WysiwygTableWrapperStyled } from './styled'

export const WysiwygField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    const [formMode, setFormMode] = useState(properties.formMode)
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) || properties.data || '')
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [submitted, setSubmitted] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [joditEditor, setJoditEditor] = useState<Jodit | undefined>()

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'OptionField-' + new Date().getTime())

    const blastContext = React.useContext(BlastContext)
    const sessionContext = useContext(SessionContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const [editorLoaded, setEditorLoaded] = React.useState<boolean>(false)

    const editor = useRef(null)

    const [placeholder] = React.useState<string | undefined>(undefined)

    const extraFieldProperties = extractExtraFieldProperties(properties)
    const [icon] = React.useState<string | undefined>(extraFieldProperties?.icon)
    const [showDialog, setShowDialog] = useState(false)
    const [refreshCounter, setRefreshCounter] = React.useState<number>(1)

    let root = document.documentElement
    if (extraFieldProperties?.modalWidth) {
        root.style.setProperty('--modal-width', extraFieldProperties?.modalWidth)
    }
    if (extraFieldProperties?.modalHeight) {
        root.style.setProperty('--modal-height', extraFieldProperties?.modalHeight)
    }

    useEffect(() => {
        if (joditEditor) {
            joditEditor.setReadOnly(!(formMode === FormMode.EDIT || formMode === FormMode.NEW))
        }
    }, [properties.formMode])

    useEffect(() => {
        const currentRow = properties.getCurrentRowData()
        if (currentRow?.dealHelper) {
            // @ts-ignore
            window.dealHelper = currentRow?.dealHelper
        }
    }, [properties])

    const editorId = 'editor'
    useEffect(() => {
        let editorInstance: Jodit | undefined
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                const editorTextBox = document.getElementById(editorId)

                if (editorTextBox) {
                    const serverSideUrl = sessionService.getServerSideUrl('/oss-article/image-import')
                    const headers: HttpHeaders = {}
                    sessionService.addTokensHeaders(headers)

                    editorInstance = Jodit.make('#editor', {
                        zIndex: 0,
                        readonly: !(formMode === FormMode.EDIT || formMode === FormMode.NEW),
                        hidePoweredByJodit: true,
                        showWordsCounter: false,
                        showCharsCounter: false,
                        // activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
                        activeButtonsInReadOnly: [],
                        cleanHTML: { cleanOnPaste: true },
                        askBeforePasteHTML: true,
                        controls: {
                            lineHeight: {
                                list: Jodit.atom([1, 2, 3, 3.5]),
                            },
                        },
                        // select: {
                        //     normalizeSelectionBeforeCutAndCopy: true,
                        //     normalizeTripleClick: true,
                        // },
                        toolbarButtonSize: 'small',
                        theme: 'default',
                        saveModeInCookie: false,
                        spellcheck: true,
                        editorCssClass: false,
                        triggerChangeEvent: true,
                        width: 'auto',
                        height: 450,
                        minHeight: 450,
                        direction: '',
                        language: 'auto',
                        debugLanguage: false,
                        i18n: 'en',
                        tabIndex: -1,
                        toolbar: true,
                        enter: 'P',
                        defaultMode: 1, // => Jodit.MODE_WYSIWYG,
                        useSplitMode: false,
                        colors: {
                            greyscale: [
                                '#000000',
                                '#434343',
                                '#666666',
                                '#999999',
                                '#B7B7B7',
                                '#CCCCCC',
                                '#D9D9D9',
                                '#EFEFEF',
                                '#F3F3F3',
                                '#FFFFFF',
                            ],
                            palette: [
                                '#980000',
                                '#FF0000',
                                '#FF9900',
                                '#FFFF00',
                                '#00F0F0',
                                '#00FFFF',
                                '#4A86E8',
                                '#0000FF',
                                '#9900FF',
                                '#FF00FF',
                            ],
                            full: [
                                '#E6B8AF',
                                '#F4CCCC',
                                '#FCE5CD',
                                '#FFF2CC',
                                '#D9EAD3',
                                '#D0E0E3',
                                '#C9DAF8',
                                '#CFE2F3',
                                '#D9D2E9',
                                '#EAD1DC',
                                '#DD7E6B',
                                '#EA9999',
                                '#F9CB9C',
                                '#FFE599',
                                '#B6D7A8',
                                '#A2C4C9',
                                '#A4C2F4',
                                '#9FC5E8',
                                '#B4A7D6',
                                '#D5A6BD',
                                '#CC4125',
                                '#E06666',
                                '#F6B26B',
                                '#FFD966',
                                '#93C47D',
                                '#76A5AF',
                                '#6D9EEB',
                                '#6FA8DC',
                                '#8E7CC3',
                                '#C27BA0',
                                '#A61C00',
                                '#CC0000',
                                '#E69138',
                                '#F1C232',
                                '#6AA84F',
                                '#45818E',
                                '#3C78D8',
                                '#3D85C6',
                                '#674EA7',
                                '#A64D79',
                                '#85200C',
                                '#990000',
                                '#B45F06',
                                '#BF9000',
                                '#38761D',
                                '#134F5C',
                                '#1155CC',
                                '#0B5394',
                                '#351C75',
                                '#733554',
                                '#5B0F00',
                                '#660000',
                                '#783F04',
                                '#7F6000',
                                '#274E13',
                                '#0C343D',
                                '#1C4587',
                                '#073763',
                                '#20124D',
                                '#4C1130',
                            ],
                        },
                        colorPickerDefaultTab: 'background',
                        imageDefaultWidth: 300,
                        removeButtons: [],
                        disablePlugins: [],
                        extraButtons: [
                            { name: '|' },
                            {
                                name: 'Add Deal',
                                tooltip: 'Insert current selected deal',
                                exec: function (editor: Jodit) {
                                    // @ts-ignore
                                    const dealHelper = window.dealHelper
                                    if (dealHelper) {
                                        const id = dealHelper.id
                                        const name = dealHelper.displayName
                                        editor.selection.insertHTML('<h3 data-id=' + id + '>' + name + '</h3><p>   </p>')
                                    } else {
                                        const dialog = new Jodit.modules.Dialog()
                                        dialog.setContent('Select a deal first')
                                        dialog.open()
                                    }
                                },
                            },
                            { name: '|' },
                            {
                                name: 'End of Subject',
                                icon: '',
                                exec: (editor: any) => {
                                    var html = '<p class="gusl-indicator">[end of subject]</p><hr>'
                                    // editor.selection.restore()
                                    // var pos = editor.selection.save()
                                    // console.log('---> pos',pos)

                                    editor.selection.insertHTML(html)
                                },
                            },
                            { name: '|' },
                            {
                                name: 'End of Deal',
                                icon: '',
                                exec: (editor: any) => {
                                    var html = '<p class="gusl-indicator">[end of deal]</p><hr>'
                                    // var pos = editor.selection.save()
                                    // console.log('---> pos', pos)
                                    editor.selection.insertHTML(html)
                                },
                            },
                        ],
                        sizeLG: 900,
                        sizeMD: 700,
                        sizeSM: 400,
                        buttons: [
                            'source',
                            '|',
                            'bold',
                            'strikethrough',
                            'underline',
                            'italic',
                            '|',
                            'ul',
                            'ol',
                            '|',
                            'outdent',
                            'indent',
                            '|',
                            'font',
                            'fontsize',
                            'lineHeight',
                            'brush',
                            'paragraph',
                            '|',
                            'image',
                            // 'video',
                            'table',
                            'link',
                            '|',
                            'align',
                            'undo',
                            'redo',
                            '|',
                            // 'hr',
                            'eraser',
                            'copyformat',
                            '|',
                            'symbols',
                            // 'fullsize',
                            // 'select',
                            'print',
                            // 'about'
                        ],
                        buttonsXS: ['bold', 'image', '|', 'brush', 'paragraph', '|', 'align', '|', 'undo', 'redo', '|', 'eraser', 'dots'],
                        textIcons: false,
                        resizer: {
                            showSize: true,
                            hideSizeTimeout: 2000,
                            useAspectRatio: false, //['img', 'table'],
                            forImageChangeAttributes: true,
                            min_width: 10,
                            min_height: 10,
                        },
                        uploader: {
                            enableDragAndDropFileToEditor: true,
                            insertImageAsBase64URI: false,
                            imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                            withCredentials: false,
                            format: 'json',
                            method: 'POST',
                            url: serverSideUrl,
                            headers: headers,
                            prepareData: (data: any) => {
                                // add form data here
                            },
                            isSuccess: (resp: any) => {
                                return !resp.error
                            },
                            getMessage: (resp: any) => {
                                return resp.msg.join !== undefined ? resp.msg.join(' ') : resp.msg
                            },
                            process: (resp: any) => {
                                // const imageUrls = resp.data?.map((i: any) => i.imageUrl);
                                // console.log('imageUrls ----->', imageUrls)
                                return {
                                    files: resp?.files ? [resp?.files[0]] : ['dummy.png'],
                                    path: '',
                                    baseurl: '',
                                    error: resp?.error ? 1 : 0,
                                    msg: 'File uploaded', // resp.msg
                                }
                            },
                            defaultHandlerSuccess: (resp: any) => {
                                if (resp.files) {
                                    if (resp.files.length) {
                                        // @ts-ignore
                                        editor?.current?.component?.selection?.insertImage(resp.files[0])
                                    }
                                }
                            },
                            defaultHandlerError: (resp: any) => {},
                        },
                        events: {
                            applyLink: (jodit: any, link: any) => {
                                link.setAttribute('target', '_blank')
                            },
                        },
                    })

                    /*
                                                editorInstance = Jodit.make(editorTextBox, {
                                                    readonly: (!(formMode === FormMode.EDIT || formMode === FormMode.NEW)),
                                                    // disablePlugins:['paste'],
                                                    // extraPlugins:['paste-from-word'],
                                                    // buttons: [
                                                    //     'eraser'
                                                    //     ],
                                                    pasteFromWord: {
                                                        enable: true,
                                                        convertUnitsToPixel: true,
                                                        allowedStyleProps: [

                                                        ]
                                                    },
                                                    enter: 'br',
                                                    height: 450,
                                                    hidePoweredByJodit: true,
                                                    showWordsCounter: false,
                                                    showCharsCounter: false,
                                                    showXPathInStatusbar: false,
                                                    askBeforePasteFromWord: false,
                                                    askBeforePasteHTML: true,
                                                    pasteExcludeStripTags: Jodit.atom(['br', 'style']),
                                                    pasteHTMLActionList: Jodit.atom([
                                                        { value: Jodit.constants.INSERT_AS_HTML, text: 'Keep' },
                                                        { value: Jodit.constants.INSERT_AS_TEXT, text: 'Insert as Text' },
                                                        { value: Jodit.constants.INSERT_ONLY_TEXT, text: 'Insert only Text' }
                                                    ]),
                                                    processPasteHTML: false,
                                                    toolbarAdaptive: false,
                                                    defaultActionOnPasteFromWord: 'insert_as_html',
                                                    processPasteFromWord: true,
                                                    // defaultActionOnPaste: 'insert_as_html',
                                                    defaultActionOnPaste: 'INSERT_ONLY_TEXT',
                                                    cleanHTML: {cleanOnPaste: true},
                                                    link: {openInNewTabCheckbox: true},
                                                    allowResizeTags: new Set(['img']),
                                                    toolbarSticky: true,
                                                    extraButtons: [{
                                                        name: 'Add Deal',
                                                        tooltip: 'Insert current selected deal',
                                                        exec: function (editor: Jodit) {
                                                            // @ts-ignore
                                                            const dealHelper = window.dealHelper
                                                            if (dealHelper) {
                                                                const id = dealHelper.id
                                                                const name = dealHelper.displayName
                                                                editor.selection.insertHTML('<h3 data-id=' + id + '>' + name + '</h3><p>   </p>');
                                                            } else {
                                                                const dialog = new Jodit.modules.Dialog();
                                                                dialog.setContent('Select a deal first');
                                                                dialog.open();
                                                            }
                                                        }
                                                    },
                                                        //     {
                                                        //     name: 'OnlyParagraph',
                                                        //     icon: '',
                                                        //     exec: (editor: any) => {
                                                        //
                                                        //         var text = editor.selection // .getHTML();
                                                        //         console.log(text);
                                                        //         var html = '<p>' + text + '</p>'
                                                        //         console.log(html);
                                                        //         editor.selection.remove();
                                                        //         editor.selection.insertHTML(html);
                                                        //         const dialog = new Jodit.modules.Dialog();
                                                        //         dialog.setContent('Hello world!');
                                                        //         dialog.open();
                                                        //     }
                                                        // },
                                                        {
                                                            name: 'End of Subject',
                                                            icon: '',
                                                            exec: (editor: any) => {
                                                                var html = "<p class='gusl-indicator'>[end of subject]</p><hr class='gusl-indicator'/>"
                                                                editor.selection.restore()
                                                                // var pos = editor.selection.save()
                                                                // console.log('---> pos',pos)

                                                                editor.selection.insertHTML(html);
                                                            }
                                                        },
                                                        {
                                                            name: 'End of Deal',
                                                            icon: '',
                                                            exec: (editor: any) => {
                                                                var html = "<p class='gusl-indicator'>[end of deal]</p><hr class='gusl-indicator'/>"
                                                                var pos = editor.selection.save()
                                                                console.log('---> pos',pos)

                                                                editor.selection.insertHTML(html);
                                                            }
                                                        }
                                                        // {
                                                        //     name: 'Strip',
                                                        //     icon: '',
                                                        //     exec: (editor: any) => {
                                                        //         var text = editor.s.html
                                                        //         editor.selection.remove();
                                                        //         console.log('strip before', text);
                                                        //         console.log('stripped',Jodit.modules.Helpers.safeHTML('<p>hello world</p>', {
                                                        //             removeOnError: false;
                                                        //             safeJavaScriptLink: true;
                                                        //         }));
                                                        //         let result = Jodit.modules.Helpers.safeHTML(editor.s.html,{
                                                        //             removeOnError: false;
                                                        //             safeJavaScriptLink: true;
                                                        //         });
                                                        //         console.log('strip after', result);
                                                        //         editor.selection.insertHTML(result);
                                                        //     }
                                                        // }
                                                    ],
                                                    resizer: {
                                                        showSize: true,
                                                        hideSizeTimeout: 2000,
                                                        useAspectRatio: false, //['img', 'table'],
                                                        forImageChangeAttributes: true,
                                                        min_width: 10,
                                                        min_height: 10
                                                    },
                                                    uploader: {
                                                        enableDragAndDropFileToEditor: true,
                                                        insertImageAsBase64URI: false,
                                                        imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                                                        withCredentials: false,
                                                        format: 'json',
                                                        method: 'POST',
                                                        url: serverSideUrl,
                                                        headers: headers,
                                                        prepareData: (data: any) => {
                                                            // add form data here
                                                        },
                                                        isSuccess: (resp: any) => {
                                                            return !resp.error;
                                                        },
                                                        getMessage: (resp: any) => {
                                                            return resp.msg.join !== undefined ? resp.msg.join(' ') : resp.msg;
                                                        },
                                                        process: (resp: any) => {
                                                            // const imageUrls = resp.data?.map((i: any) => i.imageUrl);
                                                            // console.log('imageUrls ----->', imageUrls)
                                                            return {
                                                                files: resp?.files ? [resp?.files[0]] : ['dummy.png'],
                                                                path: '',
                                                                baseurl: '',
                                                                error: resp?.error ? 1 : 0,
                                                                msg: 'File uploaded' // resp.msg
                                                            };
                                                        },
                                                        defaultHandlerSuccess: (resp: any) => {
                                                            if (resp.files) {
                                                                if (resp.files.length) {
                                                                    // @ts-ignore
                                                                    editor?.current?.component?.selection?.insertImage(resp.files[0]);
                                                                }
                                                            }
                                                        },
                                                        defaultHandlerError: (resp: any) => {
                                                        }
                                                    },
                                                    events: {
                                                        applyLink: (jodit: any, link: any) => {
                                                            link.setAttribute("target", "_blank");
                                                        }
                                                    }

                                                });
                        */
                    editorInstance.value = formValue || ''
                    editorInstance.e.on('change', () => {
                        if (editorInstance?.value) {
                            onDataInputChange(editorInstance?.value)
                        }
                    })
                    editorInstance.e.on('blur', () => {
                        if (editorInstance?.selection) {
                            var pos = editorInstance.selection.save()
                        }

                        // if (editorInstance?.value) {
                        //     onDataInputChange(editorInstance?.value)
                        // }
                        // console.log('blur', editorInstance?.value)
                    })
                    editorInstance.e.on('beforePaste', (event) => {
                        // @ts-ignore
                        const target = event.clipboardData || window.clipboardData || event.originalEvent.clipboardData
                        const pastedText = target.getData('text/plain') || target.getData('text/html')
                        if (
                            pastedText.indexOf('data:image') !== -1 ||
                            pastedText.indexOf('src="data:image"') !== -1 ||
                            pastedText.indexOf('base64') !== -1
                        ) {
                            return false
                        }
                    })

                    editorInstance.e.off('processPaste.paste').on('processPaste.paste', (event, text, type) => {
                        if (text && /.*<img.*src=".*base64.*[\S\n\r\s]*">.*/g.test(text)) {
                            return text.replace(/<img.*src=".*base64.*[\S\n\r\s]*">/g, '')
                        } else {
                            return text
                        }
                    })

                    setJoditEditor(editorInstance)
                } else {
                    // console.log('not ready')
                }
            }, 100)
        })
        return () => {
            if (joditEditor) {
                joditEditor.destruct()
            }
        }
    }, [properties.code])

    // useEffect(() => {
    //     // this is a complete hack - need a way of knowing save was done and then we can reset the data
    //     if (refreshCounter > 1) {
    //         onDataInputChange('')
    //         setRefreshCounter(refreshCounter + 1)
    //     }
    // }, [properties?.counter]);

    //     const preparePaste = (jodit: Jodit): void => {
    //         jodit.e.on(
    //             'paste',
    //             (e: ClipboardEvent | DragEvent) => {
    // // eslint-disable-next-line no-restricted-globals
    //                 if (confirm('Change pasted content?')) {
    //
    //                     jodit.e.stopPropagation('paste');
    //                     if (Jodit.modules?.Helpers?.getDataTransfer(e)) {
    //                         // @ts-ignore
    //                         const val = Jodit.modules.Helpers.getDataTransfer(e)
    //                             .getData(Jodit.constants.TEXT_HTML)
    //                             .replace(/a/g, 'b')
    //                         jodit.s.insertHTML(val)
    //                         return false;
    //                     }
    //                 }
    //             },
    //             {
    //                 top: true
    //             }
    //         )
    //     }
    //
    //     Jodit.plugins.add('preparePaste', preparePaste);

    const [joditTableConfig] = React.useState<any>({
        zIndex: 0,
        readonly: true,
        toolbar: false,
    })

    // const [joditConfig, setJoditConfig] = React.useState<any | undefined>(undefined);

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setIsEdit(mode === FormMode.EDIT || mode === FormMode.NEW || mode === FormMode.ACTION_DIALOG)
        setFormValue(properties?.data || '')
    }

    useEffect(() => {
        setFormValue(properties?.data || '')
        // setJoditConfig(createConfig());
        setRefreshCounter(refreshCounter + 1)
    }, [properties])

    const onRefresh = () => {}

    const doValidation = (fieldValue: any): boolean => {
        setSubmitted(true)
        const message = validate(
            properties.menuItem?.code,
            properties.fieldConfig.name,
            properties.fieldConfig.validators,
            'text',
            fieldValue
        )
        if (message) {
            setErrorMessage(message)
            return false
        }
        return true
    }

    const onFormSaved = (rowData: any) => {
        if (formMode === FormMode.NEW) {
            onDataInputChange('')
        }
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, onRefresh, doValidation, onFormSaved)
    })

    // useEffect(() => {
    //     window.requestAnimationFrame(function () {
    //         setTimeout(() => {
    //             if (!editorLoaded) {
    //             }
    //         }, 100)
    //     })
    // }, [properties.fieldConfig.name])

    const onImageClick = (e: React.MouseEvent<HTMLElement>) => {
        if (e) {
            e.stopPropagation()
        }
        if (!extraFieldProperties?.allowClickToEnlarge) {
            return
        }
        setShowDialog(true)
    }

    const onHide = () => {
        setShowDialog(false)
    }

    const renderImageModal = (): React.ReactElement => {
        return (
            <Modal
                dialogAs={DraggableModalDialog}
                centered
                show={true}
                keyboard={true}
                onHide={() => onHide()}
                backdrop={true}
                dialogClassName="modal-wrapper"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <ActionDialogHeaderStyled closeButton={true}>
                    <ActionDialogTitleStyled>{properties.fieldConfig.label}</ActionDialogTitleStyled>
                </ActionDialogHeaderStyled>
                <ImageActionDialogBodyStyled>
                    <WysiwygTableWrapperStyled height={extraFieldProperties?.modalHeight || '400px'}>
                        <WysiwygTableContentStyled dangerouslySetInnerHTML={{ __html: formValue }}></WysiwygTableContentStyled>
                    </WysiwygTableWrapperStyled>
                </ImageActionDialogBodyStyled>
            </Modal>
        )
    }
    const renderTableView = (): React.ReactElement => {
        return (
            <>
                <WysiwygTableValueStyled>
                    {icon && (
                        <i
                            className={icon}
                            onClick={(e) => onImageClick(e)}
                            style={{
                                width: extraFieldProperties?.imageWidth || '20px',
                                height: extraFieldProperties?.imageHeight || '20px',
                            }}
                        />
                    )}
                </WysiwygTableValueStyled>
                {extraFieldProperties?.allowClickToEnlarge && showDialog && renderImageModal()}
            </>
        )
    }

    const onDataInputChange = (value: string) => {
        // console.log('value', value)
        setFormValue(value)
        properties.onChange(properties.fieldConfig.name, value)
    }

    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        return (
            <>
                {!hideField && (
                    <>
                        <FloatingFormStyled id={'ffs_' + properties?.fieldConfig?.name}>
                            <FieldContentWrapperStyled
                                id={'fcws_' + properties?.fieldConfig?.name}
                                isEdit={isEdit}
                                inLine={properties.inline}
                                fieldType={properties?.fieldConfig?.type}
                            >
                                <FieldContentStyled id={'fcs_' + properties?.fieldConfig?.name} formMode={formMode}>
                                    <WysiwygStyled id={'wysiwyg_' + properties.fieldConfig.name}>
                                        {<textarea id={editorId} name="editor" ref={editor}></textarea>}
                                    </WysiwygStyled>
                                </FieldContentStyled>
                                {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                                {submitted && errorMessage && <small className="yellow">{errorMessage}</small>}
                            </FieldContentWrapperStyled>
                            <FieldLabel properties={properties} />
                        </FloatingFormStyled>
                    </>
                )}
            </>
        )
    }
    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
