import styled, { ThemeProps } from 'styled-components'

interface IndividualSplitProps extends ThemeProps<any> {
    splitHeight: number
}

export interface OpenTabProps extends ThemeProps<any> {
    onClick: Function
    isActive?: boolean
    isLast: boolean
}

export interface OpenTabWrapperProps extends ThemeProps<any> {
    isLast: boolean
    isActive: boolean
    isOverFlown?: boolean
    isAnimation?: boolean
}

export const IndividualSplitStyled = styled.div.attrs((props: IndividualSplitProps) => ({
    className: 'col-md-12',
}))<IndividualSplitProps>`
    height: ${(props) => props.splitHeight}px;
    overflow-y: auto;
    margin-bottom: 5px;
`
export const SplitHeaderStyled = styled.div.attrs((props) => ({
    className: 'col-md-12 p-1 d-flex align-items-center justify-content-between',
}))`
    height: 28px;
    background-color: ${(props) => props.theme.colors.dark[0]};
`
export const OpenTabStyled = styled.div.attrs((props: OpenTabProps) => ({
    onClick: props.onClick,
}))<OpenTabProps>`
    padding: 2px 5px;

    cursor: pointer;
    color: ${(props) => props.theme.colors.dark[1]};
    font-size: 12px;
    font-weight: lighter;
    :hover {
        color: ${(props) => props.theme.colors.accentColor};
    }
`
export const CloseTabIconStyled = styled.div.attrs((props: OpenTabProps) => ({
    onClick: props.onClick,
}))<OpenTabProps>`
    padding: 2px 5px;
    cursor: pointer;
    color: ${(props) => (props.isActive ? props.theme.colors.dark[1] : props.theme.colors.light)};
    font-size: 12px;
    font-weight: lighter;
    :hover {
        color: ${(props) => props.theme.colors.dark[1]};
    }
`
export const OpenTabWrapper = styled.div.attrs((props: OpenTabWrapperProps) => ({
    className: 'd-flex align-items-center',
}))<OpenTabWrapperProps>`
    height: 30px;
    margin-top: 4px;
    margin-bottom: ${(props) => (props.isOverFlown ? '5px' : '0px')};
    border-right: 1px solid ${(props) => (!props.isLast ? props.theme.colors.accentColorMuted : 'transparent')};
    background-color: ${(props) => (props.isActive ? props.theme.table.panelBgColor : 'transparent')};
    padding-right: 8px;
    border-top: 2px solid ${(props) => (props.isActive ? props.theme.navigation.sideMenu.rightBorderColor : 'transparent')};
`
