import styled from 'styled-components'

export const TitleWrapperStyled = styled.div.attrs((props) => ({
    role: 'toolbar',
    id: 'title-wrapper',
    className: 'row g-0 d-flex align-items-center justify-content-between',
}))`
    background-color: ${(props) => props.theme.table.titleBgColor};
    padding: ${(props) => props.theme.table.titlePadding};
    color: ${(props) => props.theme.table.titleColor};
    font-size: ${(props) => props.theme.table.titleFontSize};
`
export const ListContainerStyled = styled.div.attrs((props: ListScrollContainerProperties) => ({}))<ListScrollContainerProperties>`
    padding: ${(props) => (props.isMobile ? '0px' : '10px')};
    // background-color: ${(props) => props.theme.table.panelBgColor};
    // background-color: ${(props) => props.theme.colors.dark[0]};

    // vvv portrait
    // min-height: 100%;
`

interface ListScrollContainerProperties {
    isMobile?: boolean
    listStartPos: number
    footerHeight: number
}

/*
added ACTION_ITEM_WIDTH nova event list
+ ACTION_ITEM_WIDTH
 */
export const ListScrollContainerStyled = styled.div.attrs((props: ListScrollContainerProperties) => ({}))<ListScrollContainerProperties>`
    height: ${(props) => {
        if (props.isMobile) {
            return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.listStartPos + props.footerHeight) + 'px)'
        }
        return 'inherit'
    }};
`

interface AccordionListPanelProperties {
    isMobile: boolean
    listStartPos: number
    searchOpen: boolean
    hasOrderByActions: boolean
    footerHeight: number
}

export const AccordionListPanelStyled = styled.div.attrs((props: AccordionListPanelProperties) => ({}))<AccordionListPanelProperties>`
    height: ${(props) => {
        if (props.isMobile) {
            // return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.listStartPos + 10 - (props.searchOpen ? 40 : 0) - (props.hasOrderByActions ? 33 : 0) + (props.isMobile ? FOOTER_HEIGHT - FOOTER_ADJ : 0)) + 'px)'
            return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.listStartPos + props.footerHeight) + 'px)'
        }
        return (
            'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
            (props.listStartPos + 60 - (props.searchOpen ? 40 : 40) - (props.hasOrderByActions ? 33 : 0)) +
            'px)'
        )
        // return 'var(--internal-height)'
    }};

    overflow: ${(props) => {
        if (props.isMobile) {
            return 'auto'
        }
        return 'auto'
    }};
`
