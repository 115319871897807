import React, { useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { DateRangeSelectorDO } from '../../types'
import SelectorContent from './selector-content/SelectorContent'

export default function DateRangeSelector({
    code,
    element,
    placement,
    trigger,
    width,
    style = {},
    field,
    currentUnit,
    currentNum,
    handlers,
    fromTo,
}: DateRangeSelectorDO) {
    const [className] = useState('DateRangeSelector-' + new Date().getTime())
    const defaultWidth: number = 276
    const displayWidth: number = width || defaultWidth
    const [show, setShow] = useState<boolean>(false)

    const _popover = (
        <Popover
            id={'popover-' + className}
            className={'p-0 m-0'}
            style={{
                ...{
                    width: displayWidth,
                    maxWidth: displayWidth,
                },
                ...style,
            }}
        >
            <SelectorContent
                code={code}
                field={field}
                currentUnit={currentUnit}
                currentNum={currentNum}
                handlers={handlers}
                fromTo={fromTo}
                setShow={setShow}
            />
        </Popover>
    )

    const _element = () => (
        <OverlayTrigger
            trigger={trigger ? trigger : ['hover', 'focus']}
            show={show}
            placement={placement ? placement : 'right'}
            overlay={_popover}
        >
            <span
                role={'button'}
                onClick={() => {
                    setShow(!show)
                }}
            >
                {element}
            </span>
        </OverlayTrigger>
    )

    return _element()
}
