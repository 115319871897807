import React, { useRef, useState } from 'react'
import Select from 'react-select/base'
import { useAppSelector } from '../../../app/hooks'
import { GuslThemeContext } from '../../../providers/theme/GuslThemeProvider'
import { log } from '../../../services/LogService'
import { getSettings, performValidation } from '../../../services/ValidationService'
import { getStyle } from '../../../utils/CssUtils'
import { isDefined } from '../../../utils/TypeCheckers'
import { assignReferences, getCssValue, noop, RunOnceEffect } from '../../../utils/Utils'
import { FieldProperties, FormMode } from '../../types'
import FieldLabel from '../field-label/FieldLabel'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import { Option } from '../option/OptionField'
import { OptionContainerStyled, OptionViewStyled, SelectWrapperStyled } from '../option/styled'
import { OptionFieldStyles } from '../option/types'
import { FloatingFormStyled, TextFieldStyled } from '../text/styled'

export const ThemeField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const guslThemeContext = React.useContext(GuslThemeContext)

    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'ThemeField-' + new Date().getTime())
    const [formMode, setFormMode] = useState(properties.formMode)
    const valueRef = useRef(properties?.data)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [submitted, setSubmitted] = useState(false)
    const [dropdownItems] = useState<Option[]>(() => {
        const options: Option[] = []
        options.push({ value: 'alvar', label: 'The Blues' })
        options.push({ value: 'intertrader', label: 'The Golden Age' })
        return options
    })
    const [menuOpen, setMenuOpen] = useState<boolean>(false)

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setFormValue(loadInitialValue())
    }

    const loadInitialValue = (): Option => {
        let returnItem: Option = { label: '', value: '' }
        if (isDefined(properties?.data)) {
            dropdownItems.forEach((item) => {
                if (properties?.data === item.value) {
                    returnItem = item
                }
            })
        } else {
            log.info(className, 'MSG003', 'loadInitialValue', dropdownItems[0])
            return dropdownItems[0]
            //return {value: '', label: ''};
        }
        log.info(className, 'MSG003', 'loadInitialValue', returnItem)
        return returnItem
    }

    const [formValue, setFormValue] = useState<Option>(() => {
        return loadInitialValue()
    })

    const doValidation = (fieldValue: any): boolean => {
        return performValidation(formMode, properties.menuItem?.code, properties.fieldConfig, fieldValue, setSubmitted, setErrorMessage)
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.fieldConfig.tableCss, '')

        return (
            <TextFieldStyled textAlign={properties.fieldConfig.align}>
                <span className="" style={style}>
                    {formValue.label}
                </span>
            </TextFieldStyled>
        )
    }

    const renderFormView = (): React.ReactElement => {
        const onDataInputChange = (option: Option) => {
            log.info(className, 'MSG003', 'onDataInputChange', valueRef?.current?.value, formValue, option)
            setFormValue(option)
            properties.onChange(properties.fieldConfig.name, option.value)
        }

        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        if (hideField) {
            return <></>
        }

        if (formMode === FormMode.VIEW || disableField) {
            return (
                <>
                    <FloatingFormStyled>
                        <OptionViewStyled>{renderTableView()}</OptionViewStyled>
                        <FieldLabel properties={properties} />
                    </FloatingFormStyled>
                </>
            )
        }

        // @ts-ignore
        return (
            <>
                <FloatingFormStyled>
                    <OptionContainerStyled submitted={submitted} noValue={!formValue}>
                        <SelectWrapperStyled>
                            <Select
                                options={[...dropdownItems]}
                                ref={valueRef}
                                placeholder={'Select...'}
                                /*@ts-ignore*/
                                styles={OptionFieldStyles}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        text: getCssValue('muted'),
                                        primary25: getCssValue('--widget-blue-light'),
                                        primary: getCssValue('--main-background-color'),
                                    },
                                })}
                                menuIsOpen={menuOpen}
                                inputValue={''}
                                /*@ts-ignore*/
                                onChange={(option) => {
                                    onDataInputChange(option as Option)
                                }}
                                onInputChange={(e) => {}}
                                onMenuOpen={() => {
                                    setMenuOpen(true)
                                }}
                                onMenuClose={() => {
                                    setMenuOpen(false)
                                }}
                                value={formValue}
                            />
                        </SelectWrapperStyled>
                    </OptionContainerStyled>
                    <FieldLabel properties={properties} />
                    {submitted && errorMessage && <small className="invalid">{errorMessage}</small>}
                </FloatingFormStyled>
            </>
        )
    }
    /*
                                         defaultValue={valueRef?.current?.value}
                                     submitted={submitted}
                                     noValue={!formValue}

    className={inputClass() + ((submitted && !formValue) ? 'yellow' : '')}
     */

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
