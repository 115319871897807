import styled, { ThemeProps } from 'styled-components'
import { MoneyDTO } from '../../types'

interface MoneyStyledProps extends ThemeProps<any> {
    amount: MoneyDTO | undefined
    colorise: boolean
}

export const AmountStyled = styled.div.attrs((props: MoneyStyledProps) => ({
    className: 'money',
}))<MoneyStyledProps>`
    color: ${(props) => {
        let value = 'var(--theme-table-columnColor) '
        if (props.colorise) {
            if (props.amount && props.amount.value) {
                value = props.amount.value < 0 ? 'var(--theme-money-negativeColor) ' : 'var(--theme-money-positiveColor)'
            }
        }
        return value
    }} !important ;
    font-size: var(--theme-table-columnFontSize);
`
