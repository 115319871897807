import React, { useRef, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { inputChildClass } from '../../../pages/login/LoginUsernamePassword'
import { getSettings, performValidation } from '../../../services/ValidationService'
import { assignReferences, noop, RunOnceEffect } from '../../../utils/Utils'
import { FieldProperties, FormMode } from '../../types'
import FieldLabel from '../field-label/FieldLabel'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import Icon from '../maintain-table/bootstrap/Icon'
import { FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled, HintStyled } from '../text/styled'
import './Password.css'
import { IconPlaceholderStyled } from './styled'

export const PasswordField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'PasswordField-' + new Date().getTime())
    const [formMode, setFormMode] = useState(properties.formMode)
    // const [formValue, setFormValue] = useState<string>(properties?.data || '');
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) || properties.data || '')

    console.log('===>', _guslFormState?.getFieldValue(properties), properties.data)

    const valueRef = useRef(properties?.data)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [submitted, setSubmitted] = useState(false)
    const [isPasswordRevealed, setIsPasswordRevealed] = useState<boolean>(false)

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
        setFormValue(properties?.data || '')
    }

    const doValidation = (fieldValue: any): boolean => {
        return performValidation(formMode, properties.menuItem?.code, properties.fieldConfig, fieldValue, setSubmitted, setErrorMessage)
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    })

    const renderTableView = (): React.ReactElement => {
        return (
            <>
                {properties.isNumber && <div className="text-right">{formValue}</div>}
                {!properties.isNumber && <div className="">{formValue}</div>}
            </>
        )
    }

    const renderFormView = (): React.ReactElement => {
        const onDataInputChange = (value: string) => {
            setFormValue(value)
            properties.onChange(properties.fieldConfig.name, valueRef?.current?.value)
        }

        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue)

        return (
            <>
                {!hideField && (
                    <>
                        {/*<div className="form-floating">*/}
                        {/*    <div className={inputWrapperClass(' ') + ((submitted && !formValue) ? 'yellow' : '')}>*/}
                        <FloatingFormStyled>
                            <FieldContentWrapperStyled>
                                <FieldContentStyled formMode={formMode}>
                                    <input
                                        className={inputChildClass('password-field muted')}
                                        type={formMode === 0 ? 'password' : !isPasswordRevealed ? 'password' : 'text'}
                                        ref={valueRef}
                                        defaultValue={valueRef?.current?.value}
                                        value={formValue}
                                        autoFocus={properties.isFirstField}
                                        readOnly={formMode === 0}
                                        onChange={(e) => onDataInputChange(e.target.value)}
                                        required={required}
                                        name={'password'}
                                    />
                                    {formMode !== 0 && (
                                        <IconPlaceholderStyled>
                                            <Icon
                                                icon={isPasswordRevealed ? 'fa fa-eye-slash' : 'fa fa-eye'}
                                                onClick={() => {
                                                    setIsPasswordRevealed(!isPasswordRevealed)
                                                }}
                                            />
                                        </IconPlaceholderStyled>
                                    )}
                                </FieldContentStyled>
                                {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                                {submitted && errorMessage && <small className="yellow">{errorMessage}</small>}
                            </FieldContentWrapperStyled>
                            <FieldLabel properties={properties} />
                        </FloatingFormStyled>
                    </>
                )}
            </>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
