import React from 'react'
import { BuySellState } from '../../buySellSlice'
import { BuySellOrder, OrderAction } from '../../types'
import {
    DisplayContainerStyled,
    DisplayFieldPanelStyled,
    DisplayFieldStyled,
    DisplayLabelStyled,
    DisplayValueStyled,
} from '../../styled_v2'
import Money from '../../../money/money'
import RenderPlaceOrderButtons from './RenderPlaceOrderButtons'

export interface RenderDisplayInfoDO {
    buySellState: BuySellState
    setPlaceOrderError: Function
    isDMA: Function
    reset: Function
}

export default function RenderDisplayInfo({ buySellState, setPlaceOrderError, isDMA, reset }: RenderDisplayInfoDO) {
    const order: BuySellOrder | undefined = buySellState.order

    return (
        <>
            <DisplayContainerStyled>
                <DisplayFieldPanelStyled isMobile={true}>
                    <DisplayFieldStyled>
                        <DisplayLabelStyled>Free equity</DisplayLabelStyled>
                        <DisplayValueStyled>
                            <Money amount={buySellState.freeEquity} key={'freeEquity'} />
                        </DisplayValueStyled>
                    </DisplayFieldStyled>

                    {buySellState.orderAction !== OrderAction.CLOSE && (
                        <DisplayFieldStyled>
                            <DisplayLabelStyled>Available equity</DisplayLabelStyled>
                            <DisplayValueStyled>
                                <Money amount={buySellState.availableEquity} key={'availableEquity'} />
                            </DisplayValueStyled>
                        </DisplayFieldStyled>
                    )}

                    {buySellState.orderAction !== OrderAction.CLOSE && (
                        <DisplayFieldStyled>
                            <DisplayLabelStyled>Margin requirement ({(buySellState.margin || 0) * 100} %)</DisplayLabelStyled>
                            <DisplayValueStyled>
                                <Money amount={buySellState.marginRequired} key={'marginRequired'} />
                            </DisplayValueStyled>
                        </DisplayFieldStyled>
                    )}

                    <DisplayFieldStyled>
                        <DisplayLabelStyled>Order Price</DisplayLabelStyled>
                        <DisplayValueStyled>
                            <span>
                                {order?.getOrderPrice()?.toLocaleString('en-GB', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 8,
                                })}
                            </span>
                        </DisplayValueStyled>
                    </DisplayFieldStyled>

                    <DisplayFieldStyled>
                        <DisplayLabelStyled>Quantity</DisplayLabelStyled>
                        <DisplayValueStyled>
                            <span className={buySellState.canOrder ? '' : 'invalid'}>{order?.getQuantity().toLocaleString()}</span>
                        </DisplayValueStyled>
                    </DisplayFieldStyled>

                    <DisplayFieldStyled>
                        <DisplayLabelStyled>Notional</DisplayLabelStyled>
                        <DisplayValueStyled>
                            <Money amount={buySellState.notionalPrice} key={'notionalPrice'} />
                        </DisplayValueStyled>
                    </DisplayFieldStyled>
                </DisplayFieldPanelStyled>
                <RenderPlaceOrderButtons buySellState={buySellState} setPlaceOrderError={setPlaceOrderError} isDMA={isDMA} reset={reset} />
            </DisplayContainerStyled>
        </>
    )
}
