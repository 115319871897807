import React, { RefObject } from 'react'
import ModalDialog, { ModalDialogProps } from 'react-bootstrap/ModalDialog'
import Draggable from 'react-draggable'
import { EnvironmentContext } from '../../../providers/environment/EnvironmentContext'

export interface DraggableModalDialogProps extends ModalDialogProps {
    nodeRef?: RefObject<HTMLElement>
}

export const DraggableModalDialog = ({ nodeRef, ...props }: DraggableModalDialogProps) => {
    const environmentContext = React.useContext(EnvironmentContext)
    const isMobile = environmentContext.isMobileDevice()
    /*
    deliberatlely change the handle for mobile so it cannot be dragged
     */
    return (
        <Draggable nodeRef={nodeRef} handle={'.draggable-dialog-header' + (isMobile ? '_mobile' : '')}>
            <ModalDialog {...props} />
        </Draggable>
    )
}
