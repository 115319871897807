import { FieldConfigDTO } from '../components/types'
import { isDefined } from './TypeCheckers'

export const splitAndAddCssProperties = (style: { [id: string]: any }, css: string) => {
    if (isDefined(css)) {
        let cssProperties = css.split(',')
        if (css.indexOf(';') > 0) {
            cssProperties = css.split(';')
        }
        cssProperties?.forEach((cssProperty) => {
            const keyValue: string[] = cssProperty.split(':')
            if (keyValue?.length === 2) {
                style[keyValue[0].trim().replace('"', '').replace('"', '')] = keyValue[1].replace('"', '').replace('"', '').trim()
            }
        })
    }
}

export const getWidthFromStyle = (css: any | undefined, extra?: number): number | string | undefined => {
    if (!css) {
        return undefined
    }
    const width = css['width'] || css['minWidth'] || css['maxWidth']
    if (!width) {
        return undefined
    }
    let widthValue = parseInt(width?.replace('px', '').replace('%', ''), 10)
    if (width?.indexOf('%') > 0) {
        return '' + (widthValue + (extra || 0)) + '%'
    }
    return widthValue + (extra || 0)
}

export const cleanWidthFromStyle = (style: { [id: string]: any } | undefined): { [id: string]: string } => {
    if (!style) {
        return {}
    }

    const returnVal: { [id: string]: any } = {}
    for (const origKey in style) {
        if (style.hasOwnProperty(origKey) && origKey !== 'width' && origKey !== 'minWidth' && origKey !== 'maxWidth') {
            returnVal[origKey] = style[origKey]
        }
    }

    return returnVal
}
export const replaceBespokeVariables = (isMobile: boolean, style: any): { [id: string]: any } => {
    //    //     public static final String CSS = "{NonMobile}width: 390px;{Mobile}width: 100%;";
    const newStyle: { [id: string]: any } = {}
    Object.keys(style).forEach((key) => {
        if (isMobile && key.startsWith('{Mobile}')) {
            newStyle[key.replace('{Mobile}', '')] = style[key]
        } else if (isMobile && key.startsWith('{NonMobile}')) {
            // do nothing
        } else if (!isMobile && key.startsWith('{NonMobile}')) {
            newStyle[key.replace('{NonMobile}', '')] = style[key]
        } else if (!isMobile && key.startsWith('{Mobile}')) {
            // do nothing
        } else {
            newStyle[key] = style[key]
        }
    })

    return newStyle
}

export const getStyle = (css?: string, extraCss?: string): { [id: string]: any } => {
    const style: { [id: string]: any } = {}
    if (css) {
        splitAndAddCssProperties(style, css)
    }
    if (extraCss) {
        splitAndAddCssProperties(style, extraCss)
    }
    return style
}

export const getHeaderStyleFor = (fld: FieldConfigDTO): { [id: string]: any } => {
    let extraCss
    if (fld.type === 'decimal' || fld.type === 'number') {
        extraCss = 'textAlign: right'
    }
    return getStyle(fld.labelCss, extraCss)
}

export interface IsElementOverflownDO {
    element: HTMLElement | null
    direction: 'x' | 'y' | 'any' | 'both'
}

export const isElementOverFlown = ({ element, direction }: IsElementOverflownDO) => {
    const clientWidth = element?.clientWidth || 0
    const scrollWidth = element?.scrollWidth || 0
    const clientHeight = element?.clientHeight || 0
    const scrollHeight = element?.scrollHeight || 0

    const resultMap: { [index: string]: boolean } = {
        x: scrollWidth > clientWidth,
        y: scrollHeight > clientHeight,
        any: scrollWidth > clientWidth || scrollHeight > clientHeight,
        both: scrollWidth > clientWidth && scrollHeight > clientHeight,
    }

    // if (direction === 'x') {
    //     console.log('isElementOverFlown scrollWidth > clientWidth', scrollWidth, clientWidth)
    // }
    if (direction === 'x' && clientWidth === 300) {
        // 300 set as base width in List View
        // gbw - biggest hack ever, not sure why it happens, table aligned to right
        return false
    }
    return resultMap[direction]
}

export const getTextAlign = (align: string | undefined) => {
    if (!align) {
        return 'inherit'
    } else if (align === 'left') {
        return 'left'
    } else if (align === 'right') {
        return 'right'
    } else if (align === 'center') {
        return 'center'
    } else {
        return 'inherit'
    }
}
