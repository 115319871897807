import { Modal } from 'react-bootstrap'
import styled, { ThemeProps } from 'styled-components'

export const OtherDealGrid = styled.div.attrs((props) => ({
    className: 'g-0',
    id: 'other-deals-grid',
}))``
export const ArticleViewDialogBodyStyled = styled(Modal.Body).attrs((props) => ({
    id: 'article_view_dialog_body',
}))`
    background-color: #fff;
    padding: 0 !important;
    //max-height: 700px;
    overflow: auto;
    display: grid;
`
export const ArticleViewWrapperStyled = styled.div.attrs((props) => ({
    id: 'article-wrapper',
}))`
    padding: 5px;
    background-color: #ffffff;
`

export const SearchWrapperStyled = styled.div.attrs((props) => ({
    id: 'search-wrapper',
}))`
    width: 35%;
    text-align: center;
    margin: 0 auto;
    padding: 10px;
`

interface IconsStyledProps extends ThemeProps<any> {
    width: string
}

export const IconsStyled = styled.span.attrs((props: IconsStyledProps) => ({}))<IconsStyledProps>`
    width: ${(props) => props.width};
    margin-right: 5px;
`
