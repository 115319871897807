import styled, { ThemeProps } from 'styled-components'

interface PercentStyledProps extends ThemeProps<any> {
    value: number | undefined
    colorise: boolean
}

export const PercentStyled = styled.div.attrs((props: PercentStyledProps) => ({}))<PercentStyledProps>`
    color: ${(props) => {
        let value = props.theme.table.rowColor
        if (props.colorise) {
            if (props.value) {
                value = props.value < 0 ? props.theme.money.negativeColor : props.theme.money.positiveColor
            }
        }
        return value
    }};
    display: inline;
`
export const PercentTableValueStyled = styled.div.attrs((props) => ({}))`
    text-align: right;
    float: right;
`
