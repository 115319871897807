import React from 'react'
import { ChartTitleProps } from '../types'
import { ChartTitleStyled } from './styled'

export default function ChartTitle({ chartTitle, singleChart, customCharts, mixedChart }: ChartTitleProps) {
    return (
        <div className={'col'}>
            <ChartTitleStyled>
                {chartTitle}
                <span className={'secondary_text'}>
                    {singleChart
                        ? ' (' + singleChart + ') '
                        : customCharts
                        ? ' (' + customCharts.map((item) => item) + ') '
                        : !mixedChart
                        ? ''
                        : ''}
                </span>
            </ChartTitleStyled>
        </div>
    )
}
