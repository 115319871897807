import React from 'react'
import { useNavigate } from 'react-router-dom'
import { LegalDTO, LegalsDTO } from '../../../providers/environment/types'
import { LegalStyled, LegalsWrapperStyled, LegalTitleStyled } from './styled'

export const Legals = (props: LegalsDTO): React.ReactElement => {
    const navigate = useNavigate()
    const goLegal = (legal: LegalDTO) => {
        if (props.onLegalClick) {
            props.onLegalClick(legal)
        } else {
            navigate('/legal/' + legal.code)
        }
    }
    return (
        <LegalsWrapperStyled className="legals" horizontalLayout={props.horizontalLayout}>
            {props.label && <LegalTitleStyled className="legals-title">{props.label}</LegalTitleStyled>}
            {props.legals?.map((legal) => (
                <LegalStyled className="legal" horizontalLayout={props.horizontalLayout} key={legal.label} onClick={() => goLegal(legal)}>
                    {legal.label}
                </LegalStyled>
            ))}
        </LegalsWrapperStyled>
    )
}
