//import {Checkbox} from 'primereact/checkbox';
//import {classNames} from 'primereact/utils';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { BlastContext } from '../../../providers/blast/BlastContext'
import { SessionContext } from '../../../providers/session/SessionContext'
import { hasValidation, shouldDisable, shouldHide, validate } from '../../../services/ValidationService'
import { isDefined, notDefined } from '../../../utils/TypeCheckers'
import { assignReferences, constructUrl, extractExtraFieldProperties, RunOnceEffect, unSubscribe } from '../../../utils/Utils'
import { FieldProperties, FormMode } from '../../types'
import { watchBlastDeltas } from '../../ui/FieldUtils'
import FieldLabel from '../field-label/FieldLabel'
import { GuslFormState } from '../maintain-form/guslFormSlice'
import Checkbox from '../maintain-table/bootstrap/Checkbox'
import { ExtraFieldProperties } from '../money/types'
import { FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled, HintStyled } from '../text/styled'
import { CheckboxFieldStyled, CheckboxWrapperStyled, InputCheckboxStyled } from './styled'

export const CheckboxField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const sessionContext = React.useContext(SessionContext)
    const blastContext = useContext(BlastContext)

    const _guslFormState: GuslFormState = useAppSelector((state) => state.guslFormSlice[properties.code])
    // console.log('_guslFormState code:',properties.code, properties.fieldConfig.name, _guslFormState)

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'CheckboxFormField-' + new Date().getTime())
    const [formMode, setFormMode] = useState(properties.formMode)
    // const [formValue, setFormValue] = useState<boolean>(false);
    // console.log('checkbox ', properties.fieldConfig.name, _guslFormState?.getFieldValue(properties), properties.data)
    const [formValue, setFormValue] = useState<boolean>(_guslFormState?.getFieldValue(properties) || properties.data || false)

    const valueRef = useRef(properties?.data)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [submitted, setSubmitted] = useState(false)
    const [refreshCounter, setRefreshCounter] = useState<number>(1)
    const [extraFieldProperties, setExtraFieldProperties] = useState<ExtraFieldProperties>(extractExtraFieldProperties(properties))

    useEffect(() => {
        const [loaderSubscription, blastDeltaSubscription, valueObservable] = watchBlastDeltas(
            sessionContext,
            blastContext,
            properties,
            formMode
        )
        const valueSubscription = valueObservable.subscribe((newValue: any) => {
            if (isDefined(newValue)) {
                setFormValue(newValue)
                setRefreshCounter(refreshCounter + 1)
            }
        })
        return () => {
            unSubscribe(loaderSubscription)
            unSubscribe(blastDeltaSubscription)
            unSubscribe(valueSubscription)
        }
    }, [properties])

    useEffect(() => {
        setFormValue(properties?.data || false)
        properties.onChange(properties.fieldConfig.name, properties?.data || false)
    }, [properties.formMode, properties.data])

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode)
    }

    const onRefresh = () => {}

    const doValidation = (fieldValue: any): boolean => {
        setSubmitted(true)
        const message = validate(
            properties.menuItem?.code,
            properties.fieldConfig.name,
            properties.fieldConfig.validators,
            'checkbox',
            fieldValue
        )
        if (message) {
            setErrorMessage(message)
            return false
        }
        return true
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, onRefresh, doValidation)
    })

    const passesConditionToBeEditable = (): boolean => {
        if (notDefined(extraFieldProperties.updateUrl)) {
            return false
        }
        if (notDefined(extraFieldProperties.updateCondition)) {
            return true
        }
        const functionCode: string = `
            // console.log('function: ',data,"${extraFieldProperties.updateCondition}");
            return data.${extraFieldProperties.updateCondition}
            `
        const func = new Function('self', 'data', functionCode)
        const val = func(this, properties.rowData)
        if (val === 'undefined') {
            return false
        }
        return val
    }
    const renderTableView = (): React.ReactElement => {
        const isEditable: boolean = passesConditionToBeEditable()
        const onToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e) {
                e.preventDefault()
                e.stopPropagation()
            }
            sessionContext.post<any, any>(constructUrl(extraFieldProperties.updateUrl || '', properties.rowData), {}).then(
                (response) => {
                    const newValue = response.data[properties.fieldConfig.name]
                    properties.onChange(properties.fieldConfig.name, newValue)
                    setFormValue(newValue)
                    setRefreshCounter(refreshCounter + 1)
                },
                (reason) => {}
            )
            return false
        }

        return (
            <CheckboxFieldStyled textAlign={properties.fieldConfig.align}>
                <Checkbox
                    key={properties.fieldConfig.name + '_' + refreshCounter}
                    inputId={properties.fieldConfig.name}
                    name={properties.fieldConfig.name}
                    value={formValue}
                    onChange={(e) => onToggle(e)}
                    checked={formValue}
                    disabled={!isEditable}
                />
            </CheckboxFieldStyled>
        )
    }
    const renderFormView = (): React.ReactElement => {
        const onDataInputChange = (value: boolean) => {
            setFormValue(value)
            properties.onChange(properties.fieldConfig.name, value)
            // console.log('--> update', properties.fieldConfig.name, value)
        }
        const hideField = shouldHide(formMode, properties.fieldConfig)
        const disableField: boolean = shouldDisable(formMode, properties.fieldConfig)
        const required = hasValidation(properties.fieldConfig?.validators)
        const value: any = formValue
        // @ts-ignore
        return (
            <>
                {!hideField && (
                    <>
                        <FloatingFormStyled>
                            <FieldContentWrapperStyled inLine={properties.inline}>
                                <FieldContentStyled>
                                    <CheckboxWrapperStyled submitted={submitted} noValue={!formValue}>
                                        <InputCheckboxStyled
                                            type="checkbox"
                                            id={properties?.fieldConfig.name}
                                            name={properties?.fieldConfig.name}
                                            checked={formValue}
                                            readonly={disableField}
                                            disabled={disableField}
                                            onChange={(e) => onDataInputChange(e.target.checked)}
                                            value={value}
                                            submitted={submitted}
                                            noValue={!formValue}
                                        />
                                    </CheckboxWrapperStyled>
                                </FieldContentStyled>
                                {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                                {submitted && errorMessage && <small className="yellow">{errorMessage}</small>}
                            </FieldContentWrapperStyled>
                            <FieldLabel properties={properties} />
                        </FloatingFormStyled>
                    </>
                )}
            </>
        )
    }

    return <>{properties.isTableView ? renderTableView() : renderFormView()}</>
}
