import React, { useEffect, useState } from 'react'
import { SessionContext } from '../../../../../../providers/session/SessionContext'
import { cancelAbortController, RunOnceEffect, unSubscribe } from '../../../../../../utils/Utils'
import { GuslReport } from '../../../../../common/gusl-report/GuslReport'
import LoadingSpinner from '../../../../../common/loading-spinner/LoadingSpinner'
import { ReportDO } from '../../../../../common/report/types'

interface ReportPanelProperties {
    reportUrl: string
}

export const ReportPanel = ({ reportUrl }: ReportPanelProperties): React.ReactElement<ReportPanelProperties> => {
    const sessionContext = React.useContext(SessionContext)

    const [loading, setLoading] = useState<boolean>(true)
    const [report, setReport] = useState<ReportDO | undefined>()
    const [systemReady, setSystemReady] = useState<boolean>(false)

    // console.log('reportUrl',reportUrl)
    RunOnceEffect(() => {
        let loaderSubscription = sessionContext.watchSystemReady().subscribe((systemReady: boolean) => {
            setSystemReady(systemReady)
        })
        return () => {
            unSubscribe(loaderSubscription)
        }
    })

    useEffect(() => {
        let mounted = true
        let abortController = new AbortController()
        if (systemReady) {
            sessionContext
                .post<any, ReportDO>(reportUrl, {}, abortController)
                .then((response) => {
                    setLoading(false)
                    console.log('response:', response)
                    setReport(response.data)
                })
                .catch((error) => {
                    setLoading(false)
                })
        }
        return () => {
            cancelAbortController(abortController)
            mounted = false
        }
    }, [reportUrl, systemReady])

    const renderPage = (): React.ReactElement => {
        return <GuslReport code={'card_rep_panel'} data={report} />
    }

    return <>{loading ? <LoadingSpinner /> : renderPage()}</>
}
