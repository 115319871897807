import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface GuslTableScrollLeftPositionPayload {
    code: string
    scrollLeftPosition: number
}
interface GuslState {
    [id: string]: GuslScrollState
}

export interface GuslScrollState {
    code: string
    scrollLeftPosition: number
}

const initialState: GuslState = {}
const createDefault = (code: string): GuslScrollState => {
    return {
        code: code,
        scrollLeftPosition: 0,
    } as GuslScrollState
}
const getTableState = (state: GuslState, code: string): GuslScrollState => {
    let entry: GuslScrollState = state[code]
    if (!entry) {
        entry = createDefault(code)
    }
    return entry
}
export const scrollSlice = createSlice({
    name: 'scrollSlice',
    initialState,
    reducers: {
        setScrollLeftPosition(state, action: PayloadAction<GuslTableScrollLeftPositionPayload>) {
            const entry: GuslScrollState = getTableState(state, action.payload.code)
            entry.scrollLeftPosition = action.payload.scrollLeftPosition
            state[action.payload.code] = entry
        },
    },
})
export const { setScrollLeftPosition } = scrollSlice.actions

export default scrollSlice.reducer
