import React, { useRef, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import WithTooltip from '../../ui/WithTooltip'
import { RangeQueryMenuDO, RangeQueryMenuProps, TimeUnitsMenuDO } from '../types'

export default function RangeQueryMenu({
    rangeQueryMenu,
    currentQuery,
    timeUnitsMenu,
    setRangeQuery,
    customSelectRange,
    isItemActive,
    CustomToggle,
    dateSelectHandler,
}: RangeQueryMenuProps) {
    const startDateRef = useRef<any>(null)
    const endDateRef = useRef<any>(null)
    const [minutes, resetMinutes] = useState(new Date().toISOString().split(':')[0] + ':00')
    const [minutes2, resetMinutes2] = useState(new Date().toISOString().split(':')[0] + ':00')

    return (
        <div className={'d-flex justify-content-between align-items-center row g-1 side_nav_background pt-1'}>
            <div className={' col-xl-12 d-flex justify-content-start align-items-center'}>
                {rangeQueryMenu
                    ?.filter((item: RangeQueryMenuDO) => !item.label.startsWith('Custom'))
                    .map((item: RangeQueryMenuDO, idx: React.Key | null | undefined) => (
                        <div
                            key={idx}
                            className={'chart_date ' + (isItemActive(item.rangeQuery, currentQuery, customSelectRange) ? 'active' : '')}
                            onClick={() => {
                                setRangeQuery(item.rangeQuery)
                            }}
                        >
                            {item.label}
                        </div>
                    ))}
                {timeUnitsMenu
                    ?.filter((item: TimeUnitsMenuDO) => item.label.startsWith('Custom'))
                    .map((item: TimeUnitsMenuDO, idx: React.Key | null | undefined) => (
                        <Dropdown key={idx} align={'end'}>
                            <Dropdown.Toggle as={CustomToggle} role={'button'} className={' p-0 m-0 secondary_text'} variant="transparent">
                                <WithTooltip
                                    text={
                                        startDateRef?.current?.value
                                            ? `${startDateRef?.current.value} 
                                                - ${endDateRef.current?.value || ''}`
                                            : 'Select dates'
                                    }
                                >
                                    <i className="fa-regular fa-calendar-days" />
                                    <>&nbsp;</>
                                </WithTooltip>
                            </Dropdown.Toggle>

                            <Dropdown.Menu id={'date_select'} className={' px-1 backdrop_filter_dark_dates '}>
                                <p className={'text-center'}>Select {item.label?.replace('Custom ', '')}</p>
                                {/*    {(item..rangeQuery.timeUnit !== 'y' && item.time_unit !== 'h') &&*/}
                                {/*        <>*/}
                                {/*            <input*/}
                                {/*                ref={startDateRef}*/}
                                {/*                className={'form-control form-control-sm xs_text mb-2 ' +*/}
                                {/*                    'secondary_background primary_text border-0'}*/}
                                {/*                step={item.step}*/}
                                {/*                type={item.input_type}/>*/}
                                {/*            {serverData.isCustomSelectRange &&*/}
                                {/*                <input*/}
                                {/*                    ref={endDateRef}*/}
                                {/*                    className={'form-control form-control-sm xs_text mb-2 ' +*/}
                                {/*                        'secondary_background primary_text border-0'}*/}
                                {/*                    step={item.step}*/}
                                {/*                    type={item.input_type}/>}*/}

                                {/*        </>}*/}
                                {/*    {(item.time_unit === 'h') &&*/}
                                {/*        <>*/}
                                {/*            /!*RE-SETTING MINUTES TO :00*!/*/}
                                {/*            <input*/}
                                {/*                ref={startDateRef}*/}
                                {/*                value={minutes}*/}
                                {/*                onChange={(e) => {*/}
                                {/*                    resetMinutes(e.target.value.split(':')[0]*/}
                                {/*                        .split(':')[0] + ':00');*/}
                                {/*                }}*/}
                                {/*                className={'form-control form-control-sm xs_text mb-2 ' +*/}
                                {/*                    'secondary_background primary_text border-0'}*/}
                                {/*                step={item.step}*/}
                                {/*                type={item.input_type}/>*/}
                                {/*            {serverData.isCustomSelectRange &&*/}
                                {/*                <input*/}
                                {/*                    ref={endDateRef}*/}
                                {/*                    value={minutes2}*/}
                                {/*                    onChange={(e) => {*/}
                                {/*                        resetMinutes2(e.target.value.split(':')[0]*/}
                                {/*                            .split(':')[0] + ':00')*/}
                                {/*                    }}*/}
                                {/*                    className={'form-control form-control-sm xs_text mb-2 ' +*/}
                                {/*                        'secondary_background primary_text border-0'}*/}
                                {/*                    step={item.step}*/}
                                {/*                    type={item.input_type}/>}*/}

                                {/*        </>}*/}
                                {/*    {item.time_unit === 'y' &&*/}
                                {/*        <>*/}
                                {/*            <select*/}
                                {/*                onChange={(e) => {*/}
                                {/*                    console.log('YEAR')*/}
                                {/*                    console.log(e.target.value)*/}
                                {/*                    console.log(new Date(e.target.value).toISOString())*/}
                                {/*                }}*/}
                                {/*                ref={startDateRef}*/}
                                {/*                className={'form-control form-control-sm xs_text mb-2 ' +*/}
                                {/*                    'secondary_background primary_text border-0'}*/}
                                {/*                name="year" id="year">*/}
                                {/*                {Array.from({length: 20}).map((_, idx) =>*/}
                                {/*                    <option value={new Date().getFullYear() - idx}*/}
                                {/*                            key={idx}*/}
                                {/*                            className={'text-dark'}*/}
                                {/*                    >{new Date().getFullYear() - idx}</option>)}*/}
                                {/*            </select>*/}
                                {/*            {serverData.isCustomSelectRange &&*/}
                                {/*                <select*/}
                                {/*                    onChange={(e) => {*/}
                                {/*                        console.log('YEAR')*/}
                                {/*                        console.log(e.target.value)*/}
                                {/*                        console.log(new Date(e.target.value).toISOString())*/}
                                {/*                    }}*/}
                                {/*                    ref={endDateRef}*/}
                                {/*                    className={'form-control form-control-sm xs_text mb-2 ' +*/}
                                {/*                        'secondary_background primary_text border-0'}*/}
                                {/*                    name="year" id="year">*/}
                                {/*                    {Array.from({length: 20}).map((_, idx) =>*/}
                                {/*                        <option key={idx} value={new Date().getFullYear() - idx}*/}
                                {/*                                className={'text-dark'}*/}
                                {/*                        >{new Date().getFullYear() - idx}</option>)}*/}
                                {/*                </select>}*/}
                                {/*        </>}*/}

                                <Dropdown.Item className={'select'}>
                                    <input
                                        type="button"
                                        /*@ts-ignore*/
                                        onClick={dateSelectHandler}
                                        value={'Select'}
                                        className={
                                            'form-control form-control-sm  mb-2 ' + 'secondary_background primary_text border-0 date_select'
                                        }
                                    />
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    ))}
            </div>
        </div>
    )
}
