import styled, { ThemeProps } from 'styled-components'

interface NumberStyledProps extends ThemeProps<any> {
    value: number | undefined
    colorise: boolean
}

export const NumberStyled = styled.div.attrs((props: NumberStyledProps) => ({}))<NumberStyledProps>`
    color: ${(props) => {
        let value = props.theme.table.rowColor
        if (props.colorise) {
            if (props.value) {
                value = props.value < 0 ? props.theme.money.negativeColor : props.theme.money.positiveColor
            }
        }
        return value
    }};
`
