import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FieldConfigDTO } from '../../../types'
import { LookupObjectDO, MultiSelectObjectDO, MultiSelectObjectSelectionDO } from './types'

export type fieldValueType = string | number | boolean | LookupObjectDO

export interface FieldInEditModeDO {
    id: string
    type: string
    name: string
    value: string | MultiSelectObjectSelectionDO
    rowId: string
    initialValue?: string
}

export interface MultiSelectFieldInEditModeDO {
    id: string
    type: string
    name: string
    value: MultiSelectObjectSelectionDO
    rowId: string
    initialValue?: string
}

export interface MultiselectOptionsDO {
    fieldName: string
    options: MultiSelectObjectDO[]
}

export interface MultiselectOptionsWithRowIdDO {
    fieldName: string
    options: MultiSelectObjectDO[]
    rowId: string
}

export interface InlineEditState {
    code: string
    rowsInEditMode: { [index: string]: fieldValueType }[]
    fieldsInEditMode: FieldInEditModeDO[]
    inlineEdit: boolean
    originalRows: { [index: string]: fieldValueType }[]
    multiSelectOptions: MultiselectOptionsWithRowIdDO[]
    lookupOptions: MultiselectOptionsDO[]
    offAt: number
    defaultMultiSelectOptions: MultiselectOptionsWithRowIdDO[]
}

export interface InlineEditInitPayload extends InlineEditState {}

interface MasterInlineEditState {
    [id: string]: InlineEditState
}

export interface InlineEditPayload {
    code: string
    field: FieldInEditModeDO
}

export interface CodePayload {
    code: string
}

export interface AddToOrigRowsPayload {
    code: string
    row: { [index: string]: string }
}

export interface AddToMultiSelectOptionsPayload {
    code: string
    fieldName: string
    options: MultiSelectObjectDO[]
    rowId: string
}

export interface AddToDefaultSelectedOptionsPayload extends AddToMultiSelectOptionsPayload {
    rowId: string
}

export interface RemoveFromDefaultSelectedOptionsPayload {
    code: string
    fieldName: string
    option: MultiSelectObjectDO
    rowId: string
}

export interface AddToLookupOptionsPayload {
    code: string
    fieldName: string
    options: LookupObjectDO[]
}

const initialState: MasterInlineEditState = {}

const loadInitValues = (values: InlineEditInitPayload): InlineEditState => {
    return {
        code: values.code,
        fieldsInEditMode: [],
        rowsInEditMode: [],
        inlineEdit: false,
        originalRows: [],
        multiSelectOptions: [],
        lookupOptions: [],
        offAt: 0,
        defaultMultiSelectOptions: [],
    }
}

const createDefault = (code: string): InlineEditState => {
    return {
        code: code,
        fieldsInEditMode: [],
        rowsInEditMode: [],
        inlineEdit: false,
        originalRows: [],
        multiSelectOptions: [],
        lookupOptions: [],
        offAt: 0,
        defaultMultiSelectOptions: [],
    }
}

const getState = (state: MasterInlineEditState, code: string): InlineEditState => {
    let entry: InlineEditState = state[code]
    if (!entry) {
        entry = createDefault(code)
    }
    return entry
}

export function getRowsToUpdate(
    rowsInEditMode: InlineEditState['rowsInEditMode'],
    fields: FieldConfigDTO[]
): InlineEditState['rowsInEditMode'] {
    const rowsToUpdate: { [index: string]: fieldValueType }[] = []
    const unNecessaryFields: string[] = ['rowId', 'actions', 'formMode', 'conditionalRowDetails']
    const booleanTypes: string[] = ['checkbox', 'radio']
    const numberTypes: string[] = ['number', 'money', 'new_money', 'percent', 'decimal']

    rowsInEditMode.forEach((row) => {
        let rowUpdated = true
        let hasEmptyField = false

        for (const field of fields) {
            let fieldValue: fieldValueType = typeof row[field.name] !== 'undefined' ? row[field.name] : 'false'
            if (field.type && booleanTypes.includes(field.type)) {
                fieldValue = fieldValue === 'true' || fieldValue === true
            }

            if ((typeof fieldValue === 'string' && fieldValue.trim() === '') || (typeof fieldValue === 'number' && isNaN(fieldValue))) {
                // If any field in the row is empty or has an invalid value, set the flag and break the loop
                hasEmptyField = true
                rowUpdated = false
                break
            }
        }

        if (hasEmptyField) {
            // If any field is empty, remove the row from the list of rows to update
            const index = rowsToUpdate.findIndex((r) => r.rowId === row.rowId)
            if (index !== -1) {
                rowsToUpdate.splice(index, 1)
            }
        } else if (rowUpdated) {
            // If no fields are empty and the row has been updated, add it to the list of rows to update
            const updatedRow = { ...row }

            unNecessaryFields.forEach((field) => {
                if (updatedRow.hasOwnProperty(field)) {
                    delete updatedRow[field]
                }
            })

            for (const fieldName in updatedRow) {
                const field = fields.find((field) => field.name === fieldName)
                const fieldType = field?.type

                if (fieldType && booleanTypes.includes(fieldType)) {
                    updatedRow[fieldName] = updatedRow[fieldName] === 'true' || updatedRow[fieldName] === true
                }
                if (fieldType && numberTypes.includes(fieldType)) {
                    updatedRow[fieldName] = parseFloat(updatedRow[fieldName] as string)
                }
                if (fieldType === 'lookup' || fieldType === 'multi_select') {
                    updatedRow[fieldName] = JSON.parse(updatedRow[fieldName] as string)
                }
            }
            rowsToUpdate.push(updatedRow)
        }
    })

    return rowsToUpdate
}

export const inlineEditSlice = createSlice({
    name: 'inlineEditSlice',
    initialState,
    reducers: {
        initInlineEditSlice(state, action: PayloadAction<InlineEditInitPayload>) {
            state[action.payload.code] = loadInitValues(action.payload)
        },
        updateEditFields(state, action: PayloadAction<InlineEditPayload>) {
            const { code, field } = action.payload
            const entry: InlineEditState = getState(state, code)

            const existingFieldIndex = entry.fieldsInEditMode.findIndex((f) => f.name === field.name && f.rowId === field.rowId)

            const updatedFields = [...entry.fieldsInEditMode]

            if (existingFieldIndex === -1) {
                // @ts-ignore
                updatedFields.push({ ...field, initialValue: field.value })
            } else {
                updatedFields[existingFieldIndex] = {
                    ...updatedFields[existingFieldIndex],
                    value: field.value,
                }
            }

            const existingRowIndex = entry.rowsInEditMode.findIndex((row) => row.rowId === field.rowId)

            const updatedRows = [...entry.rowsInEditMode]

            if (existingRowIndex === -1) {
                // Check if any field in the row has a different value than its initialValue
                const hasDifferentValues = updatedFields.some((f) => f.rowId === field.rowId && f.value !== f.initialValue)

                if (hasDifferentValues) {
                    const originalRow = entry.originalRows.find((existingRow) => existingRow.id === field.id)

                    const fullRow = {
                        ...originalRow,
                        ...{
                            rowId: field.rowId,
                            id: field.id,
                            [field.name]: field.value,
                        },
                    }
                    // updated row with only updated fields is not working yet
                    const updatedRow = {
                        rowId: field.rowId,
                        id: field.id,
                        [field.name]: field.value,
                    }
                    // @ts-ignore
                    //updatedRows.push(fullRow);
                    updatedRows.push(updatedRow)
                }
            } else {
                const existingRow = updatedRows[existingRowIndex]

                // Check if the new value of the field is different from its initialValue
                const hasDifferentValue = updatedFields.some((f) => f.rowId === field.rowId && f.value !== f.initialValue)

                if (hasDifferentValue) {
                    // @ts-ignore
                    existingRow[field.name] = field.value
                    updatedRows[existingRowIndex] = existingRow
                }
            }

            entry.fieldsInEditMode = updatedFields
            entry.rowsInEditMode = updatedRows

            state[code] = entry
        },

        toggleInlineEdit(state, action: PayloadAction<CodePayload>) {
            const entry: InlineEditState = getState(state, action.payload.code)
            if (entry.inlineEdit) {
                entry.offAt = new Date().getTime()
            }
            entry.inlineEdit = !entry.inlineEdit
            state[action.payload.code] = entry
        },
        resetInlineEdit(state, action: PayloadAction<CodePayload>) {
            const { code } = action.payload
            const entry: InlineEditState = getState(state, code)
            entry.fieldsInEditMode = []
            entry.rowsInEditMode = []

            state[code] = entry
        },

        addToOriginalRows(state, action: PayloadAction<AddToOrigRowsPayload>) {
            const { code, row } = action.payload
            const entry: InlineEditState = getState(state, code)
            const rowExists = entry.originalRows.some((existingRow) => existingRow.id === row.id)

            if (!rowExists) {
                entry.originalRows = [...entry.originalRows, row]
            }
        },

        addToMultiSelectOptions(state, action: PayloadAction<AddToMultiSelectOptionsPayload>) {
            const { code, options, fieldName, rowId } = action.payload
            const entry: InlineEditState = getState(state, code)
            const optionsExists = entry.multiSelectOptions.some((opts) => opts.fieldName === fieldName && opts.rowId === rowId)

            if (!optionsExists) {
                entry.multiSelectOptions = [...entry.multiSelectOptions, { fieldName, options, rowId }]
            }
        },

        addToLookupOptions(state, action: PayloadAction<AddToLookupOptionsPayload>) {
            const { code, options, fieldName } = action.payload
            const entry: InlineEditState = getState(state, code)
            const optionsExists = entry.lookupOptions.some((options) => options.fieldName === fieldName)

            if (!optionsExists) {
                entry.lookupOptions = [...entry.lookupOptions, { fieldName, options }]
            }
        },
        addToDefaultSelectedOptions(state, action: PayloadAction<AddToDefaultSelectedOptionsPayload>) {
            const { code, options, fieldName, rowId } = action.payload
            const entry: InlineEditState = getState(state, code)
            const optionsExists = entry.defaultMultiSelectOptions.some(
                (options) => options.fieldName === fieldName && options.rowId === rowId
            )

            if (!optionsExists) {
                entry.defaultMultiSelectOptions = [...entry.defaultMultiSelectOptions, { fieldName, options, rowId }]
            }
        },
        removeFromDefaultSelectedOptions(state, action: PayloadAction<RemoveFromDefaultSelectedOptionsPayload>) {
            const { code, option, fieldName, rowId } = action.payload
            const entry: InlineEditState = getState(state, code)
            const defaultOptionsIndex = entry.defaultMultiSelectOptions.findIndex(
                (options) => options.fieldName === fieldName && options.rowId === rowId
            )

            if (defaultOptionsIndex !== -1) {
                entry.defaultMultiSelectOptions[defaultOptionsIndex].options = entry.defaultMultiSelectOptions[
                    defaultOptionsIndex
                ].options.filter((opt) => opt.label !== option.label && opt.value !== option.value)
                state[code] = entry
            }
        },
    },
})

export const {
    initInlineEditSlice,
    updateEditFields,
    toggleInlineEdit,
    resetInlineEdit,
    addToOriginalRows,
    addToMultiSelectOptions,
    addToLookupOptions,
    addToDefaultSelectedOptions,
    removeFromDefaultSelectedOptions,
} = inlineEditSlice.actions

export default inlineEditSlice.reducer
