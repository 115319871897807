import { Editor, PluginOptions } from 'grapesjs'

export const OssHeader = (editor: Editor, options = {}) => {
    const opts: Required<PluginOptions> = {
        config: {},
        constructor: '',
        labelImageEditor: 'Hdr',
        labelApply: 'Do It',
        height: '650px',
        width: '100%',
        commandId: 'tui-image-editor',
        toolbarIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 137.5" x="0px" y="0px"><path d="M74,21.9a9.38,9.38,0,1,0,9.38,9.38A9.38,9.38,0,0,0,74,21.9Zm0,16.75a7.38,7.38,0,1,1,7.38-7.37A7.37,7.37,0,0,1,74,38.65ZM12.85,65.24a5.71,5.71,0,0,0,0,11.41H29.77a6.3,6.3,0,0,0,4.49-1.86l7.58-7.58L51.94,77.3a4.31,4.31,0,0,1,1.27,3.07V97.52a5.67,5.67,0,1,0,11.34,0V80.32a10.67,10.67,0,0,0-3.14-7.59l-8.36-8.36,13.21-13.2a7.65,7.65,0,0,0,0-10.82l-6.57-6.57a6.79,6.79,0,0,0-4.83-2H43.11a7.69,7.69,0,0,0-5.58,2.4L29.19,43a4.65,4.65,0,0,0,3.38,7.84,4.58,4.58,0,0,0,3.28-1.36L42.32,43h7.09L27.19,65.24ZM28.31,67,52.54,42.73a1,1,0,0,0,.21-1.09,1,1,0,0,0-.92-.62H41.91a1,1,0,0,0-.71.29l-6.76,6.76a2.72,2.72,0,0,1-3.75,0,2.64,2.64,0,0,1-.05-3.69L39,35.55a5.7,5.7,0,0,1,4.13-1.77H54.86a4.79,4.79,0,0,1,3.41,1.41l6.57,6.57a5.64,5.64,0,0,1,0,8L50.93,63.67a1,1,0,0,0,0,1.41L60,74.15a8.7,8.7,0,0,1,2.55,6.17v17.2a3.67,3.67,0,1,1-7.34,0V80.37a6.29,6.29,0,0,0-1.86-4.48L42.55,65.08a1,1,0,0,0-.71-.29,1,1,0,0,0-.7.29l-8.3,8.3a4.31,4.31,0,0,1-3.07,1.27H12.85a3.71,3.71,0,0,1,0-7.41H27.6A1,1,0,0,0,28.31,67ZM92.09,6.81a10.74,10.74,0,0,0-6.78,2.41l0,0h0A10.75,10.75,0,0,0,98.87,25.92l0,0h0a10.75,10.75,0,0,0-6.8-19.09Zm8.7,9.76h-4l-1.42-2.45,2.8-2.8A8.69,8.69,0,0,1,100.79,16.57ZM90.58,20.18l-1.51-2.61L90.58,15h3l1.52,2.61-1.52,2.61Zm6-10.09L93.76,13H90.42l-2.87-2.87a8.67,8.67,0,0,1,9.07,0ZM86,11.33l2.79,2.79-1.41,2.45h-4A8.74,8.74,0,0,1,86,11.33Zm-2.57,7.24h4L88.75,21,86,23.81A8.74,8.74,0,0,1,83.39,18.57Zm4.16,6.48,2.87-2.87h3.34l2.86,2.87a8.67,8.67,0,0,1-9.07,0Zm10.67-1.23L95.42,21l1.42-2.45h4A8.72,8.72,0,0,1,98.22,23.82Z"/></svg>`,
        hideHeader: true,
        addToAssets: true,
        upload: false,
        onApplyButton: () => {},
        onApply: null,
        script: [],
        style: [],
        ...options,
    }

    const { script, style, height, width, hideHeader, onApply, upload, addToAssets, commandId } = opts

    if (opts.toolbarIcon) {
        editor.Components.addType('image', {
            extendFn: ['initToolbar'],
            model: {
                initToolbar() {
                    const tb = this.get('toolbar')
                    const tbExists = tb?.some((item) => item.command === commandId)

                    if (!tbExists) {
                        tb?.unshift({
                            command: commandId,
                            label: opts.toolbarIcon,
                        })
                        this.set('toolbar', tb)
                    }
                },
            },
        })
    }

    editor.Blocks.add('oss-header', {
        label: 'Header',
        category: 'OSS',
        attributes: { class: 'gjs-oss-header', foo: 'bar' },
        media: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 30" x="0px" y="0px"><path d="M12,6V18a1,1,0,0,1-2,0V13H4v5a1,1,0,0,1-2,0V6A1,1,0,0,1,4,6v5h6V6a1,1,0,0,1,2,0Zm5,11a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2H20V6a1,1,0,0,0-1-1H17a1,1,0,0,0,0,2h1V17Z"/></svg>`,
        content: {
            type: 'text',
            content: 'Header',
            style: { padding: '10px', 'font-size': '22px', 'font-weight': 'bold' },
        },
    })
    editor.Commands.add(commandId, {})
}
