import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import {
    GuslTableQueryParamState,
    handleNumberRangeQueries,
    resetSearchMusts,
    resetSingleNumberRange,
    setSearchMusts,
} from '../../queryParamsSlice'
import { InputStyled } from '../../styled/date-range/styled'
import { ActionStyled } from '../../styled/number-filter/styled'
import { ErrorMsgStyled } from '../../styled/side-panel/styled'
import { FilterDO } from '../types'
import { RangeQueryDTO } from '../../../../types'

export type IndividualNumberFilterDO = {
    filter: FilterDO
    code: string
}

export interface RangeValuesDO {
    from: RangeQueryDTO['from']
    to: RangeQueryDTO['to']
}

export default function IndividualNumberFilter({ filter, code }: IndividualNumberFilterDO) {
    const [className] = useState('IndividualNumberFilter-' + new Date().getTime())
    const queryParamsState: GuslTableQueryParamState = useAppSelector((state) => state.queryParamsSlice[code])
    const resetAt: number | undefined = queryParamsState.queryParams.resetAt
    const [filterResetAt, setFilterResetAt] = useState<number | undefined>(undefined)

    const dispatch = useAppDispatch()
    const [noSelection, setNoSelection] = useState<boolean>(false)
    const [noNumberSelection, setNoNumberSelection] = useState<boolean>(false)

    // MK 22/08/2023 when opening and closing individual filter, we need to preserve the values
    const currentRange: RangeQueryDTO[] | undefined = queryParamsState.queryParams.rangeQueries?.filter(
        (rangeQuery) => rangeQuery.field === filter.name
    )
    const emptyValues: RangeValuesDO = { from: 0, to: 0 }
    let values: RangeValuesDO = currentRange?.length ? { from: currentRange[0].from, to: currentRange[0].to } : emptyValues
    let [multiFormValues, setMultiFormValues] = useState<RangeValuesDO>(values)
    // END OF 22/08/2023

    const fromNumRef = useRef<HTMLInputElement>(null)
    const toNumRef = useRef<HTMLInputElement>(null)
    const singleNumRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        resetFields()
        // MK 22/08/2023
        if (!currentRange?.length) {
            setMultiFormValues(emptyValues)
        }
    }, [resetAt, filterResetAt])

    function resetFields() {
        setFilterResetAt(new Date().getTime())

        if (fromNumRef.current !== null) {
            fromNumRef.current.value = ''
        }
        if (toNumRef.current !== null) {
            toNumRef.current.value = ''
        }
    }

    function resetNumberField() {
        setFilterResetAt(new Date().getTime())

        if (singleNumRef.current !== null) {
            singleNumRef.current.value = ''
        }
    }

    const isSelected = queryParamsState.queryParams.rangeQueries?.filter((numberRange) => numberRange.field === filter.name).length === 1

    const isNumberSelected = queryParamsState.queryParams.musts?.filter((must) => must.field === filter.name).length === 1

    function setNumberRangeHandler() {
        resetNumberHandler()
        if (fromNumRef?.current?.value === '' || toNumRef?.current?.value === '') {
            setNoSelection(true)
            return
        }

        setNoSelection(false)

        dispatch(
            handleNumberRangeQueries({
                code,
                field: filter.name,
                from: parseFloat(fromNumRef?.current?.value as string),
                to: parseFloat(toNumRef?.current?.value as string),
            })
        )
    }
    function setSingleNumberHandler() {
        resetNumberRangeHandler()
        if (singleNumRef?.current?.value === '') {
            setNoNumberSelection(true)
            return
        }

        setNoSelection(false)
        // @ts-ignore
        dispatch(
            setSearchMusts({
                code,
                field: filter.name,
                // @ts-ignore
                value: singleNumRef?.current?.value,
            })
        )
    }

    function resetNumberRangeHandler() {
        setNoSelection(false)
        dispatch(resetSingleNumberRange({ code, field: filter.name }))
        resetFields()
    }
    function resetNumberHandler() {
        setNoNumberSelection(false)
        resetNumberField()

        dispatch(resetSearchMusts({ code }))
    }

    return (
        <div>
            <div>
                {noSelection && <ErrorMsgStyled>Please, select both values!</ErrorMsgStyled>}
                <div className={'d-flex justify-content-evenly align-items-center'}>
                    <div className=" w-50 mx-1">
                        <InputStyled
                            type="number"
                            ref={fromNumRef}
                            step={0.00001}
                            id={filter.label + '-from'}
                            name={filter.label}
                            className={'form-control form-control-sm'}
                            /* MK 13/08/2023  */
                            placeholder={multiFormValues.from !== 0 ? multiFormValues.from : 'From'}
                            /* END OF MK 13/08/2023  */
                        />
                    </div>

                    <div className=" w-50 mx-1">
                        <InputStyled
                            type="number"
                            ref={toNumRef}
                            step={0.00001}
                            id={filter.label + '-to'}
                            name={filter.label + '-to'}
                            className={'form-control form-control-sm'}
                            /* MK 13/08/2023  */
                            placeholder={multiFormValues.to !== 0 ? multiFormValues.to : 'To'}
                            /* END OF MK 13/08/2023  */
                        />
                    </div>
                </div>
                <div className={'d-flex justify-content-end'}>
                    <ActionStyled onClick={resetNumberRangeHandler}>Reset</ActionStyled>
                    <ActionStyled onClick={setNumberRangeHandler} isActive={isSelected}>
                        Set
                    </ActionStyled>
                </div>
            </div>
            <p className="xs-small text-center">or</p>
            <div>
                {noNumberSelection && <ErrorMsgStyled>Please, type number!</ErrorMsgStyled>}
                <InputStyled
                    type="number"
                    ref={singleNumRef}
                    step={0.00001}
                    id={filter.label + '-single'}
                    name={filter.label + '-single'}
                    className={'form-control form-control-sm'}
                    placeholder={'Type number'}
                />
                <div className={'d-flex justify-content-end'}>
                    <ActionStyled onClick={resetNumberHandler}>Reset</ActionStyled>
                    <ActionStyled onClick={setSingleNumberHandler} isActive={isNumberSelected}>
                        Set
                    </ActionStyled>
                </div>
            </div>
        </div>
    )
}
