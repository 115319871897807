import { isMobile } from '../../../../utils/TypeCheckers'

export const PIE_DEFAULT_OPTIONS = {
    maintainAspectRatio: false,
    borderWidth: 1,
    borderRadius: 3,
    plugins: {
        legend: {
            display: true,
            position: isMobile ? ('bottom' as const) : ('left' as const),
            labels: {
                padding: isMobile ? 5 : 15,
                boxWidth: 8,
                boxHeight: 8,
                usePointStyle: true,
                pointStyle: 'circle',
                font: { size: 10 },

                filter: (
                    legendItem: { text: string },
                    data: {
                        labels: string | any[]
                        datasets: { data: { [x: string]: any } }[]
                    }
                ) => {
                    // First, retrieve the data corresponding to that label
                    const label = legendItem.text
                    const lIndex = data.labels.indexOf(label)
                    // @ts-ignore
                    const newText = data.datasets[0].labels[lIndex]
                    // @ts-ignore
                    const qtd = data.datasets[0].data[lIndex]
                    // Second, mutate the legendItem to include the new text
                    legendItem.text = `${newText}:(${qtd})`
                    // Third, the filter method expects a bool, so return true to show the modified legendItem in the legend
                    return true
                },
            },
        },
    },
}
